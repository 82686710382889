import { Spacer } from "@zilliondigital/mirage-ui";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ArrowLeft from "~/assets/icons/Arrow--right.png";
import theme from "~/themes/theme";

type StyledContainerProps = {
  isActive: boolean;
};

const Container = styled.div`
  padding-top: 20px;
`;

const ContainerSubMenus = styled.div<StyledContainerProps>`
  padding: 10px;

  cursor: pointer;
  border: ${(props) =>
    props.isActive
      ? `1px solid ${theme.colors.primary.main}`
      : "1px solid transparent"};
  transition: border-left-color 0.3s ease;
  background-color: ${() => "#f4f4f4"};
  margin-bottom: 20px;
  border-radius: 5px;
`;

const Title = styled.h1<StyledContainerProps>`
  font-size: 16px;
  /* font-weight: ${({ isActive }) => (isActive ? "700" : "500")}; */
  color: ${({ isActive }) =>
    isActive ? theme.colors.primary.main : theme.colors.base.dark500};
  position: relative;
  top: 6px;
`;

const Description = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: ${() => theme.colors.base.dark500};
  margin-top: -5px;
  position: relative;
  top: 6px;
`;

const ArrowIcon = styled.img<StyledContainerProps>`
  height: 16px;
  /* opacity: ${({ isActive }) => (isActive ? "1 " : "0")}; */
  filter: ${() => theme.colors.primary.mainFilter};
`;

type MenuProps = {
  title: string;
  description: string;
  onClick: () => void;
};

export type SubMenuProps = {
  menus: MenuProps[];
  activeIndex?: number | null; // Add activeIndex to SubMenuProps
};

const SubMenu = (props: SubMenuProps) => {
  const { menus, activeIndex } = props;
  const [localActiveIndex, setLocalActiveIndex] = useState<number | null>(
    activeIndex ?? -1
  );

  useEffect(() => {
    if (activeIndex !== undefined && activeIndex !== localActiveIndex) {
      setLocalActiveIndex(activeIndex);
    }
  }, [activeIndex]);

  const handleItemClick = (index: number) => {
    setLocalActiveIndex(index);
    menus[index].onClick();
  };

  return (
    <Container>
      {menus.map((item, index) => (
        <ContainerSubMenus
          className="d-flex justify-content-center align-items-between  flex-column"
          key={index}
          isActive={index === localActiveIndex}
          onClick={() => {
            item.onClick();
            handleItemClick(index);
            item.onClick();
          }}
        >
          <div>
            <Title isActive={index === localActiveIndex} className="mt-1">
              {item.title}
            </Title>
            <Spacer bottom={10} />
            <Description>{item.description}</Description>
          </div>
          <div className="mt-3 d-flex justify-content-end">
            <ArrowIcon
              isActive={index === localActiveIndex}
              src={ArrowLeft}
              alt="Seta"
            />
          </div>
        </ContainerSubMenus>
      ))}
    </Container>
  );
};

export default SubMenu;
