import { Button, Spacer, Table } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import MoreIcon from "~/assets/icons/buttons/more.png";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getFirstLetters } from "~/services/utils";
import { formatCPFCNPJ, formatCelphone } from "~/utils";
import Title from "~/components/Texts/Title";

import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";

import BankIcon from "~/assets/icons/contacts/bank_icon.png";
import useContacts from "~/hooks/Contacts/useContacts";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import theme from "~/themes/theme";
import label from "~/labels";

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }

  .bank-accounts-picture {
    width: 10px;
    height: 10px;
    background-color: #d8d8d7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }

  .img-bank-icon {
    width: 25px;
  }
`;

const SelectBankAccount = () => {
  const {
    changeSideAnimation,
    setChangeSideAnimation,
    setBankAccountIndex,
    bankAccountIndex,
    dataKeyPixSelectedPartner,
    setDataKeyPixSelectedPartner,
  } = useNewTransfer();

  const {
    contactSelectedPixData,
    setIdBankAccountPixPartner,
    idBankAccountPixPartner,
    setStepIndex,
    setIsPixWithSelectedPartner,
    selectedIndex,
    setSelectedIndex,
    setContactSelectedPixData,
  } = useTransferWithPix();

  // const bankAccountOptions =
  //   contactSelectedPixData &&
  //   contactSelectedPixData[0]?.bank_accounts?.map(
  //     (account: any, index: number) => ({
  //       label: `Banco: ${account.id} - Agência: ${account.agency} - Conta: ${account.account_number}-${account.account_number_digit}`,
  //       value: index,
  //     })
  //   );

  // const pixKeyData =
  //   contactSelectedPixData &&
  //   contactSelectedPixData[0]?.keys?.map((key: any, index: number) => ({
  //     index: index,
  //     key: key.key,
  //     type: key.type,
  //   }));

  // type accountTypeProps = {
  //   [key: string]: string;
  // };

  const [selectedRows, setSelectedRow] = useState<any[]>([]);

  const SelectBankAccount = (id: number) => {
    setSelectedIndex(id);

    setSelectedRow([bankAccountIndex]);
  };

  const handleShowTheSelectBankAccount = (data: any) => {
    SelectBankAccount(selectedIndex === data.index ? -1 : data && data.index);
    setDataKeyPixSelectedPartner(data);
    setBankAccountIndex(
      bankAccountIndex !== -1 && bankAccountIndex === data.index
        ? -1
        : data.index
    );
  };

  const labelTypeKey: { [key: string]: string } = {
    evp: "Chave Aleatória",
    cpf: "CPF",
    cnpj: "CNPJ",
    telefone: "Telefone",
  };

  const columnContactSelected = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any, index: number) => (
        <div className="d-flex align-items-center">
          <ContainerImage>
            <div className="container-profile-picture">
              <div className="rounded-circle profile-picture">
                <h1>{getFirstLetters(data.name)}</h1>
              </div>
            </div>
          </ContainerImage>
          <Spacer left={10} />
          <div className="d-flex flex-column">
            <Title>{data.name}</Title>
            <Description color="#929292">
              {formatCPFCNPJ(data.document_number)}
            </Description>
          </div>
        </div>
      ),
    },
  ];

  const columnsBankAccounts = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any, index: number) => (
        <ContainerRenderData
          onClick={() => {
            setSelectedIndex(data && data.index);
            handleShowTheSelectBankAccount(data && data);
          }}
        >
          <div className="d-flex align-items-center">
            {selectedIndex === data.index ? (
              <ContainerImage>
                <div className="contact-selected-image">
                  <img
                    className="selected-contact-image"
                    src={SelectedContactIcon}
                  />
                </div>
              </ContainerImage>
            ) : (
              <ContainerImage>
                <div className="container-profile-picture">
                  <div className="rounded-circle  bank-accounts-picture">
                    <img className="img-bank-icon" src={BankIcon} />
                  </div>
                </div>
              </ContainerImage>
            )}
            <Spacer left={10} />
            <div className="d-flex flex-column">
              <Title>{`${
                data.type === "cpf" || data.type === "cnpj"
                  ? formatCPFCNPJ(data.key)
                  : data.type === "telefone"
                  ? formatCelphone(data.key)
                  : data.key
              }`}</Title>

              <Description>{labelTypeKey[data.type]}</Description>
            </div>
          </div>
        </ContainerRenderData>
      ),
    },
  ];

  useEffect(() => {
    setBankAccountIndex(-1);
  }, []);

  useEffect(() => {
    setIdBankAccountPixPartner(
      contactSelectedPixData &&
        contactSelectedPixData[0]?.bank_accounts[selectedIndex]?.id
    );
  }, [
    contactSelectedPixData &&
      contactSelectedPixData[0]?.bank_accounts[selectedIndex]?.id,
  ]);

  const dataKeysPix = useMemo(() => {
    if (contactSelectedPixData && contactSelectedPixData[0]) {
      const data = contactSelectedPixData[0].keys.map(
        (key: any, index: number) => {
          return {
            ...key,
            index,
          };
        }
      );

      return data;
    }
  }, [contactSelectedPixData]);

  return (
    <motion.div
      initial={{
        x: changeSideAnimation ? "-100%" : !changeSideAnimation && "100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: changeSideAnimation ? "100%" : !changeSideAnimation && "-100%",
      }}
      transition={{ duration: 0.3 }}
      className="overflow-hidden"
    >
      <div>
        <div className="p-4">
          <Description color="#666666">
            Selecione uma chave pix para enviar a transferência
          </Description>
        </div>{" "}
        <Table
          columns={columnContactSelected}
          data={[
            {
              name: contactSelectedPixData && contactSelectedPixData[0]?.name,
              document_number:
                contactSelectedPixData &&
                contactSelectedPixData[0]?.document_number,
            },
          ]}
          haveHeader={false}
        />
        <div className="p-4">
          <Title>Chaves Pix</Title>
        </div>
        <div
          style={{ zIndex: "1", overflowY: "auto", maxHeight: "400px" }}
          className="  w-100"
        >
          {dataKeysPix.length > 0 ? (
            <Table columns={columnsBankAccounts} data={dataKeysPix} />
          ) : (
            <div className="p-4 text-center">
              Este contato não possui nenhuma chave pix associada
            </div>
          )}
        </div>
        {/* <div className="col-12 p-4"> */}
        {/* <Table
            columns={columnContactSelected}
            data={[
              {
                name: newTransferTEDData.name,
                document_number: newTransferTEDData.document_number,
              },
            ]}
          /> */}
        {/* <CustomSelect
            options={bankAccountOptions}
            placeholder="Selecione"
            onChange={handleSelectChange}
          />
        </div> */}
        <Spacer bottom={20} />
        <div className="p-4">
          <div className="d-flex justify-content-between">
            <div>
              <Button
                mode="phantom"
                label="Voltar"
                onClick={() => {
                  setChangeSideAnimation(true);
                  setStepIndex(0);
                  setIsPixWithSelectedPartner(false);
                  setContactSelectedPixData(undefined);
                  setSelectedIndex(-1);
                }}
              />
            </div>

            <div className="d-flex">
              {/* <Button
                mode="border"
                label="Adicionar conta bancária"
                // onClick={handleAddContact}
                icon={MoreIcon}
              /> */}
              <Spacer left={10} />
              <Button
                label="Avançar"
                mode="main"
                onClick={() => {
                  setStepIndex(1);
                }}
                disabled={idBankAccountPixPartner === undefined}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SelectBankAccount;
