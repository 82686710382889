import "bootstrap/dist/css/bootstrap.min.css";
import {
  ButtonMoreDetails,
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";

import styled, { keyframes } from "styled-components";
import { Fragment, useEffect, useState } from "react";
import PenIcon from "~/assets/icons/contacts/pencil.png";
import Timer from "~/assets/icons/figure_3d_time.png";
import DeleteIcon from "~/assets/icons/delete.png";
import Description from "~/components/Texts/Description";
import OpenAccordionIcon from "~/assets/icons/Actions/Chevron.png";
import CloseAccordionIcon from "~/assets/icons/Actions/Chevron2.png";

import ApiService from "~/services/api";
import { formatCPFCNPJ, formatNumberToCurrencyWithoutSimbol } from "~/utils";
import theme from "~/themes/theme";
import { ImgEye, Title } from "../../app-content.styles";
import useSplit from "~/hooks/useSplit";
import { useNavigate } from "react-router-dom";
import { APIResponse, Columns, getSplit } from "~/types";

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
   
  }
`;

const ImgDelete = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.error.mainFilter};
  }
`;

const EditContainer = styled.div`
  position: relative;
  right: -25px;
`;

const Rounded = styled.div`
  width: 96px;
  height: 96px;
  background-color: #ffe5d8;
  position: relative;

  img {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`;

const SplitList = () => {
  const [selectedDetailsIndex, setSelectedDetailsIndex] = useState<
    number | null
  >(null);
  const {
    setSplitsData,
    splitsData,
    setSplitData,
    setOpenDeleteSplitModal,
    setIsEditing,
  } = useSplit();

  const navigate = useNavigate();

  type TypesRenderDepositType = {
    [key: string]: string;
  };

  const renderDepositType: TypesRenderDepositType = {
    pix_transfer: "Transferência PIX",
    pix_manual_transfer: "Transferência PIX",
    tev: "TEV - Transferência entre contas",
    ted: "Tranferência TED",
  };

  const columns: Columns[] = [
    {
      label: "Nome do arranjo",
      key: "name",

      renderData: (data: any) => {
        return (
          <div
            className="d-flex flex-column"
            style={{ position: "relative" }}
            onClick={() => {
              setSelectedDetailsIndex(
                selectedDetailsIndex === data.id ? null : data.id
              );
            }}
          >
            <div>
              <ImgEye
                style={{ height: "22px" }}
                id={`more-details-${data.id}`}
                src={
                  selectedDetailsIndex === data.id
                    ? CloseAccordionIcon
                    : OpenAccordionIcon
                }
                onClick={() => {
                  setSelectedDetailsIndex(
                    selectedDetailsIndex === data.id ? null : data.id
                  );
                }}
              />{" "}
              <NotControlledToolTip
                placement="top"
                target={`more-details-${data.id}`}
              >
                {selectedDetailsIndex === data.id
                  ? "Ocultar detalhes"
                  : "Mais Detalhes"}
              </NotControlledToolTip>
              <span className="h6">{data.name}</span>
            </div>

            {selectedDetailsIndex === data.id && (
              <div>
                <tr />
                <Spacer bottom={20} />
                {data?.beneficiaries?.map(
                  (beneficiarie: any, index: number) => {
                    return (
                      <div key={index}>
                        {" "}
                        <p className="h6">{beneficiarie?.contact_data?.name}</p>
                        <Spacer top={-10} />
                        <p>
                          {formatCPFCNPJ(
                            beneficiarie?.contact_data?.document_number
                          )}
                        </p>
                        <Spacer top={-15} />
                        <p>
                          <b> Split:</b>
                          {beneficiarie.value_type === "percentage"
                            ? ` ${beneficiarie.value}%`
                            : ` R$ ${formatNumberToCurrencyWithoutSimbol(
                                beneficiarie.value
                              )}`}{" "}
                        </p>
                        {beneficiarie.deposit_bank_account_data ? (
                          <>
                            <Spacer top={-15} />
                            <p>
                              <b> Agência:</b>{" "}
                              {beneficiarie.deposit_bank_account_data.agency} -
                              <b> Conta:</b>{" "}
                              {
                                beneficiarie.deposit_bank_account_data
                                  .account_number
                              }
                              -
                              {
                                beneficiarie.deposit_bank_account_data
                                  .account_number_digit
                              }{" "}
                            </p>
                          </>
                        ) : (
                          <>
                            <Spacer top={-15} />
                            <p>
                              <b> chave:</b>{" "}
                              {beneficiarie.deposit_pix_key_data.key}
                            </p>
                          </>
                        )}
                        <Spacer top={-17} />
                        <p>{renderDepositType[beneficiarie.deposit_type]}</p>
                        <Spacer bottom={20} />
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      label: "Valor mínimo de cobrança",
      key: "min_value",

      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-start ">
            <Description className={data.min_charge_amount ? "ps-5" : ""}>
              {data.min_charge_amount
                ? ` R$ ${formatNumberToCurrencyWithoutSimbol(
                    data.min_charge_amount
                  )}`
                : `Variável (Valores em percentual)`}
            </Description>
          </div>
        );
      },
    },

    {
      label: "",
      key: "",

      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-start ps-3 ">
            <div></div>

            <EditContainer className="me-5 d-flex hover-list-button edit-container">
              <div className="d-flex justify-content-start align-items-center">
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <ImgEye
                      style={{ height: "20px" }}
                      id={`edit-${data.id}`}
                      src={PenIcon}
                      onClick={() => {
                        setSplitData(data);

                        localStorage.setItem(
                          "edit_split_data",
                          JSON.stringify(data)
                        );
                        navigate(
                          "/app/myspace/collection-management/split/edit"
                        );
                      }}
                    />
                  </div>
                  <Spacer left={10} />
                  <div>
                    <ImgDelete
                      style={{ height: "20px" }}
                      id={`delete-${data.id}`}
                      src={DeleteIcon}
                      onClick={() => {
                        setSplitData(data);

                        setOpenDeleteSplitModal(true);
                      }}
                    />
                  </div>
                </>
                <NotControlledToolTip
                  placement="top"
                  target={`delete-${data.id}`}
                >
                  {"Excluir"}
                </NotControlledToolTip>

                <NotControlledToolTip
                  placement="top"
                  target={`edit-${data.id}`}
                >
                  {"Editar"}
                </NotControlledToolTip>
              </div>
            </EditContainer>
          </div>
        );
      },
    },
  ];

  const getSplits = async () => {
    try {
      const { data } = await ApiService.HttpGet<APIResponse<getSplit>>({
        route: "charge/split/",
        token: true,
      });

      setSplitsData(data.results);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    getSplits();
  }, []);

  return (
    <div className="pt-5">
      {splitsData.length > 0 ? (
        <Table data={splitsData} columns={columns} dataSpacer={10} />
      ) : (
        <div className="pt-5">
          {splitsData.length > 0 ? (
            <Table data={splitsData} columns={columns} dataSpacer={10} />
          ) : (
            <div className="w-100  pt-3  d-flex flex-column align-items-center justify content-start">
              <Rounded className="rounded-circle">
                {" "}
                <img src={Timer} />
              </Rounded>

              <Spacer bottom={60} />
              <Title>Você não possui padrões de Split</Title>

              <Spacer bottom={10} />
              <Description className="text-center">
                Para cadastrar padrões de split, clique no botão{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  <br />
                  Cria arranjo
                </span>{" "}
                localizado no canto superior <br /> direito.
              </Description>
              <Spacer bottom={30} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SplitList;
