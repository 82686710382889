import {
  InputCurrency,
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";
import Title from "~/components/Texts/Title";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Paginator, { applyPagination } from "~/components/Paginator";
import TableLoader from "~/components/SkeletonTables";
import useTariffs from "~/hooks/useTariffs";
import ApiService from "~/services/api";
import { PackpageTypes } from "~/types";
import SucessIcon from "~/assets/Checkmark--filled.svg";
import {
  convertStringToNumber,
  formatCash,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import {
  ImgEye,
  ImgSuccess,
  scaleAnimation,
} from "~/pages/App/app-content.styles";
import EditIcon from "~/assets/icons/round_edit_black_24dp.png";
import OkIcon from "~/assets/ok.png";
import useToast from "~/hooks/useToast";
import theme from "~/themes/theme";

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .rounded-circle {
    width: 40px;
  }

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }
`;

const IconOk = styled.p`
  font-size: 25px;
  font-weight: bold;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;
  filter: ${() => theme.colors.gray.dark500Filter};

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.info.mainFilter};
  }
`;

const RenderTariffs = () => {
  const {
    setTariffsData,
    tariffsData,
    rowsPerPage,
    setPage,
    page,
    setDataCreatePackpageTariffs,
  } = useTariffs();
  const { showToast } = useToast();

  const params = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [payloadEditValueTariff, setPayloadEditValueTariff] = useState<any>({});
  const inputRef = useRef<any>(null);

  const columnsTariffs = [
    {
      label: "Nome",
      key: "name",

      renderData: (data: any) => {
        return (
          <ContainerRenderData>
            <Title>{data.name}</Title>
          </ContainerRenderData>
        );
      },
    },
    {
      label: "Valor base (Sua taxa)",
      key: "baas_tax",

      renderData: (data: any) => {
        return <>{formatCash(data.baas_tax)} </>;
      },
    },
    {
      label: "Valor do cliente",
      key: "customer_tax_out",
      renderData: (data: any) => {
        const isEditing = editingRowId === data.id;

        return isEditing ? (
          <div className="d-flex align-items-center justify-content-start">
            <div style={{ maxWidth: "120px" }}>
              <InputCurrency
                autoFocus={true}
                ref={inputRef}
                inputSize="normal"
                name={`tariff-value-${data.id}`}
                value={formatNumberToCurrencyWithoutSimbol(
                  data?.customer_tax_out
                )}
                onChange={(e) => handleEditChange(data.id, e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    fetchEditTariffValue();
                  }
                }}
              />
            </div>
            <Spacer left={20} />

            <ImgSuccess
              id={`success-${data.id}`}
              style={{ position: "relative", bottom: "8px" }}
              src={SucessIcon}
              onClick={() => fetchEditTariffValue()}
            />
            <NotControlledToolTip placement="top" target={`success-${data.id}`}>
              Confirmar
            </NotControlledToolTip>
          </div>
        ) : (
          <div className="d-flex justify-content-around ps-2">
            {
              <p>
                R$ {formatNumberToCurrencyWithoutSimbol(data.customer_tax_out)}
              </p>
            }

            <ImgEye
              id={`edit-${data.id}`}
              style={{ position: "relative" }}
              src={EditIcon}
              onClick={() => {
                setEditingRowId(data.id);

                setPayloadEditValueTariff({
                  id: data.id,
                  customer_tax_out: data.customer_tax_out,
                });
              }}
            />
            <NotControlledToolTip placement="top" target={`edit-${data.id}`}>
              Editar
            </NotControlledToolTip>
          </div>
        );
      },
    },
    {
      label: "Lucro",
      key: "profit",

      renderData: (data: any) => {
        const isEditing = editingRowId === data.id;
        return (
          <>
            {formatCash(
              isEditing
                ? payloadEditValueTariff.customer_tax_out - data.baas_tax
                : data.customer_tax_out - data.baas_tax
            )}
          </>
        );
      },
    },
  ];

  const handleEditChange = (id: number, value: any, event: any = null) => {
    setPayloadEditValueTariff({
      id: id,
      customer_tax_out: convertStringToNumber(value),
    });

    if (event && event.key === "Enter") {
      setEditingRowId(null);
    }
  };

  const getTariffs = useCallback(async () => {
    setIsLoading(true);
    setTariffsData([]);
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/",
        token: true,
      });

      const dataFiltered = data.results.filter(
        (packpage: PackpageTypes) => packpage.id === Number(params.id)
      );

      setTariffsData(dataFiltered[0].taxes.sort());
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, []);

  const fetchEditTariffValue = useCallback(async () => {
    setIsLoading(true);
    setEditingRowId(null);

    try {
      const dataEdit = ApiService.HttpPut({
        route: `backoffice/tariffs-package/${params.id}/update-tariffs/`,
        token: true,
        body: { tariffs: [payloadEditValueTariff] },
      });

      setTimeout(async () => {
        const { data } = await ApiService.HttpGet({
          route: "backoffice/tariffs-package/",
          token: true,
        });

        const dataFiltered = data.results.filter(
          (packpage: PackpageTypes) => packpage.id === Number(params.id)
        );

        setTariffsData(dataFiltered[0].taxes.sort());

        setIsLoading(false);
        showToast("Valor de tarifa atualizado com sucesso", "", "success");
      }, 2000);
    } catch {
      setIsLoading(false);
      showToast("Nao foi possível atualizar o valor da tarifa", "", "error");
    }
  }, [payloadEditValueTariff]);

  useEffect(() => {
    const tariffsIdsAndValues = tariffsData.map((tariff: any) => ({
      id: tariff.id,
      customer_tax_out: tariff.customer_tax_out,
    }));

    setDataCreatePackpageTariffs(tariffsIdsAndValues);

    getTariffs();
  }, []);

  useEffect(() => {
    if (editingRowId !== null && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingRowId, inputRef]);

  return (
    <div className="mt-5">
      <div>
        {isLoading ? (
          <TableLoader />
        ) : (
          <Table
            haveHeader={true}
            columns={columnsTariffs}
            data={applyPagination(tariffsData.sort(), {
              page,
              rowsPerPage,
            })}
          />
        )}
      </div>
      <Paginator
        list={tariffsData.length > 0 ? tariffsData : []}
        rowsPerPage={rowsPerPage}
        page={page}
        pageSetter={setPage}
      />
    </div>
  );
};

export default RenderTariffs;
