import { AppLabel } from "~/types";
import defaultLabel from "../default/default";

import logo from "./logo.png";
import logoMenu from "./Engraxa-Pay-150x150_White.png";
import logoBlack from "./Engraxa-Pay-Horizontal-Color-Gde.png";
import backgroundLogin from "./mountain-7690893_1920.jpg";

const year = new Date().getFullYear();

const label: AppLabel = {
  ...defaultLabel,
  name: "Engraxa Pay",
  description: "Bank",
  prefix: "engraxapay",
  footerText: `Engraxa Pay - Todos os direitos reservados ${year}`,

  logoText: "Engraxa Pay",
  helpEmail: "suporte@engraxapay.com.br",
  helpPhone: "(11) 9999-9999",
  sac: "0800 000 0000",
  helpRamal: "",
  helpSupportTime:
    "Consultas, informações, reclamações e transações, de segunda a sexta, das 08h às 17h.",
  logoMenu: logoMenu,
  logo: logoBlack,
  logoHorizontal: logoBlack,
  logoMenuWidth: "70px",
  backgroundLoginImage: backgroundLogin,
  logoSignup: logoBlack,
  clarity: "jy7gacqy1q",
  backgroundPositionX: "center",

  devBankCardAPI: "https://dev.api.card.zillion.digital",
  devAPI: "https://api.prosperita.ibanks.app.br",
  stageAPI: "https://stage.api.bank.zillion.digital",
  productionAPI: "https://api.prosperita.ibanks.app.br",
};

export default label;
