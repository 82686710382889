import axios from "axios";
import label from "~/labels";
import AppStorage from "~/services/storage";

// export const baseURL = "https://v2.api.bank.prosperita.io";
// export const baseURL = "http://localhost:8000";

export const getAPIBaseURL = () => {
  const location = window.location.href;

  if (location.includes("localhost") || location.includes("dev")) {
    return label.devBankCardAPI;
  }

  if (location.includes("stage") || location.includes("staging")) {
    return label.devBankCardAPI;
  }

  return label.devBankCardAPI; //TODO
};

export const baseURL = getAPIBaseURL();

export const api = axios.create({
  baseURL,
});

export type HttpBaseProps = {
  route: string;
  params?: any;
  noDefautHeaders?: boolean;
  customHeaders?: object;
  external?: boolean;

  token?: boolean;
};

export type HttpBodyProps = {
  body?: any;
};

export type GetUrl = HttpBaseProps;
export type HttpGetProps = HttpBaseProps;
export type HttpDeleteProps = HttpBaseProps;

export type HttpPostProps = HttpBaseProps & HttpBodyProps;
export type HttpPutProps = HttpBaseProps & HttpBodyProps;
export type HttpPatchProps = HttpBaseProps & HttpBodyProps;

const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const getHeaders = (
  token: boolean,
  noDefaultHeaders: boolean,
  customHeaders = {}
) => {
  const baseHeaders = noDefaultHeaders ? {} : defaultHeaders;
  const headers = {
    ...baseHeaders,
    ...customHeaders,
  };

  if (token) {
    let tokenString = AppStorage.getAnyToken();

    return {
      ...headers,
      Authorization: `Token ${tokenString}`,
    };
  } else {
    return headers;
  }
};

const getUrl = (props: GetUrl): string => {
  const { route, params, external = false } = props;

  const url = external ? route : `${baseURL}/${route}`;

  let urlString = url;

  if (params) {
    urlString += "?";

    const paramsArr = Object.keys(params).map((key) => {
      const paramValue = params[key];
      return `${key}=${paramValue}`;
    });

    urlString += paramsArr.join("&");
  }

  return urlString;
};

const HttpGet = async <T = any>(props: HttpGetProps) => {
  const { token = false, noDefautHeaders, customHeaders = {} } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.get<T>(url, {
    headers,
  });
};

const HttpPost = async <T = any>(props: HttpPostProps) => {
  const {
    body = {},
    token = false,
    noDefautHeaders,
    customHeaders = {},
  } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.post<T>(url, body, {
    headers,
  });
};

const HttpPut = async <T = any>(props: HttpPutProps) => {
  const {
    body = {},
    token = false,
    noDefautHeaders,
    customHeaders = {},
  } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.put<T>(url, body, {
    headers,
  });
};

const HttpDelete = async <T = any>(props: HttpDeleteProps) => {
  const { token = false, noDefautHeaders, customHeaders = {} } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.delete<T>(url, {
    headers,
  });
};

const HttpPatch = async <T = any>(props: HttpPatchProps) => {
  const {
    body = {},
    token = false,
    noDefautHeaders,
    customHeaders = {},
  } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.patch<T>(url, body, {
    headers,
  });
};

const BankCardAPIService = {
  HttpGet,
  HttpPost,
  HttpPut,
  HttpDelete,
  HttpPatch,
};

export default BankCardAPIService;
