import { Spacer } from "@zilliondigital/mirage-ui";
import Loader from "~/components/Loader";
import { motion } from "framer-motion";

const LoadingTransferPix = () => {
  return (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "-100%" }}
        transition={{ duration: 0.3 }}
        className="vh-100"
      >
        {" "}
        <Spacer bottom={50} />
        <div className="ps-3">
          <Loader
            x=""
            y=""
            rx="8"
            ry="8"
            widthContainer="540"
            isHeight="10"
            isWidth="540"
            box="0 0 540 10"
            heightContainer="10"
          />
        </div>
        <Spacer bottom={20} />
        <div className="d-flex w-100 ">
          <div className="ps-3">
            <Loader
              x=""
              y=""
              rx="8"
              ry="8"
              widthContainer="398"
              isHeight="56"
              isWidth="398"
              box="0 0 398 56"
              heightContainer="56"
            />
          </div>
          <div className="ps-3">
            <Loader
              x=""
              y=""
              rx="8"
              ry="8"
              widthContainer="122"
              isHeight="56"
              isWidth="122"
              box="0 0 122 56"
              heightContainer="56"
            />
          </div>
        </div>
        <Spacer bottom={10} />
        <div className="ps-3">
          <Loader
            x=""
            y=""
            rx="8"
            ry="8"
            widthContainer="261"
            isHeight="6"
            isWidth="261"
            box="0 0 261 6"
            heightContainer="6"
          />
        </div>
        <Spacer bottom={150} />
        <div className="d-flex justify-content-center w-100 ">
          <Loader
            x=""
            y=""
            rx=""
            ry=""
            widthContainer="530"
            isHeight="10"
            isWidth="530"
            box="0 0 530 10"
            heightContainer="10"
          />
        </div>
        <Spacer bottom={20} />
        <div className="d-flex justify-content-center w-100 ">
          <Loader
            x=""
            y=""
            rx=""
            ry=""
            widthContainer="530"
            isHeight="10"
            isWidth="530"
            box="0 0 530 10"
            heightContainer="10"
          />
        </div>
        <Spacer bottom={20} />
        <div className="d-flex justify-content-center w-100 ">
          <Loader
            x=""
            y=""
            rx=""
            ry=""
            widthContainer="530"
            isHeight="10"
            isWidth="530"
            box="0 0 530 10"
            heightContainer="10"
          />
        </div>
        <Spacer bottom={20} />
        <div className="d-flex justify-content-center w-100 ">
          <Loader
            x=""
            y=""
            rx=""
            ry=""
            widthContainer="530"
            isHeight="10"
            isWidth="530"
            box="0 0 530 10"
            heightContainer="10"
          />
        </div>
        <Spacer bottom={20} />
        <div className="d-flex justify-content-center w-100 ">
          <Loader
            x=""
            y=""
            rx=""
            ry=""
            widthContainer="530"
            isHeight="10"
            isWidth="530"
            box="0 0 530 10"
            heightContainer="10"
          />
        </div>
        <Spacer bottom={20} />
      </motion.div>
    </>
  );
};

export default LoadingTransferPix;
