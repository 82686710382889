import {
  Button,
  Input,
  InputCurrency,
  NotControlledToolTip,
  Spacer,
  Toggle,
} from "@zilliondigital/mirage-ui";
import { useEffect, useMemo, useState } from "react";
import useSplit from "~/hooks/useSplit";
import * as Yup from "yup";
import { useFormik } from "formik";
import Description from "~/components/Texts/Description";
import { convertedAmount, onlyNumbers } from "~/utils";
import ApiService from "~/services/api";
import MoreIcon from "~/assets/icons/buttons/more.png";
import styled from "styled-components";
import useToast from "~/hooks/useToast";
import { APIResponse, CreateSplit, addBeneficiarie } from "~/types";
import DeleteIcon from "~/assets/icons/delete.png";
import theme from "~/themes/theme";
import { ImgDelete } from "~/pages/App/Permissions/NewConcessionModal";

const ContainerReceivers = styled.div`
  height: 500px;
  overflow-y: auto;
  margin: 20px 0 0 20px;
`;

type SplitAmount = {
  amount: string[];
  over_amount: string[];
  discount_amount: string[];
};

const initialValues: SplitAmount = {
  amount: [],
  over_amount: [],
  discount_amount: [],
};

const FormSchema = Yup.object().shape({
  amount: Yup.array().of(Yup.string()),
  over_amount: Yup.array().of(Yup.string()),
  discount_amount: Yup.array().of(Yup.string()),
});

type renderDepositType = {
  [key: string]: string;
};

const renderDepositType: renderDepositType = {
  pix_transfer: "Transferência PIX",
  pix_manual_transfer: "Transferência PIX",
  tev: "TEV - Transferência entre contas",
  ted: "Tranferência TED",
};

const NewSplitPercentOrAmount = () => {
  const [isLoading, setIsloading] = useState<boolean>(false);

  const {
    newSplitPayload,
    setStepIndex,
    setNewSplitPayload,
    receivers,
    setReceivers,
    setDepositIsByKeyPix,
    setSplitsData,
    depositIsByKeyPix,
    percentOrAmount,
    setPercentOrAmount,
    setOpenNewSplitModal,
    openNewSplitModal,
    isEditing,
    splitData,
    setSplitData,
  } = useSplit();
  const [splitOver, setSplitOver] = useState<boolean[]>(
    new Array(newSplitPayload?.receivers?.length).fill(false)
  );
  const [splitLostDiscount, setSplitLostDiscount] = useState<boolean[]>(
    new Array(newSplitPayload?.receivers?.length).fill(false)
  );

  const { showToast } = useToast();

  const handleFormSubmit = (values: any) => {
    return;
  };

  const { handleChange, setFieldValue, values, resetForm } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleFormSubmit,
  });
  const hasError = useMemo(() => {
    return values.amount.some((value: string) => Number(value) > 100);
  }, [values]);

  const moreThan100PercentAmount = useMemo(() => {
    const sum = values.amount.reduce((acc, value) => acc + Number(value), 0);
    return sum > 100;
  }, [values]);

  const moreThan100PercentOverAmount = useMemo(() => {
    const sum = values.over_amount.reduce(
      (acc, value) => acc + Number(value),
      0
    );
    return sum > 100;
  }, [values]);

  const moreThan100PercentDiscountAmount = useMemo(() => {
    const sum = values.discount_amount.reduce(
      (acc, value) => acc + Number(value),
      0
    );
    return sum > 100;
  }, [values]);

  const handleAddNewSplit = async () => {
    setIsloading(true);
    try {
      const { data } = await ApiService.HttpPost<APIResponse<CreateSplit>>({
        route: "charge/split/",
        body: newSplitPayload,
        token: true,
      });
      setIsloading(false);

      setOpenNewSplitModal(false);

      setReceivers([]);

      setPercentOrAmount("");

      setFieldValue("amount", []);

      localStorage.removeItem("splitOver");
      localStorage.removeItem("values");
      localStorage.removeItem("splitLostDiscount");

      showToast(
        "Arranjo criado",
        `O arranjo de nome ${data.data.name} foi criado com sucesso`,
        "success"
      );

      const { data: dataSplit } = await ApiService.HttpGet({
        route: "charge/split/",
        token: true,
      });

      setSplitsData(dataSplit.results);
    } catch {
      setIsloading(false);
      showToast("Não foi possível criar o arranjo", ``, "error");
      localStorage.removeItem("splitOver");
      localStorage.removeItem("values");
      localStorage.removeItem("splitLostDiscount");
    }
  };

  const handleAddNewBeneficiarie = async () => {
    setIsloading(true);
    try {
      const { data } = await ApiService.HttpPost<addBeneficiarie>({
        route: `charge/split/${splitData.id}/add-beneficiary/`,
        body: receivers[0],
        token: true,
      });
      setIsloading(false);

      setOpenNewSplitModal(false);

      setReceivers([]);

      setFieldValue("amount", []);

      setPercentOrAmount("");

      showToast("Novo beneficiário adicionado", ``, "success");

      const dataUpdated = await ApiService.HttpGet({
        route: "charge/split/",
        token: true,
      });

      setSplitData(
        dataUpdated.data.results.filter(
          (item: any) => item.id === splitData.id
        )[0]
      );
      localStorage.removeItem("values");
    } catch {
      setIsloading(false);
      showToast("Não foi possível adicionar o recebedor", ``, "error");
      localStorage.removeItem("values");
    }
  };

  const handleToggleChange = () => {
    setPercentOrAmount(percentOrAmount === "amount" ? "percentage" : "amount");
    setFieldValue(`percentage`, []);
  };

  const handleToggleModal = () => {
    setOpenNewSplitModal(!openNewSplitModal);
    resetForm();
    setReceivers([]);
    setPercentOrAmount("");
    localStorage.removeItem("splitOver");
    localStorage.removeItem("values");
    localStorage.removeItem("splitLostDiscount");
  };

  const handleRemoveReceiver = (index: number) => {
    setReceivers((prevReceivers: any) =>
      prevReceivers.filter((_: any, i: number) => i !== index)
    );
    setFieldValue(
      "amount",
      values.amount.filter((_, i) => i !== index)
    );
    setFieldValue(
      "over_amount",
      values.over_amount.filter((_, i) => i !== index)
    );
    setFieldValue(
      "discount_amount",
      values.discount_amount.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    const openedsSplitOver: any = localStorage.getItem("splitOver");
    const openedsSplitOverConverted = JSON.parse(openedsSplitOver);
    if (openedsSplitOver) {
      setSplitOver(openedsSplitOverConverted);
    }
  }, []);

  useEffect(() => {
    const openedsSplitLostDiscount: any =
      localStorage.getItem("splitLostDiscount");
    const openedsSplitLostDiscountConverted = JSON.parse(
      openedsSplitLostDiscount
    );
    if (openedsSplitLostDiscount) {
      setSplitLostDiscount(openedsSplitLostDiscountConverted);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("splitOver", JSON.stringify(splitOver));
  }, [splitOver]);

  useEffect(() => {
    localStorage.setItem(
      "splitLostDiscount",
      JSON.stringify(splitLostDiscount)
    );
  }, [splitLostDiscount]);

  useEffect(() => {
    setNewSplitPayload((prevValues: any) => ({
      ...prevValues,
      receivers: receivers,
    }));

    if (isEditing) {
      setPercentOrAmount(
        !splitData.min_charge_amount ? "percentage" : "amount"
      );
    } else {
      setPercentOrAmount(
        percentOrAmount !== "" ? percentOrAmount : "percentage"
      );
    }

    setFieldValue("amount", []);
  }, []);

  useEffect(() => {
    const valuesStorage: any = localStorage.getItem("values");
    const valuesStorageConverted = JSON.parse(valuesStorage);
    if (valuesStorageConverted) {
      setFieldValue("amount", valuesStorageConverted?.amount);
      setFieldValue("over_amount", valuesStorageConverted?.over_amount);
      setFieldValue("discount_amount", valuesStorageConverted?.discount_amount);
    }
  }, [percentOrAmount]);

  useEffect(() => {
    const localValues: any = JSON.stringify(values);
    localStorage.setItem("values", localValues);
  }, [values]);

  useEffect(() => {
    setReceivers((prevValues: any) => {
      return prevValues.map((receiver: any, index: number) => {
        return {
          ...receiver,
          value:
            percentOrAmount === "percentage"
              ? Number(values.amount[index])
              : convertedAmount(onlyNumbers(values.amount[index])),
          value_type: percentOrAmount,
          over_amount_type: "percentage",
          over_amount:
            isNaN(Number(values.over_amount[index])) ||
            values.over_amount[index] === ""
              ? 0
              : Number(values.over_amount[index]),
          discount_amount:
            isNaN(Number(values.discount_amount[index])) ||
            values.discount_amount[index] === ""
              ? 0
              : Number(values.discount_amount[index]),
          discount_amount_type: "percentage",
        };
      });
    });
  }, [values, percentOrAmount]);

  useEffect(() => {
    setNewSplitPayload((prevValues: any) => ({
      ...prevValues,
      receivers: receivers,
    }));
  }, [receivers]);

  return (
    <>
      {!isEditing && (
        <div className="d-flex ps-3 pb-3">
          <p>Valor fixo para todos </p>
          <Spacer left={10} />
          <div onClick={() => handleToggleChange()} style={{ width: "50px" }}>
            <Toggle mode={"small"} checked={percentOrAmount !== "percentage"} />
          </div>
        </div>
      )}

      {receivers.length > 0 ? (
        <ContainerReceivers>
          {newSplitPayload?.receivers?.map((receiver: any, index: number) => {
            return (
              <div
                className="ps-4 pt-3 pb-3 mt-3 "
                style={{
                  backgroundColor: theme.colors.base.light200,
                  borderRadius: "8px ",
                }}
              >
                <div className="d-flex justify-content-between">
                  <b>{receiver.name}</b>
                  <ImgDelete
                    className="me-2"
                    id={`delete${index}`}
                    src={DeleteIcon}
                    onClick={() => handleRemoveReceiver(index)}
                  />
                  <NotControlledToolTip target={`delete${index}`}>
                    Remover
                  </NotControlledToolTip>
                </div>

                <div
                  key={index}
                  className="d-flex align-items-start justify-content-center "
                >
                  {percentOrAmount === "percentage" && (
                    <>
                      <Input
                        type="text"
                        isPercentage
                        mask={"999"}
                        label={`Defina o valor a ser recebido por ${receiver.name}`}
                        name={`amount${index}`}
                        error={
                          Number(values.amount[index]) > 100
                            ? "O valor deve ser menor ou igual à 100%"
                            : moreThan100PercentAmount &&
                              "A soma dos valores percentuais ultrapassa 100%"
                        }
                        value={values.amount[index]}
                        onChange={(e) => {
                          handleChange(e);

                          values.amount[index] = e.target.value;
                          setFieldValue(`amount_[${index}]`, e.target.value);
                        }}
                      />
                    </>
                  )}

                  {percentOrAmount !== "percentage" && (
                    <InputCurrency
                      label={`Defina o valor a ser recebido por ${receiver.name}`}
                      inputSize="normal"
                      name={`amount${index}`}
                      value={"00.00"}
                      onChange={(e) => {
                        handleChange(e);
                        values.amount[index] = e.target.value;
                        setFieldValue(`amount_[${index}]`, e.target.value);
                      }}
                    />
                  )}
                  <Spacer left={25} />
                </div>
                <Spacer bottom={-5} />
                <div
                  className="p-2  me-4 "
                  style={{
                    borderRadius: "8px",
                    border: moreThan100PercentOverAmount
                      ? "1px solid red"
                      : "none",
                    background: theme.colors.base.light300,
                    height: splitOver[index] ? "auto" : "55px",
                  }}
                >
                  <div className="d-flex justify-content-between p-2">
                    <p
                      style={{
                        color: moreThan100PercentOverAmount ? "red" : "",
                      }}
                    >
                      {" "}
                      Split de acréscimo (Juros/Multa)
                    </p>

                    <Toggle
                      mode="small"
                      checked={splitOver[index]}
                      onToggle={() => {
                        const newSplitOver = [...splitOver];
                        newSplitOver[index] = !newSplitOver[index];
                        setSplitOver(newSplitOver);
                      }}
                    />
                  </div>
                  {splitOver[index] && (
                    <div className="pe-4">
                      <Input
                        label={`Defina a porcentagem do Split de acréscimo (Juros/Multa)`}
                        isPercentage
                        type="text"
                        mask={"999"}
                        error={
                          Number(values.over_amount[index]) > 100
                            ? "O valor deve ser menor ou igual à 100%"
                            : moreThan100PercentOverAmount &&
                              "A soma dos valores percentuais do split de acréscimo (Juros/Multa) ultrapassa 100%"
                        }
                        name={`over_amount${index}`}
                        value={values.over_amount[index]}
                        onChange={(e) => {
                          handleChange(e);
                          values.over_amount[index] = e.target.value;
                          setFieldValue(
                            `over_amount_[${index}]`,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <Spacer bottom={20} />
                <div
                  className="p-2  me-4 "
                  style={{
                    borderRadius: "8px",
                    background: theme.colors.base.light300,
                    height: splitLostDiscount[index] ? "auto" : "55px",
                  }}
                >
                  <div className="d-flex justify-content-between p-2">
                    <p> Split de perca de desconto</p>{" "}
                    <Toggle
                      mode="small"
                      checked={splitLostDiscount[index]}
                      onToggle={() => {
                        const newSplitLostDiscount = [...splitLostDiscount];
                        newSplitLostDiscount[index] =
                          !newSplitLostDiscount[index];
                        setSplitLostDiscount(newSplitLostDiscount);
                      }}
                    />
                  </div>
                  {splitLostDiscount[index] && (
                    <div className="pe-4">
                      <Input
                        label={`Defina a porcentagem do Split de perda de desconto`}
                        isPercentage
                        type="text"
                        mask={"999"}
                        error={
                          Number(values.discount_amount[index]) > 100
                            ? "O valor deve ser menor ou igual à 100%"
                            : moreThan100PercentDiscountAmount &&
                              "A soma dos valores percentuais do split de perca de desconto ultrapassa 100%"
                        }
                        name={`discount_amount${index}`}
                        value={values.discount_amount[index]}
                        onChange={(e) => {
                          handleChange(e);
                          values.discount_amount[index] = e.target.value;
                          setFieldValue(
                            `discount_amount_[${index}]`,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                {receiver?.bank_name ? (
                  <div className="d-flex flex-column mb-5 mt-3">
                    <Description>
                      <b>Banco: </b>
                      {""}
                      {receiver.bank_code} - {receiver.bank_name}
                    </Description>

                    <Description>
                      <b>Agência: </b>
                      {""}
                      {receiver.agency} - <b>Conta: </b>
                      {""}
                      {receiver.bank_account_with_digit}
                    </Description>
                    <Description>
                      {renderDepositType[receiver.deposit_type]}
                    </Description>
                    <Spacer bottom={-50} />
                  </div>
                ) : (
                  <div className="d-flex flex-column mb-5">
                    <Spacer top={20} />
                    <Description>
                      <b>Chave: </b>
                      {""}
                      {receiver.key}
                    </Description>
                    <Description>
                      {renderDepositType[receiver.deposit_type]}
                    </Description>
                    <Spacer bottom={-50} />
                  </div>
                )}
              </div>
            );
          })}
        </ContainerReceivers>
      ) : (
        <p className="text-center mt-5">
          Este arranjo ainda não possui beneficiários adicionados.
        </p>
      )}
      <Spacer bottom={100} />

      <div className="d-flex justify-content-between mt-5">
        <Button
          mode="phantom"
          label={"Cancelar "}
          onClick={() => {
            handleToggleModal();
          }}
        />
        <div className="d-flex">
          {!isEditing && (
            <Button
              mode="border"
              label={"Adicionar recebedor"}
              icon={MoreIcon}
              iconPosition="right"
              onClick={() => {
                setDepositIsByKeyPix(false);
                setStepIndex(1);
              }}
            />
          )}
          <Spacer left={20} />
          <Button
            mode="main"
            label={isEditing ? "Adicionar recebedor" : "Finalizar arranjo"}
            loading={isLoading}
            disabled={
              values.amount.length !== receivers.length ||
              values.amount.includes("0,00") ||
              values.amount.includes("0.00") ||
              values.amount.includes("") ||
              receivers.length === 0 ||
              hasError ||
              moreThan100PercentAmount ||
              moreThan100PercentOverAmount ||
              moreThan100PercentDiscountAmount
            }
            onClick={() =>
              isEditing ? handleAddNewBeneficiarie() : handleAddNewSplit()
            }
          />
        </div>
      </div>
    </>
  );
};

export default NewSplitPercentOrAmount;
