import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  NotControlledToolTip,
  ReturnButton,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";
import SuccessIcon from "~/assets/icons/status/done_black_24dp (1).svg";
import XIcon from "~/assets/icons/status/done_black_24dp.png";
import ErrorIcon from "~/assets/icons/status/error.png";

import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import { getFirstLetters } from "~/services/utils";
import PencilIcon from "~/assets/icons/buttons/pencil.png";
import { Fragment, useEffect, useMemo, useState } from "react";
import Copyright from "~/components/Copyright";
import theme from "~/themes/theme";
import label from "~/labels";
import ApiService from "~/services/api";
import { ApiResponse, Columns, getKeyPixContact } from "~/types";
import { useNavigate, useParams } from "react-router-dom";
import useToast from "~/hooks/useToast";
import Badge from "~/components/Badge";
import { formatCPFCNPJ, formatCash } from "~/utils";

type BankAccount = {
  agency: string;
  account_number: string;
  account_number_digit: string;
  bank_code: string;
  bank_data: {
    name: string;
  };
  type: string;
};

const GeneralContainerData = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  height: 100%;
  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .name {
    font-size: 27px;
    font-weight: 400;
  }

  .profile-picture {
    width: 64px;
    height: 64px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }

  .pix-mykeys-image {
    height: 24px;
    filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(0%)
      hue-rotate(135deg) brightness(103%) contrast(105%);
    cursor: pointer;
  }

  .container-btn-more-details {
    position: relative;
    top: 8px;
    left: 75%;
  }

  .copy-icon {
    position: relative;
    top: 8px;
    left: 70%;
  }

  .container-return-button {
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const statusLabelDictionary: { [key: string]: string } = {
  validating: "Validando",
  created: "Criado",
  processing: "Processando",
  waiting_approval: "Aguardando aprovação",
  done: "Concluído",
  error: "Erro",
  completed: "Completo",
  completed_with_errors: "Completo com Errros",
  failed: "Erro",
};

const getStatus: { [key: string]: JSX.Element } = {
  waiting_approval: (
    <Badge
      label="Em aprovação"
      color={theme.colors.base.light200}
      textColor={theme.colors.base.main}
    />
  ),
  pending: <Badge label="Pendente" color={"#FEE7CC"} textColor={"#FC8802"} />,
  processing: (
    <Badge label="Processando" color={"#FEE7CC"} textColor={"#FC8802"} />
  ),

  done: (
    <Badge
      label="Concluído"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  completed: (
    <Badge
      label="Concluído"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),

  schedule: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  scheduled: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  canceled: (
    <Badge
      label="Cancelado"
      color={theme.colors.error.light200}
      textColor={theme.colors.error.main}
    />
  ),
  refused: (
    <Badge
      label="Reprovado"
      color={theme.colors.error.light200}
      textColor={theme.colors.error.main}
    />
  ),
  error: (
    <div
      style={{
        width: "45px",
        height: "23,4px",
        display: "flex",

        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img style={{ height: "23,4px" }} src={ErrorIcon} />{" "}
    </div>
  ),
  failed: (
    <div
      style={{
        width: "45px",
        height: "23,4px",
        display: "flex",

        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img style={{ height: "23,4px" }} src={ErrorIcon} />{" "}
    </div>
  ),
};

const PaymentLotDetails = () => {
  const navigate = useNavigate();

  const [detailsData, setDetailsData] = useState<any>([]);

  const params = useParams<{ id: string }>();

  const { showToast } = useToast();

  const menusBreadCrumb = [
    {
      label: "Pagamentos em lote",
      onClick: () => navigate("/app/payments-lot"),
    },
    { label: "Detalhes", href: "" },
  ];

  const getPixLotDetails = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: `pix/mass/transfer/${params.id}/operations/  `,
        token: true,
      });

      setDetailsData(data.data);
    } catch {
      showToast("Ocorreu um erro", "", "error", () => null, false);
      navigate("/app/pix-lot");
    }
  };

  const columnsDetails: Columns[] = [
    {
      key: "status",
      label: "Status",
      renderData: (data: any) => (
        <div
          className=""
          style={{ maxWidth: "130px" }}
          id={`dot-status-${data.id}`}
        >
          {getStatus[data.status]}
          <NotControlledToolTip
            placement="top"
            target={`dot-status-${data.id}`}
          >
            {statusLabelDictionary[data.status]}
          </NotControlledToolTip>
        </div>
      ),
    },

    {
      label: "Nome",
      key: "name",

      renderColumn: (data: any) => {
        return (
          <div className="ps-5">
            <Title className="fw-bold">Nome</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div className="ms-5 ps-2 d-flex flex-column">
            <Description>{data.receiver_account.owner.name}</Description>
            <Description color="gray">
              {formatCPFCNPJ(data.receiver_account.owner.document_number)}
            </Description>
          </div>
        );
      },
    },
    {
      label: "Valor",
      key: "value",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Valor</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Description>{formatCash(data.amount)}</Description>
          </div>
        );
      },
    },
    {
      label: "Banco",
      key: "Bank",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Banco</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Description>
              {data.receiver_account.bank_data.name ?? "-"}
            </Description>
          </div>
        );
      },
    },

    {
      label: "Agencia",
      key: "Agency",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Agência</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Description>{data.receiver_account.agency ?? "-"}</Description>
          </div>
        );
      },
    },
    {
      label: "Conta",
      key: "account",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Conta</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Description>{`${data.receiver_account.account_number ?? "-"}-${
              data.receiver_account.account_number_digit ?? "-"
            }`}</Description>
          </div>
        );
      },
    },
    {
      label: "Descrição",
      key: "desxription",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Descrição</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Description>{data.description ?? "-"}</Description>
          </div>
        );
      },
    },
    {
      label: "error",
      key: "error",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Mens. de erro</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Description>{data.status_description ?? "-"}</Description>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getPixLotDetails();
  }, [params.id]);

  return (
    <Fragment>
      <Fragment>
        {detailsData.length > 0 && (
          <>
            <GeneralContainerData>
              <div className="d-flex flex-column container w-100 h-100 ">
                <Spacer top={30} />
                <div className=" row ">
                  <Breadcrumb menus={menusBreadCrumb} />
                  <Spacer bottom={30} />
                  <ContainerData className="p-4">
                    <Title>Detalhes</Title>
                    <Spacer bottom={20} />
                    <Table data={detailsData} columns={columnsDetails} />
                  </ContainerData>

                  <Spacer bottom={20} />
                </div>

                <Spacer top={40} />

                <Copyright />

                <Spacer top={30} />
              </div>
            </GeneralContainerData>
          </>
        )}
      </Fragment>
    </Fragment>
  );
};

export default PaymentLotDetails;
