import {
  // ButtonMoreDetails,
  Checkbox,
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import BilletIcon from "~/assets/icons/Type/billet.png";
import PIXIcon from "~/assets/icons/Type/ic_context_payment_pix.png";
import TEDOrTEVIcon from "~/assets/icons/Type/ted.png";
import EyeIcon from "~/assets/icons/eye-Size=MD.svg";

import { applyPagination } from "~/components/Paginator";
import TableLoader from "~/components/SkeletonTables";
import useAccount from "~/hooks/useAccount";
import useStatements from "~/hooks/useStatements";
import SuccessIcon from "~/assets/icons/status/done_black_24dp (1).svg";

import theme from "~/themes/theme";
import { formatCash, formatDateTime, formatDateTimeNew } from "~/utils";
import StatementsEmpty from "../Statements/StatementsEmpty";
import Badge from "~/components/Badge";
import ApiService from "~/services/api";

const scaleAnimation = keyframes`
    0% {
      transform: scale(1);
     
      background-color: initial;
    }
  
    100% {
      transform: scale(1.2);
    
    
    }
  `;

const ImgEye = styled.img`
  height: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;
  filter: ${() => theme.colors.gray.dark500Filter};

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.info.mainFilter};
  }
`;

const getStatusImage = (data: any) => {
  // Entrada de dinheiro
  if (data.amount > 0) {
    // Identificar casos de estorno e cancelamento e colocar status warning
    const WARNING_TYPELIST = [3, 2, 43, 40];

    // 3 - Estorno de TED (RefundTED)
    // 2 - Cancelamento de conta (CancelBoletoOut)
    // 43 - Devolução via Pix (CreditRefundPixOut)
    // 40 - Estorno de Pagamento Pix (CancelDebitPixOut)

    if (WARNING_TYPELIST.includes(data.operation_type_id)) {
      return (
        <Badge
          label="Recebido"
          color={"#CCEFDF"}
          textColor={"#00B360"}
          icon={SuccessIcon}
        />
      );
    }
    return (
      <Badge
        label="Recebido"
        color={"#CCEFDF"}
        textColor={"#00B360"}
        icon={SuccessIcon}
      />
    );
  } else {
    // SAIDA de dinheiro
    return (
      <Badge
        label="Pago"
        color={"#CCEFDF"}
        textColor={"#00B360"}
        icon={SuccessIcon}
      />
    );
  }
};

type DescriptionImageType = {
  [key: number]: string;
};

const DescriptionImage: DescriptionImageType = {
  0: BilletIcon,
  2: BilletIcon,
  3: TEDOrTEVIcon,
  6: TEDOrTEVIcon,
  40: PIXIcon,
  41: PIXIcon,
  43: PIXIcon,
};

const StatementsTransactionsDash = () => {
  const {
    selectedVouchers,
    setSelectedVouchers,
    statusColumnSelected,
    setStatusColumnSelected,
    setCurrentModalData,
    setOpenStatementsDetailsModal,

    rowsPerPage,
    page,
    getDescription,
  } = useStatements();

  const [statements, setStatements] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [isLoadingTransactions, setIsLoadingTransactions] =
    useState<boolean>(false);

  const [url, setUrl] = useState<string>("");

  const { formatNumberToCurrency } = useAccount();

  const handleToggleVoucher = (data: any) => {
    const voucherToAdd = data.voucher;

    if (voucherToAdd !== undefined && voucherToAdd !== null) {
      if (selectedVouchers.includes(voucherToAdd)) {
        setSelectedVouchers(
          selectedVouchers.filter((voucher: string) => voucher !== voucherToAdd)
        );
      } else {
        setSelectedVouchers([...selectedVouchers, voucherToAdd]);
      }
    }
  };

  const getStatements = async (fastParams = {}) => {
    setStatements([]);
    setIsLoadingTransactions(true);

    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/statement/",
        token: true,
        params: {
          limit: 5,
        },
      });

      if (data && data.results.length > 0) {
        setStatements(data.results.reverse().slice(0, 10));
        setStatistics(data.statistics);

        setIsLoadingTransactions(false);
      }

      setIsLoadingTransactions(false);
    } catch (error) {
      console.warn("err", error);
    }
    setIsLoadingTransactions(false);
  };

  useEffect(() => {
    getStatements();
    setSelectedVouchers([]);
  }, []);

  const handleStatusColumnToggle = () => {
    const transactions = applyPagination(data, { page, rowsPerPage });

    const selectedVouchersForStatusColumn = statusColumnSelected
      ? []
      : transactions
          .filter(
            (transaction) =>
              transaction.voucher !== null && transaction.voucher !== undefined
          )
          .map((transaction) => transaction.voucher);

    setSelectedVouchers(selectedVouchersForStatusColumn);
    setStatusColumnSelected(!statusColumnSelected);
  };

  const handleCancelAllSelections = () => {
    setSelectedVouchers([]);
    setStatusColumnSelected(false);
  };

  const columnsStatements: any = [
    {
      label: "Status",
      key: "status",
      width: "100px",

      renderData: (data: any) => (
        <>
          {data.type !== "DayClose" ? (
            <div
              className="d-flex justify-content-start align-items-center"
              onClick={() => handleToggleVoucher(data)}
            >
              <div className="d-flex flex-grow-1 justify-content-center pe-3 ps-3">
                {getStatusImage(data)}
              </div>
            </div>
          ) : (
            <div
              style={
                data.amount.length > 8
                  ? { fontSize: "14px" }
                  : { fontSize: "15px" }
              }
            >
              <b>{formatDateTimeNew(data.date)}</b>
            </div>
          )}{" "}
        </>
      ),
    },

    {
      label: "Descrição",
      key: "description",
      renderData: (data: any) =>
        data.type !== "DayClose" && (
          <div className="align-items-center d-flex">
            <img
              style={
                data.type_id === 2 || data.type_id === 0
                  ? { height: "16px" }
                  : { height: "24px" }
              }
              src={DescriptionImage[data.type_id]}
              className="me-2"
            />
            {getDescription(data)}
          </div>
        ),
    },
    {
      label: "Data da transação",
      key: "transacation_date",
      renderColumn: (data: any) => (
        <div className="align-items-center">
          <p>Data da transação</p>
        </div>
      ),
      renderData: (data: any) =>
        data.type !== "DayClose" && (
          <div className=" ">{formatDateTime(data.entry_date)}</div>
        ),
    },
    {
      label: "Créditos",
      key: "amount",
      align: "right",
      renderColumn: (data: any) => (
        <div className=" align-items-center d-flex">
          <p>Créditos</p>
        </div>
      ),
      renderData: (data: any) => {
        return (
          data.type !== "DayClose" && (
            <div
              className="d-flex justify-content-center"
              style={{
                direction: "rtl",
              }}
              onClick={() => {
                setUrl(data.voucher);
              }}
            >
              {data.amount >= 0
                ? `${formatNumberToCurrency(data.amount)}+`
                : "—"}
            </div>
          )
        );
      },
    },
    {
      label: "Débitos",
      key: "amount",
      align: "right",
      renderColumn: (data: any) => (
        <div className=" align-items-center d-flex">
          <p>Débitos</p>
        </div>
      ),
      renderData: (data: any) => {
        return (
          data.type !== "DayClose" && (
            <div
              className="d-flex justify-content-center "
              style={{
                direction: "rtl",
              }}
              onClick={() => {
                setUrl(data.voucher);
              }}
            >
              {data.amount < 0
                ? `${formatNumberToCurrency(data.amount).replace("-", "")}-`
                : "—"}
            </div>
          )
        );
      },
    },
    {
      label: "Identificação",
      key: "identification",
      renderColumn: (data: any) => (
        <div className="ps-5 align-items-center d-flex">
          <p>Identificação</p>
        </div>
      ),
      renderData: (data: any) =>
        data.type !== "DayClose" && (
          <div className="ps-5 ms-2  ">{data.identifier}</div>
        ),
    },
    {
      label: "",

      renderData: (data: any) => {
        return data.type !== "DayClose" ? (
          <div className="ps-3 pe-3"></div>
        ) : (
          <div className="d-flex align-items-center text-nowrap ">
            <b
              style={
                data.amount.length > 8
                  ? { fontSize: "14px" }
                  : { fontSize: "15px" }
              }
            >
              Saldo: {formatCash(data.amount)}
            </b>
          </div>
        );
      },
    },
  ];

  // const handleOpenReceipt = () => {
  //   window.open(url, "_blank");

  //   // setOpenStatementsDetailsModal(true);
  // };

  const data = statements.map((transaction: any, index: number) => ({
    ...transaction,
    tags: transaction.tags,
    status: "paid",
    type_id: transaction.operation_type_id,
    description: transaction.description,
    identifier: transaction.identifier,
    amount: transaction.amount,
    voucher: transaction.voucher_url,
    movement_type: transaction.movement_type,

    type: transaction.operation_type,
    index: index,
  }));

  const markedRows = applyPagination(data, { page, rowsPerPage })?.reduce(
    (acc, extract, index) => {
      if (extract.operation_type === "DayClose") {
        acc.push(index);
      }
      return acc;
    },
    []
  );

  return (
    <Fragment>
      <Spacer top={20} />
      {isLoadingTransactions ? (
        <TableLoader />
      ) : statements.length === 0 ? (
        <StatementsEmpty />
      ) : null}
      {statements.length > 0 && !isLoadingTransactions ? (
        <div>
          {selectedVouchers?.length > 0 && (
            <div
              style={{
                background: theme.colors.primary.main,
                borderRadius: "4px",
                color: theme.colors.secondary.main,
              }}
              className="d-flex w-100  flex-column align-content-end ps-3 pe-3 pt-3
                "
            >
              <div className="d-flex justify-content-between">
                <p>
                  {selectedVouchers?.length > 1
                    ? `${selectedVouchers?.length} itens selecionados`
                    : `${selectedVouchers?.length} item selecionado`}{" "}
                </p>
                <p
                  onClick={() => handleCancelAllSelections()}
                  style={{ cursor: "pointer" }}
                >
                  Cancelar
                </p>
              </div>
            </div>
          )}
          <Table
            disabledRows={markedRows}
            data={applyPagination(data, { page, rowsPerPage })}
            columns={columnsStatements}
            dataSpacer={8}
          />{" "}
        </div>
      ) : null}
    </Fragment>
  );
};

export default StatementsTransactionsDash;
