import { useMemo } from "react";
import { Button, Spacer, Table } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import MoreIcon from "~/assets/icons/buttons/more.png";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getFirstLetters } from "~/services/utils";
import { formatCPFCNPJ } from "~/utils";
import Title from "~/components/Texts/Title";

import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";

import BankIcon from "~/assets/icons/contacts/bank_icon.png";
import useContacts from "~/hooks/Contacts/useContacts";
import theme from "~/themes/theme";
import label from "~/labels";

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }

  .bank-accounts-picture {
    width: 10px;
    height: 10px;
    background-color: #d8d8d7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }

  .img-bank-icon {
    width: 25px;
  }
`;

const SelectBankAccount = () => {
  const {
    setSelectContact,
    handleTedNextStep,
    handleTedPrevStep,
    newTransferTEDData,
    changeSideAnimation,
    setChangeSideAnimation,
    setBankAccountIndex,
    bankAccountIndex,
  } = useNewTransfer();

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const bankAccountData: any[] = useMemo<any>(() => {
    if (newTransferTEDData && newTransferTEDData.bank_accounts) {
      return newTransferTEDData.bank_accounts.map(
        (account: any, index: number) => ({
          index: index,
          bank_id: account.bank_data.id,
          bank_name: account.bank_data.name,
          account_number: account.account_number,
          account_number_digit: account.account_number_digit,
          agency: account.agency,
          type: account.type,
        })
      );
    } else {
      return [];
    }
  }, [newTransferTEDData]);

  type accountTypeProps = {
    [key: string]: string;
  };

  const accountType: accountTypeProps = {
    corrente: "Conta Corrente",
    poupanca: "Conta Poupança",
    escrow: "Conta Escrow",
    pagamento: "Conta Pagamento",
    salario: "Conta Salário",
    taxas: "Conta de Taxas",
    movimentacao: "Conta de Movimentação",
    escrow_provedor: "Conta Escrow Provedor",
  };

  const [selectedOption, setSelectedOption] = useState<any>(null);

  const handleSelectChange = (selectedValue: any) => {
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    setBankAccountIndex(-1);
  }, []);

  const { setContactsData, contactsData } = useContacts();

  const [selectedRows, setSelectedRow] = useState<any[]>([]);

  const SelectBankAccount = (id: number) => {
    setSelectedIndex(id);

    const idBankAccountSelected = contactsData[0].bank_accounts.findIndex(
      (bankAccount: any) => bankAccount[bankAccountIndex]
    );

    setSelectedRow([bankAccountIndex]);
  };

  const handleShowTheSelectBankAccount = (data: any) => {
    SelectBankAccount(selectedIndex === data.index ? -1 : data.index);
    setBankAccountIndex(
      bankAccountIndex !== -1 && bankAccountIndex === data.index
        ? -1
        : data.index
    );
  };

  const columnContactSelected = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any, index: number) => (
        <div className="d-flex align-items-center">
          <ContainerImage>
            <div className="container-profile-picture">
              <div className="rounded-circle profile-picture">
                <h1>{getFirstLetters(data.name)}</h1>
              </div>
            </div>
          </ContainerImage>
          <Spacer left={10} />
          <div className="d-flex flex-column">
            <Title>{data.name}</Title>
            <Description color="#929292">
              {formatCPFCNPJ(data.document_number)}
            </Description>
          </div>
        </div>
      ),
    },
  ];

  const columnsBankAccounts = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any, index: number) => (
        <ContainerRenderData
          onClick={() => {
            setSelectedIndex(data.index);
            handleShowTheSelectBankAccount(data);
          }}
        >
          <div className="d-flex align-items-center">
            {selectedIndex === data.index ? (
              <ContainerImage>
                <div className="contact-selected-image">
                  <img
                    className="selected-contact-image"
                    src={SelectedContactIcon}
                  />
                </div>
              </ContainerImage>
            ) : (
              <ContainerImage>
                <div className="container-profile-picture">
                  <div className="rounded-circle  bank-accounts-picture">
                    <img className="img-bank-icon" src={BankIcon} />
                  </div>
                </div>
              </ContainerImage>
            )}
            <Spacer left={10} />
            <div className="d-flex flex-column">
              <Title>{`${data.bank_id} - ${data.bank_name}`}</Title>
              <Description>
                {`Agência: ${data.agency} | Conta: ${data.account_number}-${data.account_number_digit}`}
              </Description>
              <Description>{`Tipo: ${accountType[data.type]}`}</Description>
            </div>
          </div>
        </ContainerRenderData>
      ),
    },
  ];

  return (
    <motion.div
      initial={{
        x: changeSideAnimation ? "-100%" : !changeSideAnimation && "100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: changeSideAnimation ? "100%" : !changeSideAnimation && "-100%",
      }}
      transition={{ duration: 0.3 }}
      className="overflow-hidden"
    >
      <div>
        <div className="p-4">
          <Description color="#666666">
            Selecione uma conta bancária pra enviar a transferência
          </Description>
        </div>{" "}
        <Table
          columns={columnContactSelected}
          data={[
            {
              name: newTransferTEDData.name,
              document_number: newTransferTEDData.document_number,
            },
          ]}
          haveHeader={false}
        />
        <div className="p-4">
          <Title>Dados da conta bancária</Title>
        </div>
        <div
          style={{ zIndex: "1", overflowY: "auto", maxHeight: "400px" }}
          className="  w-100"
        >
          <Table columns={columnsBankAccounts} data={bankAccountData} />
        </div>
        {/* <div className="col-12 p-4"> */}
        {/* <Table
            columns={columnContactSelected}
            data={[
              {
                name: newTransferTEDData.name,
                document_number: newTransferTEDData.document_number,
              },
            ]}
          /> */}
        {/* <CustomSelect
            options={bankAccountOptions}
            placeholder="Selecione"
            onChange={handleSelectChange}
          />
        </div> */}
        <Spacer bottom={20} />
        <div className="p-4">
          <div className="d-flex justify-content-between">
            <div>
              <Button
                mode="phantom"
                label="Voltar"
                onClick={() => {
                  setChangeSideAnimation(true);
                  handleTedPrevStep();
                }}
              />
            </div>

            <div className="d-flex">
              {/* <Button
                mode="border"
                label="Adicionar conta bancária"
                // onClick={handleAddContact}
                icon={MoreIcon}
              /> */}
              <Spacer left={10} />
              <Button
                label="Avançar"
                mode="main"
                onClick={() => {
                  setSelectContact(false);
                  handleTedNextStep();
                }}
                disabled={bankAccountIndex === -1}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SelectBankAccount;
