import { Button, Spacer } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";
import {
  formatCash,
  formatDate,
  formatDateTime,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import { ContainerDetails } from "~/pages/App/app-content.styles";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import DownloadIcon from "~/assets/icons/buttons/download.png";

const TransferReceiptTEV = () => {
  const { dataTransferTEDReceipt } = useNewTransfer();
  const { formatNumberToCurrency } = useAccount();

  const downloadPDF = (url: string, filename: string) => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FileName.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
      });
  };

  return (
    <ContainerDetails>
      <table className=" w-100">
        <tbody>
          <tr>
            <td>
              <Description color="#666666">Valor</Description>
            </td>

            <td className="second-column">
              <Description>{`R$ ${formatCash(
                dataTransferTEDReceipt?.amount
              )}`}</Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666"> Tipo de Transferência</Description>
            </td>

            <td className="second-column">
              <Description>Transferência entre contas</Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Data do pagamento</Description>
            </td>

            <td className="second-column">
              <Description>
                {formatDateTime(dataTransferTEDReceipt?.created_date)}
              </Description>
            </td>
          </tr>

          <tr>
            <td>
              <Description color="#666666">Descrição</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferTEDReceipt.description === ""
                  ? "Sem descrição"
                  : dataTransferTEDReceipt.description}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">ID da transação</Description>
            </td>

            <td className="second-column">
              <Description>{dataTransferTEDReceipt.id}</Description>
            </td>
          </tr>
        </tbody>
      </table>

      <Spacer bottom={20} />

      <table className=" w-100">
        <thead>
          <th>Origem</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <Description color="#666666">Nome</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferTEDReceipt?.full_partner.name}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Instituição</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferTEDReceipt?.full_bank_account.bank_data.name}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Agência </Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferTEDReceipt?.full_bank_account.agency}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Conta</Description>
            </td>

            <td className="second-column">
              <Description>{`${dataTransferTEDReceipt?.full_bank_account.account_number}-${dataTransferTEDReceipt?.full_bank_account.account_number_digit}`}</Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Tipo de conta</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferTEDReceipt?.full_bank_account.type}
              </Description>
            </td>
          </tr>
        </tbody>
      </table>
      <Spacer bottom={20} />

      <table className=" w-100">
        <thead>
          <th>Destino</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <Description color="#666666">Nome</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferTEDReceipt?.full_recipient.name}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Instituição</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferTEDReceipt?.recipient_bank_account.bank_data.name}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Agência</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferTEDReceipt?.recipient_bank_account.agency}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Conta</Description>
            </td>

            <td className="second-column">
              <Description>{`${dataTransferTEDReceipt?.recipient_bank_account.account_number}-${dataTransferTEDReceipt?.recipient_bank_account.account_number_digit}`}</Description>
            </td>
          </tr>

          <tr>
            <td>
              <Description color="#666666">Tipo de conta</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferTEDReceipt?.recipient_bank_account.type}
              </Description>
            </td>
          </tr>
        </tbody>
      </table>

      <Spacer bottom={50} />

      <div className="d-flex justify-content-end">
        <Button
          mode="border"
          icon={DownloadIcon}
          label="Fazer download"
          onClick={() =>
            window.open(dataTransferTEDReceipt?.voucher_url, "_blank")
          }
        />
      </div>

      <Spacer bottom={50} />
    </ContainerDetails>
  );
};

export default TransferReceiptTEV;
