import constate from "constate";
import { useCallback, useEffect, useMemo, useState } from "react";
import ApiService from "~/services/api";
import AppStorage from "~/services/storage";
import {
  PersonalAddressValues,
  PersonalGeneralValues,
} from "./useSignupPersonal";

type AccountSteps =
  | "complementary"
  | "address"
  | "representatives"
  | "attachments"
  | "review";

type SignupCompanySteps =
  | "intro"
  | "general"
  | "address"
  | "representatives"
  | "attachments"
  | "security"
  | "finish"
  | string;

export type CompanyGeneralValues = {
  document_type: string;
  document_number: string;
  mobile_phone: string;
  name: string;
  email: string;
  brand_name: string;
  foundation_date: string;
  additional_type: string;
  city_doc_number: string;
  state_doc_number: string;
};

const initialCompanyGeneralValues: CompanyGeneralValues = {
  document_type: "CNPJ",
  document_number: "",
  mobile_phone: "",
  name: "",
  email: "",
  brand_name: "",
  foundation_date: "",
  additional_type: "",
  city_doc_number: "",
  state_doc_number: "",
};

export type CompanyAddressValues = {
  partner?: number;
  country?: number;
  zipcode: string;
  city: string;
  state: string;
  district: string;
  street: string;
  number: string;
  identification?: string;
  complement: string;
  type?: string;
};

const initialCompanyAddressValues: CompanyAddressValues = {
  country: 1,

  zipcode: "",
  city: "",
  state: "",
  district: "",
  street: "",
  number: "",

  identification: "",
  complement: "",
  type: "billing",
};

export type CompanyRepresentativeValues = PersonalGeneralValues &
  PersonalAddressValues & {
    signatory: boolean;
  };

const initialCompanyRepresentativeValues: CompanyRepresentativeValues = {
  document_type: "CPF",
  document_number: "",
  birth_date: "",
  mobile_phone: "",
  name: "",
  email: "",
  identity_number: "",
  mothers_name: "",
  gender: "",
  marital_status: "",
  nationality: "",
  birth_state: "",

  zipcode: "",
  state: "",
  city: "",
  district: "",
  street: "",
  number: "",
  complement: "",

  signatory: false,
};

export const steps: SignupCompanySteps[] = [
  "intro",
  "general",
  "address",
  "representatives",
  "attachments",
  "security",
  "finish",
];

type SignupCompanyValues = CompanyGeneralValues &
  CompanyAddressValues & {
    representatives: CompanyRepresentativeValues[];
  };

const initialSignupCompanyValues: SignupCompanyValues = {
  ...initialCompanyGeneralValues,
  ...initialCompanyAddressValues,
  representatives: [],
};

const [SignupCompanyProvider, useSignupCompany] = constate(() => {
  const [step, setStep] = useState<SignupCompanySteps>("intro");
  const [valid, setValid] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [stepChildIndex, setStepChildIndex] = useState(0);
  const [addressData, setAddressData] = useState<any>([]);

  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const [companyGeneralValues, setCompanyGeneralValues] =
    useState<CompanyGeneralValues>(initialCompanyGeneralValues);
  const [companyAddressValues, setCompanyAddressValues] =
    useState<CompanyAddressValues>(initialCompanyAddressValues);

  const [companyRepresentativeValues, setCompanyRepresentativeValues] =
    useState<any>(initialCompanyRepresentativeValues);

  const [companyRepresentativesValues, setCompanyRepresentativesValues] =
    useState<CompanyRepresentativeValues[]>([]);

  const [signupCompanyValues, setSignupCompanyValues] =
    useState<SignupCompanyValues>(initialSignupCompanyValues);

  const [canAdvance, setCanAdvance] = useState<boolean>(false);

  const [newCompanyData, setNewCompanyData] = useState<any>({});
  const [payloadDataCompany, setPayloadDataCompany] = useState<any>({});

  const [partnerData, setPartnerData] = useState<any>({});

  const onSubmitForm = () => {
    const event = new CustomEvent("signupCompanySubmit", {
      detail: { step },
    });

    window.dispatchEvent(event);
  };

  useEffect(() => {
    AppStorage.set("@signup/company/step", step);
  }, [step]);

  useEffect(() => {
    const step = AppStorage.get<string>("@signup/company/step");

    if (step) {
      setStep(step);
    }
  }, []);

  const [partnerCompany, setPartnerCompany] = useState<any>({});

  const getPartner = async () => {
    try {
      const registeredToken = AppStorage.getRegisteredToken();

      const response = await ApiService.HttpPost({
        route: "partner-default/",
        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });

      setPartnerCompany(response.data);
    } catch (err) {}
  };

  const updatePartnerStep = async (step: AccountSteps, token?: string) => {
    try {
      await ApiService.HttpPost({
        route: `partner-step/`,
        customHeaders: { Authorization: `Token ${token}` },
        body: { step },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getAddress = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: `address/`,
        token: true,
      });

      if (data.results.length > 0) {
        setAddressData(data.results);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return {
    initialCompanyGeneralValues,
    initialCompanyAddressValues,
    initialCompanyRepresentativeValues,

    step,
    setStep,
    onSubmitForm,
    valid,
    setValid,
    stepIndex,
    setStepIndex,
    stepChildIndex,
    setStepChildIndex,

    loading,
    setLoading,

    sending,
    setSending,

    companyGeneralValues,
    setCompanyGeneralValues,

    companyAddressValues,
    setCompanyAddressValues,

    companyRepresentativeValues,
    setCompanyRepresentativeValues,

    companyRepresentativesValues,
    setCompanyRepresentativesValues,

    signupCompanyValues,
    setSignupCompanyValues,

    canAdvance,
    setCanAdvance,
    newCompanyData,
    setNewCompanyData,

    updatePartnerStep,

    partnerData,
    setPartnerData,

    setPayloadDataCompany,
    payloadDataCompany,
    getAddress,
    setAddressData,
    addressData,
    setPartnerCompany,
    partnerCompany,
    getPartner,
  };
});

export { SignupCompanyProvider };

export default useSignupCompany;
