import { LateralModal } from "@zilliondigital/mirage-ui";
import useStatements from "~/hooks/useStatements";
import { ContainerDetails } from "~/pages/App/app-content.styles";

import Generic from "./DetailsGeneric";

const StatementsDetailsModal = () => {
  const {
    openStatementsDetailsModal,
    setOpenStatementsDetailsModal,
    currentModalData,
  } = useStatements();

  return (
    <LateralModal
      open={openStatementsDetailsModal}
      title={`#${currentModalData?.identifier}`}
      toggleModal={() =>
        setOpenStatementsDetailsModal(!openStatementsDetailsModal)
      }
      header
    >
      {currentModalData ? (
        <ContainerDetails>
          <Generic />
        </ContainerDetails>
      ) : (
        <></>
      )}
    </LateralModal>
  );
};

export default StatementsDetailsModal;
