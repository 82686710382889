import { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Spacer,
  Table,
  NotControlledToolTip,
  InteractiveModal,
  Button,
  Checkbox,
} from "@zilliondigital/mirage-ui";

import useAuthority, { OPT_TYPES, OPT_TYPES_ICONS } from "~/hooks/useAuthority";
import EyeIcon from "~/assets/icons/eye-Size=MD.svg";
import ConfirmIcon from "~/assets/icons/new/thumbs-up.svg";
import DesaproveIcon from "~/assets/icons/new/thumbs-down.svg";
import EmptyListIcon from "~/assets/images/LoadingImages/Permissions.svg";
import { format, parseISO } from "date-fns";
import { convertStringToNumber, formatCPFCNPJ, formatCash } from "~/utils";
import { operationStatus } from "~/data/options";
import { applyPagination } from "~/components/Paginator";
import Skeleton from "~/components/SkeletonTables";
import TitleModal from "~/components/Texts/TitleModal";
import useAccount from "~/hooks/useAccount";
import { Columns } from "~/types";
import { transactions } from "~/data/transactions";

const ApprovedByMe = () => {
  const {
    operations,
    setCurrentModalData,
    currentModalData,
    setOpenOperationDetailModal,
    loadingApprove,
    approveOperation,
    loadingOperations: loadingList,

    rowsPerPage,
    page,
  } = useAuthority();

  const [canApprove, setCanApprove] = useState<boolean>(false);

  const { verifyPermission, mainAccount } = useAccount();

  const canSign: boolean = verifyPermission("ba_can_sign");

  const handleApprove = async () => {
    setCanApprove(false);

    await approveOperation(currentModalData.id);
  };

  const columns: Columns[] = [
    {
      label: "Tipo",
      key: "bank_operation_type",
      renderData: (data: any) => (
        <div className="d-flex  align-items-center">
          <img src={OPT_TYPES_ICONS[data.bank_operation_type]} />
          <p className="ps-2 m-0">{OPT_TYPES[data.bank_operation_type]}</p>
        </div>
      ),
    },
    {
      label: "Status",
      key: "status",
      renderData: (data: any) => (
        <div className="d-flex flex-column " style={{ maxWidth: "400px" }}>
          {data.status === "error_retry" && (
            <p className=" text-danger">Ocorreu um erro. Tente novamente.</p>
          )}

          {data.status === "pending" && data.sign_weight === 0 && (
            <p>Aguardando aprovações</p>
          )}

          {data.sign_weight !== 0 && <p>Em aprovação ({data.sign_weight}%)</p>}
        </div>
      ),
    },
    {
      label: "Beneficiário",
      key: "% assinada",

      renderData: (data: any) => (
        <div className="d-flex flex-column" style={{ maxWidth: "300px" }}>
          <p>
            {data.bank_operation_type === "pix_transfer"
              ? data?.operation_data?.receiver_account?.owner.name
              : data.bank_operation_type === "ted" ||
                data.bank_operation_type === "tev"
              ? data?.operation_data?.full_recipient?.name
              : data.bank_operation_type === "billet_payment" &&
                "Nome do beneficiário não encontrado"}
          </p>
          <Spacer bottom={-10} />
          <p>
            <b>
              {data.bank_operation_type === "pix_transfer"
                ? formatCPFCNPJ(
                    data?.operation_data?.receiver_account?.owner
                      .document_number
                  )
                : data.bank_operation_type === "ted" ||
                  data.bank_operation_type === "tev"
                ? formatCPFCNPJ(
                    data?.operation_data?.full_recipient?.document_number
                  )
                : data.bank_operation_type === "billet_payment" && ""}
            </b>
          </p>
        </div>
      ),
    },
    {
      label: "Data de emissão",
      key: "created",

      renderData: (data: any) => (
        <div>
          <p>
            {data.created
              ? format(parseISO(data.created.replace("Z", "")), "dd/MM/yyyy")
              : "---"}
          </p>
        </div>
      ),
    },
    {
      label: "Valor",
      key: "operation_data",

      renderData: (data: any) => (
        <div>
          <p>{data ? formatCash(data.operation_data?.amount) : "0"}</p>
        </div>
      ),
    },
    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="buttons-box btn-icon-20">
            <Button
              id={`btn-detail-${data.id}`}
              mode="phantom"
              label=""
              iconPosition="right"
              onClick={() => {
                setCurrentModalData(data);
                setOpenOperationDetailModal(true);
              }}
              icon={EyeIcon}
            />
            {/* {canSign && (
              <>
                <Button
                  id={`btn-approve-${data.id}`}
                  mode="phantom"
                  label=""
                  iconPosition="right"
                  onClick={() => {
                    setCurrentModalData(data);

                    handleToggleIds(data);
                    setCanApprove(true);
                  }}
                  icon={ConfirmIcon}
                />

                <Button
                  id={`btn-reprove-${data.id}`}
                  mode="phantom"
                  label=""
                  iconPosition="right"
                  onClick={() => {
                    setCurrentModalData(data);
                    setCanApprove(true);
                  }}
                  icon={DesaproveIcon}
                />
              </>
            )} */}
            {/* <NotControlledToolTip
              placement="top"
              target={`btn-detail-${data.id}`}
            >
              Detalhes
            </NotControlledToolTip>
            <NotControlledToolTip
              placement="top"
              target={`btn-reprove-${data.id}`}
            >
              Reprovar
            </NotControlledToolTip>

            <NotControlledToolTip
              placement="top"
              target={`btn-approve-${data.id}`}
            >
              Aprovar
            </NotControlledToolTip> */}
          </div>
        );
      },
    },
  ];

  const operationsFiltered = useMemo(() => {
    return operations?.filter(
      (operation) =>
        operation.signed_by_me === true && operation.status === "pending"
    );
  }, [operations]);

  return (
    <div>
      {loadingList ? (
        <Skeleton />
      ) : operationsFiltered.length === 0 ? (
        <div className="empty-table-box no-filter">
          <img src={EmptyListIcon} />
          <span>
            Você não aprovou nenhuma transação ainda ou <br />
            todas aprovadas por você já foram concluídas.
          </span>
        </div>
      ) : (
        <div>
          <Table
            data={applyPagination(operationsFiltered, { page, rowsPerPage })}
            columns={columns}
            dataSpacer={8}
          />
        </div>
      )}
    </div>
  );
};

export default ApprovedByMe;
