
import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const RangeTrack = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #80b1d9;
  cursor: pointer;
`;

const RangeProgress = styled.div<{ progress: number }>`
  position: absolute;
  left: 0;
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-color: #ff7f50;
  cursor: pointer;
`;

const RangeThumb = styled.div<{ position: number }>`
  position: absolute;
  top: 50%;
  left: ${({ position }) => position}%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 2px solid #0062b3;
  border-radius: 50%;
  cursor: pointer;
`;

const ParcelLabel = styled.p<{ position: number }>`
  position: absolute;
  top: 13px;
  left: ${({ position }) => position}%;
  transform: translateX(-50%);
  margin-top: 5px;
  color: #666666;
  font-size: 12px;
`;

type ParcelSelectorProps = {
  maxParcels: number;
  onChange: (value: number) => void;
  position?: number;
  progress?: number;
};

const ParcelSelector = (props: ParcelSelectorProps) => {
  const { maxParcels, onChange } = props;

  const [value, setValue] = useState(1);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    setValue(newValue);
    onChange(newValue);
  };

  const percentage = (value / maxParcels) * 100;

  return (
    <Container>
      <RangeTrack>
        <RangeProgress progress={percentage} />
        <RangeThumb position={percentage} />
      </RangeTrack>
      <input
        type="range"
        min="1"
        max={maxParcels}
        value={value}
        style={{ position: "absolute", width: "100%", opacity: 0 }}
        onChange={handleChange}
      />

      <ParcelLabel position={percentage}>{value}x</ParcelLabel>
    </Container>
  );
};

export default ParcelSelector;
