import {
  Button,
  CustomSelect,
  Input,
  InputCurrency,
  LateralModal,
  ProgressBar,
  Radio,
  RadioGroup,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";
import EditIcon from "~/assets/icons/round_edit_black_24dp.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";

import usePayments from "~/hooks/usePayments";
import styled from "styled-components";

import { render } from "react-dom";
import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";
import useAccount from "~/hooks/useAccount";
import useTariffs from "~/hooks/useTariffs";
import { taxesArray } from "~/data/options";
import ApiService from "~/services/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import useToast from "~/hooks/useToast";
import Paginator, { applyPagination } from "~/components/Paginator";
import Description from "~/components/Texts/Description";

import {
  convertStringToNumber,
  formatCPFCNPJ,
  formatCash,
  onlyNumbers,
} from "~/utils";

import { getFirstLetters } from "~/services/utils";
import Title from "~/components/Texts/Title";
import label from "~/labels";
import theme from "~/themes/theme";
import { ContainerData, ImgEye } from "../../../app-content.styles";
import { useNavigate } from "react-router-dom";

type CopyAndPasteFormValues = {
  search: string;
};

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .rounded-circle {
    width: 40px;
  }

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }
`;

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }
`;
const initialValues: CopyAndPasteFormValues = {
  search: "",
};

const FormSchema = Yup.object().shape({
  search: Yup.string(),
});

const SelectClients = () => {
  const {
    clientsData,
    page,
    rowsPerPage,
    setPage,
    setPageClients,
    setRowsPerPage,
    setClientsData,
    dataCreatePackpageTariffs,
  } = useTariffs();
  const { showToast } = useToast();
  const [holdersSelectedsIndex, setHoldersSelectedsIndex] = useState<number[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRow] = useState<any[]>([]);
  const [clientsFiltered, setClientsFiltered] = useState<any>([]);

  const navigate = useNavigate();

  const handleSubmit = (
    values: CopyAndPasteFormValues,
    formikHelpers: any
  ) => {};

  const { values, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
  });

  const getClients = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/approved-partners/",
        token: true,
      });

      setClientsData(data);
    } catch (error) {
      // É uma boa prática lidar com o erro de alguma forma
      console.error("Erro ao buscar clientes:", error);
    }
  }, [ApiService.HttpGet, setClientsData]);

  const setCreateNewPackpageTariffs = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost({
        route: "backoffice/tariffs-package/",
        body: {
          name: "AGORA SIM PACOTE DE TARIFAS",
          tariffs: dataCreatePackpageTariffs,
        },
        token: true,
      });

      showToast("Ação realizada com suceso!", "", "success");

      const AddHoldersData = await ApiService.HttpPost({
        route: `backoffice/tariffs-package/${data.data.id}/add-holders/`,
        body: {
          holders: holdersSelectedsIndex,
        },
        token: true,
      });

      console.log(AddHoldersData.data);
      setIsLoading(false);
    } catch (e: any) {
      showToast(e.message, "", "error");

      setIsLoading(false);
    }
  };

  const SelectContact = (id: number) => {
    setHoldersSelectedsIndex((prevSelected) => {
      const index = prevSelected.indexOf(id);
      if (index > -1) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const isContactSelected = (id: number) => holdersSelectedsIndex.includes(id);

  const handleClickClient = (data: any) => {
    SelectContact(data.id);
  };

  const dataClients = clientsData?.map((contact: any) => {
    return {
      id: contact.id,
      name: contact.name,
      document_number: contact.document_number,
    };
  });

  const columnsHolders = [
    {
      label: "Nome",
      key: "name",

      renderData: (data: any) => {
        return (
          <ContainerRenderData onClick={() => handleClickClient(data)}>
            <div className="d-flex align-items-center">
              {isContactSelected(data.id) ? (
                <ContainerImage>
                  <div className="contact-selected-image">
                    <img
                      className="selected-contact-image"
                      src={SelectedContactIcon}
                    />
                  </div>
                </ContainerImage>
              ) : (
                <ContainerImage>
                  <div className="container-profile-picture">
                    <div className="rounded-circle profile-picture">
                      <h1>{getFirstLetters(data.name)}</h1>
                    </div>
                  </div>
                </ContainerImage>
              )}
              <Spacer left={10} />
              <div className="d-flex flex-column">
                <Title>{data.name}</Title>

                <Description color="#929292">
                  {formatCPFCNPJ(data.document_number)}
                </Description>
              </div>
            </div>
          </ContainerRenderData>
        );
      },
    },
  ];

  useEffect(() => {
    getClients();
  }, []);

  console.log(dataCreatePackpageTariffs);

  const filteredContacts = useMemo(() => {
    const removeNonNumericChars = (input: string) => input.replace(/\D/g, "");
    const searchQuery = values.search.toLowerCase();
    const isNumericSearch = !!removeNonNumericChars(searchQuery);

    return dataClients?.filter(
      (client: { id: number; name: string; document_number: string }) => {
        if (isNumericSearch) {
          return client.document_number
            ?.toLowerCase()
            .includes(onlyNumbers(searchQuery));
        } else {
          return client.name.toLowerCase()?.includes(searchQuery);
        }
      }
    );
  }, [values.search, dataClients]);

  useEffect(() => {
    setClientsFiltered(filteredContacts);
  }, [values.search]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ContainerData className="p-4">
              <>
                <Spacer bottom={20} />
                <Title>Correntistas</Title>
                <Description color="gray">
                  Selecione o(s) correntista(s) que utilizarão esta cesta de
                  tarifas
                </Description>

                <Spacer bottom={-20} />
                <div className="col-6">
                  <Input
                    type="text"
                    search
                    value={values.search}
                    name="search"
                    onChange={handleChange}
                    placeholder="Pesquise um cliente pelo nome ou CPF/CNPJ"
                  />
                </div>
                <div>
                  {" "}
                  {values.search.length > 0 && filteredContacts.length === 0 ? (
                    <p>Nenhum resultado encontrado</p>
                  ) : (
                    <Table
                      selectedRows={selectedRows}
                      haveHeader={false}
                      columns={columnsHolders}
                      data={applyPagination(
                        clientsFiltered.length > 0
                          ? clientsFiltered
                          : dataClients,
                        { page, rowsPerPage }
                      )}
                      dataSpacer={10}
                    />
                  )}
                </div>

                <div className="d-flex justify-content-end mt-3">
                  <Button
                    mode="border"
                    label="Voltar"
                    danger
                    onClick={() => {
                      navigate("/app/myspace/tariffs/new-packpage");
                    }}
                  />

                  <Spacer left={10} />
                  <Button
                    mode="main"
                    label="Criar pacote de tarifas"
                    onClick={setCreateNewPackpageTariffs}
                    loading={isLoading}
                  />
                </div>
              </>
            </ContainerData>
            {values.search.length > 0 &&
            filteredContacts.length === 0 ? null : (
              <Paginator
                list={
                  filteredContacts.length > 0
                    ? filteredContacts
                    : values.search.length > 0
                    ? []
                    : clientsData
                }
                rowsPerPage={rowsPerPage}
                page={page}
                pageSetter={setPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectClients;
