import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ReturnButton,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";

import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import { useNavigate, useParams } from "react-router-dom";
import useContacts from "~/hooks/Contacts/useContacts";
import { getFirstLetters } from "~/services/utils";
import PencilIcon from "~/assets/icons/buttons/pencil.png";
import AddBankAccountModal from "./EditContacts/AddBanksAccount";
import { Fragment, useEffect, useMemo, useState } from "react";
import EditContact from "./EditContacts/EditContact";
import Copyright from "~/components/Copyright";
import theme from "~/themes/theme";
import label from "~/labels";
import AddPixKeyModal from "./AddPixKey/AddPixKeyModal";
import ApiService from "~/services/api";
import { ApiResponse, Columns, getKeyPixContact } from "~/types";
import useToast from "~/hooks/useToast";

type BankAccount = {
  agency: string;
  account_number: string;
  account_number_digit: string;
  bank_code: string;
  bank_data: {
    name: string;
  };
  type: string;
};

const GeneralContainerData = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  height: 100%;
  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .name {
    font-size: 27px;
    font-weight: 400;
  }

  .profile-picture {
    width: 64px;
    height: 64px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }

  .pix-mykeys-image {
    height: 24px;
    filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(0%)
      hue-rotate(135deg) brightness(103%) contrast(105%);
    cursor: pointer;
  }

  .container-btn-more-details {
    position: relative;
    top: 8px;
    left: 75%;
  }

  .copy-icon {
    position: relative;
    top: 8px;
    left: 70%;
  }

  .container-return-button {
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;
const formatCpfCnpj = (string: string) => {
  if (string !== undefined) {
    const onlyNumbers = string.replace(/\D/g, "");

    if (onlyNumbers.length === 11) {
      return onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (onlyNumbers.length === 14) {
      return onlyNumbers.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
  }

  return string;
};

const formatDate = (date: string) => {
  if (date !== undefined || "") {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }
};

const formatPhone = (phone: string) => {
  if (phone !== undefined || "") {
    const onlyNumbers = phone.replace(/\D/g, "");

    if (onlyNumbers.length === 11) {
      return onlyNumbers.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (onlyNumbers.length === 10) {
      return onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
  }

  return phone;
};

type countrysType = {
  [key: number]: string;
};

const countrys: countrysType = {
  1: "Brasil",
  2: "Estados Unidos",
  3: "Argentina",
};

const typeOfAccount: typeKeyString = {
  corrente: "Conta corrente",
  poupanca: "Conta poupança",
};

type typeKeyString = {
  [key: string]: string;
};
const contactType: typeKeyString = {
  management: "Diretoria",
  customer: "Cliente",
  supplier: "Fornecedor",
  service_provider: "Prestador de serviços",
  employeee: "Funcionário",
};

const DetailsContact = () => {
  const {
    contactsData,
    contactIndex,
    setIsDetails,
    setOpenAddBankAccountModal,
    setOpenAddPixKeyModal,
    setIsEdit,
    setKeysData,
    keysData,
    isEdit,
    partnerDetails,
    setPartnerDetails,
  } = useContacts();

  const { showToast } = useToast();

  const navigate = useNavigate();

  const params = useParams<{ id: string }>();

  const dataAddress = partnerDetails[0]?.all_address[0];

  const menusBreadCrumb = [
    {
      label: "Contatos",
      onClick: () => navigate("/app/contacts"),
    },
    { label: "Detalhes", href: "" },
  ];

  const dataBankAccounts: BankAccount[] = partnerDetails[0]?.bank_accounts?.map(
    (bank_accounts: any, index: number) => ({
      agency: bank_accounts.agency,
      account_number: bank_accounts.account_number,
      account_number_digit: bank_accounts.account_number_digit,
      bank_id: bank_accounts.bank_code ?? bank_accounts.bank,
      bank_name: bank_accounts.bank_data.name,
      type: bank_accounts.type,
    })
  );

  const getContactPixKeys = async () => {
    try {
      const { data } = await ApiService.HttpGet<ApiResponse<getKeyPixContact>>({
        route: `pix/partners-key/${params.id}/keys/  `,
        token: true,
      });

      setKeysData(data.data);
    } catch {
      showToast("", "", "error", () => null, false);
    }
  };

  const getPartner = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "partner/",
        token: true,
        params: {
          type: "contact",
          default: false,
          id: params.id,
        },
      });

      setPartnerDetails(data.results);
    } catch (e) {
      console.warn(e);
    }
  };

  const columnsBankAccounts: Columns[] = [
    {
      label: "Banco",
      key: "Banco",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Banco</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Title>{data.name}</Title>
            <Spacer top={-20} />
            <Description>{`${data.bank_id} - ${data.bank_name}`}</Description>
          </div>
        );
      },
    },

    {
      label: "Tipo de conta",
      key: "Tipo de conta",

      renderColumn: (data: any) => {
        return (
          <div className="ps-5">
            <Title className="fw-bold">Tipo</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div className="ms-5 ps-2">
            <Description>{`${typeOfAccount[data.type]}`}</Description>
          </div>
        );
      },
    },

    {
      label: "Conta",
      key: "Conta",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Agência</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Description>{data.agency}</Description>
          </div>
        );
      },
    },
    {
      label: "Agência",
      key: "Agência",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Conta</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Description>{`${data.account_number}-${data.account_number_digit}`}</Description>
          </div>
        );
      },
    },

    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-center  w-25 ">
            <Spacer left={20} />
          </div>
        );
      },
    },
  ];

  const renderType: any = {
    telefone: "Celular",
    email: "Email",
    cpf: "CPF",
    cnpj: "CNPJ",
    evp: "Chave aleatória",
  };

  const columnsKeys: any = [
    {
      label: "Type",
      key: "Tipo",

      renderColumn: (data: any) => {
        return (
          <div>
            <Title className="fw-bold">Tipo</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div>
            <Description>{renderType[data.type]}</Description>
          </div>
        );
      },
    },

    {
      label: "key",
      key: "key",

      renderColumn: (data: any) => {
        return (
          <div className="ps-5">
            <Title className="fw-bold">Chave</Title>
          </div>
        );
      },

      renderData: (data: any) => {
        return (
          <div className="ms-5 ps-2">
            <Description>{data.key}</Description>
          </div>
        );
      },
    },
  ];

  const fullAddressQuery = useMemo(() => {
    const address = dataAddress;

    if (!address) {
      return "";
    }

    const { street, number, district, city, state, zipcode } = address;

    return `${street}, ${number} - ${district}, ${city} - ${state}, ${zipcode}`;
  }, [dataAddress]);

  useEffect(() => {
    getContactPixKeys();
    getPartner();

    setIsEdit(false);
  }, [params.id]);

  return (
    <Fragment>
      <Fragment>
        {partnerDetails.length > 0 && (
          <>
            <GeneralContainerData>
              <div className="d-flex flex-column container w-100 h-100 ">
                <Spacer top={30} />
                <div className=" row ">
                  <Breadcrumb menus={menusBreadCrumb} />
                  <Spacer bottom={30} />
                  <ContainerData className="p-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="rounded-circle  profile-picture">
                          <h1>{getFirstLetters(partnerDetails[0]?.name)}</h1>
                        </div>
                      </div>
                      <div className="w-100 ps-4 pt-2">
                        <h1 className="name">{partnerDetails[0]?.name}</h1>
                        <Spacer bottom={-7} />
                        <Description>
                          {partnerDetails[0]?.contact_type
                            ? contactType[partnerDetails[0]?.contact_type]
                            : "Contato"}
                        </Description>
                      </div>

                      <div className="w-100 d-flex justify-content-end">
                        <Button
                          label="Editar contato"
                          mode="border"
                          icon={PencilIcon}
                          onClick={() => {
                            setIsEdit(true);

                            navigate(`/app/contacts/edit/${params.id}`);
                          }}
                        />
                      </div>
                    </div>
                  </ContainerData>

                  <div className=" d-flex flex-column col-4 ">
                    <Spacer bottom={50} />
                    <ContainerData className=" p-4 ">
                      <Title>Dados gerais</Title>
                      <Spacer bottom={20} />

                      <Description>{partnerDetails[0]?.name}</Description>
                      <Description color="#808080">
                        NOME / RAZÃO SOCIAL
                      </Description>

                      <Spacer bottom={20} />
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <Description>
                            {formatCpfCnpj(partnerDetails[0]?.document_number)}
                          </Description>
                          <Description color="#808080">CPF / CNPJ</Description>
                        </div>
                        {partnerDetails[0]?.birth_date && (
                          <div className="d-flex flex-column">
                            <Description>
                              {formatDate(partnerDetails[0]?.birth_date)}
                            </Description>
                            <Description color="#808080">
                              DATA DE NASCIMENTO
                            </Description>
                          </div>
                        )}
                      </div>

                      <Spacer bottom={20} />
                      <div className="d-flex justify-content-between flex-wrap">
                        {partnerDetails[0]?.mobile_phone && (
                          <div className="d-flex flex-column">
                            <Description>
                              {formatPhone(partnerDetails[0]?.mobile_phone)}
                            </Description>
                            <Description color="#808080">TELEFONE</Description>
                          </div>
                        )}
                        {partnerDetails[0]?.email && (
                          <div className="d-flex flex-column">
                            <Description>
                              {partnerDetails[0]?.email}
                            </Description>
                            <Description color="#808080">EMAIL</Description>
                          </div>
                        )}
                      </div>

                      <Spacer bottom={20} />
                      {partnerDetails[0]?.all_address?.length !== 0 && (
                        <>
                          {" "}
                          <Title>Endereço</Title>
                          <Spacer bottom={20} />
                          <div className="d-flex flex-column">
                            <Description>
                              {" "}
                              {partnerDetails[0]?.all_address[0]?.zipcode}
                            </Description>
                            <Description color="#808080">CEP</Description>
                            {}
                          </div>
                          <Spacer bottom={20} />
                          <div className="d-flex flex-column">
                            <Description>
                              {`${
                                partnerDetails[0]?.all_address[0]?.street
                              }, Nº  
                              ${partnerDetails[0]?.all_address[0]?.number}${
                                partnerDetails[0]?.all_address[0]
                                  ?.complement !== null &&
                                partnerDetails[0]?.all_address[0]?.complement
                              },
                                ${partnerDetails[0]?.all_address[0]?.district},
                                  ${partnerDetails[0]?.all_address[0]?.city},
                                  ${
                                    countrys[
                                      partnerDetails[0]?.all_address[0]
                                        ?.country_id
                                    ]
                                  } `}
                            </Description>
                            <Description color="#808080">
                              ENDEREÇO COMPLETO
                            </Description>
                            <div className="d-flex flex-column col-12">
                              <iframe
                                style={{ width: "100%" }}
                                width="600"
                                height="200"
                                id="gmap_canvas"
                                src={`https://maps.google.com/maps?q=${fullAddressQuery}&t=&z=17&ie=UTF8&iwloc=&output=embed`}
                              ></iframe>
                            </div>
                          </div>
                        </>
                      )}
                    </ContainerData>
                  </div>

                  <div className="col-8">
                    <Spacer bottom={50} />

                    <ContainerData className="p-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <Title>Contas bancárias</Title>
                        <Button
                          mode="border"
                          // icon="fd"
                          label={
                            partnerDetails[0]?.bank_accounts?.length !== 0
                              ? "Adicionar outra conta bancária"
                              : "Adicionar uma conta bancária"
                          }
                          onClick={() => setOpenAddBankAccountModal(true)}
                        />
                      </div>

                      <Spacer bottom={40} />
                      {partnerDetails[0].bank_accounts?.length > 0 ? (
                        <Table
                          data={dataBankAccounts}
                          columns={columnsBankAccounts}
                        />
                      ) : (
                        <Description className="text-center pb-5 pt-5">
                          Nenhuma conta bancária cadastrada
                        </Description>
                      )}
                    </ContainerData>
                    <Spacer bottom={20} />
                    <ContainerData className="p-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <Title>Chaves Pix</Title>
                        <Button
                          mode="border"
                          label={
                            keysData?.length > 0
                              ? "Adicionar outra chave pix"
                              : "Adicionar uma chave pix"
                          }
                          onClick={() => {
                            setOpenAddPixKeyModal(true);
                          }}
                        />
                      </div>

                      <Spacer bottom={40} />
                      {keysData?.length > 0 ? (
                        <Table data={keysData} columns={columnsKeys} />
                      ) : (
                        <Description className="text-center pb-5 pt-5">
                          Nenhuma chave pix associada a este contato
                        </Description>
                      )}
                    </ContainerData>
                    <Spacer bottom={20} />
                  </div>
                </div>
                <Spacer top={40} />

                <Copyright />

                <Spacer top={30} />
              </div>
            </GeneralContainerData>
            <AddBankAccountModal />
            <AddPixKeyModal />
          </>
        )}
      </Fragment>
    </Fragment>
  );
};

export default DetailsContact;
