import { FormikHelpers, FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Input,
  InteractiveModal,
  Margin,
  Spacer,
} from "@zilliondigital/mirage-ui";

import "bootstrap/dist/css/bootstrap.min.css";
/* import Logo from "~/assets/images/logo-cmcapital.png"; */

import { Fragment, useEffect, useMemo, useState } from "react";
import ApiService from "~/services/api";
import Forgot from "./Forgot";

import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import useToast from "~/hooks/useToast";
import {
  BackgroundContainer,
  ContainerCompany,
  ForgotPasswordLink,
  GeneralContainer,
  PasswordContainer,
  Sidebar,
  SidebarBody,
  TextFooter,
  TitleModal,
  WelcomeDescription,
  WelcomeText,
} from "./Auth.styles";
import { onlyNumbers } from "~/services/utils";
import documentNumberValidator from "~/utils/validateDocNumber";
import useToken from "~/hooks/useToken";
import AppStorage from "~/services/storage";
import useAuth from "~/hooks/useAuth";
import ValidatorsModals from "~/components/ModalsValidators/ValidatorsModals";

import { LoginResponse, UserCheckResponse } from "~/types";
import useAccount from "~/hooks/useAccount";

import label from "~/labels";
import styled from "styled-components";
import Copyright from "~/components/Copyright";
import theme from "~/themes/theme";

import IconBusiness from "~/assets/icons/icon-business.png";
import IconPerson from "~/assets/icons/icon-person.png";
import { formatCPFCNPJ, getAccountStatus } from "~/utils";
import { account_type_dict } from "~/data/account";

const LogoImg = styled.img`
  width: 100%;

  max-height: ${() => label.backgroundSize ?? "100px"};
`;

type FormLoginValues = {
  step: number;
  document_number: string;
  password: string;
};

type verificationType = {
  phone?: string;
  email?: string;
};

const FormSchema = Yup.object().shape({
  document_number: Yup.string()
    .required("Campo obrigatório")
    .test("", "", function (value = "") {
      const { path, createError } = this;
      const isValid = documentNumberValidator(onlyNumbers(value));
      return isValid || createError({ path, message: "Documento inválido" });
    }),
  password: Yup.string().when("step", {
    is: 1,
    then: Yup.string()
      .required("Campo obrigatório")
      .min(8, "A senha precisa ter pelo menos 8 caracteres")
      .required("Campo obrigatório"),
  }),
});

const BackofficeLogin = () => {
  const location = useLocation();

  const initialValues: FormLoginValues = {
    step: location.state?.document_number ? 1 : 0,
    document_number: location.state?.document_number ?? "",
    password: "",
  };

  const [submitedValues, setSubmitedValues] =
    useState<FormLoginValues>(initialValues);
  const [verificationData, setVerificationData] = useState<verificationType>(
    {}
  );

  const { setIdUser, idUser } = useAccount();
  const [userFounded, setUserFounded] = useState<boolean>(false);

  const navigate = useNavigate();

  const { showToast } = useToast();

  const {
    partnersModal,
    setPartnersModal,
    setToken,
    setUser,
    token,
    setStepIndex,
    openModal,
    setOpenModal,
  } = useAuth();

  const { userPartners, setUserPartners } = useAccount();

  const { sendPartnerToken, verifyUserToken, getToken } = useToken();

  const toggleModal = () => {
    setStepIndex(0);
    setOpenModal(!openModal);
  };

  const onSubmitLogin = async (
    values: FormLoginValues,
    helpers: FormikHelpers<FormLoginValues>
  ) => {
    setSubmitting(true);

    setSubmitedValues(values);

    if (values.step == 0) {
      try {
        const checkDocumentNumberPayload = {
          type: "document_number",
          username: onlyNumbers(values.document_number),
        };

        const { data } = await ApiService.HttpPost<UserCheckResponse>({
          route: "user-check/",
          body: checkDocumentNumberPayload,
        });

        if (!data.status) {
          showToast(
            "Documento não encontrado",
            "Parece que o documento informado não existe em nosso sistema, verifique se o documento está correto ou cadastre-se.",
            "error"
          );
          setUserFounded(false);
        } else {
          let can_access = false;

          const {
            response: { user },
          } = data;

          if (user.partners.length > 0) {
            const has_approver_accounts = user.partners.some(
              (acc: any) => acc.type == "approver" || acc.is_approver
            );

            if (has_approver_accounts) {
              setUserPartners(user.partners);
              setUserFounded(true);
              helpers.setFieldValue("step", 1);
              can_access = true;
            }
          }

          if (!can_access) {
            showToast(
              "Erro",
              "Você não tem permissão para acessar esta área.",
              "error"
            );
            setUserFounded(false);
          }
        }
      } catch (error) {
        console.warn(error);
      }
    }

    if (values.step == 1) {
      try {
        try {
          const document_number = onlyNumbers(values.document_number);

          const authPayload = {
            username: document_number,
            password: values.password,
          };

          const { data } = await ApiService.HttpPost<LoginResponse>({
            route: "api-token-auth/",
            body: authPayload,
          });

          if (data.token) {
            setToken(data.token);
            AppStorage.setToken(data.token);

            setPartnersModal(true);
          }
        } catch (error) {
          showToast("Erro", "Usuário e/ou senha incorretos", "error");
          doReset();
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }

    setSubmitting(false);
  };

  const doReset = () => {
    setVerificationData({});
    setSubmitedValues(initialValues);
  };

  const selectPartner = async (partner: any) => {
    try {
      await ApiService.HttpPut({
        route: `user-partners/${partner.id}/set_default/`,
        token: true,
      });
    } catch (error) {
      showToast(
        "Erro",
        "Não foi possível selecionar a conta, redirecionando..",
        "error"
      );
    }

    try {
      const partnerDefault = await ApiService.HttpPost({
        route: "partner-default/",
        body: null,
        token: true,
      });

      setUser(partnerDefault.data);
      AppStorage.setUser(partnerDefault.data);

      const token = AppStorage.getToken();

      await sendPartnerToken();

      const validateToken = await getToken();

      const check = await verifyUserToken(
        {
          code: validateToken,
        },
        { token }
      );

      if (!check) {
        showToast(
          "Token Incorreto",
          "Parece que o token informado não corresponde ao enviado pelo nosso sistema, tente novamente.",
          "error"
        );
        doReset();
        return;
      }

      navigate("/backoffice");
    } catch (error) {
      showToast(
        "Erro",
        "Não foi possivel recuperar os dados da conta, tente novamente mais tarde.",
        "error"
      );
    }
  };

  const validUserPartners = useMemo(() => {
    return userPartners.filter(
      (partner: any) => partner.type == "approver" || partner.is_approver
    );
  }, [userPartners]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
    submitForm,
    setSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: onSubmitLogin,
    enableReinitialize: true,
  }) as FormikProps<FormLoginValues>;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <GeneralContainer>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Sidebar>
              <Spacer top={138} />
              <SidebarBody>
                <div className="d-flex  justify-content-center justify-content-md-start">
                  <div>
                    <LogoImg
                      style={
                        label.logoFilter ? { filter: label.logoFilter } : {}
                      }
                      src={label.logoHorizontal}
                    ></LogoImg>
                  </div>
                </div>
                <Spacer bottom={56} />
                <div className="d-flex flex-column align-items-center align-items-md-start ">
                  <WelcomeText>Bem vindo</WelcomeText>
                  <WelcomeDescription>
                    Informe seus dados para continuar...
                  </WelcomeDescription>
                </div>
                <Spacer bottom={56} />

                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    name="document_number"
                    mask={
                      values.document_number.length > 14
                        ? "99.999.999/9999-99"
                        : "999.999.999-9999999"
                    }
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("step", 0);
                    }}
                    value={values.document_number}
                    label="Informe seu CPF/CNPJ"
                    placeholder="448.584.478-51"
                    error={
                      errors.document_number && touched.document_number
                        ? errors.document_number
                        : ""
                    }
                    success={userFounded}
                    autoComplete="document_number"
                    autoCapitalize="off"
                    required={values.document_number.length < 14}
                  />

                  <Fragment>
                    <Spacer bottom={-8} />
                    <PasswordContainer>
                      <ForgotPasswordLink
                        style={
                          label.usePrimaryColor?.includes(
                            "forgot_password_text"
                          )
                            ? { color: theme.colors.primary.main }
                            : {}
                        }
                        onClick={toggleModal}
                      >
                        Esqueci minha senha
                      </ForgotPasswordLink>
                      {values.step == 0 && <Spacer bottom={28} />}

                      {values.step == 1 && (
                        <Input
                          type="password"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          label="Insira sua senha"
                          placeholder="lembrete: mínimo de 8 caracteres"
                          error={touched.password && errors.password}
                          autoComplete="current-password"
                          autoCapitalize="off"
                          required={values.password.length < 8}
                        />
                      )}
                    </PasswordContainer>
                  </Fragment>

                  <Button
                    className="text-center"
                    mode="main"
                    loading={isSubmitting}
                    full
                    onClick={() => submitForm()}
                    label={
                      values.step == 0
                        ? "Validar documento"
                        : "Acessar minha conta"
                    }
                    type="button"
                    // onClick={checkDocumentNumber}
                  />
                </form>
              </SidebarBody>
              <footer>
                <Margin top={20} />
                <Copyright />
              </footer>
            </Sidebar>
          </motion.div>

          <BackgroundContainer />

          <InteractiveModal
            size="md"
            open={openModal}
            toggleModal={toggleModal}
          >
            <Forgot />
          </InteractiveModal>

          <InteractiveModal
            size="md"
            open={partnersModal}
            toggleModal={() => undefined}
          >
            <div className="p-4">
              <TitleModal>
                Selecione um perfil de pessoa para prosseguir
              </TitleModal>
              <Spacer bottom={16} />

              {validUserPartners.map((partner, index) => (
                <Fragment>
                  <div
                    key={index}
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={() => selectPartner(partner)}
                  >
                    <ContainerCompany className="col-12">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ padding: "15px" }}
                      >
                        <div
                          className="d-flex justify-content-center align-items-center div-circle"
                          style={{
                            width: "48px",
                            height: "48px",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            alt=""
                            src={
                              partner.document_type == "cpf"
                                ? IconPerson
                                : IconBusiness
                            }
                            className="icons"
                          />
                        </div>
                      </div>

                      <div
                        className="d-flex flex-column justify-content-center "
                        style={{ padding: " 10px" }}
                      >
                        <span
                          className="title"
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          {partner.name}
                        </span>
                        <span style={{ fontSize: "14px", color: "#929292" }}>
                          {formatCPFCNPJ(partner.document_number)}
                          {" - "}
                          {account_type_dict[partner.type]}
                          {" - "}
                          {getAccountStatus(partner.account_status)}
                        </span>
                      </div>
                    </ContainerCompany>
                  </div>
                  <Spacer bottom={16} />
                </Fragment>
              ))}

              <Button
                mode="border"
                label="Adicionar nova conta"
                full
                onClick={() => {
                  navigate("/signup/user/data", {
                    state: {
                      document_number: values.document_number,
                      has_user: true,
                    },
                  });
                }}
              />
            </div>
          </InteractiveModal>

          <ValidatorsModals />
        </GeneralContainer>
      </motion.div>
    </AnimatePresence>
  );
};

export default BackofficeLogin;
