import { Button, InteractiveModal, Spacer } from "@zilliondigital/mirage-ui";
import { useState } from "react";
import useSplit from "~/hooks/useSplit";
import useToast from "~/hooks/useToast";
import { TitleModal } from "~/pages/Authentication/Auth.styles";
import ApiService from "~/services/api";
import { APIResponse, getSplit } from "~/types";

const DeleteBeneficiarieModal = () => {
  const [isloading, setIsloading] = useState<boolean>(false);

  const {
    splitData,
    setSplitData,
    beneficiarieData,
    openDeleteBeneficiarieModal,
    setOpenDeleteBeneficiarieModal,
  } = useSplit();

  const { showToast } = useToast();

  const toggleModal = () => {
    setOpenDeleteBeneficiarieModal(!openDeleteBeneficiarieModal);
  };

  type deleteBeneficiarie = {
    data?: any;
    error: boolean;
    message: string;
  };

  const deleteBeneficiarie = async () => {
    setIsloading(true);

    try {
      const data = await ApiService.HttpDelete<deleteBeneficiarie>({
        route: `charge/split/${splitData.id}/remove-beneficiary/${beneficiarieData.id}/`,
        token: true,
      });

      setIsloading(false);
      toggleModal();

      showToast("Recebedor removido com sucesso", "", "success");

      const dataUpdated = await ApiService.HttpGet<APIResponse<getSplit>>({
        route: "charge/split/",
        token: true,
      });

      setSplitData(
        dataUpdated.data.results.filter(
          (item: any) => item.id === splitData.id
        )[0]
      );
    } catch {
      setIsloading(false);
      toggleModal();
      showToast("Nao foi possível excluir o recebedor", "", "error");
    }
  };

  return (
    <InteractiveModal
      size="md"
      toggleModal={toggleModal}
      open={openDeleteBeneficiarieModal}
    >
      <div className="p-5 d-flex  flex-column justify-content-between ">
        <TitleModal className="text-center">
          Remover {beneficiarieData?.contact_data?.name} ?
        </TitleModal>
        <Spacer bottom={15} />
        <p>
          Tem certeza que deseja remover {beneficiarieData?.contact_data?.name}{" "}
          do arranjo {splitData.name}? Esta operação não poderá ser revertida.
        </p>

        <Spacer bottom={110} />
        <div>
          <Button
            mode="main"
            label="Não, manter como está"
            onClick={toggleModal}
            full
          />
          <Spacer bottom={10} />
          <Button
            loading={isloading}
            mode="border"
            label="Sim, remover recebedor"
            onClick={deleteBeneficiarie}
            full
          />
        </div>
      </div>
    </InteractiveModal>
  );
};
export default DeleteBeneficiarieModal;
