import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Checkbox,
  Input,
  InteractiveModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { CpfRegex, INVALID_FORMAT } from "~/services/validators";
import useSignupUser from "~/hooks/Signup/useSignupUser";
import { generateCPF, generateCellphone, onlyNumbers } from "~/services/utils";
import useAnimation from "~/hooks/useAnimation";
import { motion } from "framer-motion";
import ApiService from "~/services/api";
import { UserCheckResponse } from "~/types";
import useToast from "~/hooks/useToast";
import { useLocation, useNavigate } from "react-router-dom";
import { faker } from "@faker-js/faker";
import theme from "~/themes/theme";
import label from "~/labels";
import useAuth from "~/hooks/useAuth";
import {
  ForgotPasswordLink,
  PasswordContainer,
} from "~/pages/Authentication/Auth.styles";
import Forgot from "~/pages/Authentication/Forgot";
import documentNumberValidator from "~/utils/validateDocNumber";
import { formatCPFCNPJ, verifyCompleteName } from "~/utils";

type Values = {
  full_name: string;
  username: string;
  email: string;
  username_login: string;
  password: string;
  phone: string;
  terms: boolean;
  token: string;
};

const Title = styled.span`
  font-size: 32px;
  font-weight: 400;

  @media screen and (max-width: 520px) {
    font-size: 25px;
  }
`;

type Props = {
  hasNoUser: boolean;
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const DivShowAndHide = styled.div<{ hasNoUser: boolean }>`
  opacity: ${({ hasNoUser }) => (hasNoUser ? 1 : 0)};
  pointer-events: ${({ hasNoUser }) => (hasNoUser ? "auto" : "none")};
  animation: ${({ hasNoUser }) => hasNoUser && fadeIn} 0.3s ease-in-out;
`;

const FormSchema = Yup.object().shape({
  username: Yup.string().matches(CpfRegex, INVALID_FORMAT),
  full_name: Yup.string(),

  terms: Yup.boolean().oneOf([true], "É necessário aceitar os termos"),
});

const SignupBasicData = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToast();

  const {
    setValid,
    setLoading,
    setStep,
    signUpUserPayload,
    setSignUpUserPayload,
    doLogin,
    setDoLogin,
    setDataLoginUser,
    comeToLogin,
    setComeToLogin,
  } = useSignupUser();
  const [loadingScreem, setLoadingScreem] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { changeSideAnimation, setChangeSideAnimation } = useAnimation();
  const { setStepIndex, openModal, setOpenModal } = useAuth();
  const [hasNoUser, setHasNoUser] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<Values>({
    username: signUpUserPayload.username ?? "",
    full_name: signUpUserPayload.full_name ?? "",
    email: signUpUserPayload.email ?? "",
    phone: signUpUserPayload.phone ?? "",
    terms: signUpUserPayload.terms ?? false,
    username_login: "",
    password: "",
    token: "",
  });

  const handleFormSubmit = useCallback(
    async (values: Values, helpers: FormikHelpers<Values>) => {
      helpers.setSubmitting(true);
      setLoadingScreem(true);
      try {
        const { data } = await ApiService.HttpPost<UserCheckResponse>({
          route: "user-check/",
          body: {
            type: "document_number",
            username: onlyNumbers(values.username),
          },
        });
        setLoadingScreem(false);
        if (data.status) {
          showToast(
            "Atenção!",
            "CPF já cadastrado, logue-se para continuar.",
            "warning",
            () => null,
            true,
            10000
          );
          setDoLogin(true);
          setFieldValue("username_login", values.username);
          setLoadingScreem(false);

          return;
        }

        setSignUpUserPayload((prevData: any) => ({
          ...prevData,
          ...values,
        }));

        localStorage.setItem(
          "new_user",
          JSON.stringify({
            username: values.username,
            full_name: values.full_name,
            terms: values.terms,
          })
        );
        setValid(false);
        navigate("/signup/user/contact-data");
        setLoadingScreem(false);
      } catch (error) {
        showToast("Atenção", `Ocorreu um erro. ${error}`, "error");
        setLoadingScreem(false);
      }

      helpers.setSubmitting(false);
      setLoadingScreem(false);
    },
    [
      setLoading,
      setDoLogin,

      setSignUpUserPayload,
      navigate,
      showToast,
      setValid,
      setLoadingScreem,
    ]
  );

  const {
    submitForm,

    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const handleParentSubmit = () => {
    submitForm();
  };

  const fillForm = () => {
    const fakeValues: Values = {
      username: formatCPFCNPJ(generateCPF()),
      full_name: faker.name.fullName(),
      email: faker.internet.email(),
      phone: generateCellphone(true),
      terms: true,
      password: "",
      username_login: "",
      token: "",
    };

    setInitialValues(fakeValues);
  };

  const isValidDocument = useMemo(() => {
    return (
      values.username.length > 13 && documentNumberValidator(values.username)
    );
  }, [values.username]);

  const toggleModal = useCallback(() => {
    setStepIndex(0);
    setOpenModal((prevState) => !prevState);
  }, [setStepIndex, setOpenModal]);

  const verifyIfHasUser = useCallback(async () => {
    setLoadingScreem(true);
    try {
      const { data } = await ApiService.HttpPost<UserCheckResponse>({
        route: "user-check/",
        body: {
          type: "document_number",
          username: onlyNumbers(values.username),
        },
      });

      if (data.status) {
        showToast(
          "Atenção!",
          "CPF já cadastrado, logue-se para continuar.",
          "warning",
          () => null,
          true,
          10000
        );
        setDoLogin(true);
        setFieldValue("username_login", values.username);
        setLoadingScreem(false);

        return;
      }

      setHasNoUser(true);
      setLoadingScreem(false);
    } catch {
      setLoadingScreem(false);
    }
  }, [values]);

  useEffect(() => {
    const newUser: any = localStorage.getItem("new_user");
    const newUserParsed = JSON.parse(newUser);

    if (newUserParsed && newUserParsed !== null) {
      setHasNoUser(true);
      setFieldValue("username", newUserParsed.username);
      setFieldValue("full_name", newUserParsed.full_name);
      setFieldValue("terms", newUserParsed.terms);
    }
    setStep("data");
    window.addEventListener("signupUserSubmit", handleParentSubmit);
    window.addEventListener("fillForm", fillForm);

    setChangeSideAnimation(false);

    if (location.state && location.state.has_user) {
      setFieldValue("username_login", location.state.document_number);
    }

    if (comeToLogin) {
      setDoLogin(true);

      setComeToLogin(false);
    } else {
      setDoLogin(false);
    }

    return () => {
      window.removeEventListener("signupUserSubmit", handleParentSubmit);
      window.removeEventListener("fillForm", fillForm);
    };
  }, []);

  useEffect(() => {
    setDataLoginUser({
      username_login: values.username_login,
      password: values.password,
    });
  }, [values]);

  useEffect(() => {
    if (doLogin) {
      localStorage.removeItem("new_user");
    }
  }, [doLogin]);

  useEffect(() => {
    if (values.username.length <= 13 && !isValidDocument) {
      setError("initialState");
    } else if (values.username.length > 13 && isValidDocument) {
      setError("notError");
    } else if (values.username.length > 13 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.username]);

  useEffect(() => {
    const { terms, full_name, username } = values;
    if (values.full_name) {
      if (
        terms &&
        verifyCompleteName(full_name) &&
        error === "notError" &&
        hasNoUser
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
  }, [values, error, hasNoUser]);

  useEffect(() => {
    if (error === "notError") {
      verifyIfHasUser();
    }
  }, [error]);

  return (
    <Fragment>
      <motion.div
        initial={{
          x: !changeSideAnimation ? "100%" : changeSideAnimation && "-100%",
        }}
        animate={{ x: 0 }}
        exit={{
          x: !changeSideAnimation ? "-100%" : changeSideAnimation && "100%",
        }}
        transition={{ duration: 0.5 }}
      >
        <Spacer top={70} />
        <div className="d-flex justify-content-between">
          {doLogin && (
            <Button
              label={"Não tem cadastro ? Crie seu acesso"}
              mode="main"
              onClick={() => {
                setHasNoUser(false);
                setFieldValue("username", "");
                setDoLogin(!doLogin);
              }}
            />
          )}
        </div>
        <Spacer bottom={30} />
        <div style={{ maxWidth: "614px" }} className="d-flex flex-column">
          <Title>
            {" "}
            {doLogin
              ? "Faça login e continue seu cadastro"
              : !hasNoUser
              ? "Para iniciar, informe seu CPF"
              : "Dados pessoais"}
          </Title>
          <Spacer bottom={20} />
        </div>

        <div className="row">
          <div
            className={
              doLogin
                ? "col-12 col-xl-6 col-xxl-8"
                : "col-12 col-xl-6 col-xxl-6"
            }
          >
            <form>
              <div className="row">
                {doLogin ? (
                  <>
                    <div className="col-12 " style={{ maxWidth: "320px" }}>
                      <Input
                        mask={
                          values.username_login.length > 14
                            ? "99.999.999/9999-99"
                            : "999.999.999-999999"
                        }
                        type="text"
                        name="username_login"
                        value={values.username_login}
                        required
                        label="Informe seu CPF"
                        placeholder="000.000.000-00"
                        onChange={handleChange}
                        autoCapitalize="off"
                        autoComplete="off"
                        success={values.username_login.length > 13}
                      />
                    </div>
                    <div className="col-12  " style={{ maxWidth: "320px" }}>
                      <PasswordContainer>
                        <ForgotPasswordLink
                          style={
                            label.usePrimaryColor?.includes(
                              "forgot_password_text"
                            )
                              ? {
                                  color: theme.colors.primary.main,
                                  left: "145px",
                                }
                              : { left: "145px" }
                          }
                          onClick={toggleModal}
                        >
                          Esqueci minha senha
                        </ForgotPasswordLink>
                        <Input
                          type="password"
                          name="password"
                          label="Informe sua senha"
                          value={values.password}
                          required
                          placeholder="Lembrete: Mínimo 8 caracteres"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoCapitalize="none"
                          autoComplete="none"
                        />
                      </PasswordContainer>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 col-md-9">
                      <Input
                        mask="999.999.999-99"
                        type="text"
                        name="username"
                        value={values.username}
                        required={error !== "notError"}
                        label="CPF"
                        placeholder="000.000.000-00"
                        error={
                          values.username.length > 13 &&
                          !isValidDocument &&
                          error
                        }
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        autoCapitalize="off"
                        autoComplete="off"
                        success={error === "notError"}
                      />
                    </div>

                    <DivShowAndHide hasNoUser={hasNoUser}>
                      <div className="col-12 col-md-11">
                        <Input
                          type="text"
                          name="full_name"
                          value={values.full_name}
                          required={!verifyCompleteName(values.full_name)}
                          label="Nome Completo"
                          placeholder="Jhon Doe"
                          helper="Sem abreviações, como está em seus documentos"
                          error={touched.full_name && errors.full_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="name"
                          autoCapitalize="words"
                          success={verifyCompleteName(values.full_name)}
                        />
                      </div>
                      <Spacer bottom={20} />
                      <div className="col-12 col-md-7">
                        <Checkbox
                          onClick={() => setFieldValue("terms", !values.terms)}
                          checked={values.terms}
                        />

                        <span style={{ marginLeft: "10px" }}>
                          Eu autorizo o uso dos meus dados de acordo com o {""}
                          <span
                            style={
                              label.usePrimaryColor?.includes(
                                "terms_and_privacy"
                              )
                                ? {
                                    color: theme.colors.primary.main,
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }
                                : {
                                    color: theme.colors.secondary.main,
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }
                            }
                          >
                            Termos e condições
                          </span>{" "}
                          e{" "}
                          <span
                            style={
                              label.usePrimaryColor?.includes(
                                "terms_and_privacy"
                              )
                                ? {
                                    color: theme.colors.primary.main,
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }
                                : {
                                    color: theme.colors.secondary.main,
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }
                            }
                          >
                            Política de Privacidade
                          </span>
                          .
                        </span>
                        {errors.terms && touched.terms && (
                          <span style={{ color: "#ff0000" }}>
                            {errors.terms}
                          </span>
                        )}
                      </div>
                    </DivShowAndHide>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
        <Spacer bottom={89} />
      </motion.div>
      <InteractiveModal size="md" open={openModal} toggleModal={toggleModal}>
        <Forgot />
      </InteractiveModal>
      <InteractiveModal size="md" open={loadingScreem} toggleModal={() => null}>
        <>
          <div className="p-4 h-100 d-flex h-100 justify-content-center align-items-center w-100   flex-column">
            <Spacer bottom={120} />
            <div
              className="spinner-border mb-2"
              role="status"
              style={{ color: theme.colors.primary.main }}
            ></div>
            <Spacer top={20} />
            <div className="text-center">Aguarde...</div>
          </div>
        </>
      </InteractiveModal>
    </Fragment>
  );
};

export default SignupBasicData;
