import {
  Button,
  InputToken,
  InteractiveModal,
  Spacer,
} from "@zilliondigital/mirage-ui";

import { useEffect, useState } from "react";

import TitleModal from "./Texts/TitleModal";
import Description from "./Texts/Description";
import Padding from "./Padding";
import useToken from "~/hooks/useToken";

export type ModalProps = {
  title?: string;
  description?: string;
  sendAgain?: string;

  numberOfInputs?: number;
};

const AsyncTokenModal = () => {
  const {
    showModal,
    modalProps: {
      title = "Validação de segurança",
      description = "Informe o token vinculado a sua conta.",
      numberOfInputs = 6,
    },
    handleToken,
  } = useToken();

  const [token, setToken] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [buttonLabel, setButtonLabel] = useState(
    `Faltam ${numberOfInputs} digitos`
  );

  const handleClose = () => {
    handleToken("");
    setToken("");
  };

  const handleConfirmToken = () => {
    handleToken(token);
    setToken("");
  };

  const handleInput = (values: string) => {
    setToken(values);

    if (values.length === numberOfInputs && token.length > 0) {
      setButtonDisabled(false);
      setButtonLabel("Validar token de segurança");
    } else {
      setButtonDisabled(true);

      const oneDigit = numberOfInputs - values.length === 1;
      if (oneDigit) {
        setButtonLabel(`Falta 1 digito`);
      }

      !oneDigit &&
        setButtonLabel(`Faltam ${numberOfInputs - values.length} digitos`);
    }
  };

  useEffect(() => {
    setButtonDisabled(token.length !== numberOfInputs);
  }, [token]);

  return (
    <InteractiveModal open={showModal} size="md" toggleModal={handleClose}>
      <Padding top={72} right={62} bottom={72} left={62}>
        <div className="d-flex flex-column flex-grow-1">
          <TitleModal>{title}</TitleModal>
          <Spacer bottom={10} />
          <Description>{description}</Description>
          <Spacer bottom={30} />
          {showModal && (
            <InputToken
              keyDownFunction={handleConfirmToken}
              inputsNumber={6}
              onInputChange={handleInput}
            />
          )}
          <Spacer bottom={40} />
          <Button
            mode="main"
            label={buttonLabel}
            full
            onClick={handleConfirmToken}
            disabled={buttonDisabled}
          />
          <Spacer bottom={20} />
        </div>
      </Padding>
    </InteractiveModal>
  );
};

export default AsyncTokenModal;
