import { Spacer } from "@zilliondigital/mirage-ui";
import { Fragment, useEffect, useMemo } from "react";
import IconCheck from "~/assets/icons/icon-checked-company.png";
import { Title } from "../Singup.styles";
import theme from "~/themes/theme";
import useSignupCompany from "~/hooks/Signup/useSignupCompany";

const Intro = () => {
  const { setStep } = useSignupCompany();

  const roles: string[] = [
    "Ela está com o CNPJ ativo;",
    "Você está com seus documentos em mãos;",
    "Você está com os documentos da sua empresa também;",
  ];

  const docsRequireds: string[] = [
    "Cartão CNPJ pra facilitar o cadastro básico;",
    "Dados dos representantes, caso haja;",
    "Procuração de representante, caso haja;",
    "Comprovante de documento dos sócios/representantes;",
    "Contrato Social ou Estatuto Social e Atos Societários;",
    "Comprovante de endereço da empresa;",
  ];

  useEffect(() => {
    setStep("intro");
    localStorage.removeItem("new_user");
  }, []);

  return (
    <Fragment>
      <div style={{ maxWidth: "614px" }}>
        <Spacer bottom={50} />
        <Title>Para continuar o cadastro da sua empresa, veja se:</Title>
      </div>
      <Spacer bottom={25} />

      {roles.map((data: string) => {
        return (
          <>
            <div className="d-flex align-items-start">
              <img title="icon" src={IconCheck} />
              <Spacer left={16} />
              <span>{data}</span>
            </div>
            <Spacer bottom={16} />
          </>
        );
      })}

      <Spacer bottom={14} />
      <span style={{ fontSize: "24px" }}>Documentos necessários:</span>
      <Spacer bottom={10} />

      {docsRequireds.map((data: string) => {
        return (
          <>
            <div className="d-flex align-items-center ">
              <span
                style={{
                  fontSize: "1.5rem",
                  color: theme.colors.secondary.main,
                }}
              >
                °
              </span>
              <Spacer left={16} />
              <span>{data}</span>
            </div>
          </>
        );
      })}
    </Fragment>
  );
};

export default Intro;
