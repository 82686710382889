import {
  Button,
  LateralModal,
  ProgressBar,
  RadioGroup,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useEffect } from "react";
import useAddBalance from "~/hooks/useAddBalance";
import AddBalanceData from "./AddBalanceData";
import ConfirmData from "./ConfirmData";
import Done from "./Done";
import { motion } from "framer-motion";
import useToken from "~/hooks/useToken";
import LoaderAddBalance from "./Loader";
import AddBalanceSelectPixKey from "./AddBalanceSelectPixKey";
import { useLocation } from "react-router-dom";

type radioOptions = {
  title: string;
  description: string;
  value: string;
};

const radioOptions: radioOptions[] = [
  {
    title: "Boleto",
    description: `Adicionar saldo através do boleto simples.`,
    value: "0",
  },

  {
    title: "PIX",
    description: "Adicionar saldo através de Pix Qr code ou copia e cola",
    value: "1",
  },
];

const AddBalanceModal = () => {
  const { setToken } = useToken();
  const location = useLocation();

  const {
    setChooseTypeAddBalance,
    chooseTypeAddBalance,
    openAddBalanceModal,
    setNewChargeData,
    setOpenAddBalanceModal,
    stepIndex,
    setStepIndex,
    isLoading,
    setIsEmpty,
    isEmpty,
    isPIX,
    selectedValue,
    setSelectedValue,
    setChangeSideAnimation,
    setAmount,
    setIndexPixKeyToCharge,
  } = useAddBalance();

  const percentage: number[] = [20, isPIX ? 40 : 60, isPIX ? 75 : 0];
  const allowedPaths: string[] = ["/app/pix"];
  const isPIXLocation: boolean = allowedPaths.includes(location.pathname);

  const rendersAddBalance: React.ReactNode[] = [
    <AddBalanceData />,
    isPIX ? <AddBalanceSelectPixKey /> : <ConfirmData />,
    isPIX ? <ConfirmData /> : <Done />,
    isPIX && <Done />,
  ];

  const renderLabelsAddBalanceModal: string[] = [
    isPIXLocation ? "Nova cobrança via QR Code" : "Adicionar saldo",
    isPIX ? "Selecione a chave Pix" : "Confirmação de adição de saldo",
    isPIX && isPIXLocation
      ? "Nova cobrança via QR Code"
      : isPIX
      ? "Confirmação de adição de saldo"
      : "Boleto gerado",
    isPIX && isPIXLocation
      ? "Cobrança via QR Code Gerada"
      : isPIX
      ? "Qr Code Criado"
      : "",
  ];

  const handleChange = (value: any) => {
    setSelectedValue(value);

    if (value === "0") {
      setSelectedValue("billet");
      setIsEmpty(false);
    }

    if (value === "1") {
      setSelectedValue("pix");
      setIsEmpty(false);
    }
  };

  const handleAdvanceClick = () => {
    if (selectedValue === "") {
      alert("Selecione uma opção");
    } else {
      if (selectedValue === "billet") {
        setChooseTypeAddBalance("billet");
        setChangeSideAnimation(true);
      }

      if (selectedValue === "pix") {
        setChooseTypeAddBalance("pix");
        setChangeSideAnimation(true);
      }
    }
  };

  const handleToggleModal = () => {
    setOpenAddBalanceModal(!openAddBalanceModal);
    setChooseTypeAddBalance(null);
    setSelectedValue("");
    setToken("");
    setAmount(0);
    setIndexPixKeyToCharge(-1);
  };

  useEffect(() => {
    if (openAddBalanceModal === false) {
      setStepIndex(0);
      setToken("");
      setIsEmpty(true);
      setIndexPixKeyToCharge(-1);
    }
  }, [openAddBalanceModal]);

  return (
    <LateralModal
      toggleModal={handleToggleModal}
      open={openAddBalanceModal}
      header
      title={
        isLoading && isPIX
          ? "Gerando Qr code..."
          : isLoading
          ? "Gerando boleto..."
          : renderLabelsAddBalanceModal[stepIndex]
      }
    >
      <>
        {!isLoading &&
          ((!isPIX && stepIndex !== 2) || (isPIX && stepIndex !== 3)) && (
            <ProgressBar percent={percentage[stepIndex]} />
          )}

        {isLoading ? (
          <LoaderAddBalance />
        ) : chooseTypeAddBalance === null ? (
          <motion.div
            initial={{
              x: "-100%",
            }}
            animate={{ x: 0 }}
            exit={{
              x: "100%",
            }}
            transition={{ duration: 0.3 }}
            className="vh-100"
          >
            <div className=" w-100 d-flex flex-column justify-content-start p-4  ">
              <RadioGroup
                display="row"
                options={radioOptions}
                wrap
                value=""
                onChange={(value) => handleChange(value)}
                emptyValue={isEmpty}
              />
              <Spacer bottom={50} />
              <div className="d-flex w-100 justify-content-between">
                <Button
                  mode="border"
                  label="Cancelar"
                  onClick={handleToggleModal}
                />
                <Button
                  mode="main"
                  label="Avançar"
                  onClick={() => {
                    handleAdvanceClick();
                  }}
                  disabled={selectedValue === ""}
                />
              </div>
            </div>
          </motion.div>
        ) : (
          <> {rendersAddBalance[stepIndex]} </>
        )}
      </>
    </LateralModal>
  );
};

export default AddBalanceModal;
