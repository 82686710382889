import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Button,
  CustomSelect,
  Input,
  InputCurrency,
  LateralModal,
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";
import DeleteIcon from "~/assets/icons/delete.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import usePermission from "~/hooks/usePermission";
import useToast from "~/hooks/useToast";
import { onlyNumbers, mergeYupSchemas, formatCPFCNPJ } from "~/utils";
import {
  Subtitle,
  ContainerDetails,
  ContainerBottom,
} from "../app-content.styles";
import Description from "~/components/Texts/Description";
import theme from "~/themes/theme";
import { ImgPen } from "../Contacts/WithContacts";
import documentNumberValidator from "~/utils/validateDocNumber";
import styled, { keyframes } from "styled-components";

type Values = {
  document: string;
  sign_weight: string;
  permissions_role?: number;
  role: string;

  identity_number: string;
  name: string;
  birth_date: string;
  gender: string;
  mothers_name: string;
  email: string;
  mobile_phone: string;
  zipcode: string;
  number: string;
  complement: string;
};

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
    /* Cor que deseja quando a imagem estiver completamente expandida */
  }
`;

export const ImgDelete = styled.img`
  height: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.error.mainFilter};
  }
`;

const initialValues: Values = {
  document: "",
  sign_weight: "0.00",
  permissions_role: undefined,
  role: "",

  identity_number: "",
  name: "",
  birth_date: "",
  gender: "",
  mothers_name: "",
  email: "",
  mobile_phone: "",
  zipcode: "",
  number: "",
  complement: "",
};

const FormSchema = Yup.object().shape({
  document: Yup.string(),
  sign_weight: Yup.number()
    .min(0, "")
    .max(100, "deve ser menor ou igual a 100"),
  permissions_role: Yup.number(),
  role: Yup.string(),
});

const FormSchemaWithNewPartner = Yup.object().shape({
  identity_number: Yup.string().required("Campo requerido"),
  name: Yup.string().required("Campo requerido"),
  birth_date: Yup.string().required("Campo requerido"),
  gender: Yup.string().required("Campo requerido"),
  mothers_name: Yup.string().required("Campo requerido"),
  email: Yup.string().required("Campo requerido"),
  mobile_phone: Yup.string().required("Campo requerido"),
});

const NewConcessionModal = () => {
  const {
    openNewConcessionModal,
    setOpenNewConcessionModal,
    rolePermissions,
    createConcession,
    submitConcession,
    partnerCheck,
  } = usePermission();

  const { showToast } = useToast();

  const [initVals, setInitVals] = useState(initialValues);
  const [registeredPartner, setRegisteredPartner] = useState<any>();
  const [fetched, setFetched] = useState<boolean>(false);
  const [partnerId, setPartnerId] = useState<number>(-1);

  const handleFormSubmit = (values: Values) => {
    const sign_weight = parseFloat(values.sign_weight);

    const payload = {
      type: "individual",
      ...values,
      document: onlyNumbers(values.document),
      document_number: onlyNumbers(values.document),
      mobile_phone: onlyNumbers(values.mobile_phone),
      identity_number: onlyNumbers(values.identity_number),
      birth_date: values.birth_date,
      fetched,
      sign_weight,
      partnerId,
    };
    createConcession(payload);
  };

  const getSchema = useCallback(() => {
    if (fetched) {
      return FormSchema;
    }
    const merged = mergeYupSchemas(FormSchema, FormSchemaWithNewPartner);
    return merged;
  }, [fetched]);

  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    resetForm,
    submitForm,
    touched,
    handleBlur,
  } = useFormik({
    validationSchema: getSchema(),
    initialValues: initVals,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  const searchPartner = async (_document: string) => {
    const document_number = onlyNumbers(_document);

    if (document_number.length === 11 || document_number.length === 14) {
      const response = await partnerCheck(document_number);

      if (response && response.status) {
        showToast(
          "Preenchimento automático",
          "Parceiro encontrado e selecionado, você pode remover caso tenha digitado errado",
          "success"
        );
        setRegisteredPartner(response);
        setPartnerId(response.id);
        setFetched(true);
      }
    }
  };

  useEffect(() => {
    if (openNewConcessionModal) {
      setInitVals(initialValues);
      resetForm();
      setFetched(false);
      setRegisteredPartner(undefined);
      setPartnerId(-1);
    }
  }, [openNewConcessionModal]);

  const columns: any[] = [
    {
      label: "Parceiro",
      key: "name",
      renderData: (data: any) => (
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <span>{data.name}</span>
            <Description color={theme.colors.base.dark100}>
              {formatCPFCNPJ(values.document)}
            </Description>
          </div>

          <div id={`delete-${data.id}`}>
            <ImgDelete
              style={{
                height: "20px",
              }}
              src={DeleteIcon}
              onClick={() => {
                setRegisteredPartner(undefined);
                setFetched(false);
                setPartnerId(-1);
                {
                }
              }}
            />
            <NotControlledToolTip placement="top" target={`delete-${data.id}`}>
              {"Remover"}
            </NotControlledToolTip>
          </div>
        </div>
      ),
    },
  ];

  const requiredFields = [
    "birth_date",
    "document",
    "email",
    "gender",
    "identity_number",
    "mobile_phone",
    "mothers_name",
    "name",
    "number",

    "role",
    "sign_weight",
    "zipcode",
  ];

  const disabledButton = useMemo(() => {
    const value: any = values;
    const disable =
      requiredFields.some((field) => value[field] === "") ||
      values.permissions_role === undefined;

    return disable;
  }, [values]);

  const [error, setError] = useState<string>("");
  useEffect(() => {
    const isValidDocument =
      values.document.length >= 14 && documentNumberValidator(values.document);

    if (values.document.length < 14 && !isValidDocument) {
      setError("initialState");
    } else if (values.document.length >= 14 && isValidDocument) {
      setError("notError");
    } else if (values.document.length >= 14 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document]);

  return (
    <LateralModal
      open={openNewConcessionModal}
      title="Nova concessão"
      toggleModal={() => setOpenNewConcessionModal(!openNewConcessionModal)}
      header
    >
      <div className=" w-100 vh-100 d-flex flex-column justify-content-start p-4">
        <ContainerDetails>
          <form className="col-12">
            <div className="row ">
              <div className="col-12">
                {!registeredPartner ? (
                  <Input
                    label="CPF/CNPJ"
                    error={error === "Documento inválido" && error}
                    required
                    mask={
                      values.document.length > 14
                        ? "99.999.999/9999-99"
                        : "999.999.999-9999999"
                    }
                    onChange={(props: any) => {
                      handleChange(props);
                    }}
                    onBlur={handleBlur}
                    onKeyUp={(event) => {
                      const _target: any = event.target;
                      searchPartner(_target.value);
                    }}
                    placeholder="Digite um documento"
                    name="document"
                    success={error === "notError"}
                    value={values.document}
                    disabled={registeredPartner !== undefined}
                  />
                ) : null}
                {registeredPartner ? (
                  <>
                    <div className="col-12  pt-5">
                      <Table
                        columns={columns}
                        data={[registeredPartner] as any[]}
                      />
                    </div>{" "}
                  </>
                ) : null}
              </div>
              <div className="col-6">
                {openNewConcessionModal ? (
                  <CustomSelect
                    error={values.role === "" && errors.role}
                    label="Papel"
                    required
                    onChange={({ target: { value } }) =>
                      setFieldValue("role", value)
                    }
                    isSearchable={false}
                    options={[
                      {
                        label: "Proprietário",
                        value: "proprietario",
                      },
                      {
                        label: "Administrador",
                        value: "administrador",
                      },
                      {
                        label: "Financeiro",
                        value: "financeiro",
                      },
                      {
                        label: "Sócio",
                        value: "socio",
                      },
                      {
                        label: "Representante",
                        value: "representante",
                      },
                      {
                        label: "Devedor",
                        value: "devedor",
                      },
                      {
                        label: "Fiduciário",
                        value: "fiduciario",
                      },
                    ]}
                    placeholder="Selecione"
                  />
                ) : null}
              </div>
              <div className="col-6">
                {openNewConcessionModal ? (
                  <CustomSelect
                    error={!values.permissions_role && errors.permissions_role}
                    required
                    label="Permissão"
                    onChange={({ target: { value } }) =>
                      setFieldValue("permissions_role", value)
                    }
                    options={[
                      ...rolePermissions?.map((_perm) => ({
                        label: _perm.name,
                        value: _perm.id,
                      })),
                    ]}
                    isSearchable={false}
                    placeholder="Selecione"
                  />
                ) : null}
              </div>
              <div className="col-6">
                <InputCurrency
                  label="Peso da assinatura"
                  isPercentage
                  fontSize="normal"
                  inputSize="normal"
                  required={true}
                  onChange={handleChange}
                  type="text"
                  placeholder="%"
                  name="sign_weight"
                  value={values.sign_weight}
                  error={!values.sign_weight && errors.sign_weight}
                />
              </div>

              {/* NEW PARTNER */}
              {!registeredPartner ? (
                <>
                  <div className="col-6">
                    <Input
                      label="Nome completo"
                      required
                      onChange={handleChange}
                      type="text"
                      placeholder=""
                      name="name"
                      value={values.name}
                      error={!values.name && touched.name && errors.name}
                    />
                  </div>
                  <div className="col-6">
                    {openNewConcessionModal ? (
                      <CustomSelect
                        error={
                          values.gender === "" &&
                          touched.gender &&
                          errors.gender
                        }
                        label="Gênero"
                        required
                        onChange={({ target: { value } }) =>
                          setFieldValue("gender", value)
                        }
                        options={[
                          {
                            label: "Masculino",
                            value: "male",
                          },
                          {
                            label: "Feminino",
                            value: "female",
                          },
                        ]}
                        placeholder="Selecione"
                      />
                    ) : null}
                  </div>
                  <div className="col-6">
                    <Input
                      label="RG"
                      required
                      onChange={handleChange}
                      type="text"
                      placeholder=""
                      name="identity_number"
                      value={values.identity_number}
                      error={
                        !values.identity_number &&
                        touched.document &&
                        errors.identity_number
                      }
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      label="Data de nascimento"
                      required
                      onChange={handleChange}
                      type="date"
                      name="birth_date"
                      value={values.birth_date}
                      error={
                        !values.birth_date &&
                        touched.birth_date &&
                        errors.birth_date
                      }
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      label="Nome da mãe"
                      required
                      onChange={handleChange}
                      type="text"
                      placeholder=""
                      name="mothers_name"
                      value={values.mothers_name}
                      error={
                        !values.mothers_name &&
                        touched.mothers_name &&
                        errors.mothers_name
                      }
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      label="E-mail"
                      required
                      onChange={handleChange}
                      type="email"
                      placeholder=""
                      name="email"
                      value={values.email}
                      error={!values.email && touched.email && errors.email}
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      label="Celular"
                      required
                      onChange={handleChange}
                      type="phone"
                      placeholder="(99) 99999-9999"
                      name="mobile_phone"
                      mask="(99) 99999-9999"
                      value={values.mobile_phone}
                      error={
                        !values.mobile_phone &&
                        touched.mobile_phone &&
                        errors.mobile_phone
                      }
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      label="CEP"
                      required
                      onChange={handleChange}
                      type="text"
                      placeholder="99999-999"
                      mask="99999-999"
                      name="zipcode"
                      value={values.zipcode}
                      error={!values.zipcode && errors.zipcode}
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      label="Número"
                      required
                      onChange={handleChange}
                      type="text"
                      placeholder=""
                      name="number"
                      value={values.number}
                      error={!values.number && errors.number}
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      label="Complemento"
                      onChange={handleChange}
                      type="text"
                      placeholder=""
                      name="complement"
                      value={values.complement}
                      error={!values.complement && errors.complement}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </form>
        </ContainerDetails>

        <Spacer bottom={55} />
        <ContainerBottom>
          <div className="w-100 d-flex justify-content-between ">
            <Button
              mode="phantom"
              label="Cancelar"
              onClick={() => setOpenNewConcessionModal(false)}
            />

            <Button
              mode="main"
              label="Conceder"
              onClick={() => submitForm()}
              disabled={
                registeredPartner
                  ? values.permissions_role === undefined || values.role === ""
                  : disabledButton
              }
              loading={submitConcession}
            />
          </div>
        </ContainerBottom>
        <div style={{ paddingBottom: "25px" }}></div>
      </div>
    </LateralModal>
  );
};

export default NewConcessionModal;
