import constate from "constate";
import { ReactElement, useState } from "react";
import dataStatements from "~/data/extrato";
import ApiService from "~/services/api";
import { subDays } from "date-fns";
import { getToday, payloadDatesToParam } from "~/utils";
import { statementTaxes } from "~/data/options";

const initialFilterParams = {
  from_date: getToday(),
  to_date: getToday(),
};

const [StatementsProvider, useStatements] = constate(() => {
  const [filteredTransactions, setFilteredTransactions] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [openExportStatmentsModal, setOpenExportStatmentsModal] =
    useState<boolean>(false);
  const [currentModalData, setCurrentModalData] = useState<any>();
  const [openStatementsDetailsModal, setOpenStatementsDetailsModal] =
    useState<boolean>(false);
  const [statementsDataChart, setStatementsDataChart] = useState<any[]>([]);
  const [selectedVouchers, setSelectedVouchers] = useState<any>([]);

  const [isLoadingTransactions, setIsLoadingTransactions] =
    useState<boolean>(false);

  const [statusColumnSelected, setStatusColumnSelected] =
    useState<boolean>(false);

  const transactions = dataStatements.filter(
    (transaction) =>
      transaction.operation_type !== "InitialBalance" &&
      transaction.operation_type !== "FinalBalance"
  );

  const [statements, setStatements] = useState<any[]>([]);
  const [statistics, setStatistics] = useState<any>();

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState(0);

  const [filterParams, setFilterParams] = useState<any>(initialFilterParams);

  const [approvers, setApprovers] = useState<any>();
  const [loadingApprovers, setLoadingApprovers] = useState<boolean>(false);

  const [details, setDetails] = useState<any>();
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);

  const setFetchParams = (updatedParams = {}) => {
    setFilterParams((prevFilters: any) => ({
      ...prevFilters,
      ...updatedParams,
    }));
  };

  const getStatements = async (fastParams = {}) => {
    setStatements([]);
    setIsLoadingTransactions(true);

    const params = payloadDatesToParam({
      ...filterParams,
      ...fastParams,
    });

    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/statement/",
        token: true,
        params: {
          ...params,
        },
      });

      if (data && data.results.length > 0) {
        setStatements(data.results.reverse());
        setStatistics(data.statistics);

        setIsLoadingTransactions(false);
      }

      setIsLoadingTransactions(false);
    } catch (error) {
      console.warn("err", error);
    }
    setIsLoadingTransactions(false);
  };

  const getStatistics = async (fastParams = {}) => {
    setStatistics([]);
    setStatementsDataChart([]);
    setIsLoadingTransactions(true);

    const params = payloadDatesToParam({
      ...filterParams,
      ...fastParams,
    });

    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/statement/",
        token: true,
        params: {
          ...params,
        },
      });

      if (data && data.results.length > 0) {
        setStatistics(data.statistics);
        setStatementsDataChart(data.results);

        setIsLoadingTransactions(false);
      }

      setIsLoadingTransactions(false);
    } catch (error) {
      console.warn("err", error);
    }
    setIsLoadingTransactions(false);
  };

  const getApprovers = async (external_id: string, operation_type: string) => {
    setLoadingApprovers(true);

    try {
      setApprovers(undefined);

      const { data: response } = await ApiService.HttpPost({
        route: "charge/operation-by-external-id/",
        token: true,
        body: {
          external_id,
          operation_type,
        },
      });

      if (response && !response.error) {
        setApprovers(response.data);
      }
    } catch (err: any) {}
    setLoadingApprovers(false);
  };

  const getDetailedOperation = async (
    external_id: string,
    operation_type: string
  ) => {
    setLoadingDetails(true);

    try {
      setDetails(undefined);
      const { data: response } = await ApiService.HttpPost({
        route: "charge/statement-detailed/",
        token: true,
        body: {
          external_id,
          operation_type,
        },
      });

      if (response && !response.error) {
        setDetails(response.data);
      }
    } catch (err: any) {}
    setLoadingDetails(false);
  };

  const getDescription = (data: any): string => {
    const { tags } = data;

    if (tags.includes("card_transaction")) {
      return "TRANSAÇÃO DE CARTÃO";
    }

    const taxes = Object.keys(statementTaxes);

    const whitelisttags = ["giveback", "split", ...taxes];

    if (tags.some((item: any) => whitelisttags.includes(item))) {
      let tagType = "";

      if (tags.includes("giveback")) {
        tagType = "ESTORNO TARIFA";
      } else if (tags.includes("split")) {
        tagType = "SPLIT";
      } else {
        tagType = "TARIFA";
      }

      const tax = !tags.includes("split")
        ? statementTaxes[tags.filter((_x: any) => _x !== "giveback")[0]]
        : data.description;

      return `${tagType} - ${tax}`;
    }

    return data.description;
  };

  return {
    filteredTransactions,
    setFilteredTransactions,
    openModal,
    setOpenModal,
    openFilterModal,
    setOpenFilterModal,
    openStatementsDetailsModal,
    setOpenStatementsDetailsModal,
    currentModalData,
    setCurrentModalData,
    transactions,
    selectedVouchers,
    setSelectedVouchers,
    statements,
    setStatements,
    isLoadingTransactions,
    setIsLoadingTransactions,

    statistics,
    setStatistics,
    getStatistics,

    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    statusColumnSelected,
    setStatusColumnSelected,

    getStatements,
    filterParams,
    setFetchParams,

    approvers,
    setApprovers,
    loadingApprovers,
    setLoadingApprovers,
    getApprovers,

    details,
    setDetails,
    loadingDetails,
    setLoadingDetails,
    getDetailedOperation,

    getDescription,
    openExportStatmentsModal,
    setOpenExportStatmentsModal,
    statementsDataChart,
    setStatementsDataChart,
  };
});

export { StatementsProvider };

export default useStatements;
