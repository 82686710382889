import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Spacer } from "@zilliondigital/mirage-ui";
import QrCode from "~/assets/images/qr-code.png";
import { useState } from "react";

const Security = () => {
  const [link, setLink] = useState(
    "https://dominio.com.br/cadastro_correntis…hdubne"
  );

  return (
    <div className="d-flex flex-column justify-content-center col-8  h-175">
      <span style={{ fontSize: "32px" }}>
        Agora é hora de fazermos uma verificação de segurança
      </span>
      <Spacer bottom={20} />
      <span className="col-10" style={{ fontSize: "14px" }}>
        Para poder finalizar o cadastro precisamos tirar uma foto de
        verificação, para isso você vai precisar do seu documento com foto e um
        smartphone com câmera frontal. Basta escanear o QR Code abaixo para
        prosseguir ou abrir o link informado no navegador do seu smartphone.
      </span>
      <img style={{ width: "210px" }} src={QrCode} />

      <div className="d-flex">
        <div className="d-flex flex-column">
          <span style={{ fontSize: "12px", color: "#FF7E41" }}>{link}</span>
          <span style={{ fontSize: "10px", color: "#A4A3A3" }}>
            Link de acesso à validação de segurança
          </span>
        </div>
        <Spacer left={20} />
        <div>
          <Button
            mode="border"
            label="Copiar link"
            onClick={() => navigator.clipboard.writeText(link)}
          />
        </div>
      </div>
    </div>
  );
};

export default Security;
