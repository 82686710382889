
import React from "react";
import ContentLoader from "react-content-loader";

const BalanceLoaders = (props: any) => {
  return (
    <ContentLoader
      viewBox="0 0 778 116"
      width={778}
      height={116}
      backgroundColor="#d9d9d9"
      foregroundColor="#ededed"
      {...props}
    >
      <rect x="0" y="0" rx="2" ry="2" width="0" height="0" />
      <rect x="28" y="42" rx="2" ry="2" width="100" height="18" />

      <rect x="28" y="71" rx="2" ry="2" width="180" height="32" />
      <rect x="434" y="94" rx="2" ry="2" width="0" height="0" />
      <rect x="29" y="116" rx="2" ry="2" width="749" height="32" />
    </ContentLoader>
  );
};

export default BalanceLoaders;
