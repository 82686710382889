import { useEffect, useState } from "react";
import { ActivateCardCheckingImg, ActivateCardCheckingStepDiv, ActivateCardTitle, ActivateCardContainer, CardsDashboardHeader, ActivateCardReceivedWrapperDiv, ActivateCardInputWrapper, BlockCardInputWrapper, BlockCardDescription, BlockCardInput, ActivateCardInputError } from "../styled";
import useCards from "~/hooks/Cards/useCards";
import { Button, InputToken, Spacer } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import CardImg from '~/assets/images/credit--card.svg'
import { BlockCardSteps, BlockingCardStatus } from "~/hooks/Cards/interfaces";

interface IBlockCardConfirmationStep {
  setBlockCardStep: React.Dispatch<React.SetStateAction<BlockCardSteps>>;
  blockCardStep: BlockCardSteps;
}
const BlockCardConfirmationStep = (props: IBlockCardConfirmationStep) => {
  const {
    blockingCardStatus,
    postBlockBankCard,
    setBlockCardStep
  } = useCards()
  const [password, setPassword] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [buttonLabel, setButtonLabel] = useState<string>('Faltam 4 dígitos')

  useEffect(() => {
    if(blockingCardStatus == 'SUCCESS'){
      setBlockCardStep('BLOCKED')
    }
  }, [blockingCardStatus])

  const _handleConfirmToken = () => {
    if(password.length == 4){
      postBlockBankCard(password)
    }
  }

  const _handleInputChange = (value: string) => {
    setPassword(value)
    
    const no_digits = value.length
    if(no_digits == 4){
      setButtonLabel('Confirmar')
      setIsDisabled(false)
    }else if(no_digits == 3){
      setButtonLabel('Falta 1 dígito')
      setIsDisabled(true)
    }else{
      setButtonLabel(`Faltam ${4 - no_digits} dígitos`)
      setIsDisabled(true)
    }
  }

  const _returnErrorMessage = (status: BlockingCardStatus) => {
    if(status == 'SERVER_ERROR'){
      return(
        <ActivateCardInputError>
          Houve um erro no processamente de sua requisição, tente novamente.
        </ActivateCardInputError>
      )
    }else if(status == 'AUTHENTICATION_ERROR'){
      return(
        <ActivateCardInputError>
          Senha incorreta.
        </ActivateCardInputError>
      )
    }else{
      return <></>
    }
  }

  return (
    <ActivateCardCheckingStepDiv>
      <ActivateCardCheckingImg src={CardImg}/>
      <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
        <ActivateCardTitle>
          Validação de segurança
        </ActivateCardTitle>
        <BlockCardDescription>
          Informe a senha de 4 dígitos de seu cartão.
        </BlockCardDescription>
      </div>
      <BlockCardInputWrapper>
        <BlockCardInput 
          name="code_for_card_block"
          type="password"
          placeholder="Senha"
          maxLength={4}
          onKeyDown={(e) => {e.key == 'Enter' && _handleConfirmToken()}}
          onChange={(e) => {_handleInputChange(e.currentTarget.value)}}
          disabled={blockingCardStatus == 'LOADING'}
        />
        {
          (
            blockingCardStatus &&
            ['SERVER_ERROR', 'AUTHENTICATION_ERROR'].includes(blockingCardStatus)
          ) ? _returnErrorMessage(blockingCardStatus) : <></>
        }
        <Spacer bottom={40} />
        <Button
          mode="main"
          label={blockingCardStatus == 'LOADING' ? 'Processando' : buttonLabel}
          full
          onClick={_handleConfirmToken}
          disabled={blockingCardStatus == 'LOADING' ? true : isDisabled}
        />
      </BlockCardInputWrapper>
    </ActivateCardCheckingStepDiv>
  );
};

export default BlockCardConfirmationStep;
