import { render } from "react-dom";
import constate from "constate";
import { useState } from "react";

const [SplitProvider, useSplit] = constate(() => {
  const [openNewSplitModal, setOpenNewSplitModal] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [stepIndexModal, setStepIndexModal] = useState<number>(0);
  const [newSplit, setNewSplit] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(8);
  const [page, setPage] = useState(0);

  const [selectedValues, setSelectedValue] = useState<string>("none");

  const [splitsData, setSplitsData] = useState<any>([]);
  const [newSplitPayload, setNewSplitPayload] = useState<any>([]);
  const [newPartnerSplitData, setNewPartnerSplitData] = useState<any>([]);

  const [receivers, setReceivers] = useState<any>([]);
  const [depositIsByKeyPix, setDepositIsByKeyPix] = useState<boolean>(false);

  const [percentOrAmount, setPercentOrAmount] = useState<string>("");
  const [splitData, setSplitData] = useState<any>([]);

  const [openDeleteSplitModal, setOpenDeleteSplitModal] =
    useState<boolean>(false);

  const [openDeleteBeneficiarieModal, setOpenDeleteBeneficiarieModal] =
    useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [beneficiarieData, setbeneficiarieData] = useState<any>([]);

  const [openEditReceiverModal, setOpenEditReceiverModal] =
    useState<boolean>(false);

  const [value, setValue] = useState<string>("");

  return {
    openNewSplitModal,

    setOpenNewSplitModal,
    setStepIndex,
    stepIndex,
    setNewSplit,
    newSplit,
    setSplitsData,
    splitsData,
    page,
    openDeleteSplitModal,
    setOpenDeleteSplitModal,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    stepIndexModal,
    setStepIndexModal,
    selectedValues,
    setSelectedValue,
    newSplitPayload,
    setNewSplitPayload,
    newPartnerSplitData,
    setNewPartnerSplitData,
    receivers,
    setReceivers,
    setDepositIsByKeyPix,
    depositIsByKeyPix,
    percentOrAmount,
    setPercentOrAmount,
    splitData,
    setSplitData,
    openDeleteBeneficiarieModal,
    setOpenDeleteBeneficiarieModal,
    beneficiarieData,
    setbeneficiarieData,
    isEditing,
    setIsEditing,
    openEditReceiverModal,
    setOpenEditReceiverModal,
    value,
    setValue,
  };
});

export { SplitProvider };

export default useSplit;
