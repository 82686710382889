import {
  BalanceAlert,
  Button,
  Input,
  InputCurrency,
  Spacer,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";
import EyeIcon from "~/assets/icons/round_visibility_black_24dp.png";
import EyeOffIcon from "~/assets/icons/round_visibility_off_black_36dp.png";
import styled from "styled-components";

import { Fragment, useEffect, useState } from "react";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";

import { useFormik } from "formik";
import { onlyNumbers } from "~/services/utils";
import useToken from "~/hooks/useToken";
import { convertedAmount, formatNumberToCurrencyWithoutSimbol } from "~/utils";
import hideFormatDocumentNumber from "~/utils/hideDocumentNumber";

import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";
import CustomerTax from "~/components/CustomerTax";
import { ContainerBottom } from "../../app-content.styles";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";

const Container = styled.div`
  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;
  }
  padding: 20px;

  .image-eye {
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  .container-balance {
    position: relative;
    top: -20px;
  }

  .hide-balance {
    position: absolute;
    left: 180px;
  }
`;

type FormValues = {
  amount: string;
  description: string;
};

const initialValues: FormValues = {
  amount: "",
  description: "",
};

const SendPix = () => {
  const [error, setError] = useState(false);
  const {
    hideAvailableBalance,
    setHideAvailableBalance,
    mainAccount,
    balance,
    setInsufficientBalance,
    insufficientBalance,
    balanceFormatted,
    getBalance,
  } = useAccount();

  const { dataKeyPixSelectedPartner } = useNewTransfer();

  const {
    setOpenValidatorModalTransferWithPix,
    setIsLoadingTransferReceipt,
    setValidation,
    handlePrevStep,
    dataNewPixTransfer,
    amount,
    setAmount,
    setTransferReceiptRender,
    setDataTransferPixReceipt,
    contactSelectedPixData,
    idBankAccountPixPartner,
    selectedIndex,
    setTaxTransferPix,
    taxTransferPix,
  } = useTransferWithPix();

  const { showToast } = useToast();

  const { values, handleChange } = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {},
  });

  const { sendPartnerToken, getToken } = useToken();

  const fetchTax = async () => {
    try {
      const response = await ApiService.HttpPost({
        route: "customer-global-tax/",
        token: true,
        body: {
          slug: "pix_transfer",
          tax_is_enabled: true,
        },
      });
      setTaxTransferPix(response.data.customer_tax_out);
    } catch (err) {
      console.error("Erro", err);
    }
  };

  const sendPix = async () => {
    sendPartnerToken();

    const token = await getToken();

    setIsLoadingTransferReceipt(true);

    try {
      const { data: response } = await ApiService.HttpPost({
        route: "pix/transfer/",
        body: {
          amount: amount,
          code: token,
          key: dataKeyPixSelectedPartner.key,

          sender_account: 1,
          type: "key",
          description: values.description ?? "Sem descrição",
        },
        token: true,
      });

      setValidation(false);

      if (!response.error) {
        const { data } = response;
        setTransferReceiptRender(true);
        setDataTransferPixReceipt(data);
        setIsLoadingTransferReceipt(false);

        setTimeout(() => {
          showToast(
            "Pix realizado com sucesso",
            "Sua transação foi enviada com sucesso.",
            "success"
          );
        }, 500);

        setTimeout(() => {
          getBalance();
        }, 2000);
      } else {
        showToast("Pix não realizado", response.message, "error");
      }
    } catch (err: any) {
      console.warn(err);

      setIsLoadingTransferReceipt(false);

      setValidation(false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (isNaN(amount) || amount <= 0) {
        setError(true);
      } else if (amount <= balance) {
        sendPix();
      }
    }
  };

  useEffect(() => {
    fetchTax();
  }, []);

  useEffect(() => {
    setAmount(convertedAmount(onlyNumbers(values.amount)));
  }, [values.amount]);

  useEffect(() => {
    if (amount + taxTransferPix > balance) {
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }
  }, [amount]);

  const handleClick = (e: any) => {
    if (isNaN(amount) || amount <= 0) {
      setError(true);
    } else if (amount <= balance) {
      sendPix();
    }
  };

  return (
    <Fragment>
      <Container className="p-4">
        <p>
          {`Defina o valor que quer transferir para 
            ${contactSelectedPixData && contactSelectedPixData[0]?.name} `}
        </p>
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-100">
            <InputCurrency
              isPercentage={false}
              id="amount"
              type="text"
              name="amount"
              onChange={handleChange}
              value={values.amount}
              error={error && "O valor precisa ser maior que 0"}
              inputSize="large"
              label=""
              onFocus={() => setError(false)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>

          {/* <div className="ms-2 pb-5 ">
              <Description color="#666666">Agendar</Description>
              <Toggle mode="large" />
            </div> */}
        </div>
        <Spacer bottom={error ? -15 : -25} />

        <div className="container-balance">
          <div className="d-flex flex-wrap justify-content-start align-items-center mt-4">
            <Description color="#666666">
              {`Saldo disponível atual é de
        `}
            </Description>
            <Spacer left={3} />

            <Description
              className={
                !hideAvailableBalance
                  ? "font-weight-bolder "
                  : "font-weight-bolder opacity-0 "
              }
            >
              {balanceFormatted}
            </Description>

            <Description
              className={
                hideAvailableBalance
                  ? "font-weight-bolder   hide-balance   "
                  : "font-weight-bolder opacity-0 hide-balance  "
              }
            >
              R$ ****
            </Description>

            <Spacer left={5} />
            <img
              className="image-eye"
              src={!hideAvailableBalance ? EyeIcon : EyeOffIcon}
              onClick={() => setHideAvailableBalance(!hideAvailableBalance)}
            />
          </div>
          <div>
            <Description color="#666666">{`Taxa `}</Description>
            <Description className="font-weight-bolder">
              R$ {formatNumberToCurrencyWithoutSimbol(taxTransferPix)}{" "}
            </Description>
          </div>
        </div>

        {insufficientBalance && (
          <>
            {" "}
            <Spacer bottom={-10} />
            <BalanceAlert
              title="Saldo insuficiente"
              message="Essa transferência não poder ser feita por saldo insuficiente"
            />
          </>
        )}
        <Spacer top={10} />
        <div>
          {" "}
          <table className=" w-100">
            <tbody>
              <tr>
                <td>
                  <Description color="#666666">Quando</Description>
                </td>

                <td className="second-column">
                  <Description>Agora</Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">
                    Tipo de transferência
                  </Description>
                </td>

                <td className="second-column">
                  <Description>PIX</Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">Instituição</Description>
                </td>

                <td className="second-column">
                  <Description>
                    {dataKeyPixSelectedPartner.bank_account_data.bank_data.name}
                  </Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">Agência</Description>
                </td>

                <td className="second-column">
                  <Description>
                    {dataKeyPixSelectedPartner.bank_account_data?.agency}
                  </Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">Conta</Description>
                </td>

                <td className="second-column">
                  <Description>
                    {dataKeyPixSelectedPartner.bank_account_data
                      ?.account_number +
                      "-" +
                      dataKeyPixSelectedPartner.bank_account_data
                        .account_number_digit}
                  </Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">CPF</Description>
                </td>

                <td className="second-column">
                  <Description>
                    {hideFormatDocumentNumber(
                      contactSelectedPixData &&
                        contactSelectedPixData[0]?.document_number
                    )}
                  </Description>
                </td>
              </tr>

              {/* <tr>
              <td>
                <Description color="#666666">Identificador</Description>
              </td>

              <td className="second-column">
                <Description>{dataNewPixTransfer?.identifier}</Description>
              </td>
            </tr> */}
            </tbody>
          </table>
          <Spacer bottom={20} />
          <Input
            type="textarea"
            label="Descrição"
            name="description"
            value={values.description}
            onChange={handleChange}
          />
          <Spacer bottom={70} />
        </div>

        <ContainerBottom>
          <div className="d-flex justify-content-between ">
            <Button mode="phantom" label="Cancelar" onClick={handlePrevStep} />
            <Button
              mode="main"
              label="Transferir agora"
              onClick={handleClick}
              disabled={insufficientBalance}
            />
          </div>
        </ContainerBottom>
      </Container>
    </Fragment>
  );
};

export default SendPix;
