import {
  Button,
  CustomSelect,
  Input,
  LateralModal,
  ProgressBar,
  Spacer,
} from "@zilliondigital/mirage-ui";
import * as Yup from "yup";
import { REQUIRED_LABEL } from "~/services/validators";
import { useFormik } from "formik";
import Description from "~/components/Texts/Description";
import useBanks from "~/hooks/useBanks";
import useContacts from "~/hooks/Contacts/useContacts";
import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";
import { useEffect, useState } from "react";
import { APIResponse, getcontacts, setBankAccount } from "~/types";
import { useParams } from "react-router-dom";

type FormValues = {
  name: string;
  document_number: string;
  email: string;
  account_number_with_digit: string;
  agency: string;
};

const initialValues: FormValues = {
  name: "",
  document_number: "",
  email: "",
  account_number_with_digit: "",
  agency: "",
};

const FormSchema = Yup.object().shape({
  account_number_with_digit: Yup.string().required(REQUIRED_LABEL),
  agency: Yup.string().required(REQUIRED_LABEL),
});

const AddBankAccountModal = () => {
  const { getBanks, bankOptions, banksData } = useBanks();

  const {
    setOpenAddBankAccountModal,
    openAddBankAccountModal,
    contactsData,
    setContactsData,
    contactIndex,
    setPartnerDetails,
  } = useContacts();

  const { showToast } = useToast();

  const [selectedOption, setSelectedOption] = useState<any>(null);
  const bankCode = selectedOption?.target.value;
  const [loadingButton, setIsloadingButton] = useState<boolean>(false);

  const params = useParams<{ id: string }>();

  const { values, handleChange, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    onSubmit: (values) => {},
  });

  const enabledButton =
    bankCode !== undefined &&
    values.account_number_with_digit.length > 0 &&
    values.agency.length > 0;

  const removeLastCharacter = (str: string) => {
    return str.slice(0, -1);
  };

  const getLastCharacter = (str: string) => {
    return str.slice(-1);
  };

  const handleToggleModal = () => {
    setOpenAddBankAccountModal(!openAddBankAccountModal);
  };

  const handleSelectChange = (selectedValue: any) => {
    setSelectedOption(selectedValue);
  };

  const getContacts = async () => {
    const { data } = await ApiService.HttpGet<APIResponse<getcontacts>>({
      route: "partner/",
      token: true,
      params: {
        default: false,
      },
    });

    setContactsData(data.results);
  };

  const setBankAccount = async () => {
    setIsloadingButton(true);
    try {
      const data = await ApiService.HttpPost<setBankAccount>({
        route: "bank-account/",
        body: {
          account_number: removeLastCharacter(values.account_number_with_digit),
          account_number_digit: getLastCharacter(
            values.account_number_with_digit
          ),
          agency:
            values.agency.length < 4
              ? values.agency.padStart(4, "0")
              : values.agency,
          bank: bankCode,
          partner: params.id,
        },
        token: true,
      });

      setIsloadingButton(false);

      setOpenAddBankAccountModal(false);

      showToast(
        "Conta adicionada",
        "A conta bancária foi adicionada ao contato com sucesso",
        "success"
      );

      resetForm();
      getPartner();
    } catch (e) {
      console.warn(e);
      resetForm();

      showToast(
        "Ops!",
        "Não foi possível cadastrar a conta bancária, tente novamente mais tarde",
        "success"
      );

      setIsloadingButton(false);
    }
  };

  const getPartner = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "partner/",
        token: true,
        params: {
          type: "contact",
          default: false,
          id: params.id,
        },
      });

      setPartnerDetails(data.results);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (contactsData.length <= 0) {
      getContacts();
    }

    banksData?.length <= 0 && getBanks();
  }, []);

  return (
    <LateralModal
      toggleModal={handleToggleModal}
      open={openAddBankAccountModal}
      header
      title={"Adicionar conta bancária"}
    >
      <>
        <ProgressBar percent={50} />

        <div className="p-3 vh-100  ">
          <Spacer bottom={20} />
          <div className="d-flex justify-content-center">
            <Description color="#666666" className="">
              Para continuar é necessário informar os dados de quem receberá a
              transferência
            </Description>
          </div>
          <Spacer bottom={50} />
          <div className="row align-items-end">
            <div className="col-12">
              <CustomSelect
                label="Instituição"
                placeholder="Selecione"
                options={bankOptions}
                onChange={handleSelectChange}
              />
            </div>

            {/* <div className="col-6">
              {" "}
              <CustomSelect label="Tipo de conta" placeholder="Selecione" />
            </div> */}
            <div className="col-6">
              {" "}
              <Input
                name="agency"
                label="Agência"
                value={values.agency}
                onChange={handleChange}
                placeholder="0001"
                helper="Sem dígito"
                mask="9999"
              />
            </div>
            <div className="col-6">
              {" "}
              <Input
                name="account_number_with_digit"
                value={values.account_number_with_digit}
                onChange={handleChange}
                label="Conta"
                placeholder="12456780"
                helper="Com dígito"
                mask="9999999999999999999999999999999"
              />
            </div>
          </div>

          <Spacer bottom={50} />

          <div className="d-flex w-100 justify-content-between pe-4 ps-2">
            <Button
              mode="phantom"
              label="Cancelar"
              onClick={handleToggleModal}
            />
            <Button
              mode="main"
              label="Salvar dados bancários"
              disabled={!enabledButton}
              onClick={setBankAccount}
              loading={loadingButton}
            />
          </div>
        </div>
      </>
    </LateralModal>
  );
};

export default AddBankAccountModal;
