import { Fragment, useEffect } from "react";
import Title from "~/components/Texts/Title";
import { ActivateCardCheckingButtonsDiv, ActivateCardCheckingImg, ActivateCardCheckingStepDiv, ActivateCardTitle, ActivateCardContainer, CardsDashboardHeader, ActivateCardReceivedWrapperDiv, ActivateCardInputWrapper } from "../styled";
import useAccount from "~/hooks/useAccount";
import useCards from "~/hooks/Cards/useCards";
import { Button, Input } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import CardImg from '~/assets/images/credit--card.svg'
import { ActivateCardSteps } from "~/hooks/Cards/interfaces";

interface ICheckingActivateCardStep {
  setActivateCardStep: React.Dispatch<React.SetStateAction<ActivateCardSteps>>;
  activateCardStep: ActivateCardSteps;
}
const ActivateCardCheckingStep = (props: ICheckingActivateCardStep) => {

  return (
    <ActivateCardCheckingStepDiv>
      <ActivateCardCheckingImg src={CardImg}/>
      <ActivateCardTitle>
        Você recebeu seu cartão?
      </ActivateCardTitle>
      <ActivateCardCheckingButtonsDiv>
        <Button 
          label="Recebi o cartão" 
          mode='main'
          onClick={() => {props.setActivateCardStep('RECEIVED')}}
        />
        <Button 
          label="Não recebi o cartão" 
          mode='border'
          style={{borderColor: '#29183A', color: '#29183A'}}
          onClick={() => {props.setActivateCardStep('NOT_RECEIVED')}}
        />
      </ActivateCardCheckingButtonsDiv>
    </ActivateCardCheckingStepDiv>
  );
};

export default ActivateCardCheckingStep;
