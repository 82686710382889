import { Table } from "@zilliondigital/mirage-ui"
import { buildMyCardsColumnStatements } from "./myCardsColumnStatements"
import { Card } from "~/hooks/Cards/interfaces"

interface IMyCardsTable {
  data: Card[]
}
export const MyCardsTable = ({
  data
}: IMyCardsTable) => {
  const columnsStatements: any = buildMyCardsColumnStatements()

  return(
    <div className="w-100 px-4">
      <Table
        data={data}
        columns={columnsStatements}
        dataSpacer={8}
      />
    </div>
  )
}