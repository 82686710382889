export const account_type_dict: Record<string, string> = {
  account: "Conta",
  representative: "Representante",
  contact: "Contato",
  vendor: "Fornecedor",
  factory: "Fábrica",
  fund: "Fundo",
  permission: "Permissão",
  shipment: "Remessa",
  pix: "Pix",
  approver: "Aprovador",
  api: "API",
};
