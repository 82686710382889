import "bootstrap/dist/css/bootstrap.min.css";
import {
  ButtonMoreDetails,
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";

import styled, { keyframes } from "styled-components";
import { Fragment, useEffect, useState } from "react";
import PenIcon from "~/assets/icons/contacts/pencil.png";

import Description from "~/components/Texts/Description";
import { Title } from "../app-content.styles";
import SupplierIcon from "~/assets/icons/contacts/supplier.png";
import ColaboratteOrCostumerIcon from "~/assets/icons/contacts/collaborate-or-costumer.png";
import EmailIcon from "~/assets/icons/contacts/Ic_context_email.png";
import PhoneIcon from "~/assets/icons/contacts/Ic_context_telephone.png";
import AddressIcon from "~/assets/icons/contacts/Ic_context_pin.png";
import RepresentativeIcon from "~/assets/icons/contacts/collaborate-or-costumer.png";

import AttachamentsIcon from "~/assets/icons/icon-attachments-active.png";

import InputDataIconActive from "~/assets/icons/icon-inputdata-active.png";

import ApiService from "~/services/api";
import useContacts from "~/hooks/Contacts/useContacts";
import { getFirstLetters } from "~/services/utils";
import EyeIcon from "~/assets/icons/contacts/eye-Size=MD.svg";
import { formatCPFCNPJ, formatDate, formatDateHeader } from "~/utils";
import { applyPagination } from "~/components/Paginator";
import useAccount from "~/hooks/useAccount";
import theme from "~/themes/theme";
import label from "~/labels";
import useBackoffice from "~/hooks/useBackoffice";
import { useLocation, useNavigate } from "react-router-dom";
import NoFindContacts from "./NoFindContacts";

type RenderTypeProps = {
  [key: string]: string;
};

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
    /* Cor que deseja quando a imagem estiver completamente expandida */
  }
`;

const ImgEye = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

const ImgPen = styled.img`
  height: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

const renderType: RenderTypeProps = {
  customer: "Cliente",
};

const ContainerData = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }
`;

type renderImageRoleType = {
  [key: string]: string;
};

const renderIconRole: renderImageRoleType = {
  supplier: SupplierIcon,
  customer: ColaboratteOrCostumerIcon,
  employee: ColaboratteOrCostumerIcon,
};

const WithContats = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    pendings,
    page,
    rowsPerPage,
    setPendingsLoading,
    setIsDetails,
    setIsEdit,
    setPendingDocuments,
    dataRender,
    renderFilter,
    setDetailsData,
  } = useBackoffice();

  const { verifyPermission } = useAccount();

  const canCreateContact = verifyPermission("ba_can_create_contacts");

  const [buttonMoreOptions, setButtonMoreOptions] = useState<any>([
    {
      label: "Editar contato",
      onClick: () => {
        setIsDetails(true);
        setIsEdit(true);
      },
      icon: PenIcon,
    },
  ]);

  const columns = [
    {
      label: "Nome/Razão Social",
      key: "name/razao_social",

      renderData: (data: any) => {
        return (
          <ContainerData>
            <div className="container-profile-picture">
              <div className="rounded-circle profile-picture">
                <h1>{getFirstLetters(data.name)}</h1>
              </div>
            </div>

            <Spacer left={20} />
            <div className="d-flex flex-column">
              <span>{data.name}</span>

              <span style={{ color: "#929292" }}>
                {formatCPFCNPJ(data.document_number)}
              </span>
            </div>
          </ContainerData>
        );
      },
    },
    {
      label: "Dados cadastrados",
      key: "dados_cadastrados",

      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-center ">
            <div>
              <img src={renderIconRole[data.type]} />
            </div>

            {data.has_documents == true && (
              <div
                className="d-flex justify-content-start align-items-center"
                id={`attachments-${data.id}`}
              >
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <img src={AttachamentsIcon} />
                  </div>
                </>
                <NotControlledToolTip
                  placement="top"
                  target={`attachments-${data.id}`}
                >
                  {"Anexos completos"}
                </NotControlledToolTip>
              </div>
            )}

            {data.has_address == true && (
              <div
                className="d-flex justify-content-start align-items-center"
                id={`address-${data.id}`}
              >
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <img src={AddressIcon} />
                  </div>
                </>
                <NotControlledToolTip
                  placement="top"
                  target={`address-${data.id}`}
                >
                  {"Possui endereço"}
                </NotControlledToolTip>
              </div>
            )}

            {data.has_representatives == true && (
              <div
                className="d-flex justify-content-start align-items-center"
                id={`representative-${data.id}`}
              >
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <img src={RepresentativeIcon} />
                  </div>
                </>
                <NotControlledToolTip
                  placement="top"
                  target={`representative-${data.id}`}
                >
                  {"Possui representante(s)"}
                </NotControlledToolTip>
              </div>
            )}
          </div>
        );
      },
    },
    {
      label: "Última atualização",
      key: "last_update",
      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-center ">
            <div>
              <Description>
                {formatDate(data.last_update, "datetime")}
              </Description>
            </div>
          </div>
        );
      },
    },
    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => {
              setDetailsData(data);

              /* Verify route is in backoffice */
              /* with hooks */

              const parentRoute = location.pathname.split("/")[1];

              if (parentRoute === "app") {
                navigate(`/app/backoffice/${data.id}`);
              } else {
                navigate(`/backoffice/pending/${data.id}`);
              }
            }}
          >
            <div
              className="d-flex justify-content-start align-items-center"
              id={`eye-${data.id}`}
            >
              <ImgEye src={EyeIcon} />

              <NotControlledToolTip placement="top" target={`eye-${data.id}`}>
                {"Visualizar detalhes"}
              </NotControlledToolTip>
            </div>

            {/* <ButtonMoreDetails options={buttonMoreOptions} /> */}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {renderFilter.length <= 0 ? (
        <NoFindContacts />
      ) : (
        <Table
          data={applyPagination(renderFilter ?? pendings[dataRender], {
            page,
            rowsPerPage,
          })}
          columns={columns}
          dataSpacer={10}
        />
      )}
    </div>
  );
};

export default WithContats;
