import { useEffect, useState } from "react";
import { Button, Margin, Spacer, Table } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import useStatements from "~/hooks/useStatements";
import DownloadIcon from "~/assets/icons/buttons/download.png";
import { ContainerDetails, ContainerBottom } from "../../app-content.styles";
import TableLoader from "~/components/SkeletonTables";
import Title from "~/components/Texts/Title";
import { formatCash, formatDateTime, formatCPFCNPJ } from "~/utils";
import { statement_operations_dict } from "~/data/statement";
import { format, parseISO } from "date-fns";

const GenericDetails = () => {
  const {
    currentModalData,
    openStatementsDetailsModal,

    approvers,
    setApprovers,
    loadingApprovers,
    getApprovers,

    details,
    setDetails,
    loadingDetails,
    getDetailedOperation,

    getDescription,
  } = useStatements();

  const [hasApproval, setHasApproval] = useState<boolean>(false);

  const getOperationType = (): string => {
    const { operation_type_id, amount } = currentModalData;

    /* APENAS SAIDAS: SIGNIFICAM EFETIVAÇÕES DE OPERAÇÕES APROVADAS */
    if (amount < 0) {
      const canOperateStatementTypes = [2, 3, 40, 6];

      if (
        canOperateStatementTypes.includes(currentModalData.operation_type_id)
      ) {
        const availableOperationTypes: any = {
          2: "billet_payment",
          40: "pix_transfer",
          3: "ted",
          6: "tev",
        };

        const foundType = availableOperationTypes[operation_type_id];

        if (!foundType) {
          setHasApproval(false);
          return "";
        }

        setHasApproval(true);
        return foundType;
      }
    }
    setHasApproval(false);
    return "";
  };

  useEffect(() => {
    if (openStatementsDetailsModal) {
      const operationType = getOperationType();
      const { identifier, operation_type } = currentModalData;

      if (identifier) {
        if (operationType) {
          getApprovers(identifier, operationType);
        }
        if (operation_type) {
          getDetailedOperation(identifier, operation_type);
        }
      }
    } else {
      setApprovers(undefined);
    }
  }, [openStatementsDetailsModal, currentModalData]);

  const renderApprovers = () => {
    if (!hasApproval) {
      return <></>;
    }

    if (loadingApprovers) {
      return (
        <>
          <Spacer top={30} />
          <TableLoader />
        </>
      );
    }

    if (approvers) {
      return (
        <>
          <Spacer top={30} />

          <Title>Lista dos Aprovadores</Title>

          <Spacer top={5} />
          {!approvers.operation_approvers.length ? (
            <small>Ainda não há aprovadores...</small>
          ) : (
            <Table
              dataSpacer={10}
              columns={[
                {
                  key: "",
                  label: "Aprovador",
                  renderData: (data: any) => {
                    return (
                      <>
                        {data.partner_data.name} -
                        {formatCPFCNPJ(data.partner_data.document_number)}
                      </>
                    );
                  },
                },
                {
                  key: "",
                  label: "Peso da assinatura",

                  renderData: (data: any) => {
                    return <>{data.sign_weight}%</>;
                  },
                },
              ]}
              data={
                approvers.operation_approvers
                  ? approvers.operation_approvers
                  : []
              }
            />
          )}
        </>
      );
    }
    return <></>;
  };

  const renderTransferDetails = () => {
    return (
      <>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Status da transação</Description>
              </td>

              <td className="second-column">
                <Description>{details?.status_display ?? "--"}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">ID da transação</Description>
              </td>

              <td className="second-column">
                <Description>{details?.id ?? "--"}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Tipo de transferência</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.category_display === "Tax"
                    ? "Tarifa"
                    : details?.category_display}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Valor</Description>
              </td>

              <td className="second-column">
                <Description>{formatCash(details?.amount || 0)}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Data de criação</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details.created_date
                    ? format(
                        parseISO(details?.created_date),
                        "dd/MM/yyyy HH:mm:ss"
                      )
                    : "--"}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Descrição</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.description ? details?.description : "-"}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Mensagem de retorno</Description>
              </td>

              <td className="second-column">
                <Description>{details?.status_description || "-"}</Description>
              </td>
            </tr>
          </tbody>
        </table>

        <Spacer bottom={40} />
        <Title>Origem</Title>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Nome</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.full_bank_account.owner.name}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Documento</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatCPFCNPJ(
                    details?.full_bank_account.owner.document_number
                  )}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Instituição</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details.full_bank_account.bank_data.code} -{" "}
                  {details?.full_bank_account.bank_data.name}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Agência</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.full_bank_account.agency.padStart(4, 0)}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Conta</Description>
              </td>

              <td className="second-column">
                <Description>{`${details?.full_bank_account.account_number}-${details?.full_bank_account.account_number_digit}`}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Tipo de conta</Description>
              </td>

              <td className="second-column">
                <Description>{details?.full_bank_account.type}</Description>
              </td>
            </tr>
          </tbody>
        </table>
        <Spacer bottom={40} />

        <Title>Destino</Title>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Nome</Description>
              </td>

              <td className="second-column">
                <Description>{details?.full_recipient.name}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Documento</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatCPFCNPJ(details?.full_recipient.document_number)}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Instituição</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details.recipient_bank_account.bank_data.code} -{" "}
                  {details?.recipient_bank_account.bank_data.name}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Agência</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.recipient_bank_account.agency.padStart(4, 0)}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Conta</Description>
              </td>

              <td className="second-column">
                <Description>{`${details?.recipient_bank_account.account_number}-${details?.recipient_bank_account.account_number_digit}`}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Tipo de conta</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.recipient_bank_account.type}
                </Description>
              </td>
            </tr>
          </tbody>
        </table>
        <Margin bottom={100} />
      </>
    );
  };

  const renderPixDetails = () => {
    const pixTypes: Record<string, string> = {
      key: "Chave",
      bank_account: "Conta Bancária",
      qr_code: "QR Code",
      mass_transfer: "Transferência em massa",
    };

    return (
      <>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Status da transação</Description>
              </td>

              <td className="second-column">
                <Description>{details?.status_display ?? "--"}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">ID da transação</Description>
              </td>

              <td className="second-column">
                <Description>{details?.id ?? "--"}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Tipo de transferência</Description>
              </td>

              <td className="second-column">
                <Description>Transferência PIX</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Transferência via</Description>
              </td>

              <td className="second-column">
                <Description>
                  {pixTypes[details.type] || "Não identificado"}
                </Description>
              </td>
            </tr>

            {details.key && (
              <tr>
                <td>
                  <Description color="#666666">Chave</Description>
                </td>

                <td className="second-column">
                  <Description>{details.key.key}</Description>
                </td>
              </tr>
            )}

            <tr>
              <td>
                <Description color="#666666">Valor</Description>
              </td>

              <td className="second-column">
                <Description>{formatCash(details?.amount || 0)}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Data de criação</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details.created_date
                    ? format(
                        parseISO(details?.created_date),
                        "dd/MM/yyyy HH:mm:ss"
                      )
                    : "--"}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Descrição</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.description ? details?.description : "-"}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Mensagem de retorno</Description>
              </td>

              <td className="second-column">
                <Description>{details?.status_description || "-"}</Description>
              </td>
            </tr>
          </tbody>
        </table>

        <Spacer bottom={40} />
        <Title>Origem</Title>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Nome</Description>
              </td>

              <td className="second-column">
                <Description>{details?.sender_account.owner.name}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Documento</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatCPFCNPJ(details?.sender_account.owner.document_number)}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Instituição</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details.sender_account.bank_data.code} -{" "}
                  {details?.sender_account.bank_data.name}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Agência</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.sender_account.agency.padStart(4, 0)}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Conta</Description>
              </td>

              <td className="second-column">
                <Description>{`${details?.sender_account.account_number}-${details?.sender_account.account_number_digit}`}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Tipo de conta</Description>
              </td>

              <td className="second-column">
                <Description>{details?.sender_account.type}</Description>
              </td>
            </tr>
          </tbody>
        </table>
        <Spacer bottom={40} />

        <Title>Destino</Title>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Nome</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.receiver_account.owner.name}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Documento</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatCPFCNPJ(
                    details?.receiver_account.owner.document_number
                  )}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Instituição</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details.receiver_account.bank_data.code} -{" "}
                  {details?.receiver_account.bank_data.name}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Agência</Description>
              </td>

              <td className="second-column">
                <Description>
                  {details?.receiver_account.agency.padStart(4, 0)}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Conta</Description>
              </td>

              <td className="second-column">
                <Description>{`${details?.receiver_account.account_number}-${details?.receiver_account.account_number_digit}`}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Tipo de conta</Description>
              </td>

              <td className="second-column">
                <Description>{details?.receiver_account.type}</Description>
              </td>
            </tr>
          </tbody>
        </table>
        <Margin bottom={100} />
      </>
    );
  };

  const renderPayedBilletDetails = () => {
    return <></>;
  };

  const renderReceivedBilletDetails = () => {
    return <></>;
  };

  const renderDetails = () => {
    if (details) {
      const renderDict: Record<string, () => JSX.Element> = {
        PreDebitPixOut: renderPixDetails,
        CancelDebitPixOut: renderPixDetails,

        PreDischargeAccount: renderTransferDetails,
        InternalDischargeAccount: renderTransferDetails,

        PreDebitBoletoOut: renderPayedBilletDetails,

        CreditBoleto: renderReceivedBilletDetails,
      };

      const renderFunction = renderDict[currentModalData.operation_type];

      return (
        <>
          <Spacer top={30} />

          <Title>Detalhes da Operação</Title>

          <Spacer top={5} />

          {renderFunction ? renderFunction() : <></>}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <ContainerDetails>
        <Title>Detalhes do Extrato</Title>

        <Spacer top={5} />

        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">
                  {currentModalData.amount > 0 ? "Entrada" : "Saída"}
                </Description>
              </td>

              <td className="second-column">
                <Description>{formatCash(currentModalData.amount)}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Tipo de Movimentação</Description>
              </td>

              <td className="second-column">
                <Description>
                  {statement_operations_dict[currentModalData?.type] || "-"}
                </Description>
              </td>
            </tr>
            {/* <tr>
              <td>
                <Description color="#666666">Status</Description>
              </td>

              <td className="second-column">
                <Description>
                  {operationStatus[currentModalData?.status]}
                </Description>
              </td>
            </tr> */}
            <tr>
              <td>
                <Description color="#666666">Data de transação</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatDateTime(currentModalData.entry_date)}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Descrição</Description>
              </td>

              <td className="second-column">
                <Description>{getDescription(currentModalData)}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Tags</Description>
              </td>

              <td className="second-column">
                {currentModalData.tags?.join(", ")}
              </td>
            </tr>
          </tbody>
        </table>

        {renderDetails()}

        {renderApprovers()}

        <Spacer bottom={50} />
      </ContainerDetails>

      <ContainerBottom>
        <div className="d-flex justify-content-end">
          <Button
            mode="border"
            label="Abrir comprovante"
            icon={DownloadIcon}
            disabled={!currentModalData.voucher_url}
            onClick={() => {
              currentModalData.type_id === 0
                ? window.open(currentModalData.raw.voucher_url, "_blank")
                : window.open(currentModalData.voucher, "_blank");
            }}
          />
        </div>
      </ContainerBottom>
    </>
  );
};

export default GenericDetails;
