import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Breadcrumb,
  Button,
  Spacer,
  ButtonDropdown,
  InteractiveModal,
  NotControlledToolTip,
} from "@zilliondigital/mirage-ui";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import {
  ContainerData,
  GeneralContainer,
} from "~/pages/App/app-content.styles";

import usePermission from "~/hooks/usePermission";
import NewPermissionsModal from "./PermissionModal";
import TitleModal from "~/components/Texts/TitleModal";
import Paginator, { applyPagination } from "~/components/Paginator";
import DeleteIcon from "~/assets/icons/delete-icon-small.png";
import ShieldIcon from "~/assets/icons/shield.svg";
import EditIcon from "~/assets/icons/round_edit_black_24dp.png";
import Skeleton from "~/components/SkeletonTables";
import Copyright from "~/components/Copyright";

const PermissionGroupStructure = () => {
  const {
    getPermissions,
    rolePermissions,
    loadingList,
    setIsEditPermission,
    setOpenNewPermissionModal,
    setCurrentPermission,
    removePermission,
    currentPermission,
    submitPermission,

    setPage,
    setRowsPerPage,
    rowsPerPage,
    page,
  } = usePermission();

  const navigate = useNavigate();
  const [canRemove, setCanRemove] = useState<boolean>(false);

  const menusBreadCrumb = [
    { label: "Permissões", onClick: () => navigate("/app/permissions") },
    { label: "Definições", href: "app/payments" },
  ];

  const quantityPages = [
    {
      label: "20 por página",
      value: "1",
      onClick: () => {
        setRowsPerPage(20);
      },
    },
    {
      label: "50 por página",
      value: "2",
      onClick: () => {
        setRowsPerPage(50);
      },
    },
    {
      label: "100 por página",
      value: "3",
      onClick: () => {
        setRowsPerPage(100);
      },
    },
  ];

  const columns = [
    {
      label: "Nome",
      key: "name",
      renderData: (data: any) => <div>{data.name}</div>,
    },
    {
      label: "Descrição",
      key: "slug",
      renderData: (data: any) => (
        <div className="ps-4 pe-5">
          {data.permissions.length > 0 ? (
            data.permissions.length > 8 ? (
              <>
                {data.permissions
                  .slice(0, 7)
                  .map((perm: any, index: number) => (
                    <span key={perm.id}>
                      {perm.name}
                      {index < 6 ? ", " : "..."}
                    </span>
                  ))}
              </>
            ) : (
              <>
                {data.permissions.map((perm: any, index: number) => (
                  <span key={perm.id}>
                    {perm.name}
                    {index < data.permissions.length - 1 ? ", " : "."}
                  </span>
                ))}
              </>
            )
          ) : (
            <span>Nenhuma permissão cadastrada.</span>
          )}
        </div>
      ),
    },

    {
      label: "",
      key: "",

      renderData: (data: any) => {
        return (
          <div className="buttons-box btn-icon-30">
            <Button
              id={`btn-edit-${data.id}`}
              mode="phantom"
              label=""
              iconPosition="right"
              onClick={() => {
                setCurrentPermission(data);
                setOpenNewPermissionModal(true);
                setIsEditPermission(true);
              }}
              icon={EditIcon}
            />
            <Button
              id={`btn-remove-${data.id}`}
              mode="phantom"
              label=""
              iconPosition="right"
              onClick={() => {
                setCurrentPermission(data);
                setCanRemove(true);
              }}
              icon={DeleteIcon}
            />
            <NotControlledToolTip
              placement="top"
              target={`btn-edit-${data.id}`}
            >
              Editar
            </NotControlledToolTip>
            <NotControlledToolTip
              placement="top"
              target={`btn-remove-${data.id}`}
            >
              Remover
            </NotControlledToolTip>
          </div>
        );
      },
    },
  ];

  const handleRemovePermission = async () => {
    setCanRemove(false);
    await removePermission(currentPermission.id);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getPermissions();
    getPermissions(true);
    setPage(0);
  }, []);

  return (
    <GeneralContainer>
      <div className="d-flex flex-column container-fluid w-100  ps-5 pe-5">
        <Spacer top={30} />
        <Breadcrumb
          menus={menusBreadCrumb}
          onClickHome={() => navigate("/app/dashboard")}
        />

        <Spacer top={30} />
        <ContainerData className="ps-4 pe-4">
          <div className="d-flex justify-content-between p-4">
            <Title>Permissões</Title>

            <div>
              <div className="d-flex flex-column flex-lg-row">
                <Button
                  mode="main"
                  label="Criar permissão"
                  onClick={() => {
                    setIsEditPermission(false);
                    setCurrentPermission(undefined);
                    setOpenNewPermissionModal(true);
                  }}
                />

                <Spacer right={10} />
                <div className="d-block d-lg-none">
                  <Spacer bottom={10} />
                </div>
              </div>
            </div>
          </div>

          <div className="pe-4 ps-4">
            <div>
              {rolePermissions.length > 0 ? (
                <ButtonDropdown
                  mode="border"
                  label={`${rowsPerPage} por página`}
                  options={quantityPages}
                />
              ) : null}
            </div>
            <Spacer bottom={20} />

            {/* MAIN */}
            {loadingList ? (
              <Skeleton />
            ) : rolePermissions.length === 0 ? (
              <div className="empty-table-box">
                <img src={ShieldIcon} />
                <span>
                  Nenhuma permissão cadastrada <br />
                  até o momento
                </span>
              </div>
            ) : (
              <>
                <Table
                  data={applyPagination(rolePermissions, { page, rowsPerPage })}
                  columns={columns}
                  dataSpacer={8}
                />
              </>
            )}

            {/* REMOVE MODAL */}
            <InteractiveModal
              open={canRemove}
              title="exclusao"
              size="md"
              toggleModal={() => setCanRemove(!canRemove)}
            >
              <div className="p-4 h-100 d-flex h-100 text-center justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
                <TitleModal>Exclusão de permissão</TitleModal>
                <div className="d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
                  <p>Deseja realmente remover essa permissão</p>
                  <div style={{ width: "290px" }} className="mb-5">
                    <Spacer bottom={5} />
                    <Button
                      mode="main"
                      label="Sim, remover!"
                      full
                      onClick={() => {
                        handleRemovePermission();
                      }}
                      disabled={submitPermission}
                      loading={submitPermission}
                    />
                    <Spacer bottom={5} />
                    <Button
                      mode="border"
                      label="Cancelar"
                      full
                      onClick={() => setCanRemove(false)}
                    />
                  </div>
                </div>
              </div>
            </InteractiveModal>
          </div>

          <Spacer bottom={20} />

          <Spacer top={30} />
        </ContainerData>

        <div className={rolePermissions.length > 0 ? "" : "d-none"}>
          <Paginator
            list={rolePermissions.length > 0 ? rolePermissions : []}
            rowsPerPage={rowsPerPage}
            page={page}
            pageSetter={setPage}
          />

          <Spacer top={rolePermissions.length > 0 ? 2 : 40} />
        </div>

        <Spacer top={40} />

        <Copyright />

        <Spacer top={30} />
      </div>
      <NewPermissionsModal />
    </GeneralContainer>
  );
};

export default PermissionGroupStructure;
