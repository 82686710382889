import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Input, CustomSelect, Spacer } from "@zilliondigital/mirage-ui";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  CnpjRegex,
  PhoneRegex,
  INVALID_FORMAT,
  REQUIRED_LABEL,
} from "~/services/validators";
import { companyTypes } from "~/data/options";
import useSignupCompany, {
  CompanyGeneralValues,
} from "~/hooks/Signup/useSignupCompany";
import useToast from "~/hooks/useToast";
import { faker } from "@faker-js/faker";
import {
  CustomSelectValue,
  generateCNPJ,
  generateCellphone,
  validateEmail,
  validatePhoneNumber,
} from "~/services/utils";
import { useNavigate } from "react-router-dom";
import ApiService from "~/services/api";
import AppStorage from "~/services/storage";
import {
  formatCelphone,
  getDocumentType,
  onlyNumbers,
  verifyCompleteName,
  verifyIfPayloadIsComplete,
} from "~/utils";
import documentNumberValidator from "~/utils/validateDocNumber";

const Title = styled.span`
  font-size: 32px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

const FormSchema = Yup.object().shape({
  document_type: Yup.string().required(REQUIRED_LABEL),
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(CnpjRegex, INVALID_FORMAT),
  mobile_phone: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(PhoneRegex, INVALID_FORMAT),
  name: Yup.string().required(REQUIRED_LABEL),
  email: Yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
  brand_name: Yup.string().required(REQUIRED_LABEL),
  foundation_date: Yup.string().required(REQUIRED_LABEL),
  additional_type: Yup.string().required(REQUIRED_LABEL),
  city_doc_number: Yup.string(),
  state_doc_number: Yup.string(),
});

const General = () => {
  const {
    setStep,
    setValid,
    companyGeneralValues,
    setCompanyGeneralValues,
    setCanAdvance,
    setSending,
    partnerData,
    setPartnerData,
    setPayloadDataCompany,
  } = useSignupCompany();

  const [error, setError] = useState<string>("");

  const handleFormSubmit = async (
    values: CompanyGeneralValues,
    helpers: FormikHelpers<CompanyGeneralValues>
  ) => {};

  const {
    submitForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
    isValid: valid,
  } = useFormik({
    initialValues: companyGeneralValues,
    validationSchema: FormSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const fillForm = () => {
    const fakeValues: CompanyGeneralValues = {
      document_type: "CNPJ",
      document_number: generateCNPJ(),
      mobile_phone: generateCellphone(true),
      name: faker.company.name(),
      email: faker.internet.email(),
      brand_name: faker.company.name(),
      foundation_date: faker.date.past().toISOString().split("T")[0],
      additional_type: "ltda",
      city_doc_number: faker.random.numeric(8),
      state_doc_number: faker.random.numeric(8),
    };

    setCompanyGeneralValues(fakeValues);
  };

  const setFieldsFromPartnerData = useCallback(
    (data: any) => {
      const fieldMapping = {
        document_type: "document_type",
        document_number: "document_number",
        mobile_phone: "mobile_phone",
        name: "name",
        email: "email",
        brand_name: "brand_name",
        foundation_date: "foundation_date",
        additional_type: "additional_type",
        city_doc_number: "city_doc_number",
        state_doc_number: "state_doc_number",
      };
      Object.entries(fieldMapping).forEach(([key, value]) => {
        if (data[key]) {
          setFieldValue(value, data[key]);
        }
      });
    },
    [setFieldValue]
  );

  const isValidCNPJ = useMemo(() => {
    return (
      values.document_number.length > 13 &&
      documentNumberValidator(values.document_number)
    );
  }, [values.document_number]);

  const getPartner = useCallback(async () => {
    const registeredToken = AppStorage.getRegisteredToken();
    try {
      const response = await ApiService.HttpPost({
        route: "partner-default/",
        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });

      setPartnerData(response.data);
    } catch (err) {
      console.error(err);
    }
  }, [setPartnerData]);

  useEffect(() => {
    getPartner();
    window.addEventListener("signupCompanySubmit", submitForm);
    window.addEventListener("fillForm", fillForm);
    setValid(valid);
    setSending(false);
    setStep("general");

    return () => {
      window.removeEventListener("signupCompanySubmit", submitForm);
      window.removeEventListener("fillForm", fillForm);
    };
  }, []);

  useEffect(() => {
    const payload = {
      name: values.name,
      email: values.email,
      brand_name: values.brand_name,
      foundation_date: values.foundation_date,
      mobile_phone: onlyNumbers(values.mobile_phone),
      document_number: onlyNumbers(values.document_number),
      document_type: getDocumentType(values.document_number),
      additional_type: values.additional_type,
      default: true,
      create_internal_account: false,
      create_external_account: true,
      create_associated_account: false,
      type: "account",
      origin: "bank-v2",
      target: "ted",
    };

    setPayloadDataCompany(payload);

    if (
      verifyIfPayloadIsComplete(values, "state_doc_number", "city_doc_number")
    ) {
      setCanAdvance(true);
    } else {
      setCanAdvance(false);
    }
  }, [values]);

  useEffect(() => {
    if (values.document_number.length <= 17 && !isValidCNPJ) {
      setError("initialState");
    } else if (values.document_number.length >= 17 && isValidCNPJ) {
      setError("notError");
    } else if (values.document_number.length > 17 && !isValidCNPJ) {
      setError("CNPJ inválido");
    }
  }, [values.document_number]);

  useEffect(() => {
    if (partnerData?.user) {
      setFieldsFromPartnerData(partnerData);
    }
  }, [partnerData]);

  return (
    <Fragment>
      <div style={{ maxWidth: "614px" }}>
        <Spacer bottom={50} />
        <Title>Geral</Title>
      </div>
      <Spacer bottom={30} />

      <div className="row ">
        <div className="col-12 col-xxl-7 ">
          <form onSubmit={handleSubmit}>
            <div className="row d-flex flex-xl-row flex-column align-items-end ">
              <div className="col-12 col-xl-6">
                <Input
                  type="text"
                  mask="99.999.999/9999-99"
                  name="document_number"
                  value={values.document_number}
                  required={
                    values.document_number.length === 14 &&
                    partnerData?.document_number
                      ? false
                      : error !== "notError"
                  }
                  label="CNPJ"
                  placeholder="00.000.000/0000-00"
                  error={
                    values.document_number.length > 17 && !isValidCNPJ && error
                  }
                  success={
                    values.document_number.length === 14 &&
                    partnerData?.document_number
                      ? true
                      : error === "notError"
                  }
                  onChange={handleChange("document_number")}
                />
              </div>

              <div className="col-12 col-xl-6">
                <Input
                  mask="(99) 99999-9999"
                  type="phone"
                  name="mobile_phone"
                  label="Número de Celular"
                  required={!validatePhoneNumber(values.mobile_phone)}
                  value={values.mobile_phone}
                  success={
                    partnerData?.mobile_phone && values.mobile_phone === ""
                      ? validatePhoneNumber(
                          formatCelphone(partnerData?.mobile_phone)
                        )
                      : validatePhoneNumber(formatCelphone(values.mobile_phone))
                  }
                  placeholder="(00) 00000-000"
                  error={touched.mobile_phone && errors.mobile_phone}
                  onChange={handleChange("mobile_phone")}
                  autoComplete="phone"
                />
              </div>

              <div className="col-12 col-xl-6 ">
                <Input
                  type="text"
                  name="name"
                  value={values.name}
                  required={!verifyCompleteName(values.name)}
                  label="Razão social"
                  placeholder="ACME LTDA"
                  error={touched.name && errors.name}
                  success={verifyCompleteName(values.name)}
                  onChange={(e) => {
                    const uppercasedValue = e.target.value.toUpperCase();
                    handleChange("name")(uppercasedValue);
                  }}
                />
              </div>

              <div className="col-12 col-xl-6">
                <Input
                  type="text"
                  name="brand_name"
                  value={values.brand_name}
                  required={values.brand_name === ""}
                  label="Nome fantasia"
                  placeholder="ACME LTDA"
                  error={touched.brand_name && errors.brand_name}
                  success={values.brand_name !== ""}
                  onChange={handleChange("brand_name")}
                  autoCapitalize="words"
                />
              </div>

              <div className="col-12">
                <Input
                  type="email"
                  name="email"
                  label="Email de contato"
                  required={!validateEmail(values.email)}
                  value={values.email}
                  placeholder="Jhon.doe@mail.com"
                  error={touched.email && errors.email}
                  success={validateEmail(values.email)}
                  onChange={handleChange("email")}
                  autoComplete="email"
                />
              </div>

              <div className="col-12 col-xl-6">
                <Input
                  type="date"
                  name="foundation_date"
                  value={values.foundation_date}
                  required={values.foundation_date === ""}
                  label="Data de abertura"
                  helper="Data em que a empresa foi aberta"
                  error={touched.foundation_date && errors.foundation_date}
                  success={values.foundation_date !== ""}
                  onChange={handleChange("foundation_date")}
                />
              </div>

              <div className="col-12 col-xl-6">
                <CustomSelect
                  label="Natureza Jurídica"
                  name="additional_type"
                  placeholder="Selecione"
                  error={touched.additional_type && errors.additional_type}
                  success={
                    !errors.additional_type && values.additional_type
                      ? true
                      : false
                  }
                  required={values.additional_type === ""}
                  options={companyTypes}
                  menuPlacement="top"
                  value={CustomSelectValue(
                    values.additional_type,
                    companyTypes
                  )}
                  onChange={({ target: { value } }) => {
                    setFieldValue("additional_type", value);
                  }}
                />
              </div>

              <div className="col-12 col-xl-6">
                <Input
                  type="text"
                  mask={"99999999999999999999999999999"}
                  name="city_doc_number"
                  value={values.city_doc_number}
                  label="Inscrição municipal"
                  placeholder=""
                  error={touched.city_doc_number && errors.city_doc_number}
                  success={
                    !errors.city_doc_number && values.city_doc_number
                      ? true
                      : false
                  }
                  onChange={handleChange("city_doc_number")}
                />
              </div>

              <div className="col-12 col-xl-6">
                <Input
                  type="text"
                  name="state_doc_number"
                  mask={"99999999999999999999999999999"}
                  value={values.state_doc_number}
                  label="Inscrição estadual"
                  placeholder=""
                  error={touched.state_doc_number && errors.state_doc_number}
                  success={
                    !errors.state_doc_number && values.state_doc_number
                      ? true
                      : false
                  }
                  onChange={handleChange("state_doc_number")}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default General;
