import "bootstrap/dist/css/bootstrap.min.css";
import {
  ButtonMoreDetails,
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";

import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";
import PenIcon from "~/assets/icons/contacts/pencil.png";

import Description from "~/components/Texts/Description";
import { Title } from "../app-content.styles";
import SupplierIcon from "~/assets/icons/contacts/supplier.png";
import ColaboratteOrCostumerIcon from "~/assets/icons/contacts/collaborate-or-costumer.png";
import EmailIcon from "~/assets/icons/contacts/Ic_context_email.png";
import PhoneIcon from "~/assets/icons/contacts/Ic_context_telephone.png";
import AddressIcon from "~/assets/icons/contacts/Ic_context_pin.png";
import ApiService from "~/services/api";
import useContacts from "~/hooks/Contacts/useContacts";
import { getFirstLetters } from "~/services/utils";
import EyeIcon from "~/assets/icons/contacts/eye-Size=MD.svg";
import { formatCPFCNPJ } from "~/utils";
import { applyPagination } from "~/components/Paginator";
import useAccount from "~/hooks/useAccount";
import theme from "~/themes/theme";
import label from "~/labels";
import { Columns } from "~/types";
import { useNavigate } from "react-router";

type RenderTypeProps = {
  [key: string]: string;
};

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
    /* Cor que deseja quando a imagem estiver completamente expandida */
  }
`;

const ImgEye = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

export const ImgPen = styled.img`
  height: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

const renderType: RenderTypeProps = {
  customer: "Cliente",
  supplier: "Fornecedor",
  service_provider: "Prestador de serviços",
  employee: "Funcionário",
  management: "Diretoria",
  null: "—",
};

const ContainerData = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }
`;

type renderImageRoleType = {
  [key: string]: string;
};

const renderIconRole: renderImageRoleType = {
  supplier: SupplierIcon,
  customer: ColaboratteOrCostumerIcon,
  employee: ColaboratteOrCostumerIcon,
};

const WithContats = () => {
  const {
    contactsData,
    setContactIndex,
    setIsDetails,
    setIsEdit,
    rowsPerPage,
    page,
  } = useContacts();

  const navigate = useNavigate();

  const { verifyPermission } = useAccount();

  const canCreateContact = verifyPermission("ba_can_create_contacts");

  const columns: Columns[] = [
    {
      label: "Nome/Razão Social",
      key: "name/razao_social",

      renderData: (data: any) => {
        return (
          <ContainerData>
            <div className="container-profile-picture">
              <div className="rounded-circle profile-picture">
                <h1>{getFirstLetters(data.name)}</h1>
              </div>
            </div>

            <Spacer left={20} />
            <div className="d-flex flex-column">
              <span>{data.name}</span>

              <span style={{ color: "#929292" }}>
                {formatCPFCNPJ(data.document_number)}
              </span>
            </div>
          </ContainerData>
        );
      },
    },

    {
      label: "Papel",
      key: "type",

      renderData: (data: any) => (
        <Description className={data.type === null ? "ms-3" : "ms-1"}>
          {renderType[data.type]}{" "}
        </Description>
      ),
    },

    {
      label: "Dados cadastrados",
      key: "dados_cadastrados",

      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-center ">
            <div>
              <img src={renderIconRole[data.type]} />
            </div>

            {data.mobile_phone !== "" && (
              <div
                className="d-flex justify-content-start align-items-center"
                id={`telefone-${data.id}`}
              >
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <img src={PhoneIcon} />
                  </div>
                </>
                <NotControlledToolTip
                  placement="top"
                  target={`telefone-${data.id}`}
                >
                  {"Telefone"}
                </NotControlledToolTip>
              </div>
            )}

            {data.email !== "" && (
              <div
                className="d-flex justify-content-start align-items-center"
                id={`email-${data.id}`}
              >
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <img src={EmailIcon} />
                  </div>
                </>
                <NotControlledToolTip
                  placement="top"
                  target={`email-${data.id}`}
                >
                  {"Email"}
                </NotControlledToolTip>
              </div>
            )}

            {data.all_address.length > 0 && (
              <>
                <div
                  className="d-flex justify-content-start align-items-center"
                  id={`address-${data.id}`}
                >
                  <>
                    {" "}
                    <Spacer left={10} />
                    <div>
                      <img src={AddressIcon} />
                    </div>
                  </>
                  <NotControlledToolTip
                    placement="top"
                    target={`address-${data.id}`}
                  >
                    {"Endereço"}
                  </NotControlledToolTip>
                </div>
              </>
            )}
          </div>
        );
      },
    },

    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-center">
            <div
              className="d-flex justify-content-start align-items-center"
              id={`eye-${data.id}`}
            >
              <>
                {" "}
                <Spacer left={10} />
                <div>
                  <ImgEye
                    src={EyeIcon}
                    onClick={() => navigate(`/app/contacts/details/${data.id}`)}
                  />
                </div>
              </>
              <NotControlledToolTip placement="top" target={`eye-${data.id}`}>
                {"Visualizar detalhes"}
              </NotControlledToolTip>
            </div>

            <Spacer left={20} />

            <div
              className="d-flex justify-content-start align-items-center"
              id={`pen-${data.id}`}
            >
              <>
                {" "}
                <Spacer left={10} />
                {canCreateContact && (
                  <div>
                    <ImgPen
                      src={PenIcon}
                      onClick={() => {
                        navigate(`/app/contacts/edit/${data.id}`);
                      }}
                    />
                  </div>
                )}
              </>
              <NotControlledToolTip placement="top" target={`pen-${data.id}`}>
                {"Editar"}
              </NotControlledToolTip>
            </div>
          </div>
        );
      },
    },
  ];

  type TransformedContact = {
    name: string;
    email: string;
    mobile_phone: string;
    all_address: string;
    type: string;
    index: number;
    document_number: string;
    id: number;
  };

  const dataContacts: TransformedContact[] = contactsData.map(
    (contact, index) => ({
      name: contact.name,
      email: contact.email,
      mobile_phone: contact.mobile_phone,
      all_address: contact.all_address,
      type: contact.contact_type,
      index: index,
      document_number: contact.document_number,
      id: contact.id,
    })
  );

  return (
    <div>
      <Table
        data={applyPagination(dataContacts, { page, rowsPerPage })}
        columns={columns}
        dataSpacer={10}
      />
    </div>
  );
};

export default WithContats;
