import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  Spacer,
  SummaryPanel,
} from "@zilliondigital/mirage-ui";

import RefreshIcon from "~/assets/icons/buttons/Ic_functional_refresh.png";

import { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Paginator from "~/components/Paginator";

import WithContats from "./WithContacts";

import useContacts from "~/hooks/Contacts/useContacts";
import ContactsFilter from "./ContactsFilter";
import ApiService from "~/services/api";
import DetailsContact from "./DetailsContact";

import ContactsEmpty from "./ContactsEmpty";
import NewContactStructure from "./NewContact/NewContactStructure";
import TableLoader from "~/components/SkeletonTables";
import useAccount from "~/hooks/useAccount";
import Copyright from "~/components/Copyright";
import useCollections from "~/hooks/Collections/useCollections";

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const GeneralContainerData = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  width: 100%;
  flex-grow: 1;

  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .container-return-button {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .container-auxiliar {
    position: fixed;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
`;

const Title = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

const ContactsStructure = () => {
  const navigate = useNavigate();

  const {
    setOpenFilterModal,
    typeLenght,
    setContactsData,
    contactsData,
    isDetails,
    setIsLoadingPage,

    isLoadingPage,
    section,
    setSection,

    setRowsPerPage,
    rowsPerPage,
    page,
    setPage,
  } = useContacts();

  const { mainAccount, verifyPermission } = useAccount();

  const canCreateContact = verifyPermission("ba_can_create_contacts");

  const { cameToCollectionsOrTransfers, setCameToCollectionsOrTransfers } =
    useCollections();

  const ContactsPanel = [
    {
      label: "Cadastros de clientes",
      balance: String(typeLenght("contact")),
      toggleBalance: true,
      currency: "*",
    },
    {
      label: "Cadastros de colaboradores",
      balance: String(typeLenght("collaborate")),
      toggleBalance: true,
      currency: "*",
    },

    {
      label: "Cadastros de fornecedores",
      balance: String(typeLenght("vendor")),
      toggleBalance: true,
      currency: "*",
    },
  ];

  const quantityPages = [
    {
      label: "20 por página",
      value: "1",
      onClick: () => {
        setRowsPerPage(20);
      },
    },
    {
      label: "50 por página",
      value: "2",
      onClick: () => {
        setRowsPerPage(50);
      },
    },
    {
      label: "100 por página",
      value: "3",
      onClick: () => {
        setRowsPerPage(100);
      },
    },
  ];

  const getContacts = async () => {
    setContactsData([]);
    setIsLoadingPage(true);
    try {
      const { data } = await ApiService.HttpGet({
        route: "partner/",
        token: true,
        params: {
          type: "contact",
          default: false,
        },
      });

      setContactsData(data.results.reverse());
      setIsLoadingPage(false);
    } catch (e) {
      console.warn(e);
      setIsLoadingPage(false);
    }
  };

  const menusBreadCrumb = [
    { label: "Contatos", onClick: () => navigate("/app/contacts") },
  ];

  type RenderSectionType = {
    [key: string]: JSX.Element;
  };

  useEffect(() => {
    !cameToCollectionsOrTransfers && setSection("initial");

    setTimeout(() => {
      setCameToCollectionsOrTransfers(false);
    }, 500);

    if (contactsData.length == 0) {
      getContacts();
    }
  }, []);

  useEffect(() => {
    getContacts();
  }, [mainAccount]);

  const renderSection: RenderSectionType = {
    initial: (
      <Fragment>
        <GeneralContainerData>
          <div className="d-flex flex-column container-fluid w-100 h-100 ps-4 pe-4">
            <Spacer top={30} />
            <Breadcrumb
              menus={menusBreadCrumb}
              onClickHome={() => navigate("/app/dashboard")}
            />
            <Spacer top={30} />

            <ContainerData className="container-fluid ps-4 pe-4">
              <div className="d-flex justify-content-between p-4 ">
                <Title>Contatos cadastrados</Title>
                <div>
                  <div className="d-flex flex-column flex-lg-row">
                    <Button
                      mode="main"
                      label="Cadastrar contato"
                      onClick={() => navigate("/app/contacts/new")}
                      disabled={!canCreateContact}
                    />
                    {contactsData.length > 0 && !isLoadingPage && (
                      <>
                        <Spacer right={10} />
                        <div className="d-block d-lg-none">
                          <Spacer bottom={10} />
                        </div>

                        {/* <Button
                          mode="border"
                          onClick={() => setOpenFilterModal(true)}
                          label="Aplicar filtros"
                          icon={FilterIcon}
                        /> */}
                        <Spacer right={10} />
                      </>
                    )}{" "}
                  </div>
                </div>
              </div>
              <div className="pe-4 ps-4">
                {(contactsData.length > 0 || isLoadingPage) && (
                  <div className="row">
                    <SummaryPanel
                      panel={ContactsPanel}
                      isLoading={isLoadingPage}
                    />
                  </div>
                )}
                <Spacer bottom={20} />
                <div className="d-flex w-100 align-items-start  ">
                  <div className="">
                    <Button
                      mode="border"
                      label="Atualizar"
                      icon={RefreshIcon}
                      onClick={() => getContacts()}
                    />
                  </div>
                  <Spacer left={15} />
                  {contactsData?.length > 0 && (
                    <div>
                      <ButtonDropdown
                        mode="border"
                        label={`${rowsPerPage} por página`}
                        options={quantityPages}
                      />
                    </div>
                  )}
                </div>
              </div>

              <Spacer bottom={20} />
              {isLoadingPage ? (
                <TableLoader />
              ) : contactsData.length > 0 && !isLoadingPage ? (
                <div className="ps-3 pe-4">
                  <WithContats />
                </div>
              ) : (
                <ContactsEmpty />
              )}
            </ContainerData>
            <div className={contactsData.length > 0 ? "" : "d-none"}>
              <Paginator
                list={contactsData.length > 0 ? contactsData : []}
                rowsPerPage={rowsPerPage}
                page={page}
                pageSetter={setPage}
              />
            </div>

            <Spacer top={contactsData.length > 0 ? 20 : 40} />

            <Copyright />

            <Spacer top={30} />
          </div>
        </GeneralContainerData>

        <ContactsFilter />
      </Fragment>
    ),
    details: <DetailsContact />,
    new: <NewContactStructure />,
  };

  return (
    <ParentContainer>
      {isDetails ? <DetailsContact /> : renderSection[section]}
    </ParentContainer>
  );
};

export default ContactsStructure;
