import { useState, useEffect } from "react";
import { ButtonDropdown } from "@zilliondigital/mirage-ui";
import { subDays } from "date-fns";
import useAccount from "~/hooks/useAccount";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

type LastDaysProps = {
  setter?: (_from: Date, _to: Date) => void;
  executeFetch?: (fastParams: any) => void;
  label?: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
`;

const Label = styled.span``;

const today = new Date();

const LastDaysFilter = ({ setter, executeFetch, label }: LastDaysProps) => {
  const { beforeDays, setBeforeDays } = useAccount();
  const location = useLocation();
  const url = location.pathname;

  const keywords = ["collections", "transfers", "payments", "statements"];
  const keyword = keywords.find((kw) => url.includes(kw)) || "";

  const renderNames: { [key: string]: string } = {
    collections: "Boletos gerados",
    statements: "Extrato",
    transfers: "Transferências realizadas",
    payments: "Pagamentos realizados",
  };

  const getPastDaysDate = () => {
    const fromDate = subDays(today, beforeDays);

    if (setter) {
      setter(fromDate, today);
    }

    const fastParams = {
      from_date: fromDate,
      to_date: today,
    };

    if (executeFetch) {
      executeFetch(fastParams);
    }
  };

  const options = [
    {
      label: `${renderNames[keyword]} hoje`,
      value: "4",
      onClick: () => {
        setBeforeDays(0);
      },
    },
    {
      label: `${renderNames[keyword]}  últimos 30 dias`,
      value: "1",
      onClick: () => {
        setBeforeDays(30);
      },
    },
    {
      label: `${renderNames[keyword]}  últimos 60 dias`,
      value: "2",
      onClick: () => {
        setBeforeDays(60);
      },
    },
    {
      label: `${renderNames[keyword]} últimos 90 dias`,
      value: "3",
      onClick: () => {
        setBeforeDays(90);
      },
    },
  ];

  useEffect(() => {
    getPastDaysDate();
  }, [beforeDays]);

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <ButtonDropdown
        mode="border"
        label={
          beforeDays !== 0
            ? `${renderNames[keyword]} últimos ${beforeDays} dias`
            : `${renderNames[keyword]} hoje`
        }
        options={options}
        padding={beforeDays === 0 ? 80 : 0}
      />
    </Container>
  );
};

export default LastDaysFilter;
