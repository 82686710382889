import constate from "constate";
import { useEffect, useState } from "react";
import ApiService from "~/services/api";

const [PixProvider, usePix] = constate(() => {
  const [openTransferWithPixModal, setOpenTransferWithPixModal] =
    useState<boolean>(false);

  const [openPayCopyAndPasteModal, setOpenPayCopyAndPasteModal] =
    useState<boolean>(false);

  const [openManageLimitsModal, setOpenManageLimitsModal] =
    useState<boolean>(false);
  const [openMyKeysModal, setOpenMyKeysModal] = useState<boolean>(false);
  const [openMyLimitsModal, setOpenMyLimitsModal] = useState<boolean>(false);
  const [openGenerateQRCodeModal, setOpenGenerateQRCodeModal] =
    useState<boolean>(false);

  const [dataQRCodeStatic, setDataQRCodeStatic] = useState<any>({});

  const [taxPixLot, setTaxPixLot] = useState<number>(0);

  const [massTransferOperations, setMassTransferOperations] = useState<any[]>(
    []
  );
  const [massTransferOperationsLoading, setMassTransferOperationsLoading] =
    useState<boolean>(false);

  const [file, setFile] = useState<Blob | string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [operations, setOperations] = useState([]);
  const [lotQuantity, setLotQuantity] = useState<number>(0);
  const [renderDropzone, setRenderDropzone] = useState<boolean>(true);
  const [sending, setSending] = useState<boolean>(false);
  const [openPixLotModal, setOpenPixLotModal] = useState<boolean>(false);

  const fetchPixOperations = async () => {
    setMassTransferOperationsLoading(true);
    try {
      const {
        data: { data },
      } = await ApiService.HttpGet({
        route: "pix/mass/transfer/",
        token: true,
      });

      setMassTransferOperations(data);
    } catch (error) {
      console.error(error);
    }
    setMassTransferOperationsLoading(false);
  };

  return {
    openTransferWithPixModal,
    setOpenTransferWithPixModal,
    openPayCopyAndPasteModal,
    setOpenPayCopyAndPasteModal,
    openManageLimitsModal,
    setOpenManageLimitsModal,
    openMyKeysModal,
    setOpenMyKeysModal,
    openMyLimitsModal,
    setOpenMyLimitsModal,
    openGenerateQRCodeModal,
    setOpenGenerateQRCodeModal,
    dataQRCodeStatic,
    setDataQRCodeStatic,
    file,
    setFile,
    submitting,
    setSubmitting,
    operations,
    setOperations,
    lotQuantity,
    setLotQuantity,
    renderDropzone,
    setRenderDropzone,
    sending,
    setSending,
    openPixLotModal,
    setOpenPixLotModal,

    fetchPixOperations,
    massTransferOperations,
    setMassTransferOperations,
    massTransferOperationsLoading,
    setMassTransferOperationsLoading,
    setTaxPixLot,
    taxPixLot,
  };
});

export { PixProvider };

export default usePix;
