import styled from "styled-components";
import { AppLayoutProps } from "./AppLayout";

export const Container = styled.div<AppLayoutProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;

  .c-wrapper-search {
    width: 50%;
    position: fixed;
    z-index: 2;
    transition: all 1s ease;
    top: 20px;
    left: 485px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .search-container {
    @media screen and (max-width: 700px) {
      width: 50%;
    }
  }

  .wrapper-container {
    width: 90%;
    display: flex;
    margin-top: 100px;
  }

  ${(props) =>
    props.searchOpen &&
    `
    .wrapper-container {
      z-index: 2;
    }
    height: 100vh;
  `}
`;
