import {
  RouterProvider,
} from "react-router-dom";

import { Button } from "@zilliondigital/mirage-ui";
import { Fragment, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import IconPencil from "~/assets/icons/buttons/pencil.png";
import useToast from "~/hooks/useToast";
import AppRouter from "~/routes/Router";

import "~/styles/fonts.css";
import useCollections, {
  CollectionsProvider,
} from "./hooks/Collections/useCollections";
import { NewCollectionProvider } from "./hooks/Collections/useNewCollectionOrRecurrence";
import useContacts, { ContactsProvider } from "./hooks/Contacts/useContacts";
import { NewContactProvider } from "./hooks/Contacts/useNewContact";
import { MyKeysProvider } from "./hooks/PIX/useMyKeys";
import { PayCopyAndPasteProvider } from "./hooks/PIX/usePayCopyAndPaste";
import usePix, { PixProvider } from "./hooks/PIX/usePix";
import { TransferWithPixProvider } from "./hooks/PIX/useTransferWithPix";
import { SignupCompanyProvider } from "./hooks/Signup/useSignupCompany";
import { SignupPersonalProvider } from "./hooks/Signup/useSignupPersonal";
import useSignupUser, {
  SignupUserProvider,
} from "./hooks/Signup/useSignupUser";
import { NewTransferProvider } from "./hooks/Transfers/useNewTransfer";
import useTransfers, {
  TransfersProvider,
} from "./hooks/Transfers/useTransfers";
import { AccountProvider } from "./hooks/useAccount";
import useAddBalance, { AddBalanceProvider } from "./hooks/useAddBalance";
import { AnimationProvider } from "./hooks/useAnimation";
import useAuth, { AuthProvider } from "./hooks/useAuth";
import { AuthorityProvider } from "./hooks/useAuthority";
import { CardsProvider } from "./hooks/Cards/useCards";
import { BanksProvider } from "./hooks/useBanks";
import { CEPProvider } from "./hooks/useCEP";
import { NotificationsProvider } from "./hooks/useNotifications";
import usePayments, { PaymentsProvider } from "./hooks/usePayments";
import usePermission, { PermissionProvider } from "./hooks/usePermission";
import { SchedulePaymentsProvider } from "./hooks/useSchedulePayments";
import useStatements, { StatementsProvider } from "./hooks/useStatements";
import { ToastProvider } from "./hooks/useToast";
import { TokenProvider } from "./hooks/useToken";
import activedTheme from "./labels/activedTheme";
import HelperModal from "./pages/App/AppHeader/HelperModal";
import { api } from "./services/api";
import AppStorage from "./services/storage";
import theme from "./themes/theme";
import pipe from "./utils/pipe";
import withProvider from "./utils/withProvider";

import { clarity } from "react-microsoft-clarity";
import { createGlobalStyle } from "styled-components";
import Modal from "./components/Modal";
import ToastsContainer from "./components/Toasts";
import { BackofficeProvider } from "./hooks/useBackoffice";
import { ModalProvider } from "./hooks/useModal";
import useSplit, { SplitProvider } from "./hooks/useSplit";
import useTariffs, { TariffsProvider } from "./hooks/useTariffs";
import { TaxProvider } from "./hooks/useTax";
import label from "./labels";
import { MySpaceProvider } from "./hooks/useMySpace";
import AsyncTokenModal from "./components/AsyncTokenModal";

const GlobalStyle = createGlobalStyle`
    .flexible-modal {
        position: absolute;
        z-index: 1;
        border: 1px solid #ccc;
        background: white;

        .flexible-modal-header {
            position: relative;
            height: 50px;

            z-index: 3;
            padding: 8px;

            color: ${theme.colors.secondary.main};
        }
    }
    .flexible-modal-mask {
        position: fixed;
        height: 100%;
        background: transparent;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .flexible-modal-resizer {
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: se-resize;
        margin: 5px;
        border-bottom: solid 2px #333;
        border-right: solid 2px #333;
    }
    .flexible-modal-drag-area {
        background: ${theme.colors.primary.main} !important;
        height: 50px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: move;
    }


    .wrap-text {
        white-space: pre-wrap;

        * {
            white-space: pre-wrap;
        }
    }
`;

const App = () => {
  const { openAddBalanceModal } = useAddBalance();
  const {
    openStatementsDetailsModal,
    openExportStatmentsModal,
    openModal,
    openFilterModal,
  } = useStatements();

  const {
    openNewTransferModal,
    openDetailModal,
    openFilterModal: openTransferFilterModal,
    openNewTransferModalInDash,
    openTransfersDetailsModal,
  } = useTransfers();

  const {
    openDetailModal: openCollectionDetailModal,
    openFilterModal: openCollectionFilterModal,
    openNewCnabModal,
    openNewCollectionOrRecurrenceModal,
    openNewCollectionOrRecurrenceModalInDash,
    openConfirmModal,
  } = useCollections();

  const {
    openGenerateQRCodeModal,
    openManageLimitsModal,
    openMyKeysModal,
    openMyLimitsModal,
    openPayCopyAndPasteModal,
    openPixLotModal,
    openTransferWithPixModal,
  } = usePix();

  const { openAddClient } = useTariffs();

  const { openNewPaymentModal, openNewPaymentModalInDash } = usePayments();

  const { openAddBankAccountModal, openAddPixKeyModal } = useContacts();

  const { openNewConcessionModal, openNewPermissionModal } = usePermission();

  const { openEditReceiverModal, openNewSplitModal } = useSplit();

  const [loadingTheme, setLoadingTheme] = useState(true);

  const [debugForm, setDebugForm] = useState(false);

  const { setToken, setUser, logout } = useAuth();
  const { showToast } = useToast();

  const runLogout = () => {
    showToast(
      "Sessão expirada",
      "Você será redirecionado para a página de login",
      "warning"
    );
    logout();

    setTimeout(() => {
      location.href = "/";
    }, 5000);
  };

  const updateExpires = () => {
    const date = new Date();
    date.setMinutes(date.getMinutes() + 15);
    const expires = date.toISOString();
    AppStorage.setSession(expires);
  };

  const onIdle = () => {
    runLogout();
  };

  const onActive = () => {};

  const onAction = () => {
    updateExpires();
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    onAction,

    timeout: 1000 * 60 * 15,
    debounce: 300,
    startOnMount: true,
    crossTab: true,
  });

  const verifyIsExpired = () => {
    const expires = AppStorage.getSession();
    if (!expires) return true;
    const date = new Date(expires);
    const now = new Date();
    const isExpired = now > date;

    if (isExpired) {
      runLogout();
    }

    idleTimer.start();
  };

  const addInterceptors = () => {
    const blackList = ["EXC0027"]; //adicionar um execption pra quando for procurar chave pix na trnasferencia pix

    api.interceptors.response.use(
      (r) => r,
      (error) => {
        console.warn("Error interceptor", error);

        if (error.response && error.response.data) {
          const {
            response: { data },
          } = error;

          if (data.message) {
            if (!blackList.some((text) => data.message.includes(text))) {
              showToast("Erro ao executar operação", data.message, "error");
            }

            //adicionar um execption pra quando for procurar chave pix na trnasferencia pix
          }
        }

        return Promise.reject(error);
      }
    );
  };

  const verifyIsDebug = () => {
    if (window.location.href.includes("localhost")) {
      setDebugForm(true);
    }
  };

  // const isChrome = () => {
  //   var userAgent = navigator.userAgent;

  //   console.log(navigator);
  //   const isChrome =
  //     userAgent.indexOf("Chrome") > -1 &&
  //     userAgent.indexOf("Edg") === -1 &&
  //     userAgent.indexOf("OPR") === -1 &&
  //     userAgent.indexOf("Firefox") === -1 &&
  //     userAgent.indexOf("Safari") === -1;

  //   return isChrome;
  // };

  const setTheme = () => {
    const theme = AppStorage.getTheme();

    if (!theme) {
      AppStorage.setTheme(activedTheme);
      window.open("/", "_self");
      window.location.reload();
    } else {
      if (theme != activedTheme) {
        AppStorage.setTheme(activedTheme);
        window.open("/", "_self");
        window.location.reload();
      }
    }

    /* Set title */
    document.title = `${label.name} - ${label.description}`;

   

    setLoadingTheme(false);
  };

  useEffect(() => {
    setTheme();

    addInterceptors();

    verifyIsDebug();

    verifyIsExpired();

    // Set Token
    const token = AppStorage.getToken();
    const user = AppStorage.getUser();

    if (token) setToken(token);
    if (user) setUser(user);
  }, []);

  const modalsStatus = [
    openAddBalanceModal,
    openStatementsDetailsModal,
    openExportStatmentsModal,
    openModal,
    openFilterModal,
    openNewTransferModal,
    openDetailModal,
    openTransferFilterModal,
    openNewTransferModalInDash,
    openTransfersDetailsModal,
    openCollectionDetailModal,
    openCollectionFilterModal,
    openNewCnabModal,
    openNewCollectionOrRecurrenceModal,
    openNewCollectionOrRecurrenceModalInDash,
    openGenerateQRCodeModal,
    openManageLimitsModal,
    openMyKeysModal,
    openMyLimitsModal,
    openPayCopyAndPasteModal,
    openPixLotModal,
    openTransferWithPixModal,
    openNewPaymentModal,
    openNewPaymentModalInDash,
    openAddBankAccountModal,
    openAddPixKeyModal,
    openNewCnabModal,
    openNewConcessionModal,
    openNewPermissionModal,
    openEditReceiverModal,
    openNewSplitModal,
    openAddClient,
    openConfirmModal,
  ];

  useEffect(() => {
    const temTrue = modalsStatus.some((modalOpened) => modalOpened === true);
    if (temTrue) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [modalsStatus]);

  const fillForm = () => {
    const event = new CustomEvent("fillForm", {});

    window.dispatchEvent(event);
  };

  clarity.init(label.clarity);

  return (
    <div style={{ minWidth: 1200 }}>
      <GlobalStyle />
      {loadingTheme ? (
        <div className="d-flex flex-column justify-content-center align-items-center vh-100">
          <div
            className="spinner-border mb-2"
            role="status"
            style={{ color: theme.colors.primary.main }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="ms-2">Carregando aplicação</div>
        </div>
      ) : (
        <Fragment>
          {debugForm && (
            <Button
              style={{
                position: "fixed",
                top: 5,
                left: 5,
                borderRadius: "100%",
                width: 40,
                height: 30,
                padding: 0,
                zIndex: 9999,
              }}
              mode="main"
              className="button-fill"
              onClick={fillForm}
              icon={IconPencil}
            />
          )}

          <ToastsContainer />

          <Modal />
          <RouterProvider router={AppRouter} />
          <HelperModal />

          <AsyncTokenModal />
        </Fragment>
      )}
    </div>
  );
};

const enhance = pipe(
  withProvider(NewContactProvider),
  withProvider(ContactsProvider),
  withProvider(SchedulePaymentsProvider),
  withProvider(PaymentsProvider),
  withProvider(AddBalanceProvider),
  withProvider(AuthorityProvider),
  withProvider(PermissionProvider),
  withProvider(NewCollectionProvider),
  withProvider(CollectionsProvider),
  withProvider(MyKeysProvider),
  withProvider(TransferWithPixProvider),
  withProvider(PayCopyAndPasteProvider),
  withProvider(PixProvider),
  withProvider(NewTransferProvider),
  withProvider(TransfersProvider),
  withProvider(StatementsProvider),
  withProvider(SignupCompanyProvider),
  withProvider(SignupPersonalProvider),
  withProvider(SignupUserProvider),
  withProvider(CEPProvider),
  withProvider(BanksProvider),
  withProvider(NotificationsProvider),
  withProvider(AnimationProvider),
  withProvider(ToastProvider),
  withProvider(TokenProvider),
  withProvider(AuthProvider),
  withProvider(AccountProvider),
  withProvider(CardsProvider),
  withProvider(ModalProvider),
  withProvider(BackofficeProvider),
  withProvider(TariffsProvider),
  withProvider(TaxProvider),
  withProvider(SplitProvider),
  withProvider(MySpaceProvider)
);

export default enhance(App);
