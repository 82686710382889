import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  ButtonMoreDetails,
  Checkbox,
  NotControlledToolTip,
  Spacer,
  Table,
  Tooltip,
} from "@zilliondigital/mirage-ui";
import SuccessIcon from "~/assets/icons/status/done_black_24dp (1).svg";
import PIXIcon from "~/assets/icons/Type/ic_context_payment_pix.png";
import PaidIcon from "~/assets/icons/status/ic_functional_status_success.png";
import BilletIcon from "~/assets/icons/Type/billet.png";
import ScheduleIcon from "~/assets/icons/status/ic_functional_status_attention.png";

import useStatements from "~/hooks/useStatements";
import { Fragment, useState } from "react";
import EyeIcon from "~/assets/icons/round_visibility_black_24dp.png";
import WhatsIcon from "~/assets/icons/whats.png";
import usePayments from "~/hooks/usePayments";
import styled, { keyframes } from "styled-components";
import { operationStatus } from "~/data/options";

import PendingIcon from "~/assets/icons/status/Elipse 54.png";
import ErrorIcon from "~/assets/icons/status/error.png";
import {
  formatDate,
  formatDateTime,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import { applyPagination } from "~/components/Paginator";

import PaymentsEmpty from "./PaymentsEmpty";
import TableLoader from "~/components/SkeletonTables";
import Badge from "~/components/Badge";
import theme from "~/themes/theme";

type GetStatusType = {
  [key: string]: JSX.Element;
};

const getStatus: GetStatusType = {
  processing: (
    <Badge label="Processando" color={"#FEE7CC"} textColor={"#FC8802"} />
  ),

  waiting_approval: (
    <Badge
      label="Em aprovação"
      color={theme.colors.base.light200}
      textColor={theme.colors.base.main}
    />
  ),

  overdue: (
    <Badge
      label="Vencido"
      color={theme.colors.error.light200}
      textColor={theme.colors.error.main}
    />
  ),

  schedule: (
    <Badge label="Aguardando" color={"#FEE7CC"} textColor={"#FC8802"} />
  ),
  done: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  paid: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  canceled: (
    <Badge
      label="Cancelado"
      color={theme.colors.error.light200}
      textColor={theme.colors.error.main}
    />
  ),
  scheduled: (
    <Badge label="Aguardando" color={"#FEE7CC"} textColor={"#FC8802"} />
  ),
  refused: (
    <Badge
      label="Cancelado"
      color={theme.colors.error.light200}
      textColor={theme.colors.error.main}
    />
  ),
  error: (
    <div className="d-flex justify-content-center">
      <img src={ErrorIcon} />
    </div>
  ),
  approved: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
};

const typeImgRender: any = {
  pix: <img style={{ height: "24px" }} src={PIXIcon} />,
  billet: <img style={{ height: "18px" }} src={BilletIcon} />,
};

const typeLabelRender: any = {
  pix: "PIX",
  billet: "Boleto",
};

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
    /* Cor que deseja quando a imagem estiver completamente expandida */
  }
`;

const ImgEye = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

const WithPayments = () => {
  const { paymentsData, rowsPerPage, page, isLoadingList } = usePayments();

  const [voucherData, setVoucherData] = useState<string>("");

  const [buttonMoreOptions, setAButtonMoreOptions] = useState<any>([
    {
      label: "Detalhes",
      onClick: () => {
        window.open(voucherData);
      },
      icon: EyeIcon,
    },

    // {
    //   label: "Compartilhar no whatsApp",
    //   onClick: () => {
    //     const message = "Olá! Estou compartilhando essa mensagem contigo.";
    //     const shareUrl = `https://wa.me?text=${encodeURIComponent(message)}`;

    //     window.open(shareUrl);
    //   },
    //   icon: WhatsIcon,
    // },
  ]);

  const columnsStatements: any = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => (
        <div className="d-flex  justify-content-start align-items-center">
          {/* <Checkbox className="ps-1" /> */}
          {/*  <Spacer left={70} /> */}
          {data.label}
        </div>
      ),

      renderData: (data: any) => (
        <div
          className="d-flex justify-content-start align-items-center"
          id={`status-${data.id}`}
        >
          {/* <Spacer left={10} /> */}
          {/* <Checkbox /> */}
          {/* <Spacer left={60} /> */}
          {/* <div className="d-flex flex-column">*/}
          {getStatus[data.status]}
          <NotControlledToolTip placement="top" target={`status-${data.id}`}>
            {operationStatus[data.status]}
          </NotControlledToolTip>
          {/*    </div> */}
        </div>
      ),
    },

    {
      label: "Forma de pagamento",
      key: "type",
      renderData: (data: any) => (
        <div className="d-flex  ">
          {typeImgRender["billet"]}
          <Spacer left={10} />
          <p>{typeLabelRender["billet"]}</p>
        </div>
      ),
    },
    {
      label: "Valor",
      key: "amount",

      renderData: (data: any) => (
        <div>{`R$ ${formatNumberToCurrencyWithoutSimbol(data.amount)}`}</div>
      ),
    },
    {
      label: "Identificação",
      key: "identifier",

      renderData: (data: any) => (
        <div className="d-flex justify-content-center">
          {data.identifier ?? "-"}
        </div>
      ),
    },

    {
      label: "Descrição",
      key: "description",

      renderData: (data: any) => (
        <div>
          {data.description === "" || data.description === null
            ? "Sem descrição"
            : data.description}
        </div>
      ),
    },

    {
      label: "Vencimento",
      key: "due_date",

      renderData: (data: any) => <div>{formatDate(data.due_date)}</div>,
    },
    {
      label: "Pagamento",
      key: "payment_date",
      renderData: (data: any) => {
        return (
          <>
            {" "}
            <div
              onClick={() => setVoucherData(data.voucher_url)}
              className="d-flex justify-content-between pe-5 "
              // onClick={() => setIndexStatementsDetails(data.index)}
            >
              {formatDate(data.payment_date)}

              {/* <ButtonMoreDetails options={buttonMoreOptions} /> */}

              <div
                className="d-flex justify-content-center align-items-center"
                id={`details-${data.id}`}
              >
                {/* <Checkbox /> */}

                <ImgEye
                  src={EyeIcon}
                  onClick={() => {
                    data?.voucher_url &&
                      window.open(data?.voucher_url, "_blank");
                  }}
                />
                <NotControlledToolTip
                  placement="top"
                  target={`details-${data.id}`}
                >
                  {data?.voucher_url
                    ? "Visualizar comprovante"
                    : "Boleto de recarga. Não é possível visualizar o comprovante"}
                </NotControlledToolTip>
              </div>

              {/* <Container className="me-5 hover-list-button">
                <Button
                  title="Detalhes"
                  // onClick={() => openDetail(data)}
                  mode="phantom"
                  icon={EyeIcon}
                /> */}

              {/* <ButtonMoreDetails options={buttonMoreOptions} /> */}
              {/* </Container> */}
            </div>
          </>
        );
      },
    },
  ];

  const dataPayments = paymentsData.map((payment: any, index: number) => ({
    status: payment.status,
    issuer: payment.category,
    type: payment.category,
    due_date: payment.due_date,
    payment_date: payment.created_date,
    description: payment.description,
    cost_center: payment.cost_center,
    identifier: payment.external_id,
    amount: payment.payment_amount,
    index: index,
    voucher_url: payment.voucher_url,
    id: payment.id,
  }));

  return (
    <Fragment>
      <Spacer top={20} />
      {isLoadingList ? (
        <TableLoader />
      ) : (
        paymentsData.length === 0 && <PaymentsEmpty />
      )}
      {paymentsData.length > 0 && (
        <Table
          data={applyPagination(dataPayments, { page, rowsPerPage })}
          columns={columnsStatements}
          dataSpacer={8}
        />
      )}
    </Fragment>
  );
};

export default WithPayments;
