import constate from "constate";
import { ReactElement, useState } from "react";
import dataStatements from "~/data/extrato";
import ApiService from "~/services/api";
import { subDays } from "date-fns";
import { payloadDatesToParam } from "~/utils";
import { statementTaxes } from "~/data/options";

const [TaxProvider, useTax] = constate(() => {
  const [tax, setTax] = useState<any>(null);

  return {
    tax,
    setTax,
  };
});

export { TaxProvider };

export default useTax;
