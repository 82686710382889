import constate from "constate";
import { useState } from "react";
import { boolean } from "yup";

const [NewContactProvider, useNewContact] = constate(() => {
  const [newContactPayload, setNewContactPayload] = useState({});
  const [newContactData, setNewContactData] = useState<any>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [step, setStep] = useState("basic_data");

  return {
    newContactPayload,
    setNewContactPayload,
    step,
    setStep,
    setNewContactData,
    newContactData,
    setIsEdit,
    isEdit,
  };
});

export { NewContactProvider };

export default useNewContact;
