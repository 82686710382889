import { Transaction } from "~/hooks/Cards/interfaces"
import { CardsRecentTransactionsTable } from "../tables/CardsRecentTransactionsTable";

interface ICardsRecentsTransactions {
  recentTransactions: Transaction[];
  isLoadingRecentTransactions: boolean;
  recentTransactionsError: string | undefined
}
export const CardsRecentsTransactionsSection = ({
  recentTransactions,
  recentTransactionsError,
  isLoadingRecentTransactions
}: ICardsRecentsTransactions) => {

  return(
    <>
      <CardsRecentTransactionsTable 
        data={recentTransactions}
      />
    </>
  )
}