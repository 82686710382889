import React from "react";
import styled from "styled-components";

const PaddingContainer = styled.div<SpacerProps>`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: ${(props) => props.top ?? 0}px;
  padding-bottom: ${(props) => props.bottom ?? 0}px;
  padding-left: ${(props) => props.left ?? 0}px;
  padding-right: ${(props) => props.right ?? 0}px;
`;

type SpacerProps = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

type PaddingProps = SpacerProps & {
  children: React.ReactNode;
};

export const Padding = (props: PaddingProps) => {
  return <PaddingContainer {...props} />;
};

export default Padding;
