import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Radio,
  Input,
  Spacer,
  InteractiveModal,
  Button,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  hasLength,
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCase,
  onlyNumbers,
} from "~/services/utils";
import useSignupUser from "~/hooks/Signup/useSignupUser";
import ApiService from "~/services/api";
import { INVALID_FORMAT, REQUIRED_LABEL } from "~/services/validators";
import useToast from "~/hooks/useToast";
import { LoginResponse, UserRegisterResponse } from "~/types";
import AppStorage from "~/services/storage";
import { useNavigate } from "react-router-dom";
import { TitleModal } from "~/pages/Authentication/Auth.styles";

type Values = {
  password: string;
  password_confirm: string;
};

const ContainerRadio = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-bottom: 16px;
`;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Senha é obrigatória")
    .test("password-strength", INVALID_FORMAT, (value) => {
      if (!value) return false;
      if (value.length < 8) return false;
      if (!hasNumber(value)) return false;
      if (!hasLowerCase(value) || !hasUpperCase(value)) return false;
      if (!hasSpecialCharacter(value)) return false;

      return true;
    }),
  password_confirm: Yup.string()
    .required(REQUIRED_LABEL)
    .oneOf([Yup.ref("password"), null], "Senhas diferem"),
});

const SignupBasicPassword = () => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const {
    signUpUserPayload,
    setSignUpUserPayload,
    setStep,
    setOpenModal,
    openModal,
  } = useSignupUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues: Values = {
    password: signUpUserPayload.password ?? "",
    password_confirm: "",
  };

  const handleFormSubmit = async (values: Values) => {
    setSignUpUserPayload((prevData: any) => ({
      ...prevData,
      ...values,
    }));

    setIsLoading(true);

    try {
      const { full_name, username, email, phone, terms, token } =
        signUpUserPayload;

      const { password, password_confirm } = values;

      const [firstName, ...lastName] = full_name.split(" ");

      const lastNameString = lastName.join(" ");

      const payload = {
        first_name: firstName,
        last_name: lastNameString,
        username: onlyNumbers(username),
        email: email,
        password: password,
        phone_number: onlyNumbers(phone),
        terms: terms,
        confirm_password: password_confirm,
        code: token,
      };

      const registerData = await ApiService.HttpPost<UserRegisterResponse>({
        route: "user/",
        body: payload,
      });

      AppStorage.setRegisteredUser(registerData.data);

      const authPayload = {
        username: payload.username,
        password: payload.password,
      };

      const loginResponse = await ApiService.HttpPost<LoginResponse>({
        route: "api-token-auth/",
        body: authPayload,
      });

      if (loginResponse.data.token) {
        AppStorage.setRegisteredToken(loginResponse.data.token);

        setOpenModal(false);
        resetForm();

        setStep("profile");
        navigate("/signup/user/profile");
        showToast("Senha criada", "Sua senha de acesso foi criada", "success");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleParentSubmit = () => {
    submitForm();
  };

  const {
    submitForm,
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  const passwordStrength = useCallback(
    (password: string) => {
      const strengthSteps = [];

      if (password !== "") strengthSteps.push(0);
      if (password.length >= 8) strengthSteps.push(1);
      if (hasNumber(password)) strengthSteps.push(2);
      if (hasLowerCase(password) && hasUpperCase(password))
        strengthSteps.push(3);
      if (hasSpecialCharacter(password)) strengthSteps.push(4);

      return strengthSteps;
    },
    [hasNumber, hasLength, hasLowerCase, hasSpecialCharacter, hasUpperCase]
  );

  const passwordStrengthPercentage = useCallback(
    (password: string) => {
      return (100 * passwordStrength(password).length) / 5;
    },
    [values.password]
  );

  const passwordLevel = useCallback(
    (password: string) => {
      const percentage = passwordStrengthPercentage(password);

      if (percentage < 40) {
        return "easy";
      } else if (percentage >= 40 && percentage < 70) {
        return "medium";
      } else if (percentage >= 70) {
        return "hard";
      }
    },
    [values.password]
  );

  const passwordStrengthText = useCallback(
    (password: string) => {
      const percentage = passwordStrengthPercentage(password);

      if (percentage > 60 && percentage < 90) {
        return "Falta pouco";
      } else if (percentage >= 90) {
        return "Sua senha parece bem segura";
      }

      return percentage > 0 ? "Sua senha está fraca" : "";
    },
    [values.password]
  );

  useEffect(() => {
    window.addEventListener("signupUserSubmit", handleParentSubmit);

    return () => {
      window.removeEventListener("signupUserSubmit", handleParentSubmit);
      setStep("contact");
    };
  }, []);

  useEffect(() => {
    if (openModal) {
      setStep("password");
    } else {
      setStep("contact");
      resetForm();
    }
  }, [openModal]);

  return (
    <InteractiveModal
      title="Crie sua senha de acesso"
      size="lg"
      toggleModal={() => null}
      open={openModal}
    >
      <div className="p-4">
        <Spacer bottom={10} />
        <TitleModal>Crie sua senha de acesso</TitleModal>
        <Spacer bottom={20} />
        <div className="d-flex flex-column justify-content-start align-items-start w-100">
          <ContainerRadio>
            <Radio
              checked={hasLength(values.password, 8)}
              position="right"
              label="Mínimo de 8 caracteres"
            />
          </ContainerRadio>
          <ContainerRadio>
            <Radio
              checked={hasNumber(values.password)}
              position="right"
              label="Mínimo de 1 número"
            />
          </ContainerRadio>
          <ContainerRadio>
            <Radio
              checked={
                hasUpperCase(values.password) && hasLowerCase(values.password)
              }
              position="right"
              label="Letras maiúsculas e Minúsculas"
            />
          </ContainerRadio>
          <ContainerRadio>
            <Radio
              checked={hasSpecialCharacter(values.password)}
              position="right"
              label="Caracteres especiais"
            />
          </ContainerRadio>
        </div>

        <span>
          Para reforçar a segurança da sua senha, não utilize informações
          pessoais como datas de nascimentos ou números de telefones e nem
          números sequenciais.
        </span>
        <Spacer bottom={10} />
        <form onSubmit={handleSubmit}>
          <Input
            label="Digite sua senha"
            required={
              !hasLength(values.password, 8) ||
              !hasNumber(values.password) ||
              !hasUpperCase(values.password) ||
              !hasLowerCase(values.password) ||
              !hasSpecialCharacter(values.password)
            }
            type="password"
            name="password"
            value={values.password}
            newPassword={values.password.length > 0 && true}
            helper={passwordStrengthText(values.password)}
            newPasswordLevel={passwordLevel(values.password)}
            onChange={handleChange}
            autoCapitalize="none"
            autoComplete="none"
          />
          <Input
            type="password"
            required={
              values.password_confirm === "" ||
              values.password_confirm !== values.password
            }
            label="Confirme sua senha"
            name="password_confirm"
            value={values.password_confirm}
            onChange={handleChange}
            error={
              values.password_confirm.length > 0 &&
              values.password_confirm !== values.password &&
              "Suas senhas são diferentes"
            }
            autoCapitalize="none"
            autoComplete="none"
          />
        </form>
        <div className="d-flex justify-content-between">
          {" "}
          <Button
            mode="phantom"
            label="Cancelar"
            onClick={() => {
              setOpenModal(false);
              resetForm();
              setFieldValue("password", "");
            }}
          />{" "}
          <Button
            mode="main"
            arrow="right"
            label="Concluir"
            loading={isLoading}
            disabled={
              values.password_confirm === "" ||
              !hasLength(values.password, 8) ||
              !hasNumber(values.password) ||
              !hasUpperCase(values.password) ||
              !hasLowerCase(values.password) ||
              !hasSpecialCharacter(values.password) ||
              values.password_confirm !== values.password
            }
            onClick={() => handleFormSubmit(values)}
          />{" "}
        </div>
      </div>
    </InteractiveModal>
  );
};

export default SignupBasicPassword;
