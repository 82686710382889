import "bootstrap/dist/css/bootstrap.min.css";
import {
  LateralModal,
  Button,
  Spacer,
  Margin,
} from "@zilliondigital/mirage-ui";

import { format, parseISO } from "date-fns";
import { ContainerDetails } from "~/pages/App/app-content.styles";
import Title from "~/components/Texts/Title";
import Description from "~/components/Texts/Description";
import DownloadIcon from "~/assets/icons/buttons/download.png";
import styled from "styled-components";
import useTransfers from "~/hooks/Transfers/useTransfers";
const ContainerBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  margin-bottom: 15px;
  padding: 40px 25px;
`;

const ShowOperationModal = () => {
  const { openDetailModal, setOpenDetailModal, currentModalData } =
    useTransfers();

  return (
    <LateralModal
      open={openDetailModal}
      header
      title={`${
        currentModalData?.category_display === "Tax"
          ? "Tarifa"
          : currentModalData?.category_display
      } #${currentModalData?.id}`}
      toggleModal={() => {
        setOpenDetailModal(!openDetailModal);
      }}
    >
      {currentModalData ? (
        <>
          <Spacer bottom={10} />

          <ContainerDetails>
            <table className=" w-100">
              <tbody>
                <tr>
                  <td>
                    <Description color="#666666">Valor</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      R$ {currentModalData?.amount || "-"}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">
                      Tipo de transferência
                    </Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.category_display === "Tax"
                        ? "Tarifa"
                        : currentModalData?.category_display}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Data do pagamento</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData.created_date
                        ? format(
                            parseISO(currentModalData?.created_date),
                            "dd/MM/yyyy HH:mm:ss"
                          )
                        : "--"}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">ID da transação</Description>
                  </td>

                  <td className="second-column">
                    <Description>{currentModalData?.id ?? "--"}</Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Descrição</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.description
                        ? currentModalData?.description
                        : "-"}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">
                      Mensagem de retorno
                    </Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.return_error_message
                        ? currentModalData?.return_error_message
                        : "-"}
                    </Description>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Description color="#666666">Pagador</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.full_bank_account.owner.name} <br />
                    </Description>
                  </td>
                </tr>
              </tbody>
            </table>

            <Spacer bottom={40} />
            <Title>Origem</Title>
            <table className=" w-100">
              <tbody>
                <tr>
                  <td>
                    <Description color="#666666">Nome</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.full_bank_account.owner.name}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Instituição</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.full_bank_account.bank_data.name}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Agência</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.full_bank_account.agency}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Conta</Description>
                  </td>

                  <td className="second-column">
                    <Description>{`${currentModalData?.full_bank_account.account_number}-${currentModalData?.full_bank_account.account_number_digit}`}</Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Tipo de conta</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.full_bank_account.type}
                    </Description>
                  </td>
                </tr>
              </tbody>
            </table>
            <Spacer bottom={40} />

            <Title>Destino</Title>
            <table className=" w-100">
              <tbody>
                <tr>
                  <td>
                    <Description color="#666666">Nome</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.full_recipient.name}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Instituição</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.recipient_bank_account.bank_data.name}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Agência</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.recipient_bank_account.agency}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Conta</Description>
                  </td>

                  <td className="second-column">
                    <Description>{`${currentModalData?.recipient_bank_account.account_number}-${currentModalData?.recipient_bank_account.account_number_digit}`}</Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Tipo de conta</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData?.recipient_bank_account.type}
                    </Description>
                  </td>
                </tr>
              </tbody>
            </table>
            <Margin bottom={100} />
          </ContainerDetails>

          <Spacer top={25} />

          <ContainerBottom className="d-flex justify-content-between p-4 ">
            <Button
              mode="phantom"
              label="Fechar"
              onClick={() => {
                setOpenDetailModal(false);
              }}
            />
            <Button
              mode="border"
              label="Fazer download"
              disabled={!currentModalData.voucher_url}
              icon={DownloadIcon}
              onClick={() => {
                window.open(currentModalData.voucher_url, "_blank");
              }}
            />
          </ContainerBottom>
        </>
      ) : (
        <></>
      )}
    </LateralModal>
  );
};

export default ShowOperationModal;
