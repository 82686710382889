import { Navigate } from "react-router-dom";
import AppStorage from "~/services/storage";

type PrivateRouterProps = {
  children: JSX.Element;
};

const PublicRouter = ({ children }: PrivateRouterProps): JSX.Element => {
  const user = AppStorage.getUser();

  if (!user) {
    return children;
  } else {
    return <Navigate to="/app" />;
  }
};

export default PublicRouter;
