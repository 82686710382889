import {
  BalanceAlert,
  Button,
  InputCurrency,
  Spacer,
  Toggle,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";
import EyeIcon from "~/assets/icons/round_visibility_black_24dp.png";
import EyeOffIcon from "~/assets/icons/round_visibility_off_black_36dp.png";
import styled from "styled-components";
import usePayCopyAndPaste from "~/hooks/PIX/usePayCopyAndPaste";
import { Fragment, useEffect, useMemo } from "react";
import hideFormatDocumentNumber from "~/utils/hideDocumentNumber";
import useToken from "~/hooks/useToken";
import {
  convertStringToNumber,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import CustomerTax from "~/components/CustomerTax";
import { useFormik } from "formik";

const Container = styled.div`
  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;
  }
  padding: 20px;

  .image-eye {
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  .container-balance {
    position: relative;
    top: -20px;
  }
`;

type Values = {
  amount: string;
};

const SendPix = () => {
  const {
    balanceFormatted,
    hideAvailableBalance,
    setHideAvailableBalance,
    balance,
    getBalance,
  } = useAccount();

  const { sendPartnerToken } = useToken();

  const {
    prevStep,
    setPixCopyAndPasteData,
    pixCopyAndPasteData,
    setOpenValidatorModalPixCopyAndPaste,
    dataTransferPixReceipt,
  } = usePayCopyAndPaste();

  const onSubmit = (values: Values) => {
    console.log("values", values);

    setPixCopyAndPasteData((prev: any) => ({
      ...prev,
      amount: convertStringToNumber(values.amount),
    }));

    sendPartnerToken();
    setOpenValidatorModalPixCopyAndPaste(true);
  };

  const initialValues: Values = {
    amount: pixCopyAndPasteData?.amount,
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  const insuficienteBalance = useMemo(() => {
    return balance < convertStringToNumber(values.amount);
  }, [balance, values.amount]);

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <Fragment>
      <Container className="p-4   ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-100">
            <InputCurrency
              label={"Você está transferindo para " + pixCopyAndPasteData?.name}
              name="amount"
              fontSize="large"
              inputSize="large"
              value={formatNumberToCurrencyWithoutSimbol(Number(values.amount))}
              onChange={(e) => {
                handleChange(e);
              }}
              disabled={pixCopyAndPasteData?.amount > 0}
            />
          </div>

          {/* <div className="ms-2 pb-3">
            <Description color="#666666">Agendar</Description>
            <Toggle mode="large" />
          </div> */}
        </div>
        <div className="container-balance">
          <div className="d-flex flex-wrap justify-content-start align-items-center">
            <Description color="#666666">
              {`Saldo disponível atual é de
    `}
            </Description>
            <Spacer left={3} />
            <Description className="font-weight-bolder">
              {!hideAvailableBalance ? balanceFormatted : "R$ ******"}
            </Description>
            <Spacer left={5} />
            <img
              className="image-eye"
              src={!hideAvailableBalance ? EyeIcon : EyeOffIcon}
              onClick={() => setHideAvailableBalance(!hideAvailableBalance)}
            />
          </div>
          <Spacer top={20} />
          {insuficienteBalance && (
            <BalanceAlert
              title="Saldo insuficiente"
              message={
                "Você não tem saldo suficiente pra efetuar esse pagamento"
              }
            />
          )}
          <div className="w-100">
            <CustomerTax slug="pix_transfer" />
          </div>
        </div>
        {/* <BillingNotice message={pixCopyAndPasteData?.message} /> */}
        <Spacer top={20} />

        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Quando</Description>
              </td>

              <td className="second-column">
                <Description>Agora</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Tipo de transferência</Description>
              </td>

              <td className="second-column">
                <Description>PIX</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Instituição</Description>
              </td>

              <td className="second-column">
                <Description>
                  {pixCopyAndPasteData?.raw?.ReceiverBankName}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">CPF/CNPJ</Description>
              </td>

              <td className="second-column">
                <Description>
                  {hideFormatDocumentNumber(
                    pixCopyAndPasteData?.document_number
                  )}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Identificador</Description>
              </td>

              <td className="second-column">
                <Description>{pixCopyAndPasteData?.identifier}</Description>
              </td>
            </tr>
          </tbody>
        </table>

        <Spacer top={40} />
        <div className="d-flex justify-content-between">
          <Button mode="phantom" label="Cancelar" onClick={() => prevStep()} />
          <Button
            mode="main"
            label="Transferir agora"
            onClick={() => handleSubmit()}
            disabled={insuficienteBalance || !values.amount}
          />
        </div>
      </Container>
    </Fragment>
  );
};

export default SendPix;
