import usePayCopyAndPaste from "~/hooks/PIX/usePayCopyAndPaste";
import ValidatorTokenModal from "./ValidatorTokenModal";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import useAddBalance from "~/hooks/useAddBalance";
import useToken from "~/hooks/useToken";
import ValidatorTokenDeletePixKey from "./ValidatorTokenDeletePixKey";
import useMyKeys from "~/hooks/PIX/useMyKeys";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import useAuth from "~/hooks/useAuth";
import useSignupUser from "~/hooks/Signup/useSignupUser";

const ValidatorsModals = () => {
  const { setToken, token } = useToken();

  const { openValidatorModalSignUpUser, setOpenValidatorModalSignUpUser } =
    useSignupUser();

  const {
    openValidatorModalLogin,
    setOpenValidatorModalLogin,
    userCanDoLogin,
    setValidateToken,
  } = useAuth();

  const {
    openValidatorModalPixCopyAndPaste,
    PayPixCopyandPaste,
    setOpenValidatorModalPixCopyAndPaste,
  } = usePayCopyAndPaste();

  const {
    openValidatorModalTransferWithPix,
    sendPix,
    setOpenValidatorModalTransferWithPix,
  } = useTransferWithPix();

  const {
    openDeleteKeyModal,
    // setOpenDeleteKeyModal,
    setKeyTypeLabel,
    deteleKeyPix,
    typeOfKey,
    openValidatorModalCreateNewKeyPix,
    setOpenValidatorModalCreateNewKeyPix,
  } = useMyKeys();

  const {
    addBalanceBillet,
    openValidatorModalAddBalance,
    isPIX,
    setOpenValidatorModalAddBalance,
    addBalancePix,
  } = useAddBalance();

  const { sendTED, isTEV, sendTEV, setPayloadSendTEV } = useNewTransfer();

  const { openValidatorModalTED, setOpenValidatorModalTED } = useNewTransfer();

  const {
    openValidatorModalNewCollection,
    setOpenValidatorModalNewCollection,
    generateBillet,
  } = useNewCollection();

  const handleToggleOpenValidatorModalSignUpUser = () => {
    setOpenValidatorModalSignUpUser(!openValidatorModalSignUpUser);
  };

  const handleToggleOpenValidatorModalLogin = () => {
    setOpenValidatorModalLogin(!openValidatorModalLogin);
    setToken("");
    setValidateToken("");
  };

  const handleToggleOpenValidatorModalTrasnferWithPix = () => {
    setOpenValidatorModalTransferWithPix(!openValidatorModalTransferWithPix);
    setToken("");
  };

  const handleToggleOpenValidatorModalPayCopyAndPaste = () => {
    setOpenValidatorModalPixCopyAndPaste(!openValidatorModalPixCopyAndPaste);
    setToken("");
  };

  // const handleToggleOpenValidatorModalDeletePixKey = () => {
  //   setOpenDeleteKeyModal(!openDeleteKeyModal);

  //   setToken("");
  //   setKeyTypeLabel("");
  // };

  const handleToggleValidatorModalAddBalance = () => {
    setOpenValidatorModalAddBalance(!openValidatorModalAddBalance);
    setToken("");
  };

  const handleToggleValidatorModalTED = () => {
    setOpenValidatorModalTED(!openValidatorModalTED);
    setToken("");
  };

  const handleToggleValidatorModalNewCollection = () => {
    setOpenValidatorModalNewCollection(!openValidatorModalNewCollection);
    setToken("");
  };

  const handleToggleValidatorModalCreateNewKeyPix = () => {
    setOpenValidatorModalCreateNewKeyPix(!openValidatorModalCreateNewKeyPix);
    setToken("");
  };

  return (
    <>
      <ValidatorTokenModal
        open={openValidatorModalTransferWithPix}
        onTokenConfirm={sendPix}
        setterToggle={handleToggleOpenValidatorModalTrasnferWithPix}
      />
      <ValidatorTokenModal
        open={openValidatorModalPixCopyAndPaste}
        onTokenConfirm={PayPixCopyandPaste}
        setterToggle={handleToggleOpenValidatorModalPayCopyAndPaste}
      />
      <ValidatorTokenModal
        open={openValidatorModalAddBalance}
        onTokenConfirm={isPIX ? addBalancePix : addBalanceBillet}
        setterToggle={handleToggleValidatorModalAddBalance}
      />

      {/* <ValidatorTokenDeletePixKey
        open={openDeleteKeyModal}
        setterToggle={handleToggleOpenValidatorModalDeletePixKey}
        onTokenConfirm={deteleKeyPix}
      /> */}

      <ValidatorTokenModal
        open={openValidatorModalTED}
        onTokenConfirm={isTEV ? sendTEV : sendTED}
        setterToggle={handleToggleValidatorModalTED}
      />

      <ValidatorTokenModal
        open={openValidatorModalNewCollection}
        onTokenConfirm={generateBillet}
        setterToggle={handleToggleValidatorModalNewCollection}
      />
      <ValidatorTokenModal
        open={openValidatorModalLogin}
        onTokenConfirm={userCanDoLogin}
        setterToggle={handleToggleOpenValidatorModalLogin}
      />
      <ValidatorTokenModal
        open={openValidatorModalSignUpUser}
        onTokenConfirm={() => null} //colocar a funcao
        setterToggle={handleToggleOpenValidatorModalSignUpUser}
      />

      <ValidatorTokenModal
        open={openValidatorModalCreateNewKeyPix}
        onTokenConfirm={() => typeOfKey === ""}
        setterToggle={handleToggleValidatorModalCreateNewKeyPix}
      />
    </>
  );
};
export default ValidatorsModals;
