import {
  Button,
  CustomSelect,
  Input,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { accountType } from "~/data/options";
import useContacts from "~/hooks/Contacts/useContacts";
import useNewContact from "~/hooks/Contacts/useNewContact";
import useBanks from "~/hooks/useBanks";
import useToast from "~/hooks/useToast";
import ApiService from "~/services/api";
import { REQUIRED_LABEL } from "~/services/validators";
import { APIResponse, getcontacts, setBankAccount } from "~/types";

type FormValues = {
  bank: any;
  account_type: any;
  agency: string;
  account_number: string;
};
const initialValues: FormValues = {
  bank: null,
  account_type: null,
  agency: "",
  account_number: "",
};

const validationSchema = Yup.object().shape({
  bank: Yup.object().required(REQUIRED_LABEL),
  account_type: Yup.object().required(REQUIRED_LABEL),
  agency: Yup.string().required(REQUIRED_LABEL),
  account_number: Yup.string().required(REQUIRED_LABEL),
});

const ContainerData = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const BankAccount = () => {
  const { newContactData } = useNewContact();
  const { setContactsData, setSection } = useContacts();

  const { showToast } = useToast();
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const bankCode = selectedOption?.target.value;

  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {};

  const { values, handleBlur, handleChange, submitForm, resetForm } = useFormik(
    {
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: handleSubmit,
    }
  );

  const removeLastCharacter = (str: string) => {
    return str.slice(0, -1);
  };

  const getLastCharacter = (str: string) => {
    return str.slice(-1);
  };

  const handleSelectChange = (selectedValue: any) => {
    setSelectedOption(selectedValue);
  };

  const getContacts = async () => {
    const { data } = await ApiService.HttpGet<APIResponse<getcontacts>>({
      route: "partner/",
      token: true,
      params: {
        default: false,
        type: "contact",
      },
    });

    setContactsData(data.results);
  };

  const setBankAccount = async () => {
    setIsLoadingButton(true);

    try {
      const data = await ApiService.HttpPost<setBankAccount>({
        route: "bank-account/",
        body: {
          account_number: removeLastCharacter(values.account_number),
          account_number_digit: getLastCharacter(values.account_number),
          agency: values.agency,
          bank: bankCode,
          partner: newContactData.id,
        },
        token: true,
      });

      setIsLoadingButton(false);
      navigate("/app/contacts/");

      showToast(
        "Conta bancária adicionada",
        "A conta bancária foi adicionada ao contato com sucesso",
        "success"
      );

      resetForm();
      getContacts();
    } catch (e) {
      console.warn(e);
      resetForm();

      showToast(
        "Ops!",
        "Não foi possível cadastrar a conta bancária, tente novamente mais tarde",
        "success"
      );

      setIsLoadingButton(false);
    }
  };
  const { getBanks, bankOptions, banksData } = useBanks();
  useEffect(() => {
    banksData?.length <= 0 && getBanks();
  }, []);

  return (
    <Fragment>
      <ContainerData className="container p-4">
        <form>
          <div className="row ">
            <div className="col-2">
              <CustomSelect
                required
                name="bank"
                label="Intituição"
                placeholder="Selecione"
                options={bankOptions}
                onChange={handleSelectChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-2">
              {/* <CustomSelect
                name="account_type"
                label="Tipo de conta"
                placeholder="Selecione"
                options={accountType}
                onChange={handleChange}
                onBlur={handleBlur}
              /> */}
            </div>

            <div className="col-7"></div>
            <div className="col-2">
              <Input
                required
                name="agency"
                label="Agência"
                placeholder="0001"
                value={values.agency}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-2">
              <Input
                required
                name="account_number"
                label="Conta"
                placeholder="02156884"
                onChange={handleChange}
                onBlur={handleBlur}
                helper="Com digito"
              />
            </div>
          </div>
        </form>
      </ContainerData>
      <div className="w-100 d-flex justify-content-center justify-content-lg-end p-3 align-items-center">
        <Button
          mode="phantom"
          label="Continuar sem conta bancária"
          onClick={() => navigate("/app/contacts/")}
        />
        <Spacer right={20} />
        <Button
          mode="main"
          label={"Adicionar conta bancária"}
          onClick={() => setBankAccount()}
          disabled={!(values.account_number && values.agency)}
        />
      </div>
    </Fragment>
  );
};

export default BankAccount;
