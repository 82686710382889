import {
  Button,
  CustomSelect,
  Input,
  InputCurrency,
  Spacer,
  Toggle,
} from "@zilliondigital/mirage-ui";
import styled from "styled-components";
import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import ParcelSelector from "~/components/ParcelSelector";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import useAccount from "~/hooks/useAccount";
import { onlyNumbers } from "~/services/utils";
import { convertedAmount, formatNumberToCurrencyWithoutSimbol } from "~/utils";
import CustomerTax from "~/components/CustomerTax";
import useTax from "~/hooks/useTax";
import ApiService from "~/services/api";

import { APIResponse, SplitGetType } from "~/types";
import { useNavigate } from "react-router-dom";
import useSplit from "~/hooks/useSplit";
import theme from "~/themes/theme";

type BilletCollectionProps = {
  isInstallmentsOpen?: boolean;
  overduePaymentOpen?: boolean;
  earlyPaymentOpen?: boolean;
  splitOpen?: boolean;
  moreInformationsOpen?: boolean;
};

type billetPayloadType = {
  amount?: number;
  code?: string;
  description?: string;
  instruction?: string;
  custom_parameters?: boolean;
  email_notification?: boolean;
  sms_notification?: boolean;
  periodicity?: null;
  wallet_type?: "external";
  due_date?: string;
  deadline_date?: string;
  generate_qrcode?: boolean;
  installment_number?: null;
  fine_type?: string;
  fine_amount?: string;
  fine_date?: string;
  late_fee_type?: string;
  late_fee_amount?: string;
  late_fee_date?: string;
  discount_type?: string;
  discount_amount?: string;
  discount_date?: string;
  payer?: number;
  receiver?: number;
  bank?: number;
  split?: number | null;
};

type FormValues = {
  amount: string;
  amount_first_parcel: string;
  instruction: string;
  number_of_parcels: number;
  due_date: string;
  deadline_date: string;
  send_for_email: boolean;
  send_for_sms: boolean;
  send_for_whatsapp: boolean;
  late_payment_fee: string;
  interest: string;
  early_payment_discount: string;
  discount_deadline: string;
  generate_qrcode: boolean;
  discount_date: string;
  fine_amount: string;
  discount_amount: string;
  late_fee_amount: string;
  our_number: string;
  your_number: string;
  document_number: string;
};

const Container = styled.div`
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 25px 20px 25px 10px;
    background-color: white;
  }
`;

const OverduePaymentWrapper = styled.div<BilletCollectionProps>`
  background: #f2f7fa;

  padding: 10px 20px;
  min-height: ${(props) => (props.overduePaymentOpen ? "auto" : "50px")};
  border-radius: 8px;

  .hidden-content {
    height: ${(props) => (props.overduePaymentOpen ? "auto" : "0px")};
    opacity: ${(props) => (props.overduePaymentOpen ? 1 : 0)};
    visibility: ${(props) => (props.overduePaymentOpen ? "visible" : "hidden")};
    pointer-events: ${(props) => (props.overduePaymentOpen ? "all" : "none")};
    transition: height 0.5s ease-in-out;
  }
`;

const EarlyPaymentWrapper = styled.div<BilletCollectionProps>`
  background: #f2f7fa;
  padding: 10px 20px;
  min-height: ${(props) => (props.earlyPaymentOpen ? "auto" : "50px")};
  border-radius: 8px;
  .hidden-content {
    height: ${(props) => (props.earlyPaymentOpen ? "auto" : "0px")};
    opacity: ${(props) => (props.earlyPaymentOpen ? 1 : 0)};
    visibility: ${(props) => (props.earlyPaymentOpen ? "visible" : "hidden")};
    pointer-events: ${(props) => (props.earlyPaymentOpen ? "all" : "none")};
    transition: height 0.5s ease-in-out;
  }
`;

const MoreInfrmationsWrapper = styled.div<BilletCollectionProps>`
  background: #f2f7fa;
  padding: 10px 20px;
  min-height: ${(props) => (props.moreInformationsOpen ? "auto" : "50px")};
  border-radius: 8px;
  .hidden-content {
    height: ${(props) => (props.moreInformationsOpen ? "auto" : "0px")};
    opacity: ${(props) => (props.moreInformationsOpen ? 1 : 0)};
    visibility: ${(props) =>
      props.moreInformationsOpen ? "visible" : "hidden"};
    pointer-events: ${(props) => (props.moreInformationsOpen ? "all" : "none")};
    transition: height 0.5s ease-in-out;
  }
`;

const SplitWrapper = styled.div<BilletCollectionProps>`
  background: #f2f7fa;
  padding: 10px 20px;
  min-height: ${(props) => (props.splitOpen ? "auto" : "50px")};
  border-radius: 8px;
  .hidden-content {
    height: ${(props) => (props.splitOpen ? "auto" : "0px")};
    opacity: ${(props) => (props.splitOpen ? 1 : 0)};
    visibility: ${(props) => (props.splitOpen ? "visible" : "hidden")};
    pointer-events: ${(props) => (props.splitOpen ? "all" : "none")};
    transition: height 0.5s ease-in-out;
  }
`;

const convertStringToNumber = (value: string): number => {
  const onlyNumbers = value.replace(/[^0-9]/g, "");
  const valueWithDecimalPoint =
    onlyNumbers.slice(0, -2) + "." + onlyNumbers.slice(-2);
  const convertedValue = parseFloat(valueWithDecimalPoint);
  return convertedValue;
};

const FormSchema = Yup.object().shape({
  amount: Yup.string(),
  amount_first_parcel: Yup.string().required("Campo obrigatório"),
  number_of_parcels: Yup.number().required("Campo obrigatório"),
  due_date: Yup.string()
    .required("Campo obrigatório")
    .test("valid-date", "Data de vencimento inválida", (value) => {
      if (!value) {
        return false;
      }

      const currentDate = new Date();
      const selectedDate = new Date(value);

      return selectedDate >= currentDate;
    }),
  deadline_date: Yup.string()
    .required("Campo obrigatório")
    .test("valid-deadline-date", "Data limite inválida", function (value) {
      const { due_date } = this.parent;

      if (!value || !due_date) {
        return false;
      }

      const dueDate: any = new Date(due_date);
      const selectedDate = new Date(value);

      return selectedDate >= dueDate;
    }),
  send_for_email: Yup.boolean(),
  send_for_sms: Yup.boolean(),
  send_for_whatsapp: Yup.boolean(),
  late_payment_fee: Yup.string().required("Campo obrigatório"),
  interest: Yup.string().required("Campo obrigatório"),
  early_payment_discount: Yup.string().required("Campo obrigatório"),
  discount_deadline: Yup.string().required("Campo obrigatório"),
});

const CollectionData = (props: BilletCollectionProps) => {
  const optionsTime = [
    {
      value: 1,
      label: "Mensal",
    },
    {
      value: 2,
      label: "Bimestral",
    },
    {
      value: 3,
      label: "Trimestral",
    },
    {
      value: 4,
      label: "Semestral",
    },
    {
      value: 5,
      label: "Anual",
    },
  ];

  const { formatNumberToCurrency, mainAccount } = useAccount();

  const {
    handleNextStep,
    handlePreviousStep,
    setNewCollectionData,
    isInstallments,
    setIsInstallments,
    overduePayment,
    setOverduePayment,
    earlyPayment,
    moreInformations,
    setMoreInformations,
    setEarlyPayment,
    newCollectionData,
    contactInfo,
    BILLET_TAX,
    isRecurrence,
    hasSplit,
    setSplitsDataToSelect,
    splitsDataToSelect,
    setHasSplit,
    handleToggleModal,
    setTaxCreateBillet,
    setTaxCreateDynamicQrCode,
    setTaxLiquidateBillet,
    setTaxLiquidateDynamicQrCode,
    taxCreateBillet,
    taxCreateDynamicQrCode,
    taxLiquidateBillet,
    taxLiquidateDynamicQrCode,
    setSplitName,
  } = useNewCollection();

  const { balance, getBalance } = useAccount();

  const navigate = useNavigate();
  const { setOpenNewSplitModal } = useSplit();

  const [errorAmount, setErrorAmount] = useState<boolean>(false);
  const [fixedValueFine, setFixedValueFine] = useState<boolean>(true);
  const [fixedValueLateFee, setFixedValueLateFee] = useState<boolean>(false);

  const [valueOfTheOtherParcels, setValueOfTheOtherParcels] =
    useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [amountToReceive, setAmountToReceive] = useState<number>(0);
  const [splitId, setSplitId] = useState<number | null>(null);
  const [minCharge, setMinCharge] = useState<number>(0);

  const splitsList = splitsDataToSelect.map((item: any) => ({
    label: item.name,
    value: item.id,
    min_charge_amount: item.min_charge_amount,
  }));

  console.log;

  const initialValues: FormValues = {
    amount: newCollectionData?.amount
      ? formatNumberToCurrencyWithoutSimbol(newCollectionData.amount)
      : "",
    instruction: newCollectionData?.instruction ?? "",
    amount_first_parcel: "",
    number_of_parcels: 1,
    due_date: newCollectionData?.due_date ?? "",
    deadline_date: newCollectionData?.deadline_date ?? "",
    send_for_email: newCollectionData?.email_notification ?? false,
    send_for_sms: newCollectionData?.sms_notification ?? false,
    send_for_whatsapp: false,
    late_payment_fee: "",
    fine_amount: "",
    interest: "",
    early_payment_discount: "",
    discount_deadline: "",
    generate_qrcode: newCollectionData?.generate_qrcode ?? false,
    discount_amount: "",
    discount_date: "",
    late_fee_amount: "",
    our_number: "",
    your_number: "",
    document_number: "",
  };

  const { values, errors, setFieldValue, handleBlur, handleChange, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: FormSchema,
      onSubmit: (values) => {
        handleNextStep();
      },
    });

  const labelAmountToReceive = useMemo(() => {
    return formatNumberToCurrencyWithoutSimbol(
      Number(amountToReceive - taxLiquidateBillet)
    );
  }, [amountToReceive, taxLiquidateBillet]);

  const labelAmountToReceiveQrCode = useMemo(() => {
    return formatNumberToCurrency(amountToReceive - taxLiquidateDynamicQrCode);
  }, [amountToReceive]);

  const labelValueOfTheOtherParcels = formatNumberToCurrency(
    valueOfTheOtherParcels
  );

  const [payloadBilletWithoutParams, setPayloadBilletWithoutParams] =
    useState<billetPayloadType>({
      amount: convertedAmount(onlyNumbers(values.amount)),
      instruction: values.instruction,
      description: values.instruction,
      custom_parameters: overduePayment || earlyPayment,
      email_notification: values.send_for_email,
      sms_notification: values.send_for_sms,
      periodicity: null,
      wallet_type: "external",
      due_date: values.due_date,
      deadline_date: values.deadline_date,
      generate_qrcode: values.generate_qrcode,
      installment_number: null,
      payer: contactInfo.id,
      receiver: mainAccount?.partner,
      bank: 124,
    });

  const customParameters: billetPayloadType = {
    fine_type: !overduePayment
      ? "3"
      : overduePayment && fixedValueFine
      ? "1"
      : "2",
    fine_amount:
      overduePayment && values.fine_amount
        ? String(convertedAmount(onlyNumbers(values.fine_amount)))
        : !values.fine_amount
        ? "0"
        : "0",
    fine_date: values.due_date,
    late_fee_type: !overduePayment
      ? "5"
      : overduePayment && fixedValueLateFee
      ? "1"
      : "3",
    late_fee_amount:
      overduePayment && values.late_fee_amount
        ? String(convertedAmount(onlyNumbers(values.late_fee_amount)))
        : !values.late_fee_amount
        ? "0"
        : "0",
    late_fee_date: values.due_date,
    discount_type: earlyPayment ? "1" : "0",
    discount_amount:
      earlyPayment && values.discount_amount
        ? String(convertedAmount(onlyNumbers(values.discount_amount)))
        : !values.late_fee_amount
        ? "0"
        : "0",
    discount_date: values.discount_date
      ? values.discount_date
      : values.due_date,
  };

  const payloadBilletWithParams: billetPayloadType = {
    ...payloadBilletWithoutParams,
    ...customParameters,
  };

  const handleOverduePayment = () => {
    setOverduePayment(!overduePayment);
  };

  const handleEarlyPayment = () => {
    if (earlyPayment) {
      setFieldValue("discount_amount", "0");
    }

    setEarlyPayment(!earlyPayment);
  };

  const handleMoreInformations = () => {
    if (earlyPayment) {
      setFieldValue("discount_amount", "0");
    }

    setMoreInformations(!moreInformations);
  };

  const handleSplit = () => {
    setHasSplit(!hasSplit);
  };

  const handleParcelChange = (number_of_parcels: number) => {
    setFieldValue("number_of_parcels", number_of_parcels);
  };

  const handleCreateNewSplit = () => {
    handleToggleModal();
    setOpenNewSplitModal(true);
    navigate("/app/myspace/collection-management/split");
  };

  const calculateParcelValue = (
    totalAmount: number,
    firstParcelAmount: number,
    numberOfParcels: number
  ) => {
    if (firstParcelAmount) {
      const remainingAmount = totalAmount - firstParcelAmount;
      const remainingParcels = numberOfParcels - 1;
      return remainingAmount / remainingParcels;
    }
    return totalAmount / numberOfParcels;
  };

  const getSplits = async () => {
    try {
      const { data } = await ApiService.HttpGet<APIResponse<SplitGetType>>({
        route: "charge/split/",
        token: true,
      });

      setSplitsDataToSelect(data.results);
    } catch (e) {
      console.warn(e);
    }
  };

  const findMinChargeAmountById = (id: number): number | undefined => {
    const split = splitsDataToSelect.find((item: any) => item.id === id);
    setMinCharge(split?.min_charge_amount);
    setSplitName(split?.name);

    return split?.min_charge_amount;
  };

  const handleAdvance = () => {
    setNewCollectionData(
      overduePayment || earlyPayment
        ? payloadBilletWithParams
        : payloadBilletWithoutParams
    );

    handleNextStep();
  };

  const handleSelectChange = (selectedValue: any) => {
    setSplitId(selectedValue.target.value);
    console.log(selectedValue);
    findMinChargeAmountById(selectedValue.target.value);
  };

  const fetchTax = async () => {
    const slugs = [
      { slug: "create_dynamic_qrcode", setResult: setTaxCreateDynamicQrCode },
      { slug: "create_billet", setResult: setTaxCreateBillet },
      { slug: "liquidate_billet", setResult: setTaxLiquidateBillet },
      {
        slug: "liquidate_dynamic_qrcode",
        setResult: setTaxLiquidateDynamicQrCode,
      },
    ];

    try {
      for (const { slug, setResult } of slugs) {
        const response = await ApiService.HttpPost({
          route: "customer-global-tax/",
          token: true,
          body: {
            slug: slug,
            tax_is_enabled: true,
          },
        });

        setResult(response.data.customer_tax_out);
      }
    } catch (err) {
      console.error("Erro", err);
    }
  };

  useEffect(() => {
    getSplits();
    fetchTax();
    getBalance();
  }, []);

  useEffect(() => {
    if (!hasSplit) {
      setSplitId(null);
      setMinCharge(0);
    }
  }, [hasSplit]);

  useEffect(() => {
    let payload: any = {
      amount: convertedAmount(onlyNumbers(values.amount)),
      instruction: values.instruction,
      description: values.instruction,
      custom_parameters: overduePayment || earlyPayment,
      email_notification: values.send_for_email,
      sms_notification: values.send_for_sms,
      periodicity: null,
      wallet_type: "external",
      due_date: values.due_date,
      deadline_date: values.deadline_date,
      generate_qrcode: values.generate_qrcode,
      installment_number: null,
      payer: contactInfo.id,
      receiver: mainAccount?.partner,
      bank: 124,
    };

    if (hasSplit) {
      payload.split = splitId;
    }

    if (moreInformations && values.your_number !== "") {
      payload.your_number = onlyNumbers(values.your_number);
    }

    if (moreInformations && values.our_number !== "") {
      payload.our_number = onlyNumbers(values.our_number);
    }

    if (moreInformations && values.document_number !== "") {
      payload.document_number = onlyNumbers(values.document_number);
    }

    setPayloadBilletWithoutParams(payload);
  }, [
    hasSplit,
    moreInformations,
    splitId,
    values,
    overduePayment,
    earlyPayment,
    contactInfo,
    mainAccount,
  ]);

  useEffect(() => {
    const { amount, amount_first_parcel, number_of_parcels } = values;

    setErrorAmount(false);

    if (Boolean(amount)) {
      const totalAmount = convertStringToNumber(amount);
      const firstParcelAmount = convertStringToNumber(amount_first_parcel);
      setAmount(totalAmount);
      setAmountToReceive(totalAmount - BILLET_TAX);

      const otherParcelsValue = calculateParcelValue(
        totalAmount,
        firstParcelAmount,
        number_of_parcels
      );
      setValueOfTheOtherParcels(otherParcelsValue);
    }
  }, [values.amount, values.amount_first_parcel, values.number_of_parcels]);

  console.log(fixedValueFine);
  return (
    <Container className="p-4" {...props}>
      <div className="row d-flex align-items-center">
        <InputCurrency
          isPercentage={false}
          type="text"
          label="Inserir o valor da cobrança"
          name="amount"
          onChange={handleChange}
          value={values.amount}
          // onBlur={() => setErrorAmount(false)}
          error={
            amount < minCharge &&
            `Valor mínimo para este boleto é de R$ ${formatNumberToCurrencyWithoutSimbol(
              minCharge
            )}`
          }
          inputSize="large"
        />

        {/* {!isRecurrence && (
          <div
            className="col-3 pt-2 "
            // onClick={handleInstallments}
          >
            <Toggle mode="large" checked={isInstallments} disabled />
          </div>
        )} */}
      </div>
      {!isRecurrence && (
        <>
          <div>
            <div>
              <Description color="#666666">{`Taxa de Liquidação `}</Description>
              <Description className="font-weight-bolder">
                R$ {formatNumberToCurrencyWithoutSimbol(taxLiquidateBillet)}
              </Description>
            </div>
            <div>
              <Description color="#666666">{`Taxa de emissão `}</Description>
              <Description className="font-weight-bolder">
                R$ {formatNumberToCurrencyWithoutSimbol(taxCreateBillet)}
              </Description>
            </div>
          </div>
        </>
      )}
      <Spacer bottom={20} />
      {isInstallments && (
        <div>
          <ParcelSelector maxParcels={12} onChange={handleParcelChange} />
          <Spacer bottom={40} />
        </div>
      )}
      <div className="row ">
        {isInstallments && (
          <div className="col-6">
            {/* <InputCurrency
              type="text"
              label="Valor da primeira parcela"
              name="amount_first_parcel"
              helper={"Valor das demais parcelas "}
              placeholder="R$ 1.982,12"
              onChange={handleChange}
              value={values.amount}
              error={errors.amount}
            /> */}
            <Description className="value-parcel">
              {" "}
              {labelValueOfTheOtherParcels}
            </Description>
          </div>
        )}

        {isRecurrence && (
          <>
            <div className="col-6">
              <Spacer bottom={-7} />
              <Input
                type="date"
                // max="25-08-9999"
                // min="25-08-2023"
                maxLength={10}
                label="Data de inicio"
              />
            </div>
            <div className="col-6">
              <Spacer bottom={-7} />
              <CustomSelect
                label="Dia do vencimento"
                required
                options={[
                  {
                    value: 1,
                    label: "Dia 01",
                  },
                  {
                    value: 2,
                    label: "Dia 02",
                  },
                  {
                    value: 3,
                    label: "Dia 03",
                  },
                  {
                    value: 4,
                    label: "Dia 04",
                  },
                  {
                    value: 5,
                    label: "Dia 05",
                  },
                ]}
              />
            </div>
            <div className="col-6">
              <Spacer bottom={-7} />
              <CustomSelect
                label="Periodicidade"
                options={[
                  {
                    value: 1,
                    label: "Mensal",
                  },
                  {
                    value: 2,
                    label: "Bimestral",
                  },
                  {
                    value: 3,
                    label: "Trimestral",
                  },
                  {
                    value: 4,
                    label: "Semestral",
                  },
                  {
                    value: 5,
                    label: "Anual",
                  },
                ]}
              />
            </div>
            <div className="col-6">
              <Spacer bottom={-7} />
              <CustomSelect label="Definir duração" options={optionsTime} />
            </div>
          </>
        )}

        {!isRecurrence && (
          <div className="row">
            <div className="col-6">
              <Input
                type="date"
                label="Data de vencimento"
                name="due_date"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.due_date}
                required
                error={
                  touched.due_date &&
                  errors.due_date &&
                  "A data de vencimento não pode ser menor ou igual a data atual"
                }
              />
            </div>

            <div className="col-6">
              <Input
                type="date"
                label="Data limite para pagamento"
                name="deadline_date"
                onChange={handleChange}
                value={values.deadline_date}
                onBlur={handleBlur}
                error={
                  touched.deadline_date &&
                  errors.deadline_date &&
                  "A data limite não pode ser menor do que a data de vencimento"
                }
                required
              />
            </div>
          </div>
        )}
      </div>
      <Spacer bottom={10} />
      <div className="d-flex w-100 justify-content-between align-items-center row">
        <div className="col-6">
          <Description color="#929292">Gerar QR code dinâmico</Description>
        </div>
        <div
          className="col-6"
          onClick={() => {
            const newValue = !values.generate_qrcode;
            setFieldValue("generate_qrcode", newValue);
          }}
        >
          <Toggle mode="small" checked={values.generate_qrcode} />
        </div>

        {values.generate_qrcode && (
          <div className="mt-2">
            <div>
              <Description color="#666666">{`Taxa de Liquidação `}</Description>
              <Description className="font-weight-bolder">
                R${" "}
                {formatNumberToCurrencyWithoutSimbol(taxLiquidateDynamicQrCode)}
              </Description>
            </div>
            <Description color="#666666">{`Taxa de emissão `}</Description>
            <Description className="font-weight-bolder">
              R$ {formatNumberToCurrencyWithoutSimbol(taxCreateDynamicQrCode)}
            </Description>
          </div>
        )}
      </div>
      <Spacer bottom={20} />
      <Title>Canais de Cobrança</Title>
      <Spacer bottom={20} />
      <div className="row">
        <div className="d-flex w-100 justify-content-between align-items-center row">
          <div className="col-6">
            <Description color="#929292">Enviar por SMS</Description>{" "}
          </div>
          <div
            className="col-6"
            onClick={() => {
              const newValue = !values.send_for_sms;
              setFieldValue("send_for_sms", newValue);
            }}
          >
            <Toggle mode="small" checked={values.send_for_sms} />
          </div>
        </div>

        <Spacer bottom={20} />

        <div className="d-flex w-100 justify-content-between align-items-center row">
          <div className="col-6">
            <Description color="#929292">Enviar por Email</Description>{" "}
          </div>
          <div
            className="col-6"
            onClick={() => {
              const newValue = !values.send_for_email;
              setFieldValue("send_for_email", newValue);
            }}
          >
            <Toggle mode="small" checked={values.send_for_email} />
          </div>
        </div>
      </div>
      <Spacer bottom={40} />
      <MoreInfrmationsWrapper moreInformationsOpen={moreInformations}>
        <div className="d-flex justify-content-between align-items-center">
          <Title>Informações adicionais</Title>
          <div onClick={handleMoreInformations}>
            <Toggle mode="small" checked={moreInformations} />
          </div>
        </div>
        {moreInformations && <Spacer bottom={20} />}
        <div className="hidden-content row">
          <div className="col-6">
            <Input
              type="text"
              label="Nosso número"
              name="our_number"
              value={values.our_number}
              onChange={handleChange}
            />
          </div>
          <div></div>

          <div className="col-6">
            <Spacer bottom={-15} />
            <Input
              type="text"
              label="Seu número"
              name="your_number"
              value={values.your_number}
              onChange={handleChange}
            />
          </div>
          <div></div>

          <div className="col-6">
            <Spacer bottom={-15} />
            <Input
              type="text"
              label="Número do documento"
              name="document_number"
              value={values.document_number}
              onChange={handleChange}
            />
          </div>
        </div>
      </MoreInfrmationsWrapper>
      <Spacer bottom={10} />
      <OverduePaymentWrapper overduePaymentOpen={overduePayment}>
        <div className="d-flex justify-content-between align-items-center">
          <Title>Pagamento após vencimento</Title>
          <div onClick={handleOverduePayment}>
            <Toggle mode="small" checked={overduePayment} />
          </div>
        </div>
        {overduePayment && <Spacer bottom={20} />}
        <div className="hidden-content row">
          <div className="col-6">
            <InputCurrency
              name="fine_amount"
              type="text"
              label="Multa"
              value={values.fine_amount}
              onChange={handleChange}
              inputSize="normal"
            />
          </div>
          <div className="col-6 d-flex align-items-center "></div>
          <div className="col-6">
            <InputCurrency
              type="text"
              label={"Juros - % ao mês (dias corridos)"}
              placeholder="00,00%"
              name="late_fee_amount"
              isPercentage={!fixedValueLateFee}
              inputSize="normal"
              value={values.late_fee_amount}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 d-flex align-items-center "></div>
        </div>
      </OverduePaymentWrapper>
      <Spacer bottom={10} />
      <EarlyPaymentWrapper earlyPaymentOpen={earlyPayment}>
        <div className="d-flex justify-content-between align-items-center">
          <Title>Pagamento antecipado</Title>
          <div onClick={handleEarlyPayment}>
            <Toggle mode="small" checked={earlyPayment} />
          </div>
        </div>

        {earlyPayment && <Spacer bottom={20} />}
        <div className="hidden-content row">
          <div className="col-6">
            <InputCurrency
              name="discount_amount"
              type="text"
              label="Taxa de desconto (Valor fixo)"
              inputSize="normal"
              value={values.discount_amount}
              onChange={handleChange}
            />
          </div>
          <div></div>
          <div className="col-6">
            <Input
              type="date"
              label="Data limite para desconto"
              name="discount_date"
              onChange={handleChange}
              value={values.discount_date}
            />
          </div>
        </div>
      </EarlyPaymentWrapper>
      <Spacer bottom={10} />
      <SplitWrapper splitOpen={hasSplit}>
        <div className="d-flex justify-content-between align-items-center">
          <Title>Split</Title>
          <div onClick={handleSplit}>
            <Toggle mode="small" checked={hasSplit} />
          </div>
        </div>
        <Spacer bottom={hasSplit ? 20 : -20} />
        <div className="hidden-content row pt-3">
          {splitsDataToSelect.length > 0 ? (
            <>
              <CustomSelect
                options={splitsList}
                onChange={handleSelectChange}
                placeholder={"Selecione"}
                label="Selecione o arranjo"
                menuPlacement="top"
              />

              {amount < minCharge && (
                <p style={{ color: theme.colors.error.main }}>
                  {" "}
                  O valor do boleto está em R${" "}
                  {formatNumberToCurrencyWithoutSimbol(amount)}. Para utilizar
                  este arranjo você precisa redefini-lo para no mínimo R${" "}
                  {formatNumberToCurrencyWithoutSimbol(minCharge)}.{" "}
                </p>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <p>
                Você não possui arranjos criados.{" "}
                <span
                  onClick={handleCreateNewSplit}
                  style={{
                    color: theme.colors.secondary.main,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {"  "} Criar arranjo.
                </span>{" "}
              </p>
            </div>
          )}
        </div>{" "}
      </SplitWrapper>{" "}
      <Spacer bottom={20} />
      <Input
        type="textarea"
        label="Instruções adicionais"
        name="instruction"
        value={values.instruction}
        onChange={handleChange}
      />
      <Spacer bottom={60} />
      <div className="w-100 d-flex justify-content-between footer">
        <Button
          mode="phantom"
          label="Cancelar"
          onClick={() => {
            setNewCollectionData({});

            handlePreviousStep();
          }}
        />
        <Button
          mode="main"
          label="Avançar"
          onClick={handleAdvance}
          disabled={
            values.due_date === "" ||
            values.deadline_date === "" ||
            amount === 0 ||
            amount < minCharge ||
            balance <
              (values.generate_qrcode
                ? taxCreateBillet + taxCreateDynamicQrCode
                : taxCreateBillet)
              ? true
              : false
          }
        />
      </div>
    </Container>
  );
};

export default CollectionData;
