import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  ButtonDropdown,
  ReturnButton,
  Spacer,
  SummaryPanel,
  Tooltip,
} from "@zilliondigital/mirage-ui";
import useAccount from "~/hooks/useAccount";
import AddIcon from "~/assets/icons/buttons/more.png";
import PixIcon from "~/assets/icons/sidebar/ic_navigation_pix.png";
import EyeIcon from "~/assets/icons/eye-Size=MD.svg";
import EyeOffIcon from "~/assets/icons/eye-closed-Size=MD.svg";
import ChartApp, { ChartOptions } from "~/components/Chart";
import { useNavigate } from "react-router-dom";
import TransferIcon from "~/assets/icons/sidebar/ic_navigation_transfer.png";
import PayIcon from "~/assets/icons/sidebar/ic_navigation_billet.png";

import PaymentsIcon from "~/assets/icons/Actions/uniao2.png";
import CollectionsIcon from "~/assets/icons/sidebar/ic_navigation_payment.png";

import {
  ContainerData,
  DashboardActionsContainer,
  GeneralContainer,
  GeneralContainerDash,
  Title,
} from "~/pages/App/app-content.styles";
import styled from "styled-components";
import useStatements from "~/hooks/useStatements";
import { useEffect, useMemo, useState } from "react";
import AddBalanceModal from "./AddBalance/AddBalanceModal";
import useAddBalance from "~/hooks/useAddBalance";
import theme from "~/themes/theme";
import NewTransfer from "../Transfers/NewTransfer/NewTransfer";
import useTransfers from "~/hooks/Transfers/useTransfers";
import NewPaymentModal from "../Payments/NewPayment/NewPaymentModal";
import usePayments from "~/hooks/usePayments";
import NewCollectionSelect from "../Collections/NewCollectionOrRecurrence/CollectionOrRecurrenceSelect";
import useCollections from "~/hooks/Collections/useCollections";
import {
  formatCash,
  formatNumberToCurrencyWithoutSimbol,
  getToday,
  getYearAsString,
} from "~/utils";
import AvalaibleBalanceLoader from "./LoadersDashboard/AvalaibleBalanceLoader";
import ChartPannelLoader from "./LoadersDashboard/ChartPannelLoader";
import { addDays, format, parse, sub, subDays } from "date-fns";
import pt from "date-fns/locale/pt-BR";

import { Line } from "react-chartjs-2";
import Description from "~/components/Texts/Description";
import Loader from "~/components/Loader";
import DashButton from "~/components/DashButton";
import usePix from "~/hooks/PIX/usePix";
import StatementsTransactions from "../Statements/StatementsTransactions";
import StatementsTransactionsDash from "./StatementsDash";

const HIDDEN_BALANCE_TEXT: string = "R$ *****";

const HiddenBalanceText = styled.span`
  position: absolute;
`;

const ToolTipContainer = styled.div`
  position: relative;
  left: -13px;
  top: -8px;
`;

const ToolTipContainerAvailableBalance = styled.div`
  position: relative;
  left: 14px;
  top: -8px;
`;

const Dashboard = () => {
  const [isToolTipBalanceVisible, setIsToolTipBalanceVisible] =
    useState<boolean>(false);
  const [isToolTipVisible, setIsToolTipVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const { setOpenAddBalanceModal } = useAddBalance();
  const { setOpenNewTransferModalInDash } = useTransfers();
  const { setOpenNewPaymentModalInDash } = usePayments();
  const { setOpenNewCollectionOrRecurrenceModalInDash } = useCollections();
  const [loadingProjections, setLoadinProjections] = useState<boolean>(false);
  const { setOpenTransferWithPixModal } = usePix();

  const {
    hideAvailableBalance,
    setHideAvailableBalance,
    hideBalances,
    setHideBalances,
    formatNumberToCurrency,
    mainAccount,
    getBalance,
    balanceFormatted,
    blockedBalance,
    verifyPermission,
    loadingDash,
    setLoadingDash,
  } = useAccount();

  const { statementsDataChart, statistics, getStatistics } = useStatements();

  const { getBilletsProjections, futureReceivements } = useCollections();

  const canReadBalance: boolean = verifyPermission("ba_can_read_balance");
  const canCreatePayment: boolean = verifyPermission("ba_can_create_payment");
  const canCreateBillet: boolean = verifyPermission("ba_can_create_billet");
  const canCreatePaymentLink: boolean = verifyPermission(
    "ba_can_create_payment_link"
  );
  const canTransfer: boolean = verifyPermission("ba_can_create_transfer");

  const balanceBlockedFormatted = useMemo(() => {
    return formatNumberToCurrencyWithoutSimbol(blockedBalance);
  }, [blockedBalance]);
  const [isLoadingStatistics, setIsLoadingStatistics] =
    useState<boolean>(false);

  const [daysBefore, setDaysBefore] = useState<number>(0);
  const [daysBeforeStatistics, setDaysBeforeStatistics] = useState<number>(0);
  const isLoading = !mainAccount || loadingDash;

  const statementsPanel = useMemo(() => {
    const statementPanelArr = [
      {
        label: "Entradas",
        balance: "+ R$ 0,00",
        toggleBalance: true,
        currency: "+ R$",
        colorLabel: "#39B360",
      },
      {
        label: "SaÃ­das",
        balance: "-R$ 0,00",
        toggleBalance: true,
        currency: "- R$",
        colorLabel: "#E93019",
      },
    ];

    if (statistics) {
      statementPanelArr[0].balance = `+ ${formatNumberToCurrency(
        statistics?.total_in_amount ?? 0
      )}`;
      statementPanelArr[1].balance = `- ${formatNumberToCurrency(
        statistics?.total_out_amount ?? 0
      )}`;
    }

    return statementPanelArr;
  }, [statistics]);

  const balanceChartData = useMemo(() => {
    return (
      (statistics?.total_in_amount ?? 0) - (statistics?.total_out_amount ?? 0)
    );
  }, [statistics]);

  const toggleAvailableBalanceVisibility = () => {
    setHideAvailableBalance(!hideAvailableBalance);
  };

  const toggleBalancesVisibility = () => {
    setHideBalances(!hideBalances);
  };
  const availableBalanceFontSizeStyle = (balance: string) => {
    if (balance.length > 19) return { fontSize: "22px" };
    if (balance.length > 17) return { fontSize: "25px" };
    if (balance.length > 14) return { fontSize: "28px" };
    if (balance.length > 11) return { fontSize: "36px" };

    return { fontSize: "42px" };
  };

  const renderAvailableBalance = (loading?: boolean) => (
    <div className="d-flex align-items-center justify-content-start w-100 mb-2">
      {canReadBalance && (
        <span
          style={availableBalanceFontSizeStyle(balanceFormatted)}
          className={
            hideAvailableBalance ? "opacity-0 balances " : "opacity-1 balances "
          }
        >
          {!loading && balanceFormatted}
        </span>
      )}

      <HiddenBalanceText
        style={availableBalanceFontSizeStyle(balanceFormatted)}
        className={
          hideAvailableBalance ? "balances opacity-1" : "balances opacity-0"
        }
      >
        {HIDDEN_BALANCE_TEXT}
      </HiddenBalanceText>
      <Spacer left={10} />
      <div className=" col-2 d-flex flex-column align-items-end   position-relative ">
        <ToolTipContainerAvailableBalance>
          {" "}
          <Tooltip
            tooltipColor={theme.colors.info.main}
            tooltipLabel={!hideAvailableBalance ? "Ocultar" : "Mostrar"}
            visible={isToolTipBalanceVisible}
          />
        </ToolTipContainerAvailableBalance>

        <Spacer bottom={0} />

        {canReadBalance && (
          <img
            className="image-eye ms-1"
            src={!hideAvailableBalance ? EyeIcon : EyeOffIcon}
            onClick={toggleAvailableBalanceVisibility}
            onMouseEnter={() => setIsToolTipBalanceVisible(true)}
            onMouseLeave={() => setIsToolTipBalanceVisible(false)}
            style={
              isToolTipBalanceVisible
                ? {
                    filter: theme.colors.info.mainFilter,
                  }
                : {}
            }
          />
        )}
        <Spacer bottom={20} />
      </div>
    </div>
  );

  const renderActions = () => (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <Button
        className="mb-4"
        full
        mode="border"
        iconPosition="right"
        icon={AddIcon}
        label="Adicionar saldo"
        onClick={() => setOpenAddBalanceModal(true)}
      />
      {canTransfer && (
        <DashboardActionsContainer
          onClick={() => setOpenNewTransferModalInDash(true)}
        >
          <img
            style={{ filter: theme.colors.primary.mainFilter }}
            src={TransferIcon}
          />

          <span>Transferir</span>
        </DashboardActionsContainer>
      )}
      {canCreatePayment && (
        <DashboardActionsContainer
          onClick={() => setOpenNewPaymentModalInDash(true)}
        >
          <img
            style={{ filter: theme.colors.primary.mainFilter }}
            src={PaymentsIcon}
          />

          <span>Pagar</span>
        </DashboardActionsContainer>
      )}
      {(canCreateBillet || canCreatePaymentLink) && (
        <DashboardActionsContainer
          onClick={() => setOpenNewCollectionOrRecurrenceModalInDash(true)}
        >
          <img
            style={{ filter: theme.colors.primary.mainFilter }}
            src={CollectionsIcon}
          />
          <Spacer bottom={5} />
          <span>Cobrar</span>
        </DashboardActionsContainer>
      )}
    </div>
  );

  const renderProjection = () => (
    <ContainerData className="p-5" style={{ maxHeight: "36%" }}>
      <>
        <div className="d-flex flex-row-reverse justify-content-between align-items-center ">
          <ButtonDropdown
            options={optionsProjections}
            mode="border"
            padding={daysBefore === 0 ? 20 : 0}
            label="Hoje"
          />

          {loadingProjections ? (
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="70%"
              isHeight="15"
              isWidth="70%"
              box="0 0 70% 15"
              heightContainer="15"
            />
          ) : (
            <>
              <span>ProjeÃ§Ã£o de boletos</span>
            </>
          )}
        </div>

        <Spacer bottom={loadingProjections ? 5 : 20} />
        <div className=" d-flex flex-column">
          {loadingProjections ? (
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="65%"
              isHeight="40"
              isWidth="65%"
              box="0 0 65% 40"
              heightContainer="40"
            />
          ) : (
            <>
              <span className="future-receivables">
                +{" "}
                {futureReceivements === 0
                  ? "R$ 0,00"
                  : formatCash(futureReceivements)}{" "}
              </span>

              <span className="helper">Recebimentos futuros</span>
            </>
          )}
        </div>

        <Spacer bottom={200} />
        {/* <Spacer bottom={20} />
      <div className=" d-flex flex-column">
        <span className="future-payments">- R$ 0,00</span>
        <span className="helper">Pagamentos futuros</span>
      </div> */}
      </>
    </ContainerData>
  );

  const renderBalanceSummary = () => (
    <div className="w-100 p-30 pt-0 pb-0">
      <SummaryPanel
        panel={statementsPanel}
        isLoading={isLoading || isLoadingStatistics}
      />
    </div>
  );

  const dashPanel = [
    {
      label: "Saldo anterior",
      balance: balanceFormatted,
    },
    {
      label: "Entradas (Hoje)",
      balance: `+ ${balanceFormatted}`,
    },
    {
      label: "SaÃ­das (Hoje)",
      balance: `- ${balanceFormatted}`,
      colorLabel: "red",
      currency: "- R$",
    },
  ];
  const dashPanel1 = [
    {
      label: "Saldo atual",
      balance: balanceFormatted,

      bold: true,
      currency: "R$",
      render: () => (
        <div className="pe-4" style={{ position: "relative" }}>
          <div
            className="mt-2 w-100 "
            style={{
              position: "relative",
              left: "10px",
            }}
          >
            <div className="d-flex justify-content-between">
              <p>Saldo bloqueado:</p>

              <p className="me-2">R$ {balanceBlockedFormatted}</p>
            </div>
            <DashButton
              iconSize="14px"
              label="Adicionar saldo"
              icon={AddIcon}
              onClick={() => setOpenAddBalanceModal(true)}
            />
          </div>
        </div>
      ),
    },
  ];

  const today = useMemo(() => new Date(), []);

  const todayFormatted = useMemo(() => format(today, "yyyy-MM-dd"), [today]);

  const futureDate = useMemo(
    () => format(addDays(today, daysBefore), "yyyy-MM-dd"),
    [today, daysBefore]
  );
  const fromDate = useMemo(
    () => format(subDays(today, daysBeforeStatistics), "yyyy-MM-dd"),
    [today, daysBeforeStatistics]
  );

  const chartLabels = useMemo(() => {
    const today = new Date();

    const firstDay = subDays(today, daysBeforeStatistics);

    const lastDay = today;

    let showBy = "hour";

    if (daysBeforeStatistics > 0) showBy = "day";
    if (daysBeforeStatistics > 30) showBy = "month";
    if (daysBeforeStatistics > 365) showBy = "year";

    const labels = [];

    switch (showBy) {
      case "hour":
        for (let i = 0; i < 24; i++) {
          const padded = i.toString().padStart(2, "0");
          labels.push(`${padded}:00`);
        }
        break;
      case "day":
        for (let i = 0; i < daysBeforeStatistics; i++) {
          labels.push(format(addDays(firstDay, i), "dd/MM"));
        }
        break;
      case "month":
        const months = daysBeforeStatistics / 30;
        for (let i = 0; i < months; i++) {
          labels.push(format(addDays(firstDay, i * 30), "MM/yyyy"));
        }
        break;
      case "year":
        const years = daysBeforeStatistics / 365;
        for (let i = 0; i < years; i++) {
          labels.push(format(addDays(firstDay, i * 365), "yyyy"));
        }
        break;
    }

    return labels;
  }, [daysBeforeStatistics]);

  const optionsStatistics = [
    {
      label: "Hoje",
      value: "1",
      onClick: () => {
        setDaysBeforeStatistics(0);
      },
    },
    {
      label: "Ultimos 30 dias",
      value: "2",
      onClick: () => {
        setDaysBeforeStatistics(30);
      },
    },
    {
      label: "Ãšltimos 3 meses",
      value: "3",
      onClick: () => {
        setDaysBeforeStatistics(90);
      },
    },
    {
      label: "Ãštimos 6 meses",
      value: "4",
      onClick: () => {
        setDaysBeforeStatistics(180);
      },
    },
  ];

  const optionsProjections = [
    {
      label: "Hoje",
      value: "4",
      onClick: () => {
        setDaysBefore(0);
      },
    },
    {
      label: "30 dias",
      value: "1",
      onClick: () => {
        setDaysBefore(30);
      },
    },
    {
      label: "60 dias",
      value: "2",
      onClick: () => {
        setDaysBefore(60);
      },
    },
    {
      label: "90 dias",
      value: "3",
      onClick: () => {
        setDaysBefore(90);
      },
    },
  ];

  const statementsEntries = useMemo(
    () =>
      statementsDataChart?.filter((item) => item.operation_type !== "DayClose"),
    [statementsDataChart]
  );

  const outData = useMemo(() => {
    let showBy = "hour";
    if (daysBeforeStatistics > 0) showBy = "day";
    if (daysBeforeStatistics > 30) showBy = "month";
    if (daysBeforeStatistics > 365) showBy = "year";

    return chartLabels.map((label) => {
      const filteredStatements = statementsEntries.filter((statement: any) => {
        if (statement.amount > 0) {
          return false;
        }

        const statementDate = new Date(statement.entry_date);

        switch (showBy) {
          case "hour":
            const statementHour = statementDate.getHours();
            const labelHour = label.split(":")[0];
            return statementHour === parseInt(labelHour);

          case "day":
            const formattedDay = format(statementDate, "dd/MM");

            return formattedDay === label;

          case "month":
            const formattedMonth = format(statementDate, "MM/yyyy");
            return formattedMonth === label;

          case "year":
            const formattedYear = format(statementDate, "yyyy");
            return formattedYear === label;
        }

        return false;
      });

      const sum = filteredStatements.reduce((acc, statement) => {
        return acc + statement.amount * -1;
      }, 0);

      return sum;
    });
  }, [daysBeforeStatistics, statementsEntries, chartLabels]);

  const inData = useMemo(() => {
    let showBy = "hour";
    if (daysBeforeStatistics > 0) showBy = "day";
    if (daysBeforeStatistics > 30) showBy = "month";
    if (daysBeforeStatistics > 365) showBy = "year";

    return chartLabels.map((label) => {
      const filteredStatements = statementsEntries.filter((statement) => {
        if (statement.amount < 0) {
          return false;
        }

        const statementDate = new Date(statement.entry_date);

        switch (showBy) {
          case "hour":
            const statementHour = statementDate.getHours();
            const labelHour = label.split(":")[0];
            return statementHour === parseInt(labelHour);

          case "day":
            const formattedDay = format(statementDate, "dd/MM");

            return formattedDay === label;

          case "month":
            const formattedMonth = format(statementDate, "MM/yyyy");
            return formattedMonth === label;

          case "year":
            const formattedYear = format(statementDate, "yyyy");
            return formattedYear === label;
        }

        return false;
      });

      const sum = filteredStatements.reduce((acc, statement) => {
        return acc + statement.amount;
      }, 0);

      return sum;
    });
  }, [daysBeforeStatistics, statementsEntries, chartLabels]);

  const getDataDash = async () => {
    setLoadingDash(true);
    await getBalance();
    await getStatistics({ from_date: getToday(), to_date: todayFormatted });

    setLoadingDash(false);
  };

  useEffect(() => {
    if (mainAccount) {
      getDataDash();
    }
  }, [mainAccount]);

  const filterDaysProjections = async () => {
    setLoadinProjections(true);
    await getBilletsProjections(todayFormatted, futureDate);
    setLoadinProjections(false);
  };

  const filterStatistics = async () => {
    setIsLoadingStatistics(true);
    await getStatistics({ from_date: fromDate, to_date: todayFormatted });
    setIsLoadingStatistics(false);
  };

  useEffect(() => {
    filterDaysProjections();
  }, [daysBefore]);

  useEffect(() => {
    filterStatistics();
  }, [daysBeforeStatistics]);

  return (
    <GeneralContainerDash className="overflow-hidden">
      <div className="d-flex flex-column container-fluid w-100 h-100 ">
        <Spacer top={50} />

        <div className=" row">
          {" "}
          <div className="col-3 d-flex flex-column d-none">
            <ContainerData
              style={
                isLoading
                  ? { height: "370px", background: "#f8f8f8" }
                  : { height: "auto", background: "#f8f8f8" }
              }
              className=" p-5"
            >
              {/* {isLoading && <AvalaibleBalanceLoader />} */}
              {!isLoading && (
                <div className="d-flex justify-content-between align-items-center">
                  <span className="">Saldo disponÃ­vel</span>

                  <div
                    style={
                      isToolTipBalanceVisible
                        ? { position: "relative", left: 12 }
                        : { position: "relative", left: 12, zIndex: 1 }
                    }
                  >
                    <Button
                      label="Ver extrato"
                      mode="phantom"
                      link
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/app/statements")}
                    />
                  </div>
                </div>
              )}
              {!isLoading && (
                <>
                  <Spacer bottom={-15} />
                  {renderAvailableBalance(isLoading)}
                </>
              )}
              <Spacer top={0} />{" "}
              {!isLoading && (
                <div className="d-flex justify-content-between">
                  <p>Saldo bloqueado:</p>

                  <p className="me-2">R$ {balanceBlockedFormatted}</p>
                </div>
              )}
              <Spacer bottom={10} />
              {!isLoading && renderActions()}
            </ContainerData>
            <Spacer bottom={20} />
            {/* {!isLoading && renderProjection()} */}
          </div>
          <div className="col-3 ">
            <SummaryPanel
              panel={dashPanel1}
              // isLoading={isLoadingTransactions}
            />
          </div>
          <div className="col-9 ">
            <SummaryPanel
              panel={dashPanel}
              // isLoading={isLoadingTransactions}
            />

            <div>
              {" "}
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    position: "relative",
                    width: "97%",
                    height: "80px",
                    marginLeft: "22px",
                    background: theme.colors.base.light200,
                    paddingTop: "15px",
                    paddingBottom: "75px",
                    borderRadius: "8px",
                  }}
                >
                  <div>
                    <div className="row d-flex">
                      <div className="col-3">
                        <DashButton
                          label="Transferir"
                          icon={TransferIcon}
                          onClick={() => setOpenNewTransferModalInDash(true)}
                        />
                      </div>
                      <div className="col-3">
                        <DashButton
                          label="Pagar"
                          icon={PayIcon}
                          onClick={() => setOpenNewPaymentModalInDash(true)}
                        />
                      </div>
                      <div className="col-3">
                        {" "}
                        <DashButton
                          label="Cobrar"
                          icon={CollectionsIcon}
                          onClick={() =>
                            setOpenNewCollectionOrRecurrenceModalInDash(true)
                          }
                        />
                      </div>
                      <div className="col-3">
                        {" "}
                        <DashButton
                          label="PIX"
                          icon={PixIcon}
                          onClick={() => {
                            navigate("/app/pix");
                            setOpenTransferWithPixModal(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-5 align-items-center">
            <Title style={{ position: "relative", bottom: "-15px" }}>
              Ãšltimos lanÃ§amentos
            </Title>
            <Button
              label="Ver extrato completo"
              mode="main"
              onClick={() => navigate("/app/statements")}
            />
          </div>
          <StatementsTransactionsDash />
          <div className="col-8 d-none">
            <ContainerData
              style={isLoading ? { height: "720px" } : { height: "auto" }}
            >
              <>
                <div className="p-30">
                  {!isLoading && (
                    <div className="d-flex justify-content-between">
                      <Title>BalanÃ§o</Title>
                      <ButtonDropdown
                        padding={daysBeforeStatistics === 0 ? 80 : 0}
                        mode="border"
                        label="Hoje"
                        options={optionsStatistics}
                      />
                    </div>
                  )}
                  {(isLoading || isLoadingStatistics) && <ChartPannelLoader />}
                  {!isLoading && !isLoadingStatistics && (
                    <div className="d-flex justify-content-between align-items-end">
                      <div className=" w-100">
                        <div className="row">
                          <div className="col-12 d-flex align-items-center ">
                            <span
                              style={availableBalanceFontSizeStyle(
                                balanceFormatted
                              )}
                              className={
                                hideBalances
                                  ? "opacity-0 balances"
                                  : "opacity-1 balances"
                              }
                            >
                              {formatNumberToCurrency(balanceChartData)}
                            </span>

                            <HiddenBalanceText
                              style={availableBalanceFontSizeStyle(
                                balanceFormatted
                              )}
                              className={
                                hideBalances
                                  ? "balances opacity-1"
                                  : "balances opacity-0"
                              }
                            >
                              {HIDDEN_BALANCE_TEXT}
                            </HiddenBalanceText>
                            <Spacer left={25} />
                            <div
                              className="  d-flex flex-column align-items-start position-relative "
                              onClick={toggleBalancesVisibility}
                            >
                              <Spacer bottom={-20} />
                              <div>
                                <ToolTipContainer>
                                  {" "}
                                  <Tooltip
                                    tooltipColor={theme.colors.info.main}
                                    tooltipLabel={
                                      !hideBalances ? "Ocultar" : "Mostrar"
                                    }
                                    visible={isToolTipVisible}
                                  />
                                </ToolTipContainer>

                                <Spacer bottom={0} />
                                <img
                                  className="image-eye ms-1"
                                  src={!hideBalances ? EyeIcon : EyeOffIcon}
                                  onMouseEnter={() => setIsToolTipVisible(true)}
                                  onMouseLeave={() =>
                                    setIsToolTipVisible(false)
                                  }
                                  style={
                                    isToolTipVisible
                                      ? {
                                          filter:
                                            "brightness(0) saturate(100%) invert(71%) sepia(99%) saturate(1215%) hue-rotate(168deg) brightness(93%) contrast(89%)",
                                        }
                                      : {}
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!isLoading && !isLoadingStatistics && (
                    <div className="container-chart">
                      <Line
                        options={ChartOptions}
                        data={{
                          labels: chartLabels,
                          datasets: [
                            {
                              label: "Entradas",
                              data: inData,
                              borderColor: "#07b360",
                              backgroundColor: "#07b360",
                              yAxisID: "y",
                              tension: 0.4,
                              pointBackgroundColor: "rgb(255, 255, 255)",
                              pointBorderColor: "#07b360",
                              pointBorderWidth: 2,
                              pointRadius: 0,
                              pointHoverRadius: 6,
                            },
                            {
                              label: "Saidas",
                              data: outData,
                              borderColor: "#e80606",
                              backgroundColor: "#e80606",
                              xAxisID: "x",
                              tension: 0.4,
                              pointBackgroundColor: "rgb(255, 255, 255)",
                              pointBorderColor: "#e80606",
                              pointBorderWidth: 2,
                              pointRadius: 0,
                              pointHoverRadius: 6,
                            },
                          ],
                        }}
                      />
                    </div>
                  )}
                </div>
                <Spacer bottom={20} />
                {renderBalanceSummary()}
                <Spacer bottom={20} />{" "}
              </>
            </ContainerData>
          </div>
        </div>
        <div className="d-flex justify-content-end flex-1 pt-5">
          {" "}
          {/* <Description color="#CCCCCC" className="text-end">
            Powered by ProsperitÃ  InovaÃ§Ã£o e Tecnologia Ltda
            <br /> Â© 2018 - {getYearAsString()}
          </Description> */}
        </div>
      </div>

      <div style={{ zIndex: "4" }}>
        <AddBalanceModal />
        <NewTransfer />
        <NewPaymentModal />
        <NewCollectionSelect />
      </div>
    </GeneralContainerDash>
  );
};

export default Dashboard;
