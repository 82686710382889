import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AppStorage from "~/services/storage";

type PrivateRouterProps = {
  children: JSX.Element;
};

const PrivateRouter = ({ children }: PrivateRouterProps): JSX.Element => {
  const token = AppStorage.getToken();
  const user = AppStorage.getUser();

  if (token && user) {
    return children;
  } else {
    return <Navigate to="/auth/login" />;
  }
};

export default PrivateRouter;
