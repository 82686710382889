import { Fragment, useEffect, useState } from "react";
import Title from "~/components/Texts/Title";
import { ActivateCardCheckingButtonsDiv, ActivateCardCheckingImg, ActivateCardCheckingStepDiv, ActivateCardTitle, ActivateCardContainer, CardsDashboardHeader } from "./styled";
import useAccount from "~/hooks/useAccount";
import useCards from "~/hooks/Cards/useCards";
import { Button } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import CardImg from '~/assets/images/credit--card.svg'
import { ActivateCardSteps } from "~/hooks/Cards/interfaces";
import ActivateCardCheckingStep from "./section/ActivateCardCheckingStep";
import ActivateCardReceivedStep from "./section/ActivateCardReceivedStep";
import { ContainerData } from "../../app-content.styles";
import ActivateCardNotReceivedStep from "./section/ActivateCardNotReceivedStep";


const MyCardsActivateContainer = (props: {}) => {
  const { mainAccount } = useAccount()
  const navigate = useNavigate()
  const [cvv, setCVV] = useState<string>('')
  const {
    selectedCard,
    setSubPage,
    setActivateCardStep,
    activateCardStep,
    setActivatingCardStatus,
    activatingCardStatus,
    postActivateBankCard
  } = useCards()
  
  useEffect(() => {
    setSubPage({name: 'Ativar', route: '/app/cards/activate'})
    setActivateCardStep('CHECKING')
    if(selectedCard?.status != 'PENDING' || activatingCardStatus == 'SUCCESS'){
      navigate('/app/cards')
    }else{
      setActivatingCardStatus(null)
    }
  }, [])

  const _returnStepComponent = (step: ActivateCardSteps) => {
    if(!selectedCard){
      return <></>
    }

    switch (step){
      case 'CHECKING':
        return (
          <ActivateCardCheckingStep 
            activateCardStep={activateCardStep}
            setActivateCardStep={setActivateCardStep}
          />
        )
      case 'RECEIVED':
        return (
          <ActivateCardReceivedStep 
            activateCardStep={activateCardStep}
            setActivateCardStep={setActivateCardStep}
            selectedCard={selectedCard}
            setActivatingCardStatus={setActivatingCardStatus}
            activatingCardStatus={activatingCardStatus}
            postActivateBankCard={postActivateBankCard}
            cvv={cvv}
            setCVV={setCVV}
          />
        )
      case 'NOT_RECEIVED':
        return (
          <ActivateCardNotReceivedStep />
        )
      default:
        return <></>
    }
      
      
  }

  return (
    <Fragment>
      <ContainerData className="container">
        <CardsDashboardHeader>
          <Title>Ativar meu cartão</Title>
        </CardsDashboardHeader>
        <div className="col-md-8 w-100 d-flex flex-column px-4">
          <ActivateCardContainer>
            {_returnStepComponent(activateCardStep)}
          </ActivateCardContainer>
        </div>
      </ContainerData>
    </Fragment>
  );
};

export default MyCardsActivateContainer;
