import {
  BalanceAlert,
  Button,
  InputCurrency,
  Spacer,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";
import styled from "styled-components";
import { Fragment, useEffect, useState } from "react";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import { useFormik } from "formik";
import { onlyNumbers } from "~/services/utils";
import { convertedAmount } from "~/utils";
import hideFormatDocumentNumber from "~/utils/hideDocumentNumber";
import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";
import useContacts from "~/hooks/Contacts/useContacts";
import { useParams } from "react-router-dom";

const Container = styled.div`
  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;
  }
  padding: 20px;

  .image-eye {
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  .container-balance {
    position: relative;
    top: -20px;
  }

  .hide-balance {
    position: absolute;
    left: 180px;
  }
`;

type FormValues = {
  amount: string;
};

const initialValues: FormValues = {
  amount: "",
};

const AddPixKeyConfirm = () => {
  const { balance, setInsufficientBalance } = useAccount();

  const { handlePrevStep, dataNewPixTransfer, amount, setAmount } =
    useTransferWithPix();

  const {
    key,
    setKey,
    contactsData,
    contactIndex,
    setOpenAddPixKeyModal,
    setKeysData,
    typeOfKey,
    setTypeOfKey,
  } = useContacts();

  const params = useParams<{ id: string }>();

  const { showToast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { values, handleChange } = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {},
  });

  const getContactPixKeys = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: `pix/partners-key/${params.id}/keys/  `,
        token: true,
      });

      setKeysData(data.data);
    } catch {}
  };

  const handleAddKey = async () => {
    setIsLoading(true);

    try {
      const data = await ApiService.HttpPost({
        route: `pix/partners-key/${params.id}/add_key/`,
        body: {
          key: key,
          type:
            typeOfKey === "document_number" && key.length > 13
              ? "cnpj"
              : typeOfKey === "document_number" && key.length < 13
              ? "cpf"
              : typeOfKey,
        },
        token: true,
      });

      showToast("Chave Adicionada com sucesso", "", "success");
      getContactPixKeys();

      setOpenAddPixKeyModal(false);

      setKey("");
      setIsLoading(false);
    } catch (e: any) {
      showToast(e.response.data.data, "", "error");
      setIsLoading(false);
    }
  };

  const handleClick = (e: any) => {
    handleAddKey();
  };

  useEffect(() => {
    setAmount(convertedAmount(onlyNumbers(values.amount)));
  }, [values.amount]);

  useEffect(() => {
    if (amount > balance) {
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }
  }, [amount]);

  return (
    <Fragment>
      <Container className="p-4   ">
        <div className="d-flex justify-content-between align-items-center"></div>
        <Spacer bottom={error ? -15 : -25} />

        <Spacer top={40} />
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Nome</Description>
              </td>

              <td className="second-column">
                <Description>{dataNewPixTransfer?.name}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">CPF/CNPJ</Description>
              </td>

              <td className="second-column">
                <Description>
                  {hideFormatDocumentNumber(
                    dataNewPixTransfer?.document_number
                  )}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Instituição</Description>
              </td>

              <td className="second-column">
                <Description>{dataNewPixTransfer?.bank_name}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Agência</Description>
              </td>

              <td className="second-column">
                <Description>{dataNewPixTransfer?.agency}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Conta</Description>
              </td>

              <td className="second-column">
                <Description>
                  {dataNewPixTransfer?.account_number +
                    "-" +
                    dataNewPixTransfer.account_number_digit}
                </Description>
              </td>
            </tr>
          </tbody>
        </table>
        <Spacer top={40} />
        <div className="d-flex justify-content-between f">
          <Button mode="phantom" label="voltar" onClick={handlePrevStep} />
          <Button
            mode="main"
            label="Adicionar chave"
            loading={isLoading}
            onClick={handleClick}
          />
        </div>
      </Container>
    </Fragment>
  );
};

export default AddPixKeyConfirm;
