import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Menudropdown,
  NotificationsBar,
  Spacer,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import NotificationOffIcon from "~/assets/icons/icon_notifications_off.png";
import NotificationOnIcon from "~/assets/icons/Notification_menu.png";

import { ContainerImage, Header, NotificationsImg } from "./AppHeader.styles";
import useAccount from "~/hooks/useAccount";
import ApiService from "~/services/api";
import styled from "styled-components";
import LoaderHeader from "./LoaderHeader";
import useNotifications from "~/hooks/useNotifications";
import { getFirstLetters } from "~/services/utils";
import { formatDateHeader, formatDateTime, formatCPFCNPJ } from "~/utils";
import { useNavigate } from "react-router-dom";
import Description from "~/components/Texts/Description";
import MoreIcon from "~/assets/icons/buttons/more.png";
import HelpImg from "~/assets/icons/buttons/help.svg";
import useToast from "~/hooks/useToast";
import useAuth from "~/hooks/useAuth";
import theme from "~/themes/theme";
import label from "~/labels";
import { APIResponse } from "~/types";
import AppStorage from "~/services/storage";
import { format, isToday, differenceInHours } from "date-fns";

type GetNotifications = {
  id: number;
  partner: number;
  type: string;
  title: string;
  message: string;
  action: string;
  param: string;
  read: boolean;
  played: boolean;
  created: string;
  updatedt: string;
};

export type AppHeaderProps = {
  isSearching?: boolean;
  open?: boolean;
};

export type AccountsProps = {
  text: string;
  label: string;
};

const ContainerWelcome = styled.div`
  margin-top: 6px;
  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
      cursor: default;
    }
  }
`;

const UserDataContainer = styled.div<AppHeaderProps>`
  width: 260px;
  opacity: ${({ open }) => (open ? "1" : "0")};
  visibility: ${({ open }) => (open ? "default" : "hidden")};
  background-color: ${() => theme.colors.gray.light100};
  position: absolute;
  left: -690%;
  top: 130%;
  border: 1px solid ${() => theme.colors.primary.main};
  border-radius: 5px;
  transition: opacity 0.5s ease;

  .items-header {
    padding: 5px 10px;

    &:hover {
      background-color: ${() => theme.colors.primary.light100};
      cursor: pointer;
    }
  }
`;

const AppHeader = (props: AppHeaderProps) => {
  const { isSearching } = props;

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const {
    notifications,
    setNotifications,
    setHasNewNotification,
    hasNewNotification,
    showNotifications,
    setShowNotifications,
  } = useNotifications();

  const { setOpenHelpModal } = useAccount();

  const navigate = useNavigate();

  const { token, user } = useAuth();

  const { showToast } = useToast();

  const {
    isLoading,
    fetchBankAccountPermissions,
    bankAccountPermissions,
    mainAccount,
    handleMainAccountChange,
  } = useAccount();

  const newNotifications = notifications?.map((notification: any) => ({
    ...notification,
    text: notification.message,
    time: formatDateTime(notification.updatedt),
    param: notification.param,
  }));

  const notificationsBarRef = useRef<any>(null);
  const notificationsImgRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { setCanDoLogin } = useAuth();

  const accounts = useMemo(() => {
    const newAccounts = [...bankAccountPermissions];

    if (mainAccount) {
      const mainAccountIndex = newAccounts.findIndex(
        ({ bank_account_data }) =>
          bank_account_data.id === mainAccount.bank_account_data.id
      );

      if (mainAccountIndex === -1) {
        newAccounts.unshift(mainAccount);
      }

      if (mainAccountIndex > 0) {
        newAccounts.unshift(newAccounts.splice(mainAccountIndex, 1)[0]);
      }
    }

    const filteredAccounts = newAccounts.filter(
      (_acc) => !_acc.bank_account_data.dummy_account
    );

    const mappedNewAccounts = filteredAccounts.map((permission, idx) => ({
      img: (
        <div className="container-profile-picture">
          <div className="rounded-circle profile-picture">
            <h1>{getFirstLetters(permission.bank_account_data.owner.name)}</h1>
          </div>
        </div>
      ),
      text: ` ${permission.bank_account_data.owner.name} - ${permission.role_display} `,

      label: ` ${
        permission.bank_account_data.owner.document_number.length > 11
          ? "Pessoa Jurídica"
          : "Pessoa Física"
      }${" "}
            •${" "}
            ${formatCPFCNPJ(
              permission.bank_account_data.owner.document_number
            )}`,

      description: ` Conta ${permission.bank_account_data.type}  • ${permission.bank_account_data.agency} ${permission.bank_account_data.account_number}-${permission.bank_account_data.account_number_digit}`,

      changeUser: () => {
        handleMainAccountChange(permission.id);
      },
    }));

    return mappedNewAccounts;
  }, [mainAccount, bankAccountPermissions]);

  const handleNotification = (e: React.MouseEvent) => {
    e.stopPropagation();

    setShowNotifications(!showNotifications);

    if (notifications) {
      setHasNewNotification(false);
    }
  };

  const getNotifications = async () => {
    try {
      const { data } = await ApiService.HttpGet<APIResponse<GetNotifications>>({
        route: "notification/",
        token: true,
      });

      setNotifications(data.results);
      const now = new Date();

      for (const item of data.results) {
        const createdAt = new Date(item.created);

        if (
          isToday(createdAt) &&
          differenceInHours(now, createdAt) <= 1 &&
          !item.read
        ) {
          setHasNewNotification(true);

          const newData = await ApiService.HttpPatch({
            route: `notification/${item.id}/`,
            body: {
              read: true,
            },
            token: true,
          });

          showToast(
            item.title,
            item.title === "Extrato gerado com sucesso"
              ? "Clique aqui para baixar ou vá em notificações"
              : item.message,
            "success",
            () =>
              item.action === "open" ? window.open(item.param, "_blank") : null,
            true,
            60000
          );
        }
      }
    } catch (error) {
      console.error("Erro ao buscar notificações:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpened(false);

        event.stopPropagation();
      }
      event.stopPropagation();
    };

    document.addEventListener("click", handleClickOutside);
    // getNotifications();

    fetchBankAccountPermissions();

    if (token !== undefined) {
      setInterval(() => {
        getNotifications();
      }, 30000);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationsBarRef.current &&
        !notificationsBarRef.current.contains(event.target) &&
        event.target !== notificationsImgRef.current
      ) {
        setShowNotifications(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationsBarRef]);

  return (
    <Header
      {...props}
      isSearching={isSearching}
      style={isSearching ? { zIndex: "1" } : {}}
    >
      {isLoading && <LoaderHeader />}

      <div className="h-100 container-fluid content-header">
        <div className="row w-100 ">
          <div className={"d-flex col-8 "}>
            {!isLoading && accounts.length > 0 && (
              <div className="ps-3 d-flex">
                <ContainerWelcome className="d-flex">
                  <Spacer left={10} />
                  <div>
                    <Menudropdown
                      users={accounts as any}
                      bottomButton={{
                        label: (
                          <span
                            className="d-flex align-items-center"
                            style={{
                              color: theme.colors.primary.main,
                            }}
                          >
                            <img
                              src={MoreIcon}
                              style={{
                                filter: theme.colors.primary.mainFilter,
                              }}
                            />
                            <Spacer left={8} />
                            Abrir nova conta
                          </span>
                        ),
                        onBottomButtonClick: () => {
                          navigate("/signup/user/profile");
                        },
                      }}
                    />
                  </div>
                </ContainerWelcome>
              </div>
            )}
          </div>

          <div
            className={
              "col-4 d-flex  justify-content-end align-items-center pt-2 "
            }
          >
            <Description className="pt-2 d-none d-xxl-block">
              {formatDateHeader()}
            </Description>
            <Spacer left={36} />
            {!isLoading && (
              <div className="d-flex align-items-center">
                <NotificationsImg
                  ref={notificationsImgRef}
                  onClick={(e) => handleNotification(e)}
                  src={
                    hasNewNotification
                      ? NotificationOnIcon
                      : NotificationOffIcon
                  }
                />
                <Spacer left={10} />
                <div ref={notificationsBarRef} id="notifications-bar">
                  <NotificationsBar
                    title="Notificações"
                    open={showNotifications}
                    message="Sem notificações"
                    notifications={newNotifications}
                    right={160}
                    top={50}
                    onNotificationClick={(notif: any, index) => {
                      notif.action === "open" &&
                        window.open(notif?.param, "_blank");

                      setShowNotifications(false);
                    }}
                  />
                </div>
                <Spacer left={10} />

                <ContainerImage>
                  <div
                    className="container-profile-picture"
                    onMouseEnter={() => setIsOpened(true)}
                    ref={containerRef}
                  >
                    <div className="rounded-circle profile-picture">
                      <h1>{getFirstLetters(mainAccount?.owner.name)}</h1>
                    </div>
                  </div>

                  <UserDataContainer
                    className="d-flex flex-column"
                    open={isOpened}
                  >
                    <div
                      className="items-header "
                      style={{ cursor: "default" }}
                    >
                      {mainAccount?.owner.name}
                      <Spacer bottom={5} />
                      <Description color={theme.colors.gray.light300}>
                        {formatCPFCNPJ(mainAccount?.owner.document_number)}
                      </Description>
                    </div>
                    <Spacer bottom={10} />
                    {user?.is_backoffice || user?.is_approver ? (
                      <>
                        <div
                          className="items-header"
                          onClick={() => navigate("/app/backoffice")}
                        >
                          {"Aprovação de Cadastros"}
                          <Spacer bottom={5} />
                          <Description color={theme.colors.gray.light300}>
                            {"Aprovação de Cadastros"}
                          </Description>
                        </div>
                        <Spacer bottom={10} />
                      </>
                    ) : null}
                    <div
                      className="items-header pt-1 "
                      onClick={() => navigate("/app/myspace/concessions")}
                    >
                      {"Acessos e permissões"}
                      <Spacer bottom={5} />
                      <Description color={theme.colors.gray.light300}>
                        {"Acessos e permissões"}
                      </Description>
                    </div>

                    <div
                      className="items-header"
                      onClick={
                        () =>
                          user?.is_backoffice
                            ? navigate("/app/myspace/tariffs") // Para  o BackOffice
                            : navigate("/app/myspace/tax") // Para os clientes comuns
                      }
                    >
                      {"Tarifas"}
                      <Spacer bottom={5} />
                      <Description color={theme.colors.gray.light300}>
                        {"Confira aqui suas tarifas"}
                      </Description>
                    </div>

                    <div
                      className="items-header pb-4"
                      onClick={() =>
                        navigate("/app/myspace/collection-management")
                      }
                    >
                      {"Padrões de cobrança"}
                      <Spacer bottom={5} />
                      <Description color={theme.colors.gray.light300}>
                        {"Configure seus padrões"}
                      </Description>
                    </div>

                    <div
                      className="items-header pt-1 pb-4"
                      onClick={() => {
                        setCanDoLogin(false);
                        localStorage.removeItem("token");
                        AppStorage.remove("@app:user");
                        AppStorage.remove("@app:token");
                        window.location.reload();
                      }}
                    >
                      {"Sair"}
                    </div>
                  </UserDataContainer>
                </ContainerImage>
                <Spacer left={26} />
                <Button
                  mode="phantom"
                  label="Ajuda"
                  labelColor="#808080"
                  iconColorFilter="brightness(0) saturate(100%) invert(52%) sepia(0%) saturate(0%) hue-rotate(230deg) brightness(96%) contrast(90%);"
                  icon={HelpImg}
                  onClick={() => setOpenHelpModal(true)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
