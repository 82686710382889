import { Button, ProgressBar, Spacer } from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useCallback, useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import HelpImg from "~/assets/icons/buttons/help.svg";
import CheckedIcon from "~/assets/icons/checked.png";
import AnaliseIcon from "~/assets/icons/clock.png";
import AttachamentsIcon from "~/assets/icons/icon-attachments-active.png";
import AttachamentsIconInactive from "~/assets/icons/icon-attachments.png";
import InputDataIconActive from "~/assets/icons/icon-inputdata-active.png";
import SteperCompany from "~/components/Stepers/SteperCompany";
import { SteperItemProps } from "~/components/Stepers/SteperUser";
import useSignupCompany from "~/hooks/Signup/useSignupCompany";
import useAccount from "~/hooks/useAccount";
import useAuth from "~/hooks/useAuth";
import useCEP from "~/hooks/useCEP";
import useToast from "~/hooks/useToast";
import label from "~/labels";
import Address from "~/pages/Signup/SignupCompany/Address";
import Attachments from "~/pages/Signup/SignupCompany/Attachments";
import General from "~/pages/Signup/SignupCompany/General";
import Representatives from "~/pages/Signup/SignupCompany/Representatives";
import ApiService from "~/services/api";
import AppStorage from "~/services/storage";

import theme from "~/themes/theme";
import {
  Aside,
  Container,
  ContainerData,
  HeaderContainer,
  FooterSpanContainer,
  GeneralContainer,
} from "../Singup.styles";
import Security from "./Security";
import styled from "styled-components";
import { RegisteredPartner } from "~/types";

type FormDictType = {
  [key: string]: JSX.Element;
};

const FormDict: FormDictType = {
  general: <General />,
  representatives: <Representatives />,
  address: <Address />,
  attachments: <Attachments />,
  security: <Security />,
};

const TitlePage = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;
const SignupCompanyStructure = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { setToken, setUser } = useAuth();
  const { setOpenHelpModal } = useAccount();
  const {
    sending,
    step,
    setStep,
    setAddressData,
    canAdvance,
    stepIndex,
    stepChildIndex,
    companyAddressValues,
    updatePartnerStep,
    payloadDataCompany,
    setSending,
    setNewCompanyData,
    setCanAdvance,
    partnerData,
    addressData,
  } = useSignupCompany();

  const { setAddress: setCEPAddress } = useCEP();

  const registeredToken = useMemo(() => AppStorage.getRegisteredToken(), []);

  const menu: SteperItemProps[] = [
    {
      title: "Dados da empresa",
      step:
        step === "intro"
          ? "intro"
          : step === "general"
          ? "general"
          : step === "address"
          ? "address"
          : "representatives",
      stepIndex: stepIndex,
      icon: InputDataIconActive,
      iconInactive: InputDataIconActive,
      iconCompleted: CheckedIcon,
      children: [
        {
          title: "Geral",
          step: "general",
          stepIndex: stepChildIndex,
        },
        {
          title: "Representantes",
          step: "representatives",
          stepIndex: stepChildIndex,
        },
        {
          title: "Endereço",
          step: "address",
          stepIndex: stepChildIndex,
        },
      ],
    },

    {
      title: "Anexos & comprovantes",

      step: "attachments",
      stepIndex: stepIndex,
      icon: AttachamentsIcon,
      iconInactive: AttachamentsIconInactive,
      iconCompleted: CheckedIcon,
    },
  ];

  const menuDone: SteperItemProps[] = [
    {
      title: "Cadastro concluído",
      step: "",

      stepIndex: 10,
      icon: InputDataIconActive,
      iconInactive: InputDataIconActive,
      iconCompleted: CheckedIcon,
    },

    {
      title: "Em análise",

      step: "done",
      stepIndex: 0,
      icon: AnaliseIcon,
      iconInactive: AnaliseIcon,
      iconCompleted: CheckedIcon,
    },
  ];

  type StepRoutes = {
    [key: string]: string;
  };

  const stepsRoutes: StepRoutes = useMemo(
    () => ({
      general: "/signup/company/intro",
      representatives: "/signup/company/general",
      address: "/signup/company/representatives",
      attachments: "/signup/company/address",
    }),
    []
  );

  const labelStep: { [key: string]: string } = useMemo(
    () => ({
      intro: "Dados da empresa",
      general: "Dados da empresa",
      representatives: "Representantes",
      address: "Endereço",
      attachments: "Documentos",
      done: "Em análise",
    }),
    []
  );

  const advanceToAddress = useCallback(async () => {
    setSending(true);
    setCEPAddress({
      zip_code: "",
      street: "",
      district: "",
      city: "",
      state: "",
      error: "",
    });

    await updatePartnerStep("address", registeredToken);

    setStep("address");

    navigate("/signup/company/address");
    setSending(false);
  }, [navigate]);

  const setAddress = useCallback(async () => {
    const customHeaders = {
      Authorization: `Token ${registeredToken}`,
    };
    setSending(true);

    setAddressData({});

    try {
      const data = await ApiService.HttpPost({
        route: "address/",
        body: companyAddressValues,
        customHeaders,
      });

      await updatePartnerStep("attachments", registeredToken);

      setStep("attachments");
      navigate("/signup/company/attachments");
      setSending(false);
    } catch (e) {
      console.warn(e);
      setSending(false);
      showToast("Erro", String(e), "error");
    }
  }, [companyAddressValues, navigate]);

  const fetchUpdateAddress = useCallback(async () => {
    const newPartnerSaved: any = localStorage.getItem("new_company");
    const newPartnerSavedConverted = JSON.parse(newPartnerSaved);
    const customHeaders = {
      Authorization: `Token ${registeredToken}`,
    };
    setSending(true);
    setAddressData({});
    try {
      const data = await ApiService.HttpPut({
        route: `address/${newPartnerSavedConverted.id}/`,
        body: companyAddressValues,
        customHeaders,
      });

      await updatePartnerStep("attachments", registeredToken);
      setStep("attachments");
      navigate("/signup/company/attachments");
      setSending(false);
    } catch (e) {
      console.warn(e);
      showToast("Erro", String(e), "error");
      setSending(false);
    }
  }, [companyAddressValues, navigate]);

  const fetchSendDataCompany = useCallback(async () => {
    setSending(true);

    try {
      const response = partnerData?.id
        ? await ApiService.HttpPut<RegisteredPartner>({
            route: `partner/${partnerData?.id}/`,
            body: payloadDataCompany,
            customHeaders: {
              Authorization: `Token ${registeredToken}`,
            },
          })
        : await ApiService.HttpPost({
            route: "partner/",
            body: payloadDataCompany,
            customHeaders: {
              Authorization: `Token ${registeredToken}`,
            },
          });

      setNewCompanyData(response.data);

      AppStorage.setRegisteredPartner(response.data);

      localStorage.setItem(
        "new_company",
        JSON.stringify({
          id: response.data.id,
          name: response.data.name,
        })
      );

      await updatePartnerStep("representatives", registeredToken);
      setStep("representatives");
      navigate("/signup/company/representatives");

      setSending(false);
      setCanAdvance(false);
    } catch (err) {
      console.log(err);
      setSending(false);
    }
  }, [partnerData, payloadDataCompany, navigate]);

  const advanceToDone = useCallback(async () => {
    setSending(true);
    const customHeaders = {
      Authorization: `Token ${registeredToken}`,
    };

    try {
      await ApiService.HttpPost({
        route: "partner-sync/",
        customHeaders,
      });

      await ApiService.HttpPost({
        route: "submit-review/",
        customHeaders,
      });

      await updatePartnerStep("review", registeredToken);

      setStep("done");
      navigate("/signup/company/done");
      showToast(
        "Sucesso!",
        "Documentos finalizados! sua conta entrará em aprovação, lhe informamos via e-mail",
        "success"
      );
      setSending(false);
    } catch (err) {
      console.log(err);
      setSending(false);
    }
  }, []);

  const canRegister = useCallback(() => {
    if (!registeredToken) {
      showToast("Erro", "Você precisa estar logado", "error");
      navigate("/auth/login");
      return;
    }
  }, [navigate]);

  const returnToLogin = useCallback(() => {
    navigate("/auth/login");

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, [navigate]);

  const logout = useCallback(() => {
    setToken("");
    setUser(undefined);
    AppStorage.clear();
  }, []);

  const fetchDict: { [key: string]: () => void } = {
    intro: () => {
      setStep("general");
      navigate("/signup/company/general");
    },
    general: () => {
      fetchSendDataCompany();
    },
    representatives: () => advanceToAddress(),
    address: () => (addressData?.id ? fetchUpdateAddress() : setAddress()),
    attachments: () => advanceToDone(),
    done: () => {
      logout();
      returnToLogin();
    },
  };

  useEffect(() => {
    canRegister();
  }, []);

  return (
    <GeneralContainer className="  w-100">
      <Container className="d-flex flex-column flex-md-row h-100  ">
        <Aside className="col-md-4 d-flex flex-column ">
          <div className="d-flex  flex-column w-100 align-items-center align-items-md-start ">
            <div className="d-flex justify-content-start w-100">
              {/* <ImgLogo
                className="mb-2 mb-md-5"
                src={label.logoSignup ?? label.logo}
              /> */}
            </div>
            <Spacer bottom={50} />
            <div className="d-md-none w-100">
              <ProgressBar
                percent={
                  FormDict[step] ? 100 / Object.keys(FormDict).length : 5
                }
              />
            </div>

            <Spacer bottom={50} />
            <div>
              <SteperCompany menu={step === "done" ? menuDone : menu} />
            </div>
          </div>
        </Aside>

        <div style={{ backgroundColor: theme.colors.gray.light100 }}>
          <ContainerData className="col-md-8 d-flex flex-column">
            <Fragment>
              <Outlet />
            </Fragment>
          </ContainerData>
        </div>
      </Container>

      <HeaderContainer className="col-12 pt-3 pb-3 ">
        <TitlePage className="text-nowrap">{labelStep[step]}</TitlePage>
        <div style={{ position: "relative", top: "-7px" }}>
          <Button
            mode="phantom"
            iconColorFilter={
              label.usePrimaryColor?.includes("all_required_onboard")
                ? "brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(1021%) hue-rotate(84deg) brightness(113%) contrast(99%)"
                : " brightness(0) saturate(100%)"
            }
            icon={HelpImg}
            onClick={() => setOpenHelpModal(true)}
          />
        </div>
        <Spacer left={155} />
        <FooterSpanContainer></FooterSpanContainer>

        {step !== "security" && (
          <Fragment>
            <Spacer bottom={15} />
            <div className="d-flex justify-content-md-end justify-content-center w-100">
              {step !== "intro" && step !== "done" && (
                <Button
                  onClick={() => {
                    navigate(stepsRoutes[step]);

                    if (step === "attachments") {
                      setCEPAddress({
                        zip_code: "",
                        street: "",
                        district: "",
                        city: "",
                        state: "",
                        error: "",
                      });
                    }
                  }}
                  mode="border"
                  label={"Retornar"}
                />
              )}
              <Spacer left={10} />

              <Button
                onClick={() => {
                  fetchDict[step]();
                }}
                arrow={step !== "done" ? "right" : "left"}
                mode="main"
                label={step !== "done" ? "Seguir" : "Retornar ao login"}
                disabled={step === "intro" ? false : !canAdvance}
                type="submit"
                loading={sending}
              />
            </div>
          </Fragment>
        )}
      </HeaderContainer>
    </GeneralContainer>
  );
};

export default SignupCompanyStructure;
