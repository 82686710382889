import { Fragment, useEffect } from "react";
import Title from "~/components/Texts/Title";
import { CardsDashboardButtonDiv, CardsDashboardButtonWrapper, CardsDashboardHeader, CardsMyCardsButtonDiv } from "./styled";
import useAccount from "~/hooks/useAccount";
import TableLoader from "~/components/SkeletonTables";
import useCards from "~/hooks/Cards/useCards";
import { Button, NotControlledToolTip, Table } from "@zilliondigital/mirage-ui";
import MyCardsEmpty from "./features/CardsEmpty";
import { MyCardsTable } from "./tables/MyCardsTable";
import { useNavigate } from "react-router-dom";


const MyCardsContainer = (props: {}) => {
  const {
    cardsData,
    isLoadingCards,
    accountUuid,
    accountFetchingStatus
  } = useCards()
  const navigate = useNavigate()
  

  return (
    <Fragment>
      <CardsDashboardHeader>
        <Title>Meus Cartões</Title>
        <CardsDashboardButtonWrapper>
          <CardsMyCardsButtonDiv>
            <Button 
              label="Solicitar novo cartão"
              mode="main"
              style={{height: '40px', padding: '16px 32px'}}
              onClick={() => {
                navigate('/app/cards/create')
              }}
              disabled={accountUuid ? false : true}
              id={`create-card-btn--${accountUuid}`}
            />
            {
              accountUuid ?
              <></> : 
              <NotControlledToolTip placement="top" target={`create-card-btn--${accountUuid}`}>
                Não é possível solicitar um cartão no momento.
              </NotControlledToolTip>
            }
          </CardsMyCardsButtonDiv>
        </CardsDashboardButtonWrapper>
      </CardsDashboardHeader>
      <div className="col-md-8 w-100 d-flex flex-column px-4">
        {isLoadingCards || accountFetchingStatus != 'FETCHED' ? (
          <div className="w-100 mt-4 mb-4">
            <TableLoader />
          </div>
        ) : cardsData.length == 0 ? (
          <>
            <div style={{paddingTop:  '100px', paddingBottom: '150px'}}>
              <MyCardsEmpty  />
            </div>
          </>
        ) : null}
        {(cardsData.length > 0 && !isLoadingCards) ? (
          <MyCardsTable data={cardsData}/>
        ) : null}
      </div>
    </Fragment>
  );
};

export default MyCardsContainer;
