import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  Spacer,
  TabMenu,
} from "@zilliondigital/mirage-ui";
import { useCallback, useEffect, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import Skeleton from "~/components/SkeletonBalances";
import {
  ParentContainer,
  ContainerData,
  GeneralContainer,
} from "~/pages/App/app-content.styles";
import styled from "styled-components";
import theme from "~/themes/theme";

import usePermission from "~/hooks/usePermission";
import useAuthority from "~/hooks/useAuthority";
import Copyright from "~/components/Copyright";
import Paginator from "~/components/Paginator";

import SubMenu from "~/components/SubMenu";
import ApiService from "~/services/api";

import useTariffs from "~/hooks/useTariffs";
import useMySpace from "~/hooks/useMySpace";

const Card = styled.div`
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
  box-shadow: 0px 3px 1px 0px rgb(0 0 0 / 3%);
  padding: 10px;

  .empty-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem 1.5rem 1.5rem;
    flex-direction: column;

    span {
      text-align: center;
      display: block;
      margin: 15px 0;
    }

    img {
      filter: ${theme.colors.primary.mainFilter};
    }

    button {
      width: 100%;
      background: white;

      img {
        width: 15px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;

    .list {
      padding-left: 0px;
      width: 100%;

      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .item-box {
          padding: 10px 1.5rem;

          &:hover {
            background: rgba(0, 0, 0, 0.03);
          }
        }

        .desc {
          margin-bottom: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .footer-box {
    display: flex;
    padding: 15px 1.5rem;
    justify-content: flex-end;

    button {
      background: white;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
`;

const LoadingList = styled.div`
  padding: 0 1rem;
  svg {
    width: 100%;
    height: 70px;
  }
`;

const SkeletonList = () => {
  return (
    <LoadingList>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </LoadingList>
  );
};

const CollectionDefault = () => {
  const {
    setStepIndex,
    setNewTariff,
    newTariff,
    stepIndex,
    clientsData,
    setClientsData,
    page,
    setPage,
    value,
    setRowsPerPage,
    rowsPerPage,
    openEditTariffModal,
    setStepIndexModal,
  } = useTariffs();

  const {
    setActiveIndexMySpace,
    activeIndexCollectionsManagement,
    setActiveIndexCollectionsManagement,
  } = useMySpace();

  const navigate = useNavigate();
  const menusBreadCrumb = [
    { label: "Meu Espaço", href: "" },
    { label: "Tarifas", href: "app/myspace/tax" },
  ];

  const menus: any = [
    {
      label: "Tarifas Padrões",
      value: 1,
      onClick: () => {
        setStepIndex(0);
      },
    },
    {
      label: "Tarifas Personalizadas",
      value: 2,
      onClick: () => {
        setStepIndex(1);
      },
    },
  ];

  const menusSub: any[] = [
    {
      title: "Split",
      description: "Configure seus arranjos",
      onClick: () => navigate("/app/myspace/collection-management/split"),
    },
  ];

  useEffect(() => {
    setNewTariff(false);
    setStepIndex(0);
  }, []);

  const getClients = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/approved-partners/",
        token: true,
      });

      setClientsData(data);
    } catch {}
  };

  useEffect(() => {
    if (newTariff) {
      getClients();
    }
  }, [newTariff]);
  useEffect(() => {
    if (!openEditTariffModal) {
      setStepIndexModal(0);
    }
  }, [openEditTariffModal]);

  return (
    <>
      <div className="d-flex flex-column container-fluid w-100 h-100 ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <Card>
                <SubMenu
                  menus={menusSub}
                  activeIndex={activeIndexCollectionsManagement}
                />
              </Card>
            </div>

            <div className="col-md-8">
              <Outlet />
            </div>
          </div>
        </div>

        <Spacer top={40} />

        <Spacer top={30} />
      </div>
    </>
  );
};

export default CollectionDefault;
