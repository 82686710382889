import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const ChartOptions = {
  responsive: true,

  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: false,
    },

    tooltip: {
      backgroundColor: "#F8F8F8",
      bodyColor: "#808080",
      titleColor: "#222",
      displayColors: false,

      callbacks: {
        labelTextColor: function (context: any) {
          if (context.dataset.label === "Saidas") {
            return "#e80606";
          }

          if (context.dataset.label === "Entradas") {
            return "#07b360";
          }
        },
        label: function (context: any) {
          var label = context.dataset.label.toUpperCase() || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            const converted = new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(context.parsed.y));
            label = label + converted;
          }
          return label;
        },
      },
    },

    title: {
      display: true,
      text: "",
    },
  },
  scales: {
    x: {
      grid: { drawOnChartArea: false },
    },
  },
};

const labels = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul"];

export const data = {
  labels,
  datasets: [
    {
      label: "",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "#e80606",
      backgroundColor: "#e80606",
      xAxisID: "x",
      tension: 0.4,
      pointBackgroundColor: "rgb(255, 255, 255)",
      pointBorderColor: "#e80606",
      pointBorderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 6,
    },
    {
      label: "",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "#07b360",
      backgroundColor: "#07b360",
      yAxisID: "y",
      tension: 0.4,
      pointBackgroundColor: "rgb(255, 255, 255)",
      pointBorderColor: "#07b360",
      pointBorderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 6,
    },
  ],
};

const ChartApp = () => {
  return <Line options={ChartOptions} data={data} />;
};

export default ChartApp;
