export const CpfRegex = /([0-9]{3}[.]?\d{3}[.]?\d{3}-?\d{2})/g;

export const RGRegex = /(\d{2}[.]?\d{3}[.]?\d{3}[-]?\d{1})/g;

export const CnpjRegex = /(\d{2}[.]?\d{3}[.]?\d{3}[\/]?\d{4}[-]?\d{2})/g;

export const FullNameRegex =
  /^[A-Za-zÀ-ÖØ-öø-ÿ]+\s[A-Za-zÀ-ÖØ-öø-ÿ]+(\s[A-Za-zÀ-ÖØ-öø-ÿ]+)*$/;

export const PhoneRegex =
  /^(\([1-9]{2}\)\s9[0-9]{4}-[0-9]{4}|[1-9]{2}9[0-9]{8})$/;

export const TelphoneRegex = /^\(\d{2}\)\s\d{4}-\d{4}$/;

export const PasswordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/;

export const CepRegex = /[0-9]{5}-[0-9]{3}$/g;

export const WebsiteRegex = /\b\w+\.\w+\b/;

export const DateRegex =
  / ^(19\d{2}|[2-9]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])(?!.*\d).*|(19\d{2}|[2-9]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])(?=(?:\d{4}-\d{2}-\d{2})?$)(?!(?:19\d{2}|2\d{3})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])$)(?!0{4}-0{2}-0{2}$)\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])$  /;

export const HourRegex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/g;

export const REQUIRED_LABEL = "Campo obrigatório";

export const INVALID_FORMAT = "Formato inválido";

export const cnpjCpfRegex =
  /^(?:\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/;

export const dateENRegex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
