export interface ICreateCardInfos {
  card_type: 'PLASTIC' | 'VIRTUAL';
  name: string;
  use_other_address: boolean;
  other_address?: {
    zip_code: string;
    address: string;
    number?: string;
    district: string;
    city: string;
    state: string;
    country: string;
    complementary_address?: string;
    address_type: string;
  };
}

export const initalCreateCardInfos: ICreateCardInfos = {
  card_type: 'PLASTIC',
  name: 'Padrão',
  use_other_address: true,
  other_address: {
    zip_code: '',
    address: '',
    number: '',
    district: '',
    city: '',
    state: '',
    country: '',
    complementary_address: '',
    address_type: 'OTHER'
  }
}
