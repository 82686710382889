import {
  Button,
  NotControlledToolTip,
  Toggle,
} from "@zilliondigital/mirage-ui";
import { Card, CardStatusDict, CardTypeDict } from "~/hooks/Cards/interfaces";
import {
  MyCardsTableCardName,
  MyCardsTableCardSubName,
  MyCardsTableOptions,
  StatusMyCardsTablePill,
} from "./styled";
import CardIcon from "~/assets/icons/ic_context_payment_creditcard.svg";
import { fromCentsToBRMonetary } from "~/utils/formatters/toMonetary";
import OptionIcon from "~/assets/icons/ic_interactive_more.svg";
import { useNavigate } from "react-router-dom";
import useCards from "~/hooks/Cards/useCards";
import cardDetailModal from "../features/BankCardDetailsModal";
import { useEffect } from "react";
import useToken from "~/hooks/useToken";
import { formatCash } from "~/utils";

export const buildMyCardsColumnStatements = () => {
  const navigate = useNavigate();

  const { sendPartnerToken } = useToken();

  const {
    setSelectedCard,
    getCardDetailsStatus,
    getCardDetailsAsync,
    cardDetails,
    setCardDetailsStep,
    cardDetailsStep,
    selectedCard,
    setGetCardDetailsStatus,
    isCardDetailsOpen,
    setIsCardDetailsOpen,
    putCardContactlessAsync,
  } = useCards();

  useEffect(() => {
    if (isCardDetailsOpen && getCardDetailsStatus != null) {
      cardDetailModal({
        getCardDetailsStatus,
        getCardDetailsAsync,
        cardDetails,
        setCardDetailsStep,
        cardDetailsStep: cardDetailsStep,
        selectedCard: selectedCard,
        handleCloseFunc: () => {
          setIsCardDetailsOpen(false);
        },
      });
    }
  }, [cardDetails, cardDetailsStep, getCardDetailsStatus]);

  const renderOptions = (data: Card) => {
    switch (data.status) {
      case "PENDING":
        return (
          <Button
            label="Ativar"
            mode="main"
            style={{
              height: "32px",
              width: "100%",
              fontWeight: "400",
            }}
            onClick={() => {
              setSelectedCard(data);
              navigate("/app/cards/activate");
            }}
          />
        );

      case "ACTIVATED":
        return (
          <>
            <Button
              label="Bloquear"
              mode="border"
              style={{
                height: "32px",
                width: "100%",
                fontWeight: "400",
                color: "#706f6f",
              }}
              onClick={() => {
                setSelectedCard(data);

                navigate("/app/cards/block");
              }}
            />

            <Button
              label="Detalhes"
              mode="main"
              style={{
                height: "32px",
                width: "100%",
                fontWeight: "400",
              }}
              onClick={() => {
                sendPartnerToken();
                setSelectedCard(data);
                setGetCardDetailsStatus(null);
                setCardDetailsStep("CONFIRMATION");
                setIsCardDetailsOpen(true);

                cardDetailModal({
                  getCardDetailsStatus: null,
                  getCardDetailsAsync,
                  cardDetails,
                  setCardDetailsStep,
                  cardDetailsStep: "CONFIRMATION",
                  selectedCard: data,
                  handleCloseFunc: () => {
                    setIsCardDetailsOpen(false);
                  },
                });
              }}
            />
          </>
        );

      case "BLOCKED":
        return (
          <Button
            label="Desbloquear"
            mode="border"
            style={{
              height: "32px",
              width: "100%",
              fontWeight: "400",
              color: "#706f6f",
            }}
            onClick={() => {
              setSelectedCard(data);

              navigate("/app/cards/unblock");
            }}
          />
        );

      case "EXPIRED":
        return (
          <Button
            label="-"
            mode="border"
            disabled
            style={{
              height: "32px",
              width: "100%",
              fontWeight: "400",
              color: "#dedede",
              borderColor: "#e6e6e6",
            }}
          />
        );
    }
  };

  return [
    {
      label: "Cartão",
      key: "card-column",

      renderColumn: (data: any) => (
        <div className="d-flex  justify-content-start align-items-center">
          {data.label}
        </div>
      ),

      renderData: (data: Card) => (
        <div
          className="d-flex justify-content-start align-items-center"
          id={`card-${data.card_id}`}
        >
          <img src={CardIcon} />
          <div className="d-flex flex-column justify-content-center align-items-start ms-2">
            <MyCardsTableCardName>{data.name}</MyCardsTableCardName>
            <MyCardsTableCardSubName>
              {`${data.card_number ? `${data.card_number} - ` : ""} ${
                CardTypeDict[data.card_type]
              }`}
            </MyCardsTableCardSubName>
          </div>
        </div>
      ),
    },
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => (
        <div className="d-flex  justify-content-start align-items-center">
          {data.label}
        </div>
      ),

      renderData: (data: Card) => (
        <div
          className="d-flex justify-content-start align-items-center my-2"
          id={`status-${data.card_id}`}
        >
          <StatusMyCardsTablePill className={`${data.status}`}>
            {CardStatusDict[data.status]}
          </StatusMyCardsTablePill>
        </div>
      ),
    },
    {
      label: "Limite",
      key: "limit-column",

      renderColumn: (data: any) => (
        <div className="d-flex  justify-content-start align-items-center">
          {data.label}
        </div>
      ),

      renderData: (data: Card) => (
        <div
          className="d-flex justify-content-start align-items-center"
          id={`limit-${data.card_id}`}
          style={{ minWidth: "110px" }}
        >
          <MyCardsTableCardName className="mb-0">
            {formatCash(data.transaction_limit || 0)}
          </MyCardsTableCardName>
        </div>
      ),
    },
    {
      label: "Aproximação",
      key: "contactless-column",

      renderColumn: (data: any) => (
        <div className="d-flex  justify-content-start align-items-center">
          {data.label}
        </div>
      ),

      renderData: (data: Card) => (
        <div
          className="d-flex justify-content-start align-items-center"
          id={`contactless-${data.card_id}`}
        >
          <div
            className="mb-0 ms-0"
            style={{
              opacity:
                data.card_type == "VIRTUAL" || data.status != "ACTIVATED"
                  ? "0.4"
                  : "1",
            }}
          >
            <Toggle
              mode="small"
              checked={
                data.card_type == "PLASTIC" &&
                data.status == "ACTIVATED" &&
                data.contactless_enabled
              }
              disabled={
                data.card_type == "VIRTUAL" || data.status != "ACTIVATED"
              }
              onToggle={() => {
                if (
                  !(data.card_type == "VIRTUAL" || data.status != "ACTIVATED")
                ) {
                  putCardContactlessAsync(
                    data.contactless_enabled ? false : true,
                    data.card_id
                  );
                }
              }}
            />
          </div>
        </div>
      ),
    },
    {
      label: "",
      key: "options-column",

      renderColumn: (data: any) => (
        <div className="d-flex  justify-content-start align-items-center">
          {data.label}
        </div>
      ),

      renderData: (data: Card) => (
        <div
          className="d-flex justify-content-end align-items-center"
          id={`options-${data.card_id}`}
        >
          <div
            className="gap-2 d-flex justify-content-center align-items-center"
            style={{ width: "80%" }}
          >
            {renderOptions(data)}
          </div>
        </div>
      ),
    },
  ];
};
