export interface Card {
  card_id: string;
  name: string; //apelido do cartão
  transaction_limit?: number; // limite de transação
  card_type: "PLASTIC" | "VIRTUAL";
  status: "ACTIVATED" | "EXPIRED" | "BLOCKED" | "PENDING" | "PROCESSING";
  card_number: string | undefined; //número do cartão censurado **** **** **** 1111
  printed_name: string; //nome no cartão
  contactless_enabled?: boolean;
}

export interface CardDetails {
  card_number: string | undefined;
  expire_date: string;
  cvv: string;
  password?: string | undefined;
}

export const CardStatusDict = {
  ACTIVATED: "Ativo",
  EXPIRED: "Expirado",
  BLOCKED: "Bloqueado",
  PROCESSING: "Processando",
  PENDING: "Em produção",
};

export const CardTypeDict = {
  PLASTIC: "Físico",
  VIRTUAL: "Virtual",
};

export interface Transaction {
  uuid: string;
  value: number; // centavos
  description: string;
  created_at: Date;
  finished_at: Date;
  method: {
    card_id: string;
    card_number: string;
    location: string;
  };
  status: "PROCESSING" | "FINISHED" | "CANCELED" | "SCHEDULED";
}

export const RecentTransactionStatusDict = {
  PROCESSING: "Processando",
  CANCELED: "Cancelada",
  FINISHED: "Autorizada",
  SCHEDULED: "Agendada",
};

export const AuthorizationCategoryDict: Record<string, string> = {
  AUTHORIZATION: "Autorização",
  DECLINED: "Recusada",
  CONFIRMATION: "Confirmação",
  INSTALLMENT: "Parcelamento",
  CANCELLATION: "Cancelamento",
};

export interface errorBankCardsDTO {
  code: string;
  message: string;
  detail: {
    status?: string;
    message: string;
  };
}

export interface getDebitCardsAccountDTO {
  payload: {
    success?: boolean;
    uuid: string | null;
    status?: "PROCESSING" | "DISABLED" | "ENABLED" | null;
  };
  error?: errorBankCardsDTO | null;
}

export interface getDebitCardsDTO {
  payload: {
    success?: boolean;
    cards: Card[] | null;
  };
  error?: errorBankCardsDTO | null;
}

export interface getDebitCardDetailsDTO {
  payload: {
    success?: boolean;
    card: CardDetails | null;
  };
  error?: errorBankCardsDTO | null;
}

export interface putDebitCardContactlessDTO {
  payload: {
    success?: boolean;
  };
  error?: errorBankCardsDTO | null;
}

export interface TransactionDTO {
  uuid: string;
  value: number; // centavos
  description: string;
  created_at: string;
  finished_at: string;
  method: {
    card_id: string;
    card_number: string;
    location: string;
  };
  status: "PROCESSING" | "FINISHED" | "CANCELED" | "SCHEDULED";
}

export interface getRecentTransactionsDTO {
  payload: {
    success?: boolean;
    transactions: TransactionDTO[] | null;
  };
  error?: errorBankCardsDTO | null;
}

export interface postActivateDebitCardDTO {
  payload: {
    code: string;
  };
  error?: errorBankCardsDTO | null;
}

export type CreateCardStep =
  | "INFORMATION"
  | "DELIVERY_INFO_OTHER"
  | "CONFIRMATION"
  | "SUCCESS";
export type ActivateCardSteps =
  | "CHECKING"
  | "RECEIVED"
  | "ACTIVATED"
  | "NOT_RECEIVED";
export type BlockCardSteps = "CHECKING" | "CONFIRMATION" | "BLOCKED";
export type UnblockCardSteps = "CHECKING" | "CONFIRMATION" | "BLOCKED";
export type CardDetailsSteps = "CONFIRMATION" | "LOADING" | "SUCCESS";

export type CreatingCardStatus =
  | null
  | "LOADING"
  | "SERVER_ERROR"
  | "AUTHENTICATION_ERROR"
  | "SUCCESS";
export type ActivatingCardStatus =
  | null
  | "LOADING"
  | "SERVER_ERROR"
  | "AUTHENTICATION_ERROR"
  | "SUCCESS";
export type BlockingCardStatus =
  | null
  | "LOADING"
  | "SERVER_ERROR"
  | "AUTHENTICATION_ERROR"
  | "BLOCKED"
  | "SUCCESS";
export type UnblockingCardStatus =
  | null
  | "LOADING"
  | "SERVER_ERROR"
  | "AUTHENTICATION_ERROR"
  | "BLOCKED"
  | "SUCCESS";
export type GetCardDetailsStatus =
  | null
  | "LOADING"
  | "SERVER_ERROR"
  | "AUTHENTICATION_ERROR"
  | "SUCCESS";
export type PutCardContactlessStatus =
  | null
  | "LOADING"
  | "SERVER_ERROR"
  | "AUTHENTICATION_ERROR"
  | "SUCCESS";

export interface AuthorizationWebhook {
  authorization_category: string;
  account_id: number;
  card_id: number;
  customer_id: number;
  authorization_id: number;
  cid: string;
  unique_cid: string;
  mti: string;
  custom_response_code: string;
  custom_response_code_description: string;
  processing_code: string;
  principal_amount: number;
  contract_amount: number;
  installment_amount: number;
  local_amount: number;
  settlement_amount: number;
  number_of_installments: number;
  airport_tax: null;
  local_currency_code: string;
  cardholder_currency_code: string;
  settlement_currency_code: string;
  merchant_category_id: string;
  merchant_name: string;
  merchant_city: string;
  merchant_state_or_country: string;
  merchant_zip_code: string;
  soft_descriptor: string;
  card_acceptor_id: string;
  point_of_sale: Pointofsale;
  postings: Postings;
  rates: Rates;
  original_authorization_date_hour: string;
  original_event_date_hour_utc: string;
}

interface Rates {
  conversion_rate: number;
  cardholder_conversion_rate: number;
  settlement_conversion_rate: number;
}

interface Postings {
  cash_back_amount: number;
}

interface Pointofsale {
  entry_mode: string;
}
