import {
  BalanceAlert,
  Button,
  Checkbox,
  InteractiveModal,
  Spacer,
  SummaryPanel,
  Table,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { format, parseISO } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import EyeIcon from "~/assets/icons/eye-Size=MD.svg";
import EmptyListIcon from "~/assets/images/LoadingImages/Permissions.svg";
import { applyPagination } from "~/components/Paginator";
import Skeleton from "~/components/SkeletonTables";
import TitleModal from "~/components/Texts/TitleModal";
import { operationStatus } from "~/data/options";
import useAccount from "~/hooks/useAccount";
import useAuthority, { OPT_TYPES, OPT_TYPES_ICONS } from "~/hooks/useAuthority";

import { Columns } from "~/types";
import { formatCPFCNPJ, formatCash } from "~/utils";

import {
  isWeekDay,
  isWeekDayPayments,
  isWorkingHours,
  isWorkingHoursPayments,
} from "~/services/utils";
import theme from "~/themes/theme";
import usePermission from "~/hooks/usePermission";
import Description from "~/components/Texts/Description";

const PendingData = () => {
  const {
    operations,
    setCurrentModalData,
    currentModalData,
    setOpenOperationDetailModal,
    loadingApprove,
    approveOperation,
    loadingOperations: loadingList,
    setSelectedIds,
    selectedIds,
    getOperations,
    setTotalAmount,
    totalAmount,
    selectedTransaction,
    setSelectedTransaction,
    rowsPerPage,
    page,
    canApprove,
    setCanApprove,
    setCanNotSign,
    repproveOperation,
    isRepproved,
    setIsRepproved,
    statusColumnSelected,
    setStatusColumnSelected,
    insuficientBalance,
    setInsuficientBalance,
    openDesapproveModal,
    setOpenDesapproveModal,

    canNotSign,
  } = useAuthority();

  const [notComercialTimeMessage, setNotComercialTimeMessage] =
    useState<string>("");

  const [confirmSelectedAll, setConfirmSelectedAll] = useState<boolean>(false);

  const { list, setTransactionsEquals, transactionsEquals } = usePermission();

  const { balance, balanceFormatted } = useAccount();

  const handleApprove = async () => {
    setCanApprove(false);

    await approveOperation(selectedIds);
  };

  const handleRepprove = async () => {
    setCanApprove(false);

    await repproveOperation(selectedIds);
  };

  const isTedPayment = selectedTransaction?.some(
    (el) => el.bank_operation_type === "ted"
  );

  const isBilletPayment = selectedTransaction?.some(
    (el) => el.bank_operation_type === "billet_payment"
  );

  const totalOperations = useMemo(
    () => [
      {
        label: "Seu saldo",
        balance: balanceFormatted,
        toggleBalance: false,
        currency: "",
      },
      {
        label: "Total de operações",
        balance: selectedIds.length,
        toggleBalance: false,
        currency: "",
      },
      {
        label: "Valor total das operações",
        balance: `${formatCash(totalAmount)}`,
        toggleBalance: false,
        currency: "",
      },
    ],
    [totalAmount, selectedIds]
  );

  const notComercialTimeTED = useMemo(() => {
    return (isWeekDay && !isWorkingHours) || !isWeekDay;
  }, [isWeekDay, isWorkingHours]);

  const notComercialTimeBillet = useMemo(
    () => (isWeekDayPayments && !isWorkingHoursPayments) || !isWeekDayPayments,
    [isWeekDayPayments, isWorkingHoursPayments]
  );

  useEffect(() => {
    if (
      isTedPayment &&
      notComercialTimeTED &&
      isBilletPayment &&
      notComercialTimeBillet
    ) {
      setCanNotSign(true);
      setNotComercialTimeMessage(
        "Só é possivel assinar operações TED de Segunda a Sexta das 07h às 16:30h. Para Pagamento de boleto o horário é até as 19:00h. Retire as transações TED ou pagamento de boleto para prosseguir com as assinaturas."
      );
    } else if (isTedPayment && notComercialTimeTED) {
      setCanNotSign(true);
      setNotComercialTimeMessage(
        "Só é possivel assinar operações TED de Segunda a Sexta das 07h às 16:30h. Retire as transações TED para prosseguir com as assinaturas."
      );
      setCanNotSign(true);
    } else if (isBilletPayment && notComercialTimeBillet) {
      setCanNotSign(true);
      setNotComercialTimeMessage(
        "Só é possivel assinar operações de Pagamento de boleto de Segunda a Sexta das 07h às 19:00h. Retire as transações de Pagamento de boleto para prosseguir com as assinaturas."
      );
    } else {
      setCanNotSign(false);
      setNotComercialTimeMessage("");
    }
  }, [
    isTedPayment,
    isWeekDay,
    isWorkingHours,
    isBilletPayment,
    isWorkingHoursPayments,
    isWeekDayPayments,
  ]);

  const handleToggleIds = (data: any) => {
    const voucherToAdd = data;

    if (selectedIds.includes(voucherToAdd.id)) {
      setSelectedIds(
        selectedIds.filter((voucher: string) => voucher !== voucherToAdd.id)
      );
      setSelectedTransaction(
        selectedTransaction.filter(
          (voucher: any) => voucher.id !== voucherToAdd.id
        )
      );
    } else {
      setSelectedIds([...selectedIds, voucherToAdd.id]);
      setSelectedTransaction([...selectedTransaction, voucherToAdd]);
    }
  };

  const totalSum = useMemo(() => {
    const validTransactions = selectedTransaction || [];

    return validTransactions.reduce((accumulator, transaction) => {
      const amount = transaction?.operation_data?.amount;

      return accumulator + amount;
    }, 0);
  }, [selectedTransaction, selectedIds]);

  useEffect(() => {
    setTotalAmount(totalSum);
  }, [selectedIds]);

  const handleStatusColumnToggle = () => {
    const selectedIdsForStatusColumn = statusColumnSelected
      ? []
      : applyPagination(operationsFiltered, { page, rowsPerPage }).map(
          (transaction: any) => transaction.id
        );

    const selectedTransactionForStatusColumn = statusColumnSelected
      ? []
      : applyPagination(operationsFiltered, { page, rowsPerPage })?.map(
          (transaction: any) => transaction
        );

    setSelectedTransaction(selectedTransactionForStatusColumn);
    setSelectedIds(selectedIdsForStatusColumn);
    setStatusColumnSelected(!statusColumnSelected);
    setConfirmSelectedAll(false);
  };

  function findTransactionsWithEqualAmount() {
    const amountGroups: { [key: string]: any[] } = {};

    selectedTransaction?.forEach((transaction) => {
      const uniqueKey = `${transaction?.operation_data?.amount ?? "-"}-${
        transaction?.operation_data?.category ?? "-"
      }-${transaction?.bank_operation_type ?? "-"}-${
        transaction?.operation_data.recipient_bank_account?.account_number ??
        "-"
      }-${
        transaction?.operation_data.recipient_banK_account
          ?.account_number_digit ?? "-"
      }-${transaction?.operation_data.recipient_banK_account?.agency ?? "-"}-${
        transaction?.operation_data.recipient_banK_account?.bank ?? "-"
      }-${transaction?.operation_data?.recipient ?? "-"}-${
        transaction?.operation_data?.full_partner?.id ?? "-"
      }-${transaction?.operation_data?.receiver_account?.id ?? "-"}-${
        transaction?.operation_data?.key ?? "-"
      }-${transaction?.operation_data?.barcode ?? "-"}`;

      if (!amountGroups[uniqueKey]) {
        amountGroups[uniqueKey] = [];
      }
      amountGroups[uniqueKey].push(transaction);
    });

    const equalAmountPairs = Object.values(amountGroups).filter(
      (group) => group?.length > 1
    );

    return equalAmountPairs?.flat();
  }

  useEffect(() => {
    setTransactionsEquals(findTransactionsWithEqualAmount());
  }, [selectedTransaction]);

  const columns: Columns[] = [
    {
      label: "",
      key: "status",

      renderColumn: (data: any) => (
        <div
          className="d-flex justify-content-start align-items-center"
          onClick={() => {
            handleStatusColumnToggle();
          }}
        >
          <Spacer left={10} />
          <div className="align-items-center d-flex">
            <Checkbox checked={statusColumnSelected} />
          </div>
          <Spacer left={20} />

          {data.label}
        </div>
      ),

      renderData: (data: any) => (
        <div
          className="d-flex justify-content-start align-items-center"
          onClick={() => {
            handleToggleIds(data);
          }}
        >
          <div className="align-items-center d-flex ms-1">
            <Checkbox
              className="ps-1"
              checked={selectedIds.includes(data.id)}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Tipo",
      key: "bank_operation_type",
      renderData: (data: any) => (
        <div className="d-flex  align-items-center">
          <img src={OPT_TYPES_ICONS[data.bank_operation_type]} />
          <p className="ps-2 m-0">{OPT_TYPES[data.bank_operation_type]}</p>
        </div>
      ),
    },
    {
      label: "Status",
      key: "status",
      renderData: (data: any) => (
        <div className="d-flex flex-column " style={{ maxWidth: "400px" }}>
          {data.status === "error_retry" && (
            <p className=" text-danger">
              {data.status_description ?? "Ocorreu um erro."} Tente novamente.{" "}
            </p>
          )}

          {data.status === "pending" && data.sign_weight === 0 && (
            <p className="">Aguardando aprovações</p>
          )}

          {data.sign_weight !== 0 && (
            <p className="">Em aprovação ({data.sign_weight}%)</p>
          )}
        </div>
      ),
    },
    {
      label: "Beneficiário",
      key: "% assinada",

      renderData: (data: any) => (
        <div className="d-flex flex-column" style={{ maxWidth: "300px" }}>
          {data.operation_data ? (
            <>
              <p className="ps-3">
                {data.bank_operation_type === "pix_transfer"
                  ? data?.operation_data?.receiver_account?.owner.name
                  : data.bank_operation_type === "ted" ||
                    data.bank_operation_type === "tev"
                  ? data?.operation_data?.full_recipient?.name
                  : data.bank_operation_type === "billet_payment"
                  ? "Nome do beneficiário não encontrado"
                  : data.bank_operation_type === "pix_lot_transfers"
                  ? ""
                  : ""}
              </p>
              <Spacer bottom={-10} />
              <p className="ps-3">
                <b>
                  {data.bank_operation_type === "pix_transfer"
                    ? formatCPFCNPJ(
                        data?.operation_data?.receiver_account?.owner
                          .document_number
                      )
                    : data.bank_operation_type === "ted" ||
                      data.bank_operation_type === "tev"
                    ? formatCPFCNPJ(
                        data?.operation_data?.full_recipient?.document_number
                      )
                    : data.bank_operation_type === "billet_payment"
                    ? ""
                    : data.bank_operation_type === "pix_lot_transfers"
                    ? ""
                    : ""}
                </b>
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      label: "Data de emissão",
      key: "created",

      renderData: (data: any) => (
        <div>
          <p>
            {data.created
              ? format(parseISO(data.created.replace("Z", "")), "dd/MM/yyyy")
              : "---"}
          </p>
        </div>
      ),
    },
    {
      label: "Valor",
      key: "",

      renderData: (data: any) => (
        <div>
          <p>{data ? formatCash(data.operation_data?.amount) : "0"}</p>
        </div>
      ),
    },
    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="buttons-box btn-icon-20">
            <Button
              id={`btn-detail-${data.id}`}
              mode="phantom"
              label=""
              iconPosition="right"
              onClick={() => {
                setCurrentModalData(data);
                setOpenOperationDetailModal(true);
              }}
              icon={EyeIcon}
            />
          </div>
        );
      },
    },
  ];

  const operationsFiltered = useMemo(() => {
    if (!operations) {
      return [];
    }
    return operations?.filter(
      (operation) =>
        operation.signed_by_me === false &&
        (operation.status === "pending" || operation.status === "error_retry")
    );
  }, [operations]);

  useEffect(() => {
    if (totalAmount > balance) {
      setInsuficientBalance(true);
    } else {
      setInsuficientBalance(false);
    }
  }, [balance, totalAmount]);

  return (
    <div>
      {loadingList ? (
        <Skeleton />
      ) : operationsFiltered.length === 0 ? (
        <div className="empty-table-box no-filter">
          <img src={EmptyListIcon} />
          <span>Nenhuma transação pendente de aprovação hoje</span>
        </div>
      ) : (
        <div>
          {selectedTransaction?.length !== 0 && !canNotSign ? (
            <div>
              {" "}
              <SummaryPanel
                panel={totalOperations as any}
                // isLoading={pendingsLoading}
              />
            </div>
          ) : (
            <></>
          )}
          {notComercialTimeMessage !== "" && (
            <div className="p-5">
              <Description className="mt-5" color={theme.colors.error.main}>
                {notComercialTimeMessage}
              </Description>
            </div>
          )}
          {insuficientBalance && (
            <>
              {" "}
              <BalanceAlert
                title="Saldo insuficiente"
                message={
                  selectedIds.length > 1
                    ? "Você não pode aprovar essas transações por saldo insuficiente"
                    : "Você não pode aprovar essa transação por saldo insuficiente"
                }
              />{" "}
              <Spacer bottom={20} />
            </>
          )}
          <Table
            data={applyPagination(operationsFiltered, { page, rowsPerPage })}
            columns={columns}
            dataSpacer={8}
          />
        </div>
      )}

      <InteractiveModal
        open={canApprove}
        title="Aprovação"
        size="md"
        toggleModal={() => {
          setCanApprove(!canApprove);
        }}
      >
        <div className="p-4 h-100 d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
          <TitleModal>Confirmar {" Aprovação "}</TitleModal>
          <div className="d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
            <p className="text-center">{`Deseja realmente aprovar ${
              selectedIds.length > 1
                ? `essas ${selectedIds.length} operações`
                : "essa operação"
            } no valor de ${formatCash(totalAmount)}`}</p>
            <div style={{ width: "290px" }} className="mb-5">
              <Spacer bottom={5} />
              <Button
                mode="main"
                label={"Sim, aprovar"}
                full
                onClick={() => {
                  handleApprove();
                }}
                disabled={loadingApprove}
                loading={loadingApprove}
              />
              <Spacer bottom={5} />
              <Button
                mode="border"
                label="Cancelar"
                full
                onClick={() => setCanApprove(false)}
              />
            </div>
          </div>
        </div>
      </InteractiveModal>

      <InteractiveModal
        open={openDesapproveModal}
        title="Reprovação"
        size="md"
        toggleModal={() => {
          setOpenDesapproveModal(!openDesapproveModal);
        }}
      >
        <div className="p-4 h-100 d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
          <TitleModal>Confirmar {"Reprovação"}</TitleModal>
          <div className="d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
            <p className="text-center">{`Deseja realmente reprovar ${
              selectedIds.length > 1
                ? `essas ${selectedIds.length} operações`
                : "essa operação"
            } no valor de ${formatCash(totalAmount)}`}</p>
            <div style={{ width: "290px" }} className="mb-5">
              <Spacer bottom={5} />
              <Button
                mode="main"
                label={"Sim, reprovar!"}
                full
                onClick={() => {
                  handleRepprove();
                }}
                disabled={loadingApprove}
                loading={loadingApprove}
              />
              <Spacer bottom={5} />
              <Button
                mode="border"
                label="Cancelar"
                full
                onClick={() => setOpenDesapproveModal(false)}
              />
            </div>
          </div>
        </div>
      </InteractiveModal>
      <InteractiveModal
        open={loadingApprove}
        title="Aprovação"
        size="md"
        toggleModal={() => undefined}
      >
        <div className="p-4 h-100 d-flex h-100 justify-content-center align-items-center w-100   flex-column">
          <Spacer bottom={120} />
          <div
            className="spinner-border mb-2"
            role="status"
            style={{ color: theme.colors.primary.main }}
          ></div>
          <Spacer top={20} />
          <div className="text-center">
            Aguarde, esta operação pode levar alguns minutos...
          </div>
        </div>
      </InteractiveModal>
    </div>
  );
};

export default PendingData;
