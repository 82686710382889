import {
  Button,
  Checkbox,
  CustomSelect,
  Input,
  InputCurrency,
  LateralModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import useBanks from "~/hooks/useBanks";

import usePayments from "~/hooks/usePayments";
import { Subtitle } from "~/pages/App/app-content.styles";

type FilterStatementsValues = {
  contact: string;
  identification: string;
  transactionValueFrom: string;
  transactionValueTo: string;
  transactionDateFrom: string;
  transactionDateTo: string;
  waiting_for_approval: boolean;
  scheduled: boolean;
  canceled: boolean;
  completed: boolean;
  ted: boolean;
  pix: boolean;
  a2a: boolean;
};

const initialValues: FilterStatementsValues = {
  contact: "",
  identification: "",
  transactionValueFrom: "",
  transactionValueTo: "",
  transactionDateFrom: "",
  transactionDateTo: "",
  waiting_for_approval: false,
  scheduled: false,
  canceled: false,
  completed: false,
  ted: false,
  pix: false,
  a2a: false,
};

const FormSchema = Yup.object().shape({
  contact: Yup.string(),
  identification: Yup.string(),
  transactionValueFrom: Yup.string(),
  transactionValueTo: Yup.string(),
  transactionDateFrom: Yup.string(),
  transactionDateTo: Yup.string(),
  waiting_for_approval: Yup.boolean(),
  scheduled: Yup.boolean(),
  canceled: Yup.boolean(),
  completed: Yup.boolean(),
  ted: Yup.boolean(),
  pix: Yup.boolean(),
  a2a: Yup.boolean(),
});

const PaymentsFilter = () => {
  const { openFilterModal, setOpenFilterModal } = usePayments();

  const handleFormSubmit = (values: FilterStatementsValues) => {
    return;
  };

  const { handleChange, setFieldValue, values, resetForm } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleFormSubmit,
  });

  const { getBanks, bankOptions, banksData } = useBanks();
  useEffect(() => {
    banksData?.length <= 0 && getBanks();
  }, []);

  return (
    <LateralModal
      open={openFilterModal}
      title="Filtros"
      toggleModal={() => setOpenFilterModal(!openFilterModal)}
      header
    >
      <div className=" w-100 vh-100 d-flex flex-column justify-content-start p-4">
        <form className="col-12">
          <div className="row ">
            <div className="col-6">
              <Input
                name="contact"
                value={values.contact}
                onChange={handleChange}
                type="text"
                label="Contato"
                placeholder="Jhon Doe"
              />
            </div>
            <div className="col-6">
              <Input
                name="identification"
                value={values.identification}
                onChange={handleChange}
                type="text"
                label="Identificação"
                placeholder="#124547"
              />
            </div>
            <Subtitle className="ps-2">Valor da transferência</Subtitle>
            <div className="col-12 col-md-6">
              <InputCurrency value={values.transactionValueFrom} label="De" />
            </div>
            <div className="col-12 col-md-6">
              <InputCurrency value={values.transactionValueTo} label="Até" />
            </div>
            <Subtitle className="ps-2">Data da tranferência</Subtitle>
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateFrom"
                value={values.transactionDateFrom}
                onChange={handleChange}
                type="date"
                label="De"
              />
            </div>
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateTo"
                value={values.transactionDateTo}
                onChange={handleChange}
                type="date"
                label="Até"
              />
            </div>

            <div className="col-6 d-flex flex-column align-items-start ">
              <Subtitle>Status da transação</Subtitle>
              <Checkbox
                name="waiting_for_approval"
                checked={values.waiting_for_approval}
                onClick={() =>
                  setFieldValue(
                    "waiting_for_approval",
                    !values.waiting_for_approval
                  )
                }
                label="Aguardando aprovação"
                position="right"
              />

              <Spacer bottom={10} />

              <Checkbox
                label="Agendado"
                name="scheduled"
                checked={values.scheduled}
                onClick={() => setFieldValue("scheduled", !values.scheduled)}
                position="right"
              />

              <Spacer bottom={10} />
              <Checkbox
                label="Cancelado"
                name="canceled"
                checked={values.canceled}
                onClick={() => setFieldValue("canceled", !values.canceled)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Efetuado"
                name="completed"
                checked={values.completed}
                onClick={() => setFieldValue("completed", !values.completed)}
                position="right"
              />
            </div>

            <div className="col-6 d-flex flex-column align-items-start ">
              <Subtitle>Tipo de transação</Subtitle>
              <Checkbox
                label="TED"
                name="ted"
                checked={values.ted}
                onClick={() => setFieldValue("ted", !values.ted)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="PIX"
                name="pix"
                checked={values.pix}
                onClick={() => setFieldValue("pix", !values.pix)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Transferência entre contas"
                name="a2a"
                checked={values.a2a}
                onClick={() => setFieldValue("a2a", !values.a2a)}
                position="right"
              />
            </div>

            <div className="col-6">
              <Spacer bottom={20} />
              <CustomSelect
                name="select"
                label="Instituição "
                placeholder="Selecione"
                options={bankOptions}
              />
            </div>

            <Spacer bottom={10} />
          </div>
        </form>

        <Spacer bottom={25} />
        <div className="w-100 d-flex justify-content-between ">
          <Button
            mode="phantom"
            label="Limpar tudo"
            onClick={() => {
              resetForm();
            }}
          />
          <Button mode="main" label="Aplicar filtros" />
        </div>
      </div>
    </LateralModal>
  );
};

export default PaymentsFilter;
