import { Table } from "@zilliondigital/mirage-ui";
import { buildMyCardsColumnStatements } from "./myCardsColumnStatements";
import { Card, Transaction } from "~/hooks/Cards/interfaces";
import { buildRecentTransactionsStatements } from "./recentTransactionsStatements";
import useCards from "~/hooks/Cards/useCards";

interface ICardsRecentTransactionsTable {
  data: Transaction[];
}
export const CardsRecentTransactionsTable = ({
  data,
}: ICardsRecentTransactionsTable) => {
  const { cardsData } = useCards();

  const columnsStatements: any = buildRecentTransactionsStatements(cardsData);

  return (
    <div className="w-100 px-4">
      <Table data={data} columns={columnsStatements} dataSpacer={8} />
    </div>
  );
};
