import {
  Breadcrumb,
  Button,
  Spacer,
  TabMenu,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Skeleton from "~/components/SkeletonBalances";
import Title from "~/components/Texts/Title";
import {
  ContainerData,
  GeneralContainer,
  ParentContainer,
} from "~/pages/App/app-content.styles";

import theme from "~/themes/theme";
import { formatCPFCNPJ } from "~/utils";

import MoreIcon from "~/assets/icons/buttons/more-icon.png";
import ShieldIcon from "~/assets/icons/shield.svg";
import DoubleUserIcon from "~/assets/icons/user-couple.svg";
import UserIcon from "~/assets/icons/user.svg";

import Copyright from "~/components/Copyright";
import Paginator from "~/components/Paginator";
import useAuthority from "~/hooks/useAuthority";
import usePermission from "~/hooks/usePermission";

import ApprovedData from "~/pages/App/Authoritys/ApprovedData";
import PendingData from "~/pages/App/Authoritys/PendingData";
import ShowOperationModal from "~/pages/App/Authoritys/ShowOperationModal";

import useAccount from "~/hooks/useAccount";
import ApprovedByMe from "../Authoritys/ApprovedByMe";
import ConfirmTransactionsEquals from "../Authoritys/ConfirmTransactionsEquals";
import RepprovedData from "../Authoritys/RepprovedData";
import NewConcessionModal from "./NewConcessionModal";
import NewPermissionsModal from "./PermissionModal";

const Card = styled.div`
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
  box-shadow: 0px 3px 1px 0px rgb(0 0 0 / 3%);

  .title-box {
    padding: 1.5rem;
    font-weight: 500;
  }

  .empty-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem 1.5rem 1.5rem;
    flex-direction: column;

    span {
      text-align: center;
      display: block;
      margin: 15px 0;
    }

    img {
      filter: ${theme.colors.primary.mainFilter};
    }

    button {
      width: 100%;
      background: white;

      img {
        width: 15px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;

    .list {
      padding-left: 0px;
      width: 100%;

      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .item-box {
          padding: 10px 1.5rem;

          &:hover {
            background: rgba(0, 0, 0, 0.03);
          }
        }

        .desc {
          margin-bottom: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .footer-box {
    display: flex;
    padding: 15px 1.5rem;
    justify-content: flex-end;

    button {
      background: white;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
`;

const LoadingList = styled.div`
  padding: 0 1rem;
  svg {
    width: 100%;
    height: 70px;
  }
`;

const SkeletonList = () => {
  return (
    <LoadingList>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </LoadingList>
  );
};

const PermissionsStructure = () => {
  const {
    getPermissions,
    getConcessions,
    grantedForOthersList,
    rolePermissions,
    loadingList: llPerm,
    setOpenNewConcessionModal,
    list,
    setList,
    setIsEditPermission,
    setOpenNewPermissionModal,
    setCurrentPermission,
    transactionsEquals,
    setConfirmTransactionsEquals,
    confirmTransactionsEquals,
  } = usePermission();

  const { setOpenDesapproveModal } = useAuthority();
  const {
    getApprovers,
    getOperations,
    approvers,
    operations,
    loadingList: llAuth,

    page,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    totalAmount,
    selectedIds,
    setSelectedIds,
    setTotalAmount,
    setSelectedTransaction,
    setStatusColumnSelected,
    selectedTransaction,
    setCanApprove,
    canNotSign,
    insuficientBalance,
  } = useAuthority();
  const {
    formatNumberToCurrency,
    balanceFormatted,
    mainAccount,
    getBalance,
    balance,
  } = useAccount();

  const navigate = useNavigate();
  const menusBreadCrumb = [{ label: "Permissões", href: "app/payments" }];

  const [stepIndex, setStepIndex] = useState<number>(0);

  const menus: any = [
    {
      label: "Pendentes ",
      value: 1,
      onClick: () => {
        setStepIndex(0);
      },
    },
    {
      label: "Já aprovadas por mim",
      value: 4,
      onClick: () => {
        setStepIndex(3);
      },
    },
    {
      label: "Concluídas",
      value: 2,
      onClick: () => {
        setStepIndex(1);
      },
    },
    {
      label: "Reprovadas",
      value: 3,
      onClick: () => {
        setStepIndex(2);
      },
    },
  ];

  const renderData = [
    <PendingData />,
    <ApprovedData />,
    <RepprovedData />,
    <ApprovedByMe />,
  ];

  useEffect(() => {
    getPermissions();
    getPermissions(true);
    getConcessions(false);
    getApprovers();
    getOperations({ today: true, pending_operations: true });
    setStepIndex(0);

    getBalance();
    setSelectedIds([]);

    setStatusColumnSelected(false);

    setSelectedTransaction([]);
  }, [mainAccount]);
  useEffect(() => {
    setStepIndex(0);
  }, []);

  const onChangeIndex = useCallback(async () => {
    if (stepIndex === 0) {
      await getOperations({
        pending_operations: true,
        today: true,
      });
    }

    if (stepIndex === 1) {
      await getOperations({
        approved_operations: true,
        today: true,
      });
    }

    if (stepIndex === 2) {
      await getOperations({
        status: "refused",
        today: true,
      });
    }
    if (stepIndex === 3) {
      await getOperations({
        pending_operations: true,
        today: true,
      });
    }
  }, [stepIndex, operations]);

  useEffect(() => {
    if (stepIndex === 0) {
      const pendings = operations?.filter(
        (operation) =>
          operation.signed_by_me === false &&
          (operation.status === "pending" || operation.status === "error_retry")
      );

      setList(pendings);
    }

    if (stepIndex === 1) {
      setList(operations);
    }
    if (stepIndex === 2) {
      setList(operations);
    }
    if (stepIndex === 3) {
      const signed_by_me = operations?.filter(
        (operation) =>
          operation.signed_by_me === true && operation.status === "pending"
      );

      setList(signed_by_me);
    }
  }, [operations, stepIndex]);

  useEffect(() => {
    setPage(0);
    onChangeIndex();
  }, [stepIndex]);

  const { verifyPermission } = useAccount();

  const canCreatePermissions = verifyPermission("ba_can_create_permissions");
  const canSign = verifyPermission("ba_can_sign");

  const handleApprove = () => {
    if (transactionsEquals.length > 0) {
      setConfirmTransactionsEquals(true);
    } else {
      setCanApprove(true);
    }
  };

  const permissionApprovers = useMemo(() => {
    const grantedApprovers = grantedForOthersList.filter((item) => {
      const has_sign_perm = item.permissions_role.permissions.some(
        (perm: any) => perm.codename == "ba_can_sign"
      );

      const has_sign_weight = item.sign_weight > 0;

      return has_sign_perm && has_sign_weight;
    });

    return grantedApprovers;
  }, [grantedForOthersList]);

  return (
    <ParentContainer>
      <GeneralContainer>
        <div className="d-flex flex-column container-fluid w-100 h-100 ">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />
          <Spacer top={30} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <Card>
                  <div className="title-box">
                    <Title>Permissões</Title>
                  </div>

                  {llPerm ? (
                    <SkeletonList />
                  ) : rolePermissions?.length !== 0 ? (
                    <>
                      <div className="content">
                        <ul className="list">
                          {rolePermissions.slice(0, 3).map((_rp) => (
                            <li>
                              <div className="item-box">
                                <span className="title">{_rp.name}</span>
                                <p className="desc">
                                  {_rp.permissions.length} poderes associados
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {canCreatePermissions && (
                        <div className="footer-box">
                          <Button
                            label="Ver todos"
                            mode="border"
                            full
                            onClick={() => navigate("/app/permissions/manage")}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="empty-box">
                      <img src={ShieldIcon} />
                      <span>Nenhuma permissão cadastrada até o momento</span>
                      <Button
                        label="Criar Permissão"
                        mode="border"
                        icon={MoreIcon}
                        onClick={() => {
                          setIsEditPermission(false);
                          setCurrentPermission(undefined);
                          setOpenNewPermissionModal(true);
                        }}
                      />
                    </div>
                  )}
                </Card>

                <Card>
                  <div className="title-box">
                    <Title>Concessões (dadas por mim)</Title>
                  </div>

                  {llPerm ? (
                    <SkeletonList />
                  ) : grantedForOthersList.length !== 0 ? (
                    <>
                      <div className="content">
                        <ul className="list">
                          {grantedForOthersList.slice(0, 3).map((_con) => (
                            <li>
                              <div className="item-box">
                                <span className="title">
                                  {_con.partner_data.name}{" "}
                                </span>
                                <p className="desc">
                                  {formatCPFCNPJ(
                                    _con.partner_data.document_number
                                  )}{" "}
                                  - {_con.role_display}
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {canCreatePermissions && (
                        <div className="footer-box">
                          <Button
                            label="Ver todos"
                            mode="border"
                            onClick={() =>
                              navigate("/app/permissions/concessions")
                            }
                            full
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="empty-box">
                      <img src={UserIcon} />
                      <span>Nenhuma concessão cadastrada até o momento</span>
                      <Button
                        label="Criar Concessão"
                        mode="border"
                        icon={MoreIcon}
                        onClick={() => setOpenNewConcessionModal(true)}
                      />
                    </div>
                  )}
                </Card>

                <Card>
                  <div className="title-box">
                    <Title>Alçadas (Aprovadores)</Title>
                  </div>
                  {llAuth ? (
                    <SkeletonList />
                  ) : permissionApprovers.length !== 0 ? (
                    <>
                      <div className="content">
                        <ul className="list">
                          {permissionApprovers.map((_ap) => (
                            <li>
                              <div className="item-box">
                                <span className="title">
                                  {_ap.partner_data.name}
                                </span>
                                <p className="desc">
                                  {formatCPFCNPJ(
                                    _ap.partner_data.document_number
                                  )}{" "}
                                  - {_ap.sign_weight}%
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {canSign && (
                        <div className="footer-box">
                          <Button
                            label="Ver todos"
                            mode="border"
                            onClick={() => {
                              navigate("/app/permissions/concessions");
                            }}
                            full
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="empty-box">
                      <img src={DoubleUserIcon} />
                      <span>Nenhuma alçada cadastrada até o momento</span>
                    </div>
                  )}
                </Card>
              </div>

              <div className="col-md-9">
                <ContainerData className="p-4">
                  {canSign ? (
                    confirmTransactionsEquals ? (
                      <ConfirmTransactionsEquals />
                    ) : (
                      <div>
                        <div className="d-flex justify-content-between mb-2">
                          <div className="pe-2 flex-grow-1">
                            <TabMenu menus={menus} />
                          </div>
                          {/* 
                        {list.length > 0 ? (
                          <ButtonDropdown
                            mode="border"
                            label={`${rowsPerPage} por página`}
                            options={quantityPages}
                          />
                        ) : null} */}
                        </div>
                        <div style={{ height: "70px" }} className="">
                          {selectedIds?.length > 0 && stepIndex === 0 && (
                            <div>
                              <div className="d-flex justify-content-end w-100 pt-3 pb-3">
                                <Button
                                  label={
                                    selectedIds?.length > 1
                                      ? "Reprovar em lote"
                                      : "Reprovar"
                                  }
                                  onClick={() => {
                                    setOpenDesapproveModal(true);
                                  }}
                                  mode="border"
                                  danger
                                />
                                <Spacer left={10} />
                                <Button
                                  label={
                                    selectedIds?.length > 1
                                      ? "Aprovar em lote"
                                      : "Aprovar"
                                  }
                                  mode="main"
                                  onClick={() => handleApprove()}
                                  disabled={insuficientBalance || canNotSign}
                                />{" "}
                              </div>
                            </div>
                          )}
                        </div>{" "}
                        {renderData[stepIndex]}
                      </div>
                    )
                  ) : (
                    <p className="text-center">
                      {" "}
                      Você não tem permissão para assinar.
                    </p>
                  )}
                </ContainerData>
                <div
                  className={
                    list.length > 0 && !confirmTransactionsEquals
                      ? ""
                      : "d-none"
                  }
                >
                  <Paginator
                    list={list.length > 0 ? list : []}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    pageSetter={setPage}
                  />

                  <Spacer top={list.length > 0 ? 2 : 40} />
                </div>
              </div>
            </div>
          </div>

          <Spacer top={40} />

          <Copyright />

          <Spacer top={30} />
        </div>
      </GeneralContainer>

      {/* MODALS */}

      <NewConcessionModal />
      <NewPermissionsModal />
      <ShowOperationModal />
    </ParentContainer>
  );
};

export default PermissionsStructure;
