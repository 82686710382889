import { useState, useEffect } from "react";
import { formatCash } from "~/utils";
import ApiService from "~/services/api";
import Description from "~/components/Texts/Description";
import Skeleton from "~/components/SkeletonLine";
import useTax from "~/hooks/useTax";

type CustomerTaxProps = {
  slug: string;
  payValue?: number;
};

const CustomerTax = ({ slug, payValue }: CustomerTaxProps) => {
  const [loading, setLoading] = useState(false);
  const { tax, setTax } = useTax();

  const fetchTax = async () => {
    setLoading(true);

    if (slug) {
      try {
        const { data } = await ApiService.HttpPost({
          route: "customer-global-tax/",
          token: true,
          body: {
            slug,
            tax_is_enabled: true,
          },
        });

        if (data) {
          if (!data.global_banking_tax_enabled) {
            setTax(null);
            setLoading(false);
            return;
          }

          setTax(data);
        } else {
          setTax(null);
        }
      } catch (err: any) {}
    }

    setLoading(false);
  };

  const renderTax = () => {
    if (!tax) return null;

    return (
      <>
        <Description color="#929292">Taxa</Description>{" "}
        <Description>
          {" "}
          {!tax.customer_exemption
            ? tax.customer_tax_out !== 0
              ? formatCash(tax.customer_tax_out)
              : "0,00"
            : "0,00"}{" "}
        </Description>
      </>
    );
  };

  useEffect(() => {
    fetchTax();
  }, [slug]);

  return (
    <div>
      {loading ? (
        <>
          <Skeleton />
        </>
      ) : (
        renderTax()
      )}
    </div>
  );
};

export default CustomerTax;
