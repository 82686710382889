import { Button, Input, Spacer } from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import Description from "~/components/Texts/Description";
import * as Yup from "yup";
import {
  INVALID_FORMAT,
  REQUIRED_LABEL,
  cnpjCpfRegex,
} from "~/services/validators";
import ApiService from "~/services/api";
import { onlyNumbers } from "~/services/utils";
import useToast from "~/hooks/useToast";
import { motion } from "framer-motion";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import React, { useEffect, useState } from "react";

type FormValues = {
  document_number: string;
};

const initialValues: FormValues = {
  document_number: "",
};

const FormSchema = Yup.object().shape({
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(cnpjCpfRegex, INVALID_FORMAT),
});

const DataTEV = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    changeSideAnimation,
    setSelectedValue,
    setChooseNewTransfer,

    handleTedNextStep,
    setNewTransferTEVData,
    setIsTEV,
  } = useNewTransfer();

  const { values, handleChange, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    onSubmit: (values) => {},
  });

  const getBankAccounts = async (target = "") => {
    setLoading(true);

    try {
      const { data } = await ApiService.HttpGet({
        route: "bank-account/",
        token: true,
        params: {
          document_number: onlyNumbers(values.document_number),
          target,
        },
      });

      // const filteredAccountForTEV = data.results.filter(
      //   (account: any) => account.bank_data.slug === "fitbank"
      // );

      if (data.results.length > 0) {
        setNewTransferTEVData(data.results);
        handleTedNextStep();
      } else {
        showToast(
          "Nenhuma conta encontrada",
          "Não foi possível continuar a operação pois não foram encontradas contas cadastradas no documento informado",
          "error"
        );
      }
    } catch (e) {
      console.warn(e);
      showToast(
        "Ocorreu um erro durante a tentativa",
        "Tente novamente em alguns segundos",
        "error"
      );
    }
    setLoading(false);
  };

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (error) setError(false);
  }, [values.document_number]);

  const valid =
    values.document_number.length === 14 ||
    values.document_number.length === 18;

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.code === "Enter") {
      if (
        e.location === KeyboardEvent.DOM_KEY_LOCATION_STANDARD ||
        e.location === KeyboardEvent.DOM_KEY_LOCATION_STANDARD
      ) {
        e.preventDefault();

        if (valid) {
          e.preventDefault();
          getBankAccounts("TEV");
        } else {
          setError(true);
          e.preventDefault();
        }
      }
    }
  };

  return (
    <motion.div
      initial={{
        x: changeSideAnimation ? "-100%" : !changeSideAnimation && "100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: changeSideAnimation ? "100%" : !changeSideAnimation && "-100%",
      }}
      transition={{ duration: 0.3 }}
      className="vh-100"
    >
      {" "}
      <div className="p-4 ">
        <Description color="#666666">
          Para continuar é necessário informar os CPF ou CNPJ de quem receberá a
          transferência
        </Description>

        <Spacer top={20} />

        <form>
          <div
            className="row
"
          >
            <div className="col-12">
              <Input
                label="Digite o CPF/CNPJ"
                name="document_number"
                value={values.document_number}
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="123.456.789-01"
                mask={
                  values.document_number.length > 14
                    ? "99.999.999/9999-99"
                    : "999.999.999-9999999"
                }
                required={
                  values.document_number.length < 14 ||
                  (values.document_number.length > 14 &&
                    values.document_number.length < 18)
                }
                error={error && "Digite um CPF ou CNPJ válido"}
                onBlur={() => setError(false)}
                success={
                  values.document_number.length > 13 &&
                  values.document_number.length >= 18
                    ? true
                    : values.document_number.length > 13 &&
                      values.document_number.length < 15
                    ? true
                    : false
                }
                onKeyDown={(e) => handleEnter(e)}
              />{" "}
            </div>
          </div>
        </form>

        <Spacer top={40} />

        <div className="d-flex w-100 justify-content-between">
          <Button
            mode="phantom"
            label="Cancelar"
            onClick={() => {
              setSelectedValue("");
              setIsTEV(false);
              setChooseNewTransfer(null);
            }}
          />
          <div className="d-flex">
            {/* <Button
              mode="border"
              label="Selecionar contato"
              onClick={() => {
                setChangeSideAnimation(false);
                setSelectContact(true);
              }}
            />
            <Spacer right={20} /> */}
            <Button
              mode="main"
              label="Avançar"
              onClick={() => getBankAccounts("TEV")}
              loading={loading}
              disabled={
                loading ||
                values.document_number.length < 14 ||
                (values.document_number.length > 14 &&
                  values.document_number.length < 18)
              }
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default DataTEV;
