import styled from "styled-components";
import { AppHeaderProps } from "./AppHeader";
import theme from "~/themes/theme";
import label from "~/labels";

export const Header = styled.header<AppHeaderProps>`
  min-height: 97px;
  width: 98.5%;
  background-color: ${() => theme.colors.gray.light100};
  z-index: 2;
  padding: 10px 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .content-header {
    opacity: ${(props) => (props.isSearching ? 0 : 1)};
    transform: translateX(38px);
    width: 92%;
    display: flex;
    align-items: start;
    justify-content: center;
  }

  .container-menu {
    position: relative;
    top: -10px;
  }

  .user-img {
    height: 28px;
    border-radius: 50%;
    cursor: pointer;
  }
`;
export const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  position: relative;

  .profile-picture {
    width: 24px;
    height: 24px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }
`;

export const NotificationsImg = styled.img`
  height: 21px;
  cursor: pointer;
`;

export const ProductImg = styled.img`
  height: 25px;
  cursor: pointer;
`;
