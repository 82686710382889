import constate from "constate";
import { useState } from "react";

const [ContactsProvider, useContacts] = constate(() => {
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [contactsData, setContactsData] = useState<any[]>([]);

  const [contactIndex, setContactIndex] = useState<number>(0);

  const [keysData, setKeysData] = useState<any>([]);

  const [key, setKey] = useState<string>("");

  const typeLenght = (type: string) => {
    return contactsData.filter((contact) => contact.type === type).length;
  };
  const [partnerDetails, setPartnerDetails] = useState<any>([]);

  const [isDetails, setIsDetails] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);

  const [contactsFiltered, setContactsFiltered] = useState<any[]>([]);

  const [isLoadingTableContacts, setIsLoadingTableContacts] =
    useState<boolean>(false);

  const [openAddBankAccountModal, setOpenAddBankAccountModal] =
    useState<boolean>(false);

  const [typeOfKey, setTypeOfKey] = useState<string>("");

  const [openAddPixKeyModal, setOpenAddPixKeyModal] = useState<boolean>(false);

  const [section, setSection] = useState<string>("initial");

  const [documents, setDocuments] = useState<any[]>([]);

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState(0);

  return {
    partnerDetails,
    setPartnerDetails,
    openFilterModal,
    setOpenFilterModal,
    typeLenght,
    contactIndex,
    setContactIndex,
    isDetails,
    setIsDetails,
    contactsData,
    setContactsData,
    isLoadingPage,
    setIsLoadingPage,
    contactsFiltered,
    setContactsFiltered,
    isLoadingTableContacts,
    setIsLoadingTableContacts,
    openAddBankAccountModal,
    setOpenAddBankAccountModal,
    isEdit,
    setIsEdit,
    section,
    setSection,
    documents,
    setDocuments,
    setRowsPerPage,
    rowsPerPage,
    page,
    setPage,
    openAddPixKeyModal,
    setOpenAddPixKeyModal,
    setKey,
    key,
    keysData,
    setKeysData,
    typeOfKey,
    setTypeOfKey,
  };
});

export { ContactsProvider };

export default useContacts;
