import { Fragment, useCallback, useEffect } from "react";
import styled from "styled-components";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Input, CustomSelect, Spacer } from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { REQUIRED_LABEL } from "~/services/validators";
import { ufOptions } from "~/data/options";
import useCEP from "~/hooks/useCEP";
import useSignupPersonal from "~/hooks/Signup/useSignupPersonal";

const Title = styled.span`
  font-size: 32px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

type Values = {
  zip_code: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
};

const initialValues: Values = {
  zip_code: "",
  street: "",
  number: "",
  complement: "",
  district: "",
  city: "",
  state: "",
};

const FormSchema = Yup.object().shape({
  zip_code: Yup.string().required("Preencha o CEP"),
  street: Yup.string().required("Preencha a rua"),
  number: Yup.string().required(REQUIRED_LABEL),
  complement: Yup.string(),
  district: Yup.string().required("Preencha o bairro"),
  city: Yup.string().required("Preencha a cidade"),
});

const Address = () => {
  const {
    setStep,
    setValid,
    setPayloadSignupPersonal,
    setCanAdvanced,
    setNewPartner,
    setIsLoading,
    newPartner,
    getAddress,
    addressData,
  } = useSignupPersonal();
  const { handleCep, address, setAddress } = useCEP();

  const handleFormSubmit = async (
    values: Values,
    helpers: FormikHelpers<Values>
  ) => {};

  const {
    submitForm,
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  const verifyIfPayloadIsComplete = useCallback((objeto: object) => {
    return Object.values(objeto).every(
      (valor) => !(typeof valor === "string" && valor.trim() === "")
    );
  }, []);

  useEffect(() => {
    getAddress();
    setCanAdvanced(false);
    setIsLoading(false);
    localStorage.removeItem("new_user");
    setValid(isValid);
    setStep("address");

    window.addEventListener("signupPersonalSubmit", () => {
      submitForm();
    });

    return () => {
      window.removeEventListener("signupPersonalSubmit", submitForm);
    };
  }, []);

  useEffect(() => {
    setAddress({
      zip_code: addressData?.zipcode ?? "",
      street: addressData?.street ?? "",
      district: addressData?.district ?? "",
      city: addressData?.city ?? "",
      state: addressData?.state ?? "",
      error: "",
    });
    setFieldValue("number", addressData?.number ?? "");
    setFieldValue("complement", addressData?.complement ?? "");
  }, [addressData]);

  useEffect(() => {
    if (address) {
      setFieldValue("street", address.street);
      setFieldValue("district", address.district);
      setFieldValue("city", address.city);
      setFieldValue("state", address.state);
      setFieldValue("zip_code", address.zip_code);
    }
  }, [address]);

  useEffect(() => {
    const dataRequiredAddress = {
      zipcode: address.zip_code || values.zip_code,
      street: address.street || values.street,
      number: values.number,
      district: address.district || values.district,
      city: address.city || values.city,
      state: address.state || values.state,
    };

    if (verifyIfPayloadIsComplete(dataRequiredAddress)) {
      setCanAdvanced(true);
    } else {
      setCanAdvanced(false);
    }
  }, [values, address]);

  useEffect(() => {
    const newPartnerSaved: any = localStorage.getItem("new_partner");
    const newPartnerSavedConverted = JSON.parse(newPartnerSaved);

    const payload = {
      partner: newPartner?.id ?? newPartnerSavedConverted?.id,
      zipcode: values.zip_code,
      street: values.street,
      number: values.number,
      complement: values.complement,
      district: values.district,
      city: values.city,
      state: values.state,
      country: 1,
      type: "billing",
      identification: "",
    };

    setPayloadSignupPersonal(payload);
  }, [values, address]);

  return (
    <Fragment>
      <Spacer bottom={32} />
      <div>
        <Title>Informe o seu endereço</Title>
      </div>
      <Spacer bottom={30} />

      <div className="row">
        <div className="col-12 col-xxl-8 ">
          <form onSubmit={handleSubmit}>
            <div className="row d-flex align-items-end">
              <div className="col-xl-4 col-12 ">
                <Input
                  mask="99999-999"
                  type="text"
                  name="zip_code"
                  label="CEP"
                  placeholder="04313-110"
                  value={values.zip_code}
                  required={address.zip_code === ""}
                  error={address.error !== "" && address.error}
                  onKeyUp={(e: any) => {
                    handleCep(e);
                  }}
                  onChange={handleChange("zip_code")}
                  search
                  success={address.zip_code !== ""}
                />
              </div>

              <Spacer bottom={10} />

              <div className="col-xl-5 col-12">
                <Input
                  type="text"
                  name="street"
                  value={values.street}
                  label="Rua"
                  required={address.street === ""}
                  placeholder="Rua Borboletas Psicodélicas"
                  autoCapitalize="words"
                  onChange={handleChange("street")}
                  error={touched.street && errors.street}
                  success={address.street !== ""}
                />
              </div>

              <div className=" col-12 col-xl-3">
                <Input
                  type="text"
                  maxLength={10}
                  name="number"
                  value={values.number}
                  required={values.number === ""}
                  label="Número"
                  placeholder="269"
                  error={touched.number && errors.number}
                  success={values.number !== ""}
                  onChange={handleChange("number")}
                />
              </div>

              <div className=" col-12 col-xl-4">
                <Input
                  type="text"
                  name="complement"
                  value={values.complement}
                  label="Complemento"
                  placeholder="Casa 2"
                  error={touched.complement && errors.complement}
                  success={values.complement !== ""}
                  onChange={handleChange("complement")}
                />
              </div>

              <div className="col-xl-5 col-12">
                <Input
                  type="text"
                  name="district"
                  label="Bairro"
                  required={address.district === ""}
                  placeholder="Vila Guarani"
                  onChange={handleChange}
                  autoComplete="off"
                  autoCapitalize="off"
                  success={address.district !== ""}
                  error={touched.district && errors.district}
                  value={values.district}
                />
              </div>

              <div className="col-12 col-xl-4">
                <Input
                  type="text"
                  name="city"
                  value={values.city}
                  success={address.city !== ""}
                  required={address.city === ""}
                  label="Cidade"
                  placeholder="São Paulo"
                  onChange={handleChange}
                  autoComplete="city"
                  error={values.city === "" && values.city}
                />
              </div>

              <div className=" col-12 col-xl-3 ">
                <CustomSelect
                  name="state"
                  label="Estado"
                  placeholder="Selecione"
                  isSearchable={false}
                  options={ufOptions}
                  required={values.state === ""}
                  value={ufOptions.find((uf) => uf.value === values.state)}
                  onChange={(value: any) => {
                    setFieldValue("state", value);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Address;
