import constate from "constate";
import { useState } from "react";
import {
  ActivateCardSteps,
  ActivatingCardStatus,
  BlockCardSteps,
  BlockingCardStatus,
  Card,
  CardDetails,
  CardDetailsSteps,
  CreateCardStep,
  CreatingCardStatus,
  GetCardDetailsStatus,
  PutCardContactlessStatus,
  Transaction,
  UnblockCardSteps,
  UnblockingCardStatus,
} from "./interfaces";
import {
  activateBankCardGateway,
  blockBankCardGateway,
  createBankCardGateway,
  getCardDetailsGateway,
  getCardsAccountGateway,
  getCardsGateway,
  getRecentCardTransactionsGateway,
  postBlockBankCardResponse,
  putCardContactlessEnabledGateway,
  unblockBankCardGateway,
} from "./cardsGateways";
import { orderCardsFromDTO, recentTransactionsFromDTO } from "./DTOConverter";
import { ICreateCardInfos, initalCreateCardInfos } from "./initalValues";

const [CardsProvider, useCards] = constate(() => {
  const [subPage, setSubPage] = useState<{ name?: string; route?: string }>({});
  const [cardsData, setCardsData] = useState<Card[]>([]);
  const [recentTransactions, setRecentTransactions] = useState<Transaction[]>(
    []
  );
  const [isLoadingCards, setIsLoadingCards] = useState<boolean>(false);
  const [isLoadingRecentTransactions, setIsLoadingRecentTransactions] =
    useState<boolean>(false);
  const [cardsDataError, setCardsDataError] = useState<string | undefined>(
    undefined
  );
  const [cardsRecentTransactionsError, setCardsRecentTransactionsError] =
    useState<string | undefined>(undefined);
  const [selectedCard, setSelectedCard] = useState<Card | undefined>(undefined);
  const [cardDetails, setCardDetails] = useState<CardDetails | null>(null);
  const [isCardDetailsOpen, setIsCardDetailsOpen] = useState<boolean>(false);
  const [pendingCardDetails, setPendingCardDetails] = useState<any>(undefined);

  const [createCardInfos, setCreateCardInfos] = useState<ICreateCardInfos>(
    initalCreateCardInfos
  );

  const [createCardStep, setCreateCardStep] =
    useState<CreateCardStep>("INFORMATION");
  const [activateCardStep, setActivateCardStep] =
    useState<ActivateCardSteps>("CHECKING");
  const [blockCardStep, setBlockCardStep] =
    useState<BlockCardSteps>("CHECKING");
  const [unblockCardStep, setUnblockCardStep] =
    useState<UnblockCardSteps>("CHECKING");
  const [cardDetailsStep, setCardDetailsStep] =
    useState<CardDetailsSteps>("CONFIRMATION");

  const [creatingCardStatus, setCreatingCardStatus] =
    useState<CreatingCardStatus>(null);
  const [activatingCardStatus, setActivatingCardStatus] =
    useState<ActivatingCardStatus>(null);
  const [blockingCardStatus, setBlockingCardStatus] =
    useState<BlockingCardStatus>(null);
  const [unblockingCardStatus, setUnblockingCardStatus] =
    useState<UnblockingCardStatus>(null);
  const [getCardDetailsStatus, setGetCardDetailsStatus] =
    useState<GetCardDetailsStatus>(null);
  const [putCardContactlessStatus, setPutCardContactlessStatus] =
    useState<PutCardContactlessStatus>(null);

  const [accountUuid, setAccountUuid] = useState<string | undefined>(undefined);
  const [accountFetchingStatus, setAccountFetchingStatus] = useState<
    "NOT_FETCHED" | "LOADING" | "FETCHED"
  >("NOT_FETCHED");

  const getCardAccount = async (props: {
    account_id: string;
    partner_id: string;
    name: string;
    document_number: string;
    document_type: string;
    birth_date: string;
    email: string;
    phone: {
      phone_number: string;
      area_code: string;
      country_code: string;
      phone_type: string;
      main: true;
    };
    address: {
      address_type: string;
      zip_code: string;
      address: string;
      number: string;
      complementary_address: string;
      district: string;
      city: string;
      state: string;
      country: string;
    };
  }) => {
    setAccountFetchingStatus("LOADING");
    const data = await getCardsAccountGateway({
      ...props,
    });
    if ("accountUuid" in data) {
      setAccountUuid(data.accountUuid);
    } else {
      setAccountUuid(undefined);
    }
    setAccountFetchingStatus("FETCHED");
  };

  const getCardsAsync = async () => {
    setCardsData([]);
    setIsLoadingCards(true);
    setCardsDataError(undefined);

    const data = await getCardsGateway({
      accountUuid,
    });

    if (Array.isArray(data)) {
      setCardsData(orderCardsFromDTO(data));
    } else {
      setCardsDataError(
        "Erro ao buscar seus dados de cartão, tente novamente mais tarde."
      );
    }

    setIsLoadingCards(false);
  };

  const getCardDetailsAsync = async (password: string, card_id: string) => {
    setGetCardDetailsStatus("LOADING");

    const data = await getCardDetailsGateway({
      accountUuid,
      card_id,
      password,
    });
    if (data && "card_number" in data) {
      // @ts-ignore
      setCardDetails(data);
      setGetCardDetailsStatus("SUCCESS");
      setCardDetailsStep("SUCCESS");
    } else {
      //todo verificar código de erro para ver se foi erro de autenticação
      setGetCardDetailsStatus("SERVER_ERROR");
    }
  };

  const putCardContactlessAsync = async (
    contactless_enabled: boolean,
    card_id: string
  ) => {
    setPutCardContactlessStatus("LOADING");
    const data = await putCardContactlessEnabledGateway({
      accountUuid,
      card_id,
      contactless_enabled,
    });

    if (data === true) {
      // @ts-ignore
      setPutCardContactlessStatus("SUCCESS");
    } else {
      //todo verificar código de erro para ver se foi erro de autenticação
      setPutCardContactlessStatus("SERVER_ERROR");
    }
  };

  const getRecentCardTransactionsAsync = async () => {
    setRecentTransactions([]);
    setIsLoadingRecentTransactions(true);
    setCardsRecentTransactionsError(undefined);

    const data = await getRecentCardTransactionsGateway({
      accountUuid,
    });

    if (Array.isArray(data)) {
      setRecentTransactions(recentTransactionsFromDTO(data));
    } else {
      setCardsRecentTransactionsError(
        "Erro ao buscar suas transações recentes, tente novamente mais tarde."
      );
    }

    setIsLoadingRecentTransactions(false);
  };

  const postActivateBankCard = async (cvv: string) => {
    if (selectedCard) {
      setActivatingCardStatus("LOADING");
      const data = await activateBankCardGateway({
        cvv,
        selectedCard,
        accountUuid,
      });

      if (data == "SUCCESS") {
        setActivatingCardStatus(data);
      } else {
        setActivatingCardStatus("SERVER_ERROR");
      }
    }
  };

  const postBlockBankCard = async (password: string) => {
    if (selectedCard) {
      setBlockingCardStatus("LOADING");
      await blockBankCardGateway({
        selectedCard,
        password,
        accountUuid,
      }).then((data) => {
        setBlockingCardStatus(data);
      });
    }
  };

  const postUnblockBankCard = async (password: string) => {
    if (selectedCard) {
      setUnblockingCardStatus("LOADING");
      await unblockBankCardGateway({
        selectedCard,
        password,
        accountUuid,
      }).then((data) => {
        setUnblockingCardStatus(data);
      });
    }
  };

  const postCreateBankCard = async () => {
    setCreatingCardStatus("LOADING");
    const data = await createBankCardGateway({
      card_type: createCardInfos.card_type,
      name: createCardInfos.name,
      accountUuid,
      delivery_address: createCardInfos.other_address?.address
        ? createCardInfos.other_address
        : undefined,
    });

    if (data == "SUCCESS") {
      setCreatingCardStatus(data);
    } else {
      setCreatingCardStatus("SERVER_ERROR");
    }
  };

  const setCreateCardValues = (info: string, value: any) => {
    const newValues = { ...createCardInfos, [info]: value };
    setCreateCardInfos(newValues);
  };

  const resetCreateCardValues = () => {
    setCreateCardInfos(initalCreateCardInfos);
  };

  return {
    subPage,
    setSubPage,
    cardsData,
    cardsDataError,
    isLoadingCards,
    setIsLoadingCards,
    recentTransactions,
    cardsRecentTransactionsError,
    isLoadingRecentTransactions,
    getCardsAsync,
    getRecentCardTransactionsAsync,
    selectedCard,
    setSelectedCard,

    getCardAccount,
    accountUuid,
    accountFetchingStatus,

    createCardInfos,
    setCreateCardValues,
    createCardStep,
    setCreateCardStep,
    creatingCardStatus,
    setCreatingCardStatus,
    resetCreateCardValues,
    postCreateBankCard,

    activateCardStep,
    setActivateCardStep,
    activatingCardStatus,
    setActivatingCardStatus,
    postActivateBankCard,

    blockCardStep,
    setBlockCardStep,
    postBlockBankCard,
    blockingCardStatus,
    setBlockingCardStatus,

    postUnblockBankCard,
    unblockCardStep,
    setUnblockCardStep,
    unblockingCardStatus,
    setUnblockingCardStatus,

    cardDetailsStep,
    setCardDetailsStep,
    cardDetails,
    setCardDetails,
    getCardDetailsAsync,
    getCardDetailsStatus,
    setGetCardDetailsStatus,

    isCardDetailsOpen,
    setIsCardDetailsOpen,

    putCardContactlessAsync,
    putCardContactlessStatus,
    setPutCardContactlessStatus,
  };
});

export { CardsProvider };

export default useCards;
