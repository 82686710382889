import { Spacer } from "@zilliondigital/mirage-ui";
import Loader from "~/components/Loader";
import styled from "styled-components";

const LoaderHeaderContainer = styled.div`
  .container-2 {
    margin-top: 50px;
    margin: 0 25px;
  }
`;

const ChartPannelLoader = () => {
  return (
    <LoaderHeaderContainer>
      <div className="container-2 ">
        <div className="mt-4">
          <Loader
            x=""
            y=""
            rx="4"
            ry="4"
            widthContainer="320"
            isHeight="45"
            isWidth="320"
            box="0 0 320 45"
            heightContainer="45"
          />
        </div>
        <Spacer bottom={20} />{" "}
        <Loader
          x=""
          y=""
          rx="4"
          ry="4"
          widthContainer="100%"
          isHeight="400"
          isWidth="100%"
          box="0 0 100% 400"
          heightContainer="400"
        />
      </div>
    </LoaderHeaderContainer>
  );
};

export default ChartPannelLoader;
