import constate from "constate";
import { useCallback, useState } from "react";
import useSignupUser from "./useSignupUser";
import { onlyNumbers } from "~/utils";
import ApiService from "~/services/api";

type SignupPersonalSteps =
  | "general"
  | "address"
  | "representatives"
  | "attachments"
  | "security"
  | "finish"
  | string;

export type PersonalGeneralValues = {
  document_type?: string;
  document_number: string;
  birth_date: string;
  mobile_phone: string;
  name: string;
  email: string;
  identity_number: string;
  mothers_name: string;
  gender: string;
  marital_status: string;
  nationality: string;
  birth_state: string;
};

export type PersonalAddressValues = {
  zipcode: string;
  city: string;
  state: string;
  district: string;
  street: string;
  number: string;
  complement: string;
};

const initialPersonalAddressValues: PersonalAddressValues = {
  zipcode: "",
  state: "",
  city: "",
  district: "",
  street: "",
  number: "",
  complement: "",
};

type SignupPersonalValues = PersonalGeneralValues & PersonalAddressValues;

export const steps: SignupPersonalSteps[] = [
  "general",
  "address",

  "attachments",
  "security",
];

type AccountSteps =
  | "complementary"
  | "address"
  | "representatives"
  | "attachments"
  | "review";

const [SignupPersonalProvider, useSignupPersonal] = constate(() => {
  const [step, setStep] = useState<SignupPersonalSteps>("general");
  const [valid, setValid] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [stepChildIndex, setStepChildIndex] = useState(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isNotOld, setIsNotOld] = useState<boolean>(false);

  const onSubmitForm = () => {
    const event = new CustomEvent("signupPersonalSubmit", {
      detail: { step },
    });

    window.dispatchEvent(event);
  };

  const [payloadSignupPersonal, setPayloadSignupPersonal] = useState<any>({});

  const [canAdvanced, setCanAdvanced] = useState<boolean>(false);
  const [newPartner, setNewPartner] = useState<any>({});
  const [addressData, setAddressData] = useState<any>({});
  const [proofResidencePayload, setProofResidencePayload] = useState<any>({});
  const [rGOrCNHFrontPayload, setRGOrCNHFrontPayload] = useState<any>({});
  const [rGOrCNHBackPayload, setRGOrCNHBackPayload] = useState<any>({});
  const [cpfPayload, setCPFPayload] = useState<any>({});
  const { signUpUserPayload } = useSignupUser();

  const initialPersonalGeneralValues: PersonalGeneralValues = {
    document_number: onlyNumbers(payloadSignupPersonal.document_number) ?? "",
    birth_date: payloadSignupPersonal.date ?? "",
    mobile_phone: payloadSignupPersonal.mobile_phone ?? "",
    name: payloadSignupPersonal.name ?? "",
    email: payloadSignupPersonal.email ?? "",
    identity_number: payloadSignupPersonal.identity_number ?? "",
    mothers_name: payloadSignupPersonal.mothers_name ?? "",
    gender: "",
    marital_status: "",
    nationality: payloadSignupPersonal.nationality ?? "Brasileira",
    birth_state: "",
  };

  const initialSignupPersonalValues: SignupPersonalValues = {
    ...initialPersonalGeneralValues,
    ...initialPersonalAddressValues,
  };

  const [personalGeneralValues, setPersonalGeneralValues] =
    useState<PersonalGeneralValues>(initialPersonalGeneralValues);
  const [personalAddressValues, setPersonalAddressValues] =
    useState<PersonalAddressValues>(initialPersonalAddressValues);

  const [signupPersonalValues, setSignupPersonalValues] =
    useState<SignupPersonalValues>(initialSignupPersonalValues);

  const [partnerData, setPartnerData] = useState<any>({});

  const updatePartnerStep = async (step: AccountSteps, token?: string) => {
    try {
      await ApiService.HttpPost({
        route: `partner-step/`,
        customHeaders: { Authorization: `Token ${token}` },
        body: { step },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getPartner = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpPost({
        route: `partner-default/`,
        body: {},
        token: true,
      });

      setPartnerData(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getAddress = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: `address/`,
        token: true,
      });

      if (data.results.length > 0) {
        const lastIndex = data.results.length - 1;
        setAddressData(data.results[lastIndex]);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return {
    step,
    setStep,
    onSubmitForm,
    valid,
    setValid,
    stepIndex,
    setStepIndex,
    stepChildIndex,
    setStepChildIndex,
    personalGeneralValues,
    setPersonalGeneralValues,
    personalAddressValues,
    setPersonalAddressValues,
    signupPersonalValues,
    setSignupPersonalValues,
    payloadSignupPersonal,
    setPayloadSignupPersonal,
    canAdvanced,
    setCanAdvanced,
    newPartner,
    setNewPartner,
    proofResidencePayload,
    setProofResidencePayload,
    rGOrCNHBackPayload,
    setRGOrCNHBackPayload,
    rGOrCNHFrontPayload,
    setRGOrCNHFrontPayload,
    cpfPayload,
    setCPFPayload,
    isNotOld,
    setIsNotOld,
    isLoading,
    setIsLoading,
    getPartner,
    getAddress,
    updatePartnerStep,
    partnerData,
    setPartnerData,
    setAddressData,
    addressData,
  };
});

export { SignupPersonalProvider as SignupPersonalProvider };

export default useSignupPersonal;
