import styled, { keyframes } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Spacer,
  Breadcrumb,
  Button,
  Table,
  TabMenu,
  ButtonDropdown,
  InteractiveModal,
  Input,
  NotControlledToolTip,
} from "@zilliondigital/mirage-ui";
import DeleteIcon from "~/assets/icons/delete.png";

import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFirstLetters } from "~/services/utils";
import useBackoffice from "~/hooks/useBackoffice";
import EyeIcon from "~/assets/icons/contacts/eye-Size=MD.svg";
import ViewSVGIcon from "~/assets/icons/new/view.svg";
import DownloadSVGIcon from "~/assets/icons/new/download.svg";
import ThumbsUPSVGIcon from "~/assets/icons/new/thumbs-up.svg";
import ThumbsDownSVGIcon from "~/assets/icons/new/thumbs-down.svg";
import theme from "~/themes/theme";
import label from "~/labels";
import { useParams } from "react-router";
import ApiService from "~/services/api";
import confirmOperation from "~/components/AsyncConfirmModal";
import useToast from "~/hooks/useToast";
import { formatCPFCNPJ, onlyNumbers } from "~/utils";
import RepresentativesModalDetails from "./RepresentativesModalDetails";
import Copyright from "~/components/Copyright";
import DraggableModal from "~/components/DraggeableModal";
import {
  getFitbankDocumentName,
  getFitbankDocumentStatusName,
} from "~/data/documents";
import { TitleModal } from "~/pages/Authentication/Auth.styles";
import documentNumberValidator from "~/utils/validateDocNumber";
import { useFormik } from "formik";
import { INVALID_FORMAT, REQUIRED_LABEL } from "~/services/validators";
import * as Yup from "yup";
import usePermission from "~/hooks/usePermission";
import { ImgDelete } from "../../Permissions/NewConcessionModal";
import useAuth from "~/hooks/useAuth";

type FormValues = {
  document_number: string;
};

const initialValues: FormValues = {
  document_number: "",
};

const validationSchema = Yup.object().shape({
  document_number: Yup.string().required(REQUIRED_LABEL),
});

const GeneralContainerData = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  height: 100%;
  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .name {
    font-size: 27px;
    font-weight: 400;
  }

  .profile-picture {
    width: 64px;
    height: 64px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }

  .pix-mykeys-image {
    height: 24px;
    filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(0%)
      hue-rotate(135deg) brightness(103%) contrast(105%);
    cursor: pointer;
  }

  .container-btn-more-details {
    position: relative;
    top: 8px;
    left: 75%;
  }

  .copy-icon {
    position: relative;
    top: 8px;
    left: 70%;
  }

  .container-return-button {
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
    /* Cor que deseja quando a imagem estiver completamente expandida */
  }
`;

const ImgEye = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

const formatCpfCnpj = (string: string) => {
  if (string !== undefined) {
    const onlyNumbers = string.replace(/\D/g, "");

    if (onlyNumbers.length === 11) {
      return onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (onlyNumbers.length === 14) {
      return onlyNumbers.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
  }

  return string;
};

const formatDate = (date: string) => {
  if (date && date !== "") {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }
};

const formatDateTime = (date: string) => {
  const splittedDate = date.split("T");
  const formattedDate = formatDate(splittedDate[0]);
  return formattedDate;
};

const formatPhone = (phone: string) => {
  if (phone && phone !== "") {
    const onlyNumbers = phone.replace(/\D/g, "");

    if (onlyNumbers.length === 11) {
      return onlyNumbers.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (onlyNumbers.length === 10) {
      return onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
  }

  return phone;
};

const fitbankStatuses: Record<number, string> = {
  0: "Criado (Aguardando análise)",
  1: "Limitado (Análise)",
  2: "Verificado (Criado)",
  3: "Bloqueado",
  4: "Encerrado",
};

const columnsBankAccounts: any = {
  bank_accounts: [
    {
      label: "Banco",
      key: "Banco",
      renderData: (data: any) => `${data.bank.code} - ${data.bank.name}`,
    },
    {
      label: "Tipo de conta",
      key: "Tipo de conta",
      renderData: (data: any) => data.type_display,
    },
    {
      label: "Agência",
      key: "agency",
      renderData: (data: any) => data.agency,
    },
    {
      label: "Conta",
      key: "account",
      renderData: (data: any) =>
        `${data.account_number}-${data.account_number_digit}`,
    },
  ],
  fitbank_accounts: [
    {
      label: "Chave",
      key: "AccountKey",
      renderData: (data: any) => data.AccountKey,
    },
    {
      label: "Status",
      key: "AccountConditionType",
      renderData: (data: any) => fitbankStatuses[data.AccountConditionType],
    },
    {
      label: "Agência",
      key: "BankBranch",
      renderData: (data: any) =>
        data.SpbAccount ? data.SpbAccount.BankBranch : "-",
    },
    {
      label: "Conta",
      key: "BankAccount",
      renderData: (data: any) =>
        data.SpbAccount
          ? `${data.SpbAccount.BankAccount}-${data.SpbAccount.BankAccountDigit}`
          : "-",
    },
    {
      label: "Data de Criação",
      key: "CreationDate",
      renderData: (data: any) => data.CreationDate,
    },
    {
      label: "Data de Atualização",
      key: "UpdateDate",
      renderData: (data: any) => data.UpdateDate,
    },
  ],
};

type ShowingAccountsEnum = "bank_accounts" | "fitbank_accounts";

const Details = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const { showToast } = useToast();

  const [partnerData, setPartnerData] = useState<any>();

  const [sending, setSending] = useState<boolean>();

  const [showingAccounts, setShowingAccounts] =
    useState<ShowingAccountsEnum>("fitbank_accounts");

  const [openGetTrusteeModal, setOpenGetTrusteeModal] =
    useState<boolean>(false);

  const [showingFile, setShowingFile] = useState<string | undefined>(undefined);

  const {
    detailsData,
    setOpenRepresentativeDetailsModal,
    setDetailsRepresentatives,
  } = useBackoffice();

  const menusBreadCrumb = [
    {
      label: "Administração",
      onClick: () => {
        navigate("/app/backoffice/");
      },
    },
    { label: "Detalhes", href: "" },
  ];

  const {
    values,
    handleBlur,
    submitForm,
    handleChange,
    errors,
    touched,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: () => undefined,
  });

  const columns: any[] = [
    {
      label: "Parceiro",
      key: "name",
      renderData: (data: any) => (
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <span>{data.name}</span>
            <Description color={theme.colors.base.dark100}>
              {formatCPFCNPJ(values.document_number)}
            </Description>
          </div>

          <div id={`delete-${data.id}`}>
            <ImgDelete
              style={{
                height: "20px",
              }}
              src={DeleteIcon}
              onClick={() => {
                {
                  setPartner(null);
                }
              }}
            />
            <NotControlledToolTip placement="top" target={`delete-${data.id}`}>
              {"Remover"}
            </NotControlledToolTip>
          </div>
        </div>
      ),
    },
  ];

  const getPartnerAllData = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: `backoffice/new/pendings/${params.id}/`,
        token: true,
      });

      const filteredBankAccounts = data.data.bank_accounts.filter(
        (bk: any) => !bk.dummy_account
      );

      const newData = {
        ...data.data,
        bank_accounts: filteredBankAccounts,
      };

      setPartnerData(newData);
    } catch (e) {
      console.warn(e);
      // setPendingsLoading(false);
    }
  };

  useEffect(() => {
    if (params && params.id) {
      getPartnerAllData();
    }
  }, [params]);

  const approveRegister = async (type: string, trustee?: number) => {
    setSending(true);
    setOpenGetTrusteeModal(false);
    setPartner(null);

    const body =
      type === "escrow"
        ? {
            partner: partnerData.id,
            type: type,
            trustee,
          }
        : { partner: partnerData.id, type: type };

    try {
      await confirmOperation({
        title: "Atenção",
        description:
          "Você está prestes a enviar este cadastro para validação externa, certifique-se de que tenha validado todos os dados e documentos desta conta!",
        danger: true,
        confirmButtonLabel: "Sim, enviar",
        cancelButtonLabel: "Não, cancelar",
      });

      const { data } = await ApiService.HttpPost({
        route: "backoffice/send/partner/",
        token: true,
        body,
      });

      showToast("Sucesso", data.message, "success");

      getPartnerAllData();
    } catch (e) {}

    setSending(false);
  };

  const disapproverRegister = async (returned: boolean = false) => {
    setSending(true);
    try {
      const options = [
        { label: "Dados complementares", value: "complementary" },
        { label: "Endereço", value: "address" },
        { label: "Anexos", value: "attachments" },
      ];

      if (partnerData.document_type === "cnpj") {
        options.push({
          label: "Representante",
          value: "representatives",
        });
      }

      const operationParams: any = {
        title: "Atenção",
        description:
          "Você está prestes a retornar esta conta, certifique-se de que tenha validado todos os dados e documentos desta conta!",
        danger: true,
        hasReason: true,

        confirmButtonLabel: "Sim, retornar",
        cancelButtonLabel: "Não, cancelar",
      };

      if (returned) {
        operationParams["hasOption"] = options;
      }

      const { message, option } = await confirmOperation(operationParams);

      if (message == "" || option == "") {
        showToast(
          "Erro",
          "Por favor, insira um motivo e um passo para retornar o cadastro",
          "error"
        );
      }

      const { data } = await ApiService.HttpPost({
        route: "backoffice/disapprove/partner/",
        token: true,
        body: {
          partner: partnerData.id,
          reason: message,
          returned: returned,
          step: option,
        },
      });

      showToast("Sucesso", data.message, "success");

      getPartnerAllData();
    } catch (e) {}

    setSending(false);
  };

  const approveDocument = async (document: any, approve: boolean) => {
    setSending(true);
    try {
      const approveText = !approve ? "reprovar" : "aprovar";

      await confirmOperation({
        title: "Atenção",
        danger: !approve,
        description: `Você está prestes a ${approveText} este documento, certifique-se de que esta ação esteja correta`,
        confirmButtonLabel: `Sim, ${approveText}`,
        cancelButtonLabel: "Não, cancelar",
      });

      const { data } = await ApiService.HttpPost({
        route: "backoffice/approve/document/",
        token: true,
        body: {
          document: document.id,
          approve: approve,
        },
      });

      showToast("Sucesso", data.message, "success");

      getPartnerAllData();
    } catch (e) {
      showToast("Erro", "Não foi possível aprovar este documento", "error");
    }

    setSending(false);
  };

  const approveAllDocuments = async (approve: boolean = true) => {
    setSending(true);
    try {
      await confirmOperation({
        title: "Atenção",
        danger: true,
        description:
          "Você está prestes a aprovar todos os documentos, certifique-se de que os mesmos estejam corretos!",
        confirmButtonLabel: "Sim, aprovar",
        cancelButtonLabel: "Não, cancelar",
      });

      const { data } = await ApiService.HttpPost({
        route: "backoffice/approve/documents/",
        token: true,
        body: {
          partner: partnerData.id,
          approve: approve,
        },
      });

      showToast("Sucesso", data.message, "success");

      getPartnerAllData();
    } catch (e) {
      showToast("Erro", "Não foi possível aprovar este documento", "error");
    }

    setSending(false);
  };

  const allDocuments = useMemo(() => {
    if (!partnerData) {
      return [];
    }

    const documents = [];

    /* Merge with partner docuemtns */
    if (partnerData?.documents) {
      const mappedDocuments = partnerData?.documents.map((document: any) => ({
        ...document,
        partner_name: partnerData?.name,
      }));

      documents.push(...mappedDocuments);
    }

    /* Reduce partners documents and merge */
    partnerData.partners?.forEach((partner: any) => {
      if (partner.documents) {
        const mappedDocuments = partner?.documents.map((document: any) => ({
          ...document,
          partner_name: partner?.name,
        }));
        documents.push(...mappedDocuments);
      }
    });

    return documents;
  }, [partnerData]);

  const allLogs: any = useMemo(() => {
    if (!partnerData) {
      return [];
    }

    const logs = [];

    /* Merge with partner docuemtns */
    if (partnerData?.log) {
      logs.push(...partnerData?.log);
    }

    /* Reduce partners logs and merge */
    partnerData.partners?.forEach((partner: any) => {
      if (partner.log) {
        logs.push(...partner.log);
      }
    });

    /* Sort by id */
    const sortedLogs = logs.sort((a: any, b: any) => {
      return b.id - a.id;
    });

    const MethodDict: any = {
      UpdateAccount: "Atualização de conta",
      UpdatePersonCondition: "Atualização de Condição",
      UpdatePersonDocument: "Atualização de Documento",
    };

    const mappedLogs = sortedLogs.map((log: any) => {
      let content = "";

      switch (log.content.Method) {
        case "UpdateAccount":
          content = `Status da conta ${log.content.Name} (${formatCPFCNPJ(
            log.content.TaxNumber
          )}) alterado para "${fitbankStatuses[log.content.AccountStatus]}"`;
          break;
        case "UpdatePersonCondition":
          content = `Condição da pessoa ${formatCPFCNPJ(
            log.content.TaxNumber
          )} alterado para "${log.content.Message}"`;
          break;
        case "UpdatePersonDocument":
          content = `Documento ${getFitbankDocumentName(
            log.content.Document.DocumentType
          )} da pessoa ${formatCPFCNPJ(
            log.content.Document.TaxNumber
          )} alterado para "${getFitbankDocumentStatusName(
            log.content.Document.DocumentStatus
          )}"`;
      }

      return {
        ...log,
        document_number:
          log.content.Method == "UpdatePersonDocument"
            ? log.content.Document.TaxNumber
            : log.content.TaxNumber,
        method: MethodDict[log.content.Method],
        content,
      };
    });

    return mappedLogs;
  }, [partnerData]);

  const allDocumentsApproved = useMemo(() => {
    const allApproved = allDocuments.every(
      (doc) => doc.status == "validated_internally"
    );

    return allApproved;
  }, [allDocuments]);

  const hasDocumentToApprove = useMemo(() => {
    const hasDocument = allDocuments.some(
      (doc) => doc.status == "internal_validation"
    );

    return hasDocument;
  }, [allDocuments]);

  const fullAddressQuery = useMemo(() => {
    if (!partnerData) {
      return "";
    }

    const address = partnerData?.address?.[0];

    if (!address) {
      return "";
    }

    const { street, number, district, city, state, zipcode } = address;

    return `${street}, ${number} - ${district}, ${city} - ${state.code}, ${zipcode}`;
  }, [partnerData]);

  const columnsRepresentative: any[] = [
    {
      label: "Nome Completo",
      key: "name",
      renderData: (data: any) => (
        <div className="d-flex flex-column">
          <span>{data.name}</span>
          <Description color={theme.colors.base.dark100}>
            {formatCPFCNPJ(data.document_number)}
          </Description>
        </div>
      ),
    },
    {
      label: "E-mail",
      key: "email",
      renderData: (data: any) => data.email,
    },
    {
      label: "Telefone",
      key: "mobile_phone",
      renderData: (data: any) => data.mobile_phone,
    },
    {
      label: "Signatário",
      key: "signatory",
      renderData: (data: any) => <span>{data.signatory ? "Sim" : "Não"}</span>,
    },
    {
      label: "",
      key: "delete",
      renderData: (data: any) => (
        <ImgEye
          src={EyeIcon}
          onClick={() => {
            setDetailsRepresentatives(data);
            setOpenRepresentativeDetailsModal(true);
          }}
        />
      ),
    },
  ];

  const columnsDocuments = [
    {
      label: "ID",
      key: "id",

      renderData: (data: any) => {
        return (
          <div>
            <Description>{data.id}</Description>
          </div>
        );
      },
    },

    {
      label: "Vínculo",
      key: "partner_name",
      renderData: (data: any) => (
        <div>
          <Description>{data.partner_name}</Description>
        </div>
      ),
    },
    {
      label: "Nome",
      key: "type_display",
      renderData: (data: any) => (
        <div>
          <Description>{data.type_display}</Description>
        </div>
      ),
    },
    {
      label: "Status",
      key: "status_display",
      renderData: (data: any) => (
        <div>
          <Description>{data.status_display}</Description>
        </div>
      ),
    },
    {
      label: "Ações",
      key: "",
      renderData: (data: any) => (
        <div
          className="d-flex justify-content-start align-items-center"
          id={`eye-${data.id}`}
        >
          <a href={data.file} download target="_blank">
            <ImgEye src={DownloadSVGIcon} />
          </a>

          <Spacer left={10} />
          <ImgEye src={ViewSVGIcon} onClick={() => setShowingFile(data.file)} />

          {data.status == "internal_validation" && (
            <Fragment>
              <Spacer left={10} />
              <ImgEye
                src={ThumbsUPSVGIcon}
                onClick={() => approveDocument(data, true)}
              />

              <Spacer left={10} />
              <ImgEye
                src={ThumbsDownSVGIcon}
                onClick={() => approveDocument(data, false)}
              />
            </Fragment>
          )}
        </div>
      ),
    },
  ];

  const columnsLogs = [
    {
      label: "ID",
      key: "id",
      renderData: (data: any) => data.id,
    },
    {
      label: "Tipo",
      key: "Method",
      renderData: (data: any) => data.method,
    },
    {
      label: "Documento",
      key: "tax_number",
      renderData: (data: any) => formatCPFCNPJ(data.document_number),
    },
    {
      label: "Conteúdo",
      key: "content",
      renderData: (data: any) => data.content,
    },
    {
      label: "Data",
      key: "created_at",
      renderData: (data: any) => formatDateTime(data.created_at),
    },
  ];

  interface responsePartner {
    status: boolean;
    id: number;
    name: string;
    have_address: boolean;
  }

  const { partnerCheck } = usePermission();

  const [loadingPartnerCheck, setLoadingPartnerCheck] =
    useState<boolean>(false);

  const { user } = useAuth();

  const [partner, setPartner] = useState<responsePartner | null>(null);

  const searchPartner = async (_document: string) => {
    setLoadingPartnerCheck(true);

    const document_number = onlyNumbers(_document);

    const response: responsePartner = await partnerCheck(document_number);

    setPartner(response);

    if (!response.status) {
      showToast(
        "Parceiro não encontrado",
        "Verifique se digitou o CPF/CNPJ corretamente ou se o parceiro possui cadastro no sistema",
        "error"
      );
    }
    setLoadingPartnerCheck(false);
  };

  const isValidDocument =
    values.document_number.length >= 14 &&
    documentNumberValidator(values.document_number);

  const [error, setError] = useState<string>("");
  useEffect(() => {
    if (values.document_number.length < 14 && !isValidDocument) {
      setError("initialState");
    } else if (values.document_number.length >= 14 && isValidDocument) {
      setError("notError");
    } else if (values.document_number.length >= 14 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document_number]);

  console.log(partnerData?.foundation_date);

  return (
    <Fragment>
      <ParentContainer>
        <Fragment>
          <GeneralContainerData>
            <div className="d-flex flex-column container w-100 h-100 ">
              {partnerData && (
                <Fragment>
                  <Spacer top={30} />
                  <div className=" row ">
                    <Breadcrumb menus={menusBreadCrumb} />
                    <Spacer bottom={30} />
                    <ContainerData className="p-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div className="rounded-circle  profile-picture">
                            <h1>{getFirstLetters(partnerData?.name)}</h1>
                          </div>
                        </div>
                        <div className="w-100 ps-4 pt-2">
                          <h1 className="name">{partnerData?.name}</h1>
                          <Spacer bottom={-7} />
                          <Description>
                            {partnerData.document_type === "cpf"
                              ? "Pessoa física"
                              : "Pessoa juridica"}
                          </Description>
                        </div>

                        {partnerData.type == "account" &&
                          (partnerData.account_status == "editing" ||
                            partnerData.account_status ==
                              "internal_analysis") && (
                            <div className="w-100 d-flex justify-content-end">
                              <Button
                                className="ms-2"
                                label="Retornar"
                                mode="border"
                                danger
                                onClick={() => disapproverRegister(true)}
                                loading={sending}
                              />

                              <Button
                                className="ms-2"
                                label="Reprovar"
                                mode="border"
                                danger
                                onClick={() => disapproverRegister(false)}
                                loading={sending}
                              />
                              {allDocumentsApproved && user?.is_backoffice ? (
                                <ButtonDropdown
                                  className="ms-2"
                                  label="Enviar conta para abertura"
                                  mode="border"
                                  options={[
                                    {
                                      label: "Conta Corrente",
                                      value: "movement",
                                      onClick: () =>
                                        approveRegister("corrente"),
                                    },
                                    {
                                      label: "Conta Escrow",
                                      value: "escrow",
                                      onClick: () =>
                                        setOpenGetTrusteeModal(true),
                                    },
                                  ]}
                                  loading={sending}
                                />
                              ) : (
                                allDocumentsApproved &&
                                user?.is_approver && (
                                  <Button
                                    mode="main"
                                    label="Enviar conta para abertura"
                                    onClick={() => approveRegister("corrente")}
                                  />
                                )
                              )}
                            </div>
                          )}
                      </div>
                    </ContainerData>

                    <div className=" d-flex flex-column col-4 ">
                      <Spacer bottom={50} />
                      <ContainerData className=" p-4 ">
                        <div className="d-flex">
                          <Title>Dados básicos</Title>
                        </div>

                        <Spacer bottom={20} />

                        {partnerData.document_type === "cpf" ? (
                          <div className="d-flex justify-content-between row">
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {formatCpfCnpj(partnerData?.document_number)}
                              </Description>
                              <Description color="#808080">
                                {partnerData?.document_type === "cpf"
                                  ? "CPF"
                                  : "CNPJ"}
                              </Description>
                            </div>
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData?.gender === "male"
                                  ? "Masculino"
                                  : "Feminino"}
                              </Description>
                              <Description color="#808080">
                                {"GÊNERO"}
                              </Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData?.identity_number}
                              </Description>

                              <Description color="#808080">
                                DOC. DE IDENT.
                              </Description>
                            </div>
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData?.birth_state}
                              </Description>

                              <Description color="#808080">
                                NATURALIDADE
                              </Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData?.marital_status_display}
                              </Description>

                              <Description color="#808080">
                                ESTADO CIVIL
                              </Description>
                            </div>
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {formatDate(partnerData?.birth_date)}
                              </Description>

                              <Description color="#808080">
                                DATA DE NASC.
                              </Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-8">
                              <Description>
                                {partnerData?.mothers_name}
                              </Description>

                              <Description color="#808080">
                                NOME DA MÃE
                              </Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-12">
                              <Description wrapText>
                                {partnerData?.email}
                              </Description>

                              <Description color="#808080">EMAIL</Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData?.mobile_phone}
                              </Description>

                              <Description color="#808080">CELULAR</Description>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-between row">
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {formatCpfCnpj(partnerData?.document_number)}
                              </Description>
                              <Description color="#808080">
                                {partnerData?.document_type === "cpf"
                                  ? "CPF"
                                  : "CNPJ"}
                              </Description>
                            </div>
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData?.additional_type_display}
                              </Description>
                              <Description color="#808080">TIPO</Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-12">
                              <Description>
                                {partnerData?.brand_name}
                              </Description>
                              <Description color="#808080">
                                {"NOME FANTASIA"}
                              </Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {formatDate(partnerData?.foundation_date)}
                              </Description>

                              <Description color="#808080">
                                DATA DE FUNDAÇÃO
                              </Description>
                            </div>

                            <Spacer bottom={20} />

                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData?.city_doc_number === null
                                  ? "-"
                                  : partnerData?.city_doc_number}
                              </Description>

                              <Description color="#808080">
                                INSC. MUNICIPAL
                              </Description>
                            </div>
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData?.state_doc_number === null
                                  ? "-"
                                  : partnerData?.state_doc_number}
                              </Description>

                              <Description color="#808080">
                                INSC. ESTADUAL
                              </Description>
                            </div>

                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-12">
                              <Description wrapText>
                                {partnerData?.email}
                              </Description>

                              <Description color="#808080">EMAIL</Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData?.mobile_phone}
                              </Description>

                              <Description color="#808080">CELULAR</Description>
                            </div>
                          </div>
                        )}
                      </ContainerData>

                      {partnerData?.address?.length > 0 ? (
                        <ContainerData className=" p-4 mt-3 ">
                          <div className="d-flex">
                            <Title>Endereço</Title>
                          </div>

                          <Spacer bottom={20} />
                          <div className="d-flex justify-content-between row">
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData.address[0]?.zipcode}
                              </Description>
                              <Description color="#808080">CEP</Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData.address[0]?.street}
                              </Description>
                              <Description color="#808080">RUA</Description>
                            </div>
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData.address[0]?.number}
                              </Description>
                              <Description color="#808080">NÚMERO</Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData.address[0]?.district}
                              </Description>

                              <Description color="#808080">BAIRRO</Description>
                            </div>
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData.address[0]?.city}
                              </Description>

                              <Description color="#808080">CIDADE</Description>
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData.address[0]?.state.code}
                              </Description>

                              <Description color="#808080">ESTADO</Description>
                            </div>
                            <Spacer bottom={20} />

                            <div className="d-flex flex-column col-6">
                              <Description>
                                {partnerData.address[0]?.complement}
                              </Description>

                              <Description color="#808080">
                                Complemento
                              </Description>
                            </div>
                            <Spacer bottom={20} />

                            <div className="d-flex flex-column col-12">
                              <iframe
                                style={{ width: "100%" }}
                                width="600"
                                height="200"
                                id="gmap_canvas"
                                src={`https://maps.google.com/maps?q=${fullAddressQuery}&t=&z=17&ie=UTF8&iwloc=&output=embed`}
                              ></iframe>
                            </div>
                          </div>
                        </ContainerData>
                      ) : null}
                    </div>

                    <div className="col-8">
                      <Spacer bottom={50} />

                      <ContainerData className="p-4">
                        <TabMenu
                          menus={[
                            {
                              label: "Fitbank",
                              value: "fitbank_accounts",
                              onClick: () =>
                                setShowingAccounts("fitbank_accounts"),
                            },
                            {
                              label: `${label?.name}`,
                              value: "bank_accounts",
                              onClick: () =>
                                setShowingAccounts("bank_accounts"),
                            },
                          ]}
                        />
                        <Spacer bottom={20} />

                        <div className="d-flex justify-content-between align-items-center">
                          <Title>Contas bancárias</Title>
                        </div>
                        <Spacer bottom={20} />

                        {partnerData[showingAccounts].length > 0 ? (
                          <Table
                            columns={columnsBankAccounts[showingAccounts]}
                            data={partnerData[showingAccounts]}
                          />
                        ) : (
                          <Description className="text-center pb-5 pt-5">
                            Nenhuma conta bancária
                          </Description>
                        )}
                      </ContainerData>

                      <Spacer bottom={20} />

                      {partnerData.document_type === "cnpj" ? (
                        <Fragment>
                          <ContainerData className="p-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <Title>Signatários / Representantes</Title>
                            </div>
                            <Spacer bottom={40} />
                            <Table
                              columns={columnsRepresentative}
                              data={partnerData.partners}
                            />
                          </ContainerData>
                          <Spacer bottom={20} />
                        </Fragment>
                      ) : null}

                      <ContainerData className="p-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <Title>Documentos</Title>

                          {hasDocumentToApprove && (
                            <Button
                              label="Aprovar todos documentos"
                              mode="main"
                              onClick={() => approveAllDocuments()}
                              loading={sending}
                            />
                          )}
                        </div>

                        <Spacer bottom={40} />
                        {allDocuments.length > 0 ? (
                          <Table
                            data={allDocuments}
                            columns={columnsDocuments}
                          />
                        ) : (
                          <Description className="text-center pb-5 pt-5">
                            Nenhuma documento cadastro
                          </Description>
                        )}
                      </ContainerData>
                    </div>

                    <div className="col-12">
                      <Spacer bottom={20} />
                      <ContainerData className="p-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <Title>Logs Fitbank</Title>
                        </div>

                        <Spacer bottom={40} />
                        {allDocuments.length > 0 ? (
                          <Table data={allLogs} columns={columnsLogs} />
                        ) : (
                          <Description className="text-center pb-5 pt-5">
                            Nenhuma documento cadastro
                          </Description>
                        )}
                      </ContainerData>
                    </div>
                  </div>
                  <Spacer top={40} />

                  <Copyright />

                  <Spacer top={30} />
                </Fragment>
              )}
            </div>
          </GeneralContainerData>
          <RepresentativesModalDetails />
        </Fragment>
      </ParentContainer>
      <DraggableModal
        title="Visualização de documento"
        isOpen={showingFile != undefined}
        onClose={() => setShowingFile(undefined)}
      >
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={showingFile}
        ></iframe>
      </DraggableModal>
      <InteractiveModal
        size="lg"
        open={openGetTrusteeModal}
        toggleModal={() => {
          setOpenGetTrusteeModal(false);
          setPartner(null);
        }}
      >
        <div className="w-100 p-5">
          <TitleModal className="text-center">Associar Fiduciário</TitleModal>
          <div className="p-4">
            <Spacer bottom={20} />

            <>
              {" "}
              {(partner === null || partner.status === false) && (
                <Input
                  name="document_number"
                  label="Insira o CPF/CNPJ do Fiduciário"
                  placeholder="123.456.789-85"
                  success={error === "notError"}
                  error={error === "Documento inválido" && error}
                  required
                  mask={
                    values.document_number.length > 14
                      ? "99.999.999/9999-99"
                      : "999.999.999-9999999"
                  }
                  value={values.document_number}
                  onChange={(props: any) => {
                    handleChange(props);
                  }}
                  onBlur={handleBlur}
                />
              )}
              {partner !== null && partner.status === true && (
                <>
                  <Table columns={columns} data={[partner]} />
                </>
              )}
              <Button
                full
                mode="main"
                label={
                  partner !== null && partner.status === true
                    ? "Associar Fiduciário a conta"
                    : "Pesquisar"
                }
                disabled={error !== "notError"}
                onClick={() =>
                  partner !== null && partner.status === true
                    ? approveRegister("escrow", partner?.id)
                    : searchPartner(values.document_number)
                }
                loading={loadingPartnerCheck}
              />
              <Spacer bottom={10} />
              <Button
                full
                mode="border"
                label="Cancelar"
                onClick={() => {
                  setOpenGetTrusteeModal(false);
                  setPartner(null);
                }}
              />
            </>
          </div>
        </div>
      </InteractiveModal>
    </Fragment>
  );
};

export default Details;
