import { Fragment, useEffect, useState } from "react";
import { Spacer } from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import IconBusiness from "~/assets/icons/icon-business.png";
import IconPerson from "~/assets/icons/icon-person.png";
import { useNavigate } from "react-router-dom";
import { Title } from "../Singup.styles";
import styled from "styled-components";
import useToast from "~/hooks/useToast";
import { useFormik } from "formik";
import theme from "~/themes/theme";
import useSignupUser from "~/hooks/Signup/useSignupUser";

const ContainerCompany = styled.div`
  border: 1px solid ${() => theme.colors.primary.main};
  border-radius: 6px;
  max-width: 404px;
  min-height: 106px;
  display: flex;
  cursor: pointer;
  transition: border 0.5s ease, background-color 0.5s ease, color 0.5s ease,
    filter 0.5s ease;

  .div-circle {
    background-color: ${() => theme.colors.gray.light100};
    transition: border 0.5s ease, background-color 0.5s ease, color 0.5s ease,
      filter 0.5s ease;
  }

  .title {
    color: ${() => theme.colors.primary.main};
    transition: border 0.2s ease, background-color 0.2s ease, color 0.2s ease,
      filter 0.2s ease;
  }

  .icons {
    filter: ${() => theme.colors.primary.mainFilter};
    transition: border 0.2s ease, background-color 0.2s ease, color 0.2s ease,
      filter 0.2s ease;
  }

  &:hover {
    border: 1px solid ${() => theme.colors.secondary.main};

    .div-circle {
      background-color: ${() => theme.colors.gray.light100};
    }

    .title {
      color: ${() => theme.colors.secondary.main};
    }
    .icons {
      filter: ${() => theme.colors.secondary.mainFilter};
    }
  }
`;
type ProfileValues = {
  role: string;
};

const profileInitialValues: ProfileValues = {
  role: "",
};

const RoleOptions: {
  icon: string;
  name: string;
  description: string;
  value: string;
}[] = [
  {
    icon: IconPerson,
    name: "Pessoa Física",
    description:
      "É necessário que você seja maior de idade e esteja com seus documentos regularizados",
    value: "PF",
  },
  {
    icon: IconBusiness,
    name: "Pessoa Jurídica",
    description:
      "Sou funcionário, sócio ou procurador de uma empresa sediada no Brasil",
    value: "PJ",
  },
];

const SignupUserProfile = () => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { setStep, setStepIndex } = useSignupUser();

  setStep("profile");

  const handleSubmitForm = (role: string) => {
    if (role === "PF") {
      navigate("/signup/personal");
    } else {
      navigate("/signup/company");
    }
  };

  const handleParentSubmit = (values: ProfileValues) => {
    if (values.role === "") {
      showToast(
        "Atenção",
        `Selecione o tipo de cadastro que deseja fazer`,
        "error"
      );
      return;
    }

    handleSubmitForm(values.role);
  };

  const { submitForm } = useFormik({
    initialValues: profileInitialValues,
    onSubmit: handleParentSubmit,
  });

  useEffect(() => {
    window.addEventListener("signupUserSubmit", submitForm);

    setStepIndex(2);

    return () => {
      window.removeEventListener("signupUserSubmit", submitForm);
    };
  }, []);

  return (
    <Fragment>
      <div style={{ maxWidth: "474px" }} className="mt-5">
        <Title>Defina como deseja continuar seu cadastro</Title>
      </div>
      <Spacer bottom={30} />
      {RoleOptions.map((roleOption, index) => (
        <Fragment>
          <div
            key={index}
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={() => {
              roleOption.value === "PF"
                ? navigate("/signup/personal/general")
                : navigate("/signup/company/intro");
            }}
          >
            <ContainerCompany className="col-12">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ padding: "15px" }}
              >
                <div
                  className="d-flex justify-content-center align-items-center div-circle"
                  style={{
                    width: "48px",
                    height: "48px",
                    borderRadius: "50%",
                  }}
                >
                  <img alt="" src={roleOption.icon} className="icons" />
                </div>
              </div>

              <div
                className="d-flex flex-column justify-content-center "
                style={{ padding: " 10px" }}
              >
                <span
                  className="title"
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  {roleOption.name}
                </span>
                <span style={{ fontSize: "14px", color: "#929292" }}>
                  {roleOption.description}
                </span>
              </div>
            </ContainerCompany>
          </div>
          <Spacer bottom={10} />
        </Fragment>
      ))}
    </Fragment>
  );
};

export default SignupUserProfile;
