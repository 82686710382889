import {
  Button,
  InteractiveModal,
  ProgressBar,
  Spacer,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as Yup from "yup";
import HelpImg from "~/assets/icons/buttons/help.svg";
import CheckedIcon from "~/assets/icons/checked.png";
import DataIcon from "~/assets/icons/icon-fingerprint-active.png";
import PasswordIconInactive from "~/assets/icons/icon-lock-inactive.png";
import ContactIcon from "~/assets/icons/alternate_email_24dp_FILL0_wght400_GRAD0_opsz24.png";
import PasswordIcon from "~/assets/icons/icon-lock.png";
import MoreIcon from "~/assets/icons/icon-more.png";
import ProfileIconInactive from "~/assets/icons/icon-profile-inactive.png";
import ProfileIcon from "~/assets/icons/icon-profile.png";
import ValidatorsModals from "~/components/ModalsValidators/ValidatorsModals";
import SteperUser, { SteperItemProps } from "~/components/Stepers/SteperUser";
import useSignupUser from "~/hooks/Signup/useSignupUser";
import {
  Aside,
  Container,
  ContainerData,
  HeaderContainer,
  GeneralContainer,
} from "../Singup.styles";
import { FormikProps, useFormik } from "formik";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { account_type_dict } from "~/data/account";
import useSignupCompany from "~/hooks/Signup/useSignupCompany";
import useSignupPersonal from "~/hooks/Signup/useSignupPersonal";
import useAccount from "~/hooks/useAccount";
import useAnimation from "~/hooks/useAnimation";
import useAuth from "~/hooks/useAuth";
import useToast from "~/hooks/useToast";
import useToken from "~/hooks/useToken";
import {
  ContainerCompany,
  TitleModal,
} from "~/pages/Authentication/Auth.styles";
import ApiService from "~/services/api";
import AppStorage from "~/services/storage";
import theme from "~/themes/theme";
import { LoginResponse, UserCheckResponse } from "~/types";
import { formatCPFCNPJ, getAccountStatus, onlyNumbers } from "~/utils";
import documentNumberValidator from "~/utils/validateDocNumber";
import IconBusiness from "~/assets/icons/icon-business.png";
import IconPerson from "~/assets/icons/icon-person.png";
import styled from "styled-components";
import SignupBasicPassword from "./SignupUserPassword";
import label from "~/labels";
import Description from "~/components/Texts/Description";

type FormLoginValues = {
  username_login: string;
  password: string;
};

type verificationType = {
  phone?: string;
  email?: string;
};

const TitlePage = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const initialValues: FormLoginValues = {
  username_login: "",
  password: "",
};

const SignupUserStructure = () => {
  const {
    onSubmitForm,
    stepIndex,
    loading,
    doLogin,
    setLoading,
    dataLoginUser,
    setIsSignUp,
    valid,
  } = useSignupUser();
  const { setOpenHelpModal } = useAccount();
  const { setNewPartner } = useSignupPersonal();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIdUser } = useAccount();
  const [userFounded, setUserFounded] = useState<boolean>(false);
  const { showToast } = useToast();
  const { partnersModal, setPartnersModal } = useAuth();
  const { userPartners, setUserPartners } = useAccount();
  const { sendPartnerToken, verifyUserToken, getToken } = useToken();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [submitedValues, setSubmitedValues] =
    useState<FormLoginValues>(initialValues);
  const [verificationData, setVerificationData] = useState<verificationType>(
    {}
  );
  const { pathname } = location;

  const isProfileRoute = useMemo(
    () => pathname.includes("profile"),
    [pathname]
  );
  const routesIncludes = useMemo(
    () => ["personal-data", "contact-data"],
    [pathname]
  );
  const labelButtonReturnDict = useMemo(
    () => ["Voltar ao login", "Retornar"],
    []
  );

  const returnStepDict = useMemo(
    () => [
      () => navigate("/auth/login"),
      () => navigate("/signup/user/personal-data"),
    ],
    [navigate]
  );

  const menus: SteperItemProps[] = [
    {
      title: "Dados Pessoais",
      step: "data",
      stepIndex: stepIndex,
      icon: DataIcon,
      iconInactive: DataIcon,
      iconCompleted: CheckedIcon,
    },
    {
      title: "Contato",
      step: "contact",
      stepIndex: stepIndex,
      icon: ContactIcon,
      iconInactive: ContactIcon,
      iconCompleted: CheckedIcon,
    },

    {
      title: "Senha de acesso",
      step: "password",
      stepIndex: stepIndex,
      icon: PasswordIcon,
      iconInactive: PasswordIconInactive,
      iconCompleted: CheckedIcon,
    },

    {
      title: "Perfil de usuário",
      step: "profile",
      stepIndex: stepIndex,
      icon: ProfileIcon,
      iconInactive: ProfileIconInactive,
      iconCompleted: CheckedIcon,
    },

    {
      title: "",
      step: "more",
      stepIndex: stepIndex,
      icon: MoreIcon,
      iconInactive: MoreIcon,
      iconCompleted: MoreIcon,
    },
  ];

  const FormSchema = Yup.object().shape({
    document_number: Yup.string()
      .required("Campo obrigatório")
      .test("", "", function (value = "") {
        const { path, createError } = this;
        const isValid = documentNumberValidator(onlyNumbers(value));
        return isValid || createError({ path, message: "Documento inválido" });
      }),
    password: Yup.string()
      .min(8, "A senha precisa ter pelo menos 8 caracteres")
      .required("Campo obrigatório"),
  });

  const percentageProgressBar = useMemo(() => {
    return (100 / menus.length) * stepIndex;
  }, [menus.length, stepIndex]);

  const returnNameButton = useMemo(() => {
    const index = routesIncludes.findIndex((route) => pathname.includes(route));
    return labelButtonReturnDict[index];
  }, [pathname, routesIncludes, labelButtonReturnDict]);

  const buttonReturnLabel = returnNameButton;

  const { values } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: onSubmitForm,
    enableReinitialize: true,
  }) as FormikProps<FormLoginValues>;

  const doReset = useCallback(() => {
    setVerificationData({});
    setSubmitedValues(initialValues);
  }, [setVerificationData, setSubmitedValues, initialValues]);

  const onSubmitLogin = useCallback(async () => {
    setSubmitedValues(values);
    setLoading(true);
    try {
      const checkDocumentNumberPayload = {
        type: "document_number",
        username: onlyNumbers(dataLoginUser?.username_login),
      };

      const { data: data_user_check } =
        await ApiService.HttpPost<UserCheckResponse>({
          route: "user-check/",
          body: checkDocumentNumberPayload,
        });

      if (!data_user_check.status) {
        showToast(
          "Documento não encontrado",
          "Parece que o documento informado não existe em nosso sistema, verifique se o documento está correto.",
          "error"
        );
        setUserFounded(false);
      } else {
        setUserPartners(data_user_check.response.user.partners);

        const foundUser = data_user_check.response.user;
        setIdUser(foundUser?.id);

        try {
          const authPayload = {
            username: onlyNumbers(foundUser.username),
            password: dataLoginUser?.password,
          };

          const { data } = await ApiService.HttpPost<LoginResponse>({
            route: "api-token-auth/",
            body: authPayload,
          });
          if (data.token) {
            AppStorage.setRegisteredToken(data.token);

            if (data_user_check.response.partner_default) {
              setDataPersonal(data_user_check);
              setPartnersModal(true);
              setLoading(false);
            } else {
              navigate("/signup/user/profile");
              setLoading(false);
            }
          }
        } catch (error) {
          showToast("Erro", "Usuário e/ou senha incorretos", "error");
          doReset();
          console.log(error);
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [
    values,
    dataLoginUser,
    showToast,
    setUserFounded,
    setUserPartners,
    setIdUser,
    navigate,
    setPartnersModal,
    setLoading,
  ]);

  const returnStep = useCallback(() => {
    const index = routesIncludes.findIndex((route) => pathname.includes(route));
    return returnStepDict[index]();
  }, [pathname, routesIncludes, returnStepDict]);

  const setDataPersonal = useCallback(
    (data_user_check: any) => {
      localStorage.setItem(
        "new_partner",
        JSON.stringify({
          id:
            data_user_check.response.partner_default.id ??
            data_user_check.response.user.id,
          name:
            data_user_check.response.partner_default.name ??
            data_user_check.response.user.name,
        })
      );

      setNewPartner({
        id:
          data_user_check.response.partner_default.id ??
          data_user_check.response.user.id,
        name:
          data_user_check.response.partner_default.name ??
          data_user_check.response.user.name,
      });
    },
    [setNewPartner]
  );

  const checkSelectedPartnerToken = useCallback(async () => {
    const partnerDefault: any = await ApiService.HttpPost({
      route: "partner-default/",
      body: null,
      token: true,
    });

    await sendPartnerToken();
    setPartnersModal(false);
    setLoadingPage(false);

    const token = AppStorage.getRegisteredToken();

    const validateToken = await getToken();

    setLoading(true);

    const check = await verifyUserToken(
      {
        code: validateToken,
      },
      { token }
    );

    if (!check) {
      showToast(
        "Token Incorreto",
        "Parece que o token informado não corresponde ao enviado pelo nosso sistema, tente novamente.",
        "error"
      );
      setLoading(false);
      doReset();
      return;
    }

    setLoading(false);

    AppStorage.setRegisteredPartner(partnerDefault.data);
    AppStorage.setRegisteredUser(partnerDefault.data.user_data);

    const hasDataMissing = await ApiService.HttpPost({
      route: "onboard-checker/",
      body: {
        partner_id: partnerDefault.data.id,
      },
      token: true,
    });

    const {
      data: {
        data: { account_status, account_status_step, account_type },
      },
    } = hasDataMissing;

    setPartnersModal(false);

    switch (account_status) {
      case "editing":
        switch (account_status_step) {
          case "complementary":
            navigate(`/signup/${account_type}/general`);
            return;
          case "representatives":
            navigate(`/signup/${account_type}/representatives`);
            return;
          case "address":
            navigate(`/signup/${account_type}/address`);
            return;
          case "attachments":
            navigate(`/signup/${account_type}/attachments`);
            return;
          case "review":
            showToast(
              "Cadastro",
              "Conta em fase de aprovação, verifique mais tarde novamente",
              "warning"
            );
            return;

          case "finished":
            showToast("Cadastro", "Fase de aprovação finalizada", "success");
            return;
        }

        return;
      case "internal_analysis":
        showToast(
          "Cadastro",
          "Conta em fase de aprovação interna, verifique mais tarde novamente",
          "warning"
        );
        return;
      case "external_analysis":
        showToast(
          "Cadastro",
          "Conta em fase de aprovação externa, verifique mais tarde novamente",
          "warning"
        );
        return;
      case "blocked":
        showToast(
          "Cadastro",
          "Conta com pendências, breve entraremos em contato para regularizar",
          "warning"
        );
        return;
      case "approved":
        showToast(
          "Cadastro",
          "Conta aprovada, você já pode usar a plataforma",
          "success"
        );
        return;
      case "refused":
        showToast(
          "Cadastro",
          "Conta recusada, entre em contato com o nosso suporte",
          "error"
        );
        return;
    }

    showToast(
      "Atenção",
      "Não foi possível identificar o status do seu cadastro, entre em contato com o suporte",
      "warning"
    );
    navigate("/");

    return;
  }, [
    sendPartnerToken,
    setPartnersModal,
    setLoadingPage,
    showToast,
    navigate,
    getToken,
    verifyUserToken,
  ]);

  const selectPartner = useCallback(
    async (partner: any) => {
      setLoadingPage(true);

      try {
        await ApiService.HttpPut({
          route: `user-partners/${partner?.id}/set_default/`,
          body: {
            new: partner === null,
          },
          token: true,
        });

        if (partner === null) {
          setPartnersModal(false);
          navigate(`/signup/user/profile`);
          setLoadingPage(false);
          return;
        }

        return checkSelectedPartnerToken();
      } catch (error) {
        console.error(error);
        showToast(
          "Erro",
          "Não foi possivel recuperar os dados da conta, tente novamente mais tarde.",
          "error"
        );
      }
    },
    [
      setLoadingPage,
      setPartnersModal,
      navigate,
      checkSelectedPartnerToken,
      showToast,
    ]
  );

  const validUserPartners = useMemo(() => {
    return userPartners.filter(
      (partner) =>
        partner.account_status == "editing" && partner.type == "account"
    );
  }, [userPartners]);

  useEffect(() => {
    if (location.pathname === "/signup/user") {
      navigate("/signup/user/pesonal-data");
    }
  }, [location]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <GeneralContainer className=" w-100 ">
          <HeaderContainer className="col-12 pt-3 pb-3 ">
            <div style={{ width: "500px" }} className="d-flex">
              <Spacer top={20} />

              <TitlePage>{doLogin ? "Login" : "Dados pessoais"}</TitlePage>
              <div style={{ position: "relative", top: "-7px" }}>
                <Button
                  mode="phantom"
                  iconColorFilter={
                    label.usePrimaryColor?.includes("all_required_onboard")
                      ? "brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(1021%) hue-rotate(84deg) brightness(113%) contrast(99%)"
                      : " brightness(0) saturate(100%)"
                  }
                  icon={HelpImg}
                  onClick={() => setOpenHelpModal(true)}
                />
              </div>
            </div>

            <Spacer bottom={15} />
            <div className="d-flex justify-content-md-end justify-content-center w-100 ">
              {!isProfileRoute && (
                <Button
                  type="button"
                  mode="border"
                  label={buttonReturnLabel}
                  onClick={() => {
                    returnStep();
                  }}
                />
              )}
              <Spacer left={10} />
              {!isProfileRoute && (
                <Button
                  onClick={() => {
                    doLogin ? onSubmitLogin() : onSubmitForm();
                  }}
                  loading={loading}
                  arrow="right"
                  mode="main"
                  label="Continuar"
                  disabled={doLogin ? false : !valid}
                />
              )}
            </div>
          </HeaderContainer>
          <Container className="d-flex flex-column flex-md-row h-100 ">
            <Aside className="col-md-4 d-flex flex-column ">
              <div className="d-flex  flex-column w-100 align-items-center align-items-md-start ">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="d-flex justify-content-start w-100 mb-2 mb-md-5">
                    {/* <ImgLogo className="mb-2 mb-md-5" src={label.logoSignup} /> */}
                  </div>
                  <Spacer bottom={10} />
                  <div className="d-md-none w-100">
                    <ProgressBar percent={percentageProgressBar} />
                  </div>

                  <Spacer bottom={50} />

                  <div className="content">
                    <SteperUser menu={menus} />
                  </div>
                </motion.div>
              </div>
            </Aside>
            <div style={{ backgroundColor: theme.colors.gray.light100 }}>
              <ContainerData className="col-md-8 d-flex flex-column ">
                <Outlet />
              </ContainerData>
            </div>
          </Container>

          <InteractiveModal
            size="md"
            open={partnersModal}
            toggleModal={() => setPartnersModal(!partnersModal)}
          >
            <>
              {loadingPage ? (
                <div className="p-4 h-100 d-flex h-100 justify-content-center align-items-center w-100   flex-column">
                  <Spacer bottom={120} />
                  <div
                    className="spinner-border mb-2"
                    role="status"
                    style={{ color: theme.colors.primary.main }}
                  ></div>
                  <Spacer top={20} />
                  <div className="text-center">Aguarde...</div>
                </div>
              ) : (
                <div className="p-4">
                  <TitleModal>
                    Selecione um perfil de pessoa para prosseguir
                  </TitleModal>
                  <Spacer bottom={16} />

                  {validUserPartners?.length > 0 ? (
                    validUserPartners?.map((partner, index) => (
                      <Fragment>
                        <div
                          key={index}
                          style={{ textDecoration: "none", cursor: "pointer" }}
                          onClick={() => selectPartner(partner)}
                        >
                          <ContainerCompany className="col-12">
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ padding: "15px" }}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center div-circle"
                                style={{
                                  width: "48px",
                                  height: "48px",
                                  borderRadius: "50%",
                                }}
                              >
                                <img
                                  alt=""
                                  src={
                                    partner.document_type == "cpf"
                                      ? IconPerson
                                      : IconBusiness
                                  }
                                  className="icons"
                                />
                              </div>
                            </div>

                            <div
                              className="d-flex flex-column justify-content-center "
                              style={{ padding: " 10px" }}
                            >
                              <span
                                className="title"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                              >
                                {partner.name}
                              </span>
                              <span
                                style={{ fontSize: "14px", color: "#929292" }}
                              >
                                {formatCPFCNPJ(partner.document_number)}
                                {" - "}
                                {account_type_dict[partner.type]}
                                {" - "}
                                {getAccountStatus(partner.account_status)}
                              </span>
                            </div>
                          </ContainerCompany>
                        </div>
                        <Spacer bottom={16} />
                      </Fragment>
                    ))
                  ) : (
                    <div>
                      <Spacer bottom={40} />
                      <p className="mb-5 mt-4 text-center">
                        Você não possui contas pendentes de edição no cadastro.
                      </p>
                      <Spacer bottom={40} />
                    </div>
                  )}

                  <Button
                    mode="border"
                    label="Adicionar nova conta"
                    full
                    onClick={() => selectPartner(null)}
                  />

                  {validUserPartners?.length === 0 && (
                    <Button
                      className="mt-2"
                      mode="main"
                      label="Voltar pro login"
                      full
                      onClick={() => {
                        setPartnersModal(!partnersModal);
                        localStorage.clear();
                        navigate("/auth/login");
                      }}
                    />
                  )}
                </div>
              )}
            </>
          </InteractiveModal>

          <ValidatorsModals />
        </GeneralContainer>
      </motion.div>
      <SignupBasicPassword />
    </AnimatePresence>
  );
};

export default SignupUserStructure;
