import { Spacer } from "@zilliondigital/mirage-ui";
import { HTMLAttributes } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import useSignupUser, {
  SignupUserSteps,
  steps,
} from "~/hooks/Signup/useSignupUser";
import theme from "~/themes/theme";
import { useEffect } from "react";

const AppSteper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

const SteperContainerItem = styled.div`
  display: flex;
  align-items: start;

  height: 100%;

  flex-direction: column;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const SteperTitle = styled.span<SteperProps>`
  color: ${({ status }) =>
    status === "active"
      ? theme.colors.primary.main
      : status === "inactive"
      ? theme.colors.primary.light300
      : theme.colors.primary.light400};
`;

const SteperItem = styled.div<SteperProps>`
  width: 32px;
  height: 32px;
  margin-bottom: 12px;
  border-radius: 50%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: ${({ status }) =>
    status === "active"
      ? "1px solid" + theme.colors.primary.main
      : status === "inactive" && "1px solid" + theme.colors.primary.light300};

  background-color: ${({ status }) =>
    status === "completed" && theme.colors.primary.main};
`;

const SteperIcon = styled.img<SteperProps>`
  width: 22px;

  filter: ${({ status }) =>
    status === "inactive" ? theme.colors.primary.light300Filter
      : status === "completed"
      ? theme.colors.base.light100Filter
      : theme.colors.primary.mainFilter};
`;

const Divider = styled.div<SteperProps>`
  width: 1px;
  height: 15px;
  background-color: ${({ status }) =>
    status === "inactive"
      ? theme.colors.primary.light300
      : theme.colors.primary.main};
  position: absolute;
  transform: translate(1600%, -84%);
`;

export type SteperItemChildrenProps = {
  title: string;
  step: string;
  stepIndex: number;
};

export type SteperItemProps = {
  title: string;
  step: SignupUserSteps;
  stepIndex: number;
  icon: string;
  iconInactive: string;
  iconCompleted: string;
  children?: SteperItemChildrenProps[];
};

type SteperProps = HTMLAttributes<HTMLDivElement> & {
  menu?: SteperItemProps[];
  status?: "inactive" | "active" | "completed";
};

const SteperUser = (props: SteperProps) => {
  const { step, setStep, setStepIndex } = useSignupUser();

  const { menu = [] } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const setStepCustom = (_step: any) => {
    setStep(_step);
    navigate(`/signup/user/${_step}`);
  };

  useEffect(() => {
    const active: any = location.pathname.split("/").pop();
    if (steps.includes(active)) {
      setStep(active);
    }
  }, [steps]);

  return (
    <AppSteper className="d-md-flex d-none flex-md-column " {...props}>
      {menu.map((item, index) => {
        setStepIndex(menu.findIndex((item) => item.step === step) + 1);
        return (
          <SteperContainerItem {...props} key={index}>
            <div className="d-flex justify-content-center align-items-start  ">
              <div>
                <SteperItem
                  // onClick={() => {
                  //   index !== menu.length - 1 && setStepCustom(item.step);
                  // }}
                  status={
                    item.stepIndex === index + 1
                      ? "active"
                      : item.stepIndex !== index + 1 && item.stepIndex > index
                      ? "completed"
                      : "inactive"
                  }
                >
                  <SteperIcon
                    src={
                      item.stepIndex !== index + 1 && item.stepIndex > index
                        ? item.iconCompleted
                        : item.stepIndex !== index + 1
                        ? item.iconInactive
                        : item.icon
                    }
                    style={
                      item.stepIndex !== index + 1 && item.stepIndex > index
                        ? { filter: theme.colors.base.light100Filter }
                        : { filter: theme.colors.base.dark200Filter }
                    }
                  />
                </SteperItem>
                {index !== menu.length - 1 && (
                  <Divider
                    status={
                      item.stepIndex === index + 1
                        ? "active"
                        : item.stepIndex !== index + 1 && item.stepIndex > index
                        ? "completed"
                        : "inactive"
                    }
                  />
                )}
              </div>
              <Spacer left={16} />
              <SteperTitle
                // onClick={() => {
                //   index !== menu.length - 1 && setStepCustom(item.step);
                // }}
                status={
                  item.stepIndex === index + 1
                    ? "active"
                    : item.stepIndex !== index + 1 && item.stepIndex > index
                    ? "completed"
                    : "inactive"
                }
              >
                {item.title}
              </SteperTitle>
            </div>
          </SteperContainerItem>
        );
      })}
    </AppSteper>
  );
};

export default SteperUser;
