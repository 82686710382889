import { AppPartner, User } from "~/types/index";
import constate from "constate";
import { useState } from "react";
import AppStorage from "~/services/storage";

const steps = ["forgot", "instructions_sent", "security_validate"];

const [AuthProvider, useAuth] = constate(() => {
  const [token, setToken] = useState<string>();
  const [validateToken, setValidateToken] = useState<string>("");
  const [user, setUser] = useState<AppPartner>();

  const [openValidatorModalLogin, setOpenValidatorModalLogin] =
    useState<boolean>(false);

  const [openModal, setOpenModal] = useState(false);
  const [partnersModal, setPartnersModal] = useState<boolean>(false);

  const [stepIndex, setStepIndex] = useState<number>(0);
  const [step, setStep] = useState<string>(steps[stepIndex]);

  const nextStep = () => {
    if (stepIndex < steps.length - 1) {
      const index = stepIndex + 1;
      setStepIndex(index);
      setStep(steps[index]);
    }
  };

  const logout = () => {
    setToken("");
    setUser(undefined);

    AppStorage.clear();
  };

  const [canDoLogin, setCanDoLogin] = useState<boolean>(false);

  const userCanDoLogin = (_token: string) => {
    setCanDoLogin(true);
    setOpenValidatorModalLogin(false);
    setValidateToken(_token);
  };

  return {
    token,
    setToken,
    user,
    setUser,
    step,
    setStep,
    stepIndex,
    setStepIndex,
    steps,
    nextStep,
    logout,
    setOpenValidatorModalLogin,
    openValidatorModalLogin,
    userCanDoLogin,
    canDoLogin,
    setCanDoLogin,
    validateToken,
    setValidateToken,
    openModal,
    setOpenModal,
    partnersModal,
    setPartnersModal,
  };
});

export { AuthProvider };

export default useAuth;
