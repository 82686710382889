import {
  Button,
  Checkbox,
  Input,
  InputCurrency,
  LateralModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import useStatements from "~/hooks/useStatements";
import { payloadDatesToParam } from "~/utils";
import { Subtitle, ContainerBottom } from "~/pages/App/app-content.styles";

type FilterStatementsValues = {
  from_date: string;
  to_date: string;
  /* transactionValueFrom: string;
  transactionValueTo: string;  
  payment: boolean;
  transfer: boolean;
  card: boolean;
  incoming: boolean;
  outgoing: boolean;
  paid: boolean;
  scheduled: boolean; */
};

const initialValues: FilterStatementsValues = {
  from_date: "",
  to_date: "",
  /* transactionValueFrom: "",
  transactionValueTo: "",
  transactionDateTo: "",
  payment: false,
  transfer: false,
  card: false,
  incoming: false,
  outgoing: false,
  paid: false,
  scheduled: false, */
};

const FormSchema = Yup.object().shape({
  from_date: Yup.string().notRequired(),
  to_date: Yup.string().notRequired(),
  /* identification: Yup.string().notRequired(),
  transactionValueFrom: Yup.string().notRequired(),
  transactionValueTo: Yup.string().notRequired(),  
  payment: Yup.boolean(),
  transfer: Yup.boolean(),
  card: Yup.boolean(),
  incoming: Yup.boolean(),
  outgoing: Yup.boolean(),
  paid: Yup.boolean(),
  scheduled: Yup.boolean(), */
});

const StatementsFilter = () => {
  const {
    openFilterModal,
    setOpenFilterModal,
    getStatements,
    filterParams,
    isLoadingTransactions,
    setFetchParams,
  } = useStatements();

  const [initVals, setVals] = useState<any>(initialValues);

  const handleFormSubmit = async (values: FilterStatementsValues) => {
    const params = payloadDatesToParam(
      {
        ...values,
      },
      true
    );

    setFetchParams(params);
    await getStatements(params);
    setOpenFilterModal(false);
  };

  const { handleChange, setFieldValue, values, resetForm, submitForm } =
    useFormik({
      validationSchema: FormSchema,
      initialValues: initVals,
      onSubmit: handleFormSubmit,
      enableReinitialize: true,
    });

  const loadFilters = () => {
    if (filterParams) {
      const newValues = payloadDatesToParam({
        ...initialValues,
        ...filterParams,
      });
      setVals(newValues);
    }
  };

  useEffect(() => {
    if (openFilterModal) {
      loadFilters();
    }
  }, [openFilterModal, filterParams]);

  return (
    <LateralModal
      open={openFilterModal}
      title="Filtros"
      toggleModal={() => setOpenFilterModal(!openFilterModal)}
      header
    >
      <div className=" w-100 vh-100 d-flex flex-column justify-content-start p-4">
        <form className="col-12">
          <div className="row ">
            {/* <Input
              name="identification"
              value={values.identification}
              onChange={handleChange}
              type="text"
              label="Identificação"
              placeholder="#124547"
            />
            <Subtitle className="ps-2">Valor da transação</Subtitle>
            <div className="col-12 col-md-6">
              <InputCurrency
                value={values.transactionValueFrom}
                fontSize="large"
                label="De"
              />
            </div>
            <div className="col-12 col-md-6">
              <InputCurrency
                value={values.transactionValueTo}
                fontSize="large"
                label="Até"
              />
            </div> */}
            <Subtitle className="ps-2">Data da transação</Subtitle>
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateFrom"
                value={values.from_date}
                onChange={(v) => setFieldValue("from_date", v.target.value)}
                type="date"
                label="De"
              />
            </div>
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateTo"
                value={values.to_date}
                onChange={(v) => setFieldValue("to_date", v.target.value)}
                type="date"
                label="Até"
              />
            </div>

            {/* <div className="col-6 d-flex flex-column align-items-start ">
              <Subtitle>Tipo de transação</Subtitle>
              <Checkbox
                name="payment"
                checked={values.payment}
                onClick={() => setFieldValue("payment", !values.payment)}
                label="Pagamento"
                position="right"
              />

              <Spacer bottom={10} />
              <div>
                <Checkbox
                  label="Transferência"
                  name="transfer"
                  checked={values.transfer}
                  onClick={() => setFieldValue("transfer", !values.transfer)}
                  position="right"
                />
              </div>
              <Spacer bottom={10} />
              <Checkbox
                label="Cartão"
                name="card"
                checked={values.card}
                onClick={() => setFieldValue("card", !values.card)}
                position="right"
              />
            </div> */}

            {/* <div className="col-6 d-flex flex-column align-items-start ">
              <Subtitle>Tipo de movimentação</Subtitle>
              <Checkbox
                label="Entrada"
                name="incoming"
                checked={values.incoming}
                onClick={() => setFieldValue("incoming", !values.incoming)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Saída"
                name="outgoing"
                checked={values.outgoing}
                onClick={() => setFieldValue("outgoing", !values.outgoing)}
                position="right"
              />
            </div>

            <Spacer bottom={20} />
            <div className="col-6 d-flex flex-column align-items-start ">
              <Subtitle>Status da transação</Subtitle>
              <Checkbox
                label="Efetuada"
                name="paid"
                checked={values.paid}
                onClick={() => setFieldValue("paid", !values.paid)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Agendada"
                name="scheduled"
                checked={values.scheduled}
                onClick={() => setFieldValue("scheduled", !values.scheduled)}
                position="right"
              />
            </div> */}
          </div>
        </form>

        <ContainerBottom>
          <div className="w-100 d-flex justify-content-end ">
            <Button
              mode="phantom"
              label="Limpar tudo"
              onClick={() => {
                resetForm();
              }}
            />
            <Button
              mode="main"
              label="Aplicar filtros"
              loading={isLoadingTransactions}
              disabled={isLoadingTransactions}
              onClick={() => submitForm()}
            />
          </div>
        </ContainerBottom>
      </div>
    </LateralModal>
  );
};

export default StatementsFilter;
