import {
  Button,
  LateralModal,
  RadioGroup,
  Spacer,
} from "@zilliondigital/mirage-ui";

import { useEffect, useState } from "react";

import useCollections from "~/hooks/Collections/useCollections";
import useAccount from "~/hooks/useAccount";
import useAddBalance from "~/hooks/useAddBalance";
import useToast from "~/hooks/useToast";
import ApiService from "~/services/api";
import { convertStringToNumber } from "~/utils";

const ExportsModal = () => {
  const {
    selectedIds,
    openExportsModal,
    setOpenExportsModal,
    setSelectedIds,
    setStatusColumnSelected,
  } = useCollections();
  const [selectedValue, setSelectedValue] = useState<string>("");
  const { showToast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isEmpty, setIsEmpty } = useAddBalance();

  function handleChangeTille(value: any) {
    setSelectedValue(value);

    if (value === "0") {
      setSelectedValue("pdf");
      setIsEmpty(false);
    }

    if (value === "1") {
      setSelectedValue("xls");
      setIsEmpty(false);
    }
    if (value === "2") {
      setSelectedValue("download");
      setIsEmpty(false);
    }
  }

  const radioOptions: { title: string; description: string; value: string }[] =
    [
      {
        title: "Relatório (PDF)",
        description:
          "Gere um relatório em PDF detalhado dos boletos selecionados",
        value: "0",
      },
      {
        title: "Relatório (XLSX)",
        description:
          "Gere um relatório em XLSX detalhado dos boletos selecionados",
        value: "1",
      },
      {
        title: "Baixar boletos (PDF)",
        description: "Faça download dos seus boletos em PDF",
        value: "2",
      },
    ];

  const handleToggleModal = () => {
    setSelectedValue("");
    setOpenExportsModal(false);
    setSelectedIds([]);
    setIsEmpty(true);
    setStatusColumnSelected(false);
  };

  const exportPDFOrXLS = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost({
        route: `charge/billet-report-${selectedValue}/`,
        body: selectedIds,
        token: true,
      });
      setIsLoading(false);
      showToast("Relatório gerado com sucesso", "", "success");
      handleToggleModal();
      window.open(data);
    } catch {
      setIsLoading(false);
    }
  };

  const downloadBillets = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost({
        route: `charge/billet-zip/`,
        body: selectedIds,
        token: true,
      });
      setIsLoading(false);
      showToast(
        "Gerando arquivo...",
        "Você será notificado quando o arquivo estiver disponível",
        "success",

        () => null,
        true,
        10000
      );
      handleToggleModal();
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <LateralModal
      open={openExportsModal}
      toggleModal={() => handleToggleModal()}
      header
      title="Gerar relatório"
    >
      <div className="p-4 vh-100">
        <RadioGroup
          emptyValue={isEmpty}
          display="row"
          options={radioOptions}
          wrap
          onChange={(value) => handleChangeTille(value)}
        />

        <div className="d-flex w-100 justify-content-between pt-5">
          <Button
            mode="border"
            label={"Cancelar"}
            onClick={handleToggleModal}
          />
          <Button
            mode="main"
            loading={isLoading}
            label={
              selectedValue === "download"
                ? "Fazer download"
                : "Gerar relatório"
            }
            onClick={() => {
              selectedValue !== "download"
                ? exportPDFOrXLS()
                : downloadBillets();
            }}
            disabled={selectedValue === ""}
          />
        </div>
      </div>
    </LateralModal>
  );
};

export default ExportsModal;
