import "bootstrap/dist/css/bootstrap.min.css";
import {
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";

import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";

import SupplierIcon from "~/assets/icons/contacts/supplier.png";
import ColaboratteOrCostumerIcon from "~/assets/icons/contacts/collaborate-or-costumer.png";
import DeleteIcon from "~/assets/icons/delete.png";
import PenIcon from "~/assets/icons/contacts/pencil.png";

import Title from "~/components/Texts/Title";

import ApiService from "~/services/api";

import Timer from "~/assets/icons/figure_3d_time.png";
import {
  formatCPFCNPJ,
  formatCash,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";

import { taxesArray } from "~/data/options";
import theme from "~/themes/theme";
import useTariffs from "~/hooks/useTariffs";
import Description from "~/components/Texts/Description";
import { ImgEye } from "./PackpagesTariffsList";

type RenderTypeProps = {
  [key: string]: string;
};

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  

  }
`;

const Rounded = styled.div`
  width: 96px;
  height: 96px;
  background-color: #ffe5d8;
  position: relative;

  img {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`;

const EditContainer = styled.div`
  position: relative;
  right: -25px;
`;

const ImgDelete = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.error.mainFilter};
  }
`;

const renderType: RenderTypeProps = {
  both: "Ambos (PF e PJ)",
  pj: "Pessoa Jurídica",
  pf: "Pessoa Física",
};

type renderImageRoleType = {
  [key: string]: string;
};

const PersonalizedTariffs = () => {
  const [tariffsData, setTariffsData] = useState<any>([]);
  const {
    setTariffData,
    setOpenEditTariffModal,
    setStepIndexModal,
    setOpenDeletePersonalizedTariffModal,
    setDeleteTariffModalData,
  } = useTariffs();

  function getLabelBySlug(slug: string) {
    const tax = taxesArray.find((tax) => tax.value === slug);
    return tax ? tax.label : null;
  }

  const columns = [
    {
      label: "Cliente",
      key: "name",

      renderData: (data: any) => {
        return (
          <div className="d-flex flex-column">
            <Description className="ps-3">
              {data?.partner_fields.name ?? "-"}
            </Description>
            <Description color={theme.colors.base.dark300} className="ps-3">
              {formatCPFCNPJ(data?.partner_fields.document_number) ?? "-"}
            </Description>
          </div>
        );
      },
    },
    {
      label: "Tipo",
      key: "name",

      renderData: (data: any) => {
        return (
          <div className="d-flex flex-column">
            <span>{data.name ?? getLabelBySlug(data.slug)}</span>
          </div>
        );
      },
    },
    {
      label: "Aplicável à",
      key: "Tipo",

      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-center ">
            <div>{renderType[data.type]}</div>
          </div>
        );
      },
    },
    {
      label: "Sua taxa",
      key: "Valor",

      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-center ps-2  ">
            <div>{formatCash(data.baas_tax)}</div>
          </div>
        );
      },
    },
    {
      label: "Taxa do cliente",
      key: "Valor",

      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-center ps-3">
            <div>
              R$ {formatNumberToCurrencyWithoutSimbol(data.customer_tax_out)}
            </div>
          </div>
        );
      },
    },
    {
      label: "",
      key: "",

      renderData: (data: any) => {
        return (
          <EditContainer className="me-5 d-flex hover-list-button edit-container">
            <div className="d-flex justify-content-start align-items-center">
              <>
                {" "}
                <Spacer left={10} />
                <div onClick={() => setTariffData(data)} className="d-flex">
                  <ImgEye
                    style={{ height: "20px" }}
                    id={`edit-${data.id}`}
                    src={PenIcon}
                    onClick={() => {
                      setStepIndexModal(1);

                      setOpenEditTariffModal(true);
                    }}
                  />
                  <Spacer left={10} />
                  <ImgDelete
                    style={{ height: "20px" }}
                    id={`delete-${data.id}`}
                    src={DeleteIcon}
                    onClick={() => {
                      setDeleteTariffModalData(data);

                      setOpenDeletePersonalizedTariffModal(true);
                    }}
                  />
                </div>
              </>
              <NotControlledToolTip placement="top" target={`edit-${data.id}`}>
                {"Editar"}
              </NotControlledToolTip>
              <NotControlledToolTip
                placement="top"
                target={`delete-${data.id}`}
              >
                {"Excluir"}
              </NotControlledToolTip>
            </div>
          </EditContainer>
        );
      },
    },
  ];

  const getTariffs = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs/",
        token: true,
        params: {
          only_custom: true,
        },
      });

      setTariffsData(data.results);
    } catch {}
  };

  useEffect(() => {
    getTariffs();
  }, []);

  return (
    <div className="pt-5">
      {tariffsData.length > 0 ? (
        <Table data={tariffsData} columns={columns} dataSpacer={10} />
      ) : (
        <div className="w-100  pt-3  d-flex flex-column align-items-center justify content-start">
          <Rounded className="rounded-circle">
            {" "}
            <img src={Timer} />
          </Rounded>

          <Spacer bottom={60} />
          <Title>{"   Nenhuma tarifa personalizada encontrada"}</Title>

          <Spacer bottom={100} />
        </div>
      )}
    </div>
  );
};

export default PersonalizedTariffs;
