import { ActivateCardSteps, ActivatingCardStatus, Card } from "~/hooks/Cards/interfaces";
import { _returnReceivedCardStepComponent, _returnSuccessActivateCardComponent } from "./activateCardReceivedStepFunctions";
import { useNavigate } from "react-router-dom";
import { ActivateCardImgsWrapper, ActivateCardNotReceivedCircle, ActivateCardNotReceivedDescription, ActivateCardNotReceivedImg, ActivateCardNotReceivedTitle, ActivateCardReceivedWrapperDiv, ActivateCardSuccessStepDiv } from "../styled";
import TimeImg from '~/assets/images/time_img.png'
import { Button } from "@zilliondigital/mirage-ui";


const ActivateCardNotReceivedStep = () => {
  const navigate = useNavigate()

  return(
    <ActivateCardSuccessStepDiv>
      <ActivateCardImgsWrapper>
        <ActivateCardNotReceivedCircle />
        <ActivateCardNotReceivedImg src={TimeImg}/>
      </ActivateCardImgsWrapper>
      <div className="d-flex flex-column gap-1">
        <ActivateCardNotReceivedTitle>
          Seu cartão está a caminho.
        </ActivateCardNotReceivedTitle>
        <ActivateCardNotReceivedDescription>
          Dentro de alguns dias você receberá seu cartão e poderá ativa-lo.
        </ActivateCardNotReceivedDescription>
      </div>
      <ActivateCardReceivedWrapperDiv>
        <Button 
          label={'Voltar'} 
          mode='main'
          style={{
            fontWeight: 400, 
            height: '100%', 
            width: '327px',
            paddingTop: '8px',
            paddingBottom: '8px'
          }}
          onClick={(e) => {
            navigate('/app/cards')
          }}
        />
      </ActivateCardReceivedWrapperDiv>
    </ActivateCardSuccessStepDiv>
  )
};

export default ActivateCardNotReceivedStep;
