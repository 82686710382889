import { AddBalanceOutput, Button, Spacer } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import useAddBalance from "~/hooks/useAddBalance";
import usePayments from "~/hooks/usePayments";
import useToast from "~/hooks/useToast";
import { formatNumberToCurrencyWithoutSimbol } from "~/utils";
import { ContainerDetails } from "../../app-content.styles";

const ReceiptPayment = () => {
  const { newChargeData, isPIX } = useAddBalance();
  const { showToast } = useToast();

  const { dataReceiptPayment } = usePayments();

  const handleRedirect = (url: string) => {
    url !== null && window.open(url, "_blank");
    url === null &&
      showToast(
        "Erro",
        "Não foi possível abrir o boleto pra impressão. Copie o código acima e cole em sua àrea de pagamentos",
        "error"
      );
  };

  return (
    <div className="p-4">
      <Spacer bottom={20} />
      <p className="ps-4">Seu pagamento foi concluído.</p>

      <ContainerDetails>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Valor</Description>
              </td>

              <td className="second-column">
                <Description>{`R$ ${formatNumberToCurrencyWithoutSimbol(
                  dataReceiptPayment?.data?.payment_amount
                )}`}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Descrição</Description>
              </td>

              <td className="second-column">
                <Description>
                  {dataReceiptPayment?.data?.description === ""
                    ? "Sem descrição"
                    : dataReceiptPayment?.data?.description}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Status</Description>
              </td>

              <td className="second-column">
                <Description>
                  {dataReceiptPayment?.data?.status_display}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Juros</Description>
              </td>

              <td className="second-column">
                <Description>{`R$ ${formatNumberToCurrencyWithoutSimbol(
                  dataReceiptPayment?.data?.interest
                )} `}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Multa</Description>
              </td>

              <td className="second-column">
                <Description>{`R$ ${formatNumberToCurrencyWithoutSimbol(
                  dataReceiptPayment?.data?.fine
                )} `}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Desconto</Description>
              </td>

              <td className="second-column">
                <Description>{`R$ ${formatNumberToCurrencyWithoutSimbol(
                  dataReceiptPayment?.data?.discount
                )} `}</Description>
              </td>
            </tr>
          </tbody>
        </table>
      </ContainerDetails>
      <Spacer bottom={20} />
      <div className="w-100 d-flex justify-content-end">
        <Spacer bottom={20} />
        <Button
          label="Vizualizar comprovante"
          mode="border"
          onClick={() => handleRedirect(dataReceiptPayment?.data?.voucher_url)}
        />
      </div>
    </div>
  );
};

export default ReceiptPayment;
