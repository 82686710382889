import {
  Button,
  Checkbox,
  CustomSelect,
  Input,
  InputCurrency,
  LateralModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import styled from "styled-components";
import theme from "~/themes/theme";
import * as Yup from "yup";
import useContacts from "~/hooks/Contacts/useContacts";
import { contactType } from "~/services/utils";

type FilterStatementsValues = {
  contact_name: string;
  identification: string;
  collectionAmountFrom: string;
  collectionAmountTo: string;
  dateOfIssuenceFrom: string;
  dateOfIssuenceTo: string;
  pending: boolean;
  overdue: boolean;
  canceled: boolean;
  paid: boolean;
  billet: boolean;
  subscription: boolean;
  payment_link: boolean;
};

const initialValues: FilterStatementsValues = {
  contact_name: "",
  identification: "",
  collectionAmountFrom: "",
  collectionAmountTo: "",
  dateOfIssuenceFrom: "",
  dateOfIssuenceTo: "",
  pending: false,
  overdue: false,
  canceled: false,
  paid: false,
  billet: false,
  subscription: false,
  payment_link: false,
};

const FormSchema = Yup.object().shape({
  identification: Yup.string(),
  collectionAmountFrom: Yup.string(),
  collectionAmountTo: Yup.string(),
  dateOfIssuenceFrom: Yup.string(),
  dateOfIssuenceTo: Yup.string(),
  pending: Yup.boolean(),
  overdue: Yup.boolean(),
  canceled: Yup.boolean(),
  paid: Yup.boolean(),
  billet: Yup.boolean(),
  subscription: Yup.boolean(),
  payment_link: Yup.boolean(),
});

const ContactsFilter = () => {
  const { openFilterModal, setOpenFilterModal } = useContacts();

  const handleFormSubmit = (values: FilterStatementsValues) => {
    return;
  };

  const { handleChange, setFieldValue, values, resetForm } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleFormSubmit,
  });

  return (
    <LateralModal
      open={openFilterModal}
      title="Filtros"
      toggleModal={() => setOpenFilterModal(!openFilterModal)}
      header
    >
      <div className=" w-100 vh-100 d-flex flex-column justify-content-start p-4">
        <form className="col-12">
          <div className="row ">
            <div className="col-12">
              <Input
                name="contact_name"
                value={values.contact_name}
                onChange={handleChange}
                type="text"
                label="Contato"
                placeholder="Digite um nome ou CPF/CNPJ"
              />
            </div>
            <div className="col-12">
              <CustomSelect options={contactType} />
            </div>
          </div>
        </form>

        <Spacer bottom={25} />
        <div className="w-100 d-flex justify-content-between ">
          <Button
            mode="phantom"
            label="Limpar tudo"
            onClick={() => {
              resetForm();
            }}
          />
          <Button mode="main" label="Aplicar filtros" />
        </div>
      </div>
    </LateralModal>
  );
};

export default ContactsFilter;
