import {
  BalanceAlert,
  Button,
  Checkbox,
  InteractiveModal,
  NotControlledToolTip,
  Spacer,
  SummaryPanel,
  Table,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { format, parseISO } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import EyeIcon from "~/assets/icons/eye-Size=MD.svg";
import EmptyListIcon from "~/assets/images/LoadingImages/Permissions.svg";
import { applyPagination } from "~/components/Paginator";
import Skeleton from "~/components/SkeletonTables";
import TitleModal from "~/components/Texts/TitleModal";
import { operationStatus } from "~/data/options";
import useAccount from "~/hooks/useAccount";
import useAuthority, { OPT_TYPES, OPT_TYPES_ICONS } from "~/hooks/useAuthority";

import { Columns } from "~/types";
import { formatCPFCNPJ, formatCash } from "~/utils";

import { isWeekDay, isWorkingHours } from "~/services/utils";
import theme from "~/themes/theme";
import usePermission from "~/hooks/usePermission";
import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";

const ConfirmTransactionsEquals = () => {
  const {
    operations,
    setCurrentModalData,
    currentModalData,
    setOpenOperationDetailModal,
    loadingApprove,
    approveOperation,
    loadingOperations: loadingList,
    setSelectedIds,
    selectedIds,
    getOperations,
    setTotalAmount,
    totalAmount,
    selectedTransaction,
    setSelectedTransaction,

    setCanApprove,

    setInsuficientBalance,

    canNotSign,
  } = useAuthority();

  const { transactionsEquals, setConfirmTransactionsEquals } = usePermission();

  const { balance, balanceFormatted } = useAccount();

  const handleApprove = async () => {
    setCanApprove(false);

    await approveOperation(selectedIds);
  };

  const handleToggleIds = (data: any) => {
    const voucherToAdd = data;

    if (selectedIds.includes(voucherToAdd.id)) {
      setSelectedIds(
        selectedIds.filter((voucher: string) => voucher !== voucherToAdd.id)
      );
      setSelectedTransaction(
        selectedTransaction.filter(
          (voucher: any) => voucher.id !== voucherToAdd.id
        )
      );
    } else {
      setSelectedIds([...selectedIds, voucherToAdd.id]);
      setSelectedTransaction([...selectedTransaction, voucherToAdd]);
    }
  };

  const totalSum = useMemo(() => {
    const validTransactions = selectedTransaction || [];

    return validTransactions.reduce((accumulator, transaction) => {
      const amount =
        transaction.bank_operation_type === "billet_payment"
          ? transaction?.operation_data?.payment_amount
          : transaction?.operation_data?.amount;

      return accumulator + amount;
    }, 0);
  }, [selectedTransaction, selectedIds]);

  useEffect(() => {
    setTotalAmount(totalSum);
  }, [selectedIds]);

  const columns: Columns[] = [
    {
      label: "",
      key: "status",

      renderColumn: (data: any) => (
        <div className="d-flex justify-content-start align-items-center">
          <Spacer left={10} />
          <div className="align-items-center d-flex" style={{ opacity: 0 }}>
            <Checkbox disabled />
          </div>
          <Spacer left={20} />

          {data.label}
        </div>
      ),

      renderData: (data: any) => (
        <div
          className="d-flex justify-content-start align-items-center"
          onClick={() => {
            handleToggleIds(data);
          }}
        >
          <div className="align-items-center d-flex ms-1">
            <Checkbox
              className="ps-1"
              checked={selectedIds.includes(data.id)}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Tipo",
      key: "bank_operation_type",
      renderData: (data: any) => (
        <div className="d-flex  align-items-center">
          <img src={OPT_TYPES_ICONS[data.bank_operation_type]} />
          <p className="ps-2 m-0">{OPT_TYPES[data.bank_operation_type]}</p>
        </div>
      ),
    },
    {
      label: "Status",
      key: "status",
      renderData: (data: any) => (
        <div className="d-flex flex-column " style={{ maxWidth: "150px" }}>
          {data.status === "error_retry" && (
            <p className="ps-3 text-danger">
              Ocorreu um erro. Tente novamente.
            </p>
          )}

          {data.status === "pending" && data.sign_weight === 0 && (
            <p className="ps-3">Aguardando aprovações</p>
          )}

          {data.sign_weight !== 0 && (
            <p className="ps-3">Em aprovação ({data.sign_weight}%)</p>
          )}
        </div>
      ),
    },
    {
      label: "Beneficiário",
      key: "% assinada",

      renderData: (data: any) => (
        <div className="d-flex flex-column">
          <p className="ps-3">
            {data.bank_operation_type === "pix_transfer"
              ? data?.operation_data?.receiver_account?.owner.name
              : data.bank_operation_type === "ted" ||
                data.bank_operation_type === "tev"
              ? data?.operation_data?.full_recipient?.name
              : data.bank_operation_type === "billet_payment" &&
                "Nome do beneficiário não encontrado"}
          </p>
          <Spacer bottom={-10} />
          <p className="ps-3">
            <b>
              {data.bank_operation_type === "pix_transfer"
                ? formatCPFCNPJ(
                    data?.operation_data?.receiver_account?.owner
                      .document_number
                  )
                : data.bank_operation_type === "ted" ||
                  data.bank_operation_type === "tev"
                ? formatCPFCNPJ(
                    data?.operation_data?.full_recipient?.document_number
                  )
                : data.bank_operation_type === "billet_payment" && ""}
            </b>
          </p>
        </div>
      ),
    },
    {
      label: "Data de emissão",
      key: "created",

      renderData: (data: any) => (
        <div>
          <p>
            {data.created
              ? format(parseISO(data.created.replace("Z", "")), "dd/MM/yyyy")
              : "---"}
          </p>
        </div>
      ),
    },
    {
      label: "Valor",
      key: "operation_data",

      renderData: (data: any) => (
        <div>
          <p>{data ? formatCash(data.operation_data.amount) : "0"}</p>
        </div>
      ),
    },
    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="buttons-box btn-icon-20">
            <Button
              id={`btn-detail-${data.id}`}
              mode="phantom"
              label=""
              iconPosition="right"
              onClick={() => {
                setCurrentModalData(data);
                setOpenOperationDetailModal(true);
              }}
              icon={EyeIcon}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (totalAmount > balance) {
      setInsuficientBalance(true);
    } else {
      setInsuficientBalance(false);
    }
  }, [balance, totalAmount]);

  return (
    <div>
      <div className="d-flex justify-content-between w-100 align-items-center">
        {" "}
        <div className="w-100">
          {" "}
          <Title bold={"bold"} color={theme.colors.error.main}>
            {" "}
            Operações Duplicadas Detectadas!
          </Title>
        </div>
        <div className="d-flex justify-content-end w-100 pt-3 pb-3">
          <Button
            label={"Cancelar"}
            onClick={() => {
              setConfirmTransactionsEquals(false);
            }}
            mode="border"
            danger
          />
          <Spacer left={10} />
          <Button
            label={"Continuar Aprovação"}
            mode="main"
            onClick={() => {
              setConfirmTransactionsEquals(false);
              setTimeout(() => {
                setCanApprove(true);
              }, 100);
            }}
          />{" "}
        </div>
      </div>
      <Spacer bottom={10} />
      <p style={{ color: theme.colors.error.main }}>
        Verifique as operações abaixo e selecione apenas as operações que
        gostaria de aprovar
      </p>

      <Table data={transactionsEquals} columns={columns} dataSpacer={8} />
    </div>
  );
};

export default ConfirmTransactionsEquals;
