import React, { useEffect } from "react";
import { CreateCardDeliveryStepDiv, CreatingCardConfirmationTablesWrapper, CreatingCardConfirmationContainer, CreateCardConfirmationFooterBtnWrapper, CreateCardSuccessStepDiv, CreateCardSuccessImg, CreateCardSuccessTitle, CreateCardSuccessWrapperDiv, CreateCardSuccessSubTitle } from "../styled";
import { ICreateCardInfos } from "~/hooks/Cards/initalValues";
import { CardTypeDict, CreateCardStep, CreatingCardStatus } from "~/hooks/Cards/interfaces";
import { Button, Table } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import { buildCardConfirmationColStatements } from "./createCardConfirmationColStatements";
import SuccessIcon from '~/assets/icons/status_icon--green.svg'

interface ICreateCardSuccessStep {
  creatingCardStatus: CreatingCardStatus;
  createCardInfos: ICreateCardInfos;
}
const CreateCardSuccessStep = (props:ICreateCardSuccessStep) => {
  const navigate = useNavigate()

  return (
    <CreateCardSuccessStepDiv>
      <CreateCardSuccessImg src={SuccessIcon}/>
      <CreateCardSuccessTitle>
        {
          props.createCardInfos.card_type == 'PLASTIC' ?
          'Cartão solicitado' :
          'Cartão virtual criado'
        }
      </CreateCardSuccessTitle>
      <CreateCardSuccessSubTitle>
        {
          props.createCardInfos.card_type == 'PLASTIC' ?
          'Seu cartão físico foi solicitado, em breve iniciaremos a produção e envio do mesmo.' :
          'Os dados do seu cartão ficam disponíveis na área "Meus cartões"'
        }
      </CreateCardSuccessSubTitle>
      <CreateCardSuccessWrapperDiv>
        <Button 
          label={'Voltar'} 
          mode='main'
          style={{
            fontWeight: 400, 
            height: '100%', 
            width: '327px',
            paddingTop: '8px',
            paddingBottom: '8px'
          }}
          onClick={(e) => {
            navigate('/app/cards')
          }}
        />
      </CreateCardSuccessWrapperDiv>
    </CreateCardSuccessStepDiv>
  );
};

export default CreateCardSuccessStep;
