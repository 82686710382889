import { Navigate } from "react-router-dom";
import useAuth from "~/hooks/useAuth";
import AppStorage from "~/services/storage";

type BackofficeProps = {
  children: JSX.Element;
};

const BackofficeRouter = ({ children }: BackofficeProps): JSX.Element => {
  const { user } = useAuth();

  if (user?.is_backoffice) {
    return children;
  } else {
    return <Navigate to="/auth/login" />;
  }
};

export default BackofficeRouter;
