import constate from "constate";
import { useState } from "react";
import ApiService from "~/services/api";
import usePix from "./usePix";
import useToken from "../useToken";
import useToast from "../useToast";
import useAccount from "../useAccount";

const steps = ["copy-and-paste", "send-pix"];

const [PayCopyAndPasteProvider, usePayCopyAndPaste] = constate(() => {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [step, setStep] = useState<string>(steps[stepIndex]);
  const [openSendPixModal, setOpenSendPixModal] = useState(false);
  const { getBalance } = useAccount();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [validation, setValidation] = useState<boolean>(false);
  const { showToast } = useToast();

  const { setToken } = useToken();

  const [isLoadingTransferReceipt, setIsLoadingTransferReceipt] =
    useState<boolean>(false);

  const [transferReceiptRender, setTransferReceiptRender] =
    useState<boolean>(false);

  const [dataTransferPixReceipt, setDataTransferPixReceipt] =
    useState<any>(null);

  const [
    openValidatorModalPixCopyAndPaste,
    setOpenValidatorModalPixCopyAndPaste,
  ] = useState<boolean>(false);

  const PayPixCopyandPaste = async (token: string) => {
    setOpenValidatorModalPixCopyAndPaste(false);
    setIsLoadingTransferReceipt(true);

    try {
      const { data } = await ApiService.HttpPost({
        route: "pix/qrcode/",
        token: true,
        body: {
          code: pixCopyAndPasteData.hash_code,
          token,
        },
        params: {
          code: pixCopyAndPasteData.hash_code,
        },
      });

      setDataTransferPixReceipt(data.data);
      setToken("");

      setTransferReceiptRender(true);

      setIsLoadingTransferReceipt(false);

      showToast(
        "Pix realizado com sucesso",
        "Sua transação foi enviada com sucesso.",
        "success"
      );

      setTimeout(() => {
        getBalance();
      }, 2000);
    } catch (error) {
      setIsLoadingTransferReceipt(false);
    }
  };

  const nextStep = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
      setStep(steps[stepIndex + 1]);
    }
  };

  const prevStep = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
      setStep(steps[stepIndex - 1]);
    }
  };

  const [pixCopyAndPasteData, setPixCopyAndPasteData] = useState<any>({});

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  return {
    step,
    stepIndex,
    steps,
    setStepIndex,
    setStep,
    nextStep,
    prevStep,
    openSendPixModal,
    setOpenSendPixModal,
    pixCopyAndPasteData,
    setPixCopyAndPasteData,
    isLoadingData,
    setIsLoadingData,
    PayPixCopyandPaste,
    openValidatorModalPixCopyAndPaste,
    setOpenValidatorModalPixCopyAndPaste,
    isLoadingTransferReceipt,
    setIsLoadingTransferReceipt,
    transferReceiptRender,
    setTransferReceiptRender,
    setDataTransferPixReceipt,
    dataTransferPixReceipt,
    isLoading,
    setIsLoading,
  };
});

export { PayCopyAndPasteProvider };

export default usePayCopyAndPaste;
