import "bootstrap/dist/css/bootstrap.min.css";
import { LateralModal, Table, Spacer } from "@zilliondigital/mirage-ui";

import useAuthority, { OPT_TYPES } from "~/hooks/useAuthority";
import styled from "styled-components";
import { format, parseISO } from "date-fns";
import { formatCash, formatCPFCNPJ } from "~/utils";
import { ContainerDetails } from "~/pages/App/app-content.styles";
import Title from "~/components/Texts/Title";
import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";

const ShowOperationModal = () => {
  const {
    currentModalData,
    openOperationDetailModal,
    setOpenOperationDetailModal,
    getAccountFromSign,
  } = useAuthority();

  const { mainAccount } = useAccount();

  return (
    <LateralModal
      open={openOperationDetailModal}
      header
      title="Detalhes da Operação"
      toggleModal={() => {
        setOpenOperationDetailModal(!openOperationDetailModal);
      }}
    >
      {currentModalData ? (
        <ContainerDetails>
          <div className="w-100 p-3">
            <Spacer top={15} />

            <table className=" w-100">
              <tbody>
                <tr>
                  <td>
                    <Description color="#666666">Tipo de transação</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {" "}
                      {OPT_TYPES[currentModalData.bank_operation_type]}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Valor</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {formatCash(currentModalData.operation_data.amount) ||
                        "---"}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Data de criação</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData.created
                        ? format(
                            parseISO(currentModalData.created),
                            "dd/MM/yyyy HH:mm:ss"
                          )
                        : "---"}
                    </Description>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Description color="#666666">Descrição</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {currentModalData.operation_data
                        ? currentModalData.operation_data?.description
                        : "---"}
                    </Description>
                  </td>
                </tr>
              </tbody>
            </table>

            <Spacer top={15} />

            {/* ORIGEM */}
            {currentModalData.bank_operation_type !== "billet_payment" ? (
              <>
                <Spacer top={15} />
                <Title>
                  <b>Origem</b>
                </Title>

                <table className="w-100">
                  <tbody>
                    <tr>
                      <td>
                        <Description color="#666666">Nome</Description>
                      </td>

                      <td className="second-column">
                        <Description>
                          {getAccountFromSign(currentModalData, false)?.name}
                        </Description>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Description color="#666666">Documento</Description>
                      </td>

                      <td className="second-column">
                        <Description>
                          {
                            getAccountFromSign(currentModalData, false)
                              ?.document_number
                          }
                        </Description>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Description color="#666666">Conta</Description>
                      </td>

                      <td className="second-column">
                        <Description>
                          {
                            getAccountFromSign(currentModalData, false)
                              ?.bank_account
                          }
                        </Description>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : null}

            {/* DESTINO */}
            {currentModalData.bank_operation_type !== "billet_payment" &&
            currentModalData.bank_operation_type !== "pix_lot_transfers" ? (
              <>
                <Spacer top={15} />
                <Title>
                  <b>Destino</b>
                </Title>

                <table className="w-100">
                  <tbody>
                    <tr>
                      <td>
                        <Description color="#666666">Nome</Description>
                      </td>

                      <td className="second-column">
                        <Description>
                          {getAccountFromSign(currentModalData)?.name}
                        </Description>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Description color="#666666">Documento</Description>
                      </td>

                      <td className="second-column">
                        <Description>
                          {
                            getAccountFromSign(currentModalData)
                              ?.document_number
                          }
                        </Description>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Description color="#666666">Conta</Description>
                      </td>

                      <td className="second-column">
                        <Description>
                          {getAccountFromSign(currentModalData)?.bank_account}
                        </Description>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : null}

            {/* DESTINOS DO LOTE */}
            {currentModalData.bank_operation_type === "pix_lot_transfers" &&
            currentModalData.operation_data.scheduled_operations ? (
              <>
                <Spacer top={15} />
                <Title>
                  <b>Destinos do Lote</b>
                </Title>
                <Spacer top={15} />
                <div className="table-responsive">
                  <table className="w-100">
                    <thead>
                      <th>Valor</th>
                      <th>Nome</th>
                      <th>Tipo</th>
                      <th>Chave</th>
                      <th>Conta Banc.</th>
                    </thead>
                    <tbody>
                      {currentModalData.operation_data.scheduled_operations.map(
                        (_line: any) => (
                          <tr>
                            <td>
                              <Description>
                                {formatCash(_line.row.amount)}
                              </Description>
                            </td>
                            <td>
                              <Description>{_line.row.name}</Description>
                            </td>

                            <td>
                              <Description>
                                {_line.row.transfer_type}
                              </Description>
                            </td>

                            <td>
                              <Description>
                                {_line.row.transfer_type === "CHAVE"
                                  ? _line.row.key
                                  : ""}
                              </Description>
                            </td>

                            <td>
                              <Description>
                                {_line.row.account || ""}
                              </Description>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}

            <Spacer top={20} />

            <table className="w-100">
              <tbody>
                <tr>
                  <td>
                    <Description color="#666666">Criado por</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {mainAccount?.bank_account_data.partner ===
                      currentModalData.owner_bank_account_data.partner
                        ? "Você"
                        : `${
                            currentModalData.owner_bank_account_data
                              .partner_data.name
                          } | ${formatCPFCNPJ(
                            currentModalData.owner_bank_account_data
                              .partner_data.document_number
                          )}`}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Status</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {!currentModalData.approved
                        ? "Aguardando aprovação"
                        : "Aprovado"}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">% de aprovação</Description>
                  </td>

                  <td className="second-column">
                    <Description>{currentModalData.sign_weight}</Description>
                  </td>
                </tr>
              </tbody>
            </table>

            <Spacer top={15} />

            <Title>
              <b>Lista dos Aprovadores</b>
            </Title>

            <Spacer top={5} />
            {!currentModalData.operation_approvers.length ? (
              <small>Ainda não há aprovadores...</small>
            ) : (
              <Table
                dataSpacer={10}
                columns={[
                  {
                    key: "",
                    label: "Aprovador",
                    renderData: (data: any) => {
                      return (
                        <>
                          {data.partner_data.name} -
                          {formatCPFCNPJ(data.partner_data.document_number)}
                        </>
                      );
                    },
                  },
                  {
                    key: "",
                    label: "Peso da assinatura",

                    renderData: (data: any) => {
                      return <>{data.sign_weight}%</>;
                    },
                  },
                ]}
                data={
                  currentModalData.operation_approvers
                    ? currentModalData.operation_approvers
                    : []
                }
              />
            )}
          </div>
        </ContainerDetails>
      ) : (
        <></>
      )}
    </LateralModal>
  );
};

export default ShowOperationModal;
