import styled, { keyframes } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  CustomSelect,
  Input,
  InteractiveModal,
  NotControlledToolTip,
  ReturnButton,
  Spacer,
  SummaryPanel,
  Table,
} from "@zilliondigital/mirage-ui";
import * as Yup from "yup";
import DeleteIcon from "~/assets/icons/delete.png";
import PenIcon from "~/assets/icons/contacts/pencil.png";
import MoreIcon from "~/assets/icons/buttons/more-icon.png";
import { Fragment, useEffect, useState } from "react";

import { quantityPages } from "~/data/options";

import { useNavigate } from "react-router-dom";

import Description from "~/components/Texts/Description";
import TitleModal from "~/components/Texts/TitleModal";
import Paginator, { applyPagination } from "~/components/Paginator";
import useToast from "~/hooks/useToast";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";

import useContacts from "~/hooks/Contacts/useContacts";

import ApiService from "~/services/api";

import TableLoader from "~/components/SkeletonTables";
import useAccount from "~/hooks/useAccount";
import Copyright from "~/components/Copyright";
import useCollections from "~/hooks/Collections/useCollections";
import useSplit from "~/hooks/useSplit";
import { useFormik } from "formik";
import { formatCPFCNPJ, formatNumberToCurrencyWithoutSimbol } from "~/utils";
import { getFirstLetters } from "~/services/utils";
import theme from "~/themes/theme";
import DeleteBeneficiarieModal from "./DeleteBeneficiarieModal";
import NewSplitModal from "../NewSplit/NewSplitModal";
import EditReceiversModal from "./EditReceivers/EditReceiversModal";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import {
  APIResponse,
  ApiResponse,
  Columns,
  editSplit,
  getcontacts,
} from "~/types";

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const GeneralContainerData = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  width: 100%;
  flex-grow: 1;

  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .container-return-button {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .container-auxiliar {
    position: fixed;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
`;

const Title = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;
const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
    /* Cor que deseja quando a imagem estiver completamente expandida */
  }
`;

const ImgEye = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

const ImgDelete = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.error.mainFilter};
  }
`;

const EditContainer = styled.div`
  position: relative;
  right: -25px;
`;

type EditSplitValues = {
  name: string;
  days: string;
};

type Types = {
  [key: string]: string;
};

const FormSchema = Yup.object().shape({
  name: Yup.string(),
});

const EditSplitStructure = () => {
  const navigate = useNavigate();

  const {
    splitData,
    page,
    rowsPerPage,
    setSplitData,
    setOpenDeleteBeneficiarieModal,
    setbeneficiarieData,
    setOpenNewSplitModal,
    setStepIndex,
    setIsEditing,
    setOpenEditReceiverModal,
    beneficiarieData,
  } = useSplit();

  const [selectedTypeValue, setSelectedTypeValue] = useState<string>(
    splitData.split_operation_mode
  );

  const hasOneBeneficiarie = splitData?.beneficiaries?.length === 1;

  const { showToast } = useToast();

  const { setContactsData, contactsData } = useContacts();

  const { setNewTransferTEDData } = useNewTransfer();

  const [isLoading, setIsloading] = useState<boolean>(false);

  const TypesSplit: Types = {
    fitbank: "Provedor",
    schedule: "Agendado",
    liquidation: "Imediato",
  };

  const renderDepositType: Types = {
    pix_transfer: "Transferência PIX",
    pix_manual_transfer: "Transferência PIX",
    tev: "TEV - Transferência entre contas",
    ted: "Tranferência TED",
  };

  const handleFormSubmit = (values: any) => {
    return;
  };

  const initialValues: EditSplitValues = {
    name: splitData.name,
    days: splitData.days_to_execute,
  };

  const { handleChange, setFieldValue, values, resetForm } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleFormSubmit,
  });

  const menusBreadCrumb = [
    {
      label: "Meu espaço",
      href: "/app/myspace",
      onClick: () => navigate("/app/myspace"),
    },
    {
      label: "Padrões de cobrança",
      href: "/app/myspace/collection-management",
      onClick: () => navigate("/app/myspace/collection-management"),
    },
    {
      label: "Split",
      href: "/split",
      onClick: () => navigate("/app/myspace/collection-management/split"),
    },
    { label: "Editar arranjo", href: "" },
  ];

  type optionsType = {
    label: string;
    value: string;
  };

  const optionsType: optionsType[] = [
    {
      label: "Imediato",
      value: "liquidation",
    },
    {
      label: "Agendado",
      value: "schedule",
    },
    {
      label: "Provedor",
      value: "fitbank",
    },
  ];

  const columns: Columns[] = [
    {
      label: "Recebedor",
      key: "receiver",

      renderData: (data: any) => {
        return (
          <div>
            <Description>{data?.contact_data?.name}</Description>
          </div>
        );
      },
    },
    {
      label: "Valor do split",
      key: "value",

      renderData: (data: any) => {
        return (
          <div>
            <Description>
              {data.value_type === "percentage"
                ? ` ${data.value}%`
                : ` R$ ${formatNumberToCurrencyWithoutSimbol(data.value)}`}
            </Description>
          </div>
        );
      },
    },
    {
      label: "Conta/Chave Pix",
      key: "account",

      renderData: (data: any) => {
        return (
          <div>
            {data.deposit_pix_key === null ? (
              <div className="d-flex flex-column">
                <Description>
                  {data.deposit_bank_account_data.bank.code} -{" "}
                  {data.deposit_bank_account_data.bank.name}
                </Description>

                <Description>
                  <b> Agência:</b> {data.deposit_bank_account_data.agency} |{" "}
                  <b> Conta:</b> {data.deposit_bank_account_data.account_number}
                  -{data.deposit_bank_account_data.account_number_digit}
                </Description>
              </div>
            ) : (
              <Description>
                {" "}
                <b>Chave:</b> {data.deposit_pix_key_data.key}
              </Description>
            )}
          </div>
        );
      },
    },
    {
      label: "Meio de pagamento",
      key: "Payment_type",

      renderData: (data: any) => {
        return (
          <div>
            <Description>{renderDepositType[data.deposit_type]}</Description>
          </div>
        );
      },
    },
    {
      label: "",
      key: "",

      renderData: (data: any) => {
        return (
          <div className="d-flex align-items-center ps-3">
            <div></div>

            <EditContainer className="me-5 d-flex hover-list-button edit-container">
              <div className="d-flex justify-content-start align-items-center">
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <ImgEye
                      style={{ height: "20px" }}
                      id={`edit-${data.id}`}
                      src={PenIcon}
                      onClick={() => {
                        getContact(data.contact);
                        setbeneficiarieData(data);
                        setOpenEditReceiverModal(true);
                      }}
                    />
                  </div>
                  <Spacer left={10} />
                  <div>
                    {hasOneBeneficiarie ? (
                      <img
                        id={`delete-${data.id}`}
                        style={{
                          height: "20px",
                          filter:
                            "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2819%) hue-rotate(190deg) brightness(118%) contrast(74%)",
                          cursor: "default",
                        }}
                        src={DeleteIcon}
                      />
                    ) : (
                      <ImgDelete
                        style={{ height: "20px" }}
                        id={`delete-${data.id}`}
                        src={DeleteIcon}
                        onClick={() => {
                          setbeneficiarieData(data);
                          setOpenDeleteBeneficiarieModal(true);
                        }}
                      />
                    )}
                  </div>
                </>
                <NotControlledToolTip
                  placement="top"
                  target={`delete-${data.id}`}
                >
                  {hasOneBeneficiarie
                    ? "Não é possível excluir o único beneficiário do arranjo."
                    : "Excluir"}
                </NotControlledToolTip>

                <NotControlledToolTip
                  placement="top"
                  target={`edit-${data.id}`}
                >
                  {"Editar"}
                </NotControlledToolTip>
              </div>
            </EditContainer>
          </div>
        );
      },
    },
  ];

  const handleChangeSelect = (optionSelected: any) => {
    setSelectedTypeValue(optionSelected.target.value);
  };

  const getContact = async (id: number) => {
    try {
      const { data } = await ApiService.HttpGet<APIResponse<getcontacts>>({
        route: "partner/",
        token: true,
        params: {
          type: "contact",
          default: false,
        },
      });
      setContactsData(data.results);

      const selectedContact = contactsData.filter(
        (contactsData: any) => contactsData.id === id
      );

      setNewTransferTEDData(selectedContact[0]);
    } catch {}
  };

  const EditSplit = async () => {
    setIsloading(true);
    try {
      const { data } = await ApiService.HttpPut<ApiResponse<editSplit>>({
        route: `charge/split/${splitData.id}/`,
        token: true,
        body:
          selectedTypeValue === "schedule"
            ? {
                id: splitData.id,
                partner: splitData.partner,
                split_operation_mode: selectedTypeValue,
                name: values.name,
                days_to_execute: Number(values.days),
              }
            : {
                id: splitData.id,
                partner: splitData.partner,
                split_operation_mode: selectedTypeValue,
                name: values.name,
              },
      });

      setIsloading(false);
      resetForm();

      navigate("/app/myspace/collection-management/split");
      showToast("Dados atualizados com sucesso", "", "success");
    } catch (e) {
      setIsloading(false);
      showToast("Ocorreu um erro ao tentar atualizar os dados", "", "error");
    }
  };

  useEffect(() => {
    const data: any = localStorage.getItem("edit_split_data");

    const dataParsed = JSON.parse(data);

    setSplitData(dataParsed);

    setFieldValue("name", splitData.name);

    setIsEditing(true);
  }, []);

  return (
    <ParentContainer>
      <Fragment>
        <GeneralContainerData>
          <div className="d-flex flex-column container-fluid w-100 h-100 ps-5 pe-5">
            <Spacer top={30} />
            <Breadcrumb
              menus={menusBreadCrumb}
              onClickHome={() => navigate("/app/dashboard")}
            />
            <Spacer top={30} />

            <ContainerData className="container-fluid">
              <div className="d-flex justify-content-between p-4 ">
                <Title>Editar arranjo {splitData.name}</Title>

                <div>
                  <Button
                    mode="main"
                    label="Salvar alterações"
                    loading={isLoading}
                    onClick={EditSplit}
                    disabled={
                      selectedTypeValue === "schedule"
                        ? !values.days || values.days === "0" || !values.name
                        : !values.name
                    }
                  />
                  <div className="d-flex flex-column flex-lg-row"></div>
                </div>
              </div>
              <div className="pe-4 ps-4">
                <div className="d-flex flex-column ">
                  <div className="d-flex flex-column flex-lg-row  align-items-end w-50">
                    <Input
                      name="name"
                      label="Nome do arranjo"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <Spacer left={10} />
                    <CustomSelect
                      isSearchable={false}
                      options={optionsType}
                      onChange={handleChangeSelect}
                      value={{
                        label: TypesSplit[selectedTypeValue],
                        value: splitData.split_operation_mode,
                      }}
                    />
                  </div>
                  <Spacer bottom={-10} />
                  <div className="w-50">
                    {selectedTypeValue === "schedule" && (
                      <Input
                        name="days"
                        value={values.days}
                        mask="9999"
                        onChange={handleChange}
                        error={
                          values?.days === "0" &&
                          "Vamor mínimo de dias para a distribuição deve ser maior que 0"
                        }
                        required
                        type={"text"}
                        label="Após quantos dias a distribuição deve ocorrer?"
                      />
                    )}
                  </div>
                </div>

                <Spacer bottom={20} />
                <div className="d-flex w-100 align-items-start  ">
                  <div className=""></div>
                  <Spacer left={15} />
                </div>
              </div>
              <div className="ps-4">
                <Button
                  label="Adicionar recebedor"
                  icon={MoreIcon}
                  iconColorFilter={theme.colors.primary.main}
                  mode="border"
                  onClick={() => {
                    setOpenNewSplitModal(true);
                    setStepIndex(1);
                  }}
                />
              </div>
              <Spacer bottom={20} />
              <div className="ps-4">
                <Table
                  data={applyPagination(splitData.beneficiaries, {
                    page,
                    rowsPerPage,
                  })}
                  columns={columns}
                  dataSpacer={10}
                />
              </div>
              <Spacer bottom={20} />
            </ContainerData>
            <Spacer bottom={20} />
            <Copyright />

            <Spacer top={30} />
          </div>
        </GeneralContainerData>
      </Fragment>
      <DeleteBeneficiarieModal />
      <NewSplitModal />
      <EditReceiversModal />
    </ParentContainer>
  );
};

export default EditSplitStructure;
