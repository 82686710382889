import {
  Button,
  CustomSelect,
  Input,
  InputCurrency,
  InteractiveModal,
  LateralModal,
  RadioGroup,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { format, subDays } from "date-fns";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { data } from "~/components/Chart";
import useCollections from "~/hooks/Collections/useCollections";
import useAccount from "~/hooks/useAccount";
import useAddBalance from "~/hooks/useAddBalance";
import useToast from "~/hooks/useToast";
import ApiService from "~/services/api";
import { convertStringToNumber, formatCash } from "~/utils";

import { TitleModal } from "~/pages/Authentication/Auth.styles";
import Description from "~/components/Texts/Description";

type setBaixa = {
  id: number;
  type: string;
  amount: number;
  due_date?: any;
  deadline_date?: any;
  description?: any;
  created_date: string;
  billet: number;
  partner: number;
  type_display: string;
};

type FormValues = {
  amount: number;
  instruction: string;
  due_date: string;
  deadline_date: string;
};

const initialValues: FormValues = {
  amount: 0,
  instruction: "",
  due_date: "",
  deadline_date: "",
};

const FormSchema = Yup.object().shape({
  amount: Yup.string(),
  instruction: Yup.string(),
  due_date: Yup.string()
    .required("Campo obrigatório")
    .test("valid-date", "Data de vencimento inválida", (value) => {
      if (!value) {
        return false;
      }

      const currentDate = new Date();
      const selectedDate = new Date(value);

      return selectedDate >= currentDate;
    }),
  deadline_date: Yup.string()
    .required("Campo obrigatório")
    .test("valid-deadline-date", "Data limite inválida", function (value) {
      const { due_date } = this.parent;

      if (!value || !due_date) {
        return false;
      }

      const dueDate: any = new Date(due_date);
      const selectedDate = new Date(value);

      return selectedDate >= dueDate;
    }),
});

const EditCollectionGroup = () => {
  const {
    openEditInGroup,

    setOpenEditInGroup,
    dataBillet,
    setOpenDetailModal,
    getBillets,
    setIsFilteredResults,
    setIsBaixa,
    showContent,
    setShowContent,
    selectedValue,
    setOpenConfirmModal,
    openConfirmModal,
    setSelectedValue,
    selectedBillets,
    selectedIds,
    renderInitialContent,
    setRenderInitialContent,
  } = useCollections();
  const { showToast } = useToast();
  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { verifyPermission, beforeDays } = useAccount();

  const canEditBillet = verifyPermission("ba_can_edit_billet");
  const { isEmpty, setIsEmpty } = useAddBalance();

  function handleChangeTille(value: any) {
    setSelectedValue(value);

    if (value === "0") {
      setSelectedValue("baixa");
      setIsEmpty(false);
    }

    if (value === "1") {
      setSelectedValue("cancel");
      setIsEmpty(false);
    }
    if (value === "2") {
      setSelectedValue("edit");
      setIsEmpty(false);
    }
  }

  type Title = {
    [key: string]: string;
  };

  useEffect(() => {
    if (selectedValue === "baixa" && showContent) {
      setIsBaixa(true);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (!openEditInGroup) {
      setSelectedValue("");
      setShowContent(false);
    }
  }, [openEditInGroup]);

  useEffect(() => {
    setSelectedValue("");
  }, []);

  const radioOptions: any = [
    {
      title: "Baixa",
      description: "De baixa maunal nos boletos por receber pagamento direto",
      value: "0",
      disabled: selectedBillets?.some(
        (billet: any) => billet.status === "done"
      ),
    },
    {
      title: "Cancelar",
      description: "Cancele seus boletos gerados antes do pagamento",
      value: "1",
      disabled: selectedBillets?.some(
        (billet: any) =>
          billet.status === "canceled" ||
          billet.status === "canceling" ||
          billet.status === "done"
      ),
    },
    {
      title: "Alterar Vencimento",
      description: "Edite a data de vencimento dos boletos",
      value: "2",
      disabled: selectedBillets?.some(
        (billet: any) =>
          billet.status === "canceled" ||
          billet.status === "canceling" ||
          billet.status === "done"
      ),
    },
  ];

  const secondRadioOptions = [
    {
      title: "Gerar relatório (PDF)",
      description: "Gere relatório",
      value: "0",
      disabled: true,
    },
    {
      title: "Gerar relatório XLSX",
      description: "Edite a data de vencimento do boleto",
      value: "1",
    },
    {
      title: "Baixar boleto",
      description: "Edite a data de vencimento do boleto",
      value: "2",
    },
  ];

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    touched,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    onSubmit: (values) => {},
  });

  const handleToggleModal = () => {
    setOpenEditInGroup(false);
    setShowContent(false);
    setIsBaixa(false);
    setSelectedValue("");
    setRenderInitialContent(false);
    resetForm();
  };

  const renderContent: any = {
    edit: (
      <div className="row">
        <div className="col-6">
          <Input
            type="date"
            label="Data de vencimento"
            name="due_date"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.due_date}
            required
            error={
              touched.due_date &&
              errors.due_date &&
              "A data de vencimento não pode ser menor ou igual a data atual"
            }
          />
        </div>

        <div className="col-6">
          <Input
            type="date"
            label="Data limite para pagamento"
            name="deadline_date"
            onChange={handleChange}
            value={values.deadline_date}
            onBlur={handleBlur}
            error={
              touched.deadline_date &&
              errors.deadline_date &&
              "A data limite não pode ser menor do que a data de vencimento"
            }
            required
          />
        </div>

        <div className="d-flex w-100 justify-content-between">
          <Button
            mode="phantom"
            label="Cancelar"
            onClick={() => {
              setShowContent(false);
              setIsBaixa(false);
              setSelectedValue("");
            }}
          />
          <Button
            mode="main"
            label="Concluir"
            onClick={() => {
              setOpenConfirmModal(true);
            }}
            disabled={values.due_date === "" || values.deadline_date === ""}
          />
        </div>
      </div>
    ),

    baixa: (
      <>
        <InputCurrency
          fontSize="normal"
          inputSize="large"
          label="Valor"
          name="amount"
          value={String(values.amount)}
          onChange={handleChange}
        />
        <Input
          type="textarea"
          label="Descrição (opicional)"
          name="instruction"
          value={values.instruction}
          onChange={handleChange}
        />
        <div className="w-100 d-flex justify-content-between pt-5">
          <Button
            mode="phantom"
            label="Cancelar"
            onClick={() => {
              setShowContent(false);
              setIsBaixa(false);
              setSelectedValue("");
            }}
          />
          <Button
            mode="main"
            label="Avançar"
            onClick={() => {
              setOpenConfirmModal(true);
            }}
            disabled={!values.amount || values.amount === 0}
          />
        </div>
      </>
    ),
  };

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  const setBaixaBillets = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost<setBaixa>({
        route: "charge/batch-payment/",
        body: {
          amount: convertStringToNumber(values.amount as any),
          description: values.instruction,
          billets_ids: selectedIds,
        },
        token: true,
      });
      setIsLoading(false);
      getBillets({ from_date: fromDate, to_date: todayFormatted });
      setSelectedValue("");
      setShowContent(false);
      setOpenDetailModal(false);
      setOpenEditInGroup(false);
      showToast("Baixas efetuadas com sucesso!", "", "success");
      setOpenConfirmModal(false);
    } catch {
      setIsLoading(false);
      setSelectedValue("");
      setShowContent(false);
      setOpenConfirmModal(false);
    }
  };

  const cancelBillets = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost({
        route: "charge/batch-cancel/",
        body: {
          billets_ids: selectedIds,
        },
        token: true,
      });

      if (data.data.failed.length > 0 && data.data.success.length === 0) {
        setIsLoading(false);
        showToast(
          "Os boletos não foram cancelados",
          "Tente novamente mais tarde",
          "error"
        );

        setOpenConfirmModal(false);
      } else if (
        data.data.success.length > 0 &&
        data.data.failed.length === 0
      ) {
        handleToggleModal();
        setIsLoading(false);
        showToast(
          "Pedido de cancelamento em lote efetuado com sucesso",
          "",
          "success"
        );

        setOpenConfirmModal(false);

        getBillets({ from_date: fromDate, to_date: todayFormatted });
        setIsFilteredResults(false);
      } else if (data.data.success.length > 0 && data.data.failed.length > 0) {
        handleToggleModal();
        setIsLoading(false);
        showToast(
          "Pedido de cancelamento em lote efetuado com sucesso",
          "",
          "success"
        );
        showToast("Atenção alguns boletos não foram cancelados", "", "warning");

        setOpenConfirmModal(false);

        getBillets({ from_date: fromDate, to_date: todayFormatted });
        setIsFilteredResults(false);
      }
    } catch (e: any) {
      setIsLoading(false);
      handleToggleModal();
      setOpenConfirmModal(false);

      setShowContent(false);
      setSelectedValue("");
    }
  };

  const EditDueDateBillet = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost({
        route: "/charge/batch-due-date/",
        body: {
          description: "Alteração de vencimento em lote",
          billets_ids: selectedIds,
          deadline_date: values.deadline_date,
          due_date: values.due_date,
        },
        token: true,
      });

      if (data.data.failed.length > 0 && data.data.success.length === 0) {
        setIsLoading(false);
        showToast("Os boletos não podem ser alterados", "", "error");

        setOpenConfirmModal(false);
      } else if (
        data.data.success.length > 0 &&
        data.data.failed.length === 0
      ) {
        handleToggleModal();
        setOpenConfirmModal(false);

        setIsLoading(false);
        setOpenDetailModal(false);
        showToast(
          "Pedido de alteração de vencimento em lote concluído",
          "A alteração pode levar alguns minutos para ser concluída",
          "success",
          () => null,
          true,
          200000
        );

        getBillets({ from_date: fromDate, to_date: todayFormatted });
        setIsFilteredResults(false);
      } else if (data.data.success.length > 0 && data.data.failed.length > 0) {
        handleToggleModal();
        setOpenConfirmModal(false);

        setIsLoading(false);
        setOpenDetailModal(false);
        showToast(
          "Pedido de alteração de vencimento em lote concluído",
          "A alteração pode levar alguns minutos para ser concluída",
          "success",
          () => null,
          true,
          200000
        );

        showToast(
          "Alguns boletos não foram alterados",
          "",
          "warning",
          () => null,
          true,
          200000
        );

        getBillets({ from_date: fromDate, to_date: todayFormatted });
        setIsFilteredResults(false);
      }
    } catch {
      setIsLoading(false);
      setSelectedValue("");
      setShowContent(false);
    }
  };

  const titleDict: { [key: string]: string } = {
    baixa: "Baixa (lote)",
    edit: "Alterar vencimento ",
    cancel: "Cancelamento (lote)",
  };

  const titleConfirmDict: { [key: string]: string } = {
    baixa: `Baixa (lote)`,
    edit: `Alteração de vencimento (lote)`,
    cancel: `Cancelamento (lote)`,
  };

  const descriptionConfirmDict: { [key: string]: string } = {
    baixa: `Deseja confirmar a baixa de ${
      selectedBillets?.length
    } boletos no valor de R$ ${formatCash(values.amount)} cada ?`,
    edit: `Deseja confirmar a alteração de vencimento de  ${selectedBillets?.length} boletos?`,
    cancel: `Deseja confirmar o cancelamento de  ${selectedBillets?.length} boletos?`,
  };

  return (
    <>
      <LateralModal
        toggleModal={() => handleToggleModal()}
        open={openEditInGroup}
        header
        title={
          !showContent
            ? ` Editar (lote) - ${selectedBillets?.length} boletos`
            : showContent && selectedValue !== ""
            ? `${titleDict[selectedValue]} - ${selectedBillets?.length} boletos `
            : ""
        }
      >
        <div className="p-4 vh-100 ">
          {!showContent && renderInitialContent && (
            <>
              <RadioGroup
                // emptyValue={isEmpty}
                display="row"
                options={radioOptions}
                wrap
                onChange={(value) => handleChangeTille(value)}
              />
              {/* <Spacer bottom={10} />
            <RadioGroup
              // emptyValue={isEmpty}
              display="row"
              options={secondRadioOptions}
              wrap
              onChange={(value) => handleChangeTille(value)}
            /> */}
              <div className="d-flex w-100 justify-content-between pt-5">
                <Button
                  mode="border"
                  label={"Cancelar"}
                  onClick={handleToggleModal}
                />
                <Button
                  mode="main"
                  label="Avançar"
                  onClick={() => {
                    if (selectedValue === "cancel") {
                      setOpenConfirmModal(true);
                    } else {
                      setShowContent(true);
                    }
                  }}
                  disabled={selectedValue === ""}
                />
              </div>
            </>
          )}

          {selectedValue !== "cancel" &&
            showContent &&
            renderContent[selectedValue]}
        </div>
      </LateralModal>
      <InteractiveModal
        open={openConfirmModal}
        size="md"
        toggleModal={() =>
          isLoading ? null : setOpenConfirmModal(!openConfirmModal)
        }
      >
        <div className="p-4 w-100">
          <TitleModal className="text-center">
            {titleConfirmDict[selectedValue]}
          </TitleModal>
          <Spacer bottom={10} />
          <p style={{ fontSize: "22px" }} className="text-center ">
            {" "}
            {descriptionConfirmDict[selectedValue]}
          </p>
          <Spacer bottom={70} />
          <Button
            mode="main"
            label="Sim, confirmar"
            full
            loading={isLoading}
            onClick={() => {
              if (selectedValue === "baixa") {
                setBaixaBillets();
              } else if (selectedValue === "edit") {
                EditDueDateBillet();
              } else if (selectedValue === "cancel") {
                cancelBillets();
              }
            }}
          />
          <Spacer bottom={10} />
          <Button
            mode="border"
            label="Não, cancelar"
            full
            onClick={() => setOpenConfirmModal(false)}
            disabled={isLoading}
          />
        </div>
      </InteractiveModal>
    </>
  );
};

export default EditCollectionGroup;
