import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Breadcrumb,
  Button,
  Spacer,
  ButtonDropdown,
  InteractiveModal,
  NotControlledToolTip,
} from "@zilliondigital/mirage-ui";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import {
  ContainerData,
  GeneralContainer,
} from "~/pages/App/app-content.styles";

import usePermission from "~/hooks/usePermission";
import NewConcessionModal from "./NewConcessionModal";
import TitleModal from "~/components/Texts/TitleModal";
import DeleteIcon from "~/assets/icons/delete-icon-small.png";
import UserIcon from "~/assets/icons/user.svg";
import Skeleton from "~/components/SkeletonTables";
import Copyright from "~/components/Copyright";
import { formatCPFCNPJ } from "~/utils";

const ConcessionsStructure = () => {
  const {
    getConcessions,
    grantedForMeList,
    grantedForOthersList,
    removeConcession,
    submitConcession,
    setOpenNewConcessionModal,
    setCurrentConcession,
    currentConcession,
    setIsEditPermission,
    loadingList,
    getPermissions,
  } = usePermission();

  const navigate = useNavigate();
  const [canRemove, setCanRemove] = useState<boolean>(false);

  const handleRemove = async () => {
    setCanRemove(false);
    removeConcession(currentConcession.id);
  };

  const menusBreadCrumb = [
    { label: "Permissões", onClick: () => navigate("/app/permissions") },
    { label: "Atribuição de permissões", href: "app/payments" },
  ];

  // Concedidos a outros
  const columnsOthers: any = [
    {
      label: "Cedido para",
      key: "",
      renderData: (data: any) => data.owner.name,
    },

    {
      label: "CPF/CNPJ",
      key: "",
      renderData: (data: any) => `${formatCPFCNPJ(data.owner.document_number)}`,
    },
    {
      label: "Proprietário",
      key: "owner",
      renderData: (data: any) => data.bank_account_data.owner.name,
    },

    {
      label: "Grupo",
      name: "",
      renderData: (data: any) => data.group_data?.name || "---",
    },
    {
      label: "Papel",
      name: "",
      renderData: (data: any) => data?.role_display || "---",
    },
    {
      label: "Peso",
      name: "",
      renderData: (data: any) =>
        data?.sign_weight ? `${data?.sign_weight}%` : "0% (Visualizador)",
    },
    {
      label: "Permissão",
      name: "",
      renderData: (data: any) => data.permissions_role?.name || "---",
    },
    {
      label: "",
      name: "",
      renderData: (data: any) => {
        return (
          <div className="buttons-box btn-icon-30">
            <Button
              id={`btn-remove-${data.id}`}
              mode="phantom"
              label=""
              iconPosition="right"
              onClick={() => {
                setCanRemove(true);
                setCurrentConcession(data);
              }}
              icon={DeleteIcon}
            />
            <NotControlledToolTip
              placement="top"
              target={`btn-remove-${data.id}`}
            >
              Remover
            </NotControlledToolTip>
          </div>
        );
      },
    },
  ];

  // Concedidos a mim
  const columnsMe: any = [
    {
      label: "Proprietário",
      key: "owner",
      renderData: (data: any) => `${data.bank_account_data.owner.name}`,
    },

    {
      label: "Conta",
      key: "",
      renderData: (data: any) =>
        `${data.bank_account_data.account_number}-${data.bank_account_data.account_number_digit}`,
    },

    {
      label: "Cedido para",
      key: "",
      renderData: (data: any) => data.owner.name,
    },
    {
      label: "Grupo",
      name: "",
      renderData: (data: any) => data.group_data?.name || "---",
    },
    {
      label: "Papel",
      name: "",
      renderData: (data: any) => data?.role_display || "---",
    },
    {
      label: "Peso",
      name: "",
      renderData: (data: any) =>
        data?.sign_weight ? `${data?.sign_weight}%` : "Apenas visualizador",
    },
    {
      label: "Permissão",
      name: "",
      renderData: (data: any) => data.permissions_role?.name || "---",
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getConcessions(false);
    getPermissions();
  }, []);

  return (
    <GeneralContainer>
      <div className="d-flex flex-column container-fluid w-100 ps-5 pe-5">
        <Spacer top={30} />
        <Breadcrumb
          menus={menusBreadCrumb}
          onClickHome={() => navigate("/app/dashboard")}
        />

        <Spacer top={30} />
        <ContainerData className="ps-4 pe-4">
          <div className="d-flex justify-content-between p-4">
            <Title>Atribuição de permissões</Title>

            <div>
              <div className="d-flex flex-column flex-lg-row">
                <Button
                  mode="main"
                  label="Criar concessão"
                  onClick={() => {
                    setOpenNewConcessionModal(true);
                  }}
                />

                <Spacer right={10} />
                <div className="d-block d-lg-none">
                  <Spacer bottom={10} />
                </div>
              </div>
            </div>
          </div>

          <div className="pe-4 ps-4">
            <Spacer bottom={20} />

            {/* MAIN */}

            {/* <Title className="">Concedido a outros</Title> */}

            {loadingList ? (
              <Skeleton />
            ) : grantedForOthersList.length === 0 ? (
              <div className="empty-table-box">
                <img src={UserIcon} />
                <span>
                  Nenhuma concessão a outros cadastrada <br />
                  até o momento
                </span>
              </div>
            ) : (
              <>
                <Table
                  data={grantedForOthersList}
                  columns={columnsOthers}
                  dataSpacer={8}
                />
              </>
            )}

            {/* REMOVE MODAL */}
            <InteractiveModal
              open={canRemove}
              title="exclusao"
              size="md"
              toggleModal={() => setCanRemove(!canRemove)}
            >
              <div className="p-4 h-100 d-flex h-100 text-center justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
                <TitleModal>Remover concessão</TitleModal>
                <div className="d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
                  <p>Deseja realmente remover essa concessão</p>
                  <div style={{ width: "290px" }} className="mb-5">
                    <Spacer bottom={5} />
                    <Button
                      mode="main"
                      label="Sim, remover!"
                      full
                      onClick={() => {
                        handleRemove();
                      }}
                      disabled={submitConcession}
                      loading={submitConcession}
                    />
                    <Spacer bottom={5} />
                    <Button
                      mode="border"
                      label="Cancelar"
                      full
                      onClick={() => setCanRemove(false)}
                    />
                  </div>
                </div>
              </div>
            </InteractiveModal>
          </div>

          <Spacer bottom={20} />

          <Spacer top={30} />
        </ContainerData>

        <Spacer top={40} />

        <Copyright />

        <Spacer top={30} />
      </div>
      <NewConcessionModal />
    </GeneralContainer>
  );
};

export default ConcessionsStructure;
