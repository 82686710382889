import { ActivateCardCheckingImg, ActivateCardCheckingStepDiv, ActivateCardTitle, ActivateCardContainer, CardsDashboardHeader, ActivateCardReceivedWrapperDiv, ActivateCardInputWrapper, BlockCardInputWrapper, BlockCardDescription, ActivateCardSuccessTitle, ActivateCardSuccessStepDiv } from "../styled";
import { Button, InputToken, Spacer } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import SuccessIcon from '~/assets/icons/status_icon--green.svg'
import { BlockCardSteps } from "~/hooks/Cards/interfaces";


const BlockCardBlockedStep = () => {
  const navigate = useNavigate()

  return(
    <ActivateCardSuccessStepDiv>
      <ActivateCardCheckingImg src={SuccessIcon}/>
      <ActivateCardSuccessTitle>
        Seu cartão foi bloqueado com sucesso!
      </ActivateCardSuccessTitle>
      <ActivateCardReceivedWrapperDiv>
        <Button 
          label={'Voltar'} 
          mode='main'
          style={{
            fontWeight: 400, 
            height: '100%', 
            width: '327px',
            paddingTop: '8px',
            paddingBottom: '8px'
          }}
          onClick={(e) => {
            navigate('/app/cards')
          }}
        />
      </ActivateCardReceivedWrapperDiv>
    </ActivateCardSuccessStepDiv>
  )
};

export default BlockCardBlockedStep;
