import styled, { keyframes } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  Input,
  Spacer,
  TabMenu,
} from "@zilliondigital/mirage-ui";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Paginator from "~/components/Paginator";
import useContacts from "~/hooks/Contacts/useContacts";
import ApiService from "~/services/api";
import Copyright from "~/components/Copyright";
import useTariffs from "~/hooks/useTariffs";
import { PackpageTypes } from "~/types";
import { useFormik } from "formik";
import DeleteHolderModal from "./DeleteHolderModal";
import useToast from "~/hooks/useToast";
import ModalAddClients from "./ModalAddClients";

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const GeneralContainerData = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .container-return-button {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .container-auxiliar {
    position: fixed;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
`;

const Title = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

type typeValues = {
  name: string;
};

const initialValues: typeValues = {
  name: "",
};

const EditPackpages = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const {
    setEditPackpageData,
    editPackpageData,
    setTariffsData,
    setOpenAddClient,
  } = useTariffs();

  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  const { showToast } = useToast();

  const { contactsData, rowsPerPage, page, setPage } = useContacts();
  const location = useLocation();

  const customersRender = location.pathname.includes("customers");

  const { values, setFieldValue, handleChange } = useFormik({
    onSubmit: () => undefined,
    initialValues: initialValues,
  });

  const menus: { label: string; value: number; onClick: () => void }[] = [
    {
      label: "Tarifas",
      value: 1,
      onClick: () => {
        navigate(`/app/myspace/tariffs/edit/${params.id}/tariffs`);
      },
    },
    {
      label: "Correntistas",
      value: 2,
      onClick: () => {
        navigate(`/app/myspace/tariffs/edit/${params.id}/customers`);
      },
    },
  ];

  const handleUpdateDefault = () => {
    navigate(`/app/myspace/tariffs/`);

    showToast("Cesta atualizada com sucesso", "", "success");
  };

  const fethPackpageToEditData = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/",
        token: true,
      });

      const dataFiltered = data.results.filter(
        (packpage: PackpageTypes) => packpage.id === Number(params.id)
      );

      setFieldValue("name", dataFiltered[0]?.name);

      setEditPackpageData(dataFiltered);
    } catch {}
  }, [params.id, setEditPackpageData]);

  const fethPackpageUpdate = useCallback(async () => {
    setLoadingUpdate(true);
    try {
      const dataEdit = await ApiService.HttpPut({
        route: `backoffice/tariffs-package/${params.id}/`,
        body: {
          name: values.name,
        },
        token: true,
      });

      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs/",
        token: true,
      });

      setTariffsData(data.results);

      setLoadingUpdate(false);
      navigate(`/app/myspace/tariffs`);

      showToast("Cesta atualizada com sucesso", "", "success");
    } catch (e: any) {
      setLoadingUpdate(false);
      showToast("Ops, ocorreu um erro", e.message, "error");
    }
  }, [params.id, setEditPackpageData, values.name]);

  useEffect(() => {
    fethPackpageToEditData();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [params.id]);

  useEffect(() => {
    setFieldValue("name", editPackpageData[0]?.name);
  }, [editPackpageData]);

  const menusBreadcrumb = [
    {
      label: "Meu Espaço",
      href: "",
      onClick: () => navigate("/app/myspace/"),
    },
    {
      label: "Tarifas",
      href: "",
      onClick: () => navigate("/app/myspace/tariffs"),
    },
    {
      label: "Editar Cesta",
      href: "",
    },
  ];

  return (
    <ParentContainer>
      {editPackpageData.length > 0 ? (
        <Fragment>
          <GeneralContainerData>
            <div className="d-flex flex-column container-fluid w-100 h-100 ps-4 pe-4">
              <Spacer top={30} />
              <Breadcrumb menus={menusBreadcrumb} />
              <Spacer top={30} />
              <ContainerData className="container-fluid ps-4 pe-4">
                <div className="d-flex justify-content-between pt-4 ">
                  <Title>
                    {` Editar cesta de tarifas ${editPackpageData[0]?.name}`}{" "}
                  </Title>
                  <div>
                    <Button
                      mode="main"
                      label="Salvar alterações"
                      onClick={() =>
                        editPackpageData[0].default
                          ? handleUpdateDefault()
                          : fethPackpageUpdate()
                      }
                      loading={loadingUpdate}
                      disabled={values.name.length === 0}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <Input
                    name="name"
                    type="text"
                    label="Nome da Cesta de Tarifas"
                    onChange={handleChange}
                    disabled={editPackpageData[0]?.default ? true : false}
                    helper={
                      editPackpageData[0]?.default
                        ? "Não é possivel alterar o nome da cesta padrão."
                        : ""
                    }
                    value={values.name}
                  />
                </div>
                <Spacer bottom={20} />
                <div className="w-100 d-flex justify-content-between">
                  <TabMenu
                    menus={menus as any}
                    menuActive={customersRender ? 1 : 0}
                  />

                  {customersRender && !editPackpageData[0].default && (
                    <Button
                      mode="main"
                      label="Adicionar correntistas"
                      onClick={
                        () => setOpenAddClient(true)
                        // navigate(
                        //   `/app/myspace/tariffs/edit/new-clients/${params.id}`
                        // )
                      }
                    />
                  )}
                </div>

                <div className="mt-5">
                  <Outlet />
                </div>
              </ContainerData>
              <div className={contactsData.length > 0 ? "" : "d-none"}>
                <Paginator
                  list={contactsData.length > 0 ? contactsData : []}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  pageSetter={setPage}
                />
              </div>
              <Spacer top={40} />
              <Copyright />
              <Spacer top={30} />
              <DeleteHolderModal />
            </div>
          </GeneralContainerData>
        </Fragment>
      ) : undefined}

      <ModalAddClients />
    </ParentContainer>
  );
};

export default EditPackpages;
