import { FormikHelpers, FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Input,
  InteractiveModal,
  Margin,
  Padding,
  Radio,
  RadioGroup,
  Spacer,
} from "@zilliondigital/mirage-ui";

import "bootstrap/dist/css/bootstrap.min.css";
/* import Logo from "~/assets/images/logo-cmcapital.png"; */

import { Fragment, useEffect, useMemo, useState } from "react";
import ApiService from "~/services/api";
import Forgot from "./Forgot";

import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import useToast from "~/hooks/useToast";
import {
  BackgroundContainer,
  ContainerCompany,
  ForgotPasswordLink,
  GeneralContainer,
  IconModal,
  PasswordContainer,
  Sidebar,
  SidebarBody,
  TextFooter,
  TitleModal,
  WelcomeDescription,
  WelcomeText,
} from "./Auth.styles";
import { onlyNumbers } from "~/services/utils";
import documentNumberValidator from "~/utils/validateDocNumber";
import useToken from "~/hooks/useToken";
import AppStorage from "~/services/storage";
import useAuth from "~/hooks/useAuth";
import ValidatorsModals from "~/components/ModalsValidators/ValidatorsModals";

import { LoginResponse, UserCheckResponse } from "~/types";
import useAccount from "~/hooks/useAccount";

import label from "~/labels";
import styled from "styled-components";
import Copyright from "~/components/Copyright";
import theme from "~/themes/theme";

import IconBusiness from "~/assets/icons/icon-business.png";
import IconPerson from "~/assets/icons/icon-person.png";
import { formatCPFCNPJ, getAccountStatus } from "~/utils";
import { account_type_dict } from "~/data/account";
import useSignupUser from "~/hooks/Signup/useSignupUser";

const LogoImg = styled.img`
  width: 100%;

  max-height: ${() => label.backgroundSize ?? "100px"};
`;

type FormLoginValues = {
  step: number;
  document_number: string;
  partner: number;
  password: string;
};

type verificationType = {
  phone?: string;
  email?: string;
};

const initialValues: FormLoginValues = {
  step: 0,
  document_number: "",
  partner: 0,
  password: "",
};

const Login = () => {
  const { setIdUser, idUser } = useAccount();
  const [userFounded, setUserFounded] = useState<boolean>(false);
  const { setComeToLogin } = useSignupUser();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const { showToast } = useToast();

  const {
    openModal,
    partnersModal,
    setPartnersModal,
    setToken,
    setUser,
    token,
    setStepIndex,
    setOpenModal,
  } = useAuth();

  const { userPartners, setUserPartners } = useAccount();

  const { sendPartnerToken, verifyUserToken, getToken } = useToken();

  const FormSchema = Yup.object().shape({
    document_number: Yup.string()
      .required("Campo obrigatório")
      .test("", "", function (value = "") {
        const { path, createError } = this;
        const isValid = documentNumberValidator(onlyNumbers(value));
        return isValid || createError({ path, message: "Documento inválido" });
      }),
    password: Yup.string().min(
      8,
      "A senha precisa ter pelo menos 8 caracteres"
    ),
  });

  const toggleModal = () => {
    setStepIndex(0);
    setOpenModal(!openModal);
  };

  const onSubmitLogin = async () => {
    setLoading(true);
    try {
      const checkDocumentNumberPayload = {
        type: "document_number",
        username: onlyNumbers(values.document_number),
      };

      const { data } = await ApiService.HttpPost<UserCheckResponse>({
        route: "user-check/",
        body: checkDocumentNumberPayload,
      });

      if (!data.status) {
        showToast(
          "Documento não encontrado",
          "Parece que o documento informado não existe em nosso sistema, verifique se o documento está correto ou cadastre-se.",
          "error"
        );
        setUserFounded(false);
      } else {
        let redirect_to_register = false;

        const {
          response: { user },
        } = data;

        if (user.partners.length > 0) {
          const has_approved_accounts = user.partners.filter(
            (acc: any) => acc.account_status !== "editing"
          );

          if (has_approved_accounts.length > 0) {
            setUserPartners(user.partners);
            setUserFounded(true);
          } else {
            const has_approver_accounts = user.partners.some(
              (acc: any) => acc.type == "approver" || acc.is_approver
            );

            if (has_approver_accounts) {
              navigate("/auth/backoffice", {
                state: {
                  document_number: values.document_number,
                  has_user: true,
                },
              });

              return;
            } else {
              redirect_to_register = true;
            }
          }
        } else {
          redirect_to_register = true;
        }

        if (redirect_to_register) {
          showToast(
            "Cadastro",
            "É necessário finalizar seu cadastro para pode usar a plataforma, cadastre-se.",
            "warning"
          );

          setComeToLogin(true);
          navigate("/signup/user/personal-data", {
            state: {
              document_number: values.document_number,
              has_user: true,
            },
          });
        }
      }
    } catch (error) {
      console.warn(error);
    }

    if (userFounded) {
      try {
        try {
          const document_number = onlyNumbers(values.document_number);

          const authPayload = {
            username: document_number,
            password: values.password,
          };

          const { data } = await ApiService.HttpPost<LoginResponse>({
            route: "api-token-auth/",
            body: authPayload,
          });

          if (data.token) {
            setToken(data.token);
            AppStorage.setToken(data.token);
            AppStorage.setRegisteredToken(data.token);

            setPartnersModal(true);
          }
        } catch (error) {
          showToast("Erro", "Usuário e/ou senha incorretos", "error");
          setLoading(false);
          return;
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }

    setLoading(false);
  };

  const selectPartner = async (partner: any) => {
    try {
      await ApiService.HttpPut({
        route: `user-partners/${partner?.id}/set_default/`,
        body: {
          new: partner === null,
        },
        token: true,
      });

      if (partner === null) {
        setPartnersModal(false);
        navigate(`/signup/user/profile`);
        return;
      }

      setPartnersModal(!partnersModal);

      const partnerDefault = await ApiService.HttpPost({
        route: "partner-default/",
        body: null,
        token: true,
      });

      await sendPartnerToken();

      const validateToken = await getToken();

      const _token = token;

      const check = await verifyUserToken(
        {
          code: validateToken,
        },
        { token: _token }
      );

      if (!check) {
        showToast(
          "Token Incorreto",
          "Parece que o token informado não corresponde ao enviado pelo nosso sistema, tente novamente.",
          "error"
        );
        return;
      }

      setUser(partnerDefault.data);
      AppStorage.setUser(partnerDefault.data);

      const hasDataMissing = await ApiService.HttpPost({
        route: "onboard-checker/",
        body: {
          partner_id: partnerDefault.data.id,
        },
        token: true,
      });

      const {
        data: {
          data: { account_status, account_status_step, account_type },
        },
      } = hasDataMissing;

      setPartnersModal(false);

      switch (account_status) {
        case "internal_analysis":
          showToast(
            "Cadastro",
            "Conta em fase de aprovação interna, verifique mais tarde novamente",
            "warning"
          );
          return;
        case "external_analysis":
          showToast(
            "Cadastro",
            "Conta em fase de aprovação externa, verifique mais tarde novamente",
            "warning"
          );
          return;
        case "blocked":
          showToast(
            "Cadastro",
            "Conta com pendências, breve entraremos em contato para regularizar",
            "warning"
          );
          return;
        case "approved":
          navigate("/app/dashboard");
          return;
        case "refused":
          showToast(
            "Cadastro",
            "Conta recusada, entre em contato com o nosso suporte",
            "error"
          );
          return;
      }

      showToast(
        "Atenção",
        "Não foi possível identificar o status do seu cadastro, entre em contato com o suporte",
        "warning"
      );
      navigate("/");

      return;
    } catch (error) {
      console.error(error);
      showToast(
        "Erro",
        "Não foi possivel recuperar os dados da conta, tente novamente mais tarde.",
        "error"
      );
    }
  };

  const validUserPartners = useMemo(() => {
    return userPartners?.filter(
      (partner) => partner.account_status !== "editing"
    );
  }, [userPartners]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
    submitForm,
    setSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: onSubmitLogin,
    enableReinitialize: true,
  }) as FormikProps<FormLoginValues>;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <GeneralContainer>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Sidebar>
              <Spacer top={138} />
              <SidebarBody>
                <div className="d-flex  justify-content-center justify-content-md-start">
                  <div>
                    <LogoImg
                      style={
                        label.logoFilter ? { filter: label.logoFilter } : {}
                      }
                      src={label.logoHorizontal}
                    ></LogoImg>
                  </div>
                </div>
                <Spacer bottom={56} />
                <div className="d-flex flex-column align-items-center align-items-md-start ">
                  <WelcomeText>Bem vindo</WelcomeText>
                  <WelcomeDescription>
                    Informe seus dados para continuar...
                  </WelcomeDescription>
                </div>
                <Spacer bottom={56} />

                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    name="document_number"
                    mask={"999.999.999-99"}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setUserFounded(false);
                    }}
                    value={values.document_number}
                    label="Informe seu CPF"
                    placeholder="448.584.478-51"
                    error={
                      errors.document_number && touched.document_number
                        ? errors.document_number
                        : ""
                    }
                    success={userFounded}
                    autoComplete="document_number"
                    autoCapitalize="off"
                    required={values.document_number.length < 14}
                  />

                  <Fragment>
                    <Spacer bottom={-8} />
                    <PasswordContainer>
                      <ForgotPasswordLink
                        style={
                          label.usePrimaryColor?.includes(
                            "forgot_password_text"
                          )
                            ? { color: theme.colors.primary.main }
                            : {}
                        }
                        onClick={toggleModal}
                      >
                        Esqueci minha senha
                      </ForgotPasswordLink>
                      {!userFounded && <Spacer bottom={28} />}

                      {userFounded && (
                        <Input
                          type="password"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          label="Insira sua senha"
                          placeholder="lembrete: mínimo de 8 caracteres"
                          error={touched.password && errors.password}
                          autoCapitalize="none"
                          autoComplete="none"
                          required={values.password.length < 8}
                        />
                      )}
                    </PasswordContainer>
                  </Fragment>

                  <Button
                    className="text-center"
                    mode="main"
                    loading={loading}
                    full
                    onClick={() => onSubmitLogin()}
                    label={
                      !userFounded ? "Validar documento" : "Acessar minha conta"
                    }
                    type="button"
                    // onClick={checkDocumentNumber}
                  />
                </form>
                <Spacer bottom={150} />
                <Button
                  className="text-center"
                  mode="border"
                  full
                  onClick={() => {
                    navigate("/signup/user/personal-data", {
                      state: {
                        document_number: values.document_number,
                        has_user: true,
                      },
                    });
                    localStorage.clear();
                  }}
                  label={"Cadastre-se"}
                  type="button"
                />
              </SidebarBody>
              <footer>
                <Margin top={20} />
                <Copyright />
              </footer>
            </Sidebar>
          </motion.div>

          <BackgroundContainer />

          <InteractiveModal
            size="md"
            open={openModal}
            toggleModal={toggleModal}
          >
            <Forgot />
          </InteractiveModal>

          <InteractiveModal
            size="md"
            open={partnersModal}
            toggleModal={() => setPartnersModal(!partnersModal)}
          >
            <div className="p-4">
              <TitleModal>
                Selecione um perfil de pessoa para prosseguir
              </TitleModal>
              <Spacer bottom={16} />

              <div style={{ maxHeight: 500, overflow: "auto" }}>
                {validUserPartners.map((partner, index) => (
                  <Fragment key={index}>
                    <div
                      key={index}
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        paddingRight: 8,
                        marginBottom: 8,
                      }}
                      onClick={() => selectPartner(partner)}
                    >
                      <ContainerCompany className="col-12">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ padding: "15px" }}
                        >
                          <div
                            className="d-flex justify-content-center align-items-center div-circle"
                            style={{
                              width: "48px",
                              height: "48px",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              alt=""
                              src={
                                partner.document_type == "cpf"
                                  ? IconPerson
                                  : IconBusiness
                              }
                              className="icons"
                            />
                          </div>
                        </div>

                        <div
                          className="d-flex flex-column justify-content-center "
                          style={{ padding: " 10px" }}
                        >
                          <span
                            className="title"
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {partner.name}
                          </span>
                          <span style={{ fontSize: "14px", color: "#929292" }}>
                            {formatCPFCNPJ(partner.document_number)}
                            {" - "}
                            {account_type_dict[partner.type]}
                            {" - "}
                            {getAccountStatus(partner.account_status)}
                          </span>
                        </div>
                      </ContainerCompany>
                    </div>
                    <Spacer bottom={16} />
                  </Fragment>
                ))}
              </div>

              <Button
                mode="border"
                label="Adicionar nova conta"
                full
                onClick={() => selectPartner(null)}
              />
            </div>
          </InteractiveModal>

          <ValidatorsModals />
        </GeneralContainer>
      </motion.div>
    </AnimatePresence>
  );
};

export default Login;
