import { LateralModal, ProgressBar, Spacer } from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import useBackoffice from "~/hooks/useBackoffice";

import styled from "styled-components";
import Description from "~/components/Texts/Description";
import { Title } from "../../app-content.styles";
import { formatCPFCNPJ, formatDate } from "~/utils";
import { useMemo } from "react";

const RepresentativesModalDetails = () => {
  const {
    detailsData,
    setOpenRepresentativeDetailsModal,
    openRepresentativeDetailsModal,
    detailsRepresentatives,
  } = useBackoffice();

  const toggleModal = () => {
    setOpenRepresentativeDetailsModal(!openRepresentativeDetailsModal);
  };

  const address = useMemo(() => {
    if (detailsRepresentatives?.address) {
      return detailsRepresentatives?.address[0];
    }

    return {};
  }, [detailsRepresentatives]);

  return (
    detailsRepresentatives && (
      <LateralModal
        style={{ zIndex: "3" }}
        className=""
        header
        title={detailsRepresentatives?.name}
        open={openRepresentativeDetailsModal}
        toggleModal={toggleModal}
      >
        <div className="container-content p-4">
          <Title>Dados Básicos</Title>
          <Spacer bottom={20} />
          <div className="d-flex justify-content-between row">
            <div className="d-flex flex-column col-12">
              <Description>
                {formatCPFCNPJ(detailsRepresentatives.document_number)}
              </Description>
              <Description color="#808080">CPF</Description>
            </div>
            <Spacer bottom={20} />
            <div className="d-flex flex-column col-6">
              <Description>
                {formatDate(detailsRepresentatives.birth_date)}
              </Description>
              <Description color="#808080">DATA DE NASCIMENTO</Description>
            </div>
            <div className="d-flex flex-column col-6">
              <Description>{detailsRepresentatives.birth_state}</Description>
              <Description color="#808080">ESTADO DE NASCIMENTO</Description>
            </div>
            <Spacer bottom={20} />
            <div className="d-flex flex-column col-6">
              <Description>
                {detailsRepresentatives.identity_number}
              </Description>
              <Description color="#808080">DOC. DE IDENTIDADE</Description>
            </div>
            <div className="d-flex flex-column col-6">
              <Description>
                {detailsRepresentatives.marital_status_display}
              </Description>
              <Description color="#808080">ESTADO CIVIL</Description>
            </div>
            <Spacer bottom={20} />
            <div className="d-flex flex-column col-6">
              <Description>
                {detailsRepresentatives?.gender === "male"
                  ? "Masculino"
                  : "Feminino"}
              </Description>

              <Description color="#808080">GÊNERO</Description>
            </div>
            <div className="d-flex flex-column col-6">
              <Description>{detailsRepresentatives?.mothers_name}</Description>

              <Description color="#808080">NOME DA MÃE</Description>
            </div>
            <Spacer bottom={20} />
            <div className="d-flex flex-column col-6">
              <Description>{detailsRepresentatives?.nationality}</Description>

              <Description color="#808080">NACIONALIDADE</Description>
            </div>
            <Spacer bottom={20} />
            <div className="d-flex flex-column col-6 wrap-text">
              <Description wrapText>
                {detailsRepresentatives?.email}
              </Description>

              <Description color="#808080">EMAIL</Description>
            </div>

            <div className="d-flex flex-column col-6">
              <Description>{detailsRepresentatives?.mobile_phone}</Description>

              <Description color="#808080">CELULAR</Description>
            </div>
            <Spacer bottom={40} />
            <Title className="ms-2">Endereço</Title>

            <div className="d-flex flex-column col-12">
              <Description>{address.zipcode}</Description>

              <Description color="#808080">CEP</Description>
            </div>
            <Spacer bottom={20} />
            <div className="d-flex flex-column col-12">
              <Description>{`${address.street}, ${address.number}, ${address.district}, ${address.city} - ${address.state?.code}`}</Description>

              <Description color="#808080">ENDEREÇO COMPLETO</Description>
            </div>
            <Spacer bottom={20} />
          </div>
          <Spacer bottom={20} />
        </div>
      </LateralModal>
    )
  );
};

export default RepresentativesModalDetails;
