import { faker } from "@faker-js/faker";
import { CustomSelect, Input, Spacer } from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import { Fragment, useEffect, useMemo } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ufOptions } from "~/data/options";
import useSignupCompany, {
  CompanyAddressValues,
} from "~/hooks/Signup/useSignupCompany";
import useCEP from "~/hooks/useCEP";
import { CustomSelectValue } from "~/services/utils";
import { REQUIRED_LABEL } from "~/services/validators";

const Title = styled.span`
  font-size: 32px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

const FormSchema = Yup.object().shape({
  zipcode: Yup.string().required("Preencha o CEP"),
  city: Yup.string().required("Preencha a cidade"),
  state: Yup.string(),
  district: Yup.string().required("Preencha o bairro"),
  street: Yup.string().required("Preencha a rua"),
  number: Yup.string().required(REQUIRED_LABEL),
  complement: Yup.string(),
});

const Address = () => {
  const {
    setStep,
    setValid,
    setSending,
    companyAddressValues,
    setCompanyAddressValues,
    setCanAdvance,
    getAddress,
    addressData,
    getPartner,
    partnerCompany,
  } = useSignupCompany();

  const { handleCep, address } = useCEP();

  const newPartnerSaved: any = localStorage.getItem("new_company");
  const newPartnerSavedConverted = useMemo(
    () => JSON.parse(newPartnerSaved),
    [newPartnerSaved]
  );

  const handleFormSubmit = async (values: CompanyAddressValues) => {};
  const {
    submitForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues: companyAddressValues,
    validationSchema: FormSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  const fillForm = () => {
    const fakeValues: CompanyAddressValues = {
      zipcode: faker.address.zipCode("#####-###"),
      state: "SP",
      city: faker.address.city(),
      district: faker.address.city(),
      street: faker.address.streetName(),
      number: String(faker.datatype.number()),
      complement: faker.address.streetName(),
    };

    setCompanyAddressValues(fakeValues);
  };

  const companyAddress = Array.isArray(addressData)
    ? addressData.filter(
        (address: any) =>
          address.partner ===
          (newPartnerSavedConverted?.id ?? partnerCompany?.id)
      )
    : [];

  useEffect(() => {
    setCanAdvance(false);
    setStep("address");
    setSending(false);
    getPartner();
    window.addEventListener("fillForm", fillForm);
    setValid(isValid);

    getAddress();

    window.addEventListener("signupCompanySubmit", () => {
      submitForm();
    });

    return () => {
      window.removeEventListener("signupCompanySubmit", submitForm);
      window.removeEventListener("fillForm", fillForm);
    };
  }, []);

  useEffect(() => {
    const payload = {
      partner: newPartnerSavedConverted?.id ?? partnerCompany?.id,
      zipcode: values.zipcode,
      street: values.street,
      number: values.number,
      complement: values.complement,
      district: values.district,
      city: values.city,
      state: values.state,
      country: 1,
      type: "billing",
      identification: "",
    };

    setCompanyAddressValues(payload);
  }, [values, address, partnerCompany]);

  useEffect(() => {
    if (
      (address.zip_code !== "" || values.zipcode !== "") &&
      address.error === "" &&
      values.number !== ""
    ) {
      setCanAdvance(true);
    } else {
      setCanAdvance(false);
    }
  }, [values, address]);

  useEffect(() => {
    if (!addressData && (!newPartnerSavedConverted?.id || partnerCompany?.id)) {
      return;
    }
    const lastIndex = companyAddress.length - 1;
    const addressUpdated = companyAddress[lastIndex];

    if (addressUpdated) {
      setFieldValue("street", addressUpdated.street || "");
      setFieldValue("district", addressUpdated.district || "");
      setFieldValue("city", addressUpdated.city || "");
      setFieldValue("state", addressUpdated.state || "");
      setFieldValue("zipcode", addressUpdated.zipcode || "");
      setFieldValue("number", addressUpdated.number || "");
      setFieldValue("complement", addressUpdated.complement || "");
    }
  }, [addressData, newPartnerSavedConverted?.id, partnerCompany?.id]);

  useEffect(() => {
    if (address) {
      setFieldValue("street", address.street);
      setFieldValue("district", address.district);
      setFieldValue("city", address.city);
      setFieldValue("state", address.state);
      setFieldValue("zipcode", address.zip_code);
    }
  }, [address]);

  return (
    <Fragment>
      <Spacer bottom={50} />
      <div>
        <Title>Endereço Comercial</Title>
      </div>
      <Spacer bottom={30} />

      <div className="row">
        <div className="col-12 col-xxl-8 ">
          <form onSubmit={handleSubmit}>
            <div className="row d-flex align-items-end">
              <div className="col-lg-4 col-12 ">
                <Input
                  mask="99999-999"
                  type="text"
                  name="zipcode"
                  required={address.zip_code === "" && values.zipcode === ""}
                  label="CEP"
                  placeholder="12345-678"
                  error={address.error !== "" && address.error}
                  value={values.zipcode}
                  onKeyUp={(e: any) => {
                    handleCep(e);
                  }}
                  onChange={handleChange("zipcode")}
                  search
                  success={address.zip_code !== "" || values.zipcode !== ""}
                />
              </div>

              <Spacer bottom={10} />

              <div className="col-lg-5 col-12">
                <Input
                  required={address.street === "" && values.street === ""}
                  label="Rua"
                  type="text"
                  value={values.street}
                  name="street"
                  placeholder="Rua Borboletas Psicodélicas"
                  autoCapitalize="words"
                  onChange={handleChange("street")}
                  success={address.street !== "" || values.street !== ""}
                />
              </div>

              <div className=" col-12 col-lg-3">
                <Input
                  required={values.number === ""}
                  label="Número"
                  type="text"
                  value={values.number}
                  name="number"
                  placeholder="10"
                  error={touched.number && errors.number}
                  success={values.number !== ""}
                  onChange={handleChange("number")}
                />
              </div>

              <div className=" col-12 col-lg-4">
                <Input
                  label="Complemento"
                  type="text"
                  value={values.complement}
                  name="complement"
                  error={touched.complement && errors.complement}
                  placeholder="Casa 2"
                  success={values.complement !== ""}
                  onChange={handleChange("complement")}
                />
              </div>

              <div className="col-lg-5 col-12">
                <Input
                  required={address.district === "" && values.district === ""}
                  label="Bairro"
                  type="text"
                  name="district"
                  placeholder="São Paulo"
                  onChange={handleChange("district")}
                  success={address.district !== "" || address.district !== ""}
                  value={values.district}
                />
              </div>

              <div className="col-12 col-lg-4">
                <Input
                  type="text"
                  value={values.city}
                  success={address.city !== "" || values.city !== ""}
                  required={address.city === "" && values.city === ""}
                  name="city"
                  label="Cidade"
                  placeholder="São Paulo"
                  error={values.city === "" && values.city}
                  onChange={handleChange("city")}
                />
              </div>

              <div className=" col-12 col-lg-3 ">
                <CustomSelect
                  required={values.state === "" && address.state === ""}
                  label="Estado"
                  name="state"
                  value={CustomSelectValue(values.state, ufOptions)}
                  options={ufOptions}
                  onChange={(value) => {
                    setFieldValue("state", value.target.value);
                  }}
                  placeholder="Selecione"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Address;
