import axios from "axios";
import constate from "constate";
import { useState } from "react";

type AddressProps = {
  zip_code: string;
  street: string;
  district: string;
  city: string;
  state: string;
  error?: string;
};

const [CEPProvider, useCEP] = constate(() => {
  const [address, setAddress] = useState<AddressProps>({
    zip_code: "",
    street: "",
    district: "",
    city: "",
    state: "",
    error: "",
  });

  const handleCep = async (e: any) => {
    const { value } = e.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      const { logradouro, bairro, localidade, uf } = response.data;

      const error = response.data.erro;

      if (error) {
        setAddress({
          zip_code: "",
          street: "",
          district: "",
          city: "",
          state: "",
          error: "CEP inválido",
        });

        return;
      }

      setAddress({
        zip_code: value,
        street: logradouro,
        district: bairro,
        city: localidade,
        state: uf,
        error: "",
      });
    } catch (error) {
      //O CEP inválido volta undefined, porem com status code 200  e não entra no catch
    }
  };

  const handleCepString = async (initialValue = "") => {
    const cep = initialValue.replace(/[^0-9]/g, "");

    if (cep.length !== 8) {
      return;
    }

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      const { logradouro, bairro, localidade, uf } = response.data;

      const error = response.data.erro;

      if (error) {
        setAddress({
          zip_code: "",
          street: "",
          district: "",
          city: "",
          state: "",
          error: "CEP inválido",
        });

        return;
      }

      setAddress({
        zip_code: cep,
        street: logradouro,
        district: bairro,
        city: localidade,
        state: uf,
        error: "",
      });
    } catch (error) {
      //O CEP inválido volta undefined, porem com status code 200  e não entra no catch
    }
  };

  return {
    address,
    setAddress,
    handleCep,
    handleCepString,
  };
});

export { CEPProvider };

export default useCEP;
