import "bootstrap/dist/css/bootstrap.min.css";
import { SideBar, Spacer } from "@zilliondigital/mirage-ui";

import DashboardIcon from "~/assets/icons/sidebar/ic_navigation_dashboard.png";
import StatementsIcon from "~/assets/icons/sidebar/ic_navigation_receipt.png";
import PIXIcon from "~/assets/icons/sidebar/ic_navigation_pix.png";
import PaymentsIcon from "~/assets/icons/sidebar/ic_navigation_billet.png";
import CardIcon from "~/assets/icons/sidebar/ic_navigation_credit_card.png";
import CollectionsIcon from "~/assets/icons/sidebar/ic_navigation_payment.png";
import TransfersIcon from "~/assets/icons/sidebar/ic_navigation_transfer.png";
import ContactsIcon from "~/assets/icons/sidebar/ic_navigation_contacts.png";
import ExitIcon from "~/assets/icons/sidebar/ic_navigation_exit.png";
import PermissionIcon from "~/assets/icons/sidebar/permissions.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";

import AppHeader from "../AppHeader/AppHeader";
import { Container } from "./AppLayout.styles";
import useAccount from "~/hooks/useAccount";
import AppStorage from "~/services/storage";

import ValidatorsModals from "~/components/ModalsValidators/ValidatorsModals";
import ApiService from "~/services/api";
import useAuth from "~/hooks/useAuth";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";

import label from "~/labels";
import { APIResponse, getPartner } from "~/types";

export type MenuItem = {
  icon: string;
  text: string;
  onMenuClick?: () => void;
};

type menuItems = {
  icon: string;
  text: string;
  route: string;
  permission?: string;
  only_backoffice?: boolean;
};

export type AppLayoutProps = {
  searchOpen?: boolean;
};

const AppLayout = (props: AppLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const {
    setMainAccount,
    getProjections,
    getUserPartners,
    mainAccount,
    verifyPermission,
    activeIndexSideBar,
    fetchBankAccountAddress,
    accountAddress,
  } = useAccount();

  const { getBillets } = useNewCollection();

  const { user, setCanDoLogin } = useAuth();

  const menuItems: menuItems[] = [
    {
      icon: DashboardIcon,
      text: "Painel",
      route: "/app/dashboard",
    },
    {
      icon: StatementsIcon,
      text: "Extrato",
      route: "/app/statements",
      permission: "ba_can_read_extract",
    },
    {
      icon: TransfersIcon,
      text: "Transferências",
      route: "/app/transfers",
      permission: "ba_can_read_transfer",
    },
    {
      icon: CollectionsIcon,
      text: "Cobranças",
      route: "/app/collections",
      permission: "ba_can_read_billet",
    },
    {
      icon: PIXIcon,
      text: "Pix",
      route: "/app/pix",
    },
    {
      icon: PaymentsIcon,
      text: "Pagamentos",
      route: "/app/payments",
      permission: "ba_can_read_payment",
    },
    {
      icon: CardIcon,
      text: "Cartões",
      route: "/app/cards",
      permission: "ba_can_read_card",
      only_backoffice: true,
    },
    {
      icon: PermissionIcon,
      text: "Permissões",
      route: "/app/permissions",
      permission: "ba_can_read_permissions",
    },
    {
      icon: ContactsIcon,
      text: "Contatos",
      route: "/app/contacts",
      permission: "ba_can_read_contacts",
    },
  ];

  const mutationCallback = useCallback(
    (mutationsList: any[], observer: any) => {
      const mutation = mutationsList[0];
      if (
        mutation.type === "attributes" &&
        mutation.target.dataset?.lateralStatus
      ) {
        const opened = mutation.target.dataset?.lateralStatus === "true";
        const body = document.querySelector("body");

        if (opened) {
          body?.classList.add("remove-scrollbar");
        } else {
          body?.classList.remove("remove-scrollbar");
        }
      }
    },
    []
  );

  const topMenus: MenuItem[] = useMemo(() => {
    const filteredMenus = menuItems
      .filter((item) => {
        if (!item.permission) {
          return true;
        } else {
          return verifyPermission(item.permission);
        }
      })
      .filter((item) => {
        if (!item.only_backoffice) {
          return true;
        } else {
          return user?.is_backoffice;
        }
      });

    const mappedMenus = filteredMenus.map((menu) => ({
      icon: menu.icon,
      text: menu.text,
      onMenuClick: () => navigate(menu.route),
    }));

    return mappedMenus;
  }, [mainAccount, menuItems]);

  const bottomMenus: MenuItem[] = [
    {
      icon: ExitIcon,
      text: "Sair",
      onMenuClick: () => {
        setCanDoLogin(false);
        localStorage.removeItem("token");
        AppStorage.remove("@app:user");
        AppStorage.remove("@app:token");
        window.location.reload();
      },
    },
  ];

  const getPartner = async () => {
    try {
      const { data } = await ApiService.HttpGet<APIResponse<getPartner>>({
        token: true,
        route: "partner-account/",
      });

      if (!data.error) {
        setMainAccount(data.data);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    getUserPartners();
    getPartner();
    getProjections();
  }, []);

  useEffect(() => {
    if (mainAccount) {
      fetchBankAccountAddress();
    }
  }, [mainAccount]);

  useEffect(() => {
    const observer = new MutationObserver(mutationCallback);
    const elements: any = document.querySelectorAll("div[data-lateral-status]");

    const observerOptions = {
      attributes: true,
    };

    if (elements) {
      elements.forEach((_el: any) => {
        observer.observe(_el, observerOptions);
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [location]);

  return (
    <Container {...props} searchOpen={isSearching}>
      <div style={{ zIndex: "3" }}>
        {" "}
        <SideBar
          menu={topMenus}
          footer={bottomMenus}
          logo={label.logoMenu ?? label.logoHorizontal}
          logoWidth={label.logoMenuWidth}
          menuActiveIndex={activeIndexSideBar}
          useLogoFilter={label.useLogoFilter ?? false}
        />
      </div>

      <Spacer left={88} />
      <AppHeader isSearching={isSearching} />
      <div className="wrapper-container">
        <Outlet />
      </div>
      <ValidatorsModals />
    </Container>
  );
};

export default AppLayout;
