import { Button, Spacer, Table } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import MoreIcon from "~/assets/icons/buttons/more.png";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "~/components/Texts/Title";

import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";
import CPFKeyIcon from "~/assets/icons/round_account_circle_black_24dp.png";
import MailKeyIcon from "~/assets/icons/mail_FILL0_wght400_GRAD0_opsz48.png";
import PhoneKeyIcon from "~/assets/icons/phone_iphone_FILL0_wght400_GRAD0_opsz48.png";
import KeyIcon from "~/assets/icons/key.png";

import useAddBalance from "~/hooks/useAddBalance";
import { useNavigate } from "react-router-dom";
import usePix from "~/hooks/PIX/usePix";
import useToken from "~/hooks/useToken";
import theme from "~/themes/theme";
import label from "~/labels";
import { Columns } from "~/types";
import useMyKeys from "~/hooks/PIX/useMyKeys";
import { formatCPFCNPJ } from "~/utils";

type typeKeysProps = {
  [key: string]: string;
};

type dataPixTable = {
  index: number;
  type: string;
  key: string;
};

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }

  .bank-accounts-picture {
    width: 10px;
    height: 10px;
    background-color: #d8d8d7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }

  .img-bank-icon {
    width: 25px;
  }
`;

const typesKeysLabel: typeKeysProps = {
  telefone: "Celular",
  email: "Email",
  cpf: "CPF",
  cnpj: "CNPJ",
  evp: "Chave aleatória",
};

const IconsKeys: typeKeysProps = {
  evp: KeyIcon,
  telefone: PhoneKeyIcon,
  email: MailKeyIcon,
  cpf: CPFKeyIcon,
  cnpj: CPFKeyIcon,
};

const AddBalanceSelectPixKey = () => {
  const {
    handlePrevStep,

    handleNextStep,
    setChangeSideAnimation,
    changeSideAnimation,
    setChooseTypeAddBalance,
    setAmount,
    setSelectedValue,
    indexPixKeyToCharge,
    setIndexPixKeyToCharge,
    isPIX,
    setStepIndex,
    setOpenAddBalanceModal,
  } = useAddBalance();

  const { keyPixData } = useAddBalance();

  const { pixKeysData, setKeyData } = useMyKeys();

  const navigate = useNavigate();

  const { setToken } = useToken();

  const { setOpenMyKeysModal } = usePix();

  const columnsPixKeys: Columns[] = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any) => (
        <ContainerRenderData
          onClick={() => {
            setIndexPixKeyToCharge(
              indexPixKeyToCharge === data.index ? -1 : data.index
            );

            // handleShowTheSelectBankAccount(data);
          }}
        >
          <div className="d-flex align-items-center">
            {indexPixKeyToCharge === data.index ? (
              <ContainerImage>
                <div className="contact-selected-image">
                  <img
                    className="selected-contact-image"
                    src={SelectedContactIcon}
                  />
                </div>
              </ContainerImage>
            ) : (
              <ContainerImage>
                <div className="container-profile-picture">
                  <div className="rounded-circle  bank-accounts-picture">
                    <img className="img-bank-icon" src={IconsKeys[data.type]} />
                  </div>
                </div>
              </ContainerImage>
            )}
            <Spacer left={10} />
            <div className="d-flex flex-column">
              <Title>{typesKeysLabel[data.type]}</Title>
              <Description>
                {data.type === "cpf" || data.type === "cnpj"
                  ? formatCPFCNPJ(data.key)
                  : data.key}
              </Description>
            </div>
          </div>
        </ContainerRenderData>
      ),
    },
  ];

  const dataKeyPixTable: dataPixTable[] = keyPixData
    .filter((key) => key.key !== null && key.status === "registered")
    .map((key: any, index: number) => ({
      index: index,
      type: key.type,
      key: key.key,
      id: key.id,
    }));

  useEffect(() => {
    if (indexPixKeyToCharge !== -1) {
      setKeyData([dataKeyPixTable[indexPixKeyToCharge]]);
    }
  }, [indexPixKeyToCharge]);

  useEffect(() => {
    setChangeSideAnimation(false);
  }, []);

  return (
    <motion.div
      initial={{
        x: changeSideAnimation ? "100%" : !changeSideAnimation && "-100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: changeSideAnimation ? "-100%" : !changeSideAnimation && "100%",
      }}
      transition={{ duration: 0.3 }}
      className="overflow-hidden"
    >
      <div>
        <div className="p-4">
          <Description color="#666666">
            Selecione uma chave Pix para continuar sua recarga
          </Description>
        </div>

        <p className="ps-4">Chaves disponíveis</p>

        {dataKeyPixTable.length <= 0 && (
          <div className="p-4 d-flex justify-content-center">
            <Description>
              Você não possui nenhuma chave pix cadastrada ou disponível para
              continuar sua recarga.
            </Description>
          </div>
        )}
        {dataKeyPixTable.length > 0 && (
          <>
            <Spacer bottom={0} />{" "}
            <div style={{ zIndex: "1", height: "400px", overflow: "auto" }}>
              <Table columns={columnsPixKeys} data={dataKeyPixTable} />
            </div>
          </>
        )}
        <Spacer bottom={20} />
        <div className="p-4">
          <div className="d-flex justify-content-between">
            <div>
              <Button
                mode="phantom"
                label="Voltar"
                onClick={() => {
                  setIndexPixKeyToCharge(-1);

                  if (isPIX) {
                    setStepIndex(0);
                  } else {
                    handlePrevStep();
                  }
                }}
              />
            </div>

            <div className="d-flex">
              <Button
                mode="border"
                label="Adicionar chave Pix"
                onClick={() => {
                  setOpenAddBalanceModal(false);
                  setChooseTypeAddBalance(null);
                  setSelectedValue("");
                  setToken("");
                  setAmount(0);
                  setIndexPixKeyToCharge(-1);
                  setOpenMyKeysModal(true);
                  navigate("/app/pix");
                }}
                icon={MoreIcon}
              />
              <Spacer left={10} />
              <Button
                label="Avançar"
                mode="main"
                onClick={() => {
                  setChangeSideAnimation(true);
                  handleNextStep();
                }}
                disabled={indexPixKeyToCharge === -1}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AddBalanceSelectPixKey;
