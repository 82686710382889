import { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Input, Spacer } from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  PhoneRegex,
  INVALID_FORMAT,
  REQUIRED_LABEL,
} from "~/services/validators";
import useSignupUser from "~/hooks/Signup/useSignupUser";
import {
  generateCellphone,
  validateEmail,
  validatePhoneNumber,
  verifyObjectIsEmpty,
} from "~/services/utils";
import useAnimation from "~/hooks/useAnimation";
import { motion } from "framer-motion";
import useToast from "~/hooks/useToast";
import { useLocation, useNavigate } from "react-router-dom";
import { faker } from "@faker-js/faker";
import useToken from "~/hooks/useToken";
import ApiService from "~/services/api";

type Values = {
  email: string;
  phone: string;
};

const Title = styled.span`
  font-size: 32px;
  font-weight: 400;

  @media screen and (max-width: 520px) {
    font-size: 25px;
  }
`;

const FormSchema = Yup.object().shape({
  email: Yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
  phone: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(PhoneRegex, INVALID_FORMAT),
});

const SignupBasicContact = (props: any) => {
  const location = useLocation();
  const { showToast } = useToast();

  const {
    setValid,
    setLoading,
    setStep,
    signUpUserPayload,
    setSignUpUserPayload,
    setDoLogin,
    setStepIndex,
    setOpenModal,
  } = useSignupUser();
  const { changeSideAnimation, setChangeSideAnimation } = useAnimation();
  const { getToken } = useToken();

  const [initialValues, setInitialValues] = useState<Values>({
    email: signUpUserPayload.email ?? "",
    phone: signUpUserPayload.phone ?? "",
  });

  const handleFormSubmit = useCallback(
    async (values: Values, helpers: FormikHelpers<Values>) => {
      const data: any = localStorage.getItem("new_user");
      let prevData = JSON.parse(data);
      prevData.email = values.email;
      prevData.phone_number = values.phone;

      const newData = prevData;
      localStorage.setItem("new_user", JSON.stringify(newData));

      helpers.setSubmitting(true);
      setLoading(true);

      try {
        const { data } = await ApiService.HttpPost({
          route: "otp/token/send/validation/",
          body: {
            email: values.email,
            phone: values.phone,
          },
        });

        setLoading(false);

        const token = await getToken({
          title: "Código de verificação",
          description: `Informe o código  que enviamos para o seu email/celular`,
        });

        setLoading(true);

        setSignUpUserPayload((prevData: any) => ({
          ...prevData,
          token: token,
        }));

        setOpenModal(true);
      } catch (e: any) {
        setLoading(false);
      }

      helpers.setSubmitting(false);
      setLoading(false);
    },
    [setLoading, setSignUpUserPayload, getToken, setOpenModal]
  );

  const {
    submitForm,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const handleParentSubmit = useCallback(() => {
    if (!verifyObjectIsEmpty(errors)) {
      showToast("Atenção", `Ainda há campos inválidos no formulário`, "error");
      return;
    }

    submitForm();
  }, [errors, showToast, submitForm, verifyObjectIsEmpty]);

  const fillForm = () => {
    const fakeValues: Values = {
      email: faker.internet.email(),
      phone: generateCellphone(true),
    };

    setInitialValues(fakeValues);
  };

  useEffect(() => {
    const newUser: any = localStorage.getItem("new_user");

    const newUserParsed = JSON.parse(newUser);
    setSignUpUserPayload((prevData: any) => ({
      ...prevData,
      ...newUserParsed,
    }));

    window.addEventListener("signupUserSubmit", handleParentSubmit);
    window.addEventListener("fillForm", fillForm);
    setStep("contact");

    setStepIndex(1);
    setDoLogin(false);

    setChangeSideAnimation(false);

    if (location.state && location.state.has_user) {
      setFieldValue("username_login", location.state.document_number);
    }

    return () => {
      window.removeEventListener("signupUserSubmit", handleParentSubmit);
      window.removeEventListener("fillForm", fillForm);
    };
  }, []);

  useEffect(() => {
    if (validateEmail(values.email) && validatePhoneNumber(values.phone)) {
      setValid(true);
    } else {
      setValid(false);
    }

    setSignUpUserPayload((prevData: any) => ({
      ...prevData,
      ...values,
    }));
  }, [values]);

  return (
    <Fragment>
      <motion.div
        initial={{
          x: !changeSideAnimation ? "100%" : changeSideAnimation && "-100%",
        }}
        animate={{ x: 0 }}
        exit={{
          x: !changeSideAnimation ? "-100%" : changeSideAnimation && "100%",
        }}
        transition={{ duration: 0.5 }}
      >
        <Spacer top={70} />

        <div style={{ maxWidth: "614px" }} className="d-flex flex-column">
          <Title>Digite seus dados de contato</Title>
          <Spacer bottom={20} />
        </div>

        <div className="row">
          <div className={"col-12"}>
            <form>
              <div className="row">
                <>
                  <div className="col-9 mt-3">
                    <Input
                      style={{ zIndex: "5" }}
                      type="email"
                      name="email"
                      value={values.email}
                      required={!!errors.email}
                      label="Seu melhor e-mail"
                      placeholder="jhon.doe@gmail.com"
                      error={touched.email && errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoCapitalize="off"
                      autoComplete="email"
                      success={!errors.email}
                    />
                  </div>

                  <div className="col-9">
                    <Input
                      mask="(99) 99999-9999"
                      type="tel"
                      name="phone"
                      label="Número de celular"
                      value={values.phone}
                      required={!!errors.phone}
                      placeholder="(00) 99887-7665"
                      error={touched.phone && errors.phone}
                      success={!errors.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoCapitalize="off"
                      autoComplete="off"
                    />
                  </div>
                </>
              </div>
            </form>
          </div>
        </div>

        <Spacer bottom={89} />
      </motion.div>
    </Fragment>
  );
};

export default SignupBasicContact;
