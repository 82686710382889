import constate from "constate";
import { EventEmitter } from "eventemitter3";
import { useState } from "react";
import { ModalProps } from "~/components/AsyncTokenModal";
import ApiService, { HttpPostProps } from "~/services/api";

export const tokenEvent = new EventEmitter();

const initialModalprops: ModalProps = {
  title: "Validação de segurança",
  description: "Informe o token vinculado a sua conta.",
  numberOfInputs: 6,
};

const [TokenProvider, useToken] = constate(() => {
  const [showModal, setShowModal] = useState(false);

  const [modalProps, setModalProps] = useState<ModalProps>(initialModalprops);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const [token, setToken] = useState<string>("");

  const [numberOfInputs, setNumberOfInputs] = useState<number>(6);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  const [openValidatorModal, setOpenValidatorModal] = useState<boolean>(false);

  const handleToken = (token: string) => {
    tokenEvent.emit("token", token);
    setShowModal(false);
  };

  const getToken = async (modalProps?: ModalProps) => {
    if (modalProps) setModalProps(modalProps);
    setShowModal(true);

    return new Promise<string>((resolve, reject) => {
      tokenEvent.once("token", (token: string) => {
        console.log;

        if (!token) {
          reject(
            "Não foi possível validar o token de segurança, token não informado"
          );
        } else {
          resolve(token);
        }

        tokenEvent.removeAllListeners("token");
        setToken("");
      });
    });
  };

  const sendToken = async (email?: string, mobile_phone?: string) => {
    try {
      const { data } = await ApiService.HttpPost({
        route: "send-token/",
        body: {
          channel: "all",
          email: email || "",
          phone: mobile_phone || "",
        },
        token: true,
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const sendPartnerToken = async () => {
    try {
      const { data } = await ApiService.HttpPost({
        route: "send-partner-token/",
        body: {},
        token: true,
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const sendUsernameVerificationToken = async (
    username: string
  ): Promise<any> => {
    try {
      const token = await ApiService.HttpPost({
        route: "send-username-token/",
        body: {
          username,
        },
      });

      const { data } = token;

      return data;
    } catch (error: any) {
      if (error.response) {
        return error.response.data;
      }
    }
  };

  const verifyUserToken = async (
    { code = "", phone = "", email = "", channel = "all" },
    additionalParams: Record<string, any> = { token: true }
  ) => {
    try {
      let payload = { channel, phone, email, code };

      if (additionalParams) {
        payload = { ...payload, ...additionalParams };
      }

      const requestPayload: HttpPostProps = {
        route: "verify-token/",
        body: {
          ...payload,
        },
      };

      if (additionalParams.token) {
        requestPayload.token = true;
      }

      await ApiService.HttpPost(requestPayload);

      return true;
    } catch (error: any) {
      return false;
    }
  };

  return {
    showModal,
    setShowModal,

    modalProps,
    setModalProps,

    handleToken,
    getToken,

    token,
    setToken,
    buttonDisabled,
    setButtonDisabled,
    numberOfInputs,
    setNumberOfInputs,

    isLoadingButton,
    setIsLoadingButton,

    openValidatorModal,
    setOpenValidatorModal,
    sendToken,
    sendPartnerToken,
    sendUsernameVerificationToken,
    verifyUserToken,
  };
});

export { TokenProvider };

export default useToken;
