import { Button, Input, RadioGroup, Spacer } from "@zilliondigital/mirage-ui";

import useCollections from "~/hooks/Collections/useCollections";
import useAddBalance from "~/hooks/useAddBalance";
import useSplit from "~/hooks/useSplit";
import Description from "~/components/Texts/Description";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RadioOptions } from "~/types";

type RenderPercentType = {
  [key: string]: number;
};

type SplitValue = {
  name: string;
  days: string;
};

const initialValues: SplitValue = {
  name: "",
  days: "1",
};

const FormSchema = Yup.object().shape({
  name: Yup.string(),
});

const radioOptions: RadioOptions[] = [
  {
    title: "Imediato",
    description:
      "Ocorre quando ocorre a liquidação da cobrança, de forma imediata",
    value: "0",
  },
  {
    title: "Agendado",
    description: "Ocorre após uma quantidade predefinida de dias",
    value: "1",
  },
  {
    title: "Provedor",
    description:
      "Ocorre durante a liquidação, já tendo em conta os valores distribuídos",
    value: "2",
  },
];

localStorage.removeItem("splitOver");

const NewSplitType = () => {
  const { isEmpty, setIsEmpty } = useAddBalance();

  const {
    openNewSplitModal,
    setOpenNewSplitModal,
    stepIndex,
    setStepIndex,
    selectedValues,
    setSelectedValue,
    setNewSplitPayload,
    newSplitPayload,
    setReceivers,
  } = useSplit();

  const handleFormSubmit = (values: SplitValue) => {
    return;
  };

  const { handleChange, setFieldValue, values, resetForm } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleFormSubmit,
  });

  const handleToggleModal = () => {
    setOpenNewSplitModal(!openNewSplitModal);
    resetForm();
    setReceivers([]);
    setIsEmpty(true);
    localStorage.removeItem("splitOver");
    localStorage.removeItem("values");
    localStorage.removeItem("splitLostDiscount");
  };

  const handleAdvance = () => {
    setStepIndex(stepIndex + 1);
  };

  function handleChangeTile(value: any) {
    setSelectedValue(value);

    if (value === "0") {
      setSelectedValue("liquidation");
      setIsEmpty(false);
    }

    if (value === "1") {
      setSelectedValue("schedule");
      setIsEmpty(false);
    }

    if (value === "2") {
      setSelectedValue("fitbank");
      setIsEmpty(false);
    }
  }

  useEffect(() => {
    if (selectedValues === "schedule") {
      setNewSplitPayload({
        name: values.name,
        split_operation_mode: selectedValues,
        days_to_execute: Number(values.days),
      });
    } else {
      setNewSplitPayload({
        name: values.name,
        split_operation_mode: selectedValues,
      });
    }
  }, [selectedValues, values]);

  useEffect(() => {
    if (selectedValues !== "schedule") {
      setFieldValue("days", null);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (!openNewSplitModal) {
      resetForm();
    }
  }, [openNewSplitModal]);

  return (
    <>
      <Input
        label="Defina o nome do arranjo"
        required={values.name.length === 0}
        name="name"
        onChange={handleChange}
        value={values.name}
        autoCapitalize="none"
        autoComplete="none"
      />
      <Spacer bottom={40} />

      <Description className="mb-2">
        <b>Tipo de Split</b>
      </Description>

      <RadioGroup
        emptyValue={isEmpty}
        display="row"
        options={radioOptions}
        wrap
        onChange={(value) => handleChangeTile(value)}
      />

      {selectedValues === "schedule" ? (
        <div>
          <Spacer top={30} />
          <Input
            name="days"
            mask="9999"
            onChange={handleChange}
            error={
              values?.days === "0" &&
              "Vamor mínimo de dias para a distribuição deve ser maior que 0"
            }
            required
            type={"text"}
            label="Após quantos dias a distribuição deve ocorrer?"
          />
        </div>
      ) : null}

      <div
        className="d-flex w-100 justify-content-between  ps-4  pe-4 pb-5"
        style={{ position: "fixed", bottom: "0", left: "0" }}
      >
        <Button mode="phantom" label={"Cancelar"} onClick={handleToggleModal} />
        <Button
          mode="main"
          label="Avançar"
          onClick={handleAdvance}
          disabled={
            selectedValues === "" ||
            values.name.length === 0 ||
            (selectedValues === "schedule" && values.days === null) ||
            values?.days?.length === 0 ||
            values?.days === "0"
          }
        />
      </div>
    </>
  );
};

export default NewSplitType;
