import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  Input,
  Spacer,
  SummaryPanel,
  TabMenu,
} from "@zilliondigital/mirage-ui";

import RefreshIcon from "~/assets/icons/buttons/Ic_functional_refresh.png";
import { Fragment, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import Paginator from "~/components/Paginator";
import WithContats from "./WithContacts";

import useContacts from "~/hooks/Contacts/useContacts";
import ContactsFilter from "./ContactsFilter";
import ApiService from "~/services/api";
import DetailsContact from "./DetailsContact";

import ContactsEmpty from "./ContactsEmpty";
import NewContactStructure from "./NewContact/NewContactStructure";
import TableLoader from "~/components/SkeletonTables";
import useAccount from "~/hooks/useAccount";
import Copyright from "~/components/Copyright";
import useCollections from "~/hooks/Collections/useCollections";
import useBackoffice from "~/hooks/useBackoffice";
import RepresentativesModalDetails from "./Details/RepresentativesModalDetails";
import Details from "./Details/Details";
import { TitleModal } from "~/pages/Authentication/Auth.styles";
import { onlyNumbers } from "~/utils";

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const GeneralContainerData = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  width: 100%;
  flex-grow: 1;

  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .container-return-button {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .container-auxiliar {
    position: fixed;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
`;

const Title = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

const BackofficeStructure = () => {
  const navigate = useNavigate();

  const {
    pendings,
    setPendings,
    pendingsMeta,
    setPendingsMeta,
    pendingsLoading,
    setPendingsLoading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    isDetails,
    setIsDetails,
    section,
    setSection,
    dataRender,
    setDataRender,
    detailsData,
    setRenderFilter,
  } = useBackoffice();

  const [titleSection, setTitleSection] = useState<string>("Editando");

  const { mainAccount, verifyPermission } = useAccount();

  const canCreateContact = verifyPermission("ba_can_create_contacts");

  const { cameToCollectionsOrTransfers, setCameToCollectionsOrTransfers } =
    useCollections();

  const quantityPages = [
    {
      label: "20 por página",
      value: "1",
      onClick: () => {
        setRowsPerPage(20);
      },
    },
    {
      label: "50 por página",
      value: "2",
      onClick: () => {
        setRowsPerPage(50);
      },
    },
    {
      label: "100 por página",
      value: "3",
      onClick: () => {
        setRowsPerPage(100);
      },
    },
  ];

  const getPartners = async () => {
    setPendingsLoading(true);
    try {
      /* 
        OLD API
      const { data } = await ApiService.HttpGet({
        route: "backoffice/new/pendings/",
        token: true,
      }); 
      */

      const { data } = await ApiService.HttpGet({
        route: "backoffice/new/pendings-status/",
        token: true,
      });

      setPendings(data.data);

      setPendingsMeta(data.meta);
    } catch (e) {
      console.warn(e);
    }
    setPendingsLoading(false);
  };

  type RenderSectionType = {
    [key: string]: JSX.Element;
  };

  useEffect(() => {
    getPartners();
  }, [mainAccount]);

  useEffect(() => {
    setDataRender("editing");
  }, []);

  const getTariffs = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs/",
        token: true,
      });
    } catch {}
  };

  const ContactsPanel = useMemo(
    () => [
      {
        label: "Editando",
        balance: String(pendingsMeta?.editing),
        toggleBalance: false,
        currency: "",
        onClick: () => {
          setDataRender("editing");

          setTitleSection("Editando");
        },
      },
      {
        label: "Pendente de Análise",
        balance: String(pendingsMeta?.internal_analysis),
        toggleBalance: false,
        currency: "",
        onClick: () => {
          setDataRender("internal_analysis");

          setTitleSection("Pendente de análise");
        },
      },
      {
        label: "Análise Externa",
        balance: String(pendingsMeta?.external_analysis),
        toggleBalance: false,
        currency: "",
        onClick: () => {
          setDataRender("external_analysis");
          setTitleSection("Análise externa");
        },
      },
    ],
    [pendingsMeta]
  );

  const _ContactsPanel = useMemo(
    () => [
      {
        label: "Bloqueados",
        balance: String(pendingsMeta?.blocked),
        toggleBalance: false,
        currency: "",
        onClick: () => {
          setDataRender("blocked");
          setTitleSection("Bloqueados");
        },
      },
      {
        label: "Recusados",
        balance: String(pendingsMeta?.refused),
        toggleBalance: false,
        currency: "",
        onClick: () => {
          setDataRender("refused");
          setTitleSection("Recusados");
        },
      },
      {
        label: "Aprovados",
        balance: String(pendingsMeta?.approved),
        toggleBalance: false,
        currency: "",
        onClick: () => {
          setDataRender("approved");
          setTitleSection("Aprovados");
        },
      },
    ],
    [pendingsMeta]
  );

  const currentPendings: any = useMemo(() => {
    if (pendings[dataRender]) {
      return pendings[dataRender];
    }

    // if (filteredPendings.length > 0) {
    //   return filteredPendings;
    // }

    return [];
  }, [pendings, dataRender]);

  useEffect(() => {
    !cameToCollectionsOrTransfers && setSection("initial");

    getTariffs();

    setTimeout(() => {
      setCameToCollectionsOrTransfers(false);
    }, 500);

    if ((currentPendings.length = 0)) {
      getPartners();
    }
  }, []);

  const removeNonNumericChars = (input: string) => input.replace(/\D/g, "");

  const [searchInput, setSearchInput] = useState("");

  const filteredPendings = useMemo(() => {
    return currentPendings.filter((contact: any) => {
      const lowerCaseSearchInput = searchInput.toLowerCase();
      const isNumericSearch = removeNonNumericChars(searchInput);

      return isNumericSearch
        ? contact.document_number.includes(onlyNumbers(lowerCaseSearchInput))
        : contact.name.toLowerCase().includes(lowerCaseSearchInput);
    });
  }, [currentPendings, searchInput]);

  useEffect(() => {
    setRenderFilter(filteredPendings);
  }, [currentPendings, searchInput]);

  return (
    <ParentContainer>
      {" "}
      <Fragment>
        <GeneralContainerData>
          <div className="d-flex flex-column container-fluid w-100 h-100  ps-5 pe-5">
            <Spacer top={30} />
            <Breadcrumb
              menus={[{ label: "Administração", onClick: () => {} }]}
              onClickHome={() => navigate("/app/dashboard")}
            />
            <Spacer top={30} />

            <ContainerData className="container-fluid ps-4 pe-4">
              <div className="d-flex justify-content-between p-4 ">
                <Title>Aprovação de cadastros</Title>
                <div>
                  <div className="d-flex flex-column flex-lg-row">
                    {currentPendings.length > 0 && !pendingsLoading && (
                      <Fragment>
                        <Spacer right={10} />
                        <div className="d-block d-lg-none">
                          <Spacer bottom={10} />
                        </div>
                      </Fragment>
                    )}{" "}
                  </div>
                </div>
              </div>
              <div className="pe-4 ps-4">
                <div>
                  <div className="row w-100 ">
                    <SummaryPanel
                      panel={ContactsPanel}
                      isLoading={pendingsLoading}
                    />
                    <SummaryPanel
                      panel={_ContactsPanel}
                      isLoading={pendingsLoading}
                    />
                  </div>
                  <Spacer bottom={20} />
                  <div className="d-flex w-100 align-items-start  ">
                    <div className="">
                      {!pendingsLoading && (
                        <Button
                          mode="border"
                          label="Atualizar"
                          icon={RefreshIcon}
                          onClick={() => getPartners()}
                        />
                      )}
                    </div>

                    <Spacer left={15} />
                    {currentPendings.length > 0 && !pendingsLoading && (
                      <div>
                        <ButtonDropdown
                          mode="border"
                          label={`${rowsPerPage} por página`}
                          options={quantityPages}
                        />
                      </div>
                    )}
                  </div>
                  <Spacer top={50} />
                </div>
              </div>

              <div className="ps-4 ">
                <TitleModal>{titleSection}</TitleModal>
              </div>
              <div
                className="p-4 col-md-5 col-12"
                style={{ marginTop: "-30px" }}
              >
                {currentPendings.length > 0 ? (
                  <Input
                    type="text"
                    search
                    placeholder="Digite um CPF/CNPJ ou um Nome para pesquisar"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                ) : null}
              </div>

              {pendingsLoading ? (
                <TableLoader />
              ) : currentPendings.length > 0 && !pendingsLoading ? (
                <div className="pe-4 ps-4">
                  <WithContats />
                </div>
              ) : (
                <ContactsEmpty />
              )}
            </ContainerData>
            <div className={currentPendings.length > 0 ? "" : "d-none"}>
              <Paginator
                list={currentPendings.length > 0 ? currentPendings : []}
                rowsPerPage={rowsPerPage}
                page={page}
                pageSetter={setPage}
              />
            </div>

            <Spacer top={currentPendings.length > 0 ? 20 : 40} />

            <Copyright />

            <Spacer top={30} />
          </div>
        </GeneralContainerData>

        <ContactsFilter />
      </Fragment>
    </ParentContainer>
  );
};

export default BackofficeStructure;
