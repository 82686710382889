import {
  Button,
  CustomSelect,
  Input,
  InputCurrency,
  InteractiveModal,
  RadioGroup,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { format, subDays } from "date-fns";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { data } from "~/components/Chart";
import useCollections from "~/hooks/Collections/useCollections";
import useAccount from "~/hooks/useAccount";
import useAddBalance from "~/hooks/useAddBalance";
import useToast from "~/hooks/useToast";
import ApiService from "~/services/api";
import { convertStringToNumber } from "~/utils";

type setBaixa = {
  id: number;
  type: string;
  amount: number;
  due_date?: any;
  deadline_date?: any;
  description?: any;
  created_date: string;
  billet: number;
  partner: number;
  type_display: string;
};

type FormValues = {
  amount: number;
  description: string;
  due_date: string;
  deadline_date: string;
};

const initialValues: FormValues = {
  amount: 0,
  description: "",
  due_date: "",
  deadline_date: "",
};

const FormSchema = Yup.object().shape({
  amount: Yup.string(),
  description: Yup.string(),
  due_date: Yup.string()
    .required("Campo obrigatório")
    .test("valid-date", "Data de vencimento inválida", (value) => {
      if (!value) {
        return false;
      }

      const currentDate = new Date();
      const selectedDate = new Date(value);

      return selectedDate >= currentDate;
    }),
  deadline_date: Yup.string()
    .required("Campo obrigatório")
    .test("valid-deadline-date", "Data limite inválida", function (value) {
      const { due_date } = this.parent;

      if (!value || !due_date) {
        return false;
      }

      const dueDate: any = new Date(due_date);
      const selectedDate = new Date(value);

      return selectedDate >= dueDate;
    }),
});

const EditCollection = () => {
  const {
    dataBillet,
    setOpenDetailModal,
    getBillets,
    setIsBaixa,
    showContent,
    setShowContent,
    selectedValue,
    setSelectedValue,
    selectedBillets,
  } = useCollections();
  const { showToast } = useToast();
  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { verifyPermission, beforeDays } = useAccount();

  const canEditBillet = verifyPermission("ba_can_edit_billet");
  const { isEmpty, setIsEmpty } = useAddBalance();

  function handleChangeTille(value: any) {
    setSelectedValue(value);

    if (value === "0") {
      setSelectedValue("baixa");
      setIsEmpty(false);
    }

    if (value === "1") {
      setSelectedValue("cancel");
      setIsEmpty(false);
    }
    if (value === "2") {
      setSelectedValue("edit");
      setIsEmpty(false);
    }
  }

  type Title = {
    [key: string]: string;
  };

  useEffect(() => {
    if (selectedValue === "baixa" && showContent) {
      setIsBaixa(true);
    }
  }, [selectedValue]);

  const radioOptions: any = [
    {
      title: "Baixa",
      description: "De baixa maunal no boleto por receber pagamento direto",
      value: "0",
    },
    {
      title: "Cancelar",
      description: "Cancele seu boleto gerado antes do pagamento",
      value: "1",
      disabled:
        dataBillet.status === "canceled" || dataBillet.status === "canceling",
    },
    {
      title: "Alterar Vencimento",
      description: "Edite a data de vencimento do boleto",
      value: "2",
      disabled:
        dataBillet.status === "canceled" || dataBillet.status === "canceling",
    },
  ];

  const secondRadioOptions = [
    {
      title: "Gerar relatório (PDF)",
      description: "Gere relatório",
      value: "0",
      disabled: true,
    },
    {
      title: "Gerar relatório XLSX",
      description: "Edite a data de vencimento do boleto",
      value: "1",
    },
    {
      title: "Baixar boleto",
      description: "Edite a data de vencimento do boleto",
      value: "2",
    },
  ];

  const { values, errors, setFieldValue, handleBlur, handleChange, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: FormSchema,
      onSubmit: (values) => {},
    });

  const handleToggleModal = () => {
    setOpenDetailModal(false);
    setShowContent(false);
    setIsBaixa(false);
    setSelectedValue("");
  };

  const renderContent: any = {
    edit: (
      <div className="row">
        <div className="col-6">
          <Input
            type="date"
            label="Data de vencimento"
            name="due_date"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.due_date}
            required
            error={
              touched.due_date &&
              errors.due_date &&
              "A data de vencimento não pode ser menor ou igual a data atual"
            }
          />
        </div>

        <div className="col-6">
          <Input
            type="date"
            label="Data limite para pagamento"
            name="deadline_date"
            onChange={handleChange}
            value={values.deadline_date}
            onBlur={handleBlur}
            error={
              touched.deadline_date &&
              errors.deadline_date &&
              "A data limite não pode ser menor do que a data de vencimento"
            }
            required
          />
        </div>

        <div className="d-flex w-100 justify-content-between">
          <Button
            mode="phantom"
            label="Cancelar"
            onClick={() => {
              setShowContent(false);
              setIsBaixa(false);
            }}
          />
          <Button
            mode="main"
            label="Concluir"
            loading={isLoading}
            onClick={() => {
              EditDueDateBillet();
            }}
            disabled={values.due_date === "" || values.deadline_date === ""}
          />
        </div>
      </div>
    ),

    baixa: (
      <>
        <InputCurrency
          fontSize="normal"
          inputSize="large"
          label="Valor"
          name="amount"
          value={String(values.amount)}
          onChange={handleChange}
        />
        <Input
          type="textarea"
          label="Descrição (opicional)"
          name="description"
          value={values.description}
          onChange={handleChange}
        />
        <div className="w-100 d-flex justify-content-between pt-5">
          <Button
            mode="phantom"
            label="Cancelar"
            onClick={() => {
              setShowContent(false);
              setIsBaixa(false);
            }}
          />
          <Button
            mode="main"
            label="Concluir"
            loading={isLoading}
            onClick={() => {
              setBaixaBillet();
            }}
            disabled={!values.amount || values.amount === 0}
          />
        </div>
      </>
    ),
  };

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  const setBaixaBillet = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost<setBaixa>({
        route: "charge/billet-instruction/",
        body: {
          amount: convertStringToNumber(values.amount as any),
          description: values.description,
          billet: dataBillet.id,
          type: "baixa",
          deadline_date: "2023-10-03T03:00:00.000Z",
          due_date: "2023-10-03T03:00:00.000Z",
        },
        token: true,
      });
      setIsLoading(false);
      getBillets({ from_date: fromDate, to_date: todayFormatted });
      setSelectedValue("");
      setShowContent(false);
      setOpenDetailModal(false);
      showToast("Baixa efetuada com sucesso!", "", "success");
    } catch {
      setIsLoading(false);
      setSelectedValue("");
      setShowContent(false);
    }
  };

  const EditDueDateBillet = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost({
        route: "charge/billet-instruction/",
        body: {
          amount: dataBillet.amount,
          description: values.description,
          billet: dataBillet.id,
          type: "alteracao_vencimento",
          deadline_date: values.deadline_date,
          due_date: values.due_date,
        },
        token: true,
      });
      setIsLoading(false);
      setOpenDetailModal(false);
      showToast(
        "Pedido de alteração concluído",
        "A alteração pode levar alguns minutos para ser concluída",
        "success",
        () => null,
        true,
        200000
      );
      setSelectedValue("");
      setShowContent(false);
      getBillets({ from_date: fromDate, to_date: todayFormatted });
    } catch {
      setIsLoading(false);
      setSelectedValue("");
      setShowContent(false);
    }
  };

  return (
    <div className="p-4 vh-100">
      {!showContent && (
        <>
          <RadioGroup
            // emptyValue={isEmpty}
            display="row"
            options={radioOptions}
            wrap
            onChange={(value) => handleChangeTille(value)}
          />
          {/* <Spacer bottom={10} />
          <RadioGroup
            // emptyValue={isEmpty}
            display="row"
            options={secondRadioOptions}
            wrap
            onChange={(value) => handleChangeTille(value)}
          /> */}
          <div className="d-flex w-100 justify-content-between pt-5">
            <Button
              mode="border"
              label={"Cancelar"}
              onClick={handleToggleModal}
            />
            <Button
              mode="main"
              label="Avançar"
              onClick={() => {
                if (selectedValue === "cancel") {
                  setOpenDetailModal(false);
                }
                setShowContent(true);
              }}
              disabled={selectedValue === ""}
            />
          </div>
        </>
      )}

      {selectedValue !== "cancel" &&
        showContent &&
        renderContent[selectedValue]}
    </div>
  );
};

export default EditCollection;
