import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  ReturnButton,
  Spacer,
  SummaryPanel,
} from "@zilliondigital/mirage-ui";
import RefreshIcon from "~/assets/icons/buttons/Ic_functional_refresh.png";
import FilterIcon from "~/assets/icons/buttons/filter_alt_black_24dp.png";
import XIcon from "~/assets/icons/buttons/x.png";
import { useEffect, useMemo, useState } from "react";

import TransfersFilter from "./TransfersFilter";
import useTransfers from "~/hooks/Transfers/useTransfers";
import NewTransfer from "./NewTransfer/NewTransfer";
import TransfersTransactions from "./TransfersTransactions";
import TransfersEmpty from "./TransfersEmpty";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Title, TransfersInfoRenderLabel } from "../app-content.styles";
import Paginator from "~/components/Paginator";
import {
  convertStringToNumber,
  formatDate,
  formatNumberToCurrencyWithoutSimbol,
  getFirstAndLastDayOfMonth,
  getToday,
} from "~/utils";
import ButtonFilters from "~/components/ButtonsFilters";
import { transactions } from "~/data/transactions";
import useAccount from "~/hooks/useAccount";
import ShowOperationModal from "./TransfersDetailsModal";
import LastDaysFilter from "~/components/LastDaysFilter";
import Copyright from "~/components/Copyright";
import theme from "~/themes/theme";
import { format, subDays } from "date-fns";

type TransferStructureProps = {
  isLoading?: boolean;
  isEmpty?: boolean;
};

const GeneralContainerData = styled.div<TransferStructureProps>`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  flex-grow: ${({ isLoading, isEmpty }) => !isLoading && !isEmpty && "1"};
  height: ${({ isLoading, isEmpty }) => (isLoading || isEmpty) && "100%"};

  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .container-return-button {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .container-auxiliar {
    position: fixed;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div<TransferStructureProps>`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;

  height: ${({ isLoading, isEmpty }) =>
    isLoading ? "100%" : isEmpty && "600px"};
  height: ${({ isLoading, isEmpty }) => !isLoading && !isEmpty && ""};
`;

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const CancelTransfersInfoRender = (canceleds: number, total: number) => {
  return (
    <div className="d-flex flex-column  w-100 align-items-end">
      <TransfersInfoRenderLabel>
        {canceleds} <span>canceladas</span>
      </TransfersInfoRenderLabel>
      <TransfersInfoRenderLabel>
        {((canceleds / total) * 100).toFixed()}%<span> do total</span>
      </TransfersInfoRenderLabel>
    </div>
  );
};

const ScheduleTransfersInfoRender = () => {
  return (
    <div className="d-flex flex-column  w-100 align-items-end">
      <TransfersInfoRenderLabel>
        0 <span>agendadas</span>
      </TransfersInfoRenderLabel>
      <TransfersInfoRenderLabel>
        0%<span> do total</span>
      </TransfersInfoRenderLabel>
    </div>
  );
};

const TransfersStructure = (props: TransferStructureProps) => {
  const navigate = useNavigate();

  const {
    mainAccount,
    beforeDays,

    verifyPermission,
    getBalance,
  } = useAccount();

  const {
    setOpenFilterModal,

    setOpenNewTransferModal,
    getTransfers,
    isLoading,
    transfersData,
    openFilterModal,
    openNewTransferModal,
    openTransfersDetailsModal,
    filters,
    setFilters,

    filterParams,
    setFetchParams,

    filtersData,
    key,
    setKey,

    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
  } = useTransfers();

  const canTransfer = verifyPermission("ba_can_create_transfer");

  const hasModalOpen =
    openNewTransferModal || openFilterModal || openTransfersDetailsModal;

  const TransfersInfoRender = (dones: number, total: number) => {
    return (
      <div className="d-flex flex-column  w-100 align-items-end">
        <TransfersInfoRenderLabel>
          {dones}
          <span> enviadas</span>
        </TransfersInfoRenderLabel>
        <TransfersInfoRenderLabel>
          {((dones / total) * 100).toFixed()}% <span>do total</span>
        </TransfersInfoRenderLabel>
      </div>
    );
  };

  const totalTransfers = useMemo(() => {
    const totalDones = transfersData.filter((transaction) =>
      [
        "done",
        "paid",
        "pending",
        "processing",
        "schedule",
        "scheduled",
      ].includes(transaction.status)
    );

    const totalDonesAmount = totalDones.reduce(
      (total, transaction) => total + convertStringToNumber(transaction.amount),
      0
    );

    const totalCanceleds = transfersData.filter((transaction) =>
      ["canceled"].includes(transaction.status)
    );

    const totalCanceledsAmount = totalCanceleds.reduce(
      (total, transaction) => total + convertStringToNumber(transaction.amount),
      0
    );

    return {
      totalDones,
      totalCanceleds,
      totalCanceledsAmount,
      totalDonesAmount,
    };
  }, [transfersData]);

  const TransfersPanel = [
    {
      label: "Transferências efetuadas",
      balance: `R$ ${formatNumberToCurrencyWithoutSimbol(
        totalTransfers?.totalDonesAmount.toFixed(2)
      )} `,
      secondaryRender: () =>
        TransfersInfoRender(
          totalTransfers?.totalDones.length ?? 0,
          transfersData.length ?? 0
        ),
    },
    {
      label: "Transferências agendadas",
      balance: " R$ 0,00",

      secondaryRender: () => ScheduleTransfersInfoRender(),
    },

    {
      label: "Transferências canceladas",
      balance: `R$ ${formatNumberToCurrencyWithoutSimbol(
        totalTransfers?.totalCanceledsAmount.toFixed(2)
      )} `,

      secondaryRender: () =>
        CancelTransfersInfoRender(
          totalTransfers?.totalCanceleds.length ?? 0,
          transfersData.length ?? 0
        ),
    },
  ];

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  useEffect(() => {
    getTransfers({ from_date: fromDate, to_date: todayFormatted });

    getBalance();
  }, [mainAccount]);

  const quantityPages = [
    {
      label: "20 por página",
      value: "1",
      onClick: () => {
        setRowsPerPage(20);
      },
    },
    {
      label: "50 por página",
      value: "2",
      onClick: () => {
        setRowsPerPage(50);
      },
    },
    {
      label: "100 por página",
      value: "3",
      onClick: () => {
        setRowsPerPage(100);
      },
    },
  ];

  type LabelFiltersType = {
    [key: string]: string;
  };

  const labelsFilters: LabelFiltersType = {
    contact_name: `Contato: ${filtersData?.contact_name}`,
    transactionValueFrom: `De R$ ${filtersData?.transactionValueFrom}`,
    transactionValueTo: `Até R$ ${filtersData?.transactionValueTo}`,
    transactionDateFrom: `De: ${formatDate(filtersData?.transactionDateFrom)}`,
    transactionDateTo: `Até: ${formatDate(filtersData?.transactionDateTo)}`,
    done: "Transferido",
    scheduled: "Agendado",
    canceled: "Cancelado",
    error: "Ocorreu um erro",
    ted: "Ted",
    tev: "Transferência entre contas",
  };

  const filterOptions = filters.map((key) => ({
    label: labelsFilters[key as keyof LabelFiltersType],
    onClick: () => {
      const handleRemoveFilter = () => {
        const newFilters = filters.filter((_filter) => _filter !== key);
        setKey(key);
        setFilters(newFilters);
      };
      handleRemoveFilter();
    },
  }));

  // const handleClearFilters = () => {
  //   setFilters([]);

  //   getTransfers();
  // }; //comentar depois

  const menusBreadCrumb = [{ label: "Transferências", href: "app/statements" }];

  // useEffect(() => {
  //   const updatedParams = { ...filterParams };

  //   if (key === "contact_name") {
  //     delete updatedParams.contact;
  //   }

  //   if (key === "transactionValueFrom") {
  //     updatedParams.price_min = 0;
  //   }

  //   if (key === "transactionValueTo") {
  //     delete updatedParams.price_max;
  //   }

  //   setFetchParams(updatedParams);

  //   getTransfers(updatedParams);
  // }, [key]);

  return (
    <ParentContainer
      className={hasModalOpen ? "overflow-hidden" : ""}
      style={
        openNewTransferModal || openFilterModal || isLoading
          ? { paddingRight: "15px" }
          : {}
      }
    >
      {" "}
      <GeneralContainerData
        {...props}
        isLoading={isLoading}
        isEmpty={transfersData.length <= 0}
      >
        <div className="d-flex flex-column  container-fluid ps-5 pe-5  w-100 h-100 ">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />
          <Spacer top={30} />
          <ContainerData
            className="container-fluid ps-4 pe-4"
            isLoading={isLoading}
            isEmpty={transfersData.length <= 0}
          >
            <div className="d-flex justify-content-between p-4 ">
              <Title>Transferências</Title>
              <div>
                <div className="d-flex flex-column flex-lg-row">
                  {!isLoading && (
                    <>
                      <Button
                        mode="main"
                        label="Nova transferência"
                        onClick={() => setOpenNewTransferModal(true)}
                        disabled={!canTransfer}
                      />
                      <Spacer right={10} />
                    </>
                  )}
                  {/* //comentar   */}
                  {transfersData.length > 0 && (
                    <>
                      <div className="d-block d-lg-none">
                        <Spacer bottom={10} />
                      </div>

                      {/* <Button
                        mode="border"
                        onClick={() => {
                          filters.length > 0
                            ? handleClearFilters()
                            : setOpenFilterModal(true);
                        }}
                        danger={filters.length > 0 ? true : false}
                        label={
                          filters.length <= 0
                            ? "Aplicar filtros"
                            : "Limpar filtros"
                        }
                        icon={filters.length > 0 ? XIcon : FilterIcon}
                      /> */}
                      <Spacer right={10} />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="ps-4 pe-4">
              {(transfersData.length > 0 || isLoading) &&
                filters.length <= 0 && (
                  <div className="row">
                    <SummaryPanel
                      panel={TransfersPanel}
                      isLoading={isLoading}
                    />
                  </div>
                )}

              <Spacer bottom={isLoading ? 50 : filters.length > 0 ? 0 : 20} />
              <div className="d-flex w-100 align-items-start justify-content-between">
                <div className="d-flex">
                  <div className="">
                    <Button
                      mode="border"
                      label="Atualizar"
                      icon={RefreshIcon}
                      onClick={() =>
                        getTransfers({
                          from_date: fromDate,
                          to_date: todayFormatted,
                        })
                      }
                      iconColorFilter={theme.colors.primary.mainFilter}
                    />
                  </div>

                  <Spacer left={15} />
                  {transfersData.length > 0 && (
                    <div>
                      <ButtonDropdown
                        mode="border"
                        label={`${rowsPerPage} por página`}
                        options={quantityPages}
                      />
                    </div>
                  )}
                </div>

                {filters.length > 0 && (
                  <div className="pe-4 ps-4">
                    <ButtonFilters
                      onClickMore={() => setOpenFilterModal(true)}
                      filters={filterOptions}
                    />
                  </div>
                )}

                <div style={{ marginLeft: "auto" }}>
                  <div>
                    <LastDaysFilter
                      setter={(_from, _to) => {
                        setFetchParams({ from_date: _from, to_date: _to });
                      }}
                      executeFetch={getTransfers}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Spacer bottom={20} />
            {transfersData.length > 0 || isLoading ? (
              <div className="ps-3 pe-3">
                <TransfersTransactions />
              </div>
            ) : (
              !isLoading && <TransfersEmpty />
            )}
          </ContainerData>
          <div className={transfersData.length > 0 ? "" : "d-none"}>
            <Paginator
              list={transfersData.length > 0 ? transfersData : []}
              rowsPerPage={rowsPerPage}
              page={page}
              pageSetter={setPage}
            />
          </div>
          <Spacer top={transfersData.length > 0 ? 20 : 40} />

          <Copyright />

          <Spacer top={30} />
        </div>
      </GeneralContainerData>
      <NewTransfer />
      <TransfersFilter />
      <ShowOperationModal />
    </ParentContainer>
  );
};

export default TransfersStructure;
