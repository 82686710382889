import React from "react";
import ContentLoader from "react-content-loader";

const SkeletonLine = (props: any) => {
  return (
    <ContentLoader
      speed={2}
      width={50}
      height={40}
      viewBox="0 0 100 60"
      backgroundColor="#d9d9d9"
      foregroundColor="#ededed"
      {...props}
    >
      <rect x="0" y="56" rx="3" ry="3" width="100" height="50" />
    </ContentLoader>
  );
};

export default SkeletonLine;
