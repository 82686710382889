import SignupUserStructure from "~/pages/Signup/SignupUser/SignupUserStructure";
import SignupBasicData from "~/pages/Signup/SignupUser/SignupUserData";
import SignupBasicPassword from "~/pages/Signup/SignupUser/SignupUserPassword";
import SignupUserProfile from "~/pages/Signup/SignupUser/SignupUserProfile";

import SignupCompanyStructure from "~/pages/Signup/SignupCompany/SignupCompanyStructure";
import GeneralCompany from "~/pages/Signup/SignupCompany/General";
import AddressCompany from "~/pages/Signup/SignupCompany/Address";
import RepresentativesCompany from "~/pages/Signup/SignupCompany/Representatives";
import AttachmentsCompany from "~/pages/Signup/SignupCompany/Attachments";
import SecurityCompany from "~/pages/Signup/SignupCompany/Security";
import FinishCompany from "~/pages/Signup/SignupCompany/Finish";

import SignupPersonalStructure from "~/pages/Signup/SignupPersonal/SignupPersonalStructure";
import GeneralPersonal from "~/pages/Signup/SignupPersonal/General";
import AddressPersonal from "~/pages/Signup/SignupPersonal/Address";
import AttachmentsPersonal from "~/pages/Signup/SignupPersonal/Attachaments";
import SecurityPersonal from "~/pages/Signup/SignupPersonal/Done";
import Done from "~/pages/Signup/SignupPersonal/Done";
import DoneCompany from "~/pages/Signup/SignupCompany/DoneCompany";
import SignupBasicContact from "~/pages/Signup/SignupUser/SignupUserContact";
import Intro from "~/pages/Signup/SignupCompany/Intro";

export default (defaultOptions = {}): any => ({
  path: "signup",
  children: [
    {
      path: "user",
      element: <SignupUserStructure />,

      children: [
        {
          path: "personal-data",
          element: <SignupBasicData />,
        },
        {
          path: "contact-data",
          element: <SignupBasicContact />,
        },
        {
          path: "password",
          element: <SignupBasicPassword />,
        },
        {
          path: "profile",
          element: <SignupUserProfile />,
        },
      ],
      ...defaultOptions,
    },
    {
      path: "company",
      element: <SignupCompanyStructure />,
      children: [
        {
          path: "intro",
          element: <Intro />,
        },
        {
          path: "general",
          element: <GeneralCompany />,
        },
        {
          path: "representatives",
          element: <RepresentativesCompany />,
        },
        {
          path: "address",
          element: <AddressCompany />,
        },
        {
          path: "attachments",
          element: <AttachmentsCompany />,
        },
        {
          path: "done",
          element: <DoneCompany />,
        },
      ],
      ...defaultOptions,
    },
    {
      path: "personal",
      element: <SignupPersonalStructure />,
      children: [
        {
          path: "general",
          element: <GeneralPersonal />,
        },
        {
          path: "address",
          element: <AddressPersonal />,
        },

        {
          path: "attachments",
          element: <AttachmentsPersonal />,
        },
        {
          path: "done",
          element: <Done />,
        },
      ],
      ...defaultOptions,
    },
  ],
});
