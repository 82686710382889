const bankDocuments: Record<number, string> = {
  0: "front_document",
  1: "cpf",
  2: "proof_residence",

  3: "cnh_front",
  4: "cnpj",
  5: "social_contract",

  6: "letter_of_attorney",
  7: "back_document",
  8: "document_error",
  10: "identity_document",
  11: "constitutive_act",
  12: "kyc_report",
  13: "other",

  14: "selfie",
  15: "election_minute",
  16: "cnh_back",
  17: "proof_annual_billing_pj",
};

const bankDocumentsName: Record<string, string> = {
  social_contract: "Contrato Social",
  proof_residence: "Comprovante de endereço",
  front_document: "RG (Frente)",
  back_document: "RG (Verso)",
  image: "Imagem",
  selfie: "Selfie",
  cpf: "CPF",
  cnh: "CNH",
  cnh_front: "CNH (Frente)",
  cnh_back: "CNH (Verso)",
  cnpj: "CNPJ",
  letter_of_attorney: "Procuração",
  document_error: "Erro de documento",
  identity_document: "Documento de Identidade (RG)",
  constitutive_act: "Ato constitutivo",
  kyc_report: "Relatório KYC",
  other: "Outro",
  election_minute: "Ata de eleição",
  proof_annual_billing_pj: "Comprovante de faturamento anual PJ",
  fund_bylaws: "Estatuto do fundo",
  funds_regulating: "Regulamentação de Fundos",
};

const fitbankDocumentStatus: Record<number, string> = {
  0: "Aguardando validação",
  1: "Validado",
  2: "Inválido",
  3: "Expirado",
  4: "Enviado",
  5: "Reenviado",
  6: "Reprovado",
  7: "Erro",
  8: "Inexistente",
  9: "Suspenso",
  10: "Resultado de tipificação",
};

const getFitbankDocumentName = (document: number) => {
  const document_by_id = bankDocuments[document];

  return bankDocumentsName[document_by_id];
};

const getFitbankDocumentStatusName = (status: number) => {
  return fitbankDocumentStatus[status];
};

export {
  bankDocuments,
  bankDocumentsName,
  getFitbankDocumentName,
  fitbankDocumentStatus,
  getFitbankDocumentStatusName,
};
