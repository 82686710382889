import constate from "constate";
import { useState } from "react";

const [SchedulePaymentsProvider, useSchedulePayments] = constate(() => {
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [openNewSchedulePaymentsModal, setOpenNewSchedulePaymentsModal] =
    useState<boolean>(false);

  const handleCloseNewSchedulePaymenstModal = () => {
    setOpenNewSchedulePaymentsModal(false);
  };

  return {
    openFilterModal,
    setOpenFilterModal,
    openNewSchedulePaymentsModal,
    setOpenNewSchedulePaymentsModal,
    handleCloseNewSchedulePaymenstModal,
  };
});

export { SchedulePaymentsProvider };

export default useSchedulePayments;
