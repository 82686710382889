import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  NotControlledToolTip,
  Spacer,
  TabMenu,
} from "@zilliondigital/mirage-ui";
import { useCallback, useEffect, useState } from "react";
import OpenAccordionIcon from "~/assets/icons/Actions/Chevron.png";
import CloseAccordionIcon from "~/assets/icons/Actions/Chevron2.png";
import { ImgEye } from "../../app-content.styles";
import { useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import { ContainerData } from "~/pages/App/app-content.styles";
import styled, { keyframes } from "styled-components";
import theme from "~/themes/theme";
import PenIcon from "~/assets/icons/contacts/pencil.png";

import usePermission from "~/hooks/usePermission";
import useAuthority from "~/hooks/useAuthority";
import Copyright from "~/components/Copyright";
import Paginator from "~/components/Paginator";
import DeleteIcon from "~/assets/icons/delete.png";

import SubMenu from "~/components/SubMenu";
import ApiService from "~/services/api";
import DefaultPackpage from "./PackpagesTariffsList";
import useTariffs from "~/hooks/useTariffs";
import DataNewPackpage from "./NewPackpage/Data";
import PersonalizedTariffs from "./PersonalizedTariffs";

import Description from "~/components/Texts/Description";
import DeletePackpageModal from "./DeletePackpageModal";
import DeleteHolderModal from "./EditPackpages/DeleteHolderModal";
import EditTariffs from "./EditTariffs";

const renderData = [<DefaultPackpage />, <PersonalizedTariffs />];

const TariffsStructure = () => {
  const navigate = useNavigate();
  const {
    newTariff,
    stepIndex,
    clientsData,
    page,
    setPage,
    value,
    rowsPerPage,
    setTariffsData,
    packpagesTariffs,
    setPackpagesTariffs,
    setLengthDefaultPF,
    setLengthDefaultPJ,
  } = useTariffs();

  const fetchGetHoldersDefaultPackpage = useCallback(async () => {
    try {
      const { data: dataPF } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/get-default-holders/",
        token: true,
        params: {
          document_type: "cpf",
        },
      });

      setLengthDefaultPF(dataPF.data.length);

      const { data: dataPJ } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/get-default-holders/",
        token: true,
        params: {
          document_type: "cnpj",
        },
      });

      setLengthDefaultPJ(dataPJ.data.length);
    } catch {}
  }, []);

  const getTariffsPackpage = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/",
        token: true,
      });

      setPackpagesTariffs(data);
    } catch (error) {
      console.error("Erro ao buscar cestas de tarifas:", error);
    }
  }, [setPackpagesTariffs]);

  useEffect(() => {
    fetchGetHoldersDefaultPackpage();
  }, [packpagesTariffs]);

  useEffect(() => {
    getTariffsPackpage();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ContainerData className="p-4">
              {newTariff ? (
                <>
                  <DataNewPackpage />
                </>
              ) : (
                <>
                  {" "}
                  <div className="pe-2 flex-grow-1 d-flex justify-content-between">
                    <Title>Defina as cesta de tarifas</Title>

                    <Button
                      mode="main"
                      label="Criar Cesta de Tarifas"
                      onClick={() =>
                        navigate("/app/myspace/tariffs/new-packpage")
                      }
                    />
                  </div>
                  <div className="">{renderData[stepIndex]}</div>
                </>
              )}
            </ContainerData>

            <div className={clientsData.length > 0 ? "" : "d-none"}>
              {value !== "" && (
                <Paginator
                  list={clientsData.length > 0 ? clientsData : []}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  pageSetter={setPage}
                />
              )}
              <Spacer top={clientsData.length > 0 ? 2 : 40} />
            </div>
          </div>
        </div>
      </div>

      <DeletePackpageModal />
      <DeleteHolderModal />
    </>
  );
};

export default TariffsStructure;
