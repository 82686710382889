import styled from "styled-components";


export const CardsDashboardButtonDiv = styled.div`
  width: 160px; 
  
  &>button {
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }


  @media screen and (max-width: 768px) {
    width: 50%;
  }
`

export const CardsDashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 1.5rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const CardsDashboardButtonWrapper = styled.div`
  display: flex;
  width: auto;
  gap: 16px;
  
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`


export const CardsMyCardsButtonDiv = styled.div`
  @media screen and (max-width: 768px) {
    width: 50%;
  }
`

export const RecentTransactionsTitle = styled.h2`
  color: #000;
  font-family: "IBM Plex Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  margin-top: 16px;
`

//Activate Card Section
export const ActivateCardContainer = styled.div`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

export const ActivateCardTitle = styled.h2`
  color: '#161616';
  font-family: "IBM Plex Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`

export const ActivateCardDescription = styled.h2`
  font-family: "IBM Plex Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
`

//CHECKING STEP
export const ActivateCardCheckingStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 450px;
  align-items: center;
  justify-content: center;
`

export const ActivateCardCheckingButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

export const ActivateCardCheckingImg = styled.img`
  width: 96px;
  height: 96px;
`;

//RECEIVED STEP
export const ActivateCardReceivedStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 100px;
  padding-bottom: 150px;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const ActivateCardReceivedWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
`

export const ActivateCardInputWrapper = styled.div`
  width: 327px;

  &>input {
    padding: 16px;
    width: 327px;
    border-radius: 8px;
    border: none;
    border-bottom: 1px solid #8D8D8D;
    background: #F4F4F4;

    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;

    color: '#161616';
  }
`

export const ActivateCardInputError = styled.p`
  font-family: "IBM Plex Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  color: #FF7610;
  padding-top: 8px;
`


//SUCCESS STEP
export const ActivateCardSuccessStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 450px;
  align-items: center;
  justify-content: center;
`

export const ActivateCardSuccessImg = styled.img`
  width: 96px;
  height: 96px;
`;

export const ActivateCardSuccessTitle = styled.h2`
  color: #161616;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; 
`

//NOT_RECEIVED_CARD
export const ActivateCardNotReceivedImg = styled.img`
  width: 96px;
  height: 96px;
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export const ActivateCardNotReceivedCircle = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 100%;
  background-color: #FFE5D8;
  position: absolute;
  bottom: -8px;
  right: -8px;
`;

export const ActivateCardImgsWrapper = styled.div`
  position: relative;
  width: 96px;
  height: 96px;
`

export const ActivateCardNotReceivedTitle = styled.h2`
  color: #161616;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; 
`

export const ActivateCardNotReceivedDescription = styled.p`
  font-family: "IBM Plex Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
`

//BLOCK CARD CONTIAINER
export const BlockCardInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0rem;
  margin: 0px;
`

export const BlockCardDescription = styled.p`
  font-family: "IBM Plex Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  margin: 0px;
`

export const BlockCardInput = styled.input`
  padding: 16px;
  width: 327px;
  border-radius: 8px;
  border: none;
  border-bottom: 1px solid #8D8D8D;
  background: #F4F4F4;

  font-family: "IBM Plex Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;

  color: '#161616';
`


//CREATE CARD STEPS
export const CreateCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

export const CreateCardInformationStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 450px;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`

export const CardTypeLabelText = styled.label`
  width: 100%;
  color: #666;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
` 

export const CreatingCardInfoBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  justify-content: center;
`

export const CreatingCardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
`

export const CreatingCardInfoInput = styled.input`
  display: none;
`

export const  CreatingCardInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 250px;
  height: 145px;
  box-shadow: 0 0 1px 1px #EBEBEB;
  padding:16px;

  &.CHECKED {
    box-shadow: 0 0 2px 2px #FF7610;
  }

  img {
    width: 16px;
    height: 16px
  }
  
  &.CHECKED img {
    width: 32px;
    height: 32px
  }

  h4 {
    color: #3B3B3B;
    font-family: Inter;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }

  p {

    color: #929292;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  :hover{
    cursor: pointer;
  }
  
`

export const CreateCardInfosInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding-top: 10px;

  label {
    margin-bottom: -20px;
    color: #666;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const CreateCardInfoFooterBtnWrapper  = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  margin-top: 64px;
`

//CREATE CARD DELIVERY INFO STEP
export const CreateCardDeliveryStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 450px;
  padding-top: 48px;
  padding-bottom: 48px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
`

export const CreatingCardDeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  p {
    color: #666;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  h4 {
    color: #242424;
    font-family: Inter;
    font-size: 17px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
  }
`

export const CreatingCardDefaultAddressBlock = styled.div`
  
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }
`

export const CreatingCardAddressOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  label {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-start;

    margin: 0;
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }

  label input {
    margin-top: 2px;
  } 
`

export const CreatingCardAddressInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div div label {
    margin: 0;
  }
`

export const CreatingCardAddressInputLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10%;
  width: 100%;

  margin-bottom: -16px;
`

export const CreateCardAddressOtherFooterBtnWrapper  = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  margin-top: 24px;
`

export const CreateCardInputError = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  color: #FF7610 !important;
  padding-top: 8px;
`

//CONFIRMATION

export const CreatingCardConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  h4 {
    color: #242424;
    font-family: Inter;
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
  }
`

export const CreatingCardConfirmationTablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h4 {
    color: #3B3B3B;
    font-family: Inter;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
`

export const CreatingCardConfirmationTableField = styled.p`
  color: #666;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
`

export const CreatingCardConfirmationTableValue = styled.p`
  color: #242424;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
`

export const CreateCardConfirmationFooterBtnWrapper  = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  margin-top: 32px;
`

export const CreateCardSuccessStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 450px;
  align-items: center;
  justify-content: center;
`

export const CreateCardSuccessImg = styled.img`
  width: 96px;
  height: 96px;
`;

export const CreateCardSuccessTitle = styled.h2`
  color: #161616;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; 
`

export const CreateCardSuccessSubTitle = styled.h4`
  color: #666;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
`

export const CreateCardSuccessWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
`


//Card details modal
export const DetailsCardInputPassword = styled.input`
  padding: 16px;
  width: 327px;
  border-radius: 8px;
  border: none;
  border-bottom: 1px solid #8D8D8D;
  background: #F4F4F4;

  font-family: "IBM Plex Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;

  color: '#161616';
`