import {
  Button,
  CustomSelect,
  Input,
  Spacer,
} from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";

import usePix from "~/hooks/PIX/usePix";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import * as Yup from "yup";
import {
  CpfRegex,
  INVALID_FORMAT,
  PhoneRegex,
  REQUIRED_LABEL,
} from "~/services/validators";
import { useFormik } from "formik";
import ApiService from "~/services/api";
import { onlyNumbers } from "~/services/utils";
import { useEffect, useState } from "react";
import useToast from "~/hooks/useToast";
import { motion } from "framer-motion";
import styled from "styled-components";
import TableContacts from "~/components/TableContacts";
import TableContactsPix from "./TableContactsPix";
import theme from "~/themes/theme";
import useModal from "~/hooks/useModal";
import documentNumberValidator from "~/utils/validateDocNumber";
import useAccount from "~/hooks/useAccount";

const ContainerTableContacts = styled.div`
  overflow-y: auto;
  height: 345px;
`;

const ContainerLabel = styled.div`
  cursor: pointer;
`;

type FormValues = {
  document_number: string;
  email: string;
  phone: string;
  random_key: string;
};

const initialValues: FormValues = {
  document_number: "",
  email: "",
  phone: "",
  random_key: "",
};

const FormSchema = Yup.object().shape({
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(CpfRegex, INVALID_FORMAT),
  email: Yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
  phone: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(PhoneRegex, INVALID_FORMAT),
  random_key: Yup.string(),
});

const pixKeyType = [
  { label: "CPF/CNPJ", value: "1" },
  { label: "Celular", value: "2" },
  { label: "Email", value: "3" },
  { label: "Chave aleatória", value: "4" },
];

const TransferWithPixData = () => {
  const { setOpenTransferWithPixModal } = usePix();
  const {
    setManualPix,
    handleNextStep,
    setDataNewPixTransfer,
    isLoadingData,
    setIsPixWithSelectedPartner,
    setIsLoadingData,
    contactsPixData,
    contactSelectedPixData,
    setStepIndex,
    setSelectedIndex,
    setContactSelectedPixData,
    setSelectedRow,
  } = useTransferWithPix();

  const { showToast } = useToast();
  const [typeOfKey, setTypeOfKey] = useState<string>("");

  const [noKeySelected, setNoKeySelected] = useState(false);
  const [noKeyValue, setNoKeyValue] = useState(false);
  const { getBalance } = useAccount();

  const { values, errors, handleBlur, touched, handleChange, resetForm } =
    useFormik({
      initialValues: initialValues,
      validationSchema: FormSchema,
      onSubmit: (values) => {
        handleNextStep();
      },
    });

  const handleManualPix = () => {
    setManualPix(true);
  };

  type Types = {
    [key: string]: string;
  };

  const Labels: Types = {
    document_number: "Informe o CPF/CNPJ",
    telefone: "Informe o celular",
    email: "Informe o email ",
    evp: "Informe a chave aleatória",
  };

  const Masks: Types = {
    document_number:
      values.document_number.length > 14
        ? "99.999.999/9999-99"
        : "999.999.999-9999999",
    telefone: "(99) 99999-9999",
  };

  const Values: Types = {
    document_number: values.document_number,
    telefone: values.phone,
    email: values.email,
    evp: values.random_key,
  };

  const Names: Types = {
    document_number: "document_number",
    telefone: "phone",
    email: "email",
    evp: "random_key",
  };

  const Keys: Types = {
    document_number: onlyNumbers(values.document_number),
    telefone: onlyNumbers(values.phone),
    email: values.email,
    evp: values.random_key,
  };

  const handleChangeSelect = (optionSelected: any) => {
    const option = optionSelected.target.value;

    if (option === "") {
      setTypeOfKey("");
      resetForm();
      return;
    }

    if (option === "1") {
      setTypeOfKey("document_number");
      setSelectedIndex(-1);
      setContactSelectedPixData(undefined);
      setSelectedRow([-1]);
      setError("initialState");
    }
    if (option === "2") {
      setTypeOfKey("telefone");
      setSelectedIndex(-1);
      setContactSelectedPixData(undefined);
      setSelectedRow([-1]);
      setError("initialState");
    }
    if (option === "3") {
      setTypeOfKey("email");
      setSelectedIndex(-1);
      setContactSelectedPixData(undefined);
      setSelectedRow([-1]);
      setError("initialState");
    }
    if (option === "4") {
      setTypeOfKey("evp");
      setSelectedIndex(-1);
      setContactSelectedPixData(undefined);
      setSelectedRow([-1]);
      setError("initialState");
    }
  };

  const searchPixKey = async () => {
    setIsLoadingData(true);

    try {
      const { data: response } = await ApiService.HttpGet({
        route: "pix/search-key-account/",
        token: true,
        params: {
          key: Keys[typeOfKey],
          type:
            typeOfKey === "document_number" && Values[typeOfKey].length > 15
              ? "cnpj"
              : typeOfKey === "document_number" && Values[typeOfKey].length < 15
              ? "cpf"
              : typeOfKey,
        },
      });

      if (!response.error) {
        const { data } = response;
        setIsLoadingData(false);
        setDataNewPixTransfer(data);
        handleNextStep();
      }
    } catch (e: any) {
      setIsLoadingData(false);
      // showToast(e.data.message, "", "error");
    }
  };

  const [error, setError] = useState<string>("");
  useEffect(() => {
    const isValidDocument =
      values.document_number.length >= 14 &&
      documentNumberValidator(values.document_number);

    if (values.document_number.length < 14 && !isValidDocument) {
      setError("initialState");
    } else if (values.document_number.length >= 14 && isValidDocument) {
      setError("notError");
    } else if (values.document_number.length >= 14 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document_number]);

  useEffect(() => {
    if (Values[typeOfKey] !== "") {
      resetForm();
      setNoKeyValue(false);
    }

    if (typeOfKey !== "") {
      setNoKeySelected(false);
    }
  }, [typeOfKey]);

  const handleAdvanceSelectedContact = () => {
    setIsPixWithSelectedPartner(true);
    setStepIndex(0);
  };

  useEffect(() => {
    if (Values[typeOfKey] !== "") {
      setNoKeyValue(false);
    }

    if (Values[typeOfKey]?.length > 0) {
      setSelectedIndex(-1);
      setContactSelectedPixData(undefined);
      setSelectedRow([-1]);
    }
  }, [values]);

  const getPartners = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "pix/partners/",
        token: true,
      });
    } catch {}
  };

  useEffect(() => {
    getPartners();
    getBalance();
    setIsPixWithSelectedPartner(false);

    setSelectedRow([-1]);
    setContactSelectedPixData(undefined);
  }, []);

  const successRender: { [key: string]: boolean } = {
    document_number: error === "notError",
    telefone: values.phone.length > 13,
  };

  return (
    <>
      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.3 }}
      >
        <div className=" w-100  d-flex flex-column justify-content-start ps-4 pe-4 ">
          <Spacer bottom={20} />
          <Description>
            Selecione o tipo de chave que deseja efetuar a transferência
          </Description>

          <Spacer bottom={20} />
          <div className="row align-items-end">
            <div className="col-6">
              <CustomSelect
                label="Selecionar chave"
                placeholder="Selecione"
                options={pixKeyType}
                onChange={handleChangeSelect}
                error={noKeySelected && "Selecione um tipo de chave antes"}
                isSearchable={false}
              />
            </div>
            <div className="col-6">
              <Input
                type="text"
                name={Names[typeOfKey]}
                value={typeOfKey === "" ? "" : Values[typeOfKey]}
                label={typeOfKey === "" ? "" : Labels[typeOfKey]}
                placeholder={
                  typeOfKey === ""
                    ? "Selecione o tipo de chave primeiro"
                    : "Digite a chave pix"
                }
                onChange={handleChange}
                success={successRender[typeOfKey] || false}
                autoCapitalize="off"
                autoComplete="off"
                mask={Masks[typeOfKey]}
                disabled={typeOfKey === "" && true}
                error={
                  typeOfKey === "document_number"
                    ? error === "Documento inválido" && error
                    : noKeyValue && "Digite a chave pix"
                }
              />
            </div>
          </div>

          <ContainerLabel>
            <Description color={theme.colors.primary.main}>
              Contatos frequentes
            </Description>
          </ContainerLabel>

          <Spacer bottom={15} />
          <ContainerTableContacts>
            <TableContactsPix />
          </ContainerTableContacts>

          <Spacer bottom={40} />

          <div className="row">
            <div className="col-6">
              <Button
                mode="border"
                label="Cancelar transferência"
                onClick={() => {
                  typeOfKey;
                  setOpenTransferWithPixModal(false);
                }}
              />
            </div>
            <div className="d-flex justify-content-end col-6">
              <Button
                mode="border"
                label="Pix manual"
                onClick={() => {
                  setTypeOfKey("");
                  resetForm();
                  handleManualPix();
                }}
              />
              <Spacer right={20} />
              <Button
                mode="main"
                label="Avançar"
                disabled={
                  contactSelectedPixData === undefined &&
                  (typeOfKey === "" ||
                    typeOfKey === null ||
                    typeOfKey === "telefone") &&
                  values.phone.length < 14
                    ? true
                    : contactSelectedPixData === undefined &&
                      (typeOfKey === "" ||
                        typeOfKey === null ||
                        typeOfKey === "email") &&
                      !values.email.includes("@")
                    ? true
                    : contactSelectedPixData === undefined &&
                      (typeOfKey === "" ||
                        typeOfKey === null ||
                        typeOfKey === "document_number") &&
                      error !== "notError"
                    ? true
                    : contactSelectedPixData === undefined &&
                      (typeOfKey !== "" || typeOfKey !== null) &&
                      Values[typeOfKey].length <= 0
                    ? true
                    : contactSelectedPixData === undefined &&
                      (typeOfKey === "" || typeOfKey === null)
                }
                onClick={() =>
                  contactSelectedPixData !== undefined
                    ? handleAdvanceSelectedContact()
                    : typeOfKey === "" && contactSelectedPixData === undefined
                    ? setNoKeySelected(true)
                    : Values[typeOfKey] === "" &&
                      contactSelectedPixData === undefined
                    ? setNoKeyValue(true)
                    : contactSelectedPixData === undefined && searchPixKey()
                }
              />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default TransferWithPixData;
