import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Spacer } from "@zilliondigital/mirage-ui";
import { useEffect, useState } from "react";
import label from "~/labels";
import theme from "~/themes/theme";
import useSignupCompany from "~/hooks/Signup/useSignupCompany";

const DoneCompany = () => {
  const { setStep } = useSignupCompany();

  useEffect(() => {
    setStep("done");
  }, []);

  return (
    <div
      style={{ height: "60vh" }}
      className="d-flex flex-column justify-content-center  col-8  h-175"
    >
      {label.logoDoneSingup && (
        <div>
          <img src={label.logoDoneSingup} />
          <Spacer bottom={50} />
        </div>
      )}

      <span style={{ fontSize: "28px" }}>Pronto, agora é só aguardar...</span>
      <Spacer bottom={20} />
      <div className="d-flex justify-content-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
        >
          <rect
            width="96"
            height="96"
            fill="white"
            // style="mix-blend-mode:multiply"
          />
          <path
            d="M89.9999 4.92004H5.99992C5.40292 4.92004 4.91992 5.40304 4.91992 6.00004V90C4.91992 90.597 5.40292 91.08 5.99992 91.08H89.9999C90.5969 91.08 91.0799 90.597 91.0799 90V6.00004C91.0799 5.40304 90.5969 4.92004 89.9999 4.92004ZM88.9199 88.92H7.07992V7.08004H88.9199V88.92ZM23.9999 52.08C24.7319 52.08 25.4099 51.87 26.0069 51.531L41.9999 67.527L81.7649 27.762L80.2349 26.235L41.9999 64.473L27.5309 50.004C27.8699 49.41 28.0799 48.732 28.0799 48C28.0799 45.75 26.2499 43.92 23.9999 43.92C21.7499 43.92 19.9199 45.75 19.9199 48C19.9199 50.25 21.7499 52.08 23.9999 52.08ZM23.9999 46.08C25.0589 46.08 25.9199 46.941 25.9199 48C25.9199 49.059 25.0589 49.92 23.9999 49.92C22.9409 49.92 22.0799 49.059 22.0799 48C22.0799 46.941 22.9409 46.08 23.9999 46.08Z"
            fill={"black"}
          />
        </svg>
      </div>
      <Spacer bottom={20} />
      <span style={{ fontSize: "28px" }}>
        Seu cadastro foi concluído e está sob análise.
      </span>
    </div>
  );
};

export default DoneCompany;
