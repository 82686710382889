import {
  Button,
  CustomSelect,
  Input,
  InteractiveModal,
  Spacer,
} from "@zilliondigital/mirage-ui";

import ReactDOM from "react-dom";
import TitleModal from "./Texts/TitleModal";
import Description from "./Texts/Description";
import Padding from "./Padding";
import { useState } from "react";
import { genderOptions } from "~/data/options";
import { CustomSelectValue } from "~/services/utils";

type AsynPropsResolve = {
  message: string;
  option: string;
};

type AsyncProps = {
  resolve: (data: AsynPropsResolve) => void;
  reject: (reason: string) => void;
};

type Option = {
  label: string;
  value: string;
};

type ModalProps = {
  title?: string;
  description?: string;

  danger?: boolean;

  showConfirmButton?: boolean;
  confirmButtonLabel?: string;

  showCancelButton?: boolean;
  cancelButtonLabel?: string;

  hasReason?: boolean;
  hasOption?: Option[];
};

type AsyncConfirmModalProps = AsyncProps & {
  props: ModalProps;
};

const AsyncConfirmModal = ({
  resolve,
  reject,
  props,
}: AsyncConfirmModalProps) => {
  const {
    title = "Confirmação",
    description = "Deseja confirmar a operação?",
    danger = false,
    showConfirmButton = true,
    confirmButtonLabel = "Confirmar",
    showCancelButton = true,
    cancelButtonLabel = "Cancelar",
    hasReason = false,
    hasOption = [],
  } = props;

  const [message, setMessage] = useState<string>("");
  const [option, setOption] = useState<string>("");

  const handleClose = (reason: string) => {
    reject(`Não foi possível confirmar a operação. ${reason}`);
    removeDialog();
  };

  const handleConfirmToken = () => {
    resolve({ message, option });
    removeDialog();
  };

  return (
    <InteractiveModal
      open={true}
      size="md"
      toggleModal={() => handleClose("Janela fechado")}
    >
      <Padding top={72} left={72} right={72} bottom={72}>
        <div className="d-flex flex-column w-100">
          <div>
            <TitleModal>{title}</TitleModal>
            <Spacer bottom={10} />
            <Description>{description}</Description>
            <Spacer bottom={10} />

            <div className="row">
              {hasOption && hasOption.length > 0 && (
                <div className="col-12">
                  <Spacer bottom={10} />

                  <CustomSelect
                    label="Selecione uma opção"
                    name="option"
                    value={CustomSelectValue(option, hasOption)}
                    placeholder="Selecione"
                    options={hasOption}
                    onChange={({ target: { value } }) => {
                      setOption(value);
                    }}
                  />
                </div>
              )}

              {hasReason && (
                <div className="col-12">
                  <Spacer bottom={10} />
                  <Input
                    type="textarea"
                    label="Informe o motivo da ação:"
                    name="instruction"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              )}
            </div>
            <Spacer bottom={48} />
          </div>

          <div className="row">
            {showConfirmButton && (
              <div className="col-12">
                <Button
                  mode={"main"}
                  label={confirmButtonLabel ?? "Confirmar"}
                  full
                  onClick={handleConfirmToken}
                />
              </div>
            )}

            {showConfirmButton && showCancelButton && (
              <div className="col-12">
                <Spacer bottom={10} />
              </div>
            )}

            {showCancelButton && (
              <div className="col-12">
                <Button
                  mode={"border"}
                  label={cancelButtonLabel ?? "Cancelar"}
                  full
                  onClick={() => handleClose("Operação cancelada")}
                />
              </div>
            )}
          </div>
        </div>
      </Padding>
    </InteractiveModal>
  );
};

const confirmOperation = (props: ModalProps) => {
  return new Promise(
    (resolve: AsyncProps["resolve"], reject: AsyncProps["reject"]) => {
      addDialog({ resolve, reject, props });
    }
  );
};

const addDialog = ({ resolve, reject, props }: AsyncConfirmModalProps) => {
  const div = document.createElement("div");
  div.id = "confirm-modal-container";
  document.body.appendChild(div);
  ReactDOM.render(
    <AsyncConfirmModal resolve={resolve} reject={reject} props={props} />,
    div
  );

  /* Confirm if element has been added */
  if (!document.body.contains(div)) {
    console.error("Não foi possível criar o modal de confirmação");
  }
};

const removeDialog = () => {
  const div = document.getElementById("confirm-modal-container");
  if (div) {
    try {
      ReactDOM.unmountComponentAtNode(div);

      /* Verify if elements exists before remove */
      if (document.body.contains(div)) document.body.removeChild(div);
    } catch (e) {
      console.error(e);
    }
  }
};

export default confirmOperation;
