import { useEffect } from "react";
import { Spacer } from "@zilliondigital/mirage-ui";
import { HTMLAttributes } from "react";
import theme from "~/themes/theme";
import CheckedIcon from "~/assets/icons/checked.png";
import InputDataIconActive from "~/assets/icons/icon-inputdata-active.png";
import AttachamentsIconInactive from "~/assets/icons/icon-attachments.png";
import AttachamentsIcon from "~/assets/icons/icon-attachments-active.png";
import SecurityIconInactive from "~/assets/icons/icon-checklist.png";
import SecurityIcon from "~/assets/icons/icon-checklist-active.png";
import useSignupCompany, { steps } from "~/hooks/Signup/useSignupCompany";
import styled from "styled-components";
import { SteperItemProps } from "./SteperUser";
import { useLocation, useNavigate } from "react-router-dom";
import AnaliseIcon from "~/assets/icons/clock.png";

const AppSteper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

const SteperContainerItem = styled.div`
  display: flex;
  align-items: start;

  height: 100%;

  flex-direction: column;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const SteperTitle = styled.span<SteperProps>`
  color: ${({ status }) =>
    status === "active"
      ? theme.colors.primary.main
      : status === "inactive"
      ? theme.colors.primary.light300
      : theme.colors.primary.light400};
  margin-top: 4px;
`;

const SteperChildTitle = styled.span<SteperProps>`
  color: ${({ status }) =>
    status === "active"
      ? theme.colors.primary.main
      : status === "inactive"
      ? theme.colors.primary.light300
      : theme.colors.primary.light300};
`;

const SteperChildItem = styled.div<SteperProps>`
  width: 12px;
  height: 12px;

  border-radius: 50%;
  background-color: ${({ status }) =>
    status === "active" || status === "completed"
      ? theme.colors.primary.main
      : theme.colors.primary.light300};
  position: absolute;
  transform: translate(87%, 30%);
`;

const SteperItem = styled.div<SteperProps>`
  width: 32px;
  height: 32px;
  margin-bottom: 12px;
  border-radius: 50%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: ${({ status }) =>
    status === "active"
      ? "1px solid" + theme.colors.primary.main
      : status === "inactive" && "1px solid" + theme.colors.primary.light300};

  background-color: ${({ status }) =>
    status === "completed" && theme.colors.primary.main};
  z-index: 1;
`;

const SteperIcon = styled.img<SteperProps>`
  width: 22px;

  filter: ${({ status }) =>
    status === "inactive"
      ? theme.colors.primary.light300Filter
      : status === "completed"
      ? "white "
      : theme.colors.primary.mainFilter};
`;

const Divider = styled.div<SteperProps>`
  width: 1px;
  height: 18px;
  background-color: ${({ status }) =>
    status === "inactive"
      ? theme.colors.primary.light300
      : theme.colors.primary.main};
  position: absolute;
  transform: translate(1600%, -72%);
  z-index: 0;
`;

const DividerChild = styled.div<SteperProps>`
  width: 1px;
  height: 18px;
  background-color: ${({ status }) =>
    status === "inactive"
      ? theme.colors.primary.light300
      : theme.colors.primary.main};
  position: absolute;
  transform: translate(1600%, 89%);
  z-index: 0;
`;

type SteperItemChildrenProps = {
  title: string;
  step: string;
  stepIndex: number;
};

type SteperProps = HTMLAttributes<HTMLDivElement> & {
  menu?: SteperItemProps[];
  status?: "inactive" | "active" | "completed";
};

const SteperCompany = (props: SteperProps) => {
  const {
    step,
    setStep,
    stepIndex,
    setStepIndex,
    stepChildIndex,
    setStepChildIndex,
  } = useSignupCompany();

  const { menu = [] } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const setStepCustom = (_step: any) => {
    setStep(_step);
    navigate(`/signup/company/${_step}`);
  };

  useEffect(() => {
    const active: any = location.pathname.split("/").pop();
    if (steps.includes(active)) {
      setStep(active);
    }
  }, [steps]);

  return (
    <AppSteper className="d-md-flex d-none flex-md-column " {...props}>
      {menu.map((item, index) => {
        setStepIndex(menu.findIndex((item) => item.step === step) + 1);
        return (
          <SteperContainerItem {...props} key={index}>
            <div className="d-flex justify-content-center align-items-start  ">
              <div>
                <SteperItem
                  // onClick={() => {
                  //   setStepCustom(item.step);
                  // }}
                  status={
                    item.step === step
                      ? "active"
                      : item.step !== step && item.stepIndex > index
                      ? "completed"
                      : "inactive"
                  }
                >
                  <SteperIcon
                    src={
                      item.step !== step && item.stepIndex > index
                        ? item.iconCompleted
                        : item.step !== step
                        ? item.iconInactive
                        : item.icon
                    }
                    style={
                      item.stepIndex !== index + 1 && item.stepIndex > index
                        ? { filter: theme.colors.base.light100Filter }
                        : { filter: theme.colors.primary.mainFilter }
                    }
                  />
                </SteperItem>
                {index !== menu.length - 1 && (
                  <Divider
                    status={
                      item.children &&
                      item.children.length > 0 &&
                      step !== "intro"
                        ? "active"
                        : item.step !== step && item.stepIndex > index
                        ? "completed"
                        : "inactive"
                    }
                  />
                )}
              </div>
              <Spacer left={16} />
              <SteperTitle
                // onClick={() => {
                //   setStepCustom(item.step);
                // }}
                status={
                  item.step === step
                    ? "active"
                    : item.step !== step && item.stepIndex > index
                    ? "completed"
                    : "inactive"
                }
              >
                {item.title}
              </SteperTitle>
            </div>

            {item.children && (
              <div className="d-flex flex-column">
                {item.children.map((child, index) => {
                  setStepChildIndex(
                    item.children
                      ? item.children.findIndex((item) => item.step === step) +
                          1
                      : 0
                  );
                  return (
                    <div
                      className="d-flex align-items-start justify-content-start "
                      style={{ marginBottom: "7px" }}
                      key={index}
                      // onClick={() => {
                      //   setStepCustom(child.step);
                      // }}
                    >
                      <Spacer left={32} />
                      <SteperChildItem
                        status={
                          step === child.step
                            ? "active"
                            : (child.step !== step &&
                                child.stepIndex > index + 1) ||
                              (item.step !== step && item.stepIndex > index - 1)
                            ? "completed"
                            : "inactive"
                        }
                      ></SteperChildItem>
                      {
                        <DividerChild
                          status={
                            step === child.step
                              ? "inactive"
                              : (child.step !== step &&
                                  child.stepIndex > index + 1) ||
                                (item.step !== step &&
                                  item.stepIndex > index - 1)
                              ? "completed"
                              : "inactive"
                          }
                        />
                      }

                      <Spacer left={16} />
                      <SteperChildTitle
                        status={
                          step === child.step
                            ? "active"
                            : (child.step !== step &&
                                child.stepIndex > index + 1) ||
                              (item.step !== step && item.stepIndex > index - 1)
                            ? "completed"
                            : "inactive"
                        }
                      >
                        {child.title}
                      </SteperChildTitle>
                    </div>
                  );
                })}
              </div>
            )}
          </SteperContainerItem>
        );
      })}
    </AppSteper>
  );
};

export default SteperCompany;
