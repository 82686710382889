const url = window.location.href;

const themeMap: { [key: string]: string } = {
  default: "engraxapay",
  zillion: "zillion",
  engraxapay: "engraxapay",
};

const setThemeName = (url: string): string => {
  const theme = Object.keys(themeMap).find((key) => url.includes(key));
  return theme ? themeMap[theme] : themeMap.default;
};

const activedTheme = setThemeName(url);

export default activedTheme;
