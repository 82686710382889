import { ActivateCardSteps, ActivatingCardStatus, Card } from "~/hooks/Cards/interfaces";
import { ActivateCardCheckingButtonsDiv, ActivateCardCheckingImg, ActivateCardCheckingStepDiv, ActivateCardTitle, ActivateCardContainer, CardsDashboardHeader, ActivateCardReceivedWrapperDiv, ActivateCardInputWrapper, ActivateCardSuccessImg, ActivateCardSuccessStepDiv, ActivateCardSuccessTitle, ActivateCardInputError, ActivateCardDescription } from "../styled";
import { Button } from "@zilliondigital/mirage-ui";
import CardImg from '~/assets/images/credit--card.svg'
import SuccessIcon from '~/assets/icons/status_icon--green.svg'
import { Navigate, useNavigate } from "react-router-dom";
import React, { useState } from "react";

interface I_returnReceivedCardStepComponent {
  selectedCard: Card,
  setActivateCardStep: React.Dispatch<React.SetStateAction<ActivateCardSteps>>;
  activateCardStep: ActivateCardSteps;
  setActivatingCardStatus: React.Dispatch<React.SetStateAction<ActivatingCardStatus>>;
  activatingCardStatus: ActivatingCardStatus;
  postActivateBankCard: (cvv: string) => void;
  cvv: string;
  setCVV: React.Dispatch<React.SetStateAction<string>>
}
export const _returnReceivedCardStepComponent = (props:I_returnReceivedCardStepComponent) => {

  const _returnErrorMessage = (status: ActivatingCardStatus) => {
    if(status == 'SERVER_ERROR'){
      return(
        <ActivateCardInputError>
          Houve um erro no processamente de sua requisição, tente novamente.
        </ActivateCardInputError>
      )
    }else if(status == 'AUTHENTICATION_ERROR'){
      return(
        <ActivateCardInputError>
          Código incorreto, verifique o número de seu cartão e tente novamente.
        </ActivateCardInputError>
      )
    }else{
      return <></>
    }
  }

  const _returnButtonLabel = () => {
    if(props.activatingCardStatus == 'LOADING'){
      return 'Verificando'
    } 

    const cvv_digits = props.cvv.length
    if(cvv_digits == 3){
      return 'Ativar'
    }else if(cvv_digits == 2){
      return 'Falta 1 dígito'
    }else{
      return `Faltam ${3 - cvv_digits} dígitos`
    }
  }

  return(
    <ActivateCardCheckingStepDiv>
      <ActivateCardCheckingImg src={CardImg}/>
      <ActivateCardTitle>
        Digite o CVV do seu cartão.
      </ActivateCardTitle>
      <ActivateCardDescription style={{marginTop: '-1.5rem'}}>
        O CVV tem 3 dígitos e pode ser localizado na parte de trás do seu cartão.
      </ActivateCardDescription>
      <ActivateCardReceivedWrapperDiv>
        <ActivateCardInputWrapper>
          <input 
            name="cvv_activate"
            type="text"
            placeholder="CVV"
            maxLength={3}
            disabled={props.activatingCardStatus == 'LOADING'}
            onKeyDown={(e) => {e.key == 'Enter' && props.cvv.length == 3 && props.postActivateBankCard(props.cvv)}}
            onChange={(e) => {props.setCVV(e.currentTarget.value)}}
          />
          {
            (
              props.activatingCardStatus &&
              ['SERVER_ERROR', 'AUTHENTICATION_ERROR'].includes(props.activatingCardStatus)
            ) ? _returnErrorMessage(props.activatingCardStatus) : <></>
          }
        </ActivateCardInputWrapper>
        <Button 
          label={_returnButtonLabel()} 
          mode='main'
          disabled={props.activatingCardStatus == 'LOADING' || props.cvv.length < 3}
          style={{
            fontWeight: 400, 
            height: '100%', 
            width: '327px',
            paddingTop: '8px',
            paddingBottom: '8px'
          }}
          onClick={(e) => {
            props.postActivateBankCard(props.cvv)
          }}
        />
      </ActivateCardReceivedWrapperDiv>
    </ActivateCardCheckingStepDiv>
  )
}


export const _returnSuccessActivateCardComponent = () => {
  const navigate = useNavigate()

  return(
    <ActivateCardSuccessStepDiv>
      <ActivateCardCheckingImg src={SuccessIcon}/>
      <ActivateCardSuccessTitle>
        Seu cartão físico foi ativado e está pronto para uso
      </ActivateCardSuccessTitle>
      <ActivateCardReceivedWrapperDiv>
        <Button 
          label={'Voltar'} 
          mode='main'
          style={{
            fontWeight: 400, 
            height: '100%', 
            width: '327px',
            paddingTop: '8px',
            paddingBottom: '8px'
          }}
          onClick={(e) => {
            navigate('/app/cards')
          }}
        />
      </ActivateCardReceivedWrapperDiv>
    </ActivateCardSuccessStepDiv>
  )
}

