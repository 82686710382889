import { Button, InteractiveModal, Spacer } from "@zilliondigital/mirage-ui";
import { useState } from "react";
import Description from "~/components/Texts/Description";
import useSplit from "~/hooks/useSplit";
import useToast from "~/hooks/useToast";
import { TitleModal } from "~/pages/Authentication/Auth.styles";
import ApiService from "~/services/api";

const ConfirmDeleteSplitModal = () => {
  const [isloading, setIsloading] = useState<boolean>(false);

  const {
    setSplitsData,
    splitData,
    setSplitData,
    openDeleteSplitModal,
    setOpenDeleteSplitModal,
  } = useSplit();

  const { showToast } = useToast();

  const toggleModal = () => {
    setOpenDeleteSplitModal(!openDeleteSplitModal);
  };

  type deleteSplitType = {
    data?: any;
    error: boolean;
    message: string;
  };

  const deleteSplit = async () => {
    setIsloading(true);

    try {
      const data = await ApiService.HttpDelete<deleteSplitType>({
        route: `charge/split/${splitData.id}/`,
        token: true,
      });

      setIsloading(false);
      toggleModal();

      showToast("Arranjo excluído com sucesso", "", "success");

      const dataUpdated = await ApiService.HttpGet({
        route: "charge/split/",
        token: true,
      });

      setSplitsData(dataUpdated.data.results);
    } catch {
      setIsloading(false);
      toggleModal();
      showToast("Nao foi possível excluir o arranjo", "", "error");
    }
  };

  return (
    <InteractiveModal
      title={"cxddfdf"}
      size="md"
      toggleModal={toggleModal}
      open={openDeleteSplitModal}
    >
      <div className="p-5 d-flex  flex-column justify-content-between ">
        <TitleModal className="text-center">
          Remover {splitData.name} ?
        </TitleModal>
        <Spacer bottom={15} />
        <p>
          Tem certeza que deseja remover o arranjo {splitData.name}? Esta
          operação não poderá ser revertida.
        </p>

        <Spacer bottom={110} />
        <div>
          <Button
            mode="main"
            label="Não. Mantenha este arranjo"
            onClick={toggleModal}
            full
          />
          <Spacer bottom={10} />
          <Button
            loading={isloading}
            mode="border"
            label="Sim. Desejo excluir este arranjo"
            onClick={deleteSplit}
            full
          />
        </div>
      </div>
    </InteractiveModal>
  );
};
export default ConfirmDeleteSplitModal;
