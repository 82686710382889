import { delay } from "~/utils/timer/delay";
import {
  ActivatingCardStatus,
  Card,
  CardDetails,
  Transaction,
  errorBankCardsDTO,
  getDebitCardDetailsDTO,
  getDebitCardsAccountDTO,
  getDebitCardsDTO,
  getRecentTransactionsDTO,
  putDebitCardContactlessDTO,
} from "./interfaces";
import {
  mockGetCards,
  mockGetCardsError,
} from "~/utils/mocks/cards/mockGetCards";
import {
  mockPostActivateCard,
  mockPostActivateCardCodeError,
} from "~/utils/mocks/cards/activateCard";
import {
  mockPostBlockCard,
  mockPostBlockCardCodeError,
} from "~/utils/mocks/cards/blockCard";
import { mockGetRecentTransactions } from "~/utils/mocks/cards/mockGetRecentTransactions";
import BankCardAPIService from "~/services/api_card";
import { mockPostCreateCard } from "~/utils/mocks/cards/createCard";
import { mockGetCardDetails } from "~/utils/mocks/cards/mockGetCardDetails";
import { mockPostUnblockCard } from "~/utils/mocks/cards/unblockCard";
import { mockGetCardsAccount } from "~/utils/mocks/cards/mockGetCardsAccount";

interface IgetCardsAccountGateway {
  account_id: string;
  name: string;
  document_number: string;
  document_type: string;
  birth_date: string;
  phone: {
    phone_number: string;
    area_code: string;
    country_code: string;
    phone_type: string;
    main: true;
  };
  address: {
    address_type: string;
    zip_code: string;
    address: string;
    number: string;
    complementary_address: string;
    district: string;
    city: string;
    state: string;
    country: string;
  };
}
export const getCardsAccountGateway = async (
  props: IgetCardsAccountGateway
): Promise<{ accountUuid?: string } | errorBankCardsDTO> => {
  try {
    const { data } = await BankCardAPIService.HttpPost({
      route: `api/v1/digital_eazy/accounts/`,
      body: {
        ...props,
      },
      token: true,
    });

    //MOCKED RESPONSES
    // const data = await delay(2000).then(
    //   () => {
    //     let response: getDebitCardsAccountDTO = mockGetCardsAccount
    //     return response
    //   }
    // )

    return data.payload.uuid
      ? {
          accountUuid: data.payload.uuid,
        }
      : mockGetCardsError;
  } catch {
    console.warn("error");
    return mockGetCardsError;
  }
};

interface IgetRecentCardTransactionsGateway {
  accountUuid?: string;
}
export const getRecentCardTransactionsGateway = async (
  props: IgetRecentCardTransactionsGateway
) => {
  try {
    const { data } = await BankCardAPIService.HttpPost({
      route: `api/v1/digital_easy/debit_cards/transaction/${props.accountUuid}`,
      token: true,
      body: {},
    });
    // const data = await delay(2000).then(
    //   () => {
    //     let response: getRecentTransactionsDTO = mockGetRecentTransactions
    //     return response
    //   }
    // )

    if (!data.error?.code) {
      return data.payload.transactions == null ? [] : data.payload.transactions;
    } else {
      return data.error;
    }
  } catch {
    console.warn("error");
    return mockGetCardsError;
  }
};

interface IgetCardsGateway {
  accountUuid?: string;
}
export const getCardsGateway = async (
  props: IgetCardsGateway
): Promise<Card[] | errorBankCardsDTO> => {
  try {
    const { data } = await BankCardAPIService.HttpPost({
      route: `api/v1/digital_easy/debit_cards/${props.accountUuid}`,
      token: true,
    });
    // const data = await delay(2000).then(
    //   () => {
    //     let response: getDebitCardsDTO = mockGetCards
    //     return response.payload
    //   }
    // )
    return data.payload.cards == null ? [] : data.payload.cards;
  } catch {
    console.warn("error");
    return mockGetCardsError;
  }
};

interface IgetCardDetailsGateway {
  accountUuid?: string;
  card_id: string;
  password?: string;
  code?: string;
}
export const getCardDetailsGateway = async (
  props: IgetCardDetailsGateway
): Promise<CardDetails | errorBankCardsDTO | null> => {
  try {
    const { data } = await BankCardAPIService.HttpPost({
      route: `api/v1/digital_easy/debit_cards/${props.accountUuid}/details`,
      token: true,
      body: {
        // password: props.password,
        code: props.password,
        card_id: props.card_id,
      },
    });
    // const data = await delay(2000).then(
    //   () => {
    //     let response: getDebitCardDetailsDTO = mockGetCardDetails
    //     return response
    //   }
    // )
    return !data.error ? data.payload.details : data.error || null;
  } catch {
    console.warn("error");
    return mockGetCardsError;
  }
};

export interface postActivateCardResponse {
  payload: {
    success: boolean;
  };
  error: {
    code: string;
    message: string;
    detail: any;
  } | null;
}
interface IactivateBankCardGateway {
  selectedCard: Card;
  cvv: string;
  accountUuid?: string;
}
export const activateBankCardGateway = async (
  props: IactivateBankCardGateway
): Promise<string | errorBankCardsDTO> => {
  try {
    const { data } = await BankCardAPIService.HttpPost({
      route: `api/v1/digital_easy/debit_cards/${props.accountUuid}/activate`,
      token: true,
      body: {
        cvv: props.cvv,
        card_id: props.selectedCard.card_id,
      },
    });
    // const data = await delay(2000).then(
    //   () => {
    //     let data = mockPostActivateCard
    //     return data
    //   }
    // )
    return data.error?.code ? "SERVER_ERROR" : "SUCCESS";
  } catch {
    console.warn("error");
    return "SERVER_ERROR";
  }
};

export interface postBlockBankCardResponse {
  payload: {
    success: boolean;
  };
  error: {
    code: string;
    message: string;
    detail: any;
  } | null;
}
interface IblockBankCardGateway {
  selectedCard: Card;
  password: string;
  accountUuid?: string;
}
export const blockBankCardGateway = async ({
  selectedCard,
  password,
  accountUuid,
}: IblockBankCardGateway) => {
  try {
    const { data } = await BankCardAPIService.HttpPost({
      route: `api/v1/digital_easy/debit_cards/${accountUuid}/block`,
      token: true,
      body: {
        password: password,
        card_id: selectedCard.card_id,
      },
    });

    // const data = await delay(2000).then(
    //   () => {
    //     let response = mockPostUnblockCard
    //     return response
    //   }
    // )
    return data.error?.code ? "SERVER_ERROR" : "SUCCESS";
  } catch {
    console.warn("error");
    return "SERVER_ERROR";
  }
};

export interface postUnblockBankCardResponse {
  payload: {
    success: boolean;
  };
  error: {
    code: string;
    message: string;
    detail: any;
  } | null;
}
interface IunblockBankCardGateway {
  selectedCard: Card;
  password: string;
  accountUuid?: string;
}
export const unblockBankCardGateway = async ({
  selectedCard,
  password,
  accountUuid,
}: IunblockBankCardGateway) => {
  try {
    const { data } = await BankCardAPIService.HttpPost({
      route: `api/v1/digital_easy/debit_cards/${accountUuid}/unblock`,
      token: true,
      body: {
        password: password,
        card_id: selectedCard.card_id,
      },
    });
    // const data = await delay(2000).then(
    //   () => {
    //     let response = mockPostBlockCard
    //     return response
    //   }
    // )
    return data.error?.code ? "SERVER_ERROR" : "SUCCESS";
  } catch {
    console.warn("error");
    return "SERVER_ERROR";
  }
};

export interface postCreateCardResponse {
  payload: {
    success: boolean;
  };
  error: {
    code: string;
    message: string;
    detail: any;
  } | null;
}
interface IcreateBankCardGateway {
  name: string;
  card_type: string;
  accountUuid?: string;
  delivery_address?: {
    zip_code: string;
    address: string;
    number?: string;
    district: string;
    city: string;
    state: string;
    country: string;
    complementary_address?: string;
    address_type?: string;
  };
}
export const createBankCardGateway = async (
  props: IcreateBankCardGateway
): Promise<string | errorBankCardsDTO> => {
  try {
    const { data } = await BankCardAPIService.HttpPost({
      route: `api/v1/digital_easy/debit_cards/${props.accountUuid}/create`,
      token: true,
      body: {
        card_type: props.card_type,
        card_name: props.name,
        delivery_address: props.delivery_address || null,
      },
    });
    // const data = await delay(2000).then(
    //   () => {
    //     let data = mockPostCreateCard
    //     return data
    //   }
    // )
    return data.error?.code ? "SERVER_ERROR" : "SUCCESS";
  } catch {
    console.warn("error");
    return "SERVER_ERROR";
  }
};

interface IputCardContactlessEnabledGateway {
  accountUuid?: string;
  card_id: string;
  contactless_enabled: boolean;
}
export const putCardContactlessEnabledGateway = async (
  props: IputCardContactlessEnabledGateway
): Promise<boolean | errorBankCardsDTO> => {
  try {
    const { data } = await BankCardAPIService.HttpPost({
      route: `api/v1/digital_easy/debit_cards/${props.accountUuid}/contactless`,
      token: true,
      body: {
        contactless: props.contactless_enabled,
        card_id: props.card_id,
      },
    });
    // const data = await delay(2000).then(
    //   () => {
    //     let response: putDebitCardContactlessDTO = mockGetCardDetails
    //     return response
    //   }
    // )

    return !data.error && data.payload.success
      ? data.payload.success
      : data.error || false;
  } catch {
    console.warn("error");
    return mockGetCardsError;
  }
};
