import { useEffect, useState } from "react";
import constate from "constate";
import ApiService from "~/services/api";
import { formatNumberToCurrencyWithoutSimbol } from "~/utils";
import { APIResponse, AppBankAccountUser } from "~/types";

type balance = {
  outgoing: number;
  incoming: number;
  total: number;
};

interface DailyBalanceResult {
  incomings: number;
  outgoings: number;
  dailyBalance: number;
}

interface Transaction {
  movement_type: "incoming" | "outgoing";
  amount: number;
}

interface RootObject {
  id: number;
  user: number;
  birth_date?: any;
  document_number: string;
  identity_number?: any;
  document_type: string;
  additional_type: string;
  email: string;
  gender?: any;
  home_phone?: any;
  mobile_phone: string;
  name: string;
  brand_name: string;
  work_phone?: any;
  created_date: string;
  last_update: string;
  mothers_name?: any;
  status: string;
  favourite: boolean;
  internal?: any;
  internal_status: string;
  default: boolean;
  type: string;
  origin: string;
  merchant_id?: any;
  create_internal_account: boolean;
  create_external_account: boolean;
  foundation_date: string;
  state_doc_number?: any;
  city_doc_number: string;
  valid: boolean;
  signatory: boolean;
  account_waiting_block: boolean;
  cnae?: any;
  revenue?: any;
  legal_nature?: any;
  establishment_external_id?: any;
  issuing_state?: any;
  need_token: boolean;
  nationality: string;
  birth_state?: any;
  marital_status?: any;
  label_origin?: any;
  payment_link_status: string;
  logo: string;
  user_data: Userdata;
}

interface Userdata {
  auth_token: string;
  transferschedule?: any;
  id: number;
  password: string;
  last_login: string;
  is_superuser: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
}

const [AccountProvider, useAccount] = constate(() => {
  const [user, setUser] = useState<RootObject>({} as RootObject);
  const [balance, setBalance] = useState<number>(0);
  const [balanceFormatted, setBalanceFormatted] = useState<string>(
    "Informação indisponível"
  );

  const [activeIndexSideBar, setActiveIndexSideBar] = useState<number>(-1);

  const [loadingDash, setLoadingDash] = useState<boolean>(false);

  const [beforeDays, setBeforeDays] = useState<number>(0);

  const [bankAccounts, setBankAccounts] = useState<any>({});
  const [userPartners, setUserPartners] = useState<any[]>([]);
  const [accountAddress, setAccountAddress] = useState<{
    zipcode: string;
    street: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
    country: string;
  } | null>(null);
  const [mainAccount, setMainAccount] = useState<AppBankAccountUser>();

  const [bankAccountPermissions, setBankAccountPermissions] = useState<
    AppBankAccountUser[]
  >([]);

  const [hideAvailableBalance, setHideAvailableBalance] =
    useState<boolean>(false);
  const [hideBalances, setHideBalances] = useState<boolean>(false);

  const [insufficientBalance, setInsufficientBalance] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [userBalance, setUserBalance] = useState<number>(0);
  const [projectionsData, setprojectionsData] = useState<any>([]);
  const [blockedBalance, setBlockedBalance] = useState<number>(0);

  const [idUser, setIdUser] = useState<number>(-1);

  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);

  const formatNumberToCurrency = (value: number) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  const getBalance = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "balance/",
        token: true,
      });

      if (!data.error) {
        setBalance(data?.balance);
        setBlockedBalance(data.blocked_balance);
        setBalanceFormatted(
          `R$ ${formatNumberToCurrencyWithoutSimbol(data?.balance)}`
        );
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const getProjections = async (params?: any) => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/partner-billet-status/",
        token: true,
        params: {
          // due_date: "2023-05-01",
        },
      });

      setprojectionsData(data);
    } catch {}
  };

  const getUserPartners = async (params?: any) => {
    try {
      const { data: data } = await ApiService.HttpGet({
        route: "user-partners/",
        token: true,
      });

      if (data) {
        setUserPartners(data);
      }
    } catch {}
  };

  const fetchBankAccountPermissions = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "bank-account-user/",
        params: {
          limit: 1000,
        },
        token: true,
      });

      setBankAccountPermissions(data.results);
    } catch (e) {
      console.error(e);
    }
  };

  const handleMainAccountChange = async (id: number) => {
    setLoadingDash(true);
    try {
      const {
        data: { data },
      } = await ApiService.HttpPost<APIResponse<AppBankAccountUser>>({
        route: "partner-account/",
        token: true,
        body: {
          account: id,
        },
      });

      setMainAccount(data);
      setLoadingDash(true);
    } catch (e) {
      console.error(e);
      setLoadingDash(false);
    }
  };

  const fetchBankAccountAddress = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "address/",
        token: true,
      });
      setAccountAddress(data.results ? data.results[0] : {});
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!balance) {
      setBalanceFormatted("Informação indisponível");
    } else {
      const formatted = `R$ ${formatNumberToCurrencyWithoutSimbol(balance)}`;
      setBalanceFormatted(formatted);
    }
  }, [balance]);

  const verifyPermission = (codename: string) => {
    if (mainAccount && mainAccount.permissions_role) {
      const {
        permissions_role: { permissions },
      } = mainAccount;

      const search = typeof codename === "string" ? [codename] : codename;

      const mappedPerms = permissions.map((permission) => permission.codename);

      const hasPerm = search.every((perm: string) =>
        mappedPerms.includes(perm)
      );

      return hasPerm;
    }

    return false;
  };

  return {
    hideAvailableBalance,
    setHideAvailableBalance,
    hideBalances,
    setHideBalances,
    balanceFormatted,
    formatNumberToCurrency,
    user,
    setUser,
    bankAccounts,
    setBankAccounts,
    isLoading,
    setIsLoading,
    insufficientBalance,
    setInsufficientBalance,
    mainAccount,
    setMainAccount,
    getBalance,
    userBalance,
    getProjections,
    projectionsData,
    setprojectionsData,
    balance,
    setBalance,
    bankAccountPermissions,
    setBankAccountPermissions,
    fetchBankAccountPermissions,
    handleMainAccountChange,
    idUser,
    setIdUser,
    openHelpModal,
    setOpenHelpModal,

    getUserPartners,
    userPartners,
    setUserPartners,
    verifyPermission,

    blockedBalance,
    setBlockedBalance,
    beforeDays,
    setBeforeDays,
    loadingDash,
    setLoadingDash,

    activeIndexSideBar,
    setActiveIndexSideBar,

    fetchBankAccountAddress,
    setAccountAddress,
    accountAddress,
  };
});

export { AccountProvider };

export default useAccount;
