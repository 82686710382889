import {
  BalanceAlert,
  Button,
  CustomSelect,
  Input,
  InputCurrency,
  Spacer,
  Toggle,
  Tooltip,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";
import EyeIcon from "~/assets/icons/round_visibility_black_24dp.png";
import EyeOffIcon from "~/assets/icons/round_visibility_off_black_36dp.png";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Title from "~/components/Texts/Title";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import hideFormatDocumentNumber from "~/utils/hideDocumentNumber";
import { useFormik } from "formik";
import {
  convertedAmount,
  formatCPFCNPJ,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import { onlyNumbers } from "~/services/utils";
import { motion } from "framer-motion";
import useToken from "~/hooks/useToken";
import theme from "~/themes/theme";
import useTransfers from "~/hooks/Transfers/useTransfers";
import CustomerTax from "~/components/CustomerTax";
import ApiService from "~/services/api";

const Container = styled.div`
  height: 800px;

  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;

    .container-input-date {
      position: relative;
      top: -45px;
      left: 108px;
      width: 138px;
      max-height: 40px;
      z-index: -1;
    }
  }

  .image-eye {
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  .container-balance {
    position: relative;
    top: -40px;
  }
  .hide-balance {
    position: absolute;
    left: 180px;
  }

  .container-hide-icon {
    position: relative;
  }
`;

const ToolTipContainerAvailableBalance = styled.div`
  position: absolute;
  left: -13px;
  top: -25px;
`;
export const ContainerInfos = styled.div`
  width: 100%;
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;

  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.5),
    inset 0 -10px 10px -10px rgba(0, 0, 0, 0.5);
`;

const ContainerBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 40px 25px;
`;

type FormValues = {
  amount: string;
  description: string;
};

const initialValues: FormValues = {
  amount: "",
  description: "",
};

const SendTEDorTEV = () => {
  const { token, sendPartnerToken } = useToken();
  const {
    balance,
    balanceFormatted,
    hideAvailableBalance,
    setInsufficientBalance,
    setHideAvailableBalance,
    insufficientBalance,
    bankAccounts,
    mainAccount,
  } = useAccount();

  const {
    setNewTransferTEDData,
    newTransferTEDData,
    bankAccountIndex,
    setAmount,
    amount,
    setOpenValidatorModalTED,
    setSelectContact,
    setStepIndex,
    setChangeSideAnimation,
    setPayloadSendTED,

    setPayloadSendTEV,
    setNewTransferTEVData,
    isTEV,
    idContact,
    newTransferTEVData,
    setTaxTED,
    setTaxTEV,
    taxTED,
    taxTEV,
  } = useNewTransfer();
  const { isManual } = useTransfers();

  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [isRecurrence, setIsRecurrence] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [canRender, setCanRender] = useState();

  const handleRecurrence = () => {
    setIsRecurrence(!isRecurrence);
  };

  const { values, handleChange } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {},
  });

  const fetchTax = async () => {
    const slugs = [
      { slug: "ted", setResult: setTaxTED },
      { slug: "tev", setResult: setTaxTEV },
    ];

    try {
      for (const { slug, setResult } of slugs) {
        const response = await ApiService.HttpPost({
          route: "customer-global-tax/",
          token: true,
          body: {
            slug: slug,
            tax_is_enabled: true,
          },
        });

        setResult(response.data.customer_tax_out);
      }
    } catch (err) {
      console.error("Erro", err);
    }
  };

  useEffect(() => {
    setAmount(convertedAmount(onlyNumbers(values.amount)));
  }, [values.amount]);

  useEffect(() => {
    const totalTEV = amount + taxTEV;
    const totalTED = amount + taxTED;

    if (isTEV && parseFloat(totalTEV.toFixed(2)) > balance) {
      setInsufficientBalance(true);
    } else if (!isTEV && parseFloat(totalTED.toFixed(2)) > balance) {
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }
  }, [amount, taxTED, taxTEV, balance]);

  useEffect(() => {
    fetchTax();
  }, []);

  type RecurrencyIntervalType = {
    [key: string]: string;
  };

  const recurrencyInterval: RecurrencyIntervalType = {
    1: "7",
    2: "15",
    3: "30",
    4: "60",
    5: "90",
    6: "180",
    7: "356",
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const payloadTED = {
    amount: amount,
    category: "ted",
    code: token,
    currency: "986",
    description: values.description,
    due_date: getCurrentDate(),
    payment_type: "unique",
    processor: "bv",
    rec: isRecurrence ? true : false,
    recipient: !isTEV && newTransferTEDData.id,
    recipient_bank_account:
      !isTEV && !isManual
        ? newTransferTEDData?.bank_accounts[bankAccountIndex]?.id
        : isManual && newTransferTEDData?.bank_accounts[0]?.id,
    recurrence_period:
      selectedOption !== null
        ? recurrencyInterval[
            selectedOption !== null && selectedOption.target.value
          ]
        : "",
    schedule: getCurrentDate(),
    type: "debit",
  };

  useEffect(() => {
    isTEV ? setPayloadSendTEV(payloadTEV) : setPayloadSendTED(payloadTED);
  }, [token]);

  const payloadTEV = {
    amount: amount,
    category: "tev",
    currency: "986",
    code: token,
    description: values.description,
    due_date: getCurrentDate(),
    partner: mainAccount?.partner,
    payment_type: "unique",
    processor: "virtus",
    rec: isRecurrence ? true : false,
    recipient:
      isTEV && newTransferTEVData[0] ? newTransferTEVData[0].partner : null,
    recipient_bank_account:
      isTEV && newTransferTEVData[0] ? newTransferTEVData[0].id : null,
    recurrence_period:
      selectedOption !== null
        ? recurrencyInterval[
            selectedOption !== null && selectedOption.target.value
          ]
        : "",
    schedule: getCurrentDate(),
    type: "debit",
  };

  const handleClick = (e: any) => {
    if (isNaN(amount) || amount <= 0) {
      setError(true);
    } else if (amount <= balance) {
      sendPartnerToken();
      setOpenValidatorModalTED(true);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (isNaN(amount) || amount <= 0) {
        setError(true);
      } else if (amount <= balance) {
        isTEV ? setPayloadSendTEV(payloadTEV) : setPayloadSendTED(payloadTED);
        setOpenValidatorModalTED(true);
      }
    }
  };

  const handleCancel = () => {
    setChangeSideAnimation(true);
    setStepIndex(0);
    setSelectContact(false);
    setNewTransferTEDData({});
  };

  const handleSelectChange = (selectedValue: any) => {
    setSelectedOption(selectedValue);
  };

  const recurrenceOptions = [
    { label: "Semanal", value: "1" },
    { label: "Quinzenal", value: "2" },
    { label: "Mensal", value: "3" },
    { label: "Bimestral", value: "4" },
    { label: "Trimestral", value: "5" },
    { label: "Semestral", value: "6" },
    { label: "Anual", value: "7" },
  ];

  const toggleHovered = () => {
    setIsHovered(!isHovered);
  };

  return (
    <motion.div
      initial={{
        x: "100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: "-100%",
      }}
      transition={{ duration: 0.3 }}
    >
      {isManual ? (
        <>
          {" "}
          <Container className="p-4 ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="w-100">
                <InputCurrency
                  id="amount"
                  type="text"
                  name="amount"
                  label="Digite o valor que deseja transferir"
                  onChange={handleChange}
                  value={values.amount}
                  error={error && "O valor precisa ser maior que 0"}
                  inputSize="large"
                  onFocus={() => setError(false)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>

              {/* <div className="ms-2 pb-3" onClick={() => null}>
          <Description color="#666666">Agendar</Description>
          <Toggle mode="large" checked={isSchedule} />
        </div> */}
            </div>
            <div className="d-flex justify-content-start align-items-center container-balance mt-4 ">
              <Description color="#666666">
                {`Saldo disponível atual é de
    `}
              </Description>
              <Spacer left={3} />

              <Description
                className={
                  !hideAvailableBalance
                    ? "font-weight-bolder "
                    : "font-weight-bolder opacity-0 "
                }
              >
                {balanceFormatted}
              </Description>

              <Description
                className={
                  hideAvailableBalance
                    ? "font-weight-bolder   hide-balance   "
                    : "font-weight-bolder opacity-0 hide-balance  "
                }
              >
                {" "}
                R$ ****
              </Description>

              <Spacer left={5} />

              <div className=" col-2 d-flex flex-column align-items-start  position-relative container-hide-icon ">
                <ToolTipContainerAvailableBalance>
                  {" "}
                  <Tooltip
                    tooltipColor={theme.colors.info.light300}
                    tooltipLabel={!hideAvailableBalance ? "Ocultar" : "Mostrar"}
                    visible={isHovered}
                  />
                </ToolTipContainerAvailableBalance>

                <Spacer bottom={0} />

                <img
                  className="image-eye"
                  src={!hideAvailableBalance ? EyeIcon : EyeOffIcon}
                  onClick={() => setHideAvailableBalance(!hideAvailableBalance)}
                  onMouseEnter={toggleHovered}
                  onMouseLeave={toggleHovered}
                  style={
                    isHovered
                      ? {
                          filter:
                            "brightness(0) saturate(100%) invert(71%) sepia(99%) saturate(1215%) hue-rotate(168deg) brightness(93%) contrast(89%)",
                        }
                      : {}
                  }
                />
              </div>
            </div>

            <div>
              <Spacer top={-40} />
              <Description color="#666666">{`Taxa `}</Description>
              <Description className="font-weight-bolder">
                R${" "}
                {formatNumberToCurrencyWithoutSimbol(isTEV ? taxTEV : taxTED)}{" "}
              </Description>
            </div>
            <Spacer top={-40} />
            {insufficientBalance && (
              <>
                {" "}
                <Spacer bottom={10} />
                <BalanceAlert
                  title="Saldo insuficiente"
                  message="Essa transferência não poder ser feita por saldo insuficiente"
                />
              </>
            )}

            <table className=" w-100 mt-4">
              <tbody>
                <tr>
                  <td>
                    <Description color="#666666">Quando</Description>
                  </td>

                  <td className="second-column ">
                    <div className="container-input-date">
                      <Input type="date" value={getCurrentDate()} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">
                      Tipo de transferência
                    </Description>
                  </td>

                  <td className="second-column">
                    <Description>{"TED"}</Description>
                  </td>
                </tr>
              </tbody>
            </table>

            <Spacer top={40} />

            <Title>Dados do favorecido</Title>

            <table className=" w-100">
              <tbody>
                <tr>
                  <td>
                    <Description color="#666666">Nome Completo</Description>
                  </td>

                  <td className="second-column">
                    <Description>{newTransferTEDData?.name}</Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">CPF/CNPJ</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {formatCPFCNPJ(newTransferTEDData?.document_number)}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Instituição</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {newTransferTEDData?.bank_accounts[0]?.bank_data?.name}
                    </Description>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Description color="#666666">Agência</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {newTransferTEDData?.bank_accounts[0]?.agency}
                    </Description>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Description color="#666666">Conta</Description>
                  </td>

                  <td className="second-column">
                    <Description>{`${newTransferTEDData?.bank_accounts[0]?.account_number}-${newTransferTEDData?.bank_accounts[0]?.account_number_digit}`}</Description>
                  </td>
                </tr>
              </tbody>
            </table>
            <Spacer bottom={20} />

            <div>
              <div className="d-flex align-items-center ">
                <Title>Recorrência</Title>

                <Spacer left={20} />
                <div onClick={handleRecurrence}>
                  <Toggle mode="small" checked={isRecurrence} />
                </div>
              </div>
              {isRecurrence && (
                <>
                  <Spacer bottom={20} />
                  <CustomSelect
                    options={recurrenceOptions}
                    placeholder={"Selecione"}
                    onChange={handleSelectChange}
                    menuPosition={"absolute"}
                    menuPlacement="auto"
                    isSearchable={false}
                  />
                </>
              )}
            </div>
            <Spacer bottom={10} />
            <form>
              <Input
                type="textarea"
                name="description"
                value={values.description}
                label="Descrição"
                placeholder="Deixe um texto de exemplo aqui"
                onChange={handleChange}
              />
            </form>
            <Spacer bottom={70} />
          </Container>
        </>
      ) : (
        <Container>
          {" "}
          <div className="p-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="w-100">
                <InputCurrency
                  id="amount"
                  type="text"
                  name="amount"
                  label="Digite o valor que deseja transferir"
                  onChange={handleChange}
                  value={values.amount}
                  error={error && "O valor precisa ser maior que 0"}
                  inputSize="large"
                  onFocus={() => setError(false)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>

              {/* <div className="ms-2 pb-3" onClick={() => null}>
            <Description color="#666666">Agendar</Description>
            <Toggle mode="large" checked={isSchedule} />
          </div> */}
            </div>

            <div className="container-balance">
              <div className="d-flex flex-wrap justify-content-start align-items-center mt-4">
                <Description color="#666666">
                  {`Saldo disponível atual é de`}
                </Description>
                <Spacer left={3} />

                <Description
                  className={
                    !hideAvailableBalance
                      ? "font-weight-bolder "
                      : "font-weight-bolder opacity-0 "
                  }
                >
                  {balanceFormatted}
                </Description>

                <Description
                  className={
                    hideAvailableBalance
                      ? "font-weight-bolder   hide-balance   "
                      : "font-weight-bolder opacity-0 hide-balance  "
                  }
                >
                  {" "}
                  R$ ****
                </Description>

                <Spacer left={5} />

                <div className=" col-2 d-flex flex-column align-items-start  position-relative container-hide-icon ">
                  <ToolTipContainerAvailableBalance>
                    {" "}
                    <Tooltip
                      tooltipColor={theme.colors.info.light300}
                      tooltipLabel={
                        !hideAvailableBalance ? "Ocultar" : "Mostrar"
                      }
                      visible={isHovered}
                    />
                  </ToolTipContainerAvailableBalance>

                  <img
                    className="image-eye"
                    src={!hideAvailableBalance ? EyeIcon : EyeOffIcon}
                    onClick={() =>
                      setHideAvailableBalance(!hideAvailableBalance)
                    }
                    onMouseEnter={toggleHovered}
                    onMouseLeave={toggleHovered}
                    style={
                      isHovered
                        ? {
                            filter:
                              "brightness(0) saturate(100%) invert(71%) sepia(99%) saturate(1215%) hue-rotate(168deg) brightness(93%) contrast(89%)",
                          }
                        : {}
                    }
                  />
                </div>
              </div>
              <div>
                <Description color="#666666">{`Taxa `}</Description>
                <Description className="font-weight-bolder">
                  R${" "}
                  {formatNumberToCurrencyWithoutSimbol(isTEV ? taxTEV : taxTED)}{" "}
                </Description>
              </div>
            </div>

            {insufficientBalance && (
              <>
                <Spacer bottom={-20} />
                <BalanceAlert
                  title="Saldo insuficiente"
                  message="Essa transferência não poder ser feita por saldo insuficiente"
                />
                <Spacer bottom={20} />
              </>
            )}
          </div>
          <Spacer bottom={-30} />
          <ContainerInfos>
            <Spacer bottom={10} />
            <div className="p-4">
              <table className=" w-100">
                <tbody>
                  <tr>
                    <td>
                      <Description color="#666666">Quando</Description>
                    </td>

                    <td className="second-column ">
                      <div className="container-input-date">
                        <Input type="date" value={getCurrentDate()} disabled />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">
                        Tipo de transferência
                      </Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {isTEV ? "Transferência entre contas" : "TED"}
                      </Description>
                    </td>
                  </tr>
                </tbody>
              </table>

              <Spacer top={40} />

              <Title>Dados do favorecido</Title>

              <table className=" w-100">
                <tbody>
                  <tr>
                    <td>
                      <Description color="#666666">Nome Completo</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {isTEV
                          ? newTransferTEVData[0].owner.name
                          : newTransferTEDData.name}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">CPF/CNPJ</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {isTEV
                          ? hideFormatDocumentNumber(
                              newTransferTEVData[0].owner.document_number
                            )
                          : newTransferTEDData?.document_number}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Instituição</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {isTEV
                          ? newTransferTEVData[0] &&
                            newTransferTEVData[0].bank_data
                            ? newTransferTEVData[0].bank_data.name
                            : "-"
                          : newTransferTEDData &&
                            newTransferTEDData?.bank_accounts[bankAccountIndex]
                          ? newTransferTEDData?.bank_accounts[bankAccountIndex]
                              .bank_data.name
                          : "-"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Agência</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {isTEV
                          ? newTransferTEVData[0].agency
                          : newTransferTEDData?.bank_accounts[bankAccountIndex]
                              ?.agency}
                      </Description>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Description color="#666666">Conta</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {isTEV
                          ? newTransferTEVData[0].account_number +
                            "-" +
                            newTransferTEVData[0].account_number_digit
                          : newTransferTEDData?.bank_accounts[bankAccountIndex]
                              ?.account_number +
                            "-" +
                            newTransferTEDData?.bank_accounts[bankAccountIndex]
                              ?.account_number_digit}
                      </Description>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Spacer bottom={20} />

              <div>
                <div className="d-flex align-items-center ">
                  <Title>Recorrência</Title>

                  <Spacer left={20} />
                  <div onClick={handleRecurrence}>
                    <Toggle mode="small" checked={isRecurrence} />
                  </div>
                </div>
                {isRecurrence && (
                  <>
                    <Spacer bottom={20} />
                    <div style={{ zIndex: "100" }}>
                      <CustomSelect
                        options={recurrenceOptions}
                        placeholder={"Selecione"}
                        onChange={handleSelectChange}
                        menuPlacement="top"
                        isSearchable={false}
                      />
                    </div>
                  </>
                )}
              </div>
              <Spacer bottom={10} />
              <form>
                <div style={{ zIndex: "-1" }}>
                  <Input
                    type="textarea"
                    name="description"
                    value={values.description}
                    label="Descrição"
                    placeholder="Deixe um texto de exemplo aqui"
                    onChange={handleChange}
                  />
                </div>
                <Spacer bottom={50} />
              </form>
            </div>
          </ContainerInfos>
          <ContainerBottom className="d-flex justify-content-between p-4 ">
            <Button mode="phantom" label="Cancelar" onClick={handleCancel} />
            <Button
              mode="main"
              label="Transferir agora"
              onClick={handleClick}
              disabled={insufficientBalance}
            />
          </ContainerBottom>
        </Container>
      )}
    </motion.div>
  );
};

export default SendTEDorTEV;
