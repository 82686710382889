import { createHashRouter } from "react-router-dom";

/* Signup */
import signupRoutes from "./signupRoutes";

/* Authentication */
import Login from "~/pages/Authentication/Login";
import NewPassword from "~/pages/Authentication/NewPassword";

import AppLayout from "~/pages/App/AppLayout/AppLayout";
import BackofficeLayout from "~/pages/Backoffice/AppLayout/AppLayout";

import StatementsStructure from "~/pages/App/Statements/StatementsStructure";
import CollectionsStructure from "~/pages/App/Collections/CollectionsStructure";

/* Contacts */
import Contacts from "~/pages/App/Contacts/ContactsStructure";
import NewContactStructure from "~/pages/App/Contacts/NewContact/NewContactStructure";

import PaymentsStructure from "~/pages/App/Payments/PaymentsStructure";
import PIXStructure from "~/pages/App/PIX/PIXStructure";
import PixLotStructure from "~/pages/App/PixLot/PixLotStructure";
import TransfersStructure from "~/pages/App/Transfers/TransfersStructure";
import OldDashboard from "~/pages/App/Dashboard/OldDashboard";
import ErrorBoundary from "~/pages/App/ErrorBoundary/ErrorBoundary";

import PublicRouter from "~/components/PublicRouter";
import PrivateRouter from "~/components/PrivateRouter";
import PageDownloadApp from "~/pages/App/ErrorBoundary/PageDownloadApp";
import PermissionsStructure from "~/pages/App/Permissions/PermissionsStructure";
import ConcessionStructure from "~/pages/App/Permissions/ConcessionsStructure";
import PermissionGroupStructure from "~/pages/App/Permissions/PermissionGroupStructure";
import AuthoritysStructure from "~/pages/App/Authoritys/AuthoritysStructure";
import CnabStructure from "~/pages/App/Cnab/CnabStructure";
import Checkout from "~/pages/App/Checkout/Checkout";
import BackofficeStructure from "~/pages/App/Backoffice/BackofficeStructure";
import Details from "~/pages/App/Backoffice/Details/Details";
import TariffsStructure from "~/pages/App/MySpace/Tariffs/TariffsStructure";
import BackofficeLogin from "~/pages/Authentication/BackofficeLogin";
import SplitStructure from "~/pages/App/MySpace/Splits/SplitStructure";
import EditSplitStructure from "~/pages/App/MySpace/Splits/EditSplit/EditSplitStrucuture";
import DetailsContact from "~/pages/App/Contacts/DetailsContact";
import EditContact from "~/pages/App/Contacts/EditContacts/EditContact";
import ConcessionsStructure from "~/pages/App/MySpace/Concessions/ConcessionsStructure";
import MySpaceStructure from "~/pages/App/MySpace/MySpaceStructure";
import CollectionDefault from "~/pages/App/MySpace/CollectionDefault/CollectionDefault";
import DataNewPackpage from "~/pages/App/MySpace/Tariffs/NewPackpage/Data";
import SelectClients from "~/pages/App/MySpace/Tariffs/NewPackpage/SelectClients";
import EditPackpages from "~/pages/App/MySpace/Tariffs/EditPackpages/EditPackpages";
import AddNewClients from "~/pages/App/MySpace/Tariffs/EditPackpages/AddNewClients";
import RenderTariffs from "~/pages/App/MySpace/Tariffs/EditPackpages/Tariffs";
import RenderCustomers from "~/pages/App/MySpace/Tariffs/EditPackpages/Clients";
import CardsStructure from "~/pages/App/Cards/CardsStructure";
import CardsDashboardContainer from "~/pages/App/Cards/pages/CardsDashboardContainer";
import MyCardsActivateContainer from "~/pages/App/Cards/pages/MyCardsActivateContainer";
import MyCardsBlockContainer from "~/pages/App/Cards/pages/MyCardsBlockContainer";
import MyCardsCreateContainer from "~/pages/App/Cards/pages/MyCardsCreate";
import MyCardsUnblockContainer from "~/pages/App/Cards/pages/MyCardsUnblockContainer";
import useAuth from "~/hooks/useAuth";
import BackofficeRouter from "~/components/BackofficeRouter";
import Tax from "~/pages/App/MySpace/Tax/Tax";
import PixLotDetails from "~/pages/App/PixLot/PixLotDetails";
import PaymentLotStructure from "~/pages/App/PaymentsLot/PaymentLotStructure";
import PaymentLotDetails from "~/pages/App/PaymentsLot/PaymentLotDetails";
import Dashboard from "~/pages/App/Dashboard/Dashboard";

const defaultOptions = {
  errorElement: <ErrorBoundary />,
};

const signup = signupRoutes(defaultOptions);

const router = createHashRouter([
  {
    path: "/",
    element: (
      <PublicRouter>
        <Login />
      </PublicRouter>
    ),
    ...defaultOptions,
  },

  {
    path: "/app/*",
    element: (
      <div className="d-block">
        <AppLayout />
      </div>
    ),
    children: [
      {
        path: "",
        element: (
          <PrivateRouter>
            <OldDashboard />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "dashboard",
        element: (
          <PrivateRouter>
            <OldDashboard />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "dashboard/new",
        element: (
          <PrivateRouter>
            <Dashboard />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "statements",
        element: (
          <PrivateRouter>
            <StatementsStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },

      {
        path: "pix",
        element: (
          <PrivateRouter>
            <PIXStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "pix-lot",
        element: (
          <PrivateRouter>
            <PixLotStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "pix-lot/:id",
        element: (
          <PrivateRouter>
            <PixLotDetails />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "payments",
        element: (
          <PrivateRouter>
            <PaymentsStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "cards",
        element: (
          <PrivateRouter>
            <CardsStructure />
          </PrivateRouter>
        ),
        children: [
          {
            path: "",
            element: <CardsDashboardContainer />,
          },
          {
            path: "create",
            element: <MyCardsCreateContainer />,
          },
          {
            path: "activate",
            element: <MyCardsActivateContainer />,
          },
          {
            path: "block",
            element: <MyCardsBlockContainer />,
          },
          {
            path: "unblock",
            element: <MyCardsUnblockContainer />,
          },
        ],
        ...defaultOptions,
      },
      {
        path: "payments-lot",
        element: (
          <PrivateRouter>
            <PaymentLotStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "payments-lot/:id",
        element: (
          <PrivateRouter>
            <PaymentLotDetails />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "transfers",
        element: (
          <PrivateRouter>
            <TransfersStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "collections",
        element: (
          <PrivateRouter>
            <CollectionsStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "cnab",
        element: (
          <PrivateRouter>
            <CnabStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "cards",
        element: (
          <PrivateRouter>
            <NewContactStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "contacts",
        element: (
          <PrivateRouter>
            <Contacts />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "contacts/new",
        element: (
          <PrivateRouter>
            <NewContactStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "contacts/edit/:id",
        element: (
          <PrivateRouter>
            <EditContact />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "contacts/details/:id",
        element: (
          <PrivateRouter>
            <DetailsContact />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },

      {
        path: "permissions",
        element: (
          <PrivateRouter>
            <PermissionsStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "permissions/manage",
        element: (
          <PrivateRouter>
            <PermissionGroupStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "permissions/concessions",
        element: (
          <PrivateRouter>
            <ConcessionStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },

      {
        path: "authoritys",
        element: (
          <PrivateRouter>
            <AuthoritysStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "backoffice",
        element: (
          <PrivateRouter>
            <BackofficeStructure />
          </PrivateRouter>
        ),

        ...defaultOptions,
      },
      {
        path: "backoffice/:id",
        element: (
          <PrivateRouter>
            <Details />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "myspace/tariffs/edit/:id",
        element: (
          <PrivateRouter>
            <BackofficeRouter>
              <EditPackpages />
            </BackofficeRouter>
          </PrivateRouter>
        ),
        children: [
          {
            path: "tariffs",
            element: <RenderTariffs />,
          },

          {
            path: "customers",
            element: <RenderCustomers />,
          },
        ],
        ...defaultOptions,
      },
      {
        path: "myspace/tariffs/edit/new-clients/:id",
        element: (
          <PrivateRouter>
            <BackofficeRouter>
              <AddNewClients />
            </BackofficeRouter>
          </PrivateRouter>
        ),

        ...defaultOptions,
      },
      {
        path: "myspace",
        element: (
          <PrivateRouter>
            <MySpaceStructure />
          </PrivateRouter>
        ),
        children: [
          {
            path: "tariffs",
            element: (
              <BackofficeRouter>
                <TariffsStructure />
              </BackofficeRouter>
            ),
            ...defaultOptions,
          },

          {
            path: "tax",
            element: <Tax />,

            ...defaultOptions,
          },

          {
            path: "tariffs/new-packpage",
            element: <DataNewPackpage />,

            ...defaultOptions,
          },
          {
            path: "tariffs/new-packpage-clients",
            element: <SelectClients />,

            ...defaultOptions,
          },

          {
            path: "concessions",
            element: <ConcessionsStructure />,
            ...defaultOptions,
          },
          {
            path: "collection-management",
            element: <CollectionDefault />,
            children: [
              {
                path: "split",
                element: <SplitStructure />,
              },
            ],
            ...defaultOptions,
          },
        ],
        ...defaultOptions,
      },

      {
        path: "myspace/collection-management/split/edit",
        element: (
          <PrivateRouter>
            <EditSplitStructure />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
    ],
  },

  {
    path: "/backoffice/*",
    element: (
      <div className="d-block">
        <BackofficeLayout />
      </div>
    ),
    children: [
      {
        path: "pending",
        element: (
          <PrivateRouter>
            <BackofficeStructure />
          </PrivateRouter>
        ),

        ...defaultOptions,
      },

      {
        path: "pending/:id",
        element: (
          <PrivateRouter>
            <Details />
          </PrivateRouter>
        ),
        ...defaultOptions,
      },
    ],
  },

  {
    path: "auth",
    children: [
      {
        path: "login",
        element: (
          <PublicRouter>
            <Login />
          </PublicRouter>
        ),
        ...defaultOptions,
      },
      {
        path: "backoffice",
        element: (
          <PublicRouter>
            <BackofficeLogin />
          </PublicRouter>
        ),
        ...defaultOptions,
      },

      {
        path: "new-password",
        element: (
          <PublicRouter>
            <NewPassword />
          </PublicRouter>
        ),
        ...defaultOptions,
      },
    ],
  },

  signup,
]);

export default router;
