import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import PaidIcon from "~/assets/icons/status/ic_functional_status_success.png";
import {
  Button,
  ReturnButton,
  Spacer,
  SummaryPanel,
  Table,
  Dropzone,
  BalanceAlert,
  Breadcrumb,
  NotControlledToolTip,
} from "@zilliondigital/mirage-ui";
import SuccessIcon from "~/assets/icons/status/done_black_24dp (1).svg";
import XIcon from "~/assets/icons/status/done_black_24dp.png";
import ErrorIcon from "~/assets/icons/status/error.png";

import { Fragment, useEffect, useMemo, useState } from "react";
import ApiService from "~/services/api";

import { useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import EyeIcon from "~/assets/icons/eye-Size=MD.svg";

import {
  ContainerData,
  GeneralContainer,
  ImgEye,
} from "~/pages/App/app-content.styles";
import {
  formatCPFCNPJ,
  formatCash,
  formatDateTime,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import useToast from "~/hooks/useToast";
import theme from "~/themes/theme";
import useAccount from "~/hooks/useAccount";
import Copyright from "~/components/Copyright";
import PixLotModal, { _massOperate } from "./PixLotModal";
import usePix from "~/hooks/PIX/usePix";
import Description from "~/components/Texts/Description";

import Badge from "~/components/Badge";
import useToken from "~/hooks/useToken";

const getStatus: { [key: string]: JSX.Element } = {
  waiting_approval: (
    <Badge
      label="Em aprovação"
      color={theme.colors.base.light200}
      textColor={theme.colors.base.main}
    />
  ),
  pending: <Badge label="Pendente" color={"#FEE7CC"} textColor={"#FC8802"} />,
  processing: (
    <Badge label="Processando" color={"#FEE7CC"} textColor={"#FC8802"} />
  ),
  validating: (
    <Badge label="Validando" color={"#FEE7CC"} textColor={"#FC8802"} />
  ),
  done: (
    <Badge
      label="Concluído"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  completed: (
    <Badge
      label="Concluído"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  created: (
    <Badge
      label="Criado"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  paid: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  schedule: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  scheduled: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  canceled: (
    <Badge
      label="Cancelado"
      color={theme.colors.error.light200}
      textColor={theme.colors.error.main}
    />
  ),
  refused: (
    <Badge
      label="Reprovado"
      color={theme.colors.error.light200}
      textColor={theme.colors.error.main}
    />
  ),
  error: (
    <div
      style={{
        width: "45px",
        height: "23,4px",
        display: "flex",

        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img style={{ height: "23,4px" }} src={ErrorIcon} />{" "}
    </div>
  ),
  approved: (
    <img
      style={{ width: "45px", height: "23,4px" }}
      src={PaidIcon}
      className="d-block m-auto"
    />
  ),
  completed_with_errors: (
    <Badge
      label="Concluído parcialmente"
      color={"#ceccef"}
      textColor={"#0012b3"}
    />
  ),
};

const SummaryWrapper = styled.div`
  width: 100%;
  .summary-container {
    span {
      padding-left: 0 !important;
    }
  }
`;

const statusLabelDictionary: { [key: string]: string } = {
  validating: "Validando",
  created: "Criado",
  processing: "Processando",
  waiting_approval: "Aguardando aprovação",
  done: "Concluido",
  error: "Erro",
  completed: "Completo",
  completed_with_errors: "Completo com Errros",
  failed: "Falhou",
};

const statusDictionary: { [key: string]: string } = {
  validating: "warning",
  created: "warning",
  processing: "warning",
  completed: "Completo",
  completed_with_errors: "Completo com Errros",
  failed: "Falhou",
  done: "success",
  error: "error",
};

const validation_columns = [
  {
    key: "response",
    label: "Valido",
    renderData: (data: any) => {
      const _success =
        (data.response && data.response.success) ||
        data.row.transfer_type === "CONTA";
      return _success ? (
        <Badge
          label="Sim"
          color={"#CCEFDF"}
          textColor={"#00B360"}
          icon={SuccessIcon}
        />
      ) : (
        <Badge
          label="Não"
          color={"#FADCDA"}
          textColor={"#E80606"}
          icon={XIcon}
        />
      );
    },
  },

  {
    key: "row",
    label: "Nome (Planilha)",
    renderData: (data: any) => {
      return data.row ? (
        <>
          {data.row.name}
          <Description color={theme.colors.base.main}>
            {formatCPFCNPJ(String(data.row.document))}
          </Description>
        </>
      ) : (
        "—"
      );
    },
  },

  {
    key: "row",
    label: "Chave (Planilha)",
    renderData: (data: any) => {
      return data.row ? data.row.key : "—";
    },
  },
  {
    key: "row",
    label: "Banco (Planilha)",
    renderData: (data: any) => {
      return data.row.bank && data.row.agency ? (
        <div className="d-flex flex-column">
          <Description>{data.row.bank}</Description>
          <Description>Ag. - {data.row.agency}</Description>
        </div>
      ) : (
        "—"
      );
    },
  },
  {
    key: "row",
    label: "Conta (Planilha)",
    renderData: (data: any) => {
      return data.row.account ? (
        <div>
          <Description>{data.row.account}</Description>
        </div>
      ) : (
        "—"
      );
    },
  },
  {
    key: "response",
    label: "Nome (Chave)",
    renderData: (data: any) => {
      return data.response && data.response.success ? (
        <>
          {data.response.name}
          <Description color={theme.colors.base.main}>
            {formatCPFCNPJ(data.response.document_number)}
          </Description>
        </>
      ) : (
        "—"
      );
    },
  },

  {
    key: "response",
    label: "Banco (Chave)",
    renderData: (data: any) => {
      return data.response && data.response.success ? (
        <>
          Ag. {data.response.agency} C/C. {data.response.account_number}-
          {data.response.account_number_digit}
          <Description color={theme.colors.base.main}>
            {data.response.bank} - {data.response.ispb}
          </Description>
        </>
      ) : (
        "—"
      );
    },
  },
  {
    key: "response",
    label: "Valor ",
    renderData: (data: any) => {
      return data.row
        ? `R$ ${formatNumberToCurrencyWithoutSimbol(data.row.amount)}`
        : "—";
    },
  },
  {
    key: "response",
    label: "Descrição ",
    renderData: (data: any) => {
      return data.row ? data.row.description : "—";
    },
  },
  {
    key: "response",
    label: "Mens. de Erro",
    renderData: (data: any) => {
      return data.row.transfer_type === "CHAVE" && !data.response.success
        ? data.response.message
        : "—";
    },
  },
];

const PixLotStructure = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const {
    setFile,

    setSubmitting,
    operations,
    setOperations,
    setRenderDropzone,
    sending,
    setSending,
    setOpenPixLotModal,
    taxPixLot,
    setTaxPixLot,
    fetchPixOperations,
    massTransferOperations,
    massTransferOperationsLoading,
  } = usePix();

  const { getBalance, mainAccount, setActiveIndexSideBar, balance } =
    useAccount();

  const menusBreadCrumb = [
    { label: "Pix", onClick: () => navigate("/app/pix") },
    { label: "Pix em lote" },
  ];

  const operation_columns = [
    {
      key: "status",
      label: "Status",
      renderData: (data: any) => (
        <div
          className=""
          style={{ maxWidth: "130px" }}
          id={`dot-status-${data.id}`}
        >
          {getStatus[data.status]}
          <NotControlledToolTip
            placement="top"
            target={`dot-status-${data.id}`}
          >
            {statusLabelDictionary[data.status]}
          </NotControlledToolTip>
        </div>
      ),
    },

    {
      key: "total_quantity",
      label: "Total",
      renderData: (data: any) => <span>{data.quantity}</span>,
    },
    {
      key: "total_amount",
      label: "Total (R$)",
      renderData: (data: any) => <span>{formatCash(data.amount)}</span>,
    },
    {
      key: "total_success_quantity",
      label: "Sucessos",
      renderData: (data: any) => <span>{data.quantity_completed}</span>,
    },
    {
      key: "total_processing_quantity",
      label: "Processando",
      renderData: (data: any) => <span>{data.quantity_processing}</span>,
    },
    {
      key: "total_failed_amount",
      label: "Falhas",
      renderData: (data: any) => <span>{data.quantity_failed}</span>,
    },
    {
      key: "created_date",
      label: "Data de envio",
      renderData: (data: any) => (
        <span>{formatDateTime(data.created_date)}</span>
      ),
    },
    {
      key: "actions",
      label: "",

      renderData: (data: any) => {
        return (
          <div className="ps-3 pe-3">
            <div
              className="d-flex justify-content-center align-items-center"
              id={`details-${data.index}`}
            >
              <ImgEye
                className=""
                src={EyeIcon}
                onClick={() => navigate(`/app/pix-lot/${data.id}`)}
              />
              <NotControlledToolTip
                placement="top"
                target={`details-${data.index}`}
              >
                {"Visualizar detalhes"}
              </NotControlledToolTip>
            </div>
          </div>
        );
      },
    },
  ];

  const { sendPartnerToken, getToken } = useToken();

  const fetchTax = async () => {
    try {
      const response = await ApiService.HttpPost({
        route: "customer-global-tax/",
        token: true,
        body: {
          slug: "pix_lot",
          tax_is_enabled: true,
        },
      });

      setTaxPixLot(response.data.customer_tax_out);
    } catch (err) {
      console.error("Erro", err);
    }
  };

  const submitValidatedFile = async () => {
    sendPartnerToken();

    const token = await getToken();

    setSending(true);

    const payload = {
      operation: operations,
      code: token,
    };

    const response = await _massOperate(payload);

    if (response && !response.error) {
      showToast("Envio", "Operação enviada com sucesso", "success");

      setTimeout(() => {
        getBalance();
      }, 2000);
      fetchPixOperations();
      setFile("");
      setOperations([]);
    } else {
      showToast("Envio", "Erro ao transferir", "error");
    }

    setSending(false);
  };

  const onRemoveFile = (removed?: any) => {
    setFile("");
    setOperations([]);
    setSubmitting(false);
  };

  const operationTotal = useMemo(() => {
    if (operations.length === 0) return 0;

    return operations.reduce((prev, curr: any) => prev + curr.row.amount, 0);
  }, [operations]);

  const hasError = useMemo(() => {
    if (operations.length === 0) return false;

    return operations.some((item: any) =>
      item.row.transfer_type === "CONTA"
        ? false
        : !item.response || !item.response.success
    );
  }, [operations]);

  const totalTax = useMemo(() => {
    return taxPixLot * operations.length;
  }, [operationTotal]);

  const insuficienteBalance = useMemo(() => {
    const totalWithTax = operationTotal + totalTax;
    return balance < Number(totalWithTax.toFixed(2));
  }, [balance, totalTax]);

  useEffect(() => {
    fetchTax();
    fetchPixOperations();
  }, [mainAccount]);

  const pixLotPanel = useMemo(() => {
    const total = massTransferOperations.reduce(
      (prev, curr: any) => prev + curr.quantity,
      0
    );

    return [
      {
        label: "Total de lotes",
        balance: String(massTransferOperations.length),
      },
      {
        label: "Total de transações individuais",
        balance: String(total),
      },
    ];
  }, [massTransferOperations]);

  return (
    <Fragment>
      <GeneralContainer>
        <div className="d-flex flex-column container-fluid w-100 ps-5 pe-5">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />
          <Spacer top={30} />

          <ContainerData className="container-fluid ps-4 pe-4">
            {operations.length == 0 ? (
              <Fragment>
                <div className="p-4 d-flex justify-content-between">
                  <Title>Pix em lote</Title>
                  <div>
                    <Button
                      mode="main"
                      label="Novo pix em lote"
                      onClick={() => setOpenPixLotModal(true)}
                    />
                  </div>
                </div>

                {massTransferOperations.length > 0 ? (
                  <Fragment>
                    <SummaryPanel
                      panel={pixLotPanel}
                      isLoading={massTransferOperationsLoading}
                    />

                    <div className="p-4 w-100">
                      <div>
                        <div className="table-responsive">
                          <Table
                            columns={operation_columns}
                            data={massTransferOperations}
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <div>
                    <Spacer bottom={70} />
                    <div className="d-flex flex-column">
                      <Title className="text-center">
                        Nenhuma operação de Pix em lote efetuada até o momento
                      </Title>
                      <Spacer bottom={20} />
                      <Description className="text-center">
                        Para começar uma nova operação de pix em <br /> lote,
                        basta clicar em
                        <br /> Novo pix em lote localizado no <br /> canto
                        superior direito
                      </Description>
                    </div>
                  </div>
                )}
              </Fragment>
            ) : null}

            {operations.length ? (
              <div className="p-4 w-100">
                <div className="d-flex justify-content-end">
                  {!sending ? (
                    <Button
                      mode="border"
                      onClick={() => {
                        onRemoveFile();
                        setRenderDropzone(false);
                        setTimeout(() => {
                          setRenderDropzone(true);
                        }, 100);
                      }}
                      type="button"
                      label={"Cancelar"}
                    />
                  ) : null}
                  <Spacer left={10} />

                  <Button
                    label="Alterar arquivo"
                    mode="border"
                    onClick={() => setOpenPixLotModal(true)}
                  />
                  <Spacer left={10} />
                  <Button
                    mode="main"
                    onClick={() => submitValidatedFile()}
                    label={sending ? "Enviado" : "Confirmar Operação"}
                    loading={sending}
                    disabled={sending || hasError || insuficienteBalance}
                    className="mr-1"
                  />
                </div>
                <Spacer bottom={20} />
                <SummaryWrapper>
                  <div className="summary-container">
                    <SummaryPanel
                      panel={[
                        {
                          label: "Total de linhas",
                          balance: String(operations.length),
                        },
                        {
                          label: "Total de linhas válidas",
                          balance: String(
                            operations.filter((item: any) =>
                              item.row.transfer_type === "CHAVE"
                                ? item.response && item.response.success
                                : item
                            ).length
                          ),
                        },
                        {
                          label: "Total de linhas  inválidas",
                          balance: String(
                            operations.filter((item: any) =>
                              item.row.transfer_type === "CHAVE"
                                ? !item.response || !item.response.success
                                : !item.row
                            ).length
                          ),
                        },
                      ]}
                    />

                    <SummaryPanel
                      panel={[
                        {
                          label: "Valor total das taxas",
                          balance: `R$ ${formatNumberToCurrencyWithoutSimbol(
                            totalTax.toFixed(2)
                          )}`,
                        },
                        {
                          label: "Valor total das Operações",
                          balance: `R$ ${formatNumberToCurrencyWithoutSimbol(
                            operationTotal.toFixed(2)
                          )}`,
                        },
                        {
                          label: "Seu Saldo",
                          balance: `R$ ${formatNumberToCurrencyWithoutSimbol(
                            balance
                          )}`,
                        },
                      ]}
                    />
                  </div>
                </SummaryWrapper>

                {/* <div className="mt-3">
                  <Description color="#666666">{`Taxa total `}</Description>
                  <Description className="font-weight-bolder">
                    R$ {formatNumberToCurrencyWithoutSimbol(totalTax)}
                    {` (${operations.length} transações)`}
                  </Description>
                </div> */}

                {hasError ? (
                  <>
                    {" "}
                    <Spacer top={15} />
                    <Description color={theme.colors.error.main}>
                      Existem erros na operação, corrija-os ou remova as linhas
                      com erro!
                    </Description>
                  </>
                ) : null}
                {insuficienteBalance && (
                  <div className="mt-3">
                    {" "}
                    <BalanceAlert
                      title="Saldo insuficiente"
                      message={"Você não tem saldo suficiente para continuar"}
                    />
                  </div>
                )}

                <div>
                  <Spacer bottom={25} />
                  <div className="table-responsive">
                    <Table columns={validation_columns} data={operations} />
                  </div>
                  <Spacer bottom={15} />
                </div>
              </div>
            ) : null}
          </ContainerData>

          <Spacer top={40} />

          <Copyright />

          <Spacer top={40} />
        </div>
        <PixLotModal />
      </GeneralContainer>
    </Fragment>
  );
};

export default PixLotStructure;
