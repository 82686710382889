import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Spacer,
  NotControlledToolTip,
  InteractiveModal,
  Button,
  Input,
} from "@zilliondigital/mirage-ui";

import useAuthority from "~/hooks/useAuthority";
import AddIcon from "~/assets/icons/add-black.png";
import { applyPagination } from "~/components/Paginator";
import TitleModal from "~/components/Texts/TitleModal";
import Skeleton from "~/components/SkeletonTables";
import { formatCPFCNPJ } from "~/utils";
import DoubleUserIcon from "~/assets/icons/user-couple.svg";

import { useEffect, useState } from "react";
import { Columns } from "~/types";

const AddApproverData = () => {
  const {
    getBankAccounts,
    approvers,
    addNewApprover,
    accounts,
    loadingApprove,
    setCurrentGrantModalData,
    currentGrantModalData,
    loadingBankAccounts,
    page,
    rowsPerPage,
  } = useAuthority();

  const [canAdd, setCanAdd] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const handleAdd = async () => {
    setCanAdd(false);
    await addNewApprover(currentGrantModalData.owner.id);
  };

  const applyFilters = (listSet: any) => {
    if (!search) {
      return listSet;
    }

    const filtered = listSet.filter((_x: any) => {
      const name = _x.owner.name.toLowerCase();
      const haveName = name.includes(search.toLowerCase());

      const haveDocument = _x.owner.document_number.includes(search);

      const accountNumer = _x.account_number.toLowerCase();
      const haveAccountNumber = accountNumer.includes(search.toLowerCase());

      return haveName || haveDocument || haveAccountNumber;
    });

    return filtered;
  };

  useEffect(() => {
    getBankAccounts();
  }, [approvers]);

  const columns: Columns[] = [
    {
      label: "Proprietário",
      key: "id",
      renderData: (data: any) => (
        <span>
          {data.owner.name} - {formatCPFCNPJ(data.owner.document_number)}
        </span>
      ),
    },
    {
      label: "Conta",
      key: "id",
      renderData: (data: any) => {
        return (
          <span>
            Cc. {data.bank_account_data.account_number}-
            {data.bank_account_data.account_number_digit}
          </span>
        );
      },
    },
    {
      label: "Permissão",
      key: "permissions_role",
      renderData: (data: any) => {
        return <span>{data.permissions_role.name}</span>;
      },
    },

    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="">
            <Button
              id={`btn-${data.id}`}
              mode="phantom"
              label=""
              title="Incluir"
              iconPosition="right"
              onClick={() => {
                setCurrentGrantModalData(data);
                setCanAdd(true);
              }}
              icon={AddIcon}
            />
            <NotControlledToolTip placement="top" target={`btn-${data.id}`}>
              Adicionar
            </NotControlledToolTip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="col-12">
        <hr className="m-0" />
        <Spacer top={15} />
        <div className="col-6">
          <Input
            type="text"
            label="Filtro de pesquisa"
            name="instruction"
            placeholder="Pesquise por nome, documento ou conta bancária"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <hr className="m-0" />
      </div>
      <Spacer bottom={15} />
      <>
        {loadingBankAccounts ? (
          <Skeleton />
        ) : accounts.length === 0 ? (
          <div className="empty-table-box">
            <img src={DoubleUserIcon} />
            <span>
              Nenhuma concessão cadastrada <br />
              até o momento
            </span>
          </div>
        ) : (
          <div className="w-100">
            <Table
              data={applyPagination(applyFilters(accounts), {
                page,
                rowsPerPage,
              })}
              columns={columns}
              dataSpacer={8}
            />
          </div>
        )}
      </>

      <InteractiveModal
        open={canAdd}
        title="exclusao"
        size="md"
        toggleModal={() => setCanAdd(!canAdd)}
      >
        <div className="p-4 h-100 d-flex h-100 text-center justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
          <TitleModal>Adicionar ao grupo de aprovação</TitleModal>
          <div className="d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
            <p>
              Deseja realmente adicionar essa conta para ser uma conta aprovação
              de aprovação de operações?
            </p>
            <div style={{ width: "290px" }} className="mb-5">
              <Spacer bottom={5} />
              <Button
                mode="main"
                label="Sim, adicionar!"
                full
                onClick={() => {
                  handleAdd();
                }}
                disabled={loadingApprove}
                loading={loadingApprove}
              />
              <Spacer bottom={5} />
              <Button
                mode="border"
                label="Cancelar"
                full
                onClick={() => setCanAdd(false)}
              />
            </div>
          </div>
        </div>
      </InteractiveModal>
    </>
  );
};

export default AddApproverData;
