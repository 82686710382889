import { Button, Input, Spacer } from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import usePayments from "~/hooks/usePayments";
import * as Yup from "yup";
import ApiService from "~/services/api";
import {
  isWeekDayPayments,
  isWorkingHoursPayments,
  onlyNumbers,
} from "~/services/utils";
import useAccount from "~/hooks/useAccount";
import { useEffect, useState } from "react";
import useToast from "~/hooks/useToast";
import Description from "~/components/Texts/Description";
import CustomerTax from "~/components/CustomerTax";

type BarcodeFormValues = {
  barcode: string;
};

const initialValues: BarcodeFormValues = {
  barcode: "",
};

const FormSchema = Yup.object().shape({
  code: Yup.string().required("Campo obrigatório"),
});

const Barcode = () => {
  const { mainAccount } = useAccount();
  const {
    nextStep,
    handleCloseNewPaymentModal,
    setNewPaymentData,
    openNewPaymentModal,
    setIsLoadingNewPayment,
  } = usePayments();
  const [isError, setIsError] = useState<boolean>(false);

  const { bankAccounts } = useAccount();

  const { showToast } = useToast();

  const [outOfTime, setOutOfTime] = useState<boolean>(false);

  const handleSubmit = (values: any, formikHelpers: any) => {
    return;
  };

  const {
    errors,
    setFieldError,
    resetForm,
    setSubmitting,
    values,
    handleChange,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,

    onSubmit: handleSubmit,
  });

  const PayBillet = async () => {
    setIsLoadingNewPayment(true);
    setOutOfTime(false);

    try {
      const { data } = await ApiService.HttpPost({
        route: "charge/barcode-reader/",
        token: true,

        body: {
          bank_account: mainAccount?.bank_account,
          barcode: onlyNumbers(values.barcode),
        },
      });

      if (data.success === false) {
        showToast("Código de barras inválido!", "", "error");
        setIsLoadingNewPayment(false);
      } else {
        setNewPaymentData(data);
        nextStep();
        setIsLoadingNewPayment(false);
      }

      setIsLoadingNewPayment(false);
    } catch (error: any) {
      console.warn(error);

      setIsLoadingNewPayment(false);
    }
  };

  useEffect(() => {
    if (openNewPaymentModal === false) {
      resetForm();
      setOutOfTime(false);
    }
  }, [openNewPaymentModal]);

  return (
    <div className="p-4">
      <Input
        type="text"
        name="barcode"
        value={values.barcode}
        onChange={handleChange}
        label="Insira o código de barras"
        placeholder="Digite aqui o código de barras"
        error={isError && "Código de barras inválido"}
      />

      {outOfTime && (
        <>
          {" "}
          <Spacer top={-20} />
          <Description color="red">
            Pagamentos de boleto só estão disponíveis de Segunda a Sexta das 07h
            às 21h
          </Description>
        </>
      )}

      <CustomerTax slug="payment_billet" />

      <Spacer top={10} />

      <div className="w-100 d-flex justify-content-between">
        <Button
          mode="border"
          label="Cancelar pagamento"
          onClick={() => {
            handleCloseNewPaymentModal();
          }}
        />

        <Button
          mode="main"
          label="Avançar"
          onClick={() => {
            if (isWeekDayPayments && isWorkingHoursPayments) {
              PayBillet();
            } else {
              setOutOfTime(true);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Barcode;
