import {
  Button,
  Checkbox,
  Input,
  LateralModal,
  RadioGroup,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import useStatements from "~/hooks/useStatements";
import { Subtitle, ContainerBottom } from "~/pages/App/app-content.styles";

import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";

import useNotifications from "~/hooks/useNotifications";
import { subMonths, isAfter, endOfDay, format, subDays } from "date-fns";

type FilterStatementsValues = {
  from_date: string;
  to_date: string;
};

type GetNotifications = {
  id: number;
  partner: number;
  type: string;
  title: string;
  message: string;
  action: string;
  param: string;
  read: boolean;
  played: boolean;
  created: string;
  updatedt: string;
};

const initialValues: FilterStatementsValues = {
  from_date: "1997-02-25",
  to_date: "",
};

const FormSchema = Yup.object().shape({
  from_date: Yup.date()
    .max(
      subMonths(new Date(), 6),
      "A data inicial não pode ser mais que 6 meses atrás."
    )
    .required("A data inicial é obrigatória."),
  to_date: Yup.date()
    .max(
      endOfDay(new Date()),
      "A data final não pode ser maior que a data atual."
    )
    .required("A data final é obrigatória.")
    .when("from_date", (from_date, schema) => {
      return schema.min(
        from_date,
        "A data final não pode ser anterior à data inicial."
      );
    }),
});

const ExportStatementsModal = () => {
  const { openExportStatmentsModal, setOpenExportStatmentsModal } =
    useStatements();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setNotifications } = useNotifications();

  const [selectedValue, setSelectedValue] = useState<string>("");
  const { showToast } = useToast();

  const [initVals, setVals] = useState<any>(initialValues);

  const handleFormSubmit = async (values: any) => {
    exportStatments();
  };

  const { handleChange, setFieldValue, values, resetForm, submitForm, errors } =
    useFormik({
      validationSchema: FormSchema,
      initialValues: initVals,
      onSubmit: handleFormSubmit,
      enableReinitialize: true,
    });

  //   const loadFilters = () => {
  //     if (filterParams) {
  //       const newValues = payloadDatesToParam({
  //         ...initialValues,
  //         ...filterParams,
  //       });
  //       setVals(newValues);
  //     }
  //   };

  const handleChangeTile = (value: any) => {
    setSelectedValue(value);

    if (value === "0") {
      setSelectedValue("pdf");
    }

    if (value === "1") {
      setSelectedValue("xls");
    }

    if (value === "2") {
      setSelectedValue("ofx");
    }

    if (value === "3") {
      setSelectedValue("csv");
    }
  };

  const exportStatments = async () => {
    showToast(
      " Gerando relatório...",
      "Estamos gerando seu relatório e em breve você será notificado.",
      "warning",
      () => null,
      true,
      30000
    );
    setOpenExportStatmentsModal(false);

    try {
      const { data } = await ApiService.HttpGet({
        route: `charge/${
          selectedValue === "ofx" ? "statement-ofx" : "statement"
        }/`,
        token: true,
        params: {
          from_date: values.from_date,
          to_date: values.to_date,
          export: selectedValue,
        },
      });
    } catch (error) {
      console.warn("err", error);
      setIsLoading(false);
      showToast(
        "Ops, ocorreu um erro!",
        "Não foi possível gerar o extrato, tente novamente mais tarde.",
        "error"
      );
    }
  };

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const thirtyDaysAgo = format(subDays(today, 30), "yyyy-MM-dd");

  useEffect(() => {
    setFieldValue("from_date", thirtyDaysAgo);
    setFieldValue("to_date", todayFormatted);
  }, []);

  return (
    <LateralModal
      open={openExportStatmentsModal}
      title="Exportar extrato"
      toggleModal={() => setOpenExportStatmentsModal(!openExportStatmentsModal)}
      header
    >
      <div className=" w-100 vh-100 d-flex flex-column justify-content-start p-4">
        <form className="col-12">
          <div className="row ">
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateFrom"
                value={values.from_date}
                onChange={(v) => setFieldValue("from_date", v.target.value)}
                type="date"
                label="Selecione o período inicial"
              />
            </div>
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateTo"
                value={values.to_date}
                onChange={(v) => setFieldValue("to_date", v.target.value)}
                type="date"
                label="Selecione o período final"
                error={
                  errors.to_date &&
                  typeof errors.to_date === "string" &&
                  errors.to_date
                }
              />
            </div>
            {/* {errors.from_date && typeof errors.from_date === "string" && (
              <div>{errors.from_date}</div>
            )}
            {errors.to_date && typeof errors.to_date === "string" && (
              <div>{errors.to_date}</div>
            )} */}

            <Spacer bottom={30} />
            <p>
              <b>Selecione um dos formatos disponíveis</b>
            </p>

            <Spacer bottom={10} />
            <RadioGroup
              onChange={(value) => handleChangeTile(value)}
              wrap
              display="column"
              options={[
                {
                  title: "PDF",
                  description:
                    " Formato de arquivo portátil, compatível com diversas plataformas",
                  value: "0",
                },
                {
                  title: "XLS",
                  description: " Formato de arquivo para planilhas",
                  value: "1",
                },
                {
                  title: "CSV",
                  description:
                    " Formato de arquivo para trânsferência de dados financeiros",
                  value: "3",
                },
              ]}
            />
          </div>
        </form>

        <ContainerBottom>
          <div className="w-100 d-flex justify-content-between ">
            <Button
              mode="phantom"
              label="Cancelar"
              onClick={() => {
                setOpenExportStatmentsModal(!openExportStatmentsModal);
              }}
            />
            <Button
              mode="main"
              label="Exportar"
              loading={isLoading}
              onClick={exportStatments}
              disabled={
                values.from_date === "" ||
                values.to_date === "" ||
                selectedValue === ""
              }
            />
          </div>
        </ContainerBottom>
      </div>
    </LateralModal>
  );
};

export default ExportStatementsModal;
