import { InteractiveModal, LateralModal } from "@zilliondigital/mirage-ui";
import { Fragment, useMemo } from "react";
import useModal, { AppModals } from "~/hooks/useModal";
import TransferWithPixModalContent from "~/pages/App/PIX/TransferWithPix/TransferWithPixModalContent";

type ModalDictionaryModal = {
  title: string;
  component: JSX.Element;
};

type ModalDictionaryItem = {
  [key in keyof AppModals]: ModalDictionaryModal;
};
const ModalsDictionary: ModalDictionaryItem = {
  transferWithPix: {
    title: "",
    component: <TransferWithPixModalContent />,
  },
};

const Modal = () => {
  const { opened, modal, closeModal } = useModal();

  const modalObject = useMemo(() => {
    if (modal) {
      return ModalsDictionary[modal];
    }

    return null;
  }, [modal]);

  const renderContent = () => {
    return modalObject?.component;
  };

  return (
    <LateralModal
      title={modalObject?.title}
      header
      open={opened}
      toggleModal={closeModal}
    >
      <Fragment>{renderContent()}</Fragment>
    </LateralModal>
  );
};

export default Modal;
