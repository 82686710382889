import {
  Button,
  CustomSelect,
  Dropzone,
  LateralModal,
  ProgressBar,
  Spacer,
} from "@zilliondigital/mirage-ui";
import usePix from "~/hooks/PIX/usePix";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useEffect, useMemo } from "react";

import styled from "styled-components";

import useCollections from "~/hooks/Collections/useCollections";
import useToast from "~/hooks/useToast";
import { APIResponse, CnabReaderResponse } from "~/types";
import ApiService from "~/services/api";
import CustomerTax from "~/components/CustomerTax";
import Description from "~/components/Texts/Description";
import { formatNumberToCurrencyWithoutSimbol } from "~/utils";

const CnabLabel = styled.label`
  font-family: "Proxima Nova";
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: rgb(102, 102, 102);
  margin: 0px 0px 8px;
  position: relative;
`;

const NewCnabModal = () => {
  const BankOptions = [
    { label: "Banking", value: "banking" },
    { label: "Bradesco", value: "bradesco" },
    { label: "Itau", value: "itau" },
  ];

  const LayoutOptions = [{ label: "400", value: "400" }];

  const {
    openNewCnabModal,
    setOpenNewCnabModal,
    bank,
    setBank,
    layout,
    setLayout,
    setFile,
    file,
    details,
    setDetails,
    setOperation,
    setSubmitting,
    submitting,
    setTaxBilletLot,
    taxBilletLot,
  } = useCollections();

  const { showToast } = useToast();

  const onFileChange = (files: File[]) => {
    if (files.length > 1) {
      showToast(
        "Arquivo",
        "Envio apenas um arquivo no formato .txt/.rem",
        "error"
      );
      return;
    }
    setFile(files[0]);
  };

  const fetchTax = async () => {
    try {
      const response = await ApiService.HttpPost({
        route: "customer-global-tax/",
        token: true,
        body: {
          slug: "billet_lot",
          tax_is_enabled: true,
        },
      });

      setTaxBilletLot(response.data.customer_tax_out);
    } catch (err) {
      console.error("Erro", err);
    }
  };

  const submitValidationFile = async () => {
    setSubmitting(true);

    try {
      const form_data = new FormData();
      form_data.append("file", file);
      form_data.append("bank", bank);
      form_data.append("layout", layout);

      const response = await ApiService.HttpPost<
        APIResponse<CnabReaderResponse>
      >({
        route: "cnab/reader/",
        customHeaders: {
          "Content-Type": "multipart/form-data",
        },
        body: form_data,
        token: true,
      });

      setDetails(response.data.data.parsed_detail);
      setOperation(response.data.data.operation);
      setOpenNewCnabModal(false);

      setSubmitting(false);
    } catch (error) {
      console.error("Error on submitValidationFile", error);
    }

    setSubmitting(false);
  };

  const onRemoveFile = (removed: any) => {
    setFile("");
    setDetails([]);
    setSubmitting(false);
  };

  const operationTotal = useMemo(() => {
    if (details?.length === 0) return 0;

    return details?.reduce((prev, curr) => prev + curr.amount, 0);
  }, [details]);

  const handleCloseModal = () => {
    setFile("");
    setDetails([]);
    setBank("");
    setOperation(undefined);
    setOpenNewCnabModal(false);
  };

  useEffect(() => {
    fetchTax();
  }, []);

  return (
    <Fragment>
      <LateralModal
        style={{ zIndex: "3" }}
        header
        title={"Nova cobrança em lote"}
        open={openNewCnabModal}
        toggleModal={() => handleCloseModal()}
      >
        <div className="p-4">
          <div className="row align-items-start">
            <div className="col-6">
              <CustomSelect
                label="Selecionar o banco"
                placeholder="Selecione"
                options={BankOptions}
                onChange={({ target: { value } }) => setBank(value)}
                error={bank == "" && "Selecione um banco"}
              />
            </div>
            <div className="col-6">
              <CustomSelect
                label="Selecionar o layout"
                placeholder="Selecione"
                options={LayoutOptions}
                onChange={({ target: { value } }) => setLayout(value)}
                error={layout == "" && "Selecione um layout"}
              />
            </div>
          </div>
          <div className="row align-items-start">
            <div className="col-12">
              <CnabLabel>Arquivo CNAB</CnabLabel>
              <Dropzone
                onFilesChanged={onFileChange}
                onRemoveFile={onRemoveFile}
                accept={{
                  txt: ["text/plain", "application/txt", ".txt"],
                  rem: [".rem"],
                }}
              />

              <Spacer bottom={20} />
              <div>
                <Description color="#666666">{`Taxa de emissão `}</Description>
                <Description className="font-weight-bolder">
                  R$ {formatNumberToCurrencyWithoutSimbol(taxBilletLot)}
                  {" (Por boleto do lote) "}
                </Description>
              </div>
              <Spacer bottom={20} />
              <div className=" d-flex justify-content-end">
                <Button
                  mode="border"
                  label="Cancelar"
                  onClick={() => handleCloseModal()}
                />
                <Spacer left={20} />
                <Button
                  mode="main"
                  label={"Avançar"}
                  iconPosition="right"
                  loading={submitting}
                  disabled={
                    file === "" || submitting || bank == "" || layout == ""
                  }
                  onClick={() => submitValidationFile()}
                />
              </div>
            </div>
          </div>
        </div>
      </LateralModal>
    </Fragment>
  );
};

export default NewCnabModal;
