import { ProgressBar } from "@zilliondigital/mirage-ui";
import usePix from "~/hooks/PIX/usePix";
import { Fragment, useEffect } from "react";
import ManualPixData from "./ManualPix/ManualPixData";
import useTransferWithPix, {
  manualPixSteps,
  sendPixSteps,
} from "~/hooks/PIX/useTransferWithPix";
import ManualPixSend from "./ManualPix/ManualPixSend";
import TransferWithPixData from "./TransferWithPixData";
import TrasnferWithPixSend from "./TransferWithPixSend";
import LoadingTransferPix from "./Loader";
import useToken from "~/hooks/useToken";
import TransferReceipt from "./TransferReceipt";
import LoaderReceiptPix from "../LoaderReceiptPix";
import SelectBankAccount from "../PixWithPartnerSelected/SelectBankAccount";
import SendPix from "../PixWithPartnerSelected/SendPix";

type RenderTransferPixType = {
  [key: string]: React.ReactNode;
};

const RenderTransferPix: RenderTransferPixType = {
  data: <TransferWithPixData />,
  send: <TrasnferWithPixSend />,
};

const RenderTransferManualPix: RenderTransferPixType = {
  data: <ManualPixData />,
  send: <ManualPixSend />,
};

const RenderTransferPixSelectedPartner: any = [
  <SelectBankAccount />,
  <SendPix />,
];

export const TransferWithPixModalContent = () => {
  const { openTransferWithPixModal, setOpenTransferWithPixModal } = usePix();
  const {
    manualPix,
    setManualPix,
    stepIndex,
    isPixWithSelectedPartner,
    setStepIndex,
    isLoadingData,
    setTransferReceiptRender,
    isLoadingTransferReceipt,
    transferReceiptRender,
    setDataTransferPixReceipt,
    contactSelectedPixData,
    setIsPixWithSelectedPartner,
    setContactSelectedPixData,
  } = useTransferWithPix();

  const { setToken } = useToken();

  useEffect(() => {
    if (!openTransferWithPixModal) {
      setStepIndex(0);
      setManualPix(false);
      setToken("");
      setTransferReceiptRender(false);
      setDataTransferPixReceipt(null);
      setIsPixWithSelectedPartner(false);
      setContactSelectedPixData(undefined);
    }
  }, [openTransferWithPixModal]);

  const handlePercent = () => {
    if (manualPix) {
      return ((stepIndex + 1) * 100) / manualPixSteps.length;
    }
    return ((stepIndex + 1) * 100) / sendPixSteps.length;
  };

  return (
    <div>
      {!transferReceiptRender && !isLoadingTransferReceipt && (
        <div>
          <ProgressBar percent={handlePercent()} />
        </div>
      )}
      {isLoadingData ? (
        <LoadingTransferPix />
      ) : isLoadingTransferReceipt ? (
        <LoaderReceiptPix />
      ) : transferReceiptRender ? (
        <TransferReceipt />
      ) : manualPix ? (
        RenderTransferManualPix[manualPixSteps[stepIndex]]
      ) : isPixWithSelectedPartner ? (
        RenderTransferPixSelectedPartner[stepIndex]
      ) : (
        RenderTransferPix[sendPixSteps[stepIndex]]
      )}
    </div>
  );
};

export default TransferWithPixModalContent;
