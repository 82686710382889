import { Button, Input } from "@zilliondigital/mirage-ui";
import usePix from "~/hooks/PIX/usePix";
import "bootstrap/dist/css/bootstrap.min.css";
import Description from "~/components/Texts/Description";
import { useFormik } from "formik";
import * as Yup from "yup";
import usePayCopyAndPaste from "~/hooks/PIX/usePayCopyAndPaste";
import ApiService from "~/services/api";
import { motion } from "framer-motion";

type CopyAndPasteFormValues = {
  code: string;
};

const initialValues: CopyAndPasteFormValues = {
  code: "",
};

const FormSchema = Yup.object().shape({
  code: Yup.string().required("Campo obrigatório"),
});

const CopyAndPaste = () => {
  const { setOpenPayCopyAndPasteModal } = usePix();

  const { nextStep, setPixCopyAndPasteData, setIsLoadingData } =
    usePayCopyAndPaste();

  const handleSubmit = (values: CopyAndPasteFormValues, formikHelpers: any) => {
    return;
  };

  const {
    setFieldValue,
    isSubmitting,
    isValid,
    errors,
    touched,
    values,
    handleChange,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,

    onSubmit: handleSubmit,
  });

  const getPixCopyandPasteData = async () => {
    setIsLoadingData(true);

    try {
      const { data: response } = await ApiService.HttpGet({
        route: "pix/qrcode/",
        token: true,
        params: {
          code: values.code,
        },
      });

      if (!response.error) {
        const { data } = response;
        setPixCopyAndPasteData(data);
        nextStep();
        setIsLoadingData(false);
      }
    } catch (error) {
      setIsLoadingData(false);
    }
  };

  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.3 }}
      className="vh-100"
    >
      <div className="p-4 ">
        <Description color="#666666">
          Insira no campo abaixo o código do QR Code que deseja pagar
        </Description>
        <form>
          <Input
            type="textarea"
            name="code"
            placeholder="Cole o código aqui"
            value={values.code}
            error={touched.code && errors.code}
            onChange={handleChange}
          />
          <div className="d-flex justify-content-between ">
            <Button
              type="button"
              mode="border"
              label="Cancelar transferência"
              onClick={() => {
                setOpenPayCopyAndPasteModal(false);
                setFieldValue("code", "");
              }}
            />
            <Button
              type="button"
              mode="main"
              label="Avançar"
              loading={isSubmitting}
              onClick={() => getPixCopyandPasteData()}
              disabled={values.code.length < 20}
            />
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default CopyAndPaste;
