import constate from "constate";
import { useState } from "react";
import ApiService from "~/services/api";
import useToken from "../useToken";
import useTransfers from "./useTransfers";
import useToast from "../useToast";
import useAccount from "../useAccount";
import { format, subDays } from "date-fns";

export type ChooseTransferTypeProps = "ted" | "tev" | null;

const steps = ["data", "send"];

const newTransferSteps = {
  ted: steps,
  tev: steps,
};

const [NewTransferProvider, useNewTransfer] = constate(() => {
  const { setToken } = useToken();

  const { showToast } = useToast();

  const { getTransfers } = useTransfers();

  const { beforeDays, getBalance } = useAccount();

  const [taxTED, setTaxTED] = useState<number>(0);
  const [taxTEV, setTaxTEV] = useState<number>(0);

  const [dataKeyPixSelectedPartner, setDataKeyPixSelectedPartner] =
    useState<any>();
  const [chooseNewTransfer, setChooseNewTransfer] =
    useState<ChooseTransferTypeProps>(null);

  const [selectContact, setSelectContact] = useState<boolean>(false);

  const [isSchedule, setIsSchedule] = useState<boolean>(false);

  const [stepIndex, setStepIndex] = useState<number>(0);

  const [step, setStep] = useState<string>(
    chooseNewTransfer ? newTransferSteps[chooseNewTransfer][stepIndex] : ""
  );
  1;

  const [bankAccountIndex, setBankAccountIndex] = useState<number>(-1);

  const handleTedNextStep = () => {
    setStepIndex((prev) => prev + 1);
    setStep(newTransferSteps["ted"][stepIndex + 1]);
  };

  const handleTedPrevStep = () => {
    setStepIndex((prev) => prev - 1);
    setStep(newTransferSteps["ted"][stepIndex - 1]);
  };

  const [newTransferTEDData, setNewTransferTEDData] = useState<any>({});

  const [newTransferTEVData, setNewTransferTEVData] = useState<any>([]);

  const [amount, setAmount] = useState<number>(0);

  const [openValidatorModalTED, setOpenValidatorModalTED] =
    useState<boolean>(false);

  const [selectedValue, setSelectedValue] = useState("");

  const [changeSideAnimation, setChangeSideAnimation] =
    useState<boolean>(false);

  const [
    isLoadingTransferReceiptTEDorTEV,
    setIsLoadingTransferReceiptTEDorTEV,
  ] = useState<boolean>(false);

  const [transferReceiptTEDRender, setTransferReceiptTEDRender] =
    useState<boolean>(false);

  const [dataTransferTEDReceipt, setDataTransferTEDReceipt] =
    useState<any>(null);

  const [payLoadSendTED, setPayloadSendTED] = useState<object>({});
  const [payLoadSendTEV, setPayloadSendTEV] = useState<object>({});

  const [isTEV, setIsTEV] = useState<boolean>(false);

  const [idContact, setIdContact] = useState<number>(-1);

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  const sendTED = async () => {
    setOpenValidatorModalTED(false);
    setIsLoadingTransferReceiptTEDorTEV(true);
    try {
      const { data: response } = await ApiService.HttpPost({
        route: "charge/transaction-bank/",
        token: true,
        body: payLoadSendTED,
      });

      if (!response.error) {
        const { data } = response;

        setToken("");
        setDataTransferTEDReceipt(data);
        setIsLoadingTransferReceiptTEDorTEV(false);
        setTransferReceiptTEDRender(true);
        showToast(
          "Transferência realizada",
          "Sua transferência foi realizada com sucesso",
          "success"
        );

        setTimeout(() => {
          getTransfers({ from_date: fromDate, to_date: todayFormatted });
          getBalance();
        }, 2000);
      }
    } catch (error: any) {
      console.warn(error);

      setIsLoadingTransferReceiptTEDorTEV(false);

      setTimeout(() => {
        getTransfers({ from_date: fromDate, to_date: todayFormatted });
        getBalance();
      }, 2000);
    }
  };

  const sendTEV = async () => {
    setOpenValidatorModalTED(false);
    setIsLoadingTransferReceiptTEDorTEV(true);
    try {
      const { data: response } = await ApiService.HttpPost({
        route: "charge/transaction-bank-tev/",
        token: true,
        body: payLoadSendTEV,
      });

      if (!response.error) {
        const { data } = response;
        setToken("");
        setDataTransferTEDReceipt(data);
        setIsLoadingTransferReceiptTEDorTEV(false);
        setTransferReceiptTEDRender(true);
        showToast(
          "Transferência realizada",
          "Sua transferência foi realizada com sucesso",
          "success"
        );

        setTimeout(() => {
          getTransfers({ from_date: fromDate, to_date: todayFormatted });
          getBalance();
        }, 2000);
      }
    } catch {
      setIsLoadingTransferReceiptTEDorTEV(false);
      setTimeout(() => {
        getTransfers({ from_date: fromDate, to_date: todayFormatted });
        getBalance();
      }, 2000);
    }
  };

  return {
    stepIndex,
    setStepIndex,
    step,
    setStep,
    chooseNewTransfer,
    setChooseNewTransfer,
    selectContact,
    setSelectContact,
    handleTedNextStep,
    handleTedPrevStep,
    isSchedule,
    setIsSchedule,
    newTransferTEDData,
    setNewTransferTEDData,
    bankAccountIndex,
    setBankAccountIndex,
    amount,
    setAmount,
    openValidatorModalTED,
    setOpenValidatorModalTED,
    selectedValue,
    setSelectedValue,
    changeSideAnimation,
    setChangeSideAnimation,
    sendTED,
    payLoadSendTED,
    setPayloadSendTED,
    payLoadSendTEV,
    setPayloadSendTEV,
    isLoadingTransferReceiptTEDorTEV,
    dataTransferTEDReceipt,
    transferReceiptTEDRender,
    setTransferReceiptTEDRender,
    setDataTransferTEDReceipt,
    setIsLoadingTransferReceiptTEDorTEV,
    isTEV,
    setIsTEV,
    setNewTransferTEVData,
    newTransferTEVData,
    sendTEV,
    idContact,
    setIdContact,
    taxTED,
    taxTEV,
    setTaxTED,
    setTaxTEV,
    dataKeyPixSelectedPartner,
    setDataKeyPixSelectedPartner,
  };
});

export { NewTransferProvider };

export default useNewTransfer;
