import constate from "constate";
import { useState } from "react";
import { PackpageTypes } from "~/types";

const [TariffsProvider, useTariffs] = constate(() => {
  const [openNewTariffsModal, setOpenNewTariffsModal] =
    useState<boolean>(false);
  const [dataCreatePackpageTariffs, setDataCreatePackpageTariffs] = useState<
    any[]
  >([]);
  const [lengthDefaultPF, setLengthDefaultPF] = useState(0);
  const [lengthDefaultPJ, setLengthDefaultPJ] = useState(0);

  const [openAddClient, setOpenAddClient] = useState<boolean>(false);

  const [stepIndexEdit, setStepIndexEdit] = useState<number>(0);

  const [stepIndex, setStepIndex] = useState<number>(0);
  const [stepIndexModal, setStepIndexModal] = useState<number>(0);
  const [newTariff, setNewTariff] = useState<boolean>(false);
  const [clientsData, setClientsData] = useState<any>([]);

  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [rowsPerPageClients, setRowsPerPageClients] = useState<number>(5);
  const [page, setPage] = useState(0);
  const [pageClients, setPageClients] = useState(0);

  const [value, setValue] = useState<string>("");
  const [openEditTariffModal, setOpenEditTariffModal] =
    useState<boolean>(false);

  const [openDeleteHolderModal, setOpenDeleteHolderModal] =
    useState<boolean>(false);

  const [tariffData, setTariffData] = useState<any>([]);
  const [tariffsData, setTariffsData] = useState<any>([]);
  const [packpagesTariffs, setPackpagesTariffs] = useState<any>([]);

  const [editPackpageData, setEditPackpageData] = useState<any[]>([]);
  const [deleteHolderData, setDeleteHolderData] = useState<any>([]);

  const [
    openDeletePersonalizedTariffModal,
    setOpenDeletePersonalizedTariffModal,
  ] = useState<boolean>(false);

  const [openDeletePackpageModal, setOpenDeletePackpageModal] =
    useState<boolean>(false);

  const [deleteTariffModalData, setDeleteTariffModalData] = useState<any>({});
  const [deletePackpageModalData, setDeletePackpageModalData] = useState<{
    name?: string;
    id?: number;
  }>({});

  return {
    openNewTariffsModal,
    setOpenNewTariffsModal,
    setStepIndex,
    stepIndex,
    setNewTariff,
    newTariff,
    clientsData,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    setClientsData,
    value,
    setValue,
    openEditTariffModal,
    setOpenEditTariffModal,
    tariffData,
    setTariffData,
    setTariffsData,
    tariffsData,
    stepIndexModal,
    setStepIndexModal,
    openDeletePersonalizedTariffModal,
    setOpenDeletePersonalizedTariffModal,
    deleteTariffModalData,
    setDeleteTariffModalData,
    pageClients,
    setPageClients,
    rowsPerPageClients,
    setRowsPerPageClients,
    packpagesTariffs,
    setPackpagesTariffs,
    dataCreatePackpageTariffs,
    setDataCreatePackpageTariffs,
    openDeletePackpageModal,
    setOpenDeletePackpageModal,
    deletePackpageModalData,
    setDeletePackpageModalData,
    editPackpageData,
    setEditPackpageData,
    deleteHolderData,
    setDeleteHolderData,
    openDeleteHolderModal,
    setOpenDeleteHolderModal,
    stepIndexEdit,
    setStepIndexEdit,
    openAddClient,
    setOpenAddClient,
    lengthDefaultPF,
    lengthDefaultPJ,
    setLengthDefaultPF,
    setLengthDefaultPJ,
  };
});

export { TariffsProvider };

export default useTariffs;
