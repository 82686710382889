import { useCallback, useEffect, useMemo } from "react";
import { Button, ProgressBar, Spacer } from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment } from "react";
import AppStorage from "~/services/storage";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import useSignupPersonal from "~/hooks/Signup/useSignupPersonal";
import AnaliseIcon from "~/assets/icons/clock.png";
import SteperPersonal from "~/components/Stepers/SteperPersonal";
import {
  Aside,
  Container,
  ContainerData,
  HeaderContainer,
  GeneralContainer,
} from "../Singup.styles";
import HelpImg from "~/assets/icons/buttons/help.svg";
import CheckedIcon from "~/assets/icons/checked.png";
import InputDataIconActive from "~/assets/icons/icon-inputdata-active.png";
import AttachamentsIconInactive from "~/assets/icons/icon-attachments.png";
import AttachamentsIcon from "~/assets/icons/icon-attachments-active.png";
import { SteperItemProps } from "~/components/Stepers/SteperUser";
import label from "~/labels";
import useToast from "~/hooks/useToast";
import ApiService from "~/services/api";
import theme from "~/themes/theme";
import useSignupUser from "~/hooks/Signup/useSignupUser";
import styled from "styled-components";
import useAuth from "~/hooks/useAuth";
import useAccount from "~/hooks/useAccount";

export const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;
  position: relative;
  .profile-picture {
    width: 45px;
    height: 45px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.primary.light100};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1,
    .text {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.primary.main};
      text-transform: uppercase;
    }
  }
`;
const TitlePage = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const SignupPersonalStructure = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToast();
  const { setToken, setUser } = useAuth();
  const {
    step,
    setStep,
    stepIndex,
    stepChildIndex,
    canAdvanced,
    payloadSignupPersonal,
    setNewPartner,
    newPartner,
    setCanAdvanced,
    isLoading,
    setIsLoading,
    addressData,
    updatePartnerStep,
  } = useSignupPersonal();

  const { setOpenHelpModal } = useAccount();

  const registeredToken = useMemo(() => AppStorage.getRegisteredToken(), []);

  const menuSteperPersonal: SteperItemProps[] = [
    {
      title: "Dados Complementares",
      step: "",
      stepIndex: stepIndex,
      icon: InputDataIconActive,
      iconInactive: InputDataIconActive,
      iconCompleted: CheckedIcon,
      children: [
        {
          title: "Geral",
          step: "general",
          stepIndex: stepChildIndex,
        },
        {
          title: "Endereço",
          step: "address",
          stepIndex: stepChildIndex,
        },
      ],
    },

    {
      title: "Anexos & comprovantes",

      step: "attachments",
      stepIndex: stepIndex,
      icon: AttachamentsIcon,
      iconInactive: AttachamentsIconInactive,
      iconCompleted: CheckedIcon,
    },
  ];

  const menuDone: SteperItemProps[] = [
    {
      title: "Cadastro concluído",
      step: "",

      stepIndex: 10,
      icon: InputDataIconActive,
      iconInactive: InputDataIconActive,
      iconCompleted: CheckedIcon,
    },

    {
      title: "Em análise",

      step: "done",
      stepIndex: 0,
      icon: AnaliseIcon,
      iconInactive: AnaliseIcon,
      iconCompleted: CheckedIcon,
    },
  ];

  type StepRoutes = {
    [key: string]: string;
  };

  const stepsRoutes: StepRoutes = {
    address: "/signup/personal/general",
    attachments: "/signup/personal/address",
  };

  const labelStep: { [key: string]: string } = {
    general: "Dados adicionais",
    address: "Endereço",
    attachments: "Documentos",
    done: "Em análise",
  };

  const canRegister = useCallback(() => {
    if (!registeredToken) {
      showToast("Erro", "Você precisa estar logado", "error");
      navigate("/auth/login");
      return;
    }
  }, [navigate]);

  const percentageProgressBar = useMemo(() => {
    const percent = (100 / menuSteperPersonal.length) * stepIndex;

    return percent;
  }, [menuSteperPersonal, stepIndex]);

  const sendPartner = useCallback(async () => {
    const customHeaders = {
      Authorization: `Token ${registeredToken}`,
    };
    setIsLoading(true);

    try {
      const data = await ApiService.HttpPost({
        route: "partner/",
        body: payloadSignupPersonal,
        customHeaders,
      });

      setNewPartner(data.data);
      localStorage.setItem(
        "new_partner",
        JSON.stringify({
          id: data.data.id,
          name: data.data.name,
        })
      );

      await updatePartnerStep("address", registeredToken);
      navigate("/signup/personal/address");
      setStep("address");
    } catch (e: any) {
      console.warn(e);
      showToast("Erro", String(e), "error");
    }
    setIsLoading(false);
  }, [payloadSignupPersonal, navigate, setIsLoading, setNewPartner, setStep]);

  const newPartnerSaved: any = localStorage.getItem("new_partner");

  const newPartnerSavedConverted = useMemo(
    () => JSON.parse(newPartnerSaved),
    [newPartnerSaved]
  );

  const updatePartner = useCallback(async () => {
    const customHeaders = {
      Authorization: `Token ${registeredToken}`,
    };
    setIsLoading(true);

    try {
      const data = await ApiService.HttpPut({
        route: `partner/${newPartnerSavedConverted?.id}/`,
        body: payloadSignupPersonal,
        customHeaders,
      });

      setNewPartner(data.data);
      localStorage.setItem(
        "new_partner",
        JSON.stringify({
          id: data.data.id,
          name: data.data.name,
        })
      );

      await updatePartnerStep("address", registeredToken);
      navigate("/signup/personal/address");
      setStep("address");
    } catch (error) {
      showToast("Erro", String(error), "error");
    }
    setIsLoading(false);
  }, [
    payloadSignupPersonal,
    newPartnerSavedConverted?.id,
    navigate,
    setIsLoading,
    setNewPartner,
    setStep,
  ]);

  const fetchSendAddress = useCallback(async () => {
    const customHeaders = {
      Authorization: `Token ${registeredToken}`,
    };
    setIsLoading(true);

    try {
      const data = await ApiService.HttpPost({
        route: "address/",
        body: payloadSignupPersonal,
        customHeaders,
      });

      await updatePartnerStep("attachments", registeredToken);
      setStep("attachments");
      navigate("/signup/personal/attachments");
      setIsLoading(false);
    } catch (e: any) {
      console.warn(e);
      showToast("Erro", String(e), "error");
    }
    setIsLoading(false);
  }, [payloadSignupPersonal, navigate, setIsLoading, setStep]);

  const fetchUpdateAddress = useCallback(async () => {
    const customHeaders = {
      Authorization: `Token ${registeredToken}`,
    };
    setIsLoading(true);

    try {
      const data = await ApiService.HttpPut({
        route: `address/${newPartner.id ?? newPartnerSavedConverted.id}/`,
        body: payloadSignupPersonal,
        customHeaders,
      });

      await updatePartnerStep("attachments", registeredToken);
      setStep("attachments");
      navigate("/signup/personal/attachments");
      setIsLoading(false);
    } catch (e: any) {
      console.warn(e);
      showToast("Erro", String(e), "error");
    }
    setIsLoading(false);
  }, [
    newPartner.id,
    newPartnerSavedConverted?.id,
    payloadSignupPersonal,
    navigate,
    setIsLoading,
    setStep,
  ]);

  const checkDocumentsAllRight = async () => {
    setIsLoading(true);

    try {
      const customHeaders = {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${registeredToken}`,
      };

      await ApiService.HttpPost({
        route: "partner-sync/",
        customHeaders,
      });

      await ApiService.HttpPost({
        route: "submit-review/",
        customHeaders,
      });

      await updatePartnerStep("review", registeredToken);

      setIsLoading(false);

      setCanAdvanced(false);

      setStep("done");

      navigate("/signup/personal/done");

      showToast(
        "Sucesso!",
        "Documentos finalizados! sua conta entrará em processo de aprovação, lhe informaremos o resultado via e-mail",
        "success"
      );
    } catch (error: any) {
      console.warn(error);
      setIsLoading(false);
      showToast(
        "Erro",
        "Você não enviou todos os documentos ou ocorreu um erro interno",
        "error"
      );
    }
  };

  const logout = useCallback(() => {
    setToken("");
    setUser(undefined);
    AppStorage.clear();
  }, [setToken, setUser]);

  const returnToLogin = useCallback(() => {
    navigate("/auth/login");

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, [navigate]);

  const fetchDict: { [key: string]: () => void } = {
    general: newPartnerSavedConverted
      ? () => {
          updatePartner();
        }
      : () => {
          sendPartner();
        },
    address: addressData?.id
      ? () => {
          fetchUpdateAddress();
        }
      : () => {
          fetchSendAddress();
        },
    attachments: () => {
      checkDocumentsAllRight();
    },
    done: () => {
      logout();
      returnToLogin();
    },
  };

  useEffect(() => {
    canRegister();
  }, []);

  useEffect(() => {
    if (AppStorage.getRegisteredToken()) {
      if (location.pathname === "/signup/personal") {
        navigate("/signup/personal/general");
      }
    }
  }, [location]);

  useEffect(() => {
    if (step === "done") {
      setCanAdvanced(true);
    }
  }, [step]);

  return (
    <GeneralContainer className=" w-100">
      <Container className="d-flex flex-column flex-md-row h-100  ">
        <Aside className="col-md-4 d-flex flex-column ">
          <div className="d-flex  flex-column w-100 align-items-center align-items-md-start ">
            <div className="d-flex justify-content-start w-100 mb-2 mb-md-5">
              {/* <ImgLogo className="mb-2 mb-md-5" src={label.logoSignup} /> */}
            </div>
            <Spacer bottom={50} />
            <div className="d-md-none w-100">
              <ProgressBar percent={percentageProgressBar} />
            </div>

            <Spacer bottom={20} />
            <div>
              <SteperPersonal
                menu={step === "done" ? menuDone : menuSteperPersonal}
              />
            </div>
          </div>
        </Aside>

        <div style={{ backgroundColor: theme.colors.primary.light100 }}>
          <ContainerData className="col-md-8 d-flex flex-column">
            <Outlet />
          </ContainerData>
        </div>
      </Container>

      <HeaderContainer className="col-12 pt-3 pb-3 ">
        <div style={{ width: "500px" }} className="d-flex">
          <Spacer top={20} />

          <TitlePage>{labelStep[step]}</TitlePage>
          <div style={{ position: "relative", top: "-7px" }}>
            <Button
              mode="phantom"
              iconColorFilter={
                label.usePrimaryColor?.includes("all_required_onboard")
                  ? "brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(1021%) hue-rotate(84deg) brightness(113%) contrast(99%)"
                  : " brightness(0) saturate(100%)"
              }
              icon={HelpImg}
              onClick={() => setOpenHelpModal(true)}
            />
          </div>
        </div>
        <Spacer left={155} />

        {step !== "security" && (
          <Fragment>
            <Spacer bottom={15} />
            <div className="d-flex justify-content-md-end justify-content-center w-100 ">
              <Link
                style={{ textDecoration: "none" }}
                to={step === "intro" ? "/signup/user" : ""}
              ></Link>

              <Spacer left={10} />
              {step !== "general" && step !== "done" && (
                <Button
                  onClick={() => {
                    navigate(stepsRoutes[step]);
                  }}
                  mode="border"
                  label={"Retornar"}
                />
              )}
              <Spacer left={10} />
              <Button
                onClick={() => {
                  fetchDict[step]();
                }}
                arrow={step !== "done" ? "right" : "left"}
                mode="main"
                label={step !== "done" ? "Seguir" : "Retornar ao login"}
                loading={isLoading}
                disabled={!canAdvanced}
              />
            </div>
          </Fragment>
        )}
      </HeaderContainer>
    </GeneralContainer>
  );
};

export default SignupPersonalStructure;
