import "bootstrap/dist/css/bootstrap.min.css";
import { Breadcrumb, Button, Spacer } from "@zilliondigital/mirage-ui";
import { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import Skeleton from "~/components/SkeletonBalances";
import {
  ParentContainer,
  ContainerData,
  GeneralContainer,
} from "~/pages/App/app-content.styles";
import styled from "styled-components";
import theme from "~/themes/theme";
import Copyright from "~/components/Copyright";
import Paginator from "~/components/Paginator";
import SubMenu from "~/components/SubMenu";
import ApiService from "~/services/api";
import useTariffs from "~/hooks/useTariffs";
import SplitList from "./SplitList";
import useSplit from "~/hooks/useSplit";
import NewSplitModal from "./NewSplit/NewSplitModal";
import ConfirmDeleteSplitModal from "./NewSplit/ConfirmDeleteSplitModal";
import useMySpace from "~/hooks/useMySpace";

const Card = styled.div`
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
  box-shadow: 0px 3px 1px 0px rgb(0 0 0 / 3%);
  padding: 10px;

  .empty-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem 1.5rem 1.5rem;
    flex-direction: column;

    span {
      text-align: center;
      display: block;
      margin: 15px 0;
    }

    img {
      filter: ${theme.colors.primary.mainFilter};
    }

    button {
      width: 100%;
      background: white;

      img {
        width: 15px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;

    .list {
      padding-left: 0px;
      width: 100%;

      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .item-box {
          padding: 10px 1.5rem;

          &:hover {
            background: rgba(0, 0, 0, 0.03);
          }
        }

        .desc {
          margin-bottom: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .footer-box {
    display: flex;
    padding: 15px 1.5rem;
    justify-content: flex-end;

    button {
      background: white;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
`;

const SplitStructure = () => {
  const {
    setOpenNewSplitModal,
    setIsEditing,
    page,
    setPage,
    value,
    rowsPerPage,
    splitsData,
  } = useSplit();

  useEffect(() => {
    setIsEditing(false);
  }, []);

  return (
    <>
      <div className="col-md-12">
        <ContainerData className="p-4">
          {" "}
          <div className="pe-2 flex-grow-1 d-flex justify-content-between">
            <Title>Defina os padrões de split</Title>
            <Button
              mode="main"
              label="Criar Arranjo"
              onClick={() => setOpenNewSplitModal(true)}
            />
          </div>
          <SplitList />
        </ContainerData>

        <div className={splitsData.length > 0 ? "" : "d-none"}>
          {value !== "" && (
            <Paginator
              list={splitsData}
              rowsPerPage={rowsPerPage}
              page={page}
              pageSetter={setPage}
            />
          )}
          <Spacer top={splitsData.length > 0 ? 2 : 40} />
        </div>
      </div>

      <NewSplitModal />
      <ConfirmDeleteSplitModal />
    </>
  );
};

export default SplitStructure;
