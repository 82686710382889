import {
  Button,
  CustomSelect,
  Input,
  LateralModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useEffect, useState } from "react";
import Description from "~/components/Texts/Description";
import { pixMyLimits } from "~/data/pix";
import usePix from "~/hooks/PIX/usePix";
import styled from "styled-components";
import EditIcon from "~/assets/icons/round_edit_black_24dp.png";
import useToast from "~/hooks/useToast";

const ContainerLabelMaxValue = styled.div<MyLimitsModalProps>`
  opacity: ${({ editing }) => editing && "0"};
  position: relative;
  left: -5%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;

  p {
    position: relative;
    left: 40%;
  }

  .content-select {
    display: flex;
    align-items: start;
    justify-content: end;
    gap: 10px;
    position: relative;
    left: 20%;
    top: 30px;
  }

  .edit-image {
    height: 24px;
    filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(0%)
      hue-rotate(135deg) brightness(103%) contrast(105%);
  }

  tr {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const GeneralContainer = styled.div`
  tr {
    border-bottom: 1px solid #e0e0e0;
  }

  td {
    padding: 20px 0px;
  }

  position: absolute;
`;

const ContainerEditValues = styled.div`
  position: absolute;
  left: 20%;
  top: -40%;
`;

const PencilIcon = styled.img`
  cursor: pointer;
`;

type MyLimitsModalProps = {
  editing?: boolean;
};

const MyLimitsModal = (props: MyLimitsModalProps) => {
  const { openMyLimitsModal, setOpenMyLimitsModal } = usePix();

  const [editField, setEditField] = useState<string>("");

  const { showToast } = useToast();

  const [dataPixMyLimits, setDataPixMyLimits] = useState(
    pixMyLimits.map((item: any, index: number) => ({
      type: item.type,
      description: item.description,
      diurnalLimit: item.diurnalLimit,
      noturnalLimit: item.noturnalLimit,
      noturnalTime: item.noturnalTime,
      index: index,
    }))
  );

  const handleEdit = (field: string) => {
    setEditField(field);
  };

  const handleKeyPress = (event: any, field: string) => {
    if (event.key === "Enter") {
      setEditField(field);
    }
  };

  const handleSave = (field: string) => {
    setEditField(field);
    showToast(
      "Limite alterado com sucesso",
      "O limite de transação noturna foi alterada",
      "success"
    );
  };

  useEffect(() => {
    if (!openMyLimitsModal) {
      handleEdit("");
    }
  }, [openMyLimitsModal]);

  const RenderEditDiurnalLimits = () => {
    return (
      <ContainerEditValues>
        <ContainerLabelMaxValue className="d-flex align-items-center">
          <div className="w-50">
            <Input
              type="text"
              value={dataPixMyLimits[0].diurnalLimit}
              placeholder="Valor máximo desejado"
              onChange={(e) =>
                setDataPixMyLimits((prevData) => {
                  const newData = [...prevData];
                  newData[0].diurnalLimit = e.target.value;

                  setDataPixMyLimits(newData);

                  return newData;
                })
              }
              onKeyDown={(e) => handleKeyPress(e, "noturnalLimit")}
            />
          </div>
          <div className="pt-2 ps-2">
            <Button
              mode="main"
              label="Salvar"
              onClick={() => {
                handleSave("");
              }}
            />
          </div>
        </ContainerLabelMaxValue>
      </ContainerEditValues>
    );
  };

  const RenderEditNoturnalLimits = () => {
    return (
      <ContainerEditValues>
        <ContainerLabelMaxValue className="d-flex align-items-center">
          <div className="w-50">
            <Input
              type="text"
              value={dataPixMyLimits[0].diurnalLimit}
              placeholder="Valor máximo desejado"
              onChange={(e) =>
                setDataPixMyLimits((prevData) => {
                  const newData = [...prevData];
                  newData[0].diurnalLimit = e.target.value;

                  setDataPixMyLimits(newData);

                  return newData;
                })
              }
              onKeyDown={(e) => handleKeyPress(e, "noturnalLimit")}
            />
          </div>
          <div className="pt-2 ps-2">
            <Button
              mode="main"
              label="Salvar"
              onClick={() => {
                handleSave("");
              }}
            />
          </div>
        </ContainerLabelMaxValue>
      </ContainerEditValues>
    );
  };

  const RenderEditNoturnalTime = () => {
    return (
      <ContainerEditValues>
        <ContainerLabelMaxValue>
          <div className="content-select">
            <div>
              <Spacer top={5} />
              <CustomSelect
                options={[
                  {
                    label: "21h - 05:59",
                  },
                  {
                    value: "22h - 05:59",
                    label: "22h - 05:59",
                  },
                  {
                    value: "23h - 05:59",
                    label: "23h - 05:59",
                  },
                ]}
              />
            </div>
            <Spacer right={8} />
            <div className="pt-2 pe-5">
              <Button
                mode="main"
                label="Salvar"
                onClick={() => {
                  handleSave("");
                }}
              />
            </div>
          </div>
        </ContainerLabelMaxValue>
      </ContainerEditValues>
    );
  };

  return (
    <LateralModal
      style={{ zIndex: "3" }}
      header
      title="Meus limites"
      open={openMyLimitsModal}
      toggleModal={() => setOpenMyLimitsModal(!openMyLimitsModal)}
    >
      <GeneralContainer {...props}>
        <div className="p-4">
          <Description color="#666666">
            Os limites de transação são quantias máximas que podem ser gastas ou
            transferidas por Pix, em um determinado período de tempo. São
            estabelecidos com o objetivo de proteger a conta de fraudes e erros.
          </Description>
          <Spacer bottom={30} />
        </div>
        <table className=" w-100">
          <tbody>
            <tr>
              <td className="ps-3">
                <Description>Diurno</Description>
              </td>

              <td className="second-column position-relative ">
                <ContainerLabelMaxValue
                  className="d-flex align-items-center   "
                  editing={editField === "diurnalLimit"}
                >
                  <Description className="max-value">
                    R$ {dataPixMyLimits[0].diurnalLimit}
                  </Description>
                  <PencilIcon
                    className="edit-image"
                    src={EditIcon}
                    alt="icon"
                    onClick={() => handleEdit("diurnalLimit")}
                  />
                </ContainerLabelMaxValue>

                {editField === "diurnalLimit" && <RenderEditDiurnalLimits />}
              </td>
            </tr>
            <tr>
              <td className="ps-3">
                <Description> Noturno</Description>
              </td>

              <td className="second-column position-relative">
                <ContainerLabelMaxValue
                  className="d-flex align-items-center   "
                  editing={editField === "noturnalLimit"}
                >
                  <Description className="max-value">
                    R$ {dataPixMyLimits[0].noturnalLimit}
                  </Description>
                  <PencilIcon
                    className="edit-image"
                    src={EditIcon}
                    alt="icon"
                    onClick={() => handleEdit("noturnalLimit")}
                  />
                </ContainerLabelMaxValue>

                {editField === "noturnalLimit" && <RenderEditNoturnalLimits />}
              </td>
            </tr>
            <tr>
              <td className="ps-3">
                <Description>Horário noturno</Description>
              </td>

              <td className="second-column position-relative">
                <div>
                  <ContainerLabelMaxValue
                    className="d-flex align-items-center   "
                    editing={editField === "noturnalTime"}
                  >
                    <Description>
                      R$ {dataPixMyLimits[0].noturnalTime}
                    </Description>
                    <PencilIcon
                      className="edit-image"
                      src={EditIcon}
                      alt="icon"
                      onClick={() => handleEdit("noturnalTime")}
                    />
                  </ContainerLabelMaxValue>

                  {editField === "noturnalTime" && <RenderEditNoturnalTime />}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </GeneralContainer>
    </LateralModal>
  );
};

export default MyLimitsModal;
