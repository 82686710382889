import { Button, Spacer } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import { ContainerDetails } from "../../app-content.styles";
import DownloadIcon from "~/assets/icons/buttons/download.png";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import useAccount from "~/hooks/useAccount";
import { DownloadReceipt, formatDateTime } from "~/utils";

const TransferReceipt = () => {
  const { dataTransferPixReceipt } = useTransferWithPix();
  const { formatNumberToCurrency } = useAccount();

  const downloadPDF = (url: string, filename: string) => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FileName.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
      });
  };
  const handleDownload = () => {
    const pdfUrl = dataTransferPixReceipt?.voucher_url;
    const filename = "comprovante.pdf";
    downloadPDF(pdfUrl, filename);
  };

  return (
    <ContainerDetails>
      <table className=" w-100">
        <tbody>
          <tr>
            <td>
              <Description color="#666666">Valor</Description>
            </td>

            <td className="second-column">
              <Description>
                {formatNumberToCurrency(dataTransferPixReceipt?.amount)}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666"> Tipo de Transferência</Description>
            </td>

            <td className="second-column">
              <Description>PIX</Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Data do pagamento</Description>
            </td>

            <td className="second-column">
              <Description>
                {formatDateTime(dataTransferPixReceipt?.created_date)}
              </Description>
            </td>
          </tr>
          {dataTransferPixReceipt?.description && (
            <tr>
              <td>
                <Description color="#666666">Descrição</Description>
              </td>

              <td className="second-column">
                <Description>{dataTransferPixReceipt?.description}</Description>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Spacer bottom={20} />

      <table className=" w-100">
        <thead>
          <th>Origem</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <Description color="#666666">Nome</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.sender_account.owner.name}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Instituição</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.sender_account.bank_data.name}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Agência </Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.sender_account.agency}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Conta</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.sender_account.account_number +
                  "-" +
                  dataTransferPixReceipt?.sender_account.account_number_digit}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Tipo de conta</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.sender_account.type}
              </Description>
            </td>
          </tr>
        </tbody>
      </table>
      <Spacer bottom={20} />

      <table className=" w-100">
        <thead>
          <th>Destino</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <Description color="#666666">Nome</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.receiver_account.owner.name}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Instituição</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.receiver_account.bank_data.name}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Agência</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.receiver_account.agency}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Conta</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.receiver_account.account_number +
                  "-" +
                  dataTransferPixReceipt?.receiver_account.account_number_digit}
              </Description>
            </td>
          </tr>

          <tr>
            <td>
              <Description color="#666666">Tipo de conta</Description>
            </td>

            <td className="second-column">
              <Description>
                {dataTransferPixReceipt?.receiver_account.type}
              </Description>
            </td>
          </tr>
        </tbody>
      </table>

      <Spacer bottom={50} />

      <div className="d-flex justify-content-end">
        <Button
          mode="border"
          icon={DownloadIcon}
          label="Fazer download"
          onClick={() =>
            window.open(dataTransferPixReceipt?.voucher_url, "_blank")
          }
        />
      </div>
    </ContainerDetails>
  );
};

export default TransferReceipt;
