import { LateralModal, ProgressBar } from "@zilliondigital/mirage-ui";
import usePix from "~/hooks/PIX/usePix";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useEffect } from "react";
import CopyAndPaste from "./CopyAndPaste";
import SendPix from "./SendPix";
import usePayCopyAndPaste from "~/hooks/PIX/usePayCopyAndPaste";
import LoaderPixCopyAndPaste from "./Loader";
import useToken from "~/hooks/useToken";
import TransferReceipt from "./TransferReceipt";
import LoaderReceiptPix from "../LoaderReceiptPix";

const PayCopyAndPaste = () => {
  const { openPayCopyAndPasteModal, setOpenPayCopyAndPasteModal } = usePix();

  const {
    stepIndex,
    steps,
    isLoadingData,
    setStepIndex,
    setPixCopyAndPasteData,
    isLoadingTransferReceipt,
    transferReceiptRender,
    setTransferReceiptRender,
    setDataTransferPixReceipt,
  } = usePayCopyAndPaste();

  const { setToken } = useToken();

  const renderStep = [<CopyAndPaste />, <SendPix />];

  useEffect(() => {
    if (!openPayCopyAndPasteModal) {
      setStepIndex(0);
      setPixCopyAndPasteData({});
      setToken("");
      setTransferReceiptRender(false);
      setDataTransferPixReceipt(null);
    }
  }, [openPayCopyAndPasteModal]);

  return (
    <Fragment>
      <LateralModal
        style={{ zIndex: "3" }}
        header
        title={
          isLoadingTransferReceipt
            ? "Enviando pagamento..."
            : transferReceiptRender
            ? "Pagamento Efetuado"
            : "Copia e cola"
        }
        open={openPayCopyAndPasteModal}
        toggleModal={() =>
          setOpenPayCopyAndPasteModal(!openPayCopyAndPasteModal)
        }
      >
        <Fragment>
          <div>
            <ProgressBar percent={((stepIndex + 1) * 100) / steps?.length} />
          </div>

          {isLoadingData ? (
            <LoaderPixCopyAndPaste />
          ) : isLoadingTransferReceipt ? (
            <LoaderReceiptPix />
          ) : transferReceiptRender ? (
            <TransferReceipt />
          ) : (
            renderStep[stepIndex]
          )}
        </Fragment>
      </LateralModal>
    </Fragment>
  );
};

export default PayCopyAndPaste;
