import "bootstrap/dist/css/bootstrap.min.css";
import { SideBar, Spacer } from "@zilliondigital/mirage-ui";
import LogoImage from "~/assets/images/logo-cm-capital.png";
import DashboardIcon from "~/assets/icons/sidebar/ic_navigation_dashboard.png";
import StatementsIcon from "~/assets/icons/sidebar/ic_navigation_receipt.png";
import PIXIcon from "~/assets/icons/sidebar/ic_navigation_pix.png";
import PaymentsIcon from "~/assets/icons/sidebar/ic_navigation_billet.png";
import CollectionsIcon from "~/assets/icons/sidebar/ic_navigation_payment.png";
import TransfersIcon from "~/assets/icons/sidebar/ic_navigation_transfer.png";
import ContactsIcon from "~/assets/icons/sidebar/ic_navigation_contacts.png";
import ExitIcon from "~/assets/icons/sidebar/ic_navigation_exit.png";
import PermissionIcon from "~/assets/icons/sidebar/permissions.png";
import { Outlet, Route, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";

import AppHeader from "../AppHeader/AppHeader";
import { Container } from "./AppLayout.styles";
import useAccount from "~/hooks/useAccount";
import AppStorage from "~/services/storage";

import ValidatorsModals from "~/components/ModalsValidators/ValidatorsModals";
import ApiService from "~/services/api";
import useAuth from "~/hooks/useAuth";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import HelperModal from "../AppHeader/HelperModal";
import label from "~/labels";

export type MenuItem = {
  icon: string;
  text: string;
  onMenuClick?: () => void;
};

type Route = {
  path: string;
  component?: JSX.Element;
};

export type AppLayoutProps = {
  searchOpen?: boolean;
};

const AppLayout = (props: AppLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const {
    getBalance,
    setMainAccount,
    getProjections,
    getUserPartners,
    mainAccount,
    verifyPermission,
  } = useAccount();

  const { user, setCanDoLogin } = useAuth();

  const menuItems: any[] = [
    {
      icon: DashboardIcon,
      text: "Painel",
      route: "/backoffice/pending",
    },
    {
      icon: ContactsIcon,
      text: "Aprovação",
      route: "/backoffice/pending",
    },
  ];

  const mutationCallback = useCallback(
    (mutationsList: any[], observer: any) => {
      const mutation = mutationsList[0];
      if (
        mutation.type === "attributes" &&
        mutation.target.dataset?.lateralStatus
      ) {
        const opened = mutation.target.dataset?.lateralStatus === "true";
        const body = document.querySelector("body");

        if (opened) {
          body?.classList.add("remove-scrollbar");
        } else {
          body?.classList.remove("remove-scrollbar");
        }
      }
    },
    []
  );

  useEffect(() => {
    const observer = new MutationObserver(mutationCallback);
    const elements: any = document.querySelectorAll("div[data-lateral-status]");

    const observerOptions = {
      attributes: true,
    };

    if (elements) {
      elements.forEach((_el: any) => {
        observer.observe(_el, observerOptions);
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [location]);

  const topMenus: MenuItem[] = useMemo(() => {
    const filteredMenus = menuItems.filter((item) => {
      if (!item.permission) {
        return true;
      } else {
        return verifyPermission(item.permission);
      }
    });

    const mappedMenus = filteredMenus.map((menu) => ({
      icon: menu.icon,
      text: menu.text,
      onMenuClick: () => navigate(menu.route),
    }));

    return mappedMenus;
  }, [mainAccount, menuItems]);

  const bottomMenus: MenuItem[] = [
    {
      icon: ExitIcon,
      text: "Sair",
      onMenuClick: () => {
        setCanDoLogin(false);
        localStorage.removeItem("token");
        AppStorage.remove("@app:user");
        AppStorage.remove("@app:token");
        window.location.reload();
      },
    },
  ];

  return (
    <Container {...props} searchOpen={isSearching}>
      {/* {!isDashboardOrPIXLocation && !isLoading && (
        <div className="c-wrapper-search ">
          <div
            onClick={() => setIsSearching(true)}
            onBlur={() => setIsSearching(false)}
          >
            <SearchBar placeholder="Buscar..." />
          </div>
        </div>
      )} */}
      <div style={{ zIndex: "3" }}>
        {" "}
        <SideBar
          menu={topMenus}
          footer={bottomMenus}
          logo={label.logoMenu ?? label.logoHorizontal}
          logoWidth={label.logoMenuWidth}
        />
      </div>

      <Spacer left={88} />
      <AppHeader isSearching={isSearching} />
      <div className="wrapper-container">
        <Outlet />
      </div>
      <ValidatorsModals />
    </Container>
  );
};

export default AppLayout;
