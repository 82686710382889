import { AppLabel } from "~/types";
import defaultLabel from "../default/default";

import logo from "./logo.png";
import logoMenu from "./logo_menu.png";
import logoBlack from "./logo.png";
import backgroundLogin from "./3180663.jpg";

const year = new Date().getFullYear();

const label: AppLabel = {
  ...defaultLabel,
  name: "Zillion",
  description: "Bank",
  prefix: "zillion",
  footerText: `Zillion - Todos os direitos reservados ${year}`,

  logoText: "Zillion",
  helpEmail: "suporte@zillionbank.com.br",
  helpPhone: "(11) 9999-9999",
  sac: "0800 000 0000",
  helpRamal: "",
  helpSupportTime:
    "Consultas, informações, reclamações e transações, de segunda a sexta, das 08h às 17h.",
  logoMenu: logoMenu,
  logo: logoBlack,
  logoHorizontal: logoBlack,
  logoMenuWidth: "80px",
  backgroundLoginImage: backgroundLogin,
  logoSignup: logoBlack,
  clarity: "jy7gacqy1q",
  backgroundPositionX: "center",

  devBankCardAPI: "https://dev.api.card.zillion.digital",
  devAPI: "https://api.prosperita.ibanks.app.br",
  stageAPI: "https://stage.api.bank.zillion.digital",
  productionAPI: "https://api.prosperita.ibanks.app.br",
};

export default label;
