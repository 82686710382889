import "bootstrap/dist/css/bootstrap.min.css";
import {
  ButtonMoreDetails,
  Spacer,
  Table,
  NotControlledToolTip,
} from "@zilliondigital/mirage-ui";
import PIXIcon from "~/assets/icons/Type/ic_context_payment_pix.png";
import PaidIcon from "~/assets/icons/status/ic_functional_status_success.png";

import styled, { keyframes } from "styled-components";
import { Fragment, useState } from "react";
import EyeIcon from "~/assets/icons/eye-Size=MD.svg";
import WhatsIcon from "~/assets/icons/whats.png";
import SuccessIcon from "~/assets/icons/status/done_black_24dp (1).svg";
import ScheduleIcon from "~/assets/icons/status/ic_functional_status_attention.png";
import useTransfers from "~/hooks/Transfers/useTransfers";
import TedIcon from "~/assets/icons/status/ted.png";
import TableLoader from "~/components/SkeletonTables";
import StatementsEmpty from "../Statements/StatementsEmpty";
import { dateFormatterBR } from "~/utils";
import BilletIcon from "~/assets/icons/Type/billet.png";
import ErrorIcon from "~/assets/icons/status/error.png";
import PendingIcon from "~/assets/icons/status/Elipse 54.png";
import { applyPagination } from "~/components/Paginator";
import { operationStatus } from "~/data/options";
import theme from "~/themes/theme";
import Badge from "~/components/Badge";

const getStatus: { [key: string]: JSX.Element } = {
  waiting_approval: (
    <Badge
      label="Em aprovação"
      color={theme.colors.base.light200}
      textColor={theme.colors.base.main}
    />
  ),
  pending: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  processing: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  done: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  paid: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  schedule: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  scheduled: (
    <Badge
      label="Pago"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  canceled: (
    <Badge
      label="Cancelado"
      color={theme.colors.error.light200}
      textColor={theme.colors.error.main}
    />
  ),
  refused: (
    <Badge
      label="Reprovado"
      color={theme.colors.error.light200}
      textColor={theme.colors.error.main}
    />
  ),
  error: (
    <div
      style={{
        width: "45px",
        height: "23,4px",
        display: "flex",

        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img style={{ height: "23,4px" }} src={ErrorIcon} />{" "}
    </div>
  ),
  approved: (
    <img
      style={{ width: "45px", height: "23,4px" }}
      src={PaidIcon}
      className="d-block m-auto"
    />
  ),
};

const IdentificationData = styled.div`
  display: flex;

  .button {
    opacity: 0;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
    /* Cor que deseja quando a imagem estiver completamente expandida */
  }
`;

const ImgEye = styled.img`
  height: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;
  filter: ${() => theme.colors.gray.dark500Filter};

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.info.mainFilter};
  }
`;

const StatusData = styled.div`
  .button {
    opacity: 0;
  }

  &:hover > .button {
    .button {
      opacity: 1;
    }
  }
`;

const TransfersTransactions = () => {
  const {
    setOpenTransfersDetailsModal,
    transfersData,
    isLoading,

    rowsPerPage,
    page,
    setCurrentModalData,
    setOpenDetailModal,
  } = useTransfers();

  const [isTooltipErrorVisible, setIsToolTipErrorVisible] = useState<boolean[]>(
    new Array(transfersData.length).fill(false)
  );

  const openDetail = (data: any) => {
    setCurrentModalData(data);
    setOpenDetailModal(true);
  };

  const [buttonMoreOptions] = useState<any>([
    {
      label: "Detalhes",
      onClick: () => {
        setOpenTransfersDetailsModal(true);
      },
      icon: EyeIcon,
    },

    // {
    //   label: "Compartilhar no whatsApp",
    //   onClick: () => {
    //     const message = "Olá! Estou compartilhando essa mensagem contigo.";
    //     const shareUrl = `https://wa.me?text=${encodeURIComponent(message)}`;

    //     window.open(shareUrl);
    //   },
    //   icon: WhatsIcon,
    // },
  ]);

  const columns: any = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => (
        <StatusData className="d-flex  justify-content-start align-items-center">
          {/* <Checkbox /> */}
          {/*   <Spacer left={20} /> */}
          {data.label}
        </StatusData>
      ),

      renderData: (data: any) => (
        <div className="d-flex justify-content-start align-items-center">
          <div id={`status-${data.id}`}> {getStatus[data.status]}</div>
          <NotControlledToolTip placement="top" target={`status-${data.id}`}>
            {data.status === "pending" ? "Pago" : operationStatus[data.status]}
          </NotControlledToolTip>
        </div>
      ),
    },

    {
      label: "Tipo",
      key: "type",
      renderData: (data: any) => (
        <div>
          {data.type === "billet_payment"
            ? "Boleto"
            : data.type === "tax"
            ? "Tarifa"
            : data.type.toUpperCase()}
        </div>
      ),
    },
    {
      label: "Contato",
      key: "contact",
      renderData: (data: any) => (
        <div style={{ maxWidth: "300px" }}>{data.contact}</div>
      ),
    },
    {
      label: "Valor",
      key: "amount",

      renderData: (data: any) => (
        <div
          className="d-flex justify-content-end"
          style={{
            direction: "rtl",
          }}
        >
          R$ {data.amount}
        </div>
      ),
    },
    {
      label: "Descrição",
      key: "description",

      renderData: (data: any) => (
        <span
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "140px",
            overflow: "hidden",
          }}
        >
          {data.description === "" ? "Sem descrição" : data.description}
        </span>
      ),
    },
    // {
    //   label: "Mensagem Ret.",
    //   key: "return_error_message",

    //   renderData: (data: any) => (
    //     <span
    //       style={{
    //         whiteSpace: "nowrap",
    //         textOverflow: "ellipsis",
    //         maxWidth: "140px",
    //         overflow: "hidden",
    //       }}
    //     >
    //       {!data.return_error_message ? (
    //         <div className="">-</div>
    //       ) : (
    //         data.return_error_message
    //       )}
    //     </span>
    //   ),
    // },
    {
      label: "Data",
      key: "date",
      renderData: (data: any) => dateFormatterBR(data.date),
    },
    {
      label: "Identificação",
      key: "identification",
      renderData: (data: any) => (
        <>
          <IdentificationData className="d-flex justify-content-between align-items-center  pe-5">
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "100px",
                overflow: "hidden",
              }}
            >
              {data.identification}
            </span>

            <div className="ps-3  ">
              <div
                className="d-flex justify-content-start align-items-center"
                id={`details-${data.id}`}
              >
                {/* <Spacer left={10} /> */}
                {/* <Checkbox /> */}
                {/*  <Spacer left={15} /> */}

                {/* <Checkbox /> */}
                <ImgEye
                  id={`details-${data.id}`}
                  src={EyeIcon}
                  onClick={() => {
                    openDetail(data);
                  }}
                />
                <NotControlledToolTip
                  placement="top"
                  target={`details-${data.id}`}
                >
                  {"Visualizar detalhes"}
                </NotControlledToolTip>
              </div>
            </div>
          </IdentificationData>
        </>
      ),
    },
  ];

  const data = transfersData.map((transaction, index) => ({
    ...transaction,
    amount: transaction.amount,
    description: transaction.description ?? "Sem Descrição",
    contact: transaction.full_recipient.name,
    type: transaction.category,
    identification: transaction.id,
    status: transaction.status,
    date: transaction.created_date,
    id: transaction.id,
    return_error_message: transaction.return_error_message,
  }));

  return (
    <Fragment>
      <Spacer top={20} />
      {isLoading ? (
        <TableLoader />
      ) : (
        transfersData.length === 0 && <StatementsEmpty />
      )}
      {transfersData.length > 0 && (
        <Table
          data={applyPagination(data, { page, rowsPerPage })}
          columns={columns}
          dataSpacer={8}
        />
      )}
    </Fragment>
  );
};

export default TransfersTransactions;
