
import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";

const LoaderContactsList = (props: any) => {
  const { rows = 6 } = props;
  const rowHeight = 150;
  const totalRows = rows + 2;

  const totalHeight = rowHeight * totalRows;

  return (
    <div className="ps-2 pt-4">
      <ContentLoader
        viewBox={`0 0 1500 ${totalHeight}`}
        backgroundColor="#d9d9d9"
        foregroundColor="#ededed"
        {...props}
      >
        {new Array(totalRows).fill(" ").map((el, index) => {
          const contentVerticalPosition = (contentHeight: number) =>
            totalRows > 1
              ? contentHeight + rowHeight * index + index * 45
              : contentHeight;
          return (
            <Fragment key={index}>
              <circle cx="100" cy={`${contentVerticalPosition(100)}`} r="65" />

              <rect
                x="200"
                y={`${contentVerticalPosition(45)}`}
                rx="10"
                ry="4"
                width="600"
                height="30"
              />
              <rect
                x="200"
                y={`${contentVerticalPosition(100)}`}
                rx="10"
                ry="4"
                width="400"
                height="15"
              />
            </Fragment>
          );
        })}
      </ContentLoader>
    </div>
  );
};

export default LoaderContactsList;
