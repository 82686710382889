import constate from "constate";
import { useState } from "react";

const [BackofficeProvider, useProvider] = constate(() => {
  const [pendings, setPendings] = useState<Record<string, any[]>>({});

  const [pendingsMeta, setPendingsMeta] = useState<Record<string, any>>({});
  const [pendingsLoading, setPendingsLoading] = useState(false);
  const [pending, setPending] = useState({});
  const [pendingIndex, setPendingIndex] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const [pendingDocuments, setPendingDocuments] = useState([]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [isDetails, setIsDetails] = useState(false);
  const [detailsData, setDetailsData] = useState<any>({});

  const [section, setSection] = useState("initial");

  const [dataRender, setDataRender] = useState("completed");

  const [openRepresentativeDetailsModal, setOpenRepresentativeDetailsModal] =
    useState<boolean>(false);

  const [detailsRepresentatives, setDetailsRepresentatives] = useState<any>([]);

  const [renderFilter, setRenderFilter] = useState<any>([]);

  return {
    pendings,
    setPendings,

    pendingsMeta,
    setPendingsMeta,

    pendingsLoading,
    setPendingsLoading,

    pending,
    setPending,

    pendingIndex,
    setPendingIndex,

    isEdit,
    setIsEdit,

    pendingDocuments,
    setPendingDocuments,

    page,
    setPage,

    rowsPerPage,
    setRowsPerPage,
    renderFilter,
    setRenderFilter,

    isDetails,
    setIsDetails,

    section,
    setSection,

    dataRender,
    setDataRender,

    detailsData,
    setDetailsData,
    openRepresentativeDetailsModal,
    setOpenRepresentativeDetailsModal,

    setDetailsRepresentatives,
    detailsRepresentatives,
  };
});

export { BackofficeProvider };

export default useProvider;
