import { AppLabel } from "~/types";

import AppStorage from "~/services/storage";
import zillion from "./zillion/zillion";
import engraxapay from "./engraxapay/engraxapay";

type AppLabels = {
  [key: string]: AppLabel;
};

const labels: AppLabels = {
  default: engraxapay,
  zillion: zillion,
  engraxapay: engraxapay,
};

const activeTheme = AppStorage.getTheme();

const label = activeTheme ? labels[activeTheme] : labels.default;

if (label === undefined) {
  throw new Error("Active theme is not defined");
}

export default label;
