import {
  Button,
  InputToken,
  InteractiveModal,
  Spacer,
} from "@zilliondigital/mirage-ui";

import useToken from "~/hooks/useToken";
import { useEffect, useState } from "react";
import Description from "../Texts/Description";
import TitleModal from "../Texts/TitleModal";

type Props = {
  onTokenConfirm?: (token: string) => void;
  open: boolean;
  setterToggle?: () => void;
};

const ValidatorTokenModal = ({ onTokenConfirm, open, setterToggle }: Props) => {
  const [validationToken, setValidationToken] = useState<string>("");
  const { isLoadingButton } = useToken();
  const { setIsLoadingButton } = useToken();

  const {
    buttonDisabled,

    setToken,
    token,
    setButtonDisabled,
    numberOfInputs,
  } = useToken();

  const [buttonLabel, setButtonLabel] = useState(
    `Faltam ${numberOfInputs} digitos`
  );

  const handleConfirmToken = (values?: any) => {
    if (onTokenConfirm && token === validationToken)
      onTokenConfirm(validationToken);
    setValidationToken("");
  };

  useEffect(() => {
    setToken(validationToken);
  }, [validationToken]);

  useEffect(() => {
    if (token === "") {
      setButtonDisabled(true);
      setButtonLabel(`Faltam ${numberOfInputs} digitos`);
    }
  }, [token]);

  const handleInput = (values: string) => {
    setValidationToken(values);

    if (values.length === numberOfInputs && token.length > 0) {
      setButtonDisabled(false);
      setButtonLabel("Validar token de segurança");
    } else {
      setButtonDisabled(true);

      const oneDigit = numberOfInputs - values.length === 1;
      if (oneDigit) {
        setButtonLabel(`Falta 1 digito`);
      }

      !oneDigit &&
        setButtonLabel(`Faltam ${numberOfInputs - values.length} digitos`);
    }
  };

  useEffect(() => {
    if (buttonDisabled) {
      setIsLoadingButton(false);
    }
  }, [buttonDisabled]);

  return (
    <div>
      <InteractiveModal
        open={open}
        size="md"
        toggleModal={() => setterToggle && setterToggle()}
      >
        <div className="d-flex justify-content-center align-items-center w-100   flex-grow-1">
          {open && (
            <div className="d-flex flex-column align-items-center  flex-grow-1">
              <TitleModal>Validação de segurança</TitleModal>
              <Spacer bottom={10} />
              <Description>Informe o token vinculado a sua conta.</Description>
              <Spacer bottom={30} />
              <div className=" ps-4">
                {" "}
                <InputToken
                  keyDownFunction={handleConfirmToken}
                  inputsNumber={6}
                  onInputChange={(values) => handleInput(values)}
                />
              </div>

              <Spacer bottom={40} />

              <div style={{ width: "290px" }}>
                <Button
                  mode="main"
                  label={buttonLabel}
                  full
                  onClick={handleConfirmToken}
                  disabled={buttonDisabled}
                  loading={isLoadingButton}
                />
              </div>
            </div>
          )}
        </div>
      </InteractiveModal>
    </div>
  );
};

export default ValidatorTokenModal;
