import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  Checkbox,
  InteractiveModal,
  ProgressBar,
  ReturnButton,
  Spacer,
  SummaryPanel,
} from "@zilliondigital/mirage-ui";
import XIcon from "~/assets/icons/buttons/x.png";

import RefreshIcon from "~/assets/icons/buttons/Ic_functional_refresh.png";
import { useEffect, useState } from "react";

import FilterIcon from "~/assets/icons/buttons/filter_alt_black_24dp.png";

import { useNavigate } from "react-router-dom";
import useCollections from "~/hooks/Collections/useCollections";
import NewCollectionSelect from "./NewCollectionOrRecurrence/CollectionOrRecurrenceSelect";
import CollectionDetailModal from "./CollectionDetailModal";
import WithCollections from "./WithCollections";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import useAccount from "~/hooks/useAccount";
import EmptyCollections from "./EmptyCollections";
import Paginator, { applyPagination } from "~/components/Paginator";
import LastDaysFilter from "~/components/LastDaysFilter";
import Copyright from "~/components/Copyright";
import theme from "~/themes/theme";
import CollectionsFilter from "./CollectionsFilter";
import { quantityPages } from "~/types";
import ApiService from "~/services/api";
import TitleModal from "~/components/Texts/TitleModal";

import useToast from "~/hooks/useToast";
import format from "date-fns/format";
import { subDays } from "date-fns";
import Badge from "~/components/Badge";
import { formatCash, formatDate } from "~/utils";
import EditCollectionGroup from "./EditCollection/EditInGroup";
import ExportsModal from "./ExportsModal";
import billets from "~/data/billets";
import dataStatements from "~/data/extrato";

const GeneralContainerData = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: 100%;

  width: 100%;
  flex-grow: 1;

  .hover-list-button {
    transition: 0.3s;
    transform: scale(1.2);
    filter: grayscale(1);
    &:hover {
      transform: scale(1.4);
      filter: none;
    }
  }

  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .container-return-button {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .container-auxiliar {
    position: fixed;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const StatusData = styled.div`
  .button {
    opacity: 0;
  }

  &:hover > .button {
    .button {
      opacity: 1;
    }
  }
`;

const ContainerData = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  min-height: 100%;
`;

const ContainerCollectionsStatisticsLabels = styled.div`
  position: relative;
  right: 60%;
  width: 150px;

  .statistics-label {
    font-size: 12px;

    span {
      font-size: 12px;
    }
  }
`;

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Title = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;
const CollectionsInfoRenderLabel = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  padding: 0;
  margin: 0;
  span {
    font-size: 12px;
    color: #929292;
  }
`;

const calculatePercentage = (value: number, total: number) => {
  return (value / total) * 100;
};

const OpenedCollectionsInfoRender = (value: number, percent: number) => {
  return (
    <div className="d-flex flex-column  w-100 align-items-end">
      <CollectionsInfoRenderLabel>
        {value} <span>emitidas</span>
      </CollectionsInfoRenderLabel>
      <CollectionsInfoRenderLabel>
        {percent.toFixed(2)}%<span> do total</span>
      </CollectionsInfoRenderLabel>
    </div>
  );
};

const OverdueCollectionsInfoRender = (value: number, percent: number) => {
  return (
    <div className="d-flex flex-column  w-100 align-items-end">
      <CollectionsInfoRenderLabel>
        {value} <span>emitidas</span>
      </CollectionsInfoRenderLabel>
      <CollectionsInfoRenderLabel>
        {percent.toFixed(2)}%<span> do total</span>
      </CollectionsInfoRenderLabel>
    </div>
  );
};

const CollectionsStatistics = () => {
  return (
    <div className="d-flex flex-column justify-content-center w-50">
      <ProgressBar percent={1} color="#F89E23" />
      <Spacer bottom={10} />
      <ProgressBar percent={100} color="#7D18D3" />
      <Spacer bottom={10} />
      <ProgressBar percent={1} color="#1CAD6B" />
    </div>
  );
};

const CollectionsStructure = () => {
  const navigate = useNavigate();

  const { formatNumberToCurrency, mainAccount, verifyPermission, beforeDays } =
    useAccount();

  const canCreateBillet: boolean = verifyPermission("ba_can_create_billet");
  const canCreatePaymentLink: boolean = verifyPermission(
    "ba_can_create_payment_link"
  );
  const canReadCnab: boolean = verifyPermission("ba_can_read_cnab");
  const canCreateCnab: boolean = verifyPermission("ba_can_create_cnab");

  const {
    setOpenNewCollectionOrRecurrenceModal,
    openNewCollectionOrRecurrenceModal,
    billetsData,
    openDetailModal,
    setRowsPerPage,
    rowsPerPage,
    page,
    setPage,
    setFetchParams,
    loadingList,
    getBillets: TGetBillets,
    setShowContent,
    selectedValue,
    showContent,
    setSelectedValue,
    labelBadgesFilterCollections,
    setLabelBadgessFilterCollections,
    dataBillet,
    setOpenFilterModal,

    paramsFilterCollections,
    isFilteredResults,
    openFilterModal,
    setIsFilteredResults,
    selectedIds,
    setOpenExportsModal,
    setCurrentModalData,
    statusColumnSelected,
    setOpenDetailModal,
    setSelectedIds,
    selectedBillets,
    setStatusColumnSelected,
    setOpenEditInGroup,
    setRenderInitialContent,
  } = useCollections();

  const { getBillets } = useNewCollection();

  const openDetail = (data: any) => {
    setCurrentModalData(data);
    setOpenDetailModal(true);
  };

  const CollectionsStatisticsLabels = () => {
    return (
      <ContainerCollectionsStatisticsLabels>
        <CollectionsInfoRenderLabel className="statistics-label">
          <b> Total boletos: </b>
          <span>{billetsData.results?.length}</span>
        </CollectionsInfoRenderLabel>
      </ContainerCollectionsStatisticsLabels>
    );
  };

  const billets = billetsData?.results;

  const menusBreadCrumb = [{ label: "Cobranças", href: "app/collections" }];
  const quantityPages: quantityPages[] = [
    {
      label: "20 por página",
      value: "1",
      onClick: () => {
        setRowsPerPage(20);
      },
    },
    {
      label: "50 por página",
      value: "2",
      onClick: () => {
        setRowsPerPage(50);
      },
    },
    {
      label: "100 por página",
      value: "3",
      onClick: () => {
        setRowsPerPage(100);
      },
    },
  ];

  const openedAmount = formatNumberToCurrency(billetsData?.sum?.opened_amount);
  const inProcessingAmount = formatNumberToCurrency(
    billetsData?.sum?.processing_amount ?? 0
  );

  const overdueAmount = formatNumberToCurrency(
    billetsData?.sum?.overdue_amount
  );

  const canceledAmount = formatNumberToCurrency(
    billetsData?.sum?.canceled_amount
  )

  const postAmount = formatNumberToCurrency(
    billetsData?.sum?.overdue_amount + billetsData?.sum?.canceled_amount
  )

  const percentOpened = calculatePercentage(
    billetsData?.sum?.opened,
    billetsData?.sum?.total
  );

  const percentProcessing = calculatePercentage(
    billetsData?.sum?.processing ?? 0,
    billetsData?.sum?.total
  );

  const percentOverdue = calculatePercentage(
    billetsData?.sum?.overdue,
    billetsData?.sum?.total
  );

  const percentCanceled = calculatePercentage(
    billetsData?.sum?.canceled,
    billetsData?.sum?.total
  ) 
  
  const CollectionsPanel = [
    {
      label: "Cobranças em aberto",
      balance: openedAmount,
      secondaryRender: () =>
        OpenedCollectionsInfoRender(billetsData?.sum?.opened, percentOpened),
    },
    {
      label: "Cobranças em processamento",
      balance: inProcessingAmount ?? 0,
      secondaryRender: () =>
        OpenedCollectionsInfoRender(
          billetsData?.sum?.processing ?? 0,
          percentProcessing ?? 0
        ),
    },
    {
      label: "Cobranças em atraso/decurso",
      balance:postAmount ,

      secondaryRender: () =>
        OverdueCollectionsInfoRender(billetsData?.sum?.overdue + billetsData?.sum?.canceled, percentOverdue + percentCanceled),
    },

    {
      label: "Total de boletos",
      balance: billetsData?.results?.length,
    },
  ];

  const { showToast } = useToast();

  const [isLoadingCancelBillet, setIsLoadingCancelBillet] =
    useState<boolean>(false);

  const cancelBillet = async () => {
    setIsLoadingCancelBillet(true);
    try {
      const data = await ApiService.HttpPost({
        route: "charge/billet-instruction/",
        body: {
          billet: dataBillet?.id,
          type: "cancelamento",
        },
        token: true,
      });

      setShowContent(false);
      setSelectedValue("");
      setIsLoadingCancelBillet(false);
      showToast("Pedido de cancelamento efetuado com sucesso", "", "success");
      setIsFilteredResults(false);
      TGetBillets({
        from_date: fromDate,
        to_date: todayFormatted,
        limit: 9999999,
      });
    } catch (e: any) {
      setIsLoadingCancelBillet(false);
      showToast("Ocorreu um erro", e.response.data.data, "error");

      setShowContent(false);
      setSelectedValue("");
    }
  };

  const cleanFilters = () => {
    getBillets(fromDate, todayFormatted);
    setIsFilteredResults(false);
  };

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  useEffect(() => {
    if (billetsData.length === 0) {
      getBillets(fromDate, todayFormatted);
    }

    setIsFilteredResults(false);
  }, []);

  useEffect(() => {
    getBillets(fromDate, todayFormatted);
    setIsFilteredResults(false);
  }, [mainAccount]);

  const KeysDict: { [key: string]: string } = {
    contact: "Contato",
    filteramountby: "Tipo do valor",
    price_min: "Valor mínimo",
    price_max: "Valor máximo",
    from_date: "Data inicial",
    to_date: "Data final",
    filterby: "Tipo de data",
  };

  const ValueDict: { [key: string]: string } = {
    amount: "Valor total",
    amount_paid: "Valor pago",
    due_date: "Vencimento",
    created_date: "Emissão",
    payment_date: "Crédito",
    bank_payment_date: "Pagamento",
  };

  const data = billets?.map((transaction: any, index: number) => ({
    ...transaction,
    delete: index,
  }));

  const handleSelectAllValid = () => {
    const transactions = data;

    const selectedVouchersForStatusColumn = transactions
      .filter(
        (transaction: any) =>
          transaction.id !== null &&
          transaction.id !== undefined &&
          transaction.status !== "error"
      )
      .map((transaction: any) => transaction.id);

    setSelectedIds(selectedVouchersForStatusColumn);
    setStatusColumnSelected(true);
  };

  return (
    <ParentContainer
      className={
        openNewCollectionOrRecurrenceModal || openDetailModal
          ? "overflow-hidden"
          : ""
      }
    >
      <GeneralContainerData>
        <div className="d-flex flex-column container-fluid ps-5 pe-5  w-100 h-100 ">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />
          <Spacer top={30} />
          <ContainerData className="containe-fluid ps-4 pe-4">
            <div className="d-flex justify-content-between p-4 ">
              <Title>Cobrança</Title>
              <div>
                <div className="d-flex flex-column flex-lg-row">
                  <Button
                    mode="border"
                    label="Cobrança em lote"
                    onClick={() => navigate("/app/cnab")}
                    disabled={!canReadCnab && !canCreateCnab}
                  />

                  <Spacer right={10} />
                  <Button
                    mode="main"
                    label="Criar cobrança"
                    onClick={() => setOpenNewCollectionOrRecurrenceModal(true)}
                    disabled={!canCreateBillet && !canCreatePaymentLink}
                  />

                  <div className="d-block d-lg-none">
                    <Spacer bottom={10} />
                  </div>

                  {/* <Button
                      mode="border"
                      onClick={() => {
                        setOpenNewCollectionModal(true);
                        setIsRecurrence(true);
                      }}
                      label="Criar recorrência"
                      disabled
                    /> */}
                </div>
              </div>
            </div>
            <div className="ps-4 pe-4">
              {(billetsData?.results?.length > 0 || loadingList) && (
                <div className="row">
                  <SummaryPanel
                    panel={CollectionsPanel}
                    isLoading={loadingList}
                  />
                </div>
              )}
              <Spacer bottom={30} />

              <div className="d-flex w-100 align-items-start  ">
                {!isFilteredResults && (
                  <div className="">
                    <Button
                      mode="border"
                      label="Atualizar"
                      icon={RefreshIcon}
                      onClick={() => getBillets(fromDate, todayFormatted)}
                      iconColorFilter={theme.colors.primary.mainFilter}
                    />
                  </div>
                )}
                <Spacer left={15} />
                {billetsData?.results?.length > 0 && (
                  <div>
                    <ButtonDropdown
                      mode="border"
                      label={`${rowsPerPage} por página`}
                      options={quantityPages}
                    />
                  </div>
                )}
                <div style={{ marginLeft: "auto" }} className="d-flex">
                  {selectedIds.length > 1 && (
                    <div className="me-2">
                      <Button
                        mode="border"
                        label={"Gerar relatório"}
                        onClick={() => {
                          setOpenExportsModal(true);
                        }}
                      />
                    </div>
                  )}
                  {selectedIds.length > 1 && (
                    <div className="me-2">
                      <Spacer left={10} />
                      <Button
                        mode="border"
                        label={"Editar em lote"}
                        onClick={() => {
                          setRenderInitialContent(true);
                          setOpenEditInGroup(true);
                        }}
                      />
                    </div>
                  )}

                  {isFilteredResults ? (
                    <Button
                      mode="border"
                      onClick={() => setOpenFilterModal(true)}
                      label={"Editar filtros"}
                      icon={FilterIcon}
                    />
                  ) : (
                    <div>
                      <LastDaysFilter
                        setter={(_from, _to) => {
                          setFetchParams({ from_date: _from, to_date: _to });
                        }}
                        executeFetch={TGetBillets}
                      />
                    </div>
                  )}

                  <Spacer right={10} />
                  <div>
                    <Button
                      mode="border"
                      onClick={() =>
                        isFilteredResults
                          ? cleanFilters()
                          : setOpenFilterModal(true)
                      }
                      label={
                        isFilteredResults ? "Limpar filtros" : "Aplicar filtros"
                      }
                      danger={isFilteredResults}
                      icon={isFilteredResults ? XIcon : FilterIcon}
                    />
                  </div>
                </div>
                <Spacer left={10} />
                <Button
                  label={"Selecionar todos"}
                  mode="border"
                  onClick={() => handleSelectAllValid()}
                />
              </div>
              <Spacer bottom={20} />
              <div className="d-flex justify-content-end flex-wrap">
                {isFilteredResults &&
                  !openFilterModal &&
                  Object.entries(labelBadgesFilterCollections).map(
                    ([chave, valor]) => (
                      <div
                        className="d-flex "
                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                        onClick={() => console.log(valor)}
                      >
                        {chave === "status" ? (
                          <>
                            {typeof valor === "string" &&
                              valor.includes("opened") && (
                                <>
                                  <Badge
                                    color={theme.colors.base.light200}
                                    textColor={theme.colors.base.main}
                                    label="Em aberto"
                                  />
                                  <Spacer left={10} />
                                </>
                              )}
                            {typeof valor === "string" &&
                              valor.includes("processing") && (
                                <>
                                  <Badge
                                    color={theme.colors.base.light200}
                                    textColor={theme.colors.base.main}
                                    label="Em processamento"
                                  />
                                  <Spacer left={10} />
                                </>
                              )}
                            {typeof valor === "string" &&
                              valor.includes("overdue") && (
                                <>
                                  <Badge
                                    color={theme.colors.base.light200}
                                    textColor={theme.colors.base.main}
                                    label="Vencido"
                                  />
                                  <Spacer left={10} />
                                </>
                              )}
                            {typeof valor === "string" &&
                              valor.includes("canceled") &&
                              labelBadgesFilterCollections["cancel_type"] ===
                                undefined && (
                                <>
                                  <Badge
                                    color={theme.colors.base.light200}
                                    textColor={theme.colors.base.main}
                                    label="Decurso de prazo"
                                  />
                                  <Spacer left={10} />
                                </>
                              )}

                            {typeof valor === "string" &&
                              valor.includes("canceling") && (
                                <>
                                  <Badge
                                    color={theme.colors.base.light200}
                                    textColor={theme.colors.base.main}
                                    label="Em cancelamento"
                                  />
                                  <Spacer left={10} />
                                </>
                              )}
                            {typeof valor === "string" &&
                              valor.includes("done") && (
                                <>
                                  <Badge
                                    color={theme.colors.base.light200}
                                    textColor={theme.colors.base.main}
                                    label="Liquidado / Baixado"
                                  />
                                  <Spacer left={10} />
                                </>
                              )}
                          </>
                        ) : chave === "cancel_type" ? (
                          <>
                            <Badge
                              color={theme.colors.base.light200}
                              textColor={theme.colors.base.main}
                              label="Cancelado"
                            />
                            <Spacer left={10} />
                          </>
                        ) : chave === "show_all" ? (
                          <></>
                        ) : (
                          <Badge
                            color={theme.colors.base.light200}
                            textColor={theme.colors.base.main}
                            label={`${KeysDict[chave as string]}: ${
                              chave === "price_min" || chave === "price_max"
                                ? formatCash(valor as number)
                                : chave === "contact"
                                ? valor
                                : chave === "from_date" || chave === "to_date"
                                ? formatDate(valor as string)
                                : valor === undefined
                                ? "-"
                                : ValueDict[valor as string]
                            }`}
                          />
                        )}
                      </div>
                    )
                  )}
              </div>
            </div>

            {billetsData.results?.length > 0 || loadingList ? (
              <div className="ps-3 pe-3">
                <WithCollections />
              </div>
            ) : null}
            {billetsData.results?.length === 0 && !loadingList && (
              <EmptyCollections />
            )}
          </ContainerData>
          <div
            className={
              billetsData.results?.length === 0 || loadingList ? "d-none" : ""
            }
          >
            <Paginator
              list={
                billetsData?.results?.length > 0 ? billetsData?.results : []
              }
              rowsPerPage={rowsPerPage}
              page={page}
              pageSetter={setPage}
            />
          </div>
          <Spacer top={billetsData.length > 0 ? 20 : 40} />

          <Copyright />

          <Spacer top={30} />
        </div>
      </GeneralContainerData>

      <NewCollectionSelect />
      <CollectionDetailModal />
      <CollectionsFilter />
      <ExportsModal />
      <InteractiveModal
        toggleModal={() => {
          setShowContent(!showContent);
          setSelectedValue("");
        }}
        open={selectedValue === "cancel" && showContent}
        size="md"
      >
        <div className="p-5 text-center">
          <TitleModal>Cancelar cobrança de {dataBillet?.payer_name}</TitleModal>
          <Spacer bottom={90} />
          <Button
            mode="border"
            label="Não, manter como esta"
            onClick={() => {
              setShowContent(false);
              setSelectedValue("");
            }}
            full
          />
          <Spacer bottom={10} />
          <Button
            mode="main"
            label="Sim, cancelar cobrança"
            onClick={() => cancelBillet()}
            full
            loading={isLoadingCancelBillet}
          />
        </div>
      </InteractiveModal>
      <EditCollectionGroup />
    </ParentContainer>
  );
};

export default CollectionsStructure;
