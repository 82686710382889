import {
  Button,
  CustomSelect,
  Input,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import { ufOptions } from "~/data/options";
import * as Yup from "yup";
import useCEP from "~/hooks/useCEP";
import {
  CpfRegex,
  INVALID_FORMAT,
  PhoneRegex,
  REQUIRED_LABEL,
} from "~/services/validators";
import { ContainerData } from "../../app-content.styles";
import useNewContact from "~/hooks/Contacts/useNewContact";
import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";
import { useState, useEffect } from "react";

import { setContact } from "~/types";
import useToken from "~/hooks/useToken";

type FormValues = {
  name: string;
  document_number: string;
  email: string;
  phone: string;
  cep: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
};

const initialValues: FormValues = {
  name: "",
  document_number: "",
  email: "",
  phone: "",
  cep: "",
  street: "",
  number: "",
  complement: "",
  district: "",
  city: "",
  state: "",
};

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(CpfRegex, INVALID_FORMAT),
  email: Yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
  phone: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(PhoneRegex, INVALID_FORMAT),
  cep: Yup.string().required("Campo obrigatório"),
  street: Yup.string().required("Campo obrigatório"),
  number: Yup.string().required("Campo obrigatório"),
  complement: Yup.string().required("Campo obrigatório"),
  district: Yup.string().required("Campo obrigatório"),
  city: Yup.string().required("Campo obrigatório"),
  state: Yup.string().required("Campo obrigatório"),
});

const Address = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleCep, address, setAddress } = useCEP();
  const {
    setStep,
    setNewContactPayload,
    newContactPayload,
    setNewContactData,
  } = useNewContact();
  const { showToast } = useToast();
  const [selectedTypeValue, setSelectedTypeValue] = useState();

  const { getToken } = useToken();

  const { values, errors, handleBlur, touched, handleChange, resetForm } =
    useFormik({
      initialValues: initialValues,
      validationSchema: FormSchema,
      onSubmit: (values) => {},
    });

  const handleChangeSelect = (optionSelected: any) => {
    setSelectedTypeValue(optionSelected.target.value);
  };

  const setNewContact = async () => {
    setIsLoading(true);

    try {
      const { data } = await ApiService.HttpPost<setContact>({
        route: "partner/",
        token: true,
        body: newContactPayload,
      });

      await getToken();

      setNewContactData(data);
      showToast("Contato cadastrado com sucesso", " ", "success");

      setStep("bank_data");
      setIsLoading(false);
    } catch (e: any) {
      if (
        e.response.data.document_number === "invalid partner document number"
      ) {
        showToast(
          "CPF/CNPJ informado é inválido",
          " Verifique o  CPF/CNPJ e tente novamente ",
          "error"
        );

        setStep("basic_data");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setNewContactPayload({
      ...newContactPayload,
      address: {
        zipcode: address.zip_code,
        street: address.street,
        number: values.number,
        complement: values.complement,
        district: address.district,
        city: address.city,
        state: address.state,
        address_type: String(selectedTypeValue),
        id: "",
      },
    });
  }, [address, values]);

  return (
    <>
      <ContainerData className="container p-4">
        <div className="row d-flex align-items-end">
          <div className="col-2">
            {" "}
            <CustomSelect
              name="type"
              label="Tipo de Endereço"
              placeholder="Selecione"
              options={[
                {
                  label: "Residencial",
                  value: "residential",
                },
                {
                  label: "Fatura/Cobrança",
                  value: "billing",
                },
              ]}
              required={selectedTypeValue === undefined}
              onChange={handleChangeSelect}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-2">
            <Input
              mask="99999-999"
              type="text"
              name="zip_code"
              label="CEP"
              placeholder="04313-110"
              error={address.error}
              onChange={handleCep}
              search
              required={!address.zip_code}
            />
          </div>

          <div className="col-8"></div>
          <div className="col-3">
            <Input
              type="text"
              name="street"
              value={address.street}
              label="Logradouro"
              placeholder="Rua Borboletas Psicodélicas"
              autoCapitalize="words"
              onChange={handleChange}
              required={!address.street}
              success={address.street ? true : false}
            />
          </div>
          <div className="col-1">
            <Input
              type="text"
              name="number"
              value={values.number}
              label="Número"
              placeholder="269"
              error={touched.number && errors.number}
              required={!values.number}
              onChange={handleChange}
              success={values.number ? true : false}
            />
          </div>

          <div className="col-2">
            <Input
              type="text"
              name="complement"
              value={values.complement}
              label="Complemento"
              placeholder="Casa 1"
              success={values.complement ? true : false}
              onChange={handleChange}
            />
          </div>
          <div className="col-6"></div>
          <div className="col-4">
            <Input
              type="text"
              name="district"
              value={address.district}
              label="Bairro"
              placeholder="Vila Guarani"
              onChange={handleChange}
              required={!address.district}
              success={address.district ? true : false}
              autoComplete="off"
              autoCapitalize="off"
            />
          </div>

          <div className="col-2">
            <Input
              type="text"
              name="city"
              value={address.city}
              success={address.city ? true : false}
              label="Cidade"
              placeholder="São Paulo"
              onChange={handleChange}
              autoComplete="city"
              required={!address.city}
            />
          </div>
          <div className="col-1">
            <CustomSelect
              name="state"
              label="UF"
              placeholder="Selecione"
              options={ufOptions}
              value={ufOptions.find((uf) => uf.value === address.state)}
              onChange={(value: any) => {
                setAddress({ ...address, state: value.value });
              }}
              required={!address.city}
            />
          </div>
        </div>
      </ContainerData>
      <div className="w-100 d-flex justify-content-center justify-content-lg-end p-3 align-items-center">
        <Button
          mode="phantom"
          label="Voltar"
          onClick={() => setStep("basic_data")}
        />
        <Spacer right={20} />
        <Button
          mode="main"
          label="Cadastrar contato"
          disabled={
            !values.number ||
            !address.zip_code ||
            selectedTypeValue === undefined
          }
          onClick={() => setNewContact()}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default Address;
