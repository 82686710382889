export const genderOptions = [
  { label: "Masculino", value: "male" },
  { label: "Feminino", value: "female" },
];

export const accountType = [
  { label: "Conta Corrente", value: "1" },
  { label: "Conta Poupança", value: "2" },
  { label: "Conta Pagamento", value: "3" },
];

export const companyTypes = [
  { value: "sa", label: "SA" },
  { value: "ltda", label: "LTDA" },
  { value: "mei", label: "MEI" },
  { value: "me", label: "ME" },
  { value: "eireli", label: "EIRELI" },
  { value: "condomium", label: "Condomínio" },
  { value: "closed_sa", label: "SA Fechada" },
  { value: "simple_eireli", label: "EIRELI Simples" },
  { value: "slu", label: "SLU" },
  { value: "fidc", label: "FIDC" },
  { value: "scp", label: "Sociedade conta de participação" },
  { value: "coop", label: "Cooperativa" },
  { value: "pa", label: "Associação privada" },
  { value: "ssp", label: "Sociedade Simples Pura" },
  { value: "notary", label: "Cartório" },

  { value: "ei", label: "EI" },
  { value: "ss", label: "SS" },
  { value: "epp", label: "EPP" },
  { value: "emgp", label: "EMGP" },
];

export const countrysOptions = [{ value: "Brasil", label: "Brasil" }];

export const phoneCodeOptions = [{ value: "+55", label: "+55" }];

export const ufOptions = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: "SC" },
  { label: "SP", value: "SP" },
  { label: "SE", value: "SE" },
  { label: "TO", value: "TO" },
];

export const ufOptionsId = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: 26 },
  { label: "SP", value: 26 },
  { label: "SE", value: 26 },
  { label: "TO", value: "TO" },
];

export const statesOptions = [
  { label: "Acre", value: "AC" },
  { label: "Alagoas", value: "AL" },
  { label: "Amapá", value: "AP" },
  { label: "Amazonas", value: "AM" },
  { label: "Bahia", value: "BA" },
  { label: "Ceará", value: "CE" },
  { label: "Distrito Federal", value: "DF" },
  { label: "Espírito Santo", value: "ES" },
  { label: "Goiás", value: "GO" },
  { label: "Maranhão", value: "MA" },
  { label: "Mato Grosso", value: "MT" },
  { label: "Mato Grosso do Sul", value: "MS" },
  { label: "Minas Gerais", value: "MG" },
  { label: "Pará", value: "PA" },
  { label: "Paraíba", value: "PB" },
  { label: "Paraná", value: "PR" },
  { label: "Pernambuco", value: "PE" },
  { label: "Piauí", value: "PI" },
  { label: "Rio de Janeiro", value: "RJ" },
  { label: "Rio Grande do Norte", value: "RN" },
  { label: "Rio Grande do Sul", value: "RS" },
  { label: "Rondônia", value: "RO" },
  { label: "Roraima", value: "RR" },
  { label: "Santa Catarina", value: "SC" },
  { label: "São Paulo", value: "SP" },
  { label: "Sergipe", value: "SE" },
  { label: "Tocantins", value: "TO" },
];

export const documentTypeOptions = [{ label: "RG", value: "RG" }];

export const dispatchingAgencyOptions = [
  { label: "SSP-AC", value: "AC" },
  { label: "SSP-AL", value: "AL" },
  { label: "SSP-AP", value: "AP" },
  { label: "SSP-AM", value: "AM" },
  { label: "SSP-BA", value: "BA" },
  { label: "SSP-CE", value: "CE" },
  { label: "SSP-DF", value: "DF" },
  { label: "SSP-ES", value: "ES" },
  { label: "SSP-GO", value: "GO" },
  { label: "SSP-MA", value: "MA" },
  { label: "SSP-MT", value: "MT" },
  { label: "SSP-MS", value: "MS" },
  { label: "SSP-MG", value: "MG" },
  { label: "SSP-PA", value: "PA" },
  { label: "SSP-PB", value: "PB" },
  { label: "SSP-PR", value: "PR" },
  { label: "SSP-PE", value: "PE" },
  { label: "SSP-PI", value: "PI" },
  { label: "SSP-RJ", value: "RJ" },
  { label: "SSP-RN", value: "RN" },
  { label: "SSP-RS", value: "RS" },
  { label: "SSP-RO", value: "RO" },
  { label: "SSP-RR", value: "RR" },
  { label: "SSP-SC", value: "SC" },
  { label: "SSP-SP", value: "SP" },
  { label: "SSP-SE", value: "SE" },
  { label: "SSP-TO", value: "TO" },
];

export const maritalStatusOptions = [
  { value: "0", label: "Solteiro(a)" },
  { value: "1", label: "Casado(a)" },
  { value: "2", label: "Divorciado(a)" },
  { value: "3", label: "Separado(a)" },
  { value: "4", label: "Viúvo(a)" },
  { value: "5", label: "União estável" },
  { value: "6", label: "Outros" },
];

export const marriageRegimeOptions = [
  { label: "Comunhão parcial de bens", value: "Comunhão parcial de bens" },
  { label: "Comunhão universal de bens", value: "Comunhão universal de bens" },
  { label: "Separação de bens", value: "Separação de bens" },
  {
    label: "Participação final nos aquestos",
    value: "Participação final nos aquestos",
  },
  {
    label: "Regime da separação obrigatória de bens",
    value: "Regime da separação obrigatória de bens",
  },
  {
    label: "Nenhum",
    value: "Nenhum",
  },
];

export const quantityPages = [
  { label: "20 por página", value: "1" },
  { label: "50 por página", value: "2" },
  { label: "100 por página", value: "3" },
];

export const operationStatus: { [index: string]: any } = {
  pending: "Aguardando aprovação",
  processing: "Processando",
  error_retry: "Ocorreu um erro na sua aprovação. Tente novamente.",
  done: "Pago",
  canceling: "Em cacelamento",
  paid: "Pago",
  queued: "Agendado",
  overdue: "Vencido",
  canceled: "Cancelado",
  canceled_automatic: "Decurso de prazo",
  canceled_manual: "Cancelado",
  done_automatic: "Pago",
  done_manual: "Baixado",
  error_: "Ocorreu um erro",
  error: "Ocorreu um erro",
  opened: "Em aberto",
  refused: "Reprovado",
  approved: "Aprovado",
  waiting_approval: "Aguardando aprovação",
};

export const statementTaxes: any = {
  create_billet: "Emissão de boleto",
  liquidate_billet: "Liquidação de boleto",
  create_qrcode: "Emissão de Qrcode Estático",
  liquidate_qrcode: "Liquidação de Qrcode Estático",
  cancel_qrcode: "Baixa do Qrcode Estático",
  create_dynamic_qrcode: "Emissão de Qrcode Dinâmico",
  liquidate_dynamic_qrcode: "Liquidação de Qrcode Dinâmico",
  cancel_dynamic_qrcode: "Baixa do Qrcode Dinâmico",
  ted: "TED",
  tev: "TEV",
  pix_transfer: "Transferência Pix",
  pix_cash_in: "Entrada de transferência Pix",
  payment_billet: "Pagamentos Processados",
  create_payment_link: "Emissão de Link de Pagamento",
  liquidate_payment_link: "Liquidação de Link de Pagamento",
  monthly_movement_maintenance: "Taxa de Manutenção Mensal",
  monthly_escrow_maintenance: "Taxa de Manutenção Mensal Escrow",
  billet_lot: "Geração de Boleto em Lote",
  pix_lot: "Transferência de Pix em Lote",
  billet_split: "Split de Boleto",
};

export const taxesArray = [
  { label: "Emissão de boleto", value: "create_billet" },
  { label: "Liquidação de boleto", value: "liquidate_billet" },
  { label: "Emissão de Qrcode Estático", value: "create_qrcode" },
  { label: "Liquidação de Qrcode Estático", value: "liquidate_qrcode" },
  { label: "Baixa do Qrcode Estático", value: "cancel_qrcode" },
  { label: "Emissão de Qrcode Dinâmico", value: "create_dynamic_qrcode" },
  { label: "Liquidação de Qrcode Dinâmico", value: "liquidate_dynamic_qrcode" },
  { label: "Baixa do Qrcode Dinâmico", value: "cancel_dynamic_qrcode" },
  { label: "TED", value: "ted" },
  { label: "TEV", value: "tev" },
  { label: "Transferência Pix", value: "pix_transfer" },
  { label: "Entrada de transferência Pix", value: "pix_cash_in" },
  { label: "Pagamentos Processados", value: "payment_billet" },
  { label: "Emissão de Link de Pagamento", value: "create_payment_link" },
  { label: "Liquidação de Link de Pagamento", value: "liquidate_payment_link" },
  { label: "Taxa de Manutenção Mensal", value: "monthly_movement_maintenance" },
  {
    label: "Taxa de Manutenção Mensal Escrow",
    value: "monthly_escrow_maintenance",
  },
  { label: "Geração de Boleto em Lote", value: "billet_lot" },
  { label: "Transferência de Pix em Lote", value: "pix_lot" },
  { label: "Split de Boleto", value: "billet_split" },
];
