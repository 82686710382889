import React, { useEffect } from "react";
import { CreateCardDeliveryStepDiv, CreatingCardConfirmationTablesWrapper, CreatingCardConfirmationContainer, CreateCardConfirmationFooterBtnWrapper } from "../styled";
import { ICreateCardInfos } from "~/hooks/Cards/initalValues";
import { CardTypeDict, CreateCardStep, CreatingCardStatus } from "~/hooks/Cards/interfaces";
import { Button, Table } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import { buildCardConfirmationColStatements } from "./createCardConfirmationColStatements";

interface ICreateCardConfirmationStepSection {
  setCreateCardStep: React.Dispatch<React.SetStateAction<CreateCardStep>>;
  createCardStep: CreateCardStep;
  createCardInfos: ICreateCardInfos;
  setCreateCardValues: (info: string, value: string | number | boolean) => void;
  creatingCardStatus: CreatingCardStatus;
  postCreateBankCard: () => Promise<void>;
}
const CreateCardConfirmationStepSection = (props: ICreateCardConfirmationStepSection) => {
  const navigate = useNavigate()

  useEffect(() => {
    if(props.creatingCardStatus == 'SUCCESS'){
      props.setCreateCardStep('SUCCESS')
    }
  }, [props.creatingCardStatus])

  const infoCardData = [
    {field: 'Apelido do cartão', value: props.createCardInfos.name},
    {field: 'Tipo de cartão', value: CardTypeDict[props.createCardInfos.card_type]}
  ]

  //TODO get default address
  const deliveryCardData = [
    {field: 'CEP', value: props.createCardInfos.other_address?.zip_code || '' },
    {field: 'Endereço', value: props.createCardInfos.other_address?.address || '' },
    {field: 'Número', value: props.createCardInfos.other_address?.number || '' },
    {field: 'Complemento', value: props.createCardInfos.other_address?.complementary_address || '' },
    {field: 'Bairro', value: props.createCardInfos.other_address?.district || '' },
    {field: 'Cidade', value: props.createCardInfos.other_address?.city || '' },
    {field: 'UF', value: props.createCardInfos.other_address?.state || '' }
  ]

  const columnsStatements = buildCardConfirmationColStatements()

  return (
    <CreateCardDeliveryStepDiv>
      <CreatingCardConfirmationContainer>
        <h4>Confirme o pedido do cartão</h4>
        <CreatingCardConfirmationTablesWrapper>
          <h4>Informações do cartão</h4>
          <Table
            data={infoCardData}
            columns={columnsStatements}
            dataSpacer={8}
          />
        </CreatingCardConfirmationTablesWrapper>
        {
          props.createCardInfos.card_type == 'PLASTIC' ? (
            <CreatingCardConfirmationTablesWrapper>
              <h4>Endereço de entrega</h4>
              <Table
                data={deliveryCardData}
                columns={columnsStatements}
                dataSpacer={8}
              />
            </CreatingCardConfirmationTablesWrapper>
          ): <></>
        }

        <CreateCardConfirmationFooterBtnWrapper>
          <Button 
            mode="border" style={{width: '30%'}} label="Voltar"
            onClick={() => {props.setCreateCardStep('DELIVERY_INFO_OTHER')}}
            disabled={props.creatingCardStatus == 'LOADING'}
          />
          <Button 
            mode="main" style={{width: '30%'}} label="Confirmar"
            onClick={() => {
              props.postCreateBankCard()
            }}
            disabled={props.creatingCardStatus == 'LOADING'}
          />
        </CreateCardConfirmationFooterBtnWrapper>
      </CreatingCardConfirmationContainer>
    </CreateCardDeliveryStepDiv>
  );
};

export default CreateCardConfirmationStepSection;
