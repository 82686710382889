import styled from "styled-components";

type TitleProps = {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  wrapText?: boolean;
  style?: { [key: string]: any };
};

const AppDescription = styled.span<TitleProps>`
  font-size: 14px;
  color: ${({ color }) => (color ? color : "#242424;")};
  /* wrap text if wrap text */
  ${({ wrapText }) => wrapText && "overflow-wrap: break-word;"}
`;

const Description = (props: TitleProps) => {
  const { children, className, color, wrapText, style } = props;

  return (
    <AppDescription
      {...props}
      color={color}
      className={className}
      style={style}
    >
      {children}
    </AppDescription>
  );
};

export default Description;
