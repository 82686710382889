import styled, { keyframes } from "styled-components";
import theme from "~/themes/theme";

export const GeneralContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;
  padding-bottom: 15px;
  min-height: 100vh;

  .p-30 {
    padding: 30px;
  }

  .tooltipbox {
    position: relative;

    & > div {
      position: absolute;
    }
  }

  .container-return-button {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }

  .container-chart {
  }

  .empty-table-box {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 150px;
      filter: ${theme.colors.primary.mainFilter};
    }

    &.no-filter {
      img {
        filter: none !important;
      }
    }

    span {
      margin: 30px 0;
      text-align: center;
      font-size: 18px;
    }
  }

  .buttons-box {
    display: flex;

    width: 100%;
    justify-content: end;
  }

  .btn-icon-30 {
    img {
      width: 30px !important;
    }
  }

  .btn-icon-20 {
    img {
      width: 20px !important;
    }
  }
`;

export const GeneralContainerDash = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  background-color: white;
  border-radius: 24px 24px 0 0;
  padding-bottom: 15px;
  min-height: 100vh;

  .p-30 {
    padding: 30px;
  }

  .tooltipbox {
    position: relative;

    & > div {
      position: absolute;
    }
  }

  .container-return-button {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }

  .container-chart {
  }

  .empty-table-box {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 150px;
      filter: ${theme.colors.primary.mainFilter};
    }

    &.no-filter {
      img {
        filter: none !important;
      }
    }

    span {
      margin: 30px 0;
      text-align: center;
      font-size: 18px;
    }
  }

  .buttons-box {
    display: flex;

    width: 100%;
    justify-content: end;
  }

  .btn-icon-30 {
    img {
      width: 30px !important;
    }
  }

  .btn-icon-20 {
    img {
      width: 20px !important;
    }
  }
`;

export const ContainerData = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding-bottom: 30px;

  .pix-mykeys-image {
    height: 24px;
    filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(0%)
      hue-rotate(135deg) brightness(103%) contrast(105%);
    cursor: pointer;
  }

  .container-btn-more-details {
    position: relative;
    top: 8px;
    left: 75%;
  }

  .copy-icon {
    position: relative;
    top: 8px;
    left: 70%;
  }

  .balances {
    font-size: 42px;
    color: ${() => theme.colors.gray.dark500};
    font-weight: 200;

    @media (max-width: 1600px) {
      font-size: 30px;
    }
  }

  .image-eye {
    height: 24px;
    width: 24px;
    cursor: pointer;
    filter: ${() => theme.colors.base.dark400Filter};
  }

  .future-receivables {
    font-size: 17px;
    color: ${() => theme.colors.primary.main};
    font-weight: bold;
  }

  .future-payments {
    font-size: 17px;
    color: ${() => theme.colors.gray.dark500};
    font-weight: bold;
  }

  .helper {
    color: #929292;
    font-size: 12px;
  }
`;

export const ContainerDetails = styled.div`
  padding: 20px;

  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
    min-width: 120px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;
  }
`;

export const ParentContainer = styled.div`
  min-height: 100%;
  width: 100%;

  .loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background: rgba(0.1, 0.1, 0.1, 0.6);
  }

  ul {
    padding-left: 0;
  }
`;

export const Subtitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  color: ${() => theme.colors.gray.main};
  padding: 0 0 10px 0;
  margin: 0;
`;

export const Title = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;
export const TransfersInfoRenderLabel = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  padding: 0;
  margin: 0;
  span {
    font-size: 12px;
    color: #929292;
  }
`;

export const DashboardActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 96px;
  min-height: 93px;
  border: 1.5px solid ${() => theme.colors.primary.light200};
  background-color: #ffffff;
  border-radius: 8px;

  margin: 0 8px 8px 0;

  &:last-child {
    margin-right: 0px !important;
  }

  cursor: pointer;
  &:hover {
    border: 1.5px solid ${() => theme.colors.primary.light400};
    transition: border 0.5s ease;
  }

  span {
    color: ${() => theme.colors.primary.main};
    font-size: 18px;
  }
`;

export const LabelKey = styled.span`
  font-size: 12px;
  color: #808080;
`;

export const ContainerBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: ${() => theme.colors.gray.light100};

  padding: 40px 25px;

  button[label="Visualizar"] {
    img {
      transform: scale(1.4);
    }
  }
`;

export const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  

  }
`;

export const ImgEye = styled.img`
  height: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;
  filter: ${() => theme.colors.gray.dark500Filter};
  cursor: pointer;
  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.info.mainFilter};
  }
`;

export const ImgSuccess = styled.img`
  height: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;
  cursor: pointer;
  filter: ${() => theme.colors.gray.dark500Filter};

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.success.mainFilter};
  }
`;
