import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  Spacer,
  TabMenu,
} from "@zilliondigital/mirage-ui";
import { useCallback, useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import Skeleton from "~/components/SkeletonBalances";
import {
  ParentContainer,
  ContainerData,
  GeneralContainer,
} from "~/pages/App/app-content.styles";
import styled from "styled-components";
import theme from "~/themes/theme";

import usePermission from "~/hooks/usePermission";
import useAuthority from "~/hooks/useAuthority";
import Copyright from "~/components/Copyright";
import Paginator from "~/components/Paginator";

import SubMenu from "~/components/SubMenu";
import ApiService from "~/services/api";

import useTariffs from "~/hooks/useTariffs";
import useMySpace from "~/hooks/useMySpace";
import useAuth from "~/hooks/useAuth";
import EditTariffs from "./Tariffs/EditTariffs";

const Card = styled.div`
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
  box-shadow: 0px 3px 1px 0px rgb(0 0 0 / 3%);
  padding: 10px;

  .empty-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem 1.5rem 1.5rem;
    flex-direction: column;

    span {
      text-align: center;
      display: block;
      margin: 15px 0;
    }

    img {
      filter: ${theme.colors.primary.mainFilter};
    }

    button {
      width: 100%;
      background: white;

      img {
        width: 15px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;

    .list {
      padding-left: 0px;
      width: 100%;

      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .item-box {
          padding: 10px 1.5rem;

          &:hover {
            background: rgba(0, 0, 0, 0.03);
          }
        }

        .desc {
          margin-bottom: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .footer-box {
    display: flex;
    padding: 15px 1.5rem;
    justify-content: flex-end;

    button {
      background: white;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
`;

const LoadingList = styled.div`
  padding: 0 1rem;
  svg {
    width: 100%;
    height: 70px;
  }
`;

const MySpaceStructure = () => {
  const {
    setStepIndex,
    setNewTariff,
    newTariff,
    setClientsData,
    openEditTariffModal,
    setStepIndexModal,
  } = useTariffs();

  const {
    activeIndexMySpace,
    setActiveIndexMySpace,
    setActiveIndexCollectionsManagement,
  } = useMySpace();

  const { user } = useAuth();

  const location = useLocation();

  const navigate = useNavigate();

  const activeLocalBreadCrumb = [
    { label: "Acessos e Permissões", href: "app/myspace/tax" },
    { label: "Tarifas", href: "app/myspace/tax" },
    { label: "Padrões de cobrança", href: "app/myspace/collection-management" },
  ];

  const menusBreadCrumb = [
    {
      label: "Meu Espaço",
      href: "",
      onClick: () => navigate("/app/myspace/"),
    },
    activeLocalBreadCrumb[activeIndexMySpace],
  ];

  const menusBreadCrumbCollectionManage = [
    {
      label: "Meu Espaço",
      href: "",
      onClick: () => navigate("/app/myspace"),
    },
    {
      label: "Padrões de cobrança",
      href: "",
      onClick: () => navigate("/app/myspace/collection-management"),
    },
    {
      label: "Split",
    },
  ];

  const menusBreadCrumbNewPackpage = [
    {
      label: "Meu Espaço",
      href: "",
      onClick: () => navigate("/app/myspace"),
    },
    {
      label: "Tarifas",
      href: "",
      onClick: () =>
        user?.is_backoffice
          ? navigate("/app/myspace/tariffs")
          : navigate("/app/myspace/tax"),
    },
    {
      label: "Nova Cesta de Tarifas",
    },
  ];

  const menusSub = [
    {
      title: "Acessos e permissões",
      description: "Verifique as contas a qual você possui acesso",
      onClick: () => navigate("/app/myspace/concessions"),
    },
    {
      title: "Tarifas",
      description: "Confira as nossas tarifas",
      onClick: () =>
        user?.is_backoffice
          ? navigate("/app/myspace/tariffs")
          : navigate("/app/myspace/tax"),
    },
    {
      title: "Padrões de cobrança",
      description: "Configure seus padrões",
      onClick: () => navigate("/app/myspace/collection-management"),
    },
  ];

  const routes = [
    "/app/myspace/concessions",
    user?.is_backoffice
      ? "/app/myspace/tariffs" ||
        "/app/myspace/tariffs/" ||
        "/app/myspace/tariffs/new-packpage" ||
        "/app/myspace/tariffs/new-packpage/"
      : "/app/myspace/tax",
    "/app/myspace/collection-management",
  ];

  const splitRoute = location.pathname.includes("split");
  const tariffsRoute = location.pathname.includes("tariffs");
  const newPackpageRoute = location.pathname.includes("new-packpage");

  useEffect(() => {
    if (splitRoute) {
      setActiveIndexMySpace(2);

      setActiveIndexCollectionsManagement(0);
      return;
    } else if (tariffsRoute) {
      setActiveIndexMySpace(1);

      setActiveIndexCollectionsManagement(0);
      return;
    } else {
      setActiveIndexCollectionsManagement(-1);
    }

    const currentPath = location.pathname;
    const index = routes.findIndex((rota) => rota === currentPath);

    setActiveIndexMySpace(index);
  }, [location.pathname]);

  useEffect(() => {
    setNewTariff(false);
    setStepIndex(0);
  }, []);

  return (
    <>
      <ParentContainer>
        <GeneralContainer>
          <div className="d-flex flex-column container-fluid w-100 h-100 ">
            <Spacer top={30} />
            {activeIndexMySpace >= 0 && !splitRoute && !newPackpageRoute && (
              <Breadcrumb
                menus={menusBreadCrumb}
                onClickHome={() => navigate("/app/dashboard")}
              />
            )}

            {splitRoute ? (
              <Breadcrumb
                menus={menusBreadCrumbCollectionManage}
                onClickHome={() => navigate("/app/dashboard")}
              />
            ) : null}

            {newPackpageRoute ? (
              <Breadcrumb
                menus={menusBreadCrumbNewPackpage}
                onClickHome={() => navigate("/app/dashboard")}
              />
            ) : null}

            <Spacer top={30} />
            <div className="container-fluid ">
              <div className="row">
                <div className="col-md-3">
                  <Card>
                    <SubMenu
                      menus={menusSub}
                      activeIndex={
                        activeIndexMySpace >= 0 ? activeIndexMySpace : -1
                      }
                    />
                  </Card>
                </div>

                <div className="col-md-9">
                  <Outlet />
                </div>
              </div>
            </div>
            <div className="pe-5  d-flex justify-content-end">
              <Copyright />
            </div>
          </div>
        </GeneralContainer>
      </ParentContainer>
    </>
  );
};

export default MySpaceStructure;
