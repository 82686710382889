import constate from "constate";
import { useEffect, useState } from "react";

export type AppModals = {
  transferWithPix: undefined;
};

const [ModalProvider, useModal] = constate(() => {
  const [opened, setOpened] = useState<boolean>(false);
  const [modal, setModal] = useState<keyof AppModals>();
  const [props, setProps] = useState<AppModals[keyof AppModals]>();

  const openModalTyped =
    <T extends keyof AppModals>(modal: T) =>
    (props: AppModals[T]) => {
      setOpened(true);
      setModal(modal);
      setProps(props);
    };

  const openModalProps = (
    modal: keyof AppModals,
    props: AppModals[keyof AppModals]
  ) => {
    setOpened(true);
    setModal(modal);
    setProps(props);
  };

  const openModal = (modal: keyof AppModals) => {
    setOpened(true);
    setModal(modal);
  };

  const closeModal = () => {
    setOpened(false);
    setModal(undefined);
    setProps(undefined);
  };

  return {
    opened,
    modal,
    props,
    openModalTyped,
    openModalProps,
    openModal,
    closeModal,
  };
});

export { ModalProvider };

export default useModal;
