import { Spacer } from "@zilliondigital/mirage-ui";
import styled from "styled-components";
import theme from "~/themes/theme";

const ContainerButton = styled.div`
  width: 100%;
  height: 60px;
  background-color: transparent;
  border: 1px solid ${() => theme.colors.primary.main};
  border-radius: 8px;
  cursor: pointer;

  padding: 15px 25px;

  &:hover {
    border: 1px solid ${() => theme.colors.primary.dark200};
  }
`;

const Label = styled.p``;

const Icon = styled.img`
  height: 20px;
  filter: ${() => theme.colors.primary.mainFilter};
`;

const DashButton = (props: {
  label: string;
  icon?: string;
  onClick: () => void;
  iconSize?: string;
}) => {
  const { label, icon, onClick, iconSize } = props;
  return (
    <ContainerButton {...props} onClick={() => onClick && onClick()}>
      <div className="row">
        <Spacer bottom={4} />
        <div className="col-6">
          <Label>{label}</Label>
        </div>

        <div className="col-6 d-flex justify-content-end">
          <Spacer bottom={30} />
          <Icon
            src={icon}
            alt=""
            style={iconSize ? { height: iconSize, marginTop: "2px" } : {}}
          />
        </div>
      </div>
    </ContainerButton>
  );
};

export default DashButton;
