export const statement_operations_dict: Record<string, string> = {
  InternalDischargeAccount: "TEV",
  PreDischargeAccount: "TED",
  PreDebitBoletoOut: "Boleto",
  PreDebitPixOut: "PIX",
  CancelDebitPixOut: "Estorno PIX",
  CreditPixIn: "PIX",
  CreditBoleto: "Recebimento Boleto",
  DayClose: "Fechamento do dia",
  InitialBalance: "Saldo Inicial",
  FinalBalance: "Saldo Final",
};
