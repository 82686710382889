import {
  Button,
  InteractiveModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import TitleModal from "../../../../../components/Texts/TitleModal";
import Description from "../../../../../components/Texts/Description";
import Padding from "../../../../../components/Padding";
import { ActivateCardInputError, DetailsCardInputPassword } from "../styled";
import useCards from "~/hooks/Cards/useCards";
import { Card, CardDetails, CardDetailsSteps, GetCardDetailsStatus } from "~/hooks/Cards/interfaces";
import { BankCardDetailsStep } from "./BankCardDetailsStep";


interface IBankCardDetailsModal {
  getCardDetailsStatus: GetCardDetailsStatus;
  getCardDetailsAsync: (password: string, card_id: string) => Promise<void>;
  cardDetails: CardDetails | null;
  setCardDetailsStep: React.Dispatch<React.SetStateAction<CardDetailsSteps>>;
  cardDetailsStep: CardDetailsSteps;
  selectedCard: Card | undefined; 
  handleCloseFunc: () => void
}
const BankCardDetailsModal = ({
  getCardDetailsStatus,
  getCardDetailsAsync,
  cardDetails,
  setCardDetailsStep,
  cardDetailsStep, 
  selectedCard,
  handleCloseFunc
}: IBankCardDetailsModal) => {
  
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleClose = () => {
    removeDialog();
    setIsOpen(false);
    handleCloseFunc()
  };

  return (
    <InteractiveModal open={isOpen} size="md" toggleModal={handleClose}>
      <Padding top={72} right={62} bottom={72} left={62}>
        <BankCardDetailsStep 
          getCardDetailsStatus = {getCardDetailsStatus}
          getCardDetailsAsync = {getCardDetailsAsync}
          cardDetails = {cardDetails}
          setCardDetailsStep = {setCardDetailsStep}
          cardDetailsStep = {cardDetailsStep}
          selectedCard = {selectedCard}
          handleClose={handleClose}
        />
      </Padding>
    </InteractiveModal>
  );

};

const cardDetailModal = (props: IBankCardDetailsModal) => {
  return new Promise<string>((resolve, reject) => {
    addDialog({...props});
  });
};

const addDialog = (props: IBankCardDetailsModal) => {
  let div = document.getElementById("bank-card-modal-container");
  if (!div) {
    div = document.createElement("div");
    div.id = "bank-card-modal-container";
    document.body.appendChild(div);
  }
  ReactDOM.render(
    <BankCardDetailsModal {...props}/>,
    div
  );
};

const removeDialog = () => {
  const div = document.getElementById("bank-card-modal-container");
  if (div) {
    try {
      ReactDOM.unmountComponentAtNode(div);
      if (div.parentNode && div.parentNode.contains(div)) {
        div.parentNode.removeChild(div);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export default cardDetailModal;
