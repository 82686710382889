import { Spacer, Table } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import styled from "styled-components";
import SupplierIcon from "~/assets/icons/contacts/supplier.png";
import { useEffect, useMemo, useState } from "react";
import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";
import ColaboratteOrCostumerIcon from "~/assets/icons/contacts/collaborate-or-costumer.png";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import EmailIcon from "~/assets/icons/contacts/Ic_context_email.png";
import PhoneIcon from "~/assets/icons/contacts/Ic_context_telephone.png";
import AddressIcon from "~/assets/icons/contacts/Ic_context_pin.png";
import ApiService from "~/services/api";
import useContacts from "~/hooks/Contacts/useContacts";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import { formatCPFCNPJ } from "~/utils";

import { getFirstLetters } from "~/services/utils";
import LoaderContactsList from "~/pages/App/Transfers/NewTransfer/LoaderContactsList";
import useCollections from "~/hooks/Collections/useCollections";
import useTransfers from "~/hooks/Transfers/useTransfers";
import theme from "~/themes/theme";
import label from "~/labels";
import useSplit from "~/hooks/useSplit";
import { useNavigate } from "react-router-dom";

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .rounded-circle {
    width: 40px;
  }

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }
`;

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }
`;

const ContainerImageDisabled = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }
`;

type renderImageRoleType = {
  [key: string]: string;
};

const renderIconRole: renderImageRoleType = {
  supplier: SupplierIcon,
  customer: ColaboratteOrCostumerIcon,
  collaborate: ColaboratteOrCostumerIcon,
};

const TableContacts = () => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { openNewSplitModal } = useSplit();
  const { setContactInfo } = useNewCollection();

  const [selectedRows, setSelectedRow] = useState<any[]>([]);
  const [disabledRows, setDisabledRows] = useState<any[]>([]);
  const [idsCanNotBeSelected, setIdsCanNotBeSelected] = useState<any[]>([]);

  const { setNewPartnerSplitData, newSplitPayload } = useSplit();

  const {
    setContactsData,
    contactsData,
    contactsFiltered,
    isLoadingTableContacts,

    setIsLoadingTableContacts,
  } = useContacts();
  const { setNewTransferTEDData, newTransferTEDData } = useNewTransfer();
  const { openNewTransferModal, openNewTransferModalInDash } = useTransfers();
  const { setOpenNewSplitModal } = useSplit();
  const {
    setOpenNewCollectionOrRecurrenceModal,
    setOpenNewCollectionOrRecurrenceModalInDash,
  } = useCollections();

  const { setOpenNewTransferModal } = useTransfers();
  const { receivers } = useSplit();
  const navigate = useNavigate();

  const {
    openNewCollectionOrRecurrenceModal,
    openNewCollectionOrRecurrenceModalInDash,
  } = useCollections();

  const isCollection =
    openNewCollectionOrRecurrenceModal ||
    openNewCollectionOrRecurrenceModalInDash;

  const isTransfers = openNewTransferModal || openNewTransferModalInDash;
  const isSplit = openNewSplitModal;

  const getContacts = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "partner/",
        token: true,
        params: {
          type: "contact",
          default: false,
        },
      });

      setContactsData(data.results);
      setIsLoadingTableContacts(false);
    } catch {
      setIsLoadingTableContacts(false);
    }
  };

  const dataContacts = contactsData.map((contact) => {
    return {
      id: contact.id,
      name: contact.name,
      profile_picture: contact.profile_picture,
      email: contact.email,
      phone: contact.mobile_phone,
      role: contact.role,
      document_number: contact.document_number,
      address: contact.all_address,
      bank_accounts: contact.bank_accounts,
    };
  });

  const indexesDataContactsWithoutAddress = useMemo(
    () =>
      dataContacts
        .map((contact, index) =>
          contact.address.filter(
            (a: any) => a.type === "billing" || a.type === "residential"
          ).length <= 0
            ? index
            : -1
        )
        .filter((index) => index !== -1),
    [dataContacts]
  );

  const idsDataContactsWithoutAddress = useMemo(
    () =>
      dataContacts
        .map((contact) =>
          contact.address.filter(
            (a: any) => a.type === "billing" || a.type === "residential"
          ).length <= 0
            ? contact.id
            : -1
        )
        .filter((id) => id !== -1),
    [dataContacts]
  );

  const indexesDataContactsWithoutBankAccount = useMemo(
    () =>
      dataContacts
        .map((contact, index) =>
          contact.bank_accounts.length <= 0 ? index : -1
        )
        .filter((index) => index !== -1),
    [dataContacts]
  );

  const indexesDataContactsAlreadyAddInSplit = useMemo(
    () =>
      dataContacts
        ?.map((contact, index) =>
          receivers?.some((receiver: any) => receiver.contact === contact.id)
            ? index
            : -1
        )
        .filter((index) => index !== -1),
    [dataContacts, receivers]
  );

  const idsDataContactsWithoutBankAccount = useMemo(
    () =>
      dataContacts
        .map((contact) => (contact.bank_accounts.length <= 0 ? contact.id : -1))
        .filter((id) => id !== -1),
    [dataContacts]
  );
  const idsDataContactsAlreadyAddInSplit = useMemo(() => {
    const contactIds = new Set(
      dataContacts
        .filter((contact) =>
          receivers?.some((receiver: any) => receiver.contact === contact.id)
        )
        .map((contact) => contact.id)
    );
    return Array.from(contactIds);
  }, [dataContacts, receivers]);

  useEffect(() => {
    setIsLoadingTableContacts(true);
    getContacts();

    if (isCollection) {
      setDisabledRows(indexesDataContactsWithoutAddress);
      setIdsCanNotBeSelected(idsDataContactsWithoutAddress);
    } else if (isTransfers) {
      setDisabledRows(indexesDataContactsWithoutBankAccount);
      setIdsCanNotBeSelected(idsDataContactsWithoutBankAccount);
    } else if (isSplit) {
      setDisabledRows(indexesDataContactsAlreadyAddInSplit);
      setIdsCanNotBeSelected(idsDataContactsAlreadyAddInSplit);
    }
  }, []);

  useEffect(() => {
    if (isSplit) {
      const selectedContact = contactsData.filter(
        (contactsData: any) =>
          !idsDataContactsAlreadyAddInSplit.includes(contactsData.id) &&
          contactsData.id === selectedIndex
      );
      setNewTransferTEDData(selectedContact[0]);

      setContactInfo({
        id: selectedContact[0]?.id,
        name: selectedContact[0]?.name,
        document_number: selectedContact[0]?.document_number,
      });
    } else if (isTransfers) {
      const selectedContact = contactsData.filter(
        (contactsData: any) =>
          !idsDataContactsWithoutBankAccount.includes(contactsData.id) &&
          contactsData.id === selectedIndex
      );
      setNewTransferTEDData(selectedContact[0]);

      setContactInfo({
        id: selectedContact[0]?.id,
        name: selectedContact[0]?.name,
        document_number: selectedContact[0]?.document_number,
      });
    } else if (isCollection) {
      const selectedContact = contactsData.filter(
        (contactsData: any) =>
          !idsDataContactsWithoutAddress.includes(contactsData.id) &&
          contactsData.id === selectedIndex
      );
      setNewTransferTEDData(selectedContact[0]);

      setContactInfo({
        id: selectedContact[0]?.id,
        name: selectedContact[0]?.name,
        document_number: selectedContact[0]?.document_number,
      });
    }
  }, [selectedIndex]);

  const filteredContacts = contactsFiltered.map((contact) => {
    return {
      id: contact.id,
      name: contact.name,
      profile_picture: contact.profile_picture,
      email: contact.email,
      phone: contact.mobile_phone,
      role: contact.role,
      document_number: contact.document_number,
      address: contact.all_address,
      bank_accounts: contact.bank_accounts,
    };
  });

  const SelectContact = (id: number) => {
    setSelectedIndex(id);

    const idContactSelected = contactsData.findIndex(
      (contact) => contact.id === id
    );

    setSelectedRow([idContactSelected]);
  };

  const handleClickContact = (data: any) => {
    if (idsCanNotBeSelected.includes(data.id)) {
      return;
    }

    const validAddresses = data.address.filter(
      (a: any) => a.type === "billing" || a.type === "residential"
    );

    if (
      (isCollection && validAddresses.length > 0) ||
      (isTransfers && data.bank_accounts.length > 0)
    ) {
      SelectContact(selectedIndex === data.id ? -1 : data.id);
    } else {
      SelectContact(data.id);
    }
  };

  const columnContacts = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any, index: number) => {
        const validAddresses = data.address.filter(
          (a: any) => a.type === "billing" || a.type === "residential"
        );

        return (
          <ContainerRenderData
            style={
              (isCollection && validAddresses.length <= 0) ||
              (isTransfers && data.bank_accounts <= 0) ||
              (isSplit && idsDataContactsAlreadyAddInSplit.includes(data.id))
                ? { cursor: "not-allowed" }
                : { cursor: "pointer" }
            }
            onClick={() => {
              handleClickContact(data);
              setNewPartnerSplitData({
                contact: data.id,
                name: data.name,
                document_number: data.document_number,
              });
            }}
          >
            <div className="d-flex align-items-center">
              {(isCollection &&
                selectedIndex === data.id &&
                validAddresses.length > 0) ||
              (isTransfers &&
                selectedIndex === data.id &&
                data.bank_accounts.length > 0) ? (
                <ContainerImage>
                  <div className="contact-selected-image">
                    <img
                      className="selected-contact-image"
                      src={SelectedContactIcon}
                    />
                  </div>
                </ContainerImage>
              ) : (isCollection && validAddresses.length <= 0) ||
                (isTransfers && data.bank_accounts <= 0) ||
                (isSplit &&
                  idsDataContactsAlreadyAddInSplit.includes(data.id)) ? (
                <ContainerImageDisabled>
                  <div className="container-profile-picture">
                    <div
                      className="rounded-circle profile-picture"
                      style={{ background: "#eeecec" }}
                    >
                      <h1 style={{ color: "#acacac" }}>
                        {getFirstLetters(data.name)}
                      </h1>
                    </div>
                  </div>
                </ContainerImageDisabled>
              ) : !isCollection && !isTransfers && selectedIndex === data.id ? (
                <ContainerImage>
                  <div className="contact-selected-image">
                    <img
                      className="selected-contact-image"
                      src={SelectedContactIcon}
                    />
                  </div>
                </ContainerImage>
              ) : (
                <ContainerImage>
                  <div className="container-profile-picture">
                    <div className="rounded-circle profile-picture">
                      <h1>{getFirstLetters(data.name)}</h1>
                    </div>
                  </div>
                </ContainerImage>
              )}
              <Spacer left={10} />
              <div className="d-flex flex-column">
                <Title>{data.name}</Title>
                <Description color="#929292">
                  {formatCPFCNPJ(data.document_number)}
                </Description>
              </div>
            </div>

            <div className="pb-2 d-flex align-items-center">
              <div>
                <img src={renderIconRole[data.role]} />
              </div>

              {data.phone && (
                <>
                  <Spacer left={10} />
                  <div>
                    <img src={PhoneIcon} />
                  </div>
                </>
              )}

              {data.email && (
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <img src={EmailIcon} />
                  </div>
                </>
              )}

              {validAddresses.length > 0 && (
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <img src={AddressIcon} />
                  </div>
                </>
              )}
            </div>
          </ContainerRenderData>
        );
      },
    },
  ];

  const handleNavigateToContactsArea = () => {
    navigate("/app/contacts");
    setOpenNewSplitModal(false);
    setOpenNewCollectionOrRecurrenceModal(false);
    setOpenNewCollectionOrRecurrenceModalInDash(false);
    setOpenNewTransferModal(false);
  };

  return (
    <>
      {isLoadingTableContacts && dataContacts.length === 0 ? (
        <LoaderContactsList />
      ) : dataContacts.length > 0 ? (
        <Table
          data={filteredContacts.length > 0 ? filteredContacts : dataContacts}
          columns={columnContacts}
          dataSpacer={10}
          selectedRows={selectedRows}
          disabledRows={disabledRows}
          haveHeader={false}
        />
      ) : (
        <div className="text-center mb-4">
          <Spacer bottom={70} />
          <Description>
            Você não possui contatos cadastrados <br />
            <span
              onClick={() => handleNavigateToContactsArea()}
              style={{
                color: theme.colors.secondary.main,
                cursor: "pointer",
              }}
            >
              Ir para contatos.
            </span>
          </Description>
        </div>
      )}
    </>
  );
};

export default TableContacts;
