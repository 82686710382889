import { Card, getDebitCardsDTO } from "~/hooks/Cards/interfaces";

export const mockGetCardsEmpty: getDebitCardsDTO = {
  "payload": {
    "success": true,
    "cards": []
  },
  "error": null
}

export const mockGetCards: getDebitCardsDTO = {
  "payload": {
    "success": true,
    "cards": [
      {
        "card_id": "a99d5a83-82e7-40a6-aa14-a6cba7ab4ca9",
        "name": "Cartao do Ester",
        "printed_name": "Ester S A Silva",
        "card_number": "**** **** **** 7418",
        "card_type": "PLASTIC",
        "status": "ACTIVATED",
        "transaction_limit": 10000,
        "contactless_enabled": true
      },
      {
        "card_id": "a99d5a83-82e7-40a6-aa14-a6cba7ab4ca9",
        "name": "Cartao do Ester",
        "printed_name": "Ester S A Silva",
        "card_number": "**** **** **** 7418",
        "card_type": "VIRTUAL",
        "status": "ACTIVATED",
        "transaction_limit": 10000
      },
      {
        "card_id": "7d5ed45a-c0e7-491d-8fa3-63afdd0e12f2",
        "name": "Cartao do Ester Virtual",
        "printed_name": "Ester S A Silva",
        "card_number": "**** **** **** 3374",
        "card_type": "VIRTUAL",
        "status": "PROCESSING",
        "transaction_limit": 10000
      },
      {
        "card_id": "jdis012k-82e7-40a6-aa14-a6cba7ab4ca9",
        "name": "Cartao reserva",
        "printed_name": "Ester S A Silva",
        "card_number": "**** **** **** 7418",
        "card_type": "PLASTIC",
        "status": "BLOCKED",
        "transaction_limit": 10000
      },
    ]
  },
  "error": null
}

export const mockGetCardsError =  {
  "code": "GAT-01",
  "message": "Digital Eazy API returned an error",
  "detail": {
    "status": "error",
    "message": ""
  }
}