import React, {ComponentType} from 'react';

/**
 * Uma curry que recebe um componente `Provider`, um segundo componente que será
 * encapsulado pelo `Provider` e retorna um componente cujas props são as props
 * do componente encapsulado com `providerProps` para as props do provider.
 * @example
 * const LoginWithSubscribe = withProvider(SubscribeProvider)(Login);
 * <LoginWithSubscribe {...{
 *   ...propsOfLogin,
 *   providerProps: { ...propsOfSubscribeProvider }
 * }} />
 * @param Provider - Componente `Provider`, geralmente obtido de um `Context`.
 */
const withProvider = <ProviderProps extends {}>(
  Provider: ComponentType<ProviderProps>,
) => <Props extends {}>(Component: ComponentType<Props>) => (
  props: Props & ProviderProps,
) => (
  <Provider {...(props as ProviderProps)}>
    <Component {...(props as Props)} />
  </Provider>
);

export default withProvider;
