import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  Spacer,
  SummaryPanel,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import CloudIcon from "~/assets/icons/buttons/cloud.svg";

import { useEffect, useMemo, useState } from "react";
import RefreshIcon from "~/assets/icons/buttons/Ic_functional_refresh.png";
import FilterIcon from "~/assets/icons/buttons/filter_alt_black_24dp.png";

import { useNavigate } from "react-router-dom";
import Copyright from "~/components/Copyright";
import LastDaysFilter from "~/components/LastDaysFilter";
import Paginator from "~/components/Paginator";
import useAccount from "~/hooks/useAccount";
import useNotifications from "~/hooks/useNotifications";
import useStatements from "~/hooks/useStatements";
import useToast from "~/hooks/useToast";
import { ParentContainer } from "~/pages/App/app-content.styles";
import ApiService from "~/services/api";
import { getDateInfo } from "~/services/utils";

import theme from "~/themes/theme";

import { format, subDays } from "date-fns";
import ExportStatementsModal from "./ExportStatmentsModal";
import { formatNumberToCurrencyWithoutSimbol, getToday } from "~/utils";
import StatementsDetailsModal from "./StatementsDetails/StatementsDetailsModal";
import StatementsFilter from "./StatementsFilter";
import StatementsTransactions from "./StatementsTransactions";

const GeneralContainerData = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: 100%;

  width: 100%;
  flex-grow: 1;

  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;
`;

const ContainerData = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  min-height: 100%;
`;

const Title = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

// const calculatePercentage = (value: number, total: number) => {
//   return (value / total) * 100;
// };

const StatementsStructure = () => {
  const {
    statements,
    selectedVouchers,
    setSelectedVouchers,
    openStatementsDetailsModal,
    isLoadingTransactions,
    openFilterModal,
    statistics,
    statusColumnSelected,
    setStatusColumnSelected,

    setRowsPerPage,
    rowsPerPage,
    page,
    setPage,

    getStatements,
    setOpenExportStatmentsModal,
    setOpenFilterModal,
    setFetchParams,
  } = useStatements();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const {
    formatNumberToCurrency,
    balanceFormatted,
    mainAccount,
    getBalance,
    beforeDays,
  } = useAccount();
  const { showToast } = useToast();
  const [statisticsStatements, setStatisticsStatements] = useState<any>([]);

  const getStatistics = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/statement-statistics/",

        token: true,
      });

      setStatisticsStatements(data);
    } catch {}
  };

  const data = statements.map((transaction: any, index: number) => ({
    ...transaction,
    tags: transaction.tags,
    status: "paid",
    type_id: transaction.operation_type_id,
    description: transaction.description,
    identifier: transaction.identifier,
    amount: transaction.amount,
    voucher: transaction.voucher_url,
    movement_type: transaction.movement_type,

    type: transaction.operation_type,
    index: index,
  }));
  const handleSelectedAll = () => {
    const transactions = data;

    const selectedVouchersForStatusColumn = transactions
      .filter(
        (transaction) =>
          transaction.voucher !== null && transaction.voucher !== undefined
      )
      .map((transaction) => transaction.voucher);

    // Adiciona vouchers válidos à lista existente, garantindo que não haja duplicatas
    const newSelectedVouchers = Array.from(
      new Set([...selectedVouchers, ...selectedVouchersForStatusColumn])
    );

    setSelectedVouchers(newSelectedVouchers);
    setStatusColumnSelected(true);
  };

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  useEffect(() => {
    getBalance();
    getStatistics();
    getStatements({ from_date: fromDate, to_date: todayFormatted });
  }, [mainAccount]);

  const statementsPanel: any = useMemo(() => {
    const statementPanelArr = [
      {
        label: "Saldo atual",
        balance: balanceFormatted,
        toggleBalance: true,
      },

      {
        label: "Entradas",
        balance: "",
        toggleBalance: true,
        currency: "R$",
      },

      {
        label: "Saídas",
        balance: "",
        toggleBalance: true,
        currency: "-R$",
      },
    ];

    if (statistics) {
      statementPanelArr[1].balance = formatNumberToCurrency(
        statistics.total_in_amount?.toFixed(2) ?? 0
      );
      statementPanelArr[2].balance = `-R$ ${formatNumberToCurrencyWithoutSimbol(
        statistics.total_out_amount?.toFixed(2) ?? 0
      )}`;
    }

    return statementPanelArr;
  }, [statements]);

  const sendVouchers = async () => {
    setIsLoading(true);

    const vouchersWithoutNulls = selectedVouchers.filter(
      (item: any) => item !== null
    );

    if (vouchersWithoutNulls?.length > 0) {
      try {
        const { data } = await ApiService.HttpPost({
          route: "download-external/zip/",
          body: vouchersWithoutNulls,
          token: true,
        });

        setIsLoading(false);
        setStatusColumnSelected(false);
        window.open(data.data, "_blank");
        setSelectedVouchers([]);
        showToast(
          "Sucesso",
          "O download foi concluído e já está disponível",
          "success"
        );
      } catch (e) {
        console.warn(e);
        setIsLoading(false);
        showToast(
          "Ops!",
          "Parece que algo deu errado, tente novamente mais tarde",
          "error"
        );
      }
    } else {
      showToast(
        "Algo deu errado!",
        "O item selecionado não possui comprovante para ser baixado",
        "warning"
      );
      setIsLoading(false);
    }
  };

  const quantityPages = [
    {
      label: "20 por página",
      value: "1",
      onClick: () => {
        setRowsPerPage(20);
      },
    },
    {
      label: "50 por página",
      value: "2",
      onClick: () => {
        setRowsPerPage(50);
      },
    },
    {
      label: "100 por página",
      value: "3",
      onClick: () => {
        setRowsPerPage(100);
      },
    },
  ];

  const { setNotifications, notifications } = useNotifications();

  // const getNotifications = async () => {
  //   try {
  //     const { data } = await ApiService.HttpGet({
  //       route: "notification/",
  //       token: true,
  //     });

  //     setNotifications(data.results);

  //     setTimeout(() => {
  //       showToast(
  //         notifications[0]?.title,
  //         notifications[0]?.message,
  //         "success",
  //         () => {
  //           window.open(notifications[0]?.param, "_self");
  //           setOpenToast(false);
  //         }
  //       );
  //     }, 300);
  //   } catch {}
  // };

  const dateInfo = getDateInfo();

  const generateStatementsDownload = async (type: string) => {
    try {
      const route = type == "ofx" ? "charge/statement-ofx" : "charge/statement";

      const { data } = await ApiService.HttpGet({
        route: route,
        token: true,
        params: {
          first_day: dateInfo.firstDayOfMonth,
          last_day: dateInfo.lastDayOfMonth,
          month: dateInfo.currentMonth,
          year: dateInfo.currentYear,
          export: type,
        },
      });

      if (type === "ofx") {
        {
          window.open(data.data.url, "_self");
        }
      }

      showToast(
        " Gerando relatório...",
        "Estamos gerando seu relatório e em breve você será notificado.",
        "success"
      );

      if (type !== "ofx") {
        {
          // setTimeout(() => {
          //   getNotifications();
          // }, 10020);
        }
      }
    } catch (error) {
      console.warn("err", error);
      showToast(
        "Ops, ocorreu um erro!",
        "Não foi possível gerar o extrato, tente novamente mais tarde ",
        "error"
      );
    }
  };

  const menusBreadCrumb = [{ label: "Extrato" }];

  return (
    <ParentContainer
      className={
        openFilterModal || openStatementsDetailsModal ? "overflow-hidden" : ""
      }
    >
      {" "}
      <GeneralContainerData>
        <div className="d-flex flex-column container-fluid ps-5 pe-5 w-100 h-100 ">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />
          <Spacer top={30} />
          <ContainerData className="ps-4 pe-4">
            <div className="d-flex justify-content-between p-4 ">
              <Title>Extrato</Title>
              <div>
                {statements.length > 0 ? (
                  <div className="d-flex flex-column flex-lg-row">
                    {selectedVouchers.length > 0 ? (
                      <>
                        <Button
                          mode="border"
                          icon={CloudIcon}
                          loading={isLoading}
                          iconColorFilter={theme.colors.primary.mainFilter}
                          label={
                            selectedVouchers.length > 1
                              ? "Baixar comprovantes em lote"
                              : "Baixar comprovante"
                          }
                          onClick={() => sendVouchers()}
                        />
                        <Spacer right={10} />
                      </>
                    ) : null}

                    <div className="d-block d-lg-none">
                      <Spacer bottom={10} />
                    </div>

                    <Spacer right={10} />
                    <Button
                      mode="border"
                      onClick={() => setOpenFilterModal(true)}
                      label="Aplicar filtros"
                      icon={FilterIcon}
                      iconColorFilter={theme.colors.primary.mainFilter}
                    />
                    <Spacer right={10} />
                  </div>
                ) : null}{" "}
              </div>
            </div>

            <div className="ps-4 pe-4">
              {statements.length > 0 || isLoadingTransactions ? (
                <div className="row">
                  <SummaryPanel
                    panel={statementsPanel}
                    isLoading={isLoadingTransactions}
                  />
                </div>
              ) : null}

              <Spacer bottom={20} />
              <div className="d-flex w-100 align-items-start  ">
                <>
                  <div className="">
                    <Button
                      mode="border"
                      label="Atualizar"
                      icon={RefreshIcon}
                      iconColorFilter={theme.colors.primary.mainFilter}
                      onClick={() => {
                        getStatements({
                          from_date: fromDate,
                          to_date: todayFormatted,
                        });
                        getStatistics();
                      }}
                    />
                  </div>
                  <Spacer left={15} />
                </>

                {statements.length > 0 ? (
                  <div>
                    <ButtonDropdown
                      mode="border"
                      label={`${rowsPerPage} por página`}
                      options={quantityPages}
                    />
                  </div>
                ) : null}

                <div style={{ marginLeft: "auto" }} className="d-flex">
                  <div>
                    <Button
                      mode="border"
                      icon={CloudIcon}
                      label="Exportar extrato"
                      iconColorFilter={theme.colors.primary.mainFilter}
                      onClick={() => setOpenExportStatmentsModal(true)}
                    />
                  </div>
                  <Spacer left={10} />
                  <div>
                    <LastDaysFilter
                      setter={(_from, _to) => {
                        setFetchParams({ from_date: _from, to_date: _to });
                      }}
                      executeFetch={getStatements}
                    />
                  </div>
                  <Spacer left={10} />
                  <Button
                    label={"Selecionar todos"}
                    mode="border"
                    onClick={() => handleSelectedAll()}
                  />
                </div>
              </div>
            </div>

            <Spacer bottom={isLoadingTransactions ? 50 : 20} />
            <div className="ps-3 pe-3">
              <StatementsTransactions />
            </div>
          </ContainerData>
          <div className={statements.length > 0 ? "" : "d-none"}>
            <Paginator
              list={statements.length > 0 ? statements : []}
              rowsPerPage={rowsPerPage}
              page={page}
              pageSetter={setPage}
            />
          </div>
          <Spacer top={statements.length > 0 ? 2 : 40} />

          <Copyright />

          <Spacer top={30} />
        </div>
      </GeneralContainerData>
      <StatementsDetailsModal />
      <StatementsFilter />
      <ExportStatementsModal />
    </ParentContainer>
  );
};

export default StatementsStructure;
