import "bootstrap/dist/css/bootstrap.min.css";
import { Spacer } from "@zilliondigital/mirage-ui";
import Timer from "~/assets/icons/figure_3d_time.png";

import Title from "~/components/Texts/Title";
import Description from "~/components/Texts/Description";
import styled from "styled-components";
import useTransfers from "~/hooks/Transfers/useTransfers";

const Rounded = styled.div`
  width: 96px;
  height: 96px;
  background-color: #ffe5d8;
  position: relative;

  img {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`;

const NoFindContacts = () => {
  const { filters } = useTransfers();

  return (
    <div className="w-100  pt-3  d-flex flex-column align-items-center justify content-start">
      {filters.length > 0 && <Spacer bottom={50} />}
      <Rounded className="rounded-circle">
        {" "}
        <img src={Timer} />
      </Rounded>

      <Spacer bottom={filters.length > 0 ? 20 : 60} />
      <Title>
        {filters.length > 0
          ? "Nenhum contato encontrado"
          : "   Nenhum cadastro encontrado"}
      </Title>
      {filters.length <= 0 && (
        <>
          <Spacer bottom={10} />
          <Description className="text-center">
            Não foi encontrado nenhum cadastro com esse CPF ou nome
          </Description>
        </>
      )}
      <Spacer bottom={100} />
    </div>
  );
};

export default NoFindContacts;
