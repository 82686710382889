import { Fragment, useEffect } from "react";
import Title from "~/components/Texts/Title";
import { ActivateCardCheckingButtonsDiv, ActivateCardCheckingImg, ActivateCardCheckingStepDiv, ActivateCardTitle, ActivateCardContainer, CardsDashboardHeader } from "./styled";
import useAccount from "~/hooks/useAccount";
import useCards from "~/hooks/Cards/useCards";
import { Button } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import CardImg from '~/assets/images/credit--card.svg'
import { BlockCardSteps } from "~/hooks/Cards/interfaces";
import { ContainerData } from "../../app-content.styles";
import BlockCardCheckingStep from "./section/BlockCardCheckingStep";
import BlockCardConfirmationStep from "./section/BlockCardConfirmationStep";
import BlockCardBlockedStep from "./section/BlockCardBlockedStep";


const MyCardsBlockContainer = (props: {}) => {
  const navigate = useNavigate()
  const {
    selectedCard,
    setSubPage,
    blockCardStep,
    setBlockCardStep,
    setBlockingCardStatus,
  } = useCards()
  
  useEffect(() => {
    setSubPage({name: 'Bloquear', route: '/app/cards/block'})
    
    if(selectedCard?.status != 'ACTIVATED'){
      navigate('/app/cards')
    }else{
      setBlockCardStep('CHECKING')
      setBlockingCardStatus(null)
    }
  }, [])

  const _returnStepComponent = (step: BlockCardSteps) => {
    if(!selectedCard){
      return <></>
    }
    switch (step){
      case 'CHECKING':
        return (
          <BlockCardCheckingStep 
            blockCardStep={blockCardStep}
            setBlockCardStep={setBlockCardStep}
          />
        )
      
      case 'CONFIRMATION':
        return (
          <BlockCardConfirmationStep 
            blockCardStep={blockCardStep}
            setBlockCardStep={setBlockCardStep}
          />
        )
      
      case 'BLOCKED':
        return (
          <BlockCardBlockedStep />
        )
    }
  }

  return (
    <Fragment>
      <ContainerData className="container">
        <CardsDashboardHeader>
          <Title>Bloquear meu cartão</Title>
        </CardsDashboardHeader>
        <div className="col-md-8 w-100 d-flex flex-column px-4">
          <ActivateCardContainer>
            {_returnStepComponent(blockCardStep)}
          </ActivateCardContainer>
        </div>
      </ContainerData>
    </Fragment>
  );
};

export default MyCardsBlockContainer;
 