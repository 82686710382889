export type PixLimitsProps = {
  type: string;
  description: string;
  diurnalLimit: number;
  noturnalLimit: number;
  diurnalTime?: string;
  noturnalTime?: string;
};

export const pixMyLimits: PixLimitsProps[] = [
  {
    type: "1",
    description: "Transferência para mesma titularidade",
    diurnalLimit: 5000,
    noturnalLimit: 2000,
    diurnalTime: "06h - 21:59",
    noturnalTime: "22h - 05:59",
  },
  {
    type: "2",
    description: "Transferência para outra titularidade",
    diurnalLimit: 5000,
    noturnalLimit: 2000,
    diurnalTime: "06h - 21:59",
    noturnalTime: "22h - 05:59",
  },
  {
    type: "3",
    description: "Pix saque e troco",
    diurnalLimit: 5000,
    noturnalLimit: 2000,
    diurnalTime: "06h - 21:59",
    noturnalTime: "22h - 05:59",
  },
];
