import { format, parseISO } from "date-fns";

import { dateENRegex } from "~/services/validators";

export const convertedAmount = (str: string) => {
  const penultimateCharIndex = str.length - 2;
  const formattedValue =
    str.slice(0, penultimateCharIndex) + "." + str.slice(penultimateCharIndex);
  return Number(formattedValue);
};

export const getToday = (): string => {
  const dataAtual = new Date();
  const ano = dataAtual.getFullYear();
  const mes = `0${dataAtual.getMonth() + 1}`.slice(-2); // Adiciona '0' à esquerda se necessário e pega os dois últimos dígitos
  const dia = `0${dataAtual.getDate()}`.slice(-2); // Adiciona '0' à esquerda se necessário e pega os dois últimos dígitos
  return `${ano}-${mes}-${dia}`;
};

export const formatNumberToCurrencyWithoutSimbol = (
  input: string | number
): string => {
  if (input === undefined || input === null || input === "") {
    return "0,00";
  }

  let value = typeof input === "number" ? input.toString() : input;

  const parts = value.split(".");
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? parts[1] : "";

  decimalPart = decimalPart.padEnd(2, "0");

  // Ensure integerPart is a string to call .replace on it
  if (integerPart) {
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    // If integerPart is somehow not a string (shouldn't happen with current logic), return a default or handle as needed
    return "0,00";
  }

  value = `${integerPart},${decimalPart}`;

  return value;
};

export const formatCPFCNPJ = (value: string): string => {
  if (!value) return value;

  const cleanedValue = value.replace(/\D/g, "");

  if (cleanedValue.length === 11) {
    return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (cleanedValue.length === 14) {
    return cleanedValue.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  return value;
};

export function formatDateTime(dateTimeString: string): string {
  const dateTime = new Date(dateTimeString);

  const day = String(dateTime.getDate()).padStart(2, "0");
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const year = String(dateTime.getFullYear()).slice(2, 4);

  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
}

export function formatDateTimeNew(dateTimeString: string): string {
  const dateTime = new Date(dateTimeString);

  const day = String(dateTime.getDate()).padStart(2, "0");
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const year = String(dateTime.getFullYear()).slice(2, 4);

  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year} `;
}

export function formatDate(
  dateTimeString: string,
  type: "datetime" | "date" = "date"
): string {
  const dateTime = new Date(dateTimeString);
  const brtOffset = -3 * 60;
  dateTime.setMinutes(dateTime.getMinutes() - brtOffset);

  if (type === "datetime") {
    const day = String(dateTime.getDate()).padStart(2, "0");
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const year = dateTime.getFullYear();

    const hours = String(dateTime.getHours()).padStart(2, "0");
    const minutes = String(dateTime.getMinutes()).padStart(2, "0");
    const seconds = String(dateTime.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
  }

  const day = String(dateTime.getDate()).padStart(2, "0");
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const year = dateTime.getFullYear();

  return `${day}/${month}/${year}`;
}

export function dateFormatterBR(dateString: string): string {
  const date = new Date(dateString);

  return new Intl.DateTimeFormat("pt-BR", {
    dateStyle: "short",
  }).format(date);
}

export const getCurrentDate = (): string => {
  const timeZoneOffset = -3 * 60; // Fuso horário de Brasília em minutos (-3 horas)
  const currentDate = new Date();

  // Ajusta a data para o fuso horário de Brasília
  currentDate.setMinutes(currentDate.getMinutes() + timeZoneOffset);

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
// export const formatDateStatements = (isoDate: string): string => {
//   const date = new Date(isoDate);
//   const day = date.getDate().toString().padStart(2, "0");
//   const month = (date.getMonth() + 1).toString().padStart(2, "0");
//   const year = date.getFullYear();
//   return `${day}/${month}/${year}`;
// };

export const DownloadReceipt = async (string: string): Promise<void> => {
  const fileURL = string;

  fetch("https://cors-anywhere.herokuapp.com/" + fileURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Extrato de transferência.pdf`);

      document.body.appendChild(link);

      link.click();
    });
};

export const removeHyphens = (input: string | number): string => {
  if (typeof input === "number") {
    input = input.toString();
  }

  const result = input.replace(/-/g, "");

  return result;
};

export function convertStringToNumber(value: string): number {
  const sanitizedValue = value?.replace(/\./g, "")?.replace(",", ".");

  const numberValue = parseFloat(sanitizedValue);

  return isNaN(numberValue) ? 0 : numberValue;
}

export const getFirstAndLastDayOfMonth = (): [string, string] => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const firstDay = new Date(year, month - 1, 1);
  const lastDay = new Date(year, month, 0);

  const formattedFirstDay = `${year}-${month
    .toString()
    .padStart(2, "0")}-${firstDay.getDate().toString().padStart(2, "0")}`;
  const formattedLastDay = `${year}-${month
    .toString()
    .padStart(2, "0")}-${lastDay.getDate().toString().padStart(2, "0")}`;

  return [formattedFirstDay, formattedLastDay];
};

export const dueDateIsValid = (dateString: string): boolean => {
  const currentDate = new Date();
  const inputDate = new Date(dateString);

  if (isNaN(inputDate.getTime())) {
    return false;
  }

  return inputDate >= currentDate;
};

export const formatDateTOStringEN = (_date: Date): string => {
  return format(_date, "yyyy-MM-dd");
};

export const formatStringDateENToBR = (_date: string): string => {
  if (_date === "") return "";
  return _date.split("-").reverse().join("/");
};

export const formatCash = (value: number = 0) => {
  if (value) {
    return value.toLocaleString("pt-BR", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  }
  return "0";
};

export const arrayDiff = (a1: any[], a2: any[]): any[] => {
  var a = [],
    diff = [];

  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
};

export const onlyNumbers = (str = "") => str.replace(/[^0-9]/g, "");

export const getDocumentType = (document: string = ""): string => {
  const documentNumbers = onlyNumbers(document);
  const documentLength = documentNumbers.length;
  return documentLength > 11 ? "cnpj" : "cpf";
};

export const mergeYupSchemas = (...schemas: any[]) => {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );

  return merged;
};

export const formatDateHeader = (): string => {
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

  const today = new Date();
  const dayOfWeek = daysOfWeek[today.getDay()];
  const dayOfMonth = today.getDate();
  const month = months[today.getMonth()];
  const year = today.getFullYear();

  return `${dayOfWeek}, ${dayOfMonth} de ${month} de ${year}`;
};

export const payloadDatesToParam = (payloadObj: any, reverse = false) => {
  const auxObj: any = {};
  if (!reverse) {
    for (const _key in payloadObj) {
      const value = payloadObj[_key];

      if (value instanceof Date) {
        auxObj[_key] = format(value, "yyyy-MM-dd");
      } else {
        auxObj[_key] = value;
      }
    }
  } else {
    for (const _key in payloadObj) {
      const value = payloadObj[_key];

      if (typeof value === "string" && dateENRegex.exec(value) !== null) {
        auxObj[_key] = parseISO(value);
      } else {
        auxObj[_key] = value;
      }
    }
  }
  return auxObj;
};

export const getYearAsString = (): string => {
  const currentYear = new Date().getFullYear();
  return currentYear.toString();
};

export function verifyIfPayloadIsComplete(
  objeto: object,
  ...camposExcluidos: string[]
) {
  const valores = Object.values(objeto);

  return valores
    .filter(
      (valor, index) => !camposExcluidos.includes(Object.keys(objeto)[index])
    )
    .every((valor) => !(typeof valor === "string" && valor.trim() === ""));
}

export const getAccountStatus = (status: string): string => {
  const statuses: Record<string, string> = {
    editing: "Editando",
    internal_analysis: "Análise Interna",
    external_analysis: "Análise Externa",
    blocked: "Bloqueado",
    approved: "Aprovado",
    refused: "Recusado",
  };

  return statuses[status];
};

export const formatCelphone = (celular: string): string => {
  if (celular.length === 11) {
    return celular.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  } else if (celular.length === 10) {
    return celular.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  } else {
    return "";
  }
};

export const verifyCompleteName = (nome: string): boolean => {
  const partes = nome?.trim().split(" ");
  return partes?.length >= 2;
};
