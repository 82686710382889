import "bootstrap/dist/css/bootstrap.min.css";
import {
  LateralModal,
  Button,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";

import useCollection from "~/hooks/Collections/useCollections";
import { format, parseISO } from "date-fns";
import { formatCash, formatCPFCNPJ, formatDate, formatDateTime } from "~/utils";
import { ContainerDetails } from "~/pages/App/app-content.styles";
import Title from "~/components/Texts/Title";
import Description from "~/components/Texts/Description";
import DownloadIcon from "~/assets/icons/buttons/download.png";
import EyeIcon from "~/assets/icons/round_visibility_black_24dp.png";
import { operationStatus } from "~/data/options";
import styled from "styled-components";
import { baseURL } from "~/services/api";
import { useEffect } from "react";
import EditCollection from "./EditCollection/EditCollection";
import { Columns } from "~/types";

const ContainerBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: white;

  padding: 40px 25px;

  button[label="Visualizar"] {
    img {
      transform: scale(1.4);
    }
  }
`;

const ShowOperationModal = () => {
  const {
    openDetailModal,
    setOpenDetailModal,
    currentModalData,
    isEditBillet,
    isBaixa,
    setIsEditBillet,
    setSelectedValue,
    setShowContent,
    alterationsData,
  } = useCollection();

  useEffect(() => {
    if (!openDetailModal) {
      setIsEditBillet(false);
    }
  }, [openDetailModal]);

  return (
    <LateralModal
      open={openDetailModal}
      header
      title={
        isEditBillet && !isBaixa
          ? "Editar cobrança"
          : isEditBillet && isBaixa
          ? "Baixa de cobrança"
          : `${operationStatus[currentModalData?.status]} #${
              currentModalData?.id
            }b`
      }
      toggleModal={() => {
        {
          setOpenDetailModal(!openDetailModal);

          setTimeout(() => {
            setSelectedValue("");
            setShowContent(false);
          }, 2000);
        }
      }}
    >
      {isEditBillet && currentModalData ? (
        <EditCollection />
      ) : !isEditBillet && currentModalData ? (
        <>
          <ContainerDetails>
            <div className="w-100 pb-5">
              <Spacer top={15} />

              <table className=" w-100">
                <tbody>
                  <tr>
                    <td>
                      <Description color="#666666">Valor original</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {formatCash(currentModalData.amount) || "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description
                        color="#666666"
                        style={{ textWrap: "nowrap" }}
                      >
                        Valor atualizado
                      </Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {formatCash(currentModalData.updated_amount) || "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Seu número</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.your_number || "1234566788"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Nosso número</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.our_number || "123456789"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">
                        Número documento
                      </Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.document_number || "123456789"}
                      </Description>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Description color="#666666">Forma de pag.</Description>
                    </td>

                    <td className="second-column">
                      <Description>Boleto</Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Emissão</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.created_date
                          ? format(
                              parseISO(currentModalData.created_date),
                              "dd/MM/yyyy HH:mm:ss"
                            )
                          : "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Vencimento</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.due_date
                          ? format(
                              parseISO(currentModalData.due_date),
                              "dd/MM/yyyy"
                            )
                          : "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Descrição</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.description
                          ? currentModalData?.description
                          : "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Pagador</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.payer_data.name} <br />
                        <b>
                          {formatCPFCNPJ(
                            currentModalData.payer_data.document_number
                          )}
                        </b>
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Status</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {operationStatus[currentModalData.status]}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Barcode</Description>
                    </td>

                    <td className="second-column">
                      <Description>{currentModalData.barcode}</Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Linha digitável</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.digitable_line}
                      </Description>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Desconto */}
              <Spacer top={25} />

              <Title>Desconto</Title>

              <Spacer top={25} />

              <table className=" w-100">
                <tbody>
                  <tr>
                    <td>
                      <Description color="#666666">
                        Data limite desconto
                      </Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.discount_date
                          ? format(
                              parseISO(currentModalData.discount_date),
                              "dd/MM/yyyy"
                            )
                          : "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">
                        Desconto aplicado
                      </Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.discount_amount || "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Tipo</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.discount_type_display || "---"}
                      </Description>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Multa */}
              <Spacer top={25} />

              <Title>Pagamento após vencimento</Title>

              <Spacer top={25} />

              <table className=" w-100">
                <tbody>
                  <tr>
                    <td>
                      <Description color="#666666">Data Multa</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.fine_date
                          ? format(
                              parseISO(currentModalData.fine_date),
                              "dd/MM/yyyy"
                            )
                          : "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Multa aplicada</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.fine_amount || "—"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Multa Tipo</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.fine_type || "—"}
                      </Description>
                    </td>
                  </tr>
                  {/* JUROS */}
                  <tr>
                    <td>
                      <Description color="#666666">Data Juros</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.late_fee_date
                          ? format(
                              parseISO(currentModalData.late_fee_date),
                              "dd/MM/yyyy"
                            )
                          : "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Juros aplicado</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.late_fee_amount || "---"}
                      </Description>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Description color="#666666">Juros Tipo</Description>
                    </td>

                    <td className="second-column">
                      <Description>
                        {currentModalData.late_fee_type_display || "---"}
                      </Description>
                    </td>
                  </tr>
                  <Spacer top={25} />
                  <Title>Alterações</Title>
                  {alterationsData.length > 0 ? (
                    alterationsData
                      .reverse()
                      .map((alteration: any, i: number) => {
                        return (
                          <>
                            <Spacer top={12} />
                            <p>{`#${i + 1}`}</p>
                            <Spacer top={-23} />
                            <tr>
                              <td>
                                <Description color="#666666">
                                  Alterado em
                                </Description>
                              </td>

                              <td className="second-column">
                                <Description>
                                  {formatDateTime(
                                    alterationsData[i].created_date
                                  )}
                                </Description>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Description color="#666666">Tipo</Description>
                              </td>

                              <td className="second-column">
                                <Description>
                                  {alterationsData[i].type_display}
                                </Description>
                              </td>
                            </tr>

                            {alterationsData[i].amount !== null && (
                              <tr>
                                <td>
                                  <Description color="#666666">
                                    Valor
                                  </Description>
                                </td>

                                <td className="second-column">
                                  <Description>
                                    {formatCash(alterationsData[i].amount)}
                                  </Description>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td>
                                <Description color="#666666">
                                  Descrição
                                </Description>
                              </td>

                              <td className="second-column">
                                <Description>
                                  {alterationsData[i].description ??
                                    "sem descrição"}
                                </Description>
                              </td>
                            </tr>
                            {alterationsData[i].due_date !== null && (
                              <tr>
                                <td>
                                  <Description color="#666666">
                                    Data de vencimento
                                  </Description>
                                </td>

                                <td className="second-column">
                                  <Description>
                                    {formatDate(alterationsData[i].due_date)}
                                  </Description>
                                </td>
                              </tr>
                            )}
                            {alterationsData[i].deadline_date !== null && (
                              <tr>
                                <td>
                                  <Description color="#666666">
                                    Data limite de pagamento
                                  </Description>
                                </td>

                                <td className="second-column">
                                  <Description>
                                    {formatDate(
                                      alterationsData[i].deadline_date
                                    )}
                                  </Description>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      })
                  ) : (
                    <div className="mt-3 ">Nenhuma alteração até o momento</div>
                  )}
                  <Spacer top={25} />
                </tbody>
              </table>
            </div>
          </ContainerDetails>
          <Spacer bottom={50} />
          <ContainerBottom className="d-flex justify-content-end p-4 ">
            <Button
              mode="phantom"
              label="Fechar"
              style={{ marginRight: "auto" }}
              onClick={() => {
                setOpenDetailModal(false);
              }}
            />
            <Button
              mode="border"
              label="Fazer download"
              disabled={!currentModalData.url}
              icon={DownloadIcon}
              onClick={() => {
                window.open(`${baseURL}/billet/${currentModalData.id}/pdf`);
              }}
            />
            <Spacer left={15} />
            <Button
              mode="border"
              label="Visualizar"
              disabled={!currentModalData.url}
              icon={EyeIcon}
              onClick={() => {
                window.open(
                  `${baseURL}/billet/${currentModalData.id}/view`,
                  "_blank"
                );
              }}
            />
          </ContainerBottom>
        </>
      ) : (
        <></>
      )}
    </LateralModal>
  );
};

export default ShowOperationModal;
