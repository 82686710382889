import { LateralModal, ProgressBar } from "@zilliondigital/mirage-ui";
import * as Yup from "yup";
import {
  CpfRegex,
  INVALID_FORMAT,
  PhoneRegex,
  REQUIRED_LABEL,
} from "~/services/validators";
import { useFormik } from "formik";
import Description from "~/components/Texts/Description";
import useBanks from "~/hooks/useBanks";
import useContacts from "~/hooks/Contacts/useContacts";
import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";
import { useEffect, useState } from "react";
import { onlyNumbers } from "~/utils";
import useTransferWithPix, {
  sendPixSteps,
} from "~/hooks/PIX/useTransferWithPix";
import AddPixKeyData from "./AddPixKeyData";
import AddPixKeyConfirm from "./AddPixKeyConfirm";
import LoadingTransferPix from "../../PIX/TransferWithPix/Loader";

type RenderTransferPixType = {
  [key: string]: React.ReactNode;
};

const RenderAddPixKeyModal: RenderTransferPixType = {
  data: <AddPixKeyData />,
  send: <AddPixKeyConfirm />,
};

const AddPixKeyModal = () => {
  const { getBanks, banksData } = useBanks();

  const {
    openAddPixKeyModal,
    setOpenAddPixKeyModal,
    contactsData,
    setContactsData,
    typeOfKey,
    setTypeOfKey,
  } = useContacts();

  const {
    setManualPix,
    isLoadingData,
    setIsPixWithSelectedPartner,
    setStepIndex,
    setDataTransferPixReceipt,
    setContactSelectedPixData,
    stepIndex,
  } = useTransferWithPix();

  const getContacts = async () => {
    const { data } = await ApiService.HttpGet({
      route: "partner/",
      token: true,
      params: {
        default: false,
      },
    });

    setContactsData(data.results);
  };

  const handleToggleModal = () => {
    setOpenAddPixKeyModal(!openAddPixKeyModal);
  };

  useEffect(() => {
    if (contactsData.length <= 0) {
      getContacts();
    }

    banksData?.length <= 0 && getBanks();
  }, []);

  useEffect(() => {
    if (!openAddPixKeyModal) {
      setStepIndex(0);
      setManualPix(false);
      setDataTransferPixReceipt(null);
      setIsPixWithSelectedPartner(false);
      setContactSelectedPixData(undefined);
    }
  }, [openAddPixKeyModal]);

  return (
    <LateralModal
      toggleModal={handleToggleModal}
      open={openAddPixKeyModal}
      header
      title={"Adicionar chave pix"}
    >
      <>
        <ProgressBar percent={50} />

        {isLoadingData ? (
          <LoadingTransferPix />
        ) : (
          RenderAddPixKeyModal[sendPixSteps[stepIndex]]
        )}
      </>
    </LateralModal>
  );
};

export default AddPixKeyModal;
