const payments: any[] = [
  {
    id: 1,
    title: "Payment 1",
    type: "billet",
    status: "paid",
    issuer: "John Doe",
    due_date: "2021-01-01",
    payment_date: "2021-01-01",
    amount: 100.74,
    identifier: "#123456789br",
    cost_center: "Administrativo",
    description: "Fatura do cartão de crédito.",
  },
  {
    id: 2,
    title: "Payment 2",
    type: "pix",
    status: "paid",
    issuer: "John Doe",
    due_date: "2021-01-01",
    payment_date: "2021-01-01",
    amount: 100.74,
    identifier: "#123456789br",
    cost_center: "Manutenção",
    description: "Mensalidade.",
  },
  {
    id: 3,
    title: "Payment 3",
    type: "billet",
    status: "schedule",
    issuer: "John Doe",
    due_date: "2021-01-01",
    payment_date: "2021-01-01",
    amount: 100.74,
    identifier: "#123456789br",
    cost_center: "Marketing",
    description: "Compra online.",
  },
];

export default payments;
