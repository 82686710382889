import { Button, InteractiveModal, Spacer } from "@zilliondigital/mirage-ui";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import useTariffs from "~/hooks/useTariffs";
import useToast from "~/hooks/useToast";
import { TitleModal } from "~/pages/Authentication/Auth.styles";
import ApiService from "~/services/api";
import { PackpageTypes } from "~/types";

type deletePackpageType = {
  data?: any;
  error: boolean;
  message: string;
};

const DeleteHolderModal = () => {
  const [isloading, setIsloading] = useState<boolean>(false);

  const {
    setOpenDeleteHolderModal,
    openDeleteHolderModal,
    deleteHolderData,
    setEditPackpageData,
  } = useTariffs();

  const { showToast } = useToast();

  const toggleModal = () => {
    setOpenDeleteHolderModal(!openDeleteHolderModal);
  };

  const params = useParams<{ id: string }>();

  const deleteHolder = useCallback(async () => {
    setIsloading(true);

    try {
      const dataRemove = await ApiService.HttpPost<deletePackpageType>({
        route: `backoffice/tariffs-package/${params.id}/remove-holders/`,
        body: {
          holders: [deleteHolderData.id],
        },
        token: true,
      });

      setIsloading(false);
      toggleModal();

      showToast("Correntista removido com sucesso", "", "success");

      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/",
        token: true,
      });

      const dataFiltered = data.results.filter(
        (packpage: PackpageTypes) => packpage.id === Number(params.id)
      );

      setEditPackpageData(dataFiltered);
    } catch {
      setIsloading(false);
      toggleModal();
      showToast("Nao foi possível remover o correntista", "", "error");
    }
  }, [openDeleteHolderModal]);

  return (
    <InteractiveModal
      title={"Deletar cesta de tarifas"}
      size="md"
      toggleModal={toggleModal}
      open={openDeleteHolderModal}
    >
      <div className="p-5 d-flex  flex-column justify-content-between ">
        <TitleModal className="text-center">
          Deseja remover {deleteHolderData?.name} da cesta ?
        </TitleModal>
        <Spacer bottom={15} />
        <p className="text-center">
          {`Ao remover este correntista, ele voltará automaticamente para uma cesta padrão.`}
        </p>

        <Spacer bottom={110} />
        <div>
          <Button
            mode="main"
            label="Não. Manter correntista na cesta"
            onClick={toggleModal}
            full
          />
          <Spacer bottom={10} />
          <Button
            loading={isloading}
            mode="border"
            label="Sim. Remover correntista da cesta"
            onClick={deleteHolder}
            full
          />
        </div>
      </div>
    </InteractiveModal>
  );
};
export default DeleteHolderModal;
