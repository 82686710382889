import {
  AddBalanceOutput,
  Button,
  Margin,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useLocation } from "react-router-dom";
import useAddBalance from "~/hooks/useAddBalance";
import useToast from "~/hooks/useToast";
import useToken from "~/hooks/useToken";
import { baseURL } from "~/services/api";

const Done = () => {
  const {
    newChargeData,
    isPIX,
    openAddBalanceModal,
    setOpenAddBalanceModal,
    setChooseTypeAddBalance,
    setSelectedValue,
    setAmount,
    setIndexPixKeyToCharge,
  } = useAddBalance();
  const { showToast } = useToast();

  const { setToken } = useToken();

  const location = useLocation();

  const allowedPaths: string[] = ["/app/pix"];
  const isPIXLocation: boolean = allowedPaths.includes(location.pathname);

  const handleRedirect = (url: string) => {
    url !== null && window.open(url, "_blank");
    url === null &&
      showToast(
        "Erro",
        "Não foi possível abrir o boleto pra impressão. Copie o código acima e cole em sua àrea de pagamentos",
        "error"
      );
  };

  const handleToggleModal = () => {
    setOpenAddBalanceModal(!openAddBalanceModal);
    setChooseTypeAddBalance(null);
    setSelectedValue("");
    setToken("");
    setAmount(0);
    setIndexPixKeyToCharge(-1);
  };

  return (
    <div className="p-4">
      {isPIX && (
        <div className="d-flex w-100 justify-content-center">
          {" "}
          <img style={{ height: "320px" }} src={newChargeData.image} />
        </div>
      )}

      {isPIX && <Spacer bottom={40} />}

      <AddBalanceOutput
        value={isPIX ? newChargeData.emv : newChargeData?.data.digitable_line}
        fontSize="large"
        label={isPIX ? "Pix copia e cola" : "Linha digitável"}
      />
      <Spacer bottom={20} />
      {!isPIX && (
        <div className="w-100 d-flex justify-content-end">
          <Button
            label="Imprimir Boleto"
            mode="main"
            onClick={() =>
              handleRedirect(`${baseURL}/billet/${newChargeData?.data.id}/html`)
            }
          />
        </div>
      )}

      {isPIX && isPIXLocation && (
        <>
          <Margin top={40} />

          <div className="w-100 d-flex justify-content-end">
            <Button
              label="Concluir"
              mode="main"
              onClick={() => handleToggleModal()}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Done;
