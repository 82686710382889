import { CreateCardStep } from "~/hooks/Cards/interfaces";
import { CreatingCardAddressInputWrapper, CreatingCardAddressInputLine, CreateCardInputError } from "../styled";
import { ICreateCardInfos, initalCreateCardInfos } from "~/hooks/Cards/initalValues";
import { Input } from "@zilliondigital/mirage-ui";

interface ICardDeliveryInfoInputs {
  setCreateCardStep: React.Dispatch<React.SetStateAction<CreateCardStep>>;
  createCardStep: CreateCardStep;
  createCardInfos: ICreateCardInfos;
  setCreateCardValues: (info: string, value: any) => void;
  handleCepString: (initialValue?: string) => Promise<void>;
  zipCodeError: boolean;
  numberError: boolean;
}
export const CardDeliveryInfoInputs = (props:ICardDeliveryInfoInputs) => {

  return(
    <CreatingCardAddressInputWrapper>
      <CreatingCardAddressInputLine style={{width: '45%'}}>
        <Input 
            label='CEP' 
            mask="99999-999"
            value={props.createCardInfos.other_address?.zip_code}
            onBlur={(e) => {
              if(e.currentTarget.value && e.currentTarget.value.length == 9){
                props.handleCepString(e.currentTarget.value)
              }
            }}
            onChange={(e) => {
              if(typeof e.currentTarget.value != 'undefined'){
                props.setCreateCardValues('other_address',
                {
                  ...initalCreateCardInfos.other_address,
                  zip_code: e.currentTarget.value
                })
              }
            }}
          />
      </CreatingCardAddressInputLine>
      <CreatingCardAddressInputLine>
        <Input 
          label="Logradouro"
          value={props.createCardInfos.other_address?.address || ''}
          style={{borderColor: '#f1f1f1', color: '#8d8d8d'}}
          onChange={(e) => {
            if(typeof e.currentTarget.value != 'undefined'){
              props.setCreateCardValues('other_address',
              {
                ...props.createCardInfos.other_address,
                address: e.currentTarget.value
              })
            }
          }}  
        />
      </CreatingCardAddressInputLine>
      <CreatingCardAddressInputLine>
        <Input 
          label="Número"
          style={
            props.createCardInfos.other_address?.address ? 
            undefined : 
            {borderColor: '#f1f1f1', color: '#8d8d8d'}
          }
          value={props.createCardInfos.other_address?.number}
          onChange={(e) => {
            if(typeof e.currentTarget.value != 'undefined'){
              props.setCreateCardValues('other_address',
              {
                ...props.createCardInfos.other_address,
                number: e.currentTarget.value
              })
            }
          }}  
        />
        <Input 
          label="Complemento"
          style={
            props.createCardInfos.other_address?.address ? 
            undefined : 
            {borderColor: '#f1f1f1', color: '#8d8d8d'}
          }
          value={props.createCardInfos.other_address?.complementary_address}
          onChange={(e) => {
            if(typeof e.currentTarget.value != 'undefined'){
              props.setCreateCardValues('other_address',
              {
                ...props.createCardInfos.other_address,
                complementary_address: e.currentTarget.value
              })
            }
          }}  
        />
      </CreatingCardAddressInputLine>

      <CreatingCardAddressInputLine>
        <Input 
          label="Bairro"
          value={props.createCardInfos.other_address?.district || ''}
          style={{borderColor: '#f1f1f1', color: '#8d8d8d'}}
          onChange={(e) => {
            if(typeof e.currentTarget.value != 'undefined'){
              props.setCreateCardValues('other_address',
              {
                ...props.createCardInfos.other_address,
                district: e.currentTarget.value
              })
            }
          }}  
        />
      </CreatingCardAddressInputLine>
      <CreatingCardAddressInputLine>
        <Input 
          label="Cidade"
          value={props.createCardInfos.other_address?.city || ''}
          style={{borderColor: '#f1f1f1', color: '#8d8d8d'}}
          onChange={(e) => {
            if(typeof e.currentTarget.value != 'undefined'){
              props.setCreateCardValues('other_address',
              {
                ...props.createCardInfos.other_address,
                city: e.currentTarget.value
              })
            }
          }}  
        />
        <Input 
          label="Estado"
          value={props.createCardInfos.other_address?.state || ''}
          style={{borderColor: '#f1f1f1', color: '#8d8d8d'}}
          onChange={(e) => {
            if(typeof e.currentTarget.value != 'undefined'){
              props.setCreateCardValues('other_address',
              {
                ...props.createCardInfos.other_address,
                state: e.currentTarget.value
              })
            }
          }}  
        />
      </CreatingCardAddressInputLine>
      {(props.zipCodeError || props.numberError) ? (
        <div className="mt-2">
          {
            props.zipCodeError && 
            <CreateCardInputError>
              - CEP inválido.
            </CreateCardInputError>
          }
          {
            props.numberError && 
            <CreateCardInputError>
              - Número inválido.
            </CreateCardInputError>
          }
        </div>
      ) : <></>}
    </CreatingCardAddressInputWrapper>
  )
}