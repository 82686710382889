import { Button, Spacer } from "@zilliondigital/mirage-ui";
import styled from "styled-components";
import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";
import useAddBalance from "~/hooks/useAddBalance";
import { motion } from "framer-motion";
import { useEffect } from "react";
import useToken from "~/hooks/useToken";
import {
  formatCPFCNPJ,
  formatCash,
  formatNumberToCurrencyWithoutSimbol,
  formatStringDateENToBR,
} from "~/utils";
import { useLocation } from "react-router-dom";
import useMyKeys from "~/hooks/PIX/useMyKeys";

const Container = styled.div`
  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;
  }
  padding: 20px;

  .image-eye {
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  .container-balance {
    position: relative;
    top: -20px;
  }
`;

type typeKeysProps = {
  [key: string]: string;
};

const typesKeysLabel: typeKeysProps = {
  telefone: "Celular",
  email: "Email",
  cpf: "CPF",
  cnpj: "CNPJ",
  evp: "Aleatória",
};

const ConfirmData = () => {
  const {
    handlePrevStep,
    setOpenValidatorModalAddBalance,
    amount,
    isPIX,

    setChangeSideAnimation,

    getNextDay,
    setStepIndex,
    rechargeDueDate,
    fetchGenerateCollectionPixQrCode,
    getDiscountDate,
    taxLiquidateBillet,
    taxLiquidateQRCodeStatic,
  } = useAddBalance();
  const { keyData } = useMyKeys();
  const { sendPartnerToken } = useToken();

  const { brFormat } = getNextDay();

  const { formatNumberToCurrency } = useAccount();

  const location = useLocation();

  const allowedPaths = ["/app/pix"];
  const isPIXLocation = allowedPaths.includes(location.pathname);

  useEffect(() => {
    getDiscountDate();
    setChangeSideAnimation(false);
  }, []);

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      transition={{ duration: 0.3 }}
      className="vh-100"
    >
      <Container>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Valor da cobrança</Description>
              </td>

              <td className="second-column">
                <Description>{formatNumberToCurrency(amount)}</Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Forma de pagamento</Description>
              </td>

              <td className="second-column">
                <Description> {isPIX ? "Pix" : "Boleto"}</Description>
              </td>
            </tr>
            {isPIX && (
              <tr>
                <td>
                  <Description color="#666666">Chave selecionada</Description>
                </td>

                <td className="second-column d-flex flex-column">
                  <Description>{typesKeysLabel[keyData[0]?.type]}</Description>
                  <Description color="#666666">
                    {keyData[0]?.type === "cpf" || keyData[0]?.type === "cnpj"
                      ? formatCPFCNPJ(keyData[0]?.key)
                      : keyData[0]?.key}
                  </Description>
                </td>
              </tr>
            )}

            {!isPIX && (
              <tr>
                <td>
                  <Description color="#666666">Data de vencimento</Description>
                </td>

                <td className="second-column">
                  {rechargeDueDate
                    ? formatStringDateENToBR(rechargeDueDate)
                    : brFormat}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Spacer bottom={40} />
        <div className="d-flex justify-content-between w-100 pe-2">
          <Button
            label={"Editar"}
            mode="phantom"
            onClick={() => {
              isPIX ? setStepIndex(0) : handlePrevStep();
            }}
          />
          <Button
            label={
              isPIX && isPIXLocation
                ? "Gerar QR Code de cobrança"
                : isPIX
                ? "Gerar Pix"
                : "Gerar Boleto"
            }
            mode="main"
            onClick={() => {
              if (isPIXLocation) {
                fetchGenerateCollectionPixQrCode();
              } else {
                sendPartnerToken();
                setOpenValidatorModalAddBalance(true);
              }
            }}
          />
        </div>
      </Container>
    </motion.div>
  );
};

export default ConfirmData;
