import styled from "styled-components";
import theme from "~/themes/theme";
import Pattern from "~/assets/images/pattern_color_main-prosperita.png";
import label from "~/labels";

export const GeneralContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;

  .text-size-md {
    font-size: 14px;
  }

  .text-size-sm {
    font-size: 12px;
  }

  .text-color-dark100 {
    color: ${() => theme.colors.base.dark100};
  }

  .text-color-dark200 {
    color: ${() => theme.colors.base.dark200};
  }

  .text-color-secondary-main {
    color: ${() => theme.colors.secondary.main};
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;

export const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  padding: 10px 95px;
  width: 481px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 32px 32px;
  }
`;

export const SidebarBody = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  /* background-color: ${() => theme.colors.base.light100}; */
`;

export const WelcomeText = styled.span`
  color: ${theme.colors.base.dark500};
  font-size: 42px;
  font-weight: 100;
`;

export const WelcomeDescription = styled.span`
  color: ${theme.colors.base.dark100};
  font-size: 14px;
`;

export const TextFooter = styled.span`
  color: ${theme.colors.base.dark100};
  font-size: 12px;
`;

export const BackgroundContainer = styled.div`
  background-image: url(${label.backgroundLoginImage});
  min-width: calc(100% - 481px);
  background-size: ${() => label.backgroundCover && "cover"};

  background-attachment: ${() => label.backgroundAttachament ?? "none"};
  background-position-x: ${() => label.backgroundPositionX ?? "none"};
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: end;
  justify-content: start;
`;

export const ForgotPasswordLink = styled.a`
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  color: ${theme.colors.secondary.main};

  &:hover {
    color: ${theme.colors.secondary.main};
  }
`;

export const Title = styled.span`
  font-size: 27px;
`;

export const ButtonReturnContainer = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  top: 0;
  left: -15px;
`;

export const ContainerRadio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

export const IconModal = styled.img`
  width: 96px;
  height: 96px;
`;

export const SidebarBodyNewPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleModal = styled.h1`
  font-size: 28px;
  color: ${theme.colors.primary.main};
  font-weight: 300;
`;

export const ContainerCompany = styled.div`
  border: 1px solid ${() => theme.colors.primary.main};
  border-radius: 6px;
  max-width: 404px;
  min-height: 106px;
  display: flex;
  cursor: pointer;
  transition: border 0.5s ease, background-color 0.5s ease, color 0.5s ease,
    filter 0.5s ease;

  .div-circle {
    background-color: ${() => theme.colors.primary.light200};
    transition: border 0.5s ease, background-color 0.5s ease, color 0.5s ease,
      filter 0.5s ease;
  }

  .title {
    color: ${() => theme.colors.primary.main};
    transition: border 0.2s ease, background-color 0.2s ease, color 0.2s ease,
      filter 0.2s ease;
  }

  .icons {
    filter: ${() => theme.colors.primary.mainFilter};
    transition: border 0.2s ease, background-color 0.2s ease, color 0.2s ease,
      filter 0.2s ease;
  }

  &:hover {
    border: 1px solid ${() => theme.colors.secondary.main};

    .div-circle {
      background-color: ${() => theme.colors.secondary.light200};
    }

    .title {
      color: ${() => theme.colors.secondary.main};
    }
    .icons {
      filter: ${() => theme.colors.secondary.mainFilter};
    }
  }
`;
