import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AddBalanceOutput,
  NotControlledToolTip,
  Button,
  ButtonMoreDetails,
  Margin,
  ReturnButton,
  Spacer,
  Table,
  Tooltip,
  Breadcrumb,
  InteractiveModal,
} from "@zilliondigital/mirage-ui";
import RefreshIcon from "~/assets/icons/buttons/Ic_functional_refresh.png";
import Skeleton from "~/components/SkeletonTables";
import Timer from "~/assets/icons/figure_3d_time.png";

import { pixMyLimits } from "~/data/pix";
import { PixLimitsProps } from "~/data/pix";
import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import PayCopyAndPaste from "./PayCopyAndPaste/PayCopyAndPasteModal";
import usePix from "~/hooks/PIX/usePix";
import CopyIcon from "~/assets/icons/round_content_copy_black_24dp.png";
import MyKeysModal from "./MyKeys/MyKeysModal";
import MyLimitsModal from "./MyLimits/MyLimits";
import { TransferWithPixModal } from "./TransferWithPix/TransferWithPixModal";
import useMyKeys from "~/hooks/PIX/useMyKeys";
import { useNavigate } from "react-router-dom";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import WhatsIcon from "~/assets/icons/whats.png";
import useToast from "~/hooks/useToast";
import DeleteIcon from "~/assets/icons/delete-icon-small.png";
import QrCodeIcon from "~/assets/icons/qr-code.png";
import CPFKeyIcon from "~/assets/icons/round_account_circle_black_24dp.png";
import MailKeyIcon from "~/assets/icons/mail_FILL0_wght400_GRAD0_opsz48.svg";
import PhoneKeyIcon from "~/assets/icons/phone_iphone_FILL0_wght400_GRAD0_opsz48.png";
import KeyIcon from "~/assets/icons/key.png";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import usePayCopyAndPaste from "~/hooks/PIX/usePayCopyAndPaste";
import theme from "~/themes/theme";
import AddNewKeyModal from "./MyKeys/AddNewKey";
import useAccount from "~/hooks/useAccount";
import ApiService from "~/services/api";
import GenerateQRCodeModal from "./GenerateQRCodeModal";
import hideFormatDocumentNumber from "~/utils/hideDocumentNumber";
import { formatCPFCNPJ, formatNumberToCurrencyWithoutSimbol } from "~/utils";
import useAddBalance from "~/hooks/useAddBalance";
import AddBalanceModal from "../Dashboard/AddBalance/AddBalanceModal";
import Copyright from "~/components/Copyright";
import useModal from "~/hooks/useModal";
import { Columns } from "~/types";
import useToken from "~/hooks/useToken";
import { TitleModal } from "~/pages/Authentication/Auth.styles";

const GeneralContainerData = styled.div<PIXStructureProps>`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  background-color: ${" #f8f8f8"};
  border-radius: 24px 24px 0 0;
  overflow: ${({ isValidatorTokenOpen }) =>
    isValidatorTokenOpen ? "hidden" : ""};
  .pix-mykeys-image {
    height: 24px;

    cursor: pointer;
  }

  .tooltip [class$="inner"] {
    background-color: ${theme.colors.info.light300};
    border: 1px solid ${theme.colors.info.light300};
  }

  .tooltip [class$="arrow"] {
    border-top: 5px solid ${theme.colors.info.light300} !important;
  }

  .container-btn-more-details {
    position: relative;
    top: 8px;
    left: 75%;
  }

  .copy-icon {
    position: relative;
    top: 8px;
    left: 70%;
  }

  .container-return-button {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const LabelKey = styled.span`
  font-size: 12px;
  color: #808080;
`;

const ContainerData = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const IconsKeys: any = {
  evp: KeyIcon,
  telefone: PhoneKeyIcon,
  email: MailKeyIcon,
  cpf: CPFKeyIcon,
  cnpj: CPFKeyIcon,
  document_number: CPFKeyIcon,
};

const ToolTipContainerAvailableBalance = styled.div`
  position: relative;
  left: 282px;
  top: -30px;
`;

const Rounded = styled.div`
  width: 96px;
  height: 96px;
  background-color: #ffe5d8;
  position: relative;

  img {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`;

type PIXStructureProps = {
  isValidatorTokenOpen?: boolean;
};

const PIXStructure = (props: PIXStructureProps) => {
  const { openValidatorModalPixCopyAndPaste } = usePayCopyAndPaste();
  const { openValidatorModalTransferWithPix } = useTransferWithPix();
  const {
    openPayCopyAndPasteModal,
    openTransferWithPixModal,
    setOpenTransferWithPixModal,
  } = usePix();

  const { setOpenAddBalanceModal } = useAddBalance();

  const { mainAccount, getBalance, verifyPermission, setActiveIndexSideBar } =
    useAccount();

  const { getToken } = useToken();

  const [loading, setLoading] = useState<boolean>(false);

  const canTransfer = verifyPermission("ba_can_create_transfer");

  const {
    setOpenPayCopyAndPasteModal,
    setOpenMyKeysModal,

    openMyKeysModal,
    openMyLimitsModal,
    dataQRCodeStatic,
    setDataQRCodeStatic,
    setOpenGenerateQRCodeModal,
  } = usePix();

  const {
    keyPixData,
    setIndexPixKeyToCharge,
    setSelectedValue,
    setIsEmpty,
    setChooseTypeAddBalance,
  } = useAddBalance();

  const {
    setOpenDeleteKeyModal,
    idKey,
    setIdKey,
    pixKeysData,
    setPixKeysData,
    setKeyTypeLabel,
    keyTypeLabel,
    loadingPixKeys,

    openDeleteKeyModal,
    getPixKeys,
  } = useMyKeys();

  const [limitsData, setLimitsData] = useState<any>([]);

  const [key, setKey] = useState<string>("");

  const { showToast } = useToast();
  const navigate = useNavigate();

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const findLastStaticQRCode = (data: any) => {
    const filteredData = data.filter(
      (item: any) => item.type === "static" && item.amount === null
    );
    if (filteredData.length > 0) {
      return filteredData[filteredData.length - 1];
    } else {
      return null;
    }
  };

  const deleteKeyPix = useCallback(async () => {
    setLoading(true);
    try {
      await ApiService.HttpDelete({
        route: `pix/key/${idKey}/`,

        token: true,
      });

      setOpenDeleteKeyModal(false);
      setLoading(false);
      showToast(
        "Removida com sucesso",
        "Sua chave Pix foi removida com sucesso",
        "success"
      );

      setTimeout(async () => {
        getPixKeys();
      }, 5000);
    } catch (e) {
      setOpenDeleteKeyModal(false);
      setLoading(false);
      showToast(
        "Ocorreu um erro",
        "Não foi possível encontrar a chave pix informada ou ela se encontra em um status que não permite cancelamento.",
        "error"
      );
      setTimeout(async () => {
        getPixKeys();
      }, 5000);
    }
  }, [idKey]);

  const createQrCode = async () => {
    showToast(
      "Gerando QR Code...",
      "Aguarde enquanto geramos  o QR code para você.",
      "warning",
      () => undefined,
      undefined,
      3000
    );
    try {
      const { data } = await ApiService.HttpPost({
        route: "pix/qrcode/",

        body: {
          amount: null,
          key: idKey,
        },
        token: true,
      });

      setDataQRCodeStatic(data.data);
      setOpenGenerateQRCodeModal(false);

      showToast(
        "QR Code gerado",
        "Seu QR Code foi gerado com sucesso",
        "success"
      );
    } catch (e) {
      console.warn(e);
      setIdKey(-1);
    }
  };

  const getQrCode = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: `pix/qrcode/`,
        token: true,
      });

      const lastQRCodeCreated = findLastStaticQRCode(data.data);

      if (lastQRCodeCreated) {
        setDataQRCodeStatic(lastQRCodeCreated);
      } else {
        console.warn("Nenhum QR code com type 'static' encontrado.");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const typesKeysLabel: any = {
    telefone: "Celular",
    email: "Email",
    cpf: "CPF",
    cnpj: "CNPJ",
    document_number: "CPF/CNPJ",
    evp: "Chave aleatória",
  };

  const pixKeysDataFiltered = useMemo(() => {
    const initialFilterAndMap = pixKeysData
      ?.filter((key: any) => key.key !== null)
      .map((key, index) => ({
        key: key.key,
        type: key.type,
        index: index,
        status: key.status,
        id: key.id,
      }));
    const result = initialFilterAndMap.reduce((acc: any, current: any) => {
      if (!acc.some((item: any) => item.key === current.key)) {
        acc.push(current);
      }
      return acc;
    }, []);
    return result;
  }, [pixKeysData]);

  const [isToolTipVisible, setIsToolTipVisible] = useState<boolean[]>(
    new Array(pixKeysDataFiltered?.length).fill(false)
  );

  const typesKeysLabelRender: { [key: string]: string } = {
    telefone: "Celular",
    email: "Email",
    document_number: "CPF/CNPJ",
    cpf: "CPF",
    cnpj: "CNPJ",
    evp: "Chave aleatória",
  };

  const message = `Olá! Essa é minha chave Pix ${typesKeysLabel[keyTypeLabel]}: ${key} `;

  const buttonMoreOptions: any[] = [
    {
      label: "Gerar QR Code",
      onClick: () => createQrCode(),
      icon: QrCodeIcon,
    },
    {
      label: "Compartilhar via whatsApp",
      onClick: () => {
        const shareUrl = `https://wa.me?text=${encodeURIComponent(message)}`;

        window.open(shareUrl);
      },
      icon: WhatsIcon,
    },
    {
      label: "Excluir chave",
      onClick: () => {
        setOpenDeleteKeyModal(true);
      },

      icon: DeleteIcon,
    },
  ];

  const columnsPixLimits: Columns[] = [
    {
      label: "Tipo",
      key: "type",

      renderData: (data: any) => (
        <div className="d-flex justify-content-start align-items-center  ">
          <Description>{data?.type_display}</Description>
        </div>
      ),
    },
    {
      label: "Valor máximo",
      key: "diurnalLimit",
      renderData: (data: any) => (
        <div className="d-flex justify-content-start align-items-center container-pix-limits ps-3">
          <Description>
            {data?.max
              ? `R$ ${formatNumberToCurrencyWithoutSimbol(data?.max ?? 0)}`
              : "Não dísponivel"}
          </Description>
        </div>
      ),
    },
  ];
  const resendToken = async (_data: any) => {
    try {
      const { data } = await ApiService.HttpPost({
        route: `pix/key/${_data.id}/confirm_hold/`,
        body: {
          resend_code: true,
        },

        token: true,
      });

      if (!data.data.success) {
        showToast(
          "Não foi possível reenviar o token de confirmação.",
          `${data.data.message}`,
          "error"
        );

        return;
      }

      const token = await getToken({
        title: `Validação de ${_data.type} `,
        description: `Informe o token que enviamos para o seu ${_data.type} que deseja criar a chave PIX`,
      });

      const dataToken = await ApiService.HttpPost({
        route: `pix/key/${_data.id}/confirm_hold/`,
        body: {
          code: token,
          resend_code: false,
        },

        token: true,
      });

      if (dataToken.data.data.success) {
        showToast("Validação refeita com sucesso", "", "success");

        setTimeout(() => {
          getPixKeys();
        }, 3000);
      } else {
        showToast("Ocorreu um erro", dataToken.data.data.message, "error");

        setTimeout(() => {
          getPixKeys();
        }, 3000);
      }
    } catch {
      showToast("Ocorreu um erro", "", "error");
    }

    setTimeout(() => {
      getPixKeys();
    }, 3000);
  };

  const copyToClipboard = async (text: string) => {
    try {
      (await text) && navigator.clipboard.writeText(text);

      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {}
  };

  const columnsMyKeys: Columns[] = [
    {
      label: "",
      key: "type",

      renderData: (data: any) => (
        <div className="d-flex justify-content-start align-items-center   ">
          <div>
            <img className="pix-mykeys-image" src={IconsKeys[data.type]} />
          </div>

          <div className="d-flex flex-column justify-content-center align-items-start ps-3 ">
            <Description>{typesKeysLabel[data.type]}</Description>
            {data.status === "registering" &&
            (data.type === "email" || data.type === "telefone") ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  {" "}
                  Ocorreu um erro na verificação do token, por favor tente
                  novamente.
                </Description>
                <div onClick={() => resendToken(data)}>
                  <Description color={theme.colors.secondary.main}>
                    Reenviar Token
                  </Description>
                </div>
              </>
            ) : data.status === "confirmed" &&
              (data.type === "email" || data.type === "telefone") ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  Token confirmado. Sua chave logo estará disponível para
                  ultilização.
                </Description>
                <Spacer bottom={10} />
                <div onClick={() => resendToken(data)}>
                  <Description color={theme.colors.secondary.main}>
                    Reenviar Token
                  </Description>
                </div>
              </>
            ) : data.status === "registering" &&
              data.type !== "email" &&
              data.type !== "telefone" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  {" "}
                  A chave encontra-se no status "registrando" e logo estará
                  disponível para ultilização.
                </Description>
                <Spacer bottom={10} />
              </>
            ) : data.status === "canceling" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  A chave está em processo de cancelamento. Logo ela não
                  aparecerá mais aqui.
                </Description>{" "}
              </>
            ) : data.status === "error_ownership" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  Ocorreu um erro ao tentar registrar esta chave, por favor
                  entre em contato com o suporte.
                </Description>{" "}
              </>
            ) : data.status === "error_portability" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  Esta chave está com problema de portabilidade, por favor entre
                  em contato com o suporte.
                </Description>{" "}
              </>
            ) : data.status === "error" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  Está chave está com um erro, por favor entre em contato com o
                  suporte.
                </Description>{" "}
              </>
            ) : (
              <LabelKey>
                {" "}
                {data.type === "cpf" || data.type === "cnpj"
                  ? formatCPFCNPJ(data.key)
                  : data.key}
              </LabelKey>
            )}
          </div>
        </div>
      ),
    },
    {
      label: "",
      key: "",
      renderData: (data: any) => (
        <>
          {data.status === "registered" && (
            <div className="d-flex justify-content-start align-items-center position-relative pe-5  ">
              <ToolTipContainerAvailableBalance>
                {" "}
                <NotControlledToolTip
                  target={`key-target-${data.index}`}
                  className="tooltip"
                >
                  Copiar
                </NotControlledToolTip>
              </ToolTipContainerAvailableBalance>

              <Spacer bottom={0} />
              <img
                className="pix-mykeys-image copy-icon "
                src={CopyIcon}
                id={`key-target-${data.index}`}
                onClick={() => {
                  copyToClipboard(data.key);
                }}
                onMouseEnter={() =>
                  setIsToolTipVisible((prevState) => {
                    const newState = prevState.slice();
                    newState[data.index] = true;
                    return newState;
                  })
                }
                onMouseLeave={() =>
                  setIsToolTipVisible((prevState) => {
                    const newState = prevState.slice();
                    newState[data.index] = false;
                    return newState;
                  })
                }
                style={
                  isToolTipVisible[data.index]
                    ? {
                        filter:
                          "brightness(0) saturate(100%) invert(71%) sepia(99%) saturate(1215%) hue-rotate(168deg) brightness(93%) contrast(89%)",
                      }
                    : {}
                }
              />

              <div
                className="container-btn-more-details"
                onClick={() => {
                  setIdKey(data.id);
                  setIndexPixKeyToCharge(data.id);
                  setKeyTypeLabel(typesKeysLabelRender[data.type]);
                  setKey(data.key);
                }}
              >
                <ButtonMoreDetails options={buttonMoreOptions} />
              </div>
            </div>
          )}
        </>
      ),
    },
  ];

  const [loadingUpdateLimits, setLoadingUpdateLimits] =
    useState<boolean>(false);

  const menusBreadCrumb = [{ label: "Pix", href: "app/pix" }];

  const syncLimitsPix = async () => {
    setLoadingUpdateLimits(true);
    try {
      const { data } = await ApiService.HttpGet({
        route: "pix/sync-limits/",
        token: true,
      });
      setLimitsData(data.data);
      setLoadingUpdateLimits(false);
    } catch (e) {
      console.warn(e);
      setLoadingUpdateLimits(false);
    }
  };

  const getLimitsPix = async () => {
    setLoadingUpdateLimits(true);
    try {
      const { data } = await ApiService.HttpGet({
        route: "pix/limits/",
        token: true,
      });

      setLimitsData(data.data);
      setLoadingUpdateLimits(false);
    } catch (e) {
      console.warn(e);
      syncLimitsPix();
    }
  };

  useEffect(() => {
    getPixKeys();
    getQrCode();
    getBalance();
    getLimitsPix();
  }, [mainAccount]);

  return (
    <Fragment>
      <GeneralContainerData
        {...props}
        isValidatorTokenOpen={
          (openValidatorModalPixCopyAndPaste ||
            openPayCopyAndPasteModal ||
            openValidatorModalTransferWithPix ||
            openTransferWithPixModal ||
            openMyKeysModal ||
            openMyLimitsModal) &&
          true
        }
      >
        <div className="d-flex flex-column container w-100 h-100 ">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />
          <Spacer top={30} />

          <div className=" row ">
            <div className=" d-flex flex-column col-4">
              <ContainerData className=" p-4 ">
                <Title>Meu QR Code</Title>
                <Spacer bottom={10} />
                {dataQRCodeStatic?.emv && (
                  <>
                    {" "}
                    <Description>
                      Esse é o QR Code estático da sua empresa, você pode
                      compartilhar com quem quiser e utilizar para receber
                      qualquer valor ou um valor especificado por você.
                    </Description>
                    <Spacer bottom={20} />
                  </>
                )}

                {dataQRCodeStatic?.emv && (
                  <>
                    <div className="d-flex w-100 justify-content-center">
                      {" "}
                      <img
                        style={{ height: "320px" }}
                        src={dataQRCodeStatic?.image}
                      />
                    </div>

                    <Margin top={20} />
                    {pixKeysData
                      ?.filter((key: any) => {
                        return key?.id === dataQRCodeStatic?.key;
                      })
                      .map((el: any, index: number) => {
                        return (
                          <p key={index}>
                            Chave:{" "}
                            {el?.type === "document_number"
                              ? formatCPFCNPJ(el.key)
                              : el.type === " telefone" ||
                                el.type === " phone" ||
                                el.type === " celphone"
                              ? el.key
                              : el?.key}
                          </p>
                        );
                      })}
                  </>
                )}

                {!dataQRCodeStatic?.emv && (
                  <>
                    <Spacer bottom={40} />
                    <div className="d-flex flex-column align-items-center mt-2">
                      <Rounded className="rounded-circle">
                        {" "}
                        <img src={Timer} />
                      </Rounded>

                      <Spacer bottom={10} />
                      <Description>
                        Nenhum QR Code gerado até o momento
                      </Description>
                      <Spacer bottom={10} />
                      <Description className="text-center" color="#666666">
                        Parece que você não tem nenhum QR Code gerado até o
                        momento para ser exibido
                      </Description>
                    </div>
                  </>
                )}

                {/* <Description>
                  Chave pix: <span className="fw-bold">444.877.968-81</span>
                </Description>
                <Description>
                  Valor: <span className="fw-bold">Definido pelo pagador</span>{" "}
                </Description> */}
                <Spacer bottom={dataQRCodeStatic?.emv ? 40 : 80} />
                <Button
                  label={
                    dataQRCodeStatic?.emv
                      ? "Trocar chave pix"
                      : "Gerar QR Code Estático"
                  }
                  mode="border"
                  full
                  onClick={() => setOpenGenerateQRCodeModal(true)}
                />
              </ContainerData>
            </div>

            <div className="col-8">
              <div className="d-flex justify-content-end">
                <Button
                  label="Pix em lote"
                  mode="border"
                  onClick={() => navigate("/app/pix-lot")}
                  disabled={!canTransfer}
                />
                <Spacer right={15} />
                <Button
                  label="Pagar Pix copia e cola"
                  mode="border"
                  onClick={() => setOpenPayCopyAndPasteModal(true)}
                  disabled={!canTransfer}
                />
                <Spacer right={15} />
                <Button
                  label="Cobrar com QR Code"
                  mode="border"
                  onClick={() => {
                    setSelectedValue("pix");
                    setIsEmpty(false);
                    setChooseTypeAddBalance("pix");
                    setOpenAddBalanceModal(true);
                  }}
                />{" "}
                <Spacer right={15} />
                <Button
                  label="Transferir com Pix"
                  mode="main"
                  onClick={() => {
                    // openModal("transferWithPix");

                    setOpenTransferWithPixModal(true);
                  }}
                  disabled={!canTransfer}
                />
              </div>
              <Spacer bottom={20} />

              <ContainerData className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <Title>Minhas chaves</Title>
                  <div className="d-flex">
                    {" "}
                    <Button
                      label={"Atualizar"}
                      mode="border"
                      onClick={() => getPixKeys()}
                      icon={RefreshIcon}
                    />
                    <Spacer left={10} />
                    <Button
                      label={
                        pixKeysData?.length > 0
                          ? "Gerenciar chaves"
                          : "Cadastrar  chave"
                      }
                      mode="border"
                      onClick={() => setOpenMyKeysModal(true)}
                    />
                  </div>
                </div>

                {loadingPixKeys ? (
                  <Skeleton />
                ) : !loadingPixKeys && pixKeysData?.length > 0 ? (
                  <Table
                    data={pixKeysDataFiltered}
                    columns={columnsMyKeys}
                    dataSpacer={15}
                  />
                ) : (
                  <div className="d-flex justify-content-center ">
                    <Spacer top={120} />
                    <p>
                      <Spacer top={50} />
                      Você não possui nenhuma chave Pix cadastrada no momento.
                    </p>
                  </div>
                )}
              </ContainerData>
              <Spacer bottom={20} />

              <ContainerData className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <Title>Meus limites</Title>
                  {/* <Button
                    label="Gerenciar limites"
                    mode="border"
                    disabled
                    onClick={() => setOpenMyLimitsModal(true)}
                  /> */}

                  <Button
                    label="Atualizar"
                    mode="border"
                    onClick={() => syncLimitsPix()}
                    icon={RefreshIcon}
                  />
                </div>
                <Spacer bottom={20} />
                {loadingUpdateLimits ? (
                  <>
                    <Skeleton />
                  </>
                ) : !loadingUpdateLimits && limitsData.length > 0 ? (
                  <Table
                    data={limitsData}
                    columns={columnsPixLimits}
                    dataSpacer={10}
                  />
                ) : (
                  <Description className="text-center mt-4 mb-3">
                    Nenhuma informação há ser exibida no momento. <br /> Clique
                    em <b> Atualizar</b> no canto superior direito para
                    atualizar seus dados{" "}
                  </Description>
                )}
              </ContainerData>
            </div>
          </div>
          <Spacer top={20} />

          <Copyright />

          <Spacer top={40} />
        </div>
      </GeneralContainerData>

      <PayCopyAndPaste />
      <MyKeysModal />
      <AddNewKeyModal />
      <MyLimitsModal />
      <TransferWithPixModal />
      <GenerateQRCodeModal />
      <AddBalanceModal />
      <InteractiveModal
        open={openDeleteKeyModal}
        size="md"
        toggleModal={() => setOpenDeleteKeyModal(false)}
      >
        <div className="d-flex justify-content-center align-items-center w-100   flex-grow-1">
          {openDeleteKeyModal && (
            <div className="d-flex flex-column align-items-center mt-5  flex-grow-1">
              <div style={{ width: "290px" }}>
                {" "}
                <TitleModal>
                  {`  Tem certeza que deseja remover a chave Pix ${keyTypeLabel} ? `}
                </TitleModal>
              </div>

              <Spacer bottom={10} />
              <div style={{ width: "290px" }}>
                <Description>
                  As transações anteriores não sofrerão alterações.
                </Description>
              </div>
              <Spacer bottom={30} />

              <div style={{ width: "290px" }} className="mb-5">
                <Button
                  mode="main"
                  label="Não, mantenha esta chave"
                  full
                  onClick={() => setOpenDeleteKeyModal(false)}
                />
                <Spacer bottom={5} />
                <Button
                  mode="border"
                  label="Sim, quero remover"
                  full
                  onClick={() => {
                    deleteKeyPix();
                  }}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </div>
      </InteractiveModal>
    </Fragment>
  );
};

export default PIXStructure;
