import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  ReturnButton,
  Spacer,
  TabMenu,
} from "@zilliondigital/mirage-ui";
import React, { Fragment, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import BasicData from "./BasicData";
import Address from "./Address";
import BankAccount from "./BankAccount";
import useNewContact from "~/hooks/Contacts/useNewContact";
import useContacts from "~/hooks/Contacts/useContacts";
import ApiService from "~/services/api";
import useAccount from "~/hooks/useAccount";
import Copyright from "~/components/Copyright";

type Render = {
  [key: string]: React.ReactNode;
};

const GeneralContainerData = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .container-return-button {
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const dataRender: Render = {
  basic_data: <BasicData />,
  address: <Address />,
  bank_data: <BankAccount />,
};

const NewContactStructure = () => {
  const navigate = useNavigate();

  const { setStep, step } = useNewContact();

  const { setSection } = useContacts();

  type menus = {
    label: string;
    value: string;
    onClick?: () => void;
  };

  const menus: menus[] = [
    {
      label: "Dados básicos",
      value: "1",
      // onClick: () => setStep("basic_data"),
    },
    {
      label: "Endereço",
      value: "2",
      // onClick: () => setStep("address"),
    },
    {
      label: "Dados bancários",
      value: "3",
      // onClick: () => setStep("bank_data"),
    },
  ];

  const menusBreadCrumb = [
    { label: "Contatos", onClick: () => navigate("/app/contacts") },
    {
      label: "Novo contato",
    },
  ];

  // const getUserId = async () => {
  //   try {
  //     const { data } = await ApiService.HttpPost({
  //       route: "user-check/",
  //       token: true,
  //       body: {
  //         type: "document_number",
  //         document_number: String(mainAccount?.owner?.document_number),
  //       },
  //     });

  //     setIdUser(data?.response?.user?.id);
  //   } catch (e) {}
  // }; // porque isso foi comentado, como vai pegar o userID?

  useEffect(() => {
    // getUserId();
    return () => {
      setStep("basic_data");
    };
  }, []);

  return (
    <Fragment>
      <GeneralContainerData>
        <div className="d-flex flex-column container w-100 ">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />
          <Spacer top={30} />
          <div style={{ width: "500px" }}>
            <TabMenu menus={menus} />
          </div>
          {dataRender[step]}
          <Spacer top={40} />

          <Copyright />

          <Spacer top={30} />
        </div>
      </GeneralContainerData>
    </Fragment>
  );
};

export default NewContactStructure;
