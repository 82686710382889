import { Button, Spacer, Table } from "@zilliondigital/mirage-ui";
import {
  Card,
  CardDetails,
  CardDetailsSteps,
  GetCardDetailsStatus,
} from "~/hooks/Cards/interfaces";
import TitleModal from "../../../../../components/Texts/TitleModal";
import Description from "../../../../../components/Texts/Description";
import { ActivateCardInputError, DetailsCardInputPassword } from "../styled";
import { useState } from "react";
import { buildCardDetailsColStatements } from "./bankCardDetailsColStatements.tsx";
import formatCurrencyBRL from "~/utils/formatBrl";
import { fromCentsToBRMonetary } from "~/utils/formatters/toMonetary";
import { formatCash } from "~/utils";

interface I_returnModalChildren {
  getCardDetailsStatus: GetCardDetailsStatus;
  getCardDetailsAsync: (password: string, card_id: string) => Promise<void>;
  cardDetails: CardDetails | null;
  setCardDetailsStep: React.Dispatch<React.SetStateAction<CardDetailsSteps>>;
  cardDetailsStep: CardDetailsSteps;
  selectedCard: Card | undefined;
  handleClose: () => void;
}
export const BankCardDetailsStep = ({
  getCardDetailsStatus,
  getCardDetailsAsync,
  cardDetails,
  setCardDetailsStep,
  cardDetailsStep,
  selectedCard,
  handleClose,
}: I_returnModalChildren) => {
  const [password, setPassword] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [buttonLabel, setButtonLabel] = useState<string>("Faltam 6 dígitos");
  console.log(getCardDetailsStatus);
  const columnsStatements = buildCardDetailsColStatements();
  const _returnCardDetails = () => {
    let card_number = cardDetails?.card_number || "";
    if (card_number.length == 16) {
      card_number = `${card_number.slice(0, 4)} ${card_number.slice(
        4,
        8
      )} ${card_number.slice(8, 12)} ${card_number.slice(12, 16)}`;
    }

    let expire_date = cardDetails?.expire_date || "";
    if (expire_date.length == 10) {
      expire_date = `${expire_date.slice(5, 7)}/${expire_date.slice(2, 4)}`;
    }

    return [
      { field: "Cartão", value: selectedCard?.name || "" },
      { field: "Nome impresso", value: selectedCard?.printed_name || "" },
      { field: "Número", value: card_number },
      { field: "Data de vencimento", value: expire_date },
      { field: "CVV", value: cardDetails?.cvv || "" },
      {
        field: "Senha",
        value: cardDetails?.password || "****",
      },
      {
        field: "Limite de Transação",
        value: formatCash(selectedCard?.transaction_limit || 0),
      },
    ];
  };

  const _handleConfirmToken = () => {
    if (password.length == 6 && selectedCard) {
      getCardDetailsAsync(password, selectedCard.card_id);
    }
  };

  const _handleInputChange = (value: string) => {
    setPassword(value);

    const no_digits = value.length;
    if (no_digits == 6) {
      setButtonLabel("Confirmar");
      setIsDisabled(false);
    } else {
      setButtonLabel(`Faltam ${6 - no_digits} dígitos`);
      setIsDisabled(true);
    }
  };

  const _returnErrorMessage = (status: GetCardDetailsStatus) => {
    if (status == "SERVER_ERROR") {
      return (
        <ActivateCardInputError>
          Houve um erro no processamente de sua requisição, tente novamente.
        </ActivateCardInputError>
      );
    } else if (status == "AUTHENTICATION_ERROR") {
      return <ActivateCardInputError>Senha incorreta.</ActivateCardInputError>;
    } else {
      return <></>;
    }
  };

  switch (cardDetailsStep) {
    case "CONFIRMATION":
      return (
        <div className="d-flex flex-column flex-grow-1">
          <TitleModal>{"Verificação de segurança"}</TitleModal>
          <Spacer bottom={10} />
          <Description>{"Insira o token abaixo para continuar"}</Description>
          <Spacer bottom={30} />

          <DetailsCardInputPassword
            name="code_for_card_block"
            type="password"
            placeholder="Token"
            maxLength={6}
            onKeyDown={(e) => {
              e.key == "Enter" && _handleConfirmToken();
            }}
            onChange={(e) => {
              _handleInputChange(e.currentTarget.value);
            }}
            disabled={getCardDetailsStatus == "LOADING"}
          />
          {getCardDetailsStatus &&
          ["SERVER_ERROR", "AUTHENTICATION_ERROR"].includes(
            getCardDetailsStatus
          ) ? (
            _returnErrorMessage(getCardDetailsStatus)
          ) : (
            <></>
          )}
          <Spacer bottom={40} />
          <Button
            mode="main"
            label={
              getCardDetailsStatus == "LOADING" ? "Processando" : buttonLabel
            }
            full
            onClick={_handleConfirmToken}
            disabled={getCardDetailsStatus == "LOADING" ? true : isDisabled}
          />
        </div>
      );

    case "SUCCESS":
      return (
        <div className="d-flex flex-column flex-grow-1">
          <TitleModal>{"Detalhes do cartão"}</TitleModal>
          <Spacer bottom={10} />
          <Description>{"Guarde os dados abaixo em local seguro."}</Description>
          <Spacer bottom={30} />

          <Table
            data={_returnCardDetails()}
            columns={columnsStatements}
            dataSpacer={8}
          />

          <Spacer bottom={40} />
          <Button
            mode="main"
            label={"Voltar"}
            full
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      );
    default:
      return <></>;
  }
};
