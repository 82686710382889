import {
  Button,
  CustomSelect,
  Input,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useEffect, useState } from "react";
import Description from "~/components/Texts/Description";
import * as Yup from "yup";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import { motion } from "framer-motion";
import ApiService from "~/services/api";
import useBanks from "~/hooks/useBanks";
import { useFormik } from "formik";
import {
  INVALID_FORMAT,
  REQUIRED_LABEL,
  cnpjCpfRegex,
} from "~/services/validators";
import { onlyNumbers } from "~/services/utils";
import useToast from "~/hooks/useToast";
import documentNumberValidator from "~/utils/validateDocNumber";

type FormValues = {
  name: string;
  document_number: string;
  email: string;
  account_number_with_digit: string;
  agency: string;
};

const initialValues: FormValues = {
  name: "",
  document_number: "",
  email: "",
  account_number_with_digit: "",
  agency: "",
};

const FormSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_LABEL),
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(cnpjCpfRegex, INVALID_FORMAT),
  email: Yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
  account_number_with_digit: Yup.string().required(REQUIRED_LABEL),

  agency: Yup.string().required(REQUIRED_LABEL),
});

const ManualPixData = () => {
  const {
    setManualPix,
    handleNextStep,
    dataNewPixTransfer,
    setDataNewPixTransfer,
  } = useTransferWithPix();

  const { getBanks, bankOptions, banksData } = useBanks();

  const { showToast } = useToast();

  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleCancel = () => {
    setManualPix(false);
  };

  const handleSelectChange = (selectedValue: any) => {
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    banksData?.length <= 0 && getBanks();
  }, []);

  const bankCode = selectedOption?.target.value;

  const { values, errors, handleBlur, touched, handleChange, resetForm } =
    useFormik({
      initialValues: initialValues,
      validationSchema: FormSchema,
      onSubmit: (values) => {
        handleNextStep();
      },
    });

  const removeLastCharacter = (str: string) => {
    return str.slice(0, -1);
  };

  const getLastCharacter = (str: string) => {
    return str.slice(-1);
  };

  const registerPartnerPayload = {
    account_number: removeLastCharacter(values.account_number_with_digit),
    account_number_digit: getLastCharacter(values.account_number_with_digit),
    agency: values.agency,
    bank: bankCode,
    document_type: values.document_number.length > 14 ? "cnpj" : "cpf",
    document_number: onlyNumbers(values.document_number),
    email: "pix@prosperita.io",
    name: values.name,
  };

  const isValid =
    values.name.length >= 2 &&
    values.agency.length >= 2 &&
    values.account_number_with_digit.length >= 2 &&
    values.document_number.length >= 14 &&
    bankCode !== undefined;

  const registerPartner = async () => {
    setIsLoading(true);

    try {
      const data = await ApiService.HttpPost({
        route: "pix/partners/",
        body: registerPartnerPayload,
        token: true,
      });

      setDataNewPixTransfer(data);

      setIsLoading(false);
      handleNextStep();
    } catch (e: any) {
      setIsLoading(false);
      if (e.response.data[0] === "Partner already exists!") {
        showToast(
          "Parceiro já existe",
          "Você não pode cadastrar esse parceiro pois ele já existe em nossa base de dados",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  const isValidDocument =
    values.document_number.length >= 14 &&
    documentNumberValidator(values.document_number);

  useEffect(() => {
    if (values.document_number.length < 14 && !isValidDocument) {
      setError("initialState");
    } else if (values.document_number.length >= 14 && isValidDocument) {
      setError("notError");
    } else if (values.document_number.length >= 14 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document_number]);

  return (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "-100%" }}
        transition={{ duration: 0.3 }}
        style={{ overflow: "hidden" }}
      >
        <div className="p-3 " style={{ overflow: "hidden ", height: "85vh" }}>
          <Spacer bottom={20} />
          <div className="d-flex justify-content-center">
            <Description color="#666666" className="">
              Para continuar é necessário informar os dados de quem receberá a
              transferência
            </Description>
          </div>
          <Spacer bottom={50} />
          <div className="row align-items-end">
            <div className="col-6">
              {/* <CustomSelect
                label="Tipo de conta"
                placeholder="Selecione"
                options={accountType}
              /> */}

              <Input
                name="name"
                label="Nome/Razão Social"
                placeholder="Jhon Doe"
                value={values.name}
                onChange={handleChange}
              />
            </div>
            <div className="col-6">
              {" "}
              <Input
                name="document_number"
                label="CPF/CNPJ"
                placeholder="Informe o documento"
                success={error === "notError"}
                error={error === "Documento inválido" && error}
                value={values.document_number}
                onChange={handleChange}
                mask={
                  values.document_number.length > 14
                    ? "99.999.999/9999-99"
                    : "999.999.999-9999999"
                }
              />
            </div>

            <div className="col-12">
              <CustomSelect
                label="Instituição"
                placeholder="Selecione"
                options={bankOptions}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-6">
              {" "}
              <Input
                name="agency"
                label="Agência"
                value={values.agency}
                onChange={handleChange}
                placeholder="0001"
                helper="Sem dígito"
                mask="99999999999999999999999999"
              />
            </div>
            <div className="col-6">
              {" "}
              <Input
                name="account_number_with_digit"
                value={values.account_number_with_digit}
                onChange={handleChange}
                label="Conta"
                placeholder="12456780"
                helper="Com dígito"
                mask="9999999999999999999999999999999"
              />
            </div>
          </div>

          <Spacer bottom={50} />

          <div className="d-flex w-100 justify-content-between pe-4 ps-2">
            <Button mode="phantom" label="Cancelar" onClick={handleCancel} />
            <Button
              mode="main"
              label="Avançar"
              disabled={!isValid || error !== "notError"}
              loading={isLoading}
              onClick={registerPartner}
            />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ManualPixData;
