import { Spacer } from "@zilliondigital/mirage-ui";
import Loader from "~/components/Loader";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1;
  display: flex;

  .container-1 {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .dash-info {
    background-color: #f2f7fb;
    border-radius: 8px;
    width: 100%;
    min-height: 80px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    margin: 0 8px 8px 0;
    width: 96px;
    min-height: 93px;

    &:last-child {
      margin-right: 0px !important;
    }
  }
`;

const AvalaibleBalanceLoader = () => {
  return (
    <Container>
      <div className="container-1">
        <div className="pt-3">
          <Loader
            x=""
            y=""
            rx="4"
            ry="4"
            widthContainer="60%"
            isHeight="15"
            isWidth="60%"
            box="0 0 60% 15"
            heightContainer="15"
          />
          <Spacer bottom={5} />
          <Loader
            x=""
            y=""
            rx="4"
            ry="4"
            widthContainer="85%"
            isHeight="60"
            isWidth="85%"
            box="0 0 85% 60"
            heightContainer="60"
          />
        </div>
        <Spacer bottom={15} />

        <div className="">
          {" "}
          <Loader
            x=""
            y=""
            rx="4"
            ry="4"
            widthContainer="100%"
            isHeight="40"
            isWidth="100%"
            box="0 0 100% 40"
            heightContainer="40"
          />
        </div>
        <Spacer bottom={25} />
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="actions">
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="100%"
              isHeight="90"
              isWidth="100%"
              box="0 0 100% 90"
              heightContainer="90"
            />
          </div>
          <div className="actions">
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="100%"
              isHeight="90"
              isWidth="100%"
              box="0 0 100% 90"
              heightContainer="90"
            />
          </div>
          <div className="actions">
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="100%"
              isHeight="90"
              isWidth="100%"
              box="0 0 100% 90"
              heightContainer="90"
            />
          </div>

          <div className="">
            <Spacer bottom={100} />{" "}
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="100%"
              isHeight="35"
              isWidth="100%"
              box="0 0 100% 35"
              heightContainer="35"
            />
          </div>

          <div className="mt-2">
            {" "}
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="85%"
              isHeight="15"
              isWidth="85%"
              box="0 0 85% 15"
              heightContainer="15"
            />
            <Spacer bottom={0} />
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="65%"
              isHeight="15"
              isWidth="65%"
              box="0 0 65% 15"
              heightContainer="15"
            />
          </div>

          <div className="">
            <Spacer bottom={20} />{" "}
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="85%"
              isHeight="15"
              isWidth="85%"
              box="0 0 85% 15"
              heightContainer="15"
            />
            <Spacer bottom={0} />
            <Loader
              x=""
              y=""
              rx="4"
              ry="4"
              widthContainer="65%"
              isHeight="15"
              isWidth="65%"
              box="0 0 65% 15"
              heightContainer="15"
            />
          </div>
        </div>
        <Spacer bottom={132} />
      </div>
    </Container>
  );
};

export default AvalaibleBalanceLoader;
