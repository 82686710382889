import styled from "styled-components";


const TableCardName = styled.p`
  color: #242424;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 8px;
  margin-bottom: 8px;
`

const TableCardSubName = styled.p`
  color: #929292;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-left: 8px;
  margin-bottom: 0px;
`

export const TablePill = styled.div`
  border-radius: 24px;
  padding: 8px 16px;
`

//MY CARDS COMPONENTS
export const StatusMyCardsTablePill = styled(TablePill)`
  
  &.ACTIVATED {
    background-color: #A7F0BA;
    color: #0E6027;
  }

  &.BLOCKED {
    background-color: #F4F4F4;
    color: #16161640;
  }

  &.CANCELED {
    background-color: #FFD7D9;
    color: #A2191F;
  }

  &.PROCESSING {
    background-color: #D0E2FF;
    color: #0043CE;
  }

  &.PENDING {
    background-color: #D0E2FF;
    color: #0043CE;
  }
`

export const MyCardsTableCardName = styled(TableCardName)``

export const MyCardsTableCardSubName = styled(TableCardSubName)``

export const MyCardsTableOptions = styled.div`
  cursor: pointer;
  

  &:hover {
    filter: brightness(0) saturate(100%) invert(49%) sepia(71%) saturate(2440%)
      hue-rotate(330deg) brightness(89%) contrast(102%);
  }
`

////Recent Transactions Table
export const StatusRecentTransactionsTablePill = styled(TablePill)`
  &.FINISHED {
    background-color: #A7F0BA;
    color: #0E6027;
  }

  &.SCHEDULED {
    background-color: #FCF4D6;
    color: #d3a501;
  }

  &.CANCELED {
    background-color: #FFD7D9;
    color: #E65244;
  }

  &.PROCESSING {
    background-color: #F4F4F4;
    color: #16161640;
  }
`

export const RecentTransactionsTableCardName = styled(TableCardName)``

export const RecentTransactionsTableCardSubName = styled(TableCardSubName)``

export const RecentTransactionsTableCarValue = styled(TableCardName)`
  &.positive {
    color: #49D793;
  }

  &.negative {
    color: #E65244;
  }
`

