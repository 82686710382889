import "bootstrap/dist/css/bootstrap.min.css";
import { Spacer } from "@zilliondigital/mirage-ui";
import RepeatImg from "~/assets/images/repeat--lg--black.svg"
import "~/styles/reset.css";
import Title from "~/components/Texts/Title";
import Description from "~/components/Texts/Description";
import styled from "styled-components";

const EmptyImg = styled.img`
  width: 96px;
  height: 96px;
`;

const RecentTransactionsEmpty = () => {
  return (
    <div className="
      w-100 pt-3 d-flex flex-column 
      align-items-center justify-content-center
      gap-5
    ">
      <EmptyImg src={RepeatImg} />
      
      <Description className="text-center">
        Você não possui transações recentes para serem exibidas
      </Description>
    </div>
  );
};

export default RecentTransactionsEmpty;
