import constate from "constate";

import { useEffect, useState } from "react";
import ApiService from "~/services/api";
import useAccount from "./useAccount";
import useToken from "./useToken";
import useToast from "./useToast";
import { addDays } from "date-fns";
import { formatDateTOStringEN } from "~/utils";
import useMyKeys from "./PIX/useMyKeys";

export type ChooseTypeAddBalanceProps = "billet" | "pix" | null;

const [AddBalanceProvider, useAddBalance] = constate(() => {
  const [changeSideAnimation, setChangeSideAnimation] =
    useState<boolean>(false);
  const [openAddBalanceModal, setOpenAddBalanceModal] = useState(false);
  const [openValidatorModalAddBalance, setOpenValidatorModalAddBalance] =
    useState<boolean>(false);
  const [rechargeDueDate, setRechargeDueDate] = useState<string>();
  const [chooseTypeAddBalance, setChooseTypeAddBalance] =
    useState<ChooseTypeAddBalanceProps>(null);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState("");
  const [newChargeData, setNewChargeData] = useState<any>({});
  const [amount, setAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyPixData, setKeyPixData] = useState<any[]>([]);
  const [indexPixKeyToCharge, setIndexPixKeyToCharge] = useState<number>(-1);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);

  const { token, getToken } = useToken();
  const { mainAccount } = useAccount();
  const { showToast } = useToast();
  const { pixKeysData, keyData } = useMyKeys();

  const [taxGenerateBillet, setTaxGenerateBillet] = useState<number>(0);
  const [taxLiquidateBillet, setTaxLiquidateBillet] = useState<number>(0);
  const [taxGenerateQRCodeStatic, setTaxGenerateQRCodeStatic] =
    useState<number>(0);
  const [taxLiquidateQRCodeStatic, setTaxLiquidateQRCodeStatic] =
    useState<number>(0);

  const isPIX = chooseTypeAddBalance === "pix";

  const handleNextStep = () => {
    setStepIndex((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    setStepIndex((prev) => prev - 1);
  };

  const getNextDay = (): { isoFormat: string; brFormat: string } => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const isoFormat = `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${tomorrow.getDate().toString().padStart(2, "0")}`;

    const brFormat = `${tomorrow.getDate().toString().padStart(2, "0")}/${(
      tomorrow.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${tomorrow.getFullYear()}`;

    return { isoFormat, brFormat };
  };

  const { isoFormat } = getNextDay();

  const getDiscountDate = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "account/parameter-billet/",
        token: true,
      });

      if (data) {
        const response_results = data.results[0];
        if (response_results.to_discount) {
          const days: number = response_results.discount_days;
          const due_date = addDays(new Date(), days);

          setRechargeDueDate(formatDateTOStringEN(due_date));
        }
      }
    } catch (err: any) {}
  };

  const addBalanceBillet = async () => {
    const description = "Boleto para recarga de saldo";

    setIsLoading(true);
    setOpenValidatorModalAddBalance(false);
    try {
      const { data } = await ApiService.HttpPost({
        route: "charge/billet/",
        token: true,
        body: {
          due_date: rechargeDueDate || isoFormat,
          amount: amount,
          wallet_type: "external",
          description: description,
          receiver: mainAccount?.bank_account_data.partner,
          payer: mainAccount?.bank_account_data.partner,
          bank: mainAccount?.bank_account_data.bank,
          code: token,
        },
      });

      setNewChargeData(data);
      handleNextStep();
      setIsLoading(false);

      setAmount(0);
    } catch (err: any) {
      showToast("Boleto não gerado!", err.response.data.data, "error");
      setIsLoading(false);
    }
  };

  const getPixKeys = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "pix/key/",
        token: true,
      });

      setKeyPixData(data.results);
    } catch {}
  };

  const getQrCode = async (idQrCode: number) => {
    try {
      const { data } = await ApiService.HttpGet({
        route: `pix/qrcode/${idQrCode}/`,
        token: true,
      });

      setNewChargeData(data.data);
      handleNextStep();
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
      setIsLoading(false);
    }
  };

  const addBalancePix = async () => {
    const description = "Pix para recarga de saldo";

    setIsLoading(true);
    setOpenValidatorModalAddBalance(false);
    setAmount(0);

    try {
      const { data } = await ApiService.HttpPost({
        route: "pix/qrcode/",
        token: true,
        body: {
          amount: amount,
          description: description,
          key: pixKeysData[0].id,
          token: token,
        },
      });

      const idQrCode = data.data.id;

      setTimeout(() => {
        if (idQrCode) getQrCode(idQrCode);
      }, 5000);
    } catch (err: any) {
      setStepIndex(0);
      setIndexPixKeyToCharge(-1);

      showToast(
        err.response.data.data.message,
        "Selecione outra chave pix ou tente novamente mais tarde",
        "error"
      );

      setIsLoading(false);
    }
  };

  const fetchGenerateCollectionPixQrCode = async () => {
    const description = "Pix para recarga de saldo";
    const token = await getToken();
    setIsLoading(true);
    setOpenValidatorModalAddBalance(false);
    setAmount(0);

    try {
      const { data } = await ApiService.HttpPost({
        route: "pix/qrcode/",
        token: true,
        body: {
          amount: amount,
          description: description,
          key: keyData[0].id,
          token: token,
        },
      });

      const idQrCode = data.data.id;

      setTimeout(() => {
        if (idQrCode) getQrCode(idQrCode);
      }, 5000);
    } catch (err: any) {
      setStepIndex(0);
      setIndexPixKeyToCharge(-1);

      showToast(
        err.response.data.data.message,
        "Selecione outra chave pix ou tente novamente mais tarde",
        "error"
      );

      setIsLoading(false);
    }
  };

  return {
    isEmpty,
    setIsEmpty,
    stepIndex,
    setStepIndex,
    chooseTypeAddBalance,
    setChooseTypeAddBalance,
    handleNextStep,
    handlePrevStep,
    openAddBalanceModal,
    setOpenAddBalanceModal,
    newChargeData,
    setNewChargeData,
    addBalanceBillet,
    addBalancePix,
    amount,
    setAmount,
    openValidatorModalAddBalance,
    setOpenValidatorModalAddBalance,
    isLoading,
    setIsLoading,
    isPIX,
    changeSideAnimation,
    setChangeSideAnimation,
    selectedValue,
    setSelectedValue,
    getNextDay,
    getPixKeys,
    keyPixData,
    setKeyPixData,
    indexPixKeyToCharge,
    setIndexPixKeyToCharge,
    rechargeDueDate,
    setRechargeDueDate,
    getDiscountDate,
    fetchGenerateCollectionPixQrCode,
    taxGenerateBillet,
    setTaxGenerateBillet,
    taxGenerateQRCodeStatic,
    setTaxGenerateQRCodeStatic,
    taxLiquidateBillet,
    setTaxLiquidateBillet,
    taxLiquidateQRCodeStatic,
    setTaxLiquidateQRCodeStatic,
  };
});

export { AddBalanceProvider };

export default useAddBalance;
