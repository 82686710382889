import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  ContainerData,
  GeneralContainer,
  ImgEye,
  Title,
} from "../app-content.styles";
import {
  BalanceAlert,
  Breadcrumb,
  Button,
  CustomSelect,
  Dropzone,
  NotControlledToolTip,
  ReturnButton,
  Spacer,
  SummaryPanel,
  TabMenu,
  Table,
} from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import useToast from "~/hooks/useToast";
import ApiService from "~/services/api";
import styled, { keyframes } from "styled-components";
import {
  APIResponse,
  CnabReaderResponse,
  Columns,
  ParsedBillet,
} from "~/types";
import formatCurrencyBRL from "~/utils/formatBrl";
import SuccessIcon from "~/assets/icons/status/done_black_24dp (1).svg";
import ErrorIcon from "~/assets/icons/status/error.png";
import EmptyIcon from "~/assets/images/EmptyImages/files, folder, file, sort, man.png";
import {
  dateFormatterBR,
  formatCPFCNPJ,
  formatDate,
  formatDateTime,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import confirmOperation from "~/components/AsyncConfirmModal";
import Copyright from "~/components/Copyright";
import useCollections from "~/hooks/Collections/useCollections";
import NewCnabModal from "./NewCnabModal";
import DotStatus from "~/components/DotStatus";
import PaidIcon from "~/assets/icons/status/ic_functional_status_paid.svg";
import ReceivedIcon from "~/assets/icons/status/ic_functional_status_received.svg";
import Description from "~/components/Texts/Description";

import EyeIcon from "~/assets/icons/eye-Size=MD.svg";
import theme from "~/themes/theme";
import Badge from "~/components/Badge";
import useAccount from "~/hooks/useAccount";

const SummaryWrapper = styled.div`
  width: 100%;
  .summary-container {
    span {
      padding-left: 0 !important;
    }
  }
`;

const typeDictionary: { [key: string]: string } = {
  out: "Remessa",
  in: "Retorno",
  register: "Registrado",
};

type GetStatusType = {
  [key: string]: JSX.Element;
};

const getStatus: GetStatusType = {
  processing: (
    <Badge label="Processando" color={"#FEE7CC"} textColor={"#FC8802"} />
  ),
  validating: (
    <Badge label="Processando" color={"#FEE7CC"} textColor={"#FC8802"} />
  ),
  done: (
    <Badge
      label="Processado"
      color={"#CCEFDF"}
      textColor={"#00B360"}
      icon={SuccessIcon}
    />
  ),
  error: (
    <div className="d-flex justify-content-center">
      <img src={ErrorIcon} />
    </div>
  ),
};

const CnabStructure = () => {
  const menusBreadCrumb = [
    { label: "Cobranças", onClick: () => navigate("/app/collections") },
    { label: "Cobranças em lote" },
  ];

  const menus = [
    {
      label: "Remessa",
      value: "1",
      onClick: () => setStep("out"),
    },
    {
      label: "Retorno",
      value: "2",
      onClick: () => setStep("in"),
    },
  ];

  const navigate = useNavigate();
  const { showToast } = useToast();
  const {
    setFile,
    setSubmitting,
    sending,
    setSending,
    operation,
    details,
    setDetails,
    setOpenNewCnabModal,
    fetchCnabOperations,
    cnabOperations,
    taxBilletLot,
    setTaxBilletLot,
  } = useCollections();

  const {
    verifyPermission,
    mainAccount,
    setActiveIndexSideBar,
    getBalance,
    balance,
  } = useAccount();

  const [step, setStep] = useState<string>("out");

  const canCreateCnab: boolean = verifyPermission("ba_can_create_cnab");

  const cnabOperationsOut = cnabOperations.filter((el) => el.type === "out");
  const cnabOperationsIn = cnabOperations.filter(
    (el) => el.type === "in" || el.type === "register"
  );

  const insuficientBalance = useMemo(() => {
    return balance < taxBilletLot * details.length;
  }, [balance, taxBilletLot]);

  const operationTotal = useMemo(() => {
    if (details.length === 0) return 0;

    return details.reduce((prev, curr) => prev + curr.amount, 0);
  }, [details]);

  const sendOperation = async () => {
    setSending(true);

    try {
      await confirmOperation({
        title: "Confirmação",
        description: "Deseja confirmar a operação?",
      });

      const { data } = await ApiService.HttpPost<APIResponse<any>>({
        route: "cnab/operation/",
        body: {
          operation,
          billets: details,
        },
        token: true,
      });

      fetchCnabOperations();
      showToast("Sucesso", data.message, "success");

      onRemoveFile(null);
    } catch (error) {
      console.error("Error on sendOperation", error);
    }

    setSending(false);
  };

  const CNABsPainelOut = useMemo(() => {
    const totalQuantity = cnabOperationsOut.reduce(
      (prev, curr) => prev + curr.total_quantity,
      0
    );

    return [
      {
        label: "Total de lotes",
        balance: String(cnabOperations.length),
      },
      {
        label: "Total de transações individuais",
        balance: String(totalQuantity),
      },
    ];
  }, [cnabOperations]);

  const CNABsPainelIn = useMemo(() => {
    const totalQuantity = cnabOperationsIn.reduce(
      (prev, curr) => prev + curr.total_quantity,
      0
    );

    return [
      {
        label: "Total de lotes",
        balance: String(cnabOperations.length),
      },
      {
        label: "Total de transações individuais",
        balance: String(totalQuantity),
      },
    ];
  }, [cnabOperations]);

  const onRemoveFile = (removed: any) => {
    setFile("");
    setDetails([]);
    setSubmitting(false);
  };

  const operation_columns_Out: Columns[] = [
    {
      key: "status",
      label: "Status",
      renderData: (data: any) => <>{getStatus[data.status]}</>,
    },
    {
      key: "bank",
      label: "Banco",
      renderData: (data: any) => (
        <span className="text-capitalize">{data.bank}</span>
      ),
    },
    {
      key: "layout",
      label: "Layout",
    },
    {
      key: "type",
      label: "Tipo",
      renderData: (data: any) => <span>{typeDictionary[data.type]}</span>,
    },
    {
      key: "total_quantity",
      label: "Total",
      renderData: (data: any) => (
        <div className="d-flex justify-content-center">
          <span>{data.total_quantity}</span>
        </div>
      ),
    },
    {
      key: "total_amount",
      label: "Total (R$)",
      renderData: (data: any) => (
        <span>{formatCurrencyBRL(data.total_amount)}</span>
      ),
    },
    {
      key: "total_success_quantity",
      label: "Sucessos",
      renderData: (data: any) => (
        <div className="d-flex justify-content-center">
          <span>{data.total_success_quantity}</span>
        </div>
      ),
    },
    {
      key: "total_success_amount",
      label: "Sucessos (R$)",
      renderData: (data: any) => (
        <span>{formatCurrencyBRL(data.total_success_amount)}</span>
      ),
    },
    {
      key: "total_failed_amount",
      label: "Falhas",
      renderData: (data: any) => (
        <div className="d-flex justify-content-center">
          <span>{data.total_failed_amount}</span>
        </div>
      ),
    },
    {
      key: "total_failed_amount",
      label: "Falhas (R$)",
      renderData: (data: any) => (
        <span>{formatCurrencyBRL(data.total_failed_amount)}</span>
      ),
    },
    {
      key: "created_date",
      label: "Data de envio",
      renderData: (data: any) => (
        <span>{formatDateTime(data.created_date)}</span>
      ),
    },
    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            <>
              {" "}
              <Spacer left={10} />
              <div>
                <ImgEye
                  id={`eye-${data.id}`}
                  onClick={() => {
                    window.open(
                      data.type == "out" ? data.file : data.file_url,
                      "_blank"
                    );
                  }}
                  src={EyeIcon}
                />
              </div>
            </>
            <NotControlledToolTip placement="top" target={`eye-${data.id}`}>
              {"Visualizar detalhes"}
            </NotControlledToolTip>
          </div>
        );
      },
    },
  ];

  const operation_columns_In: Columns[] = [
    {
      key: "status",
      label: "Status",
      renderData: (data: any) => <>{getStatus[data.status]}</>,
    },
    {
      key: "bank",
      label: "Banco",
      renderData: (data: any) => (
        <span className="text-capitalize">{data.bank}</span>
      ),
    },
    {
      key: "layout",
      label: "Layout",
    },
    {
      key: "type",
      label: "Tipo",
      renderData: (data: any) => <span>{typeDictionary[data.type]}</span>,
    },
    {
      key: "total_quantity",
      label: "Total",
      renderData: (data: any) => (
        <div className="d-flex justify-content-center">
          <span>{data.total_quantity}</span>
        </div>
      ),
    },
    {
      key: "total_amount",
      label: "Total (R$)",
      renderData: (data: any) => (
        <span>{formatCurrencyBRL(data.total_amount)}</span>
      ),
    },
    {
      key: "total_success_quantity",
      label: "Sucessos",
      renderData: (data: any) => (
        <div className="d-flex justify-content-center">
          <span>{data.total_success_quantity}</span>
        </div>
      ),
    },
    {
      key: "total_success_amount",
      label: "Sucessos (R$)",
      renderData: (data: any) => (
        <span>{formatCurrencyBRL(data.total_success_amount)}</span>
      ),
    },
    {
      key: "total_failed_amount",
      label: "Falhas",
      renderData: (data: any) => (
        <div className="d-flex justify-content-center">
          <span>{data.total_failed_amount}</span>
        </div>
      ),
    },
    {
      key: "total_failed_amount",
      label: "Falhas (R$)",
      renderData: (data: any) => (
        <span>{formatCurrencyBRL(data.total_failed_amount)}</span>
      ),
    },
    {
      key: "created_date",
      label: "Data de envio",
      renderData: (data: any) => (
        <span>{formatDateTime(data.created_date)}</span>
      ),
    },
    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            <>
              {" "}
              <Spacer left={10} />
              <div>
                <ImgEye
                  id={`eye-${data.id}`}
                  onClick={() => {
                    window.open(
                      data.type == "out" ? data.file : data.file_url,
                      "_blank"
                    );
                  }}
                  src={EyeIcon}
                />
              </div>
            </>
            <NotControlledToolTip placement="top" target={`eye-${data.id}`}>
              {"Visualizar detalhes"}
            </NotControlledToolTip>
          </div>
        );
      },
    },
  ];

  const validation_columns: Columns[] = [
    {
      key: "payer_name",
      label: "Pagador",
      renderData: (data: any) => {
        return data.payer.name;
      },
    },
    {
      key: "payer_document",
      label: "CPF/CNPJ",
      renderData: (data: any) => {
        return formatCPFCNPJ(data.payer.document_number);
      },
    },
    {
      key: "amount",
      label: "Valor",
      renderData: (data: any) => {
        return formatCurrencyBRL(data.amount);
      },
    },
    {
      key: "discount_amount",
      label: "Desconto",
      renderData: (data: any) => {
        return formatCurrencyBRL(data.discount_amount);
      },
    },
    {
      key: "fine_amount",
      label: "Multa",
      renderData: (data: any) => {
        return data.fine_type == "1"
          ? formatCurrencyBRL(data.fine_amount)
          : `${data.fine_amount.toFixed(2)}%`;
      },
    },
    {
      key: "late_fee_amount",
      label: "Juros",
      renderData: (data: any) => {
        return data.late_fee_type == "1"
          ? formatCurrencyBRL(data.late_fee_amount)
          : `${data.late_fee_amount.toFixed(2)}%`;
      },
    },
    {
      key: "our_number",
      label: "Nosso Nº",
      renderData: (data: any) => {
        return data.our_number;
      },
    },
    {
      key: "document_number",
      label: "Nº do Documento",
      renderData: (data: any) => {
        return data.document_number;
      },
    },
    {
      key: "due_date",
      label: "Dt de Vencimento",
      renderData: (data: any) => {
        return dateFormatterBR(data.due_date);
      },
    },
    {
      key: "deadline_date",
      label: "Dt de Expiração",
      renderData: (data: any) => {
        return dateFormatterBR(data.deadline_date);
      },
    },
  ];

  const fetchTax = async () => {
    try {
      const response = await ApiService.HttpPost({
        route: "customer-global-tax/",
        token: true,
        body: {
          slug: "billet_lot",
          tax_is_enabled: true,
        },
      });

      setTaxBilletLot(response.data.customer_tax_out);
    } catch (err) {
      console.error("Erro", err);
    }
  };

  useEffect(() => {
    fetchCnabOperations();
    fetchTax();
    getBalance();
    return () => {
      setStep("out");
    };
  }, [mainAccount]);

  return (
    <Fragment>
      <GeneralContainer>
        <div className="d-flex flex-column  w-100 h-100 ps-5 pe-5">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />

          <Spacer top={30} />
          <ContainerData>
            {details.length === 0 && (
              <Fragment>
                <div className="p-4 d-flex justify-content-between">
                  <div>
                    {" "}
                    <Title>Cobrança em Lote (CNAB)</Title>
                    <TabMenu menus={menus} />
                  </div>

                  <div>
                    <Button
                      mode="main"
                      label="Nova cobrança em lote"
                      onClick={() => setOpenNewCnabModal(true)}
                      disabled={!canCreateCnab}
                    />
                  </div>
                </div>

                {cnabOperations.length > 0 ? (
                  <Fragment>
                    {step === "out" && (
                      <div>
                        {cnabOperationsOut.length > 0 ? (
                          <>
                            <SummaryPanel
                              panel={CNABsPainelOut}
                              // isLoading={loadingList}
                            />

                            <div className="p-4 w-100">
                              <div>
                                <div className="table-responsive">
                                  <Table
                                    columns={operation_columns_Out}
                                    data={cnabOperationsOut}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div>
                            <Spacer bottom={70} />
                            <div className="d-flex justify-content-center">
                              <img src={EmptyIcon} />
                            </div>
                            <div className="d-flex flex-column">
                              <Title className="text-center">
                                Nenhuma operação do tipo Remessa encontrada no
                                momento
                              </Title>
                              <Spacer bottom={20} />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {step === "in" && (
                      <div>
                        {cnabOperationsIn.length > 0 ? (
                          <>
                            <SummaryPanel
                              panel={CNABsPainelIn}
                              // isLoading={loadingList}
                            />

                            <div className="p-4 w-100">
                              <div>
                                <div className="table-responsive">
                                  <Table
                                    columns={operation_columns_In}
                                    data={cnabOperationsIn}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div>
                            <Spacer bottom={70} />
                            <div className="d-flex justify-content-center">
                              <img src={EmptyIcon} />
                            </div>
                            <Spacer bottom={20} />
                            <div className="d-flex flex-column">
                              <Title className="text-center">
                                Nenhuma operação do tipo Retorno encontrada no
                                momento
                              </Title>
                              <Spacer bottom={20} />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <div>
                    <Spacer bottom={70} />
                    <div className="d-flex flex-column">
                      <Title className="text-center">
                        Nenhuma operação de Cobrança em lote até o momento
                      </Title>
                      <Spacer bottom={20} />
                      <Description className="text-center">
                        Para começar uma nova operação de Cobrança em <br />{" "}
                        lote, basta clicar em
                        <br /> Nova cobrança em lote localizado no <br /> canto
                        superior direito
                      </Description>
                    </div>
                  </div>
                )}
              </Fragment>
            )}

            {details.length > 0 && (
              <Fragment>
                <div className="p-4 d-flex justify-content-between">
                  <Title>Resultado da validação</Title>
                  <div className="d-flex  ">
                    <div>
                      <Button
                        mode="border"
                        onClick={() => setDetails([])}
                        label="Cancelar"
                        full
                      />
                    </div>
                    <Spacer right={15} />
                    <div>
                      <Button
                        mode="border"
                        onClick={() => setOpenNewCnabModal(true)}
                        label="Alterar arquivo"
                        full
                      />
                    </div>
                    <Spacer right={15} />
                    <div>
                      <Button
                        mode="main"
                        onClick={sendOperation}
                        label={sending ? "Enviando" : "Confirmar Operação"}
                        iconPosition="right"
                        loading={sending}
                        disabled={sending || insuficientBalance}
                        full
                      />
                    </div>
                  </div>
                </div>

                <SummaryWrapper>
                  <div className="summary-container">
                    <SummaryPanel
                      panel={[
                        {
                          label: "Total de linhas",
                          value: String(details.length),
                        },
                        {
                          label: "Valor total das Taxas",
                          value: `R$ ${formatNumberToCurrencyWithoutSimbol(
                            taxBilletLot * details.length
                          )}`,
                        },
                        {
                          label: "Valor total em boletos",
                          value: `R$ ${String(
                            formatNumberToCurrencyWithoutSimbol(operationTotal)
                          )}`,
                        },
                      ]}
                    />
                  </div>
                </SummaryWrapper>

                <Spacer top={20} />
                {insuficientBalance && (
                  <BalanceAlert
                    title="Saldo insuficiente"
                    message={
                      "Você não tem saldo suficiente para continuar com esta operação"
                    }
                  />
                )}

                <div className="p-4 w-100">
                  <div>
                    <div className="table-responsive">
                      <Table columns={validation_columns} data={details} />
                    </div>
                  </div>

                  <Spacer bottom={15} />
                </div>
              </Fragment>
            )}
          </ContainerData>

          <Spacer top={40} />

          <Copyright />

          <Spacer top={30} />
        </div>
      </GeneralContainer>
      <NewCnabModal />
    </Fragment>
  );
};

export default CnabStructure;
