import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  ReturnButton,
  Spacer,
  SummaryPanel,
} from "@zilliondigital/mirage-ui";

import RefreshIcon from "~/assets/icons/buttons/Ic_functional_refresh.png";

import { Fragment, useEffect, useState } from "react";
import useAccount from "~/hooks/useAccount";

import { useNavigate } from "react-router-dom";

import Title from "~/components/Texts/Title";
import Paginator from "~/components/Paginator";
import {
  ContainerData,
  GeneralContainer,
} from "~/pages/App/app-content.styles";
import PaymentsEmpty from "./PaymentsEmpty";
import payments from "~/data/payments";
import WithPayments from "./WithPayments";
import usePayments from "~/hooks/usePayments";
import PaymentsFilter from "./PaymentsFilter";
import NewPaymentModal from "./NewPayment/NewPaymentModal";
import useToast from "~/hooks/useToast";
import LastDaysFilter from "~/components/LastDaysFilter";
import Copyright from "~/components/Copyright";
import { format, subDays } from "date-fns";

const PaymentsStructure = () => {
  const {
    setOpenFilterModal,
    setOpenNewPaymentModal,
    getPayments,
    paymentsData,

    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    isLoadingList,
    setFetchParams,
  } = usePayments();

  const { getBalance, mainAccount, verifyPermission, beforeDays } =
    useAccount();
  const canCreatePayment = verifyPermission("ba_can_create_payment");

  const navigate = useNavigate();
  const { showToast } = useToast();

  const transactionsPanel = [
    {
      label: "Pagamentos agendados",
      balance: "R$ 0,00",
      toggleBalance: true,
    },
    {
      label: "Cadastro de clientes",
      balance: "0",
    },

    {
      label: "Cadastro de fornecedores",
      balance: "0",
    },
  ];

  const menusBreadCrumb = [{ label: "Pagamentos", href: "app/payments" }];

  const quantityPages = [
    {
      label: "20 por página",
      value: "1",
      onClick: () => {
        setRowsPerPage(20);
      },
    },
    {
      label: "50 por página",
      value: "2",
      onClick: () => {
        setRowsPerPage(50);
      },
    },
    {
      label: "100 por página",
      value: "3",
      onClick: () => {
        setRowsPerPage(100);
      },
    },
  ];

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  useEffect(() => {
    getPayments({ from_date: fromDate, to_date: todayFormatted });
    getBalance();
  }, [mainAccount]);

  return (
    <Fragment>
      <GeneralContainer>
        <div className="d-flex flex-column container-fluid w-100 ps-5 pe-5">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />
          <Spacer top={30} />
          <ContainerData className="ps-4 pe-4">
            <div className="d-flex justify-content-between p-4 ">
              <Title>Pagamentos</Title>
              <div>
                <div className="d-flex flex-column flex-lg-row">
                  <Button
                    mode="border"
                    label="Pagamento em lote"
                    onClick={() => navigate("/app/payments-lot")}
                    disabled={!canCreatePayment}
                  />
                  <Spacer left={10} />
                  <Button
                    mode="main"
                    label="Efetuar novo pagamento"
                    onClick={() => setOpenNewPaymentModal(true)}
                    disabled={!canCreatePayment}
                  />
                  {/*
                  <Spacer right={10} />
                   <Button
                    mode="border"
                    label="Buscador de boletos"
                    icon={LupaIcon}
                    disabled
                    onClick={() =>
                      showToast(
                        "Não disponível!",
                        "Este recurso ainda não está disponível.",
                        "warning"
                      )
                    }
                  /> */}
                  {paymentsData.length > 0 ? (
                    <>
                      <Spacer right={10} />
                      <div className="d-block d-lg-none">
                        <Spacer bottom={10} />
                      </div>
                      {/* <Button
                        mode="border"
                        onClick={() => setOpenFilterModal(true)}
                        label={
                          filteredTransactions.length > 0
                            ? "Limpar filtros"
                            : "Aplicar filtros"
                        }
                        icon={
                          filteredTransactions.length > 0
                            ? CleanFilterIcon
                            : FilterIcon
                        }
                        danger={filteredTransactions.length > 0}
                        disabled
                      /> */}
                      <Spacer right={10} />
                    </>
                  ) : null}{" "}
                </div>
              </div>
            </div>

            <div className="ps-4 pe-4">
              {(paymentsData.length > 0 || isLoadingList) && (
                <div className="row">
                  <SummaryPanel
                    panel={transactionsPanel}
                    isLoading={isLoadingList}
                  />
                </div>
              )}
              <Spacer bottom={20} />
              <div className="d-flex w-100 align-items-start  ">
                <div>
                  <Button
                    mode="border"
                    label="Atualizar"
                    icon={RefreshIcon}
                    onClick={() =>
                      getPayments({
                        from_date: fromDate,
                        to_date: todayFormatted,
                      })
                    }
                  />
                </div>
                <Spacer left={15} />
                {paymentsData?.length > 0 && (
                  <div style={{ width: "180px" }}>
                    <ButtonDropdown
                      mode="border"
                      label={`${rowsPerPage} por página`}
                      options={quantityPages}
                    />
                  </div>
                )}
                <div style={{ marginLeft: "auto" }}>
                  <div>
                    <LastDaysFilter
                      setter={(_from, _to) => {
                        setFetchParams({ from_date: _from, to_date: _to });
                      }}
                      executeFetch={getPayments}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Spacer bottom={20} />

            {paymentsData.length > 0 || isLoadingList ? (
              <div className="ps-3 pe-3">
                <WithPayments />
              </div>
            ) : (
              !isLoadingList && <PaymentsEmpty />
            )}
          </ContainerData>
          <div className={paymentsData.length > 0 ? "" : "d-none"}>
            <Paginator
              list={paymentsData.length > 0 ? payments : []}
              rowsPerPage={rowsPerPage}
              page={page}
              pageSetter={setPage}
            />
          </div>

          <Spacer top={paymentsData.length > 0 ? 20 : 40} />

          <Copyright />

          <Spacer top={30} />
        </div>
      </GeneralContainer>
      <PaymentsFilter />
      <NewPaymentModal />
      {/* <StatementsDetailsModal />  */}
    </Fragment>
  );
};

export default PaymentsStructure;
