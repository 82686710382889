import Timer from "~/assets/icons/figure_3d_time.png";
import styled from "styled-components";
import useTransfers from "~/hooks/Transfers/useTransfers";
import { Spacer } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";

const Rounded = styled.div`
  width: 96px;
  height: 96px;
  background-color: #ffe5d8;
  position: relative;

  img {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`;

const PaymentsEmpty = () => {
  const { filters } = useTransfers();

  return (
    <div className="w-100  pt-3  d-flex flex-column align-items-center justify content-start">
      {filters.length > 0 && <Spacer bottom={50} />}
      <Rounded className="rounded-circle">
        {" "}
        <img src={Timer} />
      </Rounded>

      <Spacer bottom={filters.length > 0 ? 20 : 60} />
      <Title>
        {filters.length > 0
          ? "Nenhum pagamento encontrado"
          : "Nenhum pagamento efetuado até o momento"}
      </Title>

      <Spacer bottom={10} />
      <Description className="text-center">
        Para efetuar pagamentos, basta clicar em <br />
        <span className="fw-bold">Efetuar novo pagamento </span>localizado no{" "}
        <br /> canto superior direito.
      </Description>
      <Spacer bottom={30} />
    </div>
  );
};

export default PaymentsEmpty;
