import { Button, InteractiveModal, Spacer } from "@zilliondigital/mirage-ui";
import { useCallback, useState } from "react";
import useTariffs from "~/hooks/useTariffs";
import useToast from "~/hooks/useToast";
import { TitleModal } from "~/pages/Authentication/Auth.styles";
import ApiService from "~/services/api";

const DeletePackpageModal = () => {
  const [isloading, setIsloading] = useState<boolean>(false);

  const {
    setOpenDeletePackpageModal,
    openDeletePackpageModal,
    deletePackpageModalData,
    setPackpagesTariffs,
  } = useTariffs();

  const { showToast } = useToast();

  const toggleModal = () => {
    setOpenDeletePackpageModal(!openDeletePackpageModal);
  };

  type deletePackpageType = {
    data?: any;
    error: boolean;
    message: string;
  };

  const deletePackpage = useCallback(async () => {
    setIsloading(true);

    try {
      const data = await ApiService.HttpDelete<deletePackpageType>({
        route: `backoffice/tariffs-package/${deletePackpageModalData.id}/`,
        token: true,
      });

      setIsloading(false);
      toggleModal();

      showToast("Cesta excluída com sucesso", "", "success");

      const dataUpdated = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/",
        token: true,
      });

      setPackpagesTariffs(dataUpdated.data);
    } catch {
      setIsloading(false);
      toggleModal();
      showToast("Nao foi possível excluir a cesta", "", "error");
    }
  }, [setOpenDeletePackpageModal, deletePackpageModalData]);

  return (
    <InteractiveModal
      title={"Deletar cesta de tarifas"}
      size="md"
      toggleModal={toggleModal}
      open={openDeletePackpageModal}
    >
      <div className="p-5 d-flex  flex-column justify-content-between ">
        <TitleModal className="text-center">
          Deseja remover {deletePackpageModalData.name} ?
        </TitleModal>
        <Spacer bottom={15} />
        <p className="text-center ">
          {`Ao excluir esta cesta, os correntistas atribuídos a ela voltarão para uma cesta padrão.`}
        </p>

        <Spacer bottom={110} />
        <div>
          <Button
            mode="main"
            label="Não. Mantenha esta cesta"
            onClick={toggleModal}
            full
          />
          <Spacer bottom={10} />
          <Button
            loading={isloading}
            mode="border"
            label="Sim. Desejo excluir este cesta"
            onClick={deletePackpage}
            full
          />
        </div>
      </div>
    </InteractiveModal>
  );
};
export default DeletePackpageModal;
