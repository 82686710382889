import constate from "constate";
import { useState } from "react";
import useAccount from "./useAccount";
import useToast from "./useToast";
import ApiService from "~/services/api";
import { getFirstAndLastDayOfMonth, getToday } from "~/utils";
import { subDays } from "date-fns";
import { payloadDatesToParam } from "~/utils";

const steps = ["barcode", "confirm-payment"];

const initialFilterParams = {
  from_date: getToday(),
  to_date: getToday(),
  orderby: "created_date",
  price_min: 0,
};

const [PaymentsProvider, usePayments] = constate(() => {
  const { showToast } = useToast();
  const { bankAccounts } = useAccount();

  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [openNewPaymentModal, setOpenNewPaymentModal] =
    useState<boolean>(false);
  const [openNewPaymentModalInDash, setOpenNewPaymentModalInDash] =
    useState<boolean>(false);

  const [isSchedulingPayment, setIsSchedulingPayment] =
    useState<boolean>(false);

  const [openConfirmPaymentModal, setOpenConfirmPaymentModal] =
    useState<boolean>(false);

  const [operations, setOperations] = useState<any[]>([]);
  const [massPaymentsOperations, setMassPaymentsOperations] = useState<any[]>(
    []
  );

  const [stepIndex, setStepIndex] = useState<number>(0);
  const [step, setStep] = useState<string>(steps[stepIndex]);

  const [newPaymentData, setNewPaymentData] = useState<any>({});

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState(0);

  const [filterParams, setFilterParams] = useState<any>(initialFilterParams);

  const [taxPayments, setTaxPayments] = useState<number>(0);

  const setFetchParams = (updatedParams = {}) => {
    setFilterParams((prevFilters: any) => ({
      ...prevFilters,
      ...updatedParams,
    }));
  };

  const nextStep = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
      setStep(steps[stepIndex + 1]);
    }
  };

  const prevStep = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
      setStep(steps[stepIndex - 1]);
    }
  };

  const handleCloseNewPaymentModal = () => {
    setStepIndex(0);
    setStep(steps[0]);
    setOpenNewPaymentModal(false);
    setOpenNewPaymentModalInDash(false);
    setRenderReceipt(false);
  };

  const [descriptionPayment, setDescriptionPayment] = useState<String>("");

  const formatDate = (originalDate: string): string => {
    const formattedDate = originalDate.split("T")[0];
    return formattedDate;
  };
  const formatAmount = (number: number) => Number(number).toFixed(2);

  const [paymentsData, setPaymentsData] = useState<any>([]);

  const [formattedFirstDay, formattedLastDay] = getFirstAndLastDayOfMonth();

  const [isLoadingReceipt, setIsLoadingReceipt] = useState<boolean>(false);
  const [renderReceipt, setRenderReceipt] = useState<boolean>(false);

  const [dataReceiptPayment, setDataReceiptPayment] = useState<any>({});

  const [isLoadingList, setIsLoadingList] = useState<boolean>(false);

  const getPayments = async (fastParams = {}) => {
    setPaymentsData([]);
    setIsLoadingList(true);

    const params = payloadDatesToParam({
      ...filterParams,
      ...fastParams,
    });

    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/pay-billet/",
        token: true,
        params: {
          ...params,
        },
      });

      setPaymentsData(data.results);
      setIsLoadingList(false);
    } catch {
      console.warn("error");
    }
    setIsLoadingList(false);
  };

  const [isLoadingNewPayment, setIsLoadingNewPayment] =
    useState<boolean>(false);










  return {
    openFilterModal,
    setOpenFilterModal,
    openNewPaymentModal,
    setOpenNewPaymentModal,
    openConfirmPaymentModal,
    setOpenConfirmPaymentModal,
    step,
    stepIndex,
    steps,
    setStepIndex,
    setStep,
    nextStep,
    prevStep,
    handleCloseNewPaymentModal,
    isSchedulingPayment,
    setIsSchedulingPayment,
    newPaymentData,
    setNewPaymentData,

    descriptionPayment,
    setDescriptionPayment,
    isLoadingNewPayment,
    setIsLoadingNewPayment,
    setPaymentsData,
    paymentsData,
    getPayments,
    openNewPaymentModalInDash,
    setOpenNewPaymentModalInDash,
    renderReceipt,
    setRenderReceipt,
    setIsLoadingReceipt,
    isLoadingReceipt,
    dataReceiptPayment,
    setDataReceiptPayment,

    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,

    filterParams,
    setFetchParams,
    isLoadingList,
    setIsLoadingList,

    taxPayments,
    setTaxPayments,
    setOperations,
    operations,
    massPaymentsOperations,
    setMassPaymentsOperations,
  };
});

export { PaymentsProvider };

export default usePayments;
