import styled from "styled-components";
import label from "~/labels";
import theme from "~/themes/theme";

export const GeneralContainer = styled.div`
  background-image: ${`linear-gradient(to right, ${theme.colors.gray.light100} 50%, ${theme.colors.gray.light100} 50%)`};
  min-height: 100vh;
  overflow-y: auto;
  position: relative;
  display: flex;

  background-color: ${() => theme.colors.gray.light100};

  @media screen and (max-width: 768px) {
    background-image: none;
    background-color: #ffffff;
  }
  .color-gray {
    color: #7f7f7f;
  }

  .bg-white {
    background-color: #ffffff;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  background-color: ${() => theme.colors.gray.light100};
`;

export const Aside = styled.aside`
  background-color: ${() => theme.colors.gray.light100};
  max-width: 381px;
  z-index: 1;
  padding: 48px 81px;
  .content {
    position: fixed;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: none;
    padding: 0px 10px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
`;

export const ContainerData = styled.div`
  width: 100%;
  padding: 80px 105px;
  flex-grow: 1;
  z-index: 2;
  background-color: ${() => theme.colors.gray.light100};

  @media screen and (max-width: 768px) {
    padding: 20px 20px;
  }
`;

export const ImgLogo = styled.img`
  width: 100%;

  max-width: 200px;

  @media screen and (max-width: 768px) {
    max-width: 150px;
  }
`;

export const HeaderContainer = styled.div`
  background-color: ${() => theme.colors.gray.light100};
  padding: 0 81px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  span {
    font-size: 15px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 10px;
    flex-direction: column-reverse;
    span {
      font-size: 12px;
    }
  }
`;

export const FooterSpanContainer = styled.div`
  max-width: 200px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Title = styled.span`
  font-size: 32px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;
