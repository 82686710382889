import { Button, Input, Spacer } from "@zilliondigital/mirage-ui";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Padlock from "~/assets/icons/figure_3d_padlock.jpg";
import Timer from "~/assets/icons/figure_3d_time.jpg";
import { FormikHelpers, FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import { INVALID_FORMAT, REQUIRED_LABEL } from "~/services/validators";
import useAuth from "~/hooks/useAuth";
import { ContainerModal, IconModal, TitleModal } from "./Auth.styles";
import ApiService from "~/services/api";
import { onlyNumbers } from "~/utils";
import useToast from "~/hooks/useToast";
import { set } from "date-fns";
import { obscureEmail } from "~/services/utils";

type FormValues = {
  username: string;
};

const initialValues: FormValues = {
  username: "",
};

const FormSchema = Yup.object().shape({
  username: Yup.string().required(REQUIRED_LABEL),
});

const Forgot = () => {
  const { stepIndex, nextStep, openModal, setStepIndex, setOpenModal } =
    useAuth();

  const [emailUser, setEmailUser] = useState<string>("");

  const navigate = useNavigate();

  const { showToast } = useToast();

  const handleFormSubmit = (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    helpers.setSubmitting(true);

    helpers.setSubmitting(false);
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    resetForm,
    errors,
    touched,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: handleFormSubmit,
  }) as FormikProps<FormValues>;
  const validateDocNumber =
    values.username?.length > 13 && values.username?.length >= 18
      ? true
      : values.username?.length > 13 && values.username?.length < 15
      ? true
      : false;

  const sendTokenNewPassword = async () => {
    setSubmitting(true);

    try {
      const { data } = await ApiService.HttpPost({
        route: "user-check/",
        body: {
          type: "document_number",
          username: onlyNumbers(values.username),
        },
      });

      if (data.status === false) {
        setSubmitting(false);
        showToast(
          "Usuário não encontrado!",
          "Verifique os dados e tente novamente",
          "error"
        );
      } else {
        setEmailUser(data?.response?.user?.email);

        resetForm();

        const { data: newData } = await ApiService.HttpPost({
          route: "username-forgot-password/",

          body: {
            document_number: data.response.user.username,
          },
        });
        setSubmitting(false);
        nextStep();
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (!openModal) {
      resetForm();
      setStepIndex(0);
    }
  }, [openModal]);

  const EmailInputRender = () => {
    return (
      <div className="p-5">
        <div className="d-flex justify-content-center">
          <IconModal src={Padlock} />
        </div>
        <Spacer bottom={20} />
        <TitleModal className="text-center">Esqueci a senha</TitleModal>
        <Spacer bottom={20} />
        <p className="text-size-md">
          Informe no campo abaixo seu CPF/CNPJ cadastrado para receber as
          instruções de renovação da sua senha de acesso.
        </p>
        <Spacer bottom={-20} />

        <form className="w-100" onSubmit={handleSubmit}>
          <Input
            type="text"
            mask={
              values.username.length > 14
                ? "99.999.999/9999-99"
                : "999.999.999-9999999"
            }
            placeholder="Ex.: 123.456.789-41"
            name="username"
            onChange={handleChange}
            value={values.username}
            error={touched.username && errors.username}
            success={validateDocNumber}
            autoFocus={openModal ? true : false}
          />

          <Button
            className="text-center"
            mode="main"
            disabled={!validateDocNumber}
            loading={isSubmitting}
            full
            onClick={() => sendTokenNewPassword()}
            label="Solicitar instruções"
          />
        </form>
      </div>
    );
  };
  const InstructionsSentRender = () => {
    return (
      <div className="p-5">
        <IconModal src={Timer} />
        <Spacer bottom={52} />
        <TitleModal>Instruções Enviadas</TitleModal>
        <Spacer bottom={22} />
        <p>
          Em instantes você receberá um código de segurança no seu email{" "}
          <span className="fw-bold">{obscureEmail(emailUser ?? "")}</span> para
          continuar com a troca da senha...
        </p>
        <Spacer bottom={48} />
        <Button
          type="button"
          mode="main"
          onSubmit={(e) => {}}
          full
          label="Continuar"
          onClick={() => {
            setOpenModal(false);
            navigate("/auth/new-password");
          }}
        ></Button>
      </div>
    );
  };

  const stepRender = [<EmailInputRender />, <InstructionsSentRender />];

  return <ContainerModal>{stepRender[stepIndex]}</ContainerModal>;
};

export default Forgot;
