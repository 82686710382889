import {
  BalanceAlert,
  Button,
  Input,
  InputCurrency,
  Spacer,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";
import EyeIcon from "~/assets/icons/round_visibility_black_24dp.png";
import EyeOffIcon from "~/assets/icons/round_visibility_off_black_36dp.png";
import styled from "styled-components";

import { Fragment, useEffect, useState } from "react";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";

import { useFormik } from "formik";
import { onlyNumbers } from "~/services/utils";
import useToken from "~/hooks/useToken";
import { convertedAmount, formatNumberToCurrencyWithoutSimbol } from "~/utils";
import hideFormatDocumentNumber from "~/utils/hideDocumentNumber";

import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";
import CustomerTax from "~/components/CustomerTax";
import useBanks from "~/hooks/useBanks";
import { ContainerBottom } from "../../app-content.styles";
import { ContainerInfos } from "../../Transfers/NewTransfer/TED/SendTEDorTEV";

const Container = styled.div`
  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;
  }
  padding: 20px;

  .image-eye {
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  .container-balance {
    position: relative;
    top: -20px;
  }

  .hide-balance {
    position: absolute;
    left: 180px;
  }
`;
const ContainerInfo = styled.div``;

type FormValues = {
  amount: string;
  description: string;
};

const initialValues: FormValues = {
  amount: "",
  description: "",
};

const TransferWithPixSend = () => {
  const [error, setError] = useState(false);
  const {
    hideAvailableBalance,
    setHideAvailableBalance,
    mainAccount,
    balance,
    setInsufficientBalance,
    insufficientBalance,
    balanceFormatted,
    getBalance,
  } = useAccount();

  const {
    setOpenValidatorModalTransferWithPix,
    setIsLoadingTransferReceipt,
    setValidation,
    handlePrevStep,
    dataNewPixTransfer,
    amount,
    setAmount,
    setTransferReceiptRender,
    setDataTransferPixReceipt,
    setTaxTransferPix,
    taxTransferPix,
  } = useTransferWithPix();

  const { showToast } = useToast();

  const { values, handleChange } = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {},
  });

  const { sendPartnerToken, getToken } = useToken();

  const fetchTax = async () => {
    try {
      const response = await ApiService.HttpPost({
        route: "customer-global-tax/",
        token: true,
        body: {
          slug: "pix_transfer",
          tax_is_enabled: true,
        },
      });
      setTaxTransferPix(response.data.customer_tax_out);
    } catch (err) {
      console.error("Erro", err);
    }
  };

  const sendPix = async () => {
    sendPartnerToken();

    const token = await getToken();

    setIsLoadingTransferReceipt(true);

    try {
      const { data: response } = await ApiService.HttpPost({
        route: "pix/transfer/",
        body: {
          amount: amount,
          code: token,
          key: dataNewPixTransfer?.key,
          sender_account: 1,
          type: "key",
          description: values.description ?? "Sem descrição",
        },
        token: true,
      });

      setValidation(false);

      if (!response.error) {
        const { data } = response;
        setTransferReceiptRender(true);
        setDataTransferPixReceipt(data);
        setIsLoadingTransferReceipt(false);

        setTimeout(() => {
          showToast(
            "Pix realizado com sucesso",
            "Sua transação foi enviada com sucesso.",
            "success"
          );
        }, 500);

        setTimeout(() => {
          getBalance();
        }, 2000);
      } else {
        showToast("Pix não realizado", response.message, "error");
      }
    } catch (err: any) {
      console.warn(err);

      setIsLoadingTransferReceipt(false);

      setValidation(false);
    }
  };

  const { getBanks, bankOptions, banksData } = useBanks();
  useEffect(() => {
    banksData?.length <= 0 && getBanks();
  }, []);

  useEffect(() => {
    fetchTax();
  }, []);

  useEffect(() => {
    setAmount(convertedAmount(onlyNumbers(values.amount)));
  }, [values.amount]);

  useEffect(() => {
    const total = amount + taxTransferPix;

    if (parseFloat(total.toFixed(2)) > balance) {
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }
  }, [amount]);

  const handleSendPix = async () => {
    if (isNaN(amount) || amount <= 0) {
      setError(true);
    } else if (amount <= balance) {
      sendPix();
    }
  };

  const handleClick = (e: any) => {
    handleSendPix();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSendPix();
    }
  };

  return (
    <Fragment>
      <Container className="p-4">
        <div>
          <p>
            {"Defina o valor que quer transferir para " +
              dataNewPixTransfer?.name}
          </p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="w-100">
              <InputCurrency
                isPercentage={false}
                id="amount"
                type="text"
                name="amount"
                onChange={handleChange}
                value={values.amount}
                error={error && "O valor precisa ser maior que 0"}
                inputSize="large"
                label=""
                onFocus={() => setError(false)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>

            {/* <div className="ms-2 pb-5 ">
            <Description color="#666666">Agendar</Description>
            <Toggle mode="large" />
          </div> */}
          </div>
          <Spacer bottom={error ? -15 : -25} />
          <div className="container-balance">
            <div className="d-flex flex-wrap justify-content-start align-items-center  mt-4">
              <Description color="#666666">
                {`Saldo disponível atual é de`}
              </Description>
              <Spacer left={3} />

              <Description
                className={
                  !hideAvailableBalance
                    ? "font-weight-bolder "
                    : "font-weight-bolder opacity-0 "
                }
              >
                {balanceFormatted}
              </Description>

              <Description
                className={
                  hideAvailableBalance
                    ? "font-weight-bolder   hide-balance   "
                    : "font-weight-bolder opacity-0 hide-balance  "
                }
              >
                R$ ****
              </Description>

              <Spacer left={5} />
              <img
                className="image-eye"
                src={!hideAvailableBalance ? EyeIcon : EyeOffIcon}
                onClick={() => setHideAvailableBalance(!hideAvailableBalance)}
              />
            </div>

            <div>
              <Description color="#666666">{`Taxa `}</Description>
              <Description className="font-weight-bolder">
                R$ {formatNumberToCurrencyWithoutSimbol(taxTransferPix)}{" "}
              </Description>
            </div>
          </div>
        </div>
        {insufficientBalance && (
          <>
            {" "}
            <Spacer bottom={-5} />
            <BalanceAlert
              title="Saldo insuficiente"
              message="Essa transferência não poder ser feita por saldo insuficiente"
            />
          </>
        )}
        <Spacer top={0} />
        <ContainerInfo>
          <table className=" w-100">
            <tbody>
              <tr>
                <td>
                  <Description color="#666666">Quando</Description>
                </td>

                <td className="second-column">
                  <Description>Agora</Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">
                    Tipo de transferência
                  </Description>
                </td>

                <td className="second-column">
                  <Description>PIX</Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">CPF</Description>
                </td>

                <td className="second-column">
                  <Description>
                    {hideFormatDocumentNumber(
                      dataNewPixTransfer?.document_number
                    )}
                  </Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">Instituição</Description>
                </td>

                <td className="second-column">
                  <Description>{dataNewPixTransfer?.bank_name}</Description>
                </td>
              </tr>

              <tr>
                <td>
                  <Description color="#666666">Agência</Description>
                </td>

                <td className="second-column">
                  <Description>{dataNewPixTransfer?.agency}</Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">Conta</Description>
                </td>

                <td className="second-column">
                  <Description>
                    {dataNewPixTransfer?.account_number +
                      "-" +
                      dataNewPixTransfer.account_number_digit}
                  </Description>
                </td>
              </tr>

              <tr>
                <td>
                  <Description color="#666666">Identificador</Description>
                </td>

                <td className="second-column">
                  <Description>{dataNewPixTransfer?.identifier}</Description>
                </td>
              </tr>
            </tbody>
          </table>
          <Spacer bottom={10} />
          <Input
            type="textarea"
            label="Descrição"
            name="description"
            value={values.description}
            onChange={handleChange}
          />
          <Spacer top={100} />
        </ContainerInfo>

        <ContainerBottom>
          <div className="d-flex justify-content-between f">
            <Button mode="phantom" label="Cancelar" onClick={handlePrevStep} />
            <Button
              mode="main"
              label="Transferir agora"
              onClick={handleClick}
              disabled={insufficientBalance}
            />
          </div>
        </ContainerBottom>
      </Container>
    </Fragment>
  );
};

export default TransferWithPixSend;
