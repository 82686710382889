import styled, { keyframes } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Input, Spacer, Table } from "@zilliondigital/mirage-ui";
import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Paginator, { applyPagination } from "~/components/Paginator";
import ApiService from "~/services/api";
import Copyright from "~/components/Copyright";
import useTariffs from "~/hooks/useTariffs";
import { PackpageTypes } from "~/types";
import { useFormik } from "formik";
import Description from "~/components/Texts/Description";
import { formatCPFCNPJ, onlyNumbers } from "~/utils";
import theme from "~/themes/theme";
import useToast from "~/hooks/useToast";
import label from "~/labels";
import { getFirstLetters } from "~/services/utils";

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const GeneralContainerData = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  width: 100%;
  flex-grow: 1;

  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .container-return-button {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .container-auxiliar {
    position: fixed;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
`;

const Title = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .rounded-circle {
    width: 40px;
  }

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }
`;

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }
`;

const AddNewClients = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const {
    setEditPackpageData,
    editPackpageData,
    clientsData,
    page,
    rowsPerPage,
    setRowsPerPage,
    setClientsData,
    setPage,
    setStepIndexEdit,
  } = useTariffs();
  const { showToast } = useToast();
  const [holdersSelectedsIndex, setHoldersSelectedsIndex] = useState<number[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRow] = useState<any[]>([]);
  const [clientsFiltered, setClientsFiltered] = useState<any>([]);

  const initialValues = {
    search: "",
  };

  const { values, setFieldValue, handleChange } = useFormik({
    onSubmit: () => undefined,
    initialValues: initialValues,
  });

  const SelectContact = (id: number) => {
    setHoldersSelectedsIndex((prevSelected) => {
      const index = prevSelected.indexOf(id);
      if (index > -1) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const isContactSelected = (id: number) => holdersSelectedsIndex.includes(id);

  const handleClickClient = (data: any) => {
    SelectContact(data.id);
  };

  const dataClients = clientsData?.map((contact: any) => {
    return {
      id: contact.id,
      name: contact.name,
      document_number: contact.document_number,
    };
  });

  const columnsHolders = [
    {
      label: "Nome",
      key: "name",

      renderData: (data: any) => {
        return (
          <ContainerRenderData onClick={() => handleClickClient(data)}>
            <div className="d-flex align-items-center">
              {isContactSelected(data.id) ? (
                <ContainerImage>
                  <div className="contact-selected-image">
                    <img
                      className="selected-contact-image"
                      src={SelectedContactIcon}
                    />
                  </div>
                </ContainerImage>
              ) : (
                <ContainerImage>
                  <div className="container-profile-picture">
                    <div className="rounded-circle profile-picture">
                      <h1>{getFirstLetters(data.name)}</h1>
                    </div>
                  </div>
                </ContainerImage>
              )}
              <Spacer left={10} />
              <div className="d-flex flex-column">
                <Title>{data.name}</Title>
                <Spacer bottom={-22} />
                <Description color="#929292">
                  {formatCPFCNPJ(data.document_number)}
                </Description>
              </div>
            </div>
          </ContainerRenderData>
        );
      },
    },
  ];

  const filteredContacts = useMemo(() => {
    const removeNonNumericChars = (input: string) => input.replace(/\D/g, "");
    const searchQuery = values.search.toLowerCase();
    const isNumericSearch = !!removeNonNumericChars(searchQuery);

    return dataClients?.filter(
      (client: { id: number; name: string; document_number: string }) => {
        if (isNumericSearch) {
          return client.document_number
            ?.toLowerCase()
            .includes(onlyNumbers(searchQuery));
        } else {
          return client.name.toLowerCase()?.includes(searchQuery);
        }
      }
    );
  }, [values.search, dataClients]);

  const getClients = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/approved-partners/",
        token: true,
      });

      setClientsData(data);
    } catch (error) {
      console.log("Erro ao buscar clientes:", error);
    }
  }, [params.id]);

  const fetchAddHolders = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost({
        route: `backoffice/tariffs-package/${params.id}/add-holders/`,
        body: {
          holders: holdersSelectedsIndex,
        },
        token: true,
      });

      console.log(data);
      setStepIndexEdit(2);
      navigate(`/app/myspace/tariffs/edit/${params.id}/customers`);
      showToast("Correntistas adicionados com sucesso", "", "success");

      setIsLoading(false);
    } catch (e: any) {
      showToast(e.message, "", "error");

      setIsLoading(false);
    }
  }, [holdersSelectedsIndex]);

  const fethPackpageToEditData = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/",
        token: true,
      });

      const dataFiltered = data.results.filter(
        (packpage: PackpageTypes) => packpage.id === Number(params.id)
      );

      setFieldValue("name", dataFiltered[0]?.name);

      setEditPackpageData(dataFiltered);
    } catch {}
  }, [params.id]);

  useEffect(() => {
    setClientsFiltered(filteredContacts);
  }, [values.search]);

  useEffect(() => {
    getClients();
  }, [params.id]);

  useEffect(() => {
    if (editPackpageData.length <= 0) {
      fethPackpageToEditData();
    }
  }, [params.id]);

  useEffect(() => {
    setFieldValue("name", editPackpageData[0]?.name);
  }, [editPackpageData]);

  return (
    <ParentContainer>
      <Fragment>
        <GeneralContainerData>
          <div className="d-flex flex-column container-fluid w-100 h-100 ps-4 pe-4">
            <Spacer top={30} />

            <ContainerData className="container-fluid ps-4 pe-4">
              <div className="pt-4 w-100 d-flex justify-content-between">
                <Title>
                  Adicionar correntistas à cesta {editPackpageData[0]?.name}
                </Title>
                <div className="d-flex  align-items-center">
                  <Button
                    mode="border"
                    label="cancelar"
                    onClick={() => {
                      navigate(
                        `/app/myspace/tariffs/edit/${params.id}/customers`
                      );
                    }}
                  />
                  <Spacer left={10} />
                  <Button
                    mode="main"
                    label="Adicionar correntistas"
                    onClick={fetchAddHolders}
                    disabled={holdersSelectedsIndex.length === 0}
                  />
                </div>
              </div>

              <>
                <Spacer bottom={20} />
                <Title>Correntistas</Title>

                <small>
                  {" "}
                  Selecione o(s) correntista(s) para adicionar a esta cesta de
                  tarifas
                </small>
                <br />
                <Description color="gray">
                  Atenção: Correntistas já atribuidos a outra cesta não padrão,
                  não aparecerão na listagem.
                </Description>

                <Spacer bottom={-20} />
                <div className="col-6">
                  <Input
                    type="text"
                    search
                    value={values.search}
                    name="search"
                    onChange={handleChange}
                    placeholder="Pesquise um cliente pelo nome ou CPF/CNPJ"
                  />
                </div>
                <div>
                  {" "}
                  {values.search.length > 0 && filteredContacts.length === 0 ? (
                    <p>Nenhum resultado encontrado</p>
                  ) : (
                    <Table
                      selectedRows={selectedRows}
                      haveHeader={false}
                      columns={columnsHolders}
                      data={applyPagination(
                        clientsFiltered.length > 0
                          ? clientsFiltered
                          : dataClients,
                        { page, rowsPerPage }
                      )}
                      dataSpacer={0}
                    />
                  )}
                </div>
              </>
            </ContainerData>
            {values.search.length > 0 &&
            filteredContacts.length === 0 ? null : (
              <Paginator
                list={
                  filteredContacts.length > 0
                    ? filteredContacts
                    : values.search.length > 0
                    ? []
                    : clientsData
                }
                rowsPerPage={rowsPerPage}
                page={page}
                pageSetter={setPage}
              />
            )}
            <Spacer top={40} />

            <Copyright />

            <Spacer top={30} />
          </div>
        </GeneralContainerData>
      </Fragment>
    </ParentContainer>
  );
};

export default AddNewClients;
