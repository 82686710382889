import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input, CustomSelect, Spacer } from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { REQUIRED_LABEL } from "~/services/validators";
import { genderOptions, ufOptions, maritalStatusOptions } from "~/data/options";
import useSignupPersonal, {
  PersonalGeneralValues,
} from "~/hooks/Signup/useSignupPersonal";
import {
  generateCPF,
  generateCellphone,
  generateRG,
  validateEmail,
  validatePhoneNumber,
} from "~/services/utils";
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";
import documentNumberValidator from "~/utils/validateDocNumber";
import { verifyCompleteName } from "~/utils";

const Title = styled.span`
  font-size: 32px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

const FormSchema = Yup.object().shape({
  document_type: Yup.string().required(REQUIRED_LABEL),
  document_number: Yup.string().required(REQUIRED_LABEL),
  mobile_phone: Yup.string().required(REQUIRED_LABEL),
  name: Yup.string().required(REQUIRED_LABEL),
  email: Yup.string().required(REQUIRED_LABEL),
  identity_number: Yup.string().required(REQUIRED_LABEL),
  mothers_name: Yup.string().required(REQUIRED_LABEL),
  gender: Yup.string().required(REQUIRED_LABEL),
  marital_status: Yup.string().required(REQUIRED_LABEL),
  nationality: Yup.string().required(REQUIRED_LABEL),
  birth_state: Yup.string().required(REQUIRED_LABEL),
  birth_date: Yup.string()
    .required()
    .test("birth_date", "Você precisa ser maior de 18 anos", (value) => {
      if (!value) {
        return false;
      }
      const currentDate = new Date();
      const selectedDate = new Date(value);
      const age = currentDate.getFullYear() - selectedDate.getFullYear();
      return age >= 18;
    }),
});

const General = () => {
  const navigate = useNavigate();
  const {
    setStep,
    onSubmitForm,
    setValid,
    personalGeneralValues,
    setPersonalGeneralValues,
    setNewPartner,
    setIsLoading,
    setPayloadSignupPersonal,
    payloadSignupPersonal,
    setCanAdvanced,
    getPartner,
    partnerData,
  } = useSignupPersonal();

  const [selectedGender, setSelectedGender] = useState<string>("");
  const [selectedMaritalStatus, setSelectedMaritalStatus] =
    useState<string>("");
  const [error, setError] = useState<string>("");

  const [selectedBirthState, setSelectedBirthState] = useState<string>("");
  const [isNotOld, setIsNotOld] = useState<boolean>(false);

  const handleFormSubmit = async (values: any, helpers: any) => {};

  const LabelGender: { [key: string]: string } = {
    male: "Masculino",
    female: "Feminino",
  };

  const LabelMaritalStatus: { [key: string]: string } = {
    "0": "Solteiro(a)",
    "1": "Casado(a)",
    "2": "Divorciado(a)",
    "3": "Separado(a)",
    "4": "Viúvo(a)",
    "5": "União estável",
    "6": "Outros",
  };

  const {
    submitForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: personalGeneralValues,
    validationSchema: FormSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  const fillForm = () => {
    const fakeValues: PersonalGeneralValues = {
      document_type: "cpf",
      document_number: generateCPF(),
      birth_date: faker.date.past(30).toISOString().split("T")[0],
      mobile_phone: generateCellphone(true),
      name: faker.name.fullName({
        gender: "male",
      }),
      email: faker.internet.email(),
      identity_number: generateRG(),
      mothers_name: faker.name.fullName({ gender: "female" }),
      gender: "male",
      marital_status: "0",
      nationality: "Brasileira",
      birth_state: "SP",
    };

    setPersonalGeneralValues(fakeValues);
  };

  const setFieldsFromPartnerData = (data: any) => {
    const fieldMapping = {
      email: "email",
      mobile_phone: "mobile_phone",
      username: "document_number",
      name: "name",
      full_name: "name",
      phone_number: "mobile_phone",
      document_type: "document_type",
      birth_date: "birth_date",
      identity_number: "identity_number",
      mothers_name: "mothers_name",
      document_number: "document_number",
      gender: "gender",
    };
    Object.entries(fieldMapping).forEach(([key, value]) => {
      if (data[key]) {
        setFieldValue(value, data[key]);
      }
    });
  };

  const verifyIfPayloadIsComplete = useCallback((objeto: object) => {
    return Object.values(objeto).every(
      (valor) => !(typeof valor === "string" && valor.trim() === "")
    );
  }, []);

  const handleSelectGender = useCallback(
    (selectedValue: any) => {
      setSelectedGender(selectedValue.target.value);
    },
    [setSelectedGender]
  );

  const handleSelectMaritalStatus = useCallback(
    (selectedValue: any) => {
      setSelectedMaritalStatus(selectedValue.target.value);
    },
    [setSelectedMaritalStatus]
  );

  const handleSelectBirthState = useCallback(
    (selectedValue: any) => {
      setSelectedBirthState(selectedValue.target.value);
    },
    [setSelectedBirthState]
  );

  const isValidDocument = useMemo(() => {
    return (
      values.document_number.length > 13 &&
      documentNumberValidator(values.document_number)
    );
  }, [values.document_number]);

  useEffect(() => {
    setValid(isValid);
    getPartner();

    setIsLoading(false);

    window.addEventListener("signuPersonalSubmit", submitForm);
    window.addEventListener("fillForm", fillForm);
    setCanAdvanced(false);

    setStep("general");

    return () => {
      window.removeEventListener("signuPersonalSubmit", submitForm);
      window.removeEventListener("fillForm", fillForm);
    };
  }, []);

  useEffect(() => {
    const data: any = localStorage.getItem("new_user");
    const dataParsed = JSON.parse(data);

    if (partnerData?.user) {
      setFieldsFromPartnerData(partnerData);
      setSelectedGender(partnerData.gender);
      setSelectedBirthState(partnerData.birth_state);
      setSelectedMaritalStatus(partnerData.marital_status);
    } else if (data) {
      setFieldsFromPartnerData(dataParsed);
    }
  }, [partnerData]);

  useEffect(() => {
    setPayloadSignupPersonal({
      document_type: "cpf",
      document_number: values.document_number,
      birth_date: values.birth_date,
      mobile_phone: values.mobile_phone,
      name: values.name,
      email: values.email,
      identity_number: values.identity_number,
      mothers_name: values.mothers_name,
      gender: selectedGender,
      marital_status: selectedMaritalStatus,
      nationality: values.nationality,
      birth_state: selectedBirthState,
      default: true,
      create_associated_account: false,
      create_external_account: true,
      create_internal_account: false,
      origin: "bank-v2",
      target: "ted",
      type: "account",
    });
  }, [values, selectedBirthState, selectedGender, selectedMaritalStatus]);

  useEffect(() => {
    if (verifyIfPayloadIsComplete(payloadSignupPersonal)) {
      if (!isNotOld) {
        setCanAdvanced(true);
      } else {
        setCanAdvanced(false);
      }
    } else {
      setCanAdvanced(false);
    }
  }, [payloadSignupPersonal]);

  useEffect(() => {
    const currentDate = new Date();
    const selectedDate = new Date(values.birth_date);

    if (values.birth_date) {
      const age = currentDate.getFullYear() - selectedDate.getFullYear();
      if (age < 18) {
        setIsNotOld(true);
      } else {
        setIsNotOld(false);
      }
    }
  }, [values.birth_date]);

  useEffect(() => {
    if (values?.document_number.length <= 13 && !isValidDocument) {
      setError("initialState");
    } else if (values?.document_number.length >= 13 && isValidDocument) {
      setError("notError");
    } else if (values?.document_number.length > 13 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document_number]);

  return (
    <Fragment>
      <Spacer bottom={32} />

      <div>
        <Title>Informe seus dados pessoais</Title>
      </div>
      <Spacer bottom={30} />
      <div className="row">
        <div className="col-12 col-xxl-7 ">
          <form onSubmit={handleSubmit}>
            <div className="row d-flex flex-xl-row flex-column align-items-end ">
              <div className="col-12  ">
                <Input
                  type="text"
                  name="name"
                  value={values.name}
                  helper="Sem abreviações, como está em seus documentos"
                  required={!verifyCompleteName(values.name)}
                  label="Nome completo"
                  placeholder="Ex: Carlos Magno"
                  error={touched.name && errors.name}
                  success={verifyCompleteName(values.name)}
                  onChange={handleChange("name")}
                  autoCapitalize="words"
                />
              </div>
              <Spacer bottom={5} />
              <div className="col-12 col-xl-6">
                <Input
                  type="text"
                  mask="999.999.999-99"
                  name="document_number"
                  value={values.document_number}
                  required={
                    partnerData?.document_number &&
                    values.document_number.length === 11
                      ? false
                      : error !== "notError"
                  }
                  label="CPF"
                  placeholder="123.456.789-09"
                  error={
                    values.document_number.length > 13 &&
                    !isValidDocument &&
                    error
                  }
                  success={
                    partnerData?.document_number &&
                    values.document_number.length === 11
                      ? true
                      : error === "notError"
                  }
                  onChange={handleChange("document_number")}
                />
              </div>
              <div className="col-12 col-xl-6">
                <Input
                  type="date"
                  required={!values.birth_date || isNotOld}
                  label="Data de nascimento"
                  name="birth_date"
                  onChange={handleChange}
                  value={values.birth_date}
                  success={values.birth_date !== "" && !isNotOld}
                  error={isNotOld && "Você precisa ser maior de 18 anos"}
                />
              </div>

              <div className="col-12 col-xl-6">
                <Input
                  mask="(99) 99999-9999"
                  required={!validatePhoneNumber(values.mobile_phone)}
                  type="phone"
                  name="mobile_phone"
                  label="Número de Celular"
                  value={values.mobile_phone}
                  success={validatePhoneNumber(values.mobile_phone)}
                  placeholder="(11) 91111-1111"
                  error={touched.mobile_phone && errors.mobile_phone}
                  onChange={handleChange("mobile_phone")}
                  autoComplete="phone"
                />
              </div>

              <div className="col-12 col-xl-6">
                <Input
                  type="email"
                  name="email"
                  label="Email"
                  required={!validateEmail(values.email)}
                  value={values.email}
                  placeholder="Jhon.doe@mail.com"
                  error={touched.email && errors.email}
                  success={validateEmail(values.email)}
                  onChange={handleChange("email")}
                  autoComplete="email"
                />
              </div>

              <div className="col-12 col-xl-6 ">
                <Input
                  type="text"
                  value={values.identity_number}
                  name="identity_number"
                  required={values.identity_number.length <= 7}
                  maxLength={10}
                  label="RG"
                  placeholder="123456789"
                  error={touched.identity_number && errors.identity_number}
                  success={values.identity_number.length > 7}
                  onChange={handleChange("identity_number")}
                  autoCapitalize="words"
                />
              </div>

              <div className="col-12 col-xl-6">
                <Input
                  type="text"
                  name="mothers_name"
                  value={values.mothers_name}
                  required={!verifyCompleteName(values.mothers_name)}
                  label="Nome completo da mãe"
                  placeholder="Joana da Silva"
                  error={touched.mothers_name && errors.mothers_name}
                  success={verifyCompleteName(values.mothers_name)}
                  onChange={handleChange("mothers_name")}
                  autoCapitalize="words"
                />
              </div>

              <div className="col-12 col-xl-6">
                <CustomSelect
                  label="Gênero"
                  name="gender"
                  placeholder="Selecione"
                  options={genderOptions}
                  value={{
                    label: LabelGender[selectedGender] ?? "Selecione",
                    value: selectedGender,
                  }}
                  required={
                    selectedGender === "" || selectedGender === "Selecione"
                  }
                  onChange={handleSelectGender}
                />
              </div>

              <div className="col-12 col-xl-6">
                <CustomSelect
                  menuPlacement="top"
                  label="Estado civil"
                  required={
                    selectedMaritalStatus === "" ||
                    selectedMaritalStatus === "Selecione"
                  }
                  name="marital_status"
                  placeholder="Selecione"
                  options={maritalStatusOptions}
                  value={{
                    label:
                      LabelMaritalStatus[selectedMaritalStatus] ?? "Selecione",
                    value: selectedMaritalStatus,
                  }}
                  onChange={handleSelectMaritalStatus}
                />
              </div>

              <div className="col-12 col-xl-6">
                <Input
                  type="text"
                  name="nationality"
                  value={values.nationality}
                  required={!values.nationality}
                  label="Nacionalidade"
                  placeholder="Brasileira"
                  error={touched.nationality && errors.nationality}
                  success={
                    !errors.nationality && values.nationality ? true : false
                  }
                  onChange={handleChange("nationality")}
                  autoCapitalize="words"
                />
              </div>

              <div className="col-12 col-xl-6">
                <CustomSelect
                  menuPlacement="top"
                  label="Estado de nascimento"
                  name="birth_state"
                  placeholder="Selecione"
                  options={ufOptions}
                  value={{
                    label:
                      selectedBirthState !== ""
                        ? selectedBirthState
                        : "Selecione",
                    value: selectedBirthState,
                  }}
                  required={
                    selectedBirthState === "" ||
                    selectedBirthState === "Selecione"
                  }
                  onChange={handleSelectBirthState}
                />
              </div>
              <Spacer bottom={30} />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default General;
