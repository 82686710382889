import {
  Button,
  CustomSelect,
  Dropzone,
  LateralModal,
  ProgressBar,
  Spacer,
} from "@zilliondigital/mirage-ui";
import usePix from "~/hooks/PIX/usePix";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useEffect, useMemo } from "react";

import styled from "styled-components";

import useCollections from "~/hooks/Collections/useCollections";
import useToast from "~/hooks/useToast";
import { APIResponse, CnabReaderResponse } from "~/types";
import ApiService from "~/services/api";
import Description from "~/components/Texts/Description";
import CustomerTax from "~/components/CustomerTax";
import { formatNumberToCurrencyWithoutSimbol } from "~/utils";

const CnabLabel = styled.label`
  font-family: "Proxima Nova";
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: rgb(102, 102, 102);
  margin: 0px 0px 8px;
  position: relative;
`;

export const _massOperate = async (
  _to_send_data: FormData | object | null,
  validate = false
): Promise<any> => {
  const _operation = validate ? "validate" : "transfer";
  const customHeaders = validate
    ? {
        "Content-Type": "multipart/form-data",
      }
    : {};

  try {
    const mass = await ApiService.HttpPost({
      route: `pix/mass/${_operation}/`,
      token: true,
      customHeaders: customHeaders,
      body: _to_send_data,
    });

    const { data } = mass;

    return data;
  } catch (error: any) {
    if (error.response) {
      if (typeof error.response.data === "string") {
        return false;
      }
      return error.response.data;
    }
  }
};

const PixLotModal = () => {
  const {
    file,
    setFile,
    submitting,
    setSubmitting,
    operations,
    setOperations,
    setLotQuantity,
    renderDropzone,
    openPixLotModal,
    setOpenPixLotModal,
    taxPixLot,
    setTaxPixLot,
    fetchPixOperations,
  } = usePix();

  const { showToast } = useToast();

  const fetchTax = async () => {
    try {
      const response = await ApiService.HttpPost({
        route: "customer-global-tax/",
        token: true,
        body: {
          slug: "pix_lot",
          tax_is_enabled: true,
        },
      });

      setTaxPixLot(response.data.customer_tax_out);
    } catch (err) {
      console.error("Erro", err);
    }
  };

  const onFileChange = (files: File[]) => {
    if (files.length > 1) {
      showToast(
        "Arquivo",
        "Envio apenas um arquivo no formato .xls/.xlsx",
        "error"
      );
      return;
    }
    setFile(files[0]);
  };

  const submitValidationFile = async () => {
    setSubmitting(true);
    const form_data = new FormData();
    form_data.append("file", file);

    const response = await _massOperate(form_data, true);

    if (response && !response.error) {
      setOperations(response.data);

      const success_operations = response.data.filter((_register: any) => {
        return _register.response.success;
      });
      setLotQuantity(success_operations.length);

      handleCloseModal();
      await fetchPixOperations();
    } else {
      showToast(
        "Arquivo",
        "Erro ao enviar arquivo ou arquivo inválido",
        "error"
      );
    }

    setSubmitting(false);
  };

  const onRemoveFile = (removed?: any) => {
    setFile("");
    setOperations([]);
    setSubmitting(false);
  };

  const operationTotal = useMemo(() => {
    if (operations.length === 0) return 0;

    return operations.reduce((prev, curr: any) => prev + curr.row.amount, 0);
  }, [operations]);

  const hasError = useMemo(() => {
    if (operations.length === 0) return false;

    return operations.some(
      (item: any) => !item.response || !item.response.success
    );
  }, [operations]);

  const handleCloseModal = () => {
    setOpenPixLotModal(!openPixLotModal);
    // setFile("");
    // setOperations([]);
    // setSubmitting(false);
  };

  useEffect(() => {
    fetchTax();
  }, []);

  return (
    <Fragment>
      <LateralModal
        style={{ zIndex: "3" }}
        header
        title={"Novo pix em lote"}
        open={openPixLotModal}
        toggleModal={() => handleCloseModal()}
      >
        <div className="p-4">
          <Description>
            Envie seu documento de pix em lote para ser processado, não se
            preocupe, você poderá conferir as transações antes de autoriza-las.
          </Description>

          <Spacer bottom={20} />
          <div>
            {renderDropzone ? (
              <Dropzone
                helper="O arquivo deve estar no formato .xls"
                onFilesChanged={onFileChange}
                onRemoveFile={onRemoveFile}
                accept={{
                  "application/msword": [".xls", ".xlsx"],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [".xls", ".xlsx"],
                }}
              />
            ) : null}
          </div>
          <div>
            <Description color="#666666">{`Taxa `}</Description>
            <Description className="font-weight-bolder">
              R$ {formatNumberToCurrencyWithoutSimbol(taxPixLot)}
              {" - (Por transação do lote)"}
            </Description>
          </div>
          <div className="row align-items-start">
            <div className="col-12">
              <Spacer bottom={20} />
              <div className=" d-flex justify-content-end">
                <Button
                  mode="border"
                  label="Cancelar"
                  onClick={() => handleCloseModal()}
                />
                <Spacer left={20} />
                <Button
                  mode="main"
                  label={"Avançar"}
                  loading={submitting}
                  disabled={file === "" || submitting}
                  onClick={() => submitValidationFile()}
                />
              </div>
            </div>
          </div>
        </div>
      </LateralModal>
    </Fragment>
  );
};

export default PixLotModal;
