import constate from "constate";
import { useState } from "react";
import ApiService from "~/services/api";
import useToast from "./useToast";
import useAccount from "./useAccount";
import { formatCPFCNPJ } from "~/utils";
import BarcodeIcon from "~/assets/icons/Type/barcode.svg";
import PixIcon from "~/assets/icons/Type/pix2.svg";
import TransferIcon from "~/assets/icons/Type/ted2.svg";
import usePermission from "./usePermission";

type RecipientType = {
  name: string;
  document_number: string;
  bank_account: string;
};

export const OPT_TYPES: { [index: string]: string } = {
  ted: "TED",
  tev: "TEV",
  pix_transfer: "PIX",
  pix_lot_transfers: "PIX EM LOTE",
  billet_payment: "BOLETO",
};

export const OPT_TYPES_ICONS: { [index: string]: string } = {
  ted: TransferIcon,
  tev: TransferIcon,
  pix_transfer: PixIcon,
  pix_lot_transfers: PixIcon,
  billet_payment: BarcodeIcon,
};

const [AuthorityProvider, useAuthority] = constate(() => {
  const [canApprove, setCanApprove] = useState<boolean>(false);
  const [openDesapproveModal, setOpenDesapproveModal] =
    useState<boolean>(false);
  const [statusColumnSelected, setStatusColumnSelected] =
    useState<boolean>(false);
  const [isRepproved, setIsRepproved] = useState<boolean>(false);
  const [canNotSign, setCanNotSign] = useState<boolean>(false);
  const { showToast } = useToast();
  const { mainAccount } = useAccount();
  const [operations, setOperations] = useState<any[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [approvers, setApprovers] = useState<any[]>([]);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [bankAccounts, setBankAccounts] = useState<any[]>([]);
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const [loadingBankAccounts, setLoadingBankAccounts] =
    useState<boolean>(false);
  const [loadingApprove, setLoadingApprove] = useState<boolean>(false);
  const [currentModalData, setCurrentModalData] = useState<any>();
  const [currentGrantModalData, setCurrentGrantModalData] = useState<any>();
  const [openOperationDetailModal, setOpenOperationDetailModal] =
    useState<boolean>(false);
  const [loadingRemoveApprover, setLoadingRemoveApprover] =
    useState<boolean>(false);

  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<any[]>([]);

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState(0);

  const [loadingOperations, setLoadingOperations] = useState<boolean>(false);

  const [insuficientBalance, setInsuficientBalance] = useState<boolean>(false);

  const { setConfirmTransactionsEquals } = usePermission();

  const getOperations = async (params?: any) => {
    setLoadingOperations(true);

    try {
      const { data: response } = await ApiService.HttpGet({
        route: "charge/operation-approval/",
        params: params,
        token: true,
      });

      if (response) {
        setOperations(response?.results);

        setLoadingOperations(false);
      }
    } catch (err: any) {
      showToast("Operações não encontradas!", err.response, "error");
    }
    setLoadingOperations(false);
  };

  const approveOperation = async (transaction_bank_sign_ids: number[]) => {
    setLoadingApprove(true);

    try {
      const { data: response } = await ApiService.HttpPost({
        route: "charge/operation-approval/",
        body: { transaction_bank_sign: transaction_bank_sign_ids },
        token: true,
      });

      if (!response.error) {
        setLoadingApprove(false);
        setSelectedIds([]);
        setSelectedTransaction([]);
        getOperations({ pending_operation: true, today: true });
        setStatusColumnSelected(false);

        if (
          response.data.success.length > 0 &&
          response.data.failed.length === 0
        ) {
          showToast("Sucesso!", "Operações aprovadas com sucesso!", "success");
        } else if (
          response.data.failed.length > 0 &&
          response.data.success.length === 0
        ) {
          showToast(
            "Ocorreu um erro!",
            "As operações não foram aprovadas!",
            "error"
          );
        } else if (
          response.data.success.length > 0 &&
          response.data.failed.length > 0
        ) {
          showToast(
            "Atenção!",
            "Nem todas operações foram aprovadas!",
            "warning"
          );
        }
      }

      setLoadingApprove(false);
    } catch (err: any) {
      setLoadingApprove(false);
      showToast("Ocorreu um erro", err.response.data.message ?? "", "error");
    }
  };

  const repproveOperation = async (params: object) => {
    setOpenDesapproveModal(false);
    try {
      const { data: response } = await ApiService.HttpPost({
        route: "charge/operation-approval/refuse-operations/",
        body: { operations: params },
        token: true,
      });

      if (!response.error) {
        showToast("Sucesso!", "Operações reprovadas com sucesso!", "success");
        setLoadingApprove(false);
        setSelectedIds([]);
        setSelectedTransaction([]);
        getOperations({ pending_operation: true, today: true });
      }
    } catch (err: any) {
      showToast("Ocorreu um erro", err.response.data.message, "error");
    }
  };

  const getApprovers = async () => {
    setLoadingList(true);

    try {
      const { data: response } = await ApiService.HttpGet({
        route: "charge/operation-approval-group/",
        token: true,
      });

      if (response) {
        setApprovers(response.results);
      }
    } catch (err: any) {
      showToast("Aprovadores", "não encontrados!", "error");
    }
    setLoadingList(false);
  };

  const getAccounts = async () => {
    setLoadingList(true);

    try {
      const { data: response } = await ApiService.HttpGet({
        route: "bank-account-user/",
        params: {
          granted_permissions_only: "others",
        },
        token: true,
      });

      if (response) {
        const granted_permissions = response.results.filter(
          (bk: any) => bk.partner !== mainAccount?.owner.id
        );

        if (!approvers) {
          await getApprovers();
        }

        const added_accounts = approvers.map((_bk) => _bk.allowed_account);
        const removed_alreay_in = granted_permissions.filter((bk: any) => {
          return !added_accounts.includes(bk.id);
        });
        setAccounts(removed_alreay_in);
      }
    } catch (err: any) {
      showToast("Contas", "não encontradas!", "error");
    }
    setLoadingList(false);
  };

  const getBankAccounts = async () => {
    setLoadingBankAccounts(true);

    try {
      const { data: response } = await ApiService.HttpGet({
        route: "bank-account/",
        params: { no_balance: "true" },
        token: true,
      });

      if (response) {
        setBankAccounts(response.results);
      }
    } catch (err: any) {
      showToast("Contas", "não encontradas!", "error");
    }
    setLoadingBankAccounts(false);
  };

  const removeApprover = async (id: number) => {
    setLoadingRemoveApprover(true);

    try {
      const { data: response } = await ApiService.HttpDelete({
        route: `charge/operation-approval-group/${id}/`,
        token: true,
      });

      if (!response.error) {
        showToast("Remoção", "Removido com sucesso!", "success");
        getApprovers();
      }
    } catch (err: any) {
      showToast("Remoção", "Não removido, tente novamente mais tarde", "error");
    }
    setLoadingRemoveApprover(false);
  };

  const getAccountFromSign = (
    payload: any,
    recipient: boolean = true
  ): RecipientType | undefined => {
    const { operation_data } = payload;

    if (["ted", "tev"].includes(payload.bank_operation_type)) {
      const { full_bank_account, recipient_bank_account } = operation_data;

      const rightAccess = recipient
        ? recipient_bank_account
        : full_bank_account;

      const data = {
        name: recipient
          ? operation_data.full_recipient.name
          : operation_data.full_partner.name,
        document_number: formatCPFCNPJ(
          recipient
            ? operation_data.full_recipient.document_number
            : operation_data.full_partner.document_number
        ),
        bank_account: `Ag: ${rightAccess.agency} — conta: ${
          rightAccess.account_number
        }-${rightAccess.account_number_digit} — banco: ${
          rightAccess.bank_data?.name ?? " Não informado"
        }`,
      };
      return data;
    }

    if (payload.bank_operation_type === "pix_transfer") {
      const { sender_account, receiver_account } = operation_data;

      const rightAccess = recipient ? receiver_account : sender_account;

      const data = {
        name: rightAccess.owner.name,
        document_number: formatCPFCNPJ(rightAccess.owner.document_number),
        bank_account: `Ag: ${rightAccess.agency} — conta: ${rightAccess.account_number}-${rightAccess.account_number_digit} — banco: ${rightAccess.bank_data.name}`,
      };
      return data;
    }
    if (payload.bank_operation_type === "pix_lot_transfers") {
      const { partner_data, payer_bank_account_data } = operation_data;
      let data = {
        name: "",
        document_number: "",
        bank_account: "",
      };

      if (!recipient) {
        data = {
          name: partner_data.name,
          document_number: formatCPFCNPJ(partner_data.document_number),
          bank_account: `Ag: ${payer_bank_account_data.agency} — conta: ${payer_bank_account_data.account_number}-${payer_bank_account_data.account_number_digit} — banco: ${payer_bank_account_data.bank_data.name}`,
        };
      }

      return data;
    }
  };

  const addNewApprover = async (allowed_partner: number) => {
    setLoadingApprove(true);

    try {
      const { data: response } = await ApiService.HttpPost({
        route: "charge/operation-approval-group/",
        body: { allowed_partner: allowed_partner },
        token: true,
      });

      if (!response.error) {
        showToast("Adicionar", "adicionado com sucesso!", "success");
        getApprovers();
      }
    } catch (err: any) {
      showToast("Inclusão", err.response.data.message, "error");
    }
    setLoadingApprove(false);
  };

  return {
    getOperations,
    getApprovers,
    getAccounts,
    getBankAccounts,
    repproveOperation,
    approveOperation,
    removeApprover,
    addNewApprover,
    canApprove,
    setCanApprove,
    openDesapproveModal,
    setOpenDesapproveModal,

    operations,
    approvers,
    accounts,
    bankAccounts,

    statusColumnSelected,
    setStatusColumnSelected,

    loadingList,
    loadingApprove,
    loadingRemoveApprover,
    loadingBankAccounts,
    loadingOperations,
    setLoadingOperations,
    openOperationDetailModal,
    setOpenOperationDetailModal,

    currentGrantModalData,
    setCurrentGrantModalData,
    currentModalData,
    setCurrentModalData,

    getAccountFromSign,

    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    setSelectedIds,
    selectedIds,
    selectedTransaction,
    setSelectedTransaction,
    setTotalAmount,
    totalAmount,
    setCanNotSign,
    canNotSign,
    setIsRepproved,
    isRepproved,
    setInsuficientBalance,
    insuficientBalance,
  };
});

export { AuthorityProvider };

export default useAuthority;
