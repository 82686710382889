import {
  Button,
  LateralModal,
  Spacer,
  Table,
  Tooltip,
} from "@zilliondigital/mirage-ui";
import { Fragment, useEffect, useMemo, useState } from "react";
import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import useAccount from "~/hooks/useAccount";
import usePix from "~/hooks/PIX/usePix";
import useMyKeys from "~/hooks/PIX/useMyKeys";
import useToast from "~/hooks/useToast";
import styled from "styled-components";
import DeleteIcon from "~/assets/icons/delete.png";
import MoreIcon from "~/assets/icons/Type/More.png";
import theme from "~/themes/theme";
import { formatCPFCNPJ } from "~/utils";
import ApiService from "~/services/api";

import useToken from "~/hooks/useToken";
import Skeleton from "~/components/SkeletonTables";
import { Columns } from "~/types";

const ToolTipContainerAvailableBalance = styled.div`
  position: relative;
  left: 42px;
  top: -20px;
`;

const DeleteImage = styled.img`
  position: relative;
  top: 12px;
  height: 18px;
  cursor: pointer;
`;

const RegisterKeyImage = styled.img`
  position: relative;
  top: 12px;
  height: 15px;
  cursor: pointer;
`;

const LabelKey = styled.span`
  font-size: 12px;
  color: #808080;
`;

const MyKeysModal = () => {
  const { openMyKeysModal, setOpenMyKeysModal } = usePix();

  const { mainAccount } = useAccount();

  const [typesKeyToRegister, setTypesKeysToRegister] = useState<string[]>([]);
  const [
    dataRecomendationsKeysToRegister,
    setDataRecomendationsKeysToRegister,
  ] = useState<any>([]);

  const {
    setOpenDeleteKeyModal,
    pixKeysData,
    setIdKey,
    setKeyTypeLabel,
    typeOfKey,
    setTypeOfKey,
    setOpenNewKeyModal,
    getPixKeys,
    idKey,
    loadingPixKeys,
  } = useMyKeys();

  const { showToast } = useToast();

  const { sendPartnerToken, getToken } = useToken();

  const typesKeysLabel: { [key: string]: string } = {
    telefone: "Celular",
    email: "Email",
    document_number: "CPF/CNPJ",
    cpf: "CPF",
    cnpj: "CNPJ",
    evp: "Chave aleatória",
  };

  const dataKeys = pixKeysData
    ?.filter((key) => key.key !== null)
    .map((key, index) => ({
      key: key.key,
      type: key.type,
      id: key.id,
      index: index,
      status: key.status,
    }));

  const pixKeysDataFiltered = useMemo(() => {
    const initialFilterAndMap = pixKeysData
      ?.filter((key: any) => key.key !== null)
      .map((key, index) => ({
        key: key.key,
        type: key.type,
        index: index,
        status: key.status,
        id: key.id,
      }));
    const result = initialFilterAndMap.reduce((acc: any, current: any) => {
      if (!acc.some((item: any) => item.key === current.key)) {
        acc.push(current);
      }
      return acc;
    }, []);
    return result;
  }, [pixKeysData]);

  const [isToolTipDeleteVisible, setIsToolTipDeleteVisible] = useState<
    boolean[]
  >(new Array(pixKeysDataFiltered?.length).fill(false));

  const [numberOfEVPs, setNumberOfEVPs] = useState<number>(0);

  useEffect(() => {
    const validTypes = ["email", "evp", "cpf", "cnpj", "telefone"];

    const missingTypes = validTypes.filter(
      (type) => !dataKeys?.map((key) => key.type).includes(type)
    );

    const numberOfEVPS = dataKeys?.filter((key) => key.type === "evp").length;

    if (numberOfEVPS < 5) {
      missingTypes.push("evp");
    }

    setTypesKeysToRegister(missingTypes);

    setNumberOfEVPs(numberOfEVPS);
  }, [pixKeysData]);

  useEffect(() => {
    if (mainAccount) {
      setDataRecomendationsKeysToRegister(
        typesKeyToRegister
          .map((typeKey: any, index: number) => {
            return {
              key:
                typeKey === "evp"
                  ? "Chave aleatória"
                  : mainAccount.owner[
                      typeKey === "telefone" ? "mobile_phone" : typeKey
                    ],
              type_key: typeKey,
              numberOfEVPs: numberOfEVPs,
              index: index,
            };
          })
          .filter((key) => key.key !== undefined)
      );
    }
  }, [typesKeyToRegister, mainAccount]);

  const [isToolTipRegisterKeyVisible, setIsToolTipRegisterKeyVisible] =
    useState<boolean[]>(
      new Array(dataRecomendationsKeysToRegister.length).fill(false)
    );

  const resendToken = async (_data: any) => {
    try {
      const { data } = await ApiService.HttpPost({
        route: `pix/key/${_data.id}/confirm_hold/`,
        body: {
          resend_code: true,
        },

        token: true,
      });

      if (!data.data.success) {
        showToast(
          "Não foi possível reenviar o token de confirmação.",
          `${data.data.message}`,
          "error"
        );

        return;
      }

      const token = await getToken({
        title: `Validação de ${_data.type} `,
        description: `Informe o token que enviamos para o seu ${_data.type} que deseja criar a chave PIX`,
      });

      const dataToken = await ApiService.HttpPost({
        route: `pix/key/${_data.id}/confirm_hold/`,
        body: {
          code: token,
          resend_code: false,
        },

        token: true,
      });

      if (dataToken.data.data.success) {
        showToast("Validação refeita com sucesso", "", "success");
        getPixKeys();
      } else {
        showToast("Ocorreu um erro", "", "error");
        getPixKeys();
      }
    } catch {
      showToast("Ocorreu um erro", "", "error");
    }
  };

  const columnsMyKeys = [
    {
      label: "",
      key: "type",

      renderData: (data: any) => (
        <div
          className="d-flex justify-content-start align-items-center    "
          style={{ minWidth: "" }}
        >
          <div className="d-flex flex-column justify-content-center align-items-start ps-3 ">
            <Description> {typesKeysLabel[data?.type]}</Description>
            {data.status === "registering" &&
            (data.type === "email" || data.type === "telefone") ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  {" "}
                  Ocorreu um erro na verificação do token, por favor tente
                  novamente.
                </Description>
                <div onClick={() => resendToken(data)}>
                  <Description color={theme.colors.secondary.main}>
                    Reenviar Token
                  </Description>
                </div>
              </>
            ) : data.status === "confirmed" &&
              (data.type === "email" || data.type === "telefone") ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  Token confirmado. Sua chave logo estará disponível para
                  ultilização.
                </Description>
                <Spacer bottom={10} />
                <div onClick={() => resendToken(data)}>
                  <Description color={theme.colors.secondary.main}>
                    Reenviar Token
                  </Description>
                </div>
              </>
            ) : data.status === "registering" &&
              data.type !== "email" &&
              data.type !== "telefone" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  {" "}
                  A chave encontra-se no status "registrando" e logo estará
                  disponível para ultilização.
                </Description>
                <Spacer bottom={10} />
              </>
            ) : data.status === "canceling" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  A chave está em processo de cancelamento. Logo ela não
                  aparecerá mais aqui.
                </Description>{" "}
              </>
            ) : data.status === "error_ownership" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  Ocorreu um erro ao tentar registrar esta chave, por favor
                  entre em contato com o suporte.
                </Description>{" "}
              </>
            ) : data.status === "error_portability" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  Esta chave está com problema de portabilidade, por favor entre
                  em contato com o suporte.
                </Description>{" "}
              </>
            ) : data.status === "error" ? (
              <>
                {" "}
                <LabelKey>{data.key}</LabelKey>
                <Description>
                  Está chave está com um erro, por favor entre em contato com o
                  suporte.
                </Description>{" "}
              </>
            ) : (
              <LabelKey>
                {" "}
                {data.type === "cpf" || data.type === "cnpj"
                  ? formatCPFCNPJ(data.key)
                  : data.key}
              </LabelKey>
            )}
          </div>
        </div>
      ),
    },
    {
      label: "",
      key: "",
      renderData: (data: any, index: number) => (
        <div>
          {data.status === "registered" && (
            <div
              className="d-flex justify-content-end align-items-center flex-1  pe-4 position-relative"
              onClick={(e) => e.stopPropagation()}
            >
              <ToolTipContainerAvailableBalance>
                {" "}
                <Tooltip
                  tooltipColor={theme.colors.error.main}
                  tooltipLabel="Remover"
                  visible={isToolTipDeleteVisible[data.index]}
                />
              </ToolTipContainerAvailableBalance>

              <Spacer bottom={0} />
              <DeleteImage
                onClick={() => {
                  setKeyTypeLabel(typesKeysLabel[data.type]);
                  setIdKey(data.id);

                  setOpenDeleteKeyModal(true);
                }}
                src={DeleteIcon}
                onMouseEnter={() =>
                  setIsToolTipDeleteVisible((prevState) => {
                    const newState = prevState.slice();
                    newState[data.index] = true;
                    return newState;
                  })
                }
                onMouseLeave={() =>
                  setIsToolTipDeleteVisible((prevState) => {
                    const newState = prevState.slice();
                    newState[data.index] = false;
                    return newState;
                  })
                }
                style={
                  isToolTipDeleteVisible[data.index]
                    ? {
                        filter:
                          "brightness(0) saturate(100%) invert(69%) sepia(58%) saturate(6738%) hue-rotate(331deg) brightness(91%) contrast(98%)",
                      }
                    : {}
                }
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  //   const columnsKeysToRegister = [
  //     {
  //       label: "",
  //       key: "type",

  //       renderData: (data: any) => (
  //         <div className="d-flex justify-content-start align-items-center    ">
  //           <div className="d-flex flex-column justify-content-center align-items-start ps-3 ">
  //             {data.type_key == "document_number" && (
  //               <>
  //                 <Description>{formatCPFCNPJ(data.key)}</Description>
  //                 <LabelKey>CPF/CNPJ</LabelKey>
  //               </>
  //             )}
  //             {data.type_key === "telefone" && (
  //               <>
  //                 <Description>{data.key}</Description>
  //                 <LabelKey>Celular</LabelKey>
  //               </>
  //             )}
  //             {data.type_key === "evp" && numberOfEVPs !== 5 && (
  //               <>
  //                 <Description>Chave aleatória</Description>
  //                 <LabelKey>{5 - numberOfEVPs} chaves restantes</LabelKey>
  //               </>
  //             )}
  //             {data.type_key === "email" && (
  //               <>
  //                 {" "}
  //                 <Description>{data.key}</Description>
  //                 <LabelKey>Email</LabelKey>
  //               </>
  //             )}
  //           </div>
  //         </div>
  //       ),
  //     },
  //     {
  //       label: "",
  //       key: "",
  //       renderData: (data: any, index: number) => (
  //         <div
  //           className="d-flex justify-content-end align-items-center flex-1  pe-4 position-relative"
  //           onClick={(e) => e.stopPropagation()}
  //         >
  //           <ToolTipContainerAvailableBalance>
  //             {" "}
  //             <Tooltip
  //               tooltipColor={theme.colors.info.light300}
  //               tooltipLabel="Adicionar"
  //               visible={isToolTipRegisterKeyVisible[data.index]}
  //             />
  //           </ToolTipContainerAvailableBalance>

  //           <Spacer bottom={0} />
  //           <RegisterKeyImage
  //             src={MoreIcon}
  //             onClick={() => {
  //               setTypeOfKey(
  //                 data.type_key === "telefone" ? "phone_number" : data.type_key
  //               );
  // null;
  //             }}
  //             onMouseEnter={() =>
  //               setIsToolTipRegisterKeyVisible((prevState) => {
  //                 const newState = prevState.slice();
  //                 newState[data.index] = true;
  //                 return newState;
  //               })
  //             }
  //             onMouseLeave={() =>
  //               setIsToolTipRegisterKeyVisible((prevState) => {
  //                 const newState = prevState.slice();
  //                 newState[data.index] = false;
  //                 return newState;
  //               })
  //             }
  //             style={
  //               isToolTipRegisterKeyVisible[data.index]
  //                 ? {
  //                     filter:
  //                       " brightness(0) saturate(100%) invert(64%) sepia(72%) saturate(318%) hue-rotate(155deg) brightness(89%) contrast(100%)",
  //                   }
  //                 : {}
  //             }
  //           />
  //         </div>
  //       ),
  //     },
  //   ];

  return (
    <Fragment>
      <LateralModal
        style={{ zIndex: "3" }}
        header
        title="Minhas chaves"
        open={openMyKeysModal}
        toggleModal={() => setOpenMyKeysModal(!openMyKeysModal)}
      >
        <Fragment>
          <div className="pt-4 pb-4">
            <div className="ps-4 pe-4">
              <Description color="#666666">
                Facilite a forma de receber seus pagamentos e transferências
                cadastrando seu CNPJ, celular ou e-mail como chave Pix, ou gerar
                chaves aleatórias sem seus dados pessoais. Pix é seguro e você
                recebe na hora, todos os dias.
              </Description>
            </div>
            <Spacer bottom={30} />

            {pixKeysData?.length > 0 && (
              <>
                <div>
                  <Title className="ps-4 pe-4">
                    Chaves cadastradas para {mainAccount?.owner.name}
                  </Title>
                </div>
                <Spacer bottom={15} />
                <div style={{ zIndex: "1", height: "500px", overflow: "auto" }}>
                  {loadingPixKeys ? (
                    <Skeleton />
                  ) : (
                    <Table
                      data={pixKeysDataFiltered}
                      columns={columnsMyKeys}
                      dataSpacer={15}
                      renderHeader={() => <Fragment />}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="d-flex justify-content-end pe-4 ps-2">
            <Button
              label="Registrar chave"
              mode="border"
              onClick={() => setOpenNewKeyModal(true)}
            />
          </div>
          <div className="p-4 ">
            {/* <div>
              <Title>Sugestões de novas chaves</Title>
            </div> */}
          </div>

          {/* <Table
            data={dataRecomendationsKeysToRegister}
            columns={columnsKeysToRegister}
            dataSpacer={15}
            renderHeader={() => <Fragment />}
          /> */}

          <Spacer bottom={50} />
        </Fragment>
      </LateralModal>
    </Fragment>
  );
};

export default MyKeysModal;
