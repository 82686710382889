import {
  Button,
  CustomSelect,
  Input,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import useNewContact from "~/hooks/Contacts/useNewContact";
import useCEP from "~/hooks/useCEP";
import { contactType, onlyNumbers, optionsPhoneCode } from "~/services/utils";
import {
  INVALID_FORMAT,
  REQUIRED_LABEL,
  cnpjCpfRegex,
} from "~/services/validators";
import documentNumberValidator from "~/utils/validateDocNumber";

const ContainerData = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

type FormValues = {
  name: string;
  type: string;
  document_number: string;
  birth_date: string;
  phone: string;
  email: string;
};

const initialValues: FormValues = {
  name: "",
  type: "",
  document_number: "",
  birth_date: "",
  phone: "",
  email: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_LABEL),
  type: Yup.string().required(REQUIRED_LABEL),
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(cnpjCpfRegex, INVALID_FORMAT),
  birth_date: Yup.string().required(REQUIRED_LABEL),
  phone: Yup.string().required(REQUIRED_LABEL),
  email: Yup.string().required(REQUIRED_LABEL),
});

const BasicData = () => {
  const [selectedTypeValue, setSelectedTypeValue] = useState<any>();

  const { setNewContactPayload, setStep } = useNewContact();
  const navigate = useNavigate();

  const { setAddress } = useCEP();

  const handleSubmit = (values: FormValues) => {
    setSubmitting(true);
    setNewContactPayload({
      address: {},
      default: false,
      name: values.name,
      document_number: onlyNumbers(values.document_number),
      document_type: values.document_number.length > 14 ? "cnpj" : "cpf",
      birth_date: values.birth_date,
      mobile_phone: onlyNumbers(values.phone),
      email: values.email,
      contact_type: String(selectedTypeValue),
      target: "contact",
      status: "active",
      type: "contact",
      bank_account: false,
      create_external_account: false,
      create_internal_account: false,
    });

    setTimeout(() => {
      setStep("address");

      setSubmitting(false);
    }, 500);
  };

  const {
    values,
    handleBlur,
    submitForm,
    handleChange,
    errors,
    touched,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,

    onSubmit: handleSubmit,
  });

  const isValidDocument =
    values.document_number.length >= 14 &&
    documentNumberValidator(values.document_number);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (values.document_number.length < 14 && !isValidDocument) {
      setError("initialState");
    } else if (values.document_number.length >= 14 && isValidDocument) {
      setError("notError");
    } else if (values.document_number.length >= 14 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document_number]);

  const handleChangeSelect = (optionSelected: any) => {
    setSelectedTypeValue(optionSelected.target.value);
  };

  useEffect(() => {
    setAddress({
      zip_code: "",
      street: "",
      district: "",
      city: "",
      state: "",
      error: "",
    });
  }, []);

  return (
    <>
      <ContainerData className="container p-4">
        <form>
          <div className="row ">
            <div className="col-3">
              <Input
                required
                name="name"
                label="Nome/Razão Social"
                placeholder="Nome"
                value={values.name}
                onChange={handleChange}
                success={values.name.length > 3}
              />
            </div>
            <div className="col-2 pt-2">
              {" "}
              <CustomSelect
                onChange={handleChangeSelect}
                onBlur={handleBlur}
                name="type"
                label="Tipo de contato"
                placeholder="Selecione"
                options={contactType}
              />
            </div>
            <div className="col-7 pt-2"></div>

            <div className="col-3">
              <Input
                name="document_number"
                label="CPF/CNPJ"
                placeholder="123.456.789-85"
                success={error === "notError"}
                error={error === "Documento inválido" && error}
                required
                mask={
                  values.document_number.length > 14
                    ? "99.999.999/9999-99"
                    : "999.999.999-9999999"
                }
                value={values.document_number}
                onChange={(props: any) => {
                  handleChange(props);
                }}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-2">
              <Input
                required
                type="date"
                name="birth_date"
                label="Data de nascimento"
                value={values.birth_date}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="col-7"></div>

            <div className="col-3">
              <Input
                name="phone"
                label="Celular"
                mask="(99) 99999-9999"
                placeholder="(11) 98144-5263"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                success={values.phone.length >= 9}
              />
            </div>
            <div className="col-3">
              <Input
                required
                name="email"
                label="Email"
                placeholder="seunome@empresa.com"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                success={values.email.includes("@")}
              />
            </div>
          </div>
        </form>
      </ContainerData>
      <div className="w-100 d-flex justify-content-center justify-content-lg-end p-3 align-items-center">
        <Button
          mode="phantom"
          label="Cancelar"
          onClick={() => navigate("/app/contacts")}
        />
        <Spacer right={20} />
        <Button
          mode="main"
          label="Salvar e avançar"
          disabled={
            !(
              values.name &&
              values.birth_date &&
              values.document_number &&
              values.email &&
              values.phone
            ) ||
            !values.email.includes("@") ||
            error !== "notError"
          }
          onClick={() => handleSubmit(values)}
          loading={isSubmitting}
        />
      </div>
    </>
  );
};

export default BasicData;
