import styled from "styled-components";
import theme from "~/themes/theme";

const AppTitle = styled.span`
  font-size: 18px;
  color: #3b3b3b;
`;

type TitleProps = {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  bold?: string;
};

const Title = (props: TitleProps) => {
  const { children, className, color, bold } = props;

  return (
    <AppTitle
      {...props}
      className={className}
      style={{ color: color, fontWeight: bold }}
    >
      {children}
    </AppTitle>
  );
};

export default Title;
