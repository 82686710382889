import {
  Fragment,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  CustomSelect,
  Dropzone,
  InteractiveModal,
  NotControlledToolTip,
  Spacer,
} from "@zilliondigital/mirage-ui";
import EyeImg from "~/assets/icons/eye-Size=MD.svg";
import DeleteIcon from "~/assets/icons/delete.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { REQUIRED_LABEL } from "~/services/validators";
import useSignupPersonal from "~/hooks/Signup/useSignupPersonal";
import { useNavigate } from "react-router-dom";
import OpenAccordionIcon from "~/assets/icons/Actions/Chevron.png";
import CloseAccordionIcon from "~/assets/icons/Actions/x-circle.png";
import AppStorage from "~/services/storage";
import ApiService from "~/services/api";
import { ImgEye } from "~/pages/App/app-content.styles";
import { ImgDelete } from "~/pages/App/Permissions/NewConcessionModal";
import DraggableModal from "~/components/DraggeableModal";
import theme from "~/themes/theme";
import { TitleModal } from "~/pages/Authentication/Auth.styles";
import useToast from "~/hooks/useToast";
import RgFrontImage from "../../../assets/rg_front.png";
import RgBackImage from "../../../assets/rg_back.png";
import CNHFrontImage from "../../../assets/CNH_front.png";
import CNHBackImage from "../../../assets/CNH_back.png";
import Badge from "~/components/Badge";

const Title = styled.span`
  font-size: 29px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

const Container = styled.div<AttachamentsProps>`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 90%;
  padding: ${({ open }) => (open ? "0 30px 20px 30px" : "0 30px")};
`;

const ToggleButton = styled.button<AttachamentsProps>`
  background-color: #f2f6f9;
  width: 34px;
  height: 34px;
  color: #000;
  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.3s;

  &:hover {
    color: #000;
    background-color: #f2f6f9;
  }
`;

const ContainerDocs = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin-top: 10px;
  border-radius: 8px;
  background-color: ${theme.colors.base.light200};
`;

const ContainerAttachaments = styled.div<AttachamentsProps>`
  height: ${({ open }) => (open ? "auto" : "0")};
  pointer-events: ${({ open }) => (open ? "all" : "none")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  transition: 0.3s;
  overflow: ${({ open }) => (open ? "visible" : "hidden")};
`;

const ImgX = styled.img<AttachamentsProps>``;

type Values = {
  identify_document: [];
};

const initialValues: Values = {
  identify_document: [],
};

type AttachamentsProps = HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
};

const FormSchema = Yup.object().shape({
  identify_document: Yup.array().required(REQUIRED_LABEL),
});

const Attachments = (props: AttachamentsProps) => {
  const [opened, setOpened] = useState(false);

  const { setStep, newPartner, setCanAdvanced, setIsLoading } =
    useSignupPersonal();

  const navigate = useNavigate();

  const [allDocuments, setAllDocuments] = useState<any>([]);
  const [showingFile, setShowingFile] = useState<string | undefined>(undefined);
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] =
    useState<boolean>(false);
  const [dataDocumentToDelete, setDataDocumentToDelete] = useState<any>({});
  const [loadingDeleteDocument, setLoadingDeleteDocument] =
    useState<boolean>(false);
  const { showToast } = useToast();
  const [documentType, setDocumentType] = useState<string>("rg");
  const [openSendingDocumentModal, setOpenSendingDocumentModal] =
    useState<boolean>(false);

  const newPartnerSaved: any = localStorage.getItem("new_partner");
  const newPartnerSavedConverted = useMemo(
    () => JSON.parse(newPartnerSaved),
    [newPartnerSaved]
  );

  const registeredToken = useMemo(() => AppStorage.getRegisteredToken(), []);

  const labelDocsDict: { [key: string]: string } = {
    cpf: "CPF",
    front_document: "RG (Frente)",
    back_document: "RG (Verso)",
    cnh_front: "CNH (Frente)",
    cnh_back: "CNH (Verso)",
    proof_residence: "Comprovante de Residência",
  };

  const DocumentTypeSelectOptions = [
    { value: "rg", label: "RG" },
    { value: "cnh", label: "CNH" },
  ];

  const handleFormSubmit = (values: any) => {
    setStep("security");
    navigate("/signup/personal/security");
  };

  const { submitForm, handleSubmit } = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: handleFormSubmit,
  });

  const handleSelectDocumentType = useCallback(
    (selectedValue: any) => {
      setDocumentType(selectedValue.target.value);
    },
    [setDocumentType]
  );

  const handleCloseModal = useCallback(() => {
    setOpenDeleteDocumentModal(false);
    setDataDocumentToDelete({});
  }, [setOpenDeleteDocumentModal, setDataDocumentToDelete]);

  const getDocument = useCallback(async () => {
    const customHeaders = {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${registeredToken}`,
    };

    try {
      const { data } = await ApiService.HttpGet({
        route: `partner-documents/`,
        customHeaders,
      });

      setAllDocuments(data.results);
    } catch (err) {
      return null;
    }
  }, [setAllDocuments]);

  const deleteDocument = useCallback(
    async (data: any) => {
      setLoadingDeleteDocument(true);

      const customHeaders = {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${registeredToken}`,
      };

      try {
        const response = await ApiService.HttpDelete({
          route: `partner-documents/${data[0].id}`,
          customHeaders,
        });

        setLoadingDeleteDocument(false);
        handleCloseModal();
        getDocument();
        showToast(
          `${labelDocsDict[data[0].type]} excluído com sucesso!`,
          "",
          "success"
        );

        setTimeout(() => {
          setLoadingDeleteDocument(false);
        }, 2500);
      } catch (err) {
        handleCloseModal();
        setLoadingDeleteDocument(false);
        showToast(
          "Ocorreu um erro",
          "Não foi possivel excluir o documento, tente mais tarde.",
          "error"
        );
      }
    },
    [getDocument, handleCloseModal, labelDocsDict, setLoadingDeleteDocument]
  );

  const documentExists = useMemo(() => {
    return (type: string) =>
      allDocuments?.some((doc: any) => doc.type === type);
  }, [allDocuments]);

  const documentToDelete = useMemo(() => {
    return (type: string) => {
      const docToDelete = allDocuments?.filter((doc: any) => doc.type === type);
      setDataDocumentToDelete(docToDelete);
      setOpenDeleteDocumentModal(true);
    };
  }, [allDocuments, setDataDocumentToDelete, setOpenDeleteDocumentModal]);

  const sendDocument = useCallback(
    async (payload: any) => {
      setOpenSendingDocumentModal(true);

      const updateData = documentExists(payload.type);
      const hasDoc = allDocuments.filter(
        (doc: any) => doc.type === payload.type
      );

      const customHeaders = {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${registeredToken}`,
      };

      try {
        const res = updateData
          ? await ApiService.HttpPut({
              route: `partner-documents/${hasDoc[0].id}/`,
              customHeaders,
              body: payload,
            })
          : await ApiService.HttpPost({
              route: "partner-documents/",
              customHeaders,
              body: payload,
            });

        setOpenSendingDocumentModal(false);
        showToast(`Documento enviado com sucesso!`, "", "success");
        getDocument();
        return res;
      } catch (err) {
        setOpenSendingDocumentModal(false);
        showToast(`Ocorreu um erro!`, "", "success");
      }
    },
    [allDocuments, documentExists, getDocument, setOpenSendingDocumentModal]
  );

  const viewDocument = useCallback(
    (type: string) => {
      const docToView = allDocuments?.find((doc: any) => doc.type === type);
      if (docToView) {
        setShowingFile(docToView.file);
      }
    },
    [allDocuments, setShowingFile]
  );

  const renderForm = (
    type: string,
    label: string,
    description: string,
    onFileChange: any
  ) => (
    <>
      <div className="row mt-4">
        <Spacer bottom={20} />
        <div className="col-12 d-flex justify-content-start mt-2 mt-xxl-0">
          <div className="col-12">
            <div className="col-12 d-flex flex-column">
              <span className="" style={{ fontSize: "18px" }}>
                {label}
              </span>
              <div className="col-12">
                <span className="text-left" style={{ fontSize: "14px" }}>
                  {description}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <Dropzone
            notShowFile={true}
            helper="O arquivo deve estar no formato PDF, JPG ou PNG"
            onFilesChanged={onFileChange}
            multiple={false}
            accept={{
              images: ["image/png", "image/jpeg"],
              pdf: ["application/pdf"],
            }}
          />

          {documentExists(type) && (
            <>
              {" "}
              <ContainerDocs>
                <span> {labelDocsDict[type]}</span>
                <div className="d-flex">
                  <ImgEye
                    id={`view`}
                    src={EyeImg}
                    onClick={() => viewDocument(type)}
                  />
                  <NotControlledToolTip target={`view`}>
                    Visualizar
                  </NotControlledToolTip>
                  <Spacer left={10} />

                  <ImgDelete
                    id={`delete`}
                    src={DeleteIcon}
                    onClick={() => {
                      documentToDelete(type);
                    }}
                  />
                  <NotControlledToolTip target={`delete`}>
                    Excluir
                  </NotControlledToolTip>
                </div>
              </ContainerDocs>
            </>
          )}
        </div>
      </div>
    </>
  );

  const allRequiredDocumentsExist = (documents: any): boolean => {
    if (!documents) return false;

    const requiredDocuments: any = {
      cpf: false,
      front_document: false,
      back_document: false,
      cnh_front: false,
      cnh_back: false,
      proof_residence: false,
    };

    documents.forEach((doc: any) => {
      if (requiredDocuments.hasOwnProperty(doc.type)) {
        requiredDocuments[doc.type] = true;
      }
    });

    const hasIdentityDocuments =
      (requiredDocuments.front_document && requiredDocuments.back_document) ||
      (requiredDocuments.cnh_front && requiredDocuments.cnh_back);

    return (
      requiredDocuments.cpf &&
      hasIdentityDocuments &&
      requiredDocuments.proof_residence
    );
  };

  const setDocAlreadySended = useCallback(() => {
    allDocuments.some((doc: any) => {
      if (doc.type === "front_document" || doc.type === "back_document") {
        setDocumentType("rg");
      } else if (doc.type === "cnh_front" || doc.type === "cnh_back") {
        setDocumentType("cnh");
      }
    });
  }, [allDocuments]);

  useEffect(() => {
    setCanAdvanced(allRequiredDocumentsExist(allDocuments));
    setDocAlreadySended();
  }, [allDocuments]);

  useEffect(() => {
    setIsLoading(false);
    setCanAdvanced(false);
    getDocument();
    setStep("attachments");
    window.addEventListener("signupPersonalSubmit", submitForm);
  }, []);

  return (
    <div className="row d-flex flex-column flex-xxl-row  w-100  ">
      <div className="col-12 col-xxl-6 ">
        {" "}
        <div style={{ maxWidth: "714px" }}>
          <Spacer bottom={32} />
          <Title>Agora é hora de comprovar as informações inseridas</Title>
        </div>
        <Spacer bottom={32} />
        <form onSubmit={handleSubmit}>
          <Container
            open={opened}
            className="d-flex flex-column justify-content-between align-items-start "
          >
            <div className="d-flex  w-100 justify-content-between align-items-center ps-1 pe-1 pt-3 pb-3">
              <div className="d-flex align-items-center">
                <Badge
                  label={
                    allRequiredDocumentsExist(allDocuments)
                      ? "Concluído"
                      : "Pendente"
                  }
                  color={
                    allRequiredDocumentsExist(allDocuments)
                      ? "#CCEFDF"
                      : "#FEE7CC"
                  }
                  textColor={
                    allRequiredDocumentsExist(allDocuments)
                      ? "#00B360"
                      : "#FC8802"
                  }
                />
                <Spacer left={10} />{" "}
                <span>
                  Documentos de{" "}
                  {newPartner?.name ?? newPartnerSavedConverted?.name}
                </span>
              </div>
              <ToggleButton type="button" onClick={() => setOpened(!opened)}>
                <ImgX
                  open={opened}
                  src={opened ? CloseAccordionIcon : OpenAccordionIcon}
                />
              </ToggleButton>
            </div>

            <ContainerAttachaments open={opened} className="row">
              <Spacer top={40} />

              <>
                <div className="col-12">
                  <CustomSelect
                    isDisabled={
                      documentExists("front_document") ||
                      documentExists("cnh_front") ||
                      documentExists("back_document") ||
                      documentExists("cnh_back")
                    }
                    label={"Selecione o tipo de documento que deseja enviar"}
                    options={DocumentTypeSelectOptions}
                    onChange={handleSelectDocumentType}
                    value={
                      documentType === "rg"
                        ? DocumentTypeSelectOptions[0]
                        : DocumentTypeSelectOptions[1]
                    }
                  />
                  <Spacer bottom={-32} />
                </div>

                {renderForm(
                  documentType === "rg" ? "front_document" : "cnh_front",
                  documentType === "rg" ? "RG Frente" : "CNH Frente",
                  `Insira a frente ${
                    documentType === "rg" ? "do seu RG" : "da sua CNH"
                  }.`,
                  (data: any) => {
                    sendDocument({
                      file: data[0],
                      type:
                        documentType === "rg" ? "front_document" : "cnh_front",
                      partner: String(
                        newPartner?.id ?? newPartnerSavedConverted.id
                      ),
                      description: "Cadastro",
                    });
                  }
                )}

                {renderForm(
                  documentType === "rg" ? "back_document" : "cnh_back",

                  documentType === "rg" ? "RG Verso" : "CNH Verso",
                  `Insira o verso ${
                    documentType === "rg" ? "do seu RG" : "da sua CNH"
                  }.`,
                  (data: any) => {
                    sendDocument({
                      file: data[0],
                      type:
                        documentType === "rg" ? "back_document" : "cnh_back",

                      partner: String(
                        newPartner?.id ?? newPartnerSavedConverted.id
                      ),
                      description: "Cadastro",
                    });
                  }
                )}

                {renderForm(
                  "cpf",
                  "CPF",
                  "Você pode enviar uma foto da frente da CNH ou do verso do RG, se contiver o número do CPF.",
                  (data: any) => {
                    sendDocument({
                      file: data[0],
                      type: "cpf",
                      partner: String(
                        newPartner?.id ?? newPartnerSavedConverted.id
                      ),
                      description: "Cadastro",
                    });
                  }
                )}

                {renderForm(
                  "proof_residence",
                  "Comprovante de Endereço",
                  "Conta de água, luz, telefone ou gás.",
                  (data: any) => {
                    sendDocument({
                      file: data[0],
                      type: "proof_residence",
                      partner: String(
                        newPartner?.id ?? newPartnerSavedConverted.id
                      ),
                      description: "Cadastro",
                    });
                  }
                )}
              </>

              <Spacer bottom={20} />

              <DraggableModal
                title="Visualização de documento"
                isOpen={showingFile != undefined}
                onClose={() => setShowingFile(undefined)}
              >
                <iframe
                  style={{ width: "100%", height: "150%" }}
                  src={showingFile}
                ></iframe>
              </DraggableModal>
            </ContainerAttachaments>
          </Container>
        </form>
        <InteractiveModal
          size="md"
          toggleModal={() => null}
          open={openSendingDocumentModal}
        >
          <div className="p-4 h-100 d-flex h-100 justify-content-center align-items-center w-100   flex-column">
            <Spacer bottom={120} />
            <div
              className="spinner-border mb-2"
              role="status"
              style={{ color: theme.colors.primary.main }}
            ></div>
            <Spacer top={20} />
            <div className="text-center">Enviando documento...</div>
          </div>
        </InteractiveModal>
        <InteractiveModal
          size="md"
          toggleModal={handleCloseModal}
          open={openDeleteDocumentModal}
        >
          <div className="p-4">
            <TitleModal className="text-center">
              Deseja excluir o documento{" "}
              {labelDocsDict[dataDocumentToDelete[0]?.type]} ?
            </TitleModal>
            <Spacer bottom={150} />
            <Button
              mode="main"
              label="Não, cancelar"
              full
              onClick={handleCloseModal}
            />
            <Spacer bottom={10} />
            <Button
              mode="border"
              label="Sim, excluir"
              full
              onClick={() => deleteDocument(dataDocumentToDelete)}
              loading={loadingDeleteDocument}
            />
          </div>
        </InteractiveModal>
      </div>
      <div className="col-12 col-xxl-6 ">
        <Spacer bottom={32} />
        <Title
          className="text-center"
          style={{ fontSize: "20px", fontWeight: "600" }}
        >
          Como enviar os documentos corretamente
        </Title>
        <Spacer bottom={32} />{" "}
        <p>
          Alguns cuidados são necessários na hora de enviar os seus documentos.
          Por exemplo:
        </p>
        <p>• Deve ser original, não sendo permitidas cópias ou rasuras;</p>
        <p>• Deve estar dentro do prazo de validade;</p>
        <p>
          • Não serão aceitos documentos digitalizados, autenticados, copiados
          ou serigrafados;
        </p>
        <p>
          • Ao fotografar os documentos, você deve prestar atenção à iluminação
          ambiente e à qualidade da imagem (certifique-se de que a documentação
          não esteja cortada ou com pouca nitidez);
        </p>
        <p>• Deve ficar fora da tampa plástica;</p>
        <p>• Deve estar em bom estado: sem danos, rasgos ou amassados;</p>
        <p>
          • No caso de documentos digitais é necessário enviar o arquivo
          extraído do portal;
        </p>
        <p>
          • Documentos digitais, como a versão digital da Carteira Nacional de
          Habilitação, deverão possuir o QR Code para validação;
        </p>
        <Spacer bottom={20} />
        <div className="d-flex">
          <div className="d-flex flex-column">
            {" "}
            <b>1. Envie a frente</b> <Spacer bottom={5} />
            <img src={documentType === "rg" ? RgFrontImage : CNHFrontImage} />
          </div>

          <Spacer left={20} />
          <div className="d-flex flex-column">
            {" "}
            <b>2. Envie o verso</b>
            <Spacer bottom={5} />
            <img src={documentType === "rg" ? RgBackImage : CNHBackImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attachments;
