import { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Menudropdown,
  NotificationsBar,
  Spacer,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import IconOpenNotification from "~/assets/icons/Size=MD, Type=Bell-off.png";

import NotificationOffIcon from "~/assets/icons/icon_notifications_off.png";
import NotificationOnIcon from "~/assets/icons/Notification_menu.png";

import {
  ContainerImage,
  Header,
  NotificationsImg,
  ProductImg,
} from "./AppHeader.styles";
import useAccount from "~/hooks/useAccount";
import ApiService from "~/services/api";
import styled from "styled-components";
import LoaderHeader from "./LoaderHeader";
import useNotifications from "~/hooks/useNotifications";
import { getFirstLetters } from "~/services/utils";
import {
  formatDate,
  formatDateHeader,
  formatDateTime,
  formatCPFCNPJ,
} from "~/utils";
import { useNavigate } from "react-router-dom";
import Description from "~/components/Texts/Description";
import MoreIcon from "~/assets/icons/buttons/more.png";

import HelpImg from "~/assets/icons/buttons/help.svg";
import useToast from "~/hooks/useToast";
import useAuth from "~/hooks/useAuth";
import theme from "~/themes/theme";
import label from "~/labels";

export type AppHeaderProps = {
  isSearching?: boolean;
  open?: boolean;
};

export type AccountsProps = {
  text: string;
  label: string;
};

const ContainerWelcome = styled.div`
  margin-top: 6px;
  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
      cursor: default;
    }
  }
`;

const UserDataContainer = styled.div<AppHeaderProps>`
  width: 260px;

  opacity: ${({ open }) => (open ? "1" : "0")};
  visibility: ${({ open }) => (open ? "default" : "hidden")};

  background-color: ${() => theme.colors.gray.light100};
  position: absolute;
  left: -690%;
  top: 130%;
  border: 1px solid ${() => theme.colors.primary.main};
  border-radius: 5px;
  transition: opacity 0.5s ease;
  /* padding: 15px; */

  .items-header {
    padding: 5px 10px;

    &:hover {
      background-color: ${() => theme.colors.primary.light100};
      cursor: pointer;
    }
  }
`;

const AppHeader = (props: AppHeaderProps) => {
  const { isSearching, open } = props;

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const {
    notifications,
    setNotifications,
    setHasNewNotification,
    hasNewNotification,
    showNotifications,
    setShowNotifications,
  } = useNotifications();

  const { setOpenHelpModal } = useAccount();
  const { showToast } = useToast();

  const { user } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const allowedPaths = ["/app/dashboard", "/app/pix"];

  const { isLoading, mainAccount } = useAccount();

  const handleNotification = (e: any) => {
    e.stopPropagation();

    setShowNotifications(!showNotifications);

    if (notifications) {
      setHasNewNotification(false);
    }
  };

  const notificationsBarRef = useRef<any>(null);
  const notificationsImgRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        notificationsBarRef.current &&
        !notificationsBarRef.current.contains(event.target) &&
        event.target !== notificationsImgRef.current
      ) {
        setShowNotifications(false);
      }
    };

    // Adicione o event listener quando o componente for montado
    document.addEventListener("mousedown", handleClickOutside);

    // Remova o event listener quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationsBarRef]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Verifica se o clique ocorreu fora do elemento
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        // Faça algo quando o clique ocorrer fora do elemento

        setIsOpened(false);

        event.stopPropagation();
      }
      event.stopPropagation();
    };

    // Adiciona um event listener para o clique no documento
    document.addEventListener("click", handleClickOutside);

    // Remove o event listener ao desmontar o componente
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Header
      {...props}
      isSearching={isSearching}
      style={isSearching ? { zIndex: "1" } : {}}
    >
      {isLoading && <LoaderHeader />}

      <div className="h-100 container-fluid content-header">
        <div className="row w-100 ">
          <div className={"d-flex col-8 "}></div>

          <div
            className={
              "col-4 d-flex  justify-content-end align-items-start pt-2 "
            }
          >
            <Description className="pt-2">{formatDateHeader()}</Description>
            <Spacer left={36} />
            {!isLoading && (
              <div className="d-flex align-items-center">
                <NotificationsImg
                  ref={notificationsImgRef}
                  onClick={(e) => handleNotification(e)}
                  src={
                    hasNewNotification
                      ? NotificationOnIcon
                      : NotificationOffIcon
                  }
                />
                <Spacer left={10} />
                <div ref={notificationsBarRef} id="notifications-bar">
                  <NotificationsBar
                    title="Notificações"
                    open={showNotifications}
                    message="Sem notificações"
                    notifications={[]}
                    right={160}
                    top={50}
                    onNotificationClick={(notif: any, index) => {
                      window.open(notif?.param, "_self");

                      setShowNotifications(false);
                    }}
                  />
                </div>
                <Spacer left={10} />

                <ContainerImage>
                  <div
                    className="container-profile-picture"
                    onMouseEnter={() => setIsOpened(true)}
                    ref={containerRef}
                  >
                    <div className="rounded-circle profile-picture">
                      <h1>{getFirstLetters(user?.name ?? "S N")}</h1>
                    </div>
                  </div>

                  <UserDataContainer
                    className="d-flex flex-column"
                    open={isOpened}
                  >
                    <div className="items-header">
                      {user?.name}
                      <Spacer bottom={5} />
                      <Description color={theme.colors.gray.light300}>
                        {formatCPFCNPJ(user?.document_number ?? "")}
                      </Description>
                    </div>
                    <Spacer bottom={10} />
                  </UserDataContainer>
                </ContainerImage>
                <Spacer left={26} />
                <Button
                  mode="phantom"
                  label="Ajuda"
                  labelColor="#808080"
                  iconColorFilter="brightness(0) saturate(100%) invert(52%) sepia(0%) saturate(0%) hue-rotate(230deg) brightness(96%) contrast(90%);"
                  icon={HelpImg}
                  onClick={() => setOpenHelpModal(true)}
                />

                {/* <img className="user-img" src={UserImg} /> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
