import { AppLabel } from "~/types";

const year = new Date().getFullYear();

import logo from "./logo.png";
import logoHorizontal from "./logo-horizontal.png";
import { Fragment } from "react";

const label: AppLabel = {
  name: "Bank engine",
  description: "Banco whitelabel",
  prefix: "prosperita",
  footerText: `Bank engine - Todos os direitos reservados ${year}`,

  logoText: "Bank engine",
  logoMenu: logo,
  logo: logo,
  logoComponent: <Fragment />,
  logoWidth: 130,
  logoAuthWidth: 130,

  logoMenuWidth: "277px",

  backgroundLoginImage: "",

  logoHorizontal: logoHorizontal,
  logoHorizontalComponent: <Fragment />,
  logoHorizontalWidth: 150,
  logoHorizontalMenuWidth: 300,

  clarity: "jy7iuqnjd5",

  helpPhone: "0800 000 0000",
  helpEmail: "",
  helpSupportTime: "",
  helpRamal: "",

  sentryDSN:
    "https://f6065a44aaac4d7f9214338e16438066@sentry.tools.prosperita.io/4",

  devBankCardAPI: "https://api.workspace.prosperita.ibanks.app.br",
  devAPI: "https://v2.api.bank.prosperita.io",
  stageAPI: "https://v2.api.bank.prosperita.io",
  productionAPI: "https://v2.api.bank.prosperita.io",
};

export default label;
