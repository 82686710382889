import constate from "constate";
import { useState } from "react";
import ApiService from "~/services/api";
import useToast from "./useToast";

const [PermissionProvider, usePermission] = constate(() => {
  const { showToast } = useToast();
  const [permissions, setPermissions] = useState<any[]>([]);
  const [rolePermissions, setRolePermissions] = useState<any[]>([]);
  const [list, setList] = useState<any[]>([]);
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const [isEditPermission, setIsEditPermission] = useState<boolean>(false);
  const [submitPermission, setSubmitPermission] = useState<boolean>(false);
  const [submitConcession, setSubmitConcession] = useState<boolean>(false);
  const [currentPermission, setCurrentPermission] = useState<any>();
  const [currentConcession, setCurrentConcession] = useState<any>();
  const [transactionsEquals, setTransactionsEquals] = useState<any[]>([]);
  const [confirmTransactionsEquals, setConfirmTransactionsEquals] =
    useState<boolean>(false);
  const [openNewPermissionModal, setOpenNewPermissionModal] =
    useState<boolean>(false);
  const [openNewConcessionModal, setOpenNewConcessionModal] =
    useState<boolean>(false);
  const [grantedForMeList, setGrantedForMe] = useState<any[]>([]);
  const [grantedForOthersList, setGrantedForOthers] = useState<any[]>([]);

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState(0);

  const getPermissions = async (all_perms: boolean = false) => {
    setLoadingList(true);

    try {
      const params = all_perms ? { permissions: "all" } : { self: true };
      const { data: response } = await ApiService.HttpGet({
        route: "bank-account-user-role-permission/",
        params,
        token: true,
      });

      if (response) {
        if (all_perms) {
          setPermissions(response);
        } else {
          setRolePermissions(response.results);
        }
      }
    } catch (err: any) {
      showToast("Operações não encontradas!", err.response, "error");
    }
    setLoadingList(false);
  };

  const removePermission = async (id: number) => {
    setSubmitPermission(true);

    try {
      await ApiService.HttpDelete({
        route: `bank-account-user-role-permission/${id}/`,
        token: true,
      });

      showToast("Exclusão", "Permissão removida com sucesso", "success");
      getPermissions();
    } catch (err: any) {
      showToast("Exclusão", "Erro ao excluir", "error");
    }
    setSubmitPermission(false);
  };

  const createPermission = async (payload: any) => {
    setSubmitPermission(true);

    try {
      const { data: response } = await ApiService.HttpPost({
        route: `bank-account-user-role-permission/`,
        token: true,
        body: payload,
      });

      if (response) {
        showToast("Criação", "Permissão criada com sucesso", "success");
        getPermissions();
      }
    } catch (err: any) {
      showToast("Criação", "Erro ao criar", "error");
    }
    setSubmitPermission(false);
  };

  const editPermission = async (id: number, payload: any) => {
    setSubmitPermission(true);

    try {
      const { data } = await ApiService.HttpPut({
        route: `bank-account-user-role-permission/${id}/`,
        token: true,
        body: payload,
      });
      return data;
    } catch (err: any) {
      showToast("Edição", "Erro ao editar", "error");
    }
    setSubmitPermission(false);
  };

  const getConcessions = async (_grantedForMe: boolean = true) => {
    setLoadingList(true);

    try {
      const { data: response } = await ApiService.HttpGet({
        route: "bank-account-user/",
        params: {
          granted_permissions_only: _grantedForMe ? "me" : "others",
        },
        token: true,
      });

      if (response) {
        if (_grantedForMe) {
          setGrantedForMe(response.results);
        } else {
          setGrantedForOthers(response.results);
        }
      }
    } catch (err: any) {
      showToast("Contas", "não encontradas!", "error");
    }
    setLoadingList(false);
  };

  const removeConcession = async (id: number) => {
    setSubmitConcession(true);

    try {
      await ApiService.HttpDelete({
        route: `bank-account-user/${id}/`,
        token: true,
      });

      showToast("Exclusão", "Concessão removida com sucesso", "success");
      getConcessions(false);
    } catch (err: any) {
      showToast("Exclusão", "Erro ao excluir", "error");
    }
    setSubmitConcession(false);
  };

  const createConcession = async (payload: any) => {
    setSubmitConcession(true);

    try {
      const { data: response } = await ApiService.HttpPost({
        route: `bank-account-user/`,
        token: true,
        body: payload,
      });

      if (response) {
        showToast("Criação", "Concessão criada com sucesso", "success");
        getConcessions(false);
        setOpenNewConcessionModal(false);
      }
    } catch (err: any) {}
    setSubmitConcession(false);
  };

  const partnerCheck = async (document_number: string) => {
    try {
      const { data: response } = await ApiService.HttpPost({
        route: "partner-check/",
        token: true,
        body: { document_number },
      });

      return response;
    } catch (err: any) {
      return undefined;
    }
  };

  return {
    getPermissions,
    getConcessions,
    createPermission,
    removePermission,
    createConcession,
    removeConcession,
    editPermission,

    isEditPermission,

    permissions,
    rolePermissions,
    grantedForMeList,
    grantedForOthersList,
    loadingList,

    openNewPermissionModal,
    setOpenNewPermissionModal,
    openNewConcessionModal,
    setOpenNewConcessionModal,

    setIsEditPermission,
    submitPermission,
    setSubmitPermission,
    submitConcession,
    setSubmitConcession,

    currentPermission,
    setCurrentPermission,

    currentConcession,
    setCurrentConcession,
    partnerCheck,

    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    list,
    setList,
    setTransactionsEquals,
    transactionsEquals,
    setConfirmTransactionsEquals,
    confirmTransactionsEquals,
  };
});

export { PermissionProvider };

export default usePermission;
