import {
  Button,
  LateralModal,
  ProgressBar,
  RadioGroup,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { Fragment, useEffect } from "react";
import useCollections from "~/hooks/Collections/useCollections";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import ContactData from "./ContactData";
import CollectionData from "./CollectionOrRecurrenceData";
import Confimation from "./Confimation";
import { motion } from "framer-motion";
import ContactsList from "./ContactsList";
import LoaderReceiptGenerateBillet from "./LoadingReceipt";
import ReceiptCollection from "./ReceiptCollection";
import useAddBalance from "~/hooks/useAddBalance";
import useAccount from "~/hooks/useAccount";

type RenderPercentType = {
  [key: string]: number;
};

const NewCollectionSelect = () => {
  const {
    openNewCollectionOrRecurrenceModal,
    openNewCollectionOrRecurrenceModalInDash,
  } = useCollections();
  const { isEmpty, setIsEmpty } = useAddBalance();

  const {
    chooseNewCollection,
    setChooseNewCollection,

    setStep,
    selectContact,
    stepIndex,
    isRecurrence,
    handleToggleModal,
    selectedValue,
    isLoadingReceipt,
    receiptRender,
    setSelectedValue,
    setSelectContact,
    setHasSplit,
  } = useNewCollection();

  const RenderNewCollection: React.ReactNode[] = [
    selectContact ? <ContactsList /> : <ContactData />,
    <CollectionData />,
    isLoadingReceipt ? (
      <LoaderReceiptGenerateBillet />
    ) : receiptRender ? (
      <ReceiptCollection />
    ) : (
      <Confimation />
    ),
  ];

  const newCollectionsTitlesModal = [
    selectContact ? "Selecionar contato" : "Nova cobrança",
    "Nova cobrança",
    isLoadingReceipt
      ? "Gerando boleto"
      : receiptRender
      ? "Cobrança gerada"
      : "Confirmação de cobrança",
  ];

  function handleChange(value: any) {
    setSelectedValue(value);

    if (value === "0") {
      setSelectedValue("billet");
      setIsEmpty(false);
    }

    if (value === "1") {
      setSelectedValue("payment_link");
      setIsEmpty(false);
    }
  }

  const { verifyPermission } = useAccount();

  const canCreateBillet = verifyPermission("ba_can_create_billet");
  // const canCreatePaymentLink = verifyPermission("ba_can_create_payment_link");

  const radioOptions = [
    {
      title: "Boleto simples",
      description: "Emitir uma cobrança através do boleto simples",
      value: "0",
      disabled: !canCreateBillet,
    },
    // {
    //   title: "Link de pagamento",
    //   description:
    //     "Transferência a qualquer hora e dia da semana, de forma instantânea.",
    //   value: "1",
    //   disabled: !canCreatePaymentLink,
    // },
  ];

  const progressBarPercentMap: RenderPercentType = {
    contact: 25,
    0: 25,
    1: 65,
    2: 100,
  };

  const calculateProgressBarPercent = (
    stepIndex: number,
    selectContact: boolean
  ) => {
    return progressBarPercentMap[selectContact ? "contact" : stepIndex];
  };

  useEffect(() => {
    if (
      !openNewCollectionOrRecurrenceModal ||
      !openNewCollectionOrRecurrenceModalInDash
    ) {
      setIsEmpty && setIsEmpty(true);
      setHasSplit(false);
    }
  }, [
    openNewCollectionOrRecurrenceModal,
    openNewCollectionOrRecurrenceModalInDash,
  ]);

  return (
    <LateralModal
      open={
        openNewCollectionOrRecurrenceModal ||
        openNewCollectionOrRecurrenceModalInDash
      }
      title={
        chooseNewCollection === "none"
          ? "Tipo de cobrança"
          : newCollectionsTitlesModal[stepIndex]
      }
      toggleModal={handleToggleModal}
      style={{ zIndex: "3" }}
      header
    >
      <Fragment>
        <ProgressBar
          percent={calculateProgressBarPercent(stepIndex, selectContact)}
        />
        {chooseNewCollection === "none" && (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3 }}
          >
            {" "}
            <div className=" w-100  d-flex flex-column justify-content-start p-4">
              <RadioGroup
                emptyValue={isEmpty}
                display="row"
                options={radioOptions}
                wrap
                onChange={(value) => handleChange(value)}
              />
              <Spacer bottom={20} />
              <div className="d-flex w-100 justify-content-between">
                <Button
                  mode="border"
                  label={
                    isRecurrence ? "Cancelar recorrência" : "Cancelar cobrança"
                  }
                  onClick={handleToggleModal}
                />
                <Button
                  mode="main"
                  label="Avançar"
                  onClick={() => {
                    if (selectedValue === "billet") {
                      setSelectContact(true);
                      setStep("data");
                      setChooseNewCollection("billet");
                    }

                    if (selectedValue === "payment_link") {
                      setStep("data");
                      setChooseNewCollection("payment_link");
                    }
                  }}
                  disabled={selectedValue === ""}
                />
              </div>
            </div>
          </motion.div>
        )}

        {chooseNewCollection === "billet" && RenderNewCollection[stepIndex]}
        {chooseNewCollection === "payment_link" &&
          RenderNewCollection[stepIndex]}
      </Fragment>
    </LateralModal>
  );
};

export default NewCollectionSelect;
