import constate from "constate";
import { useState } from "react";
import useCollections from "./useCollections";
import ApiService from "~/services/api";
import { getFirstAndLastDayOfMonth } from "~/utils";
import useToast from "~/hooks/useToast";
import { format, subDays } from "date-fns";
import useAccount from "../useAccount";

const BILLET_TAX: number = 0;

export type ChooseCollectionTypeProps = "none" | "billet" | "payment_link";

const steps: string[] = ["data", "collection", "confirm"];

const newCollectionSteps = {
  none: [],
  billet: steps,
  payment_link: steps,
};

const [NewCollectionProvider, useNewCollection] = constate(() => {
  const { showToast } = useToast();
  const { setBilletsData } = useCollections();
  const { beforeDays } = useAccount();

  const [openModalSecurityValidation, setOpenModalSecurityValidation] =
    useState(false);

  const [isRecurrence, setIsRecurrence] = useState<boolean>(false);

  const [taxCreateDynamicQrCode, setTaxCreateDynamicQrCode] =
    useState<number>(0);
  const [taxLiquidateDynamicQrCode, setTaxLiquidateDynamicQrCode] =
    useState<number>(0);
  const [taxCreateBillet, setTaxCreateBillet] = useState<number>(0);
  const [taxLiquidateBillet, setTaxLiquidateBillet] = useState<number>(0);

  const [chooseNewCollection, setChooseNewCollection] =
    useState<ChooseCollectionTypeProps>("none");
  const [isInstallments, setIsInstallments] = useState<boolean>(false);
  const [overduePayment, setOverduePayment] = useState<boolean>(false);
  const [earlyPayment, setEarlyPayment] = useState<boolean>(false);
  const [hasSplit, setHasSplit] = useState<boolean>(false);

  const [moreInformations, setMoreInformations] = useState<boolean>(false);

  const [newCollectionData, setNewCollectionData] = useState<any>({});

  const [selectedValue, setSelectedValue] = useState<string>("");

  const [splitName, setSplitName] = useState<string>("");

  const [stepIndex, setStepIndex] = useState<number>(0);

  const [step, setStep] = useState<string>(
    newCollectionSteps[chooseNewCollection][stepIndex]
  );

  const [selectContact, setSelectContact] = useState<boolean>(false);

  const [contactInfo, setContactInfo] = useState<any>({});

  const [splitsDataToSelect, setSplitsDataToSelect] = useState<any>([]);

  const handleNextStep = () => {
    if (stepIndex < newCollectionSteps[chooseNewCollection].length - 1) {
      setStepIndex(stepIndex + 1);
      setStep(newCollectionSteps[chooseNewCollection][stepIndex + 1]);
    }
  };

  const handlePreviousStep = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
      setStep(newCollectionSteps[chooseNewCollection][stepIndex - 1]);
    }
  };

  const {
    setOpenNewCollectionOrRecurrenceModal,
    openNewCollectionOrRecurrenceModal,

    setOpenNewCollectionOrRecurrenceModalInDash,
    setLoadingList,
  } = useCollections();

  const [openValidatorModalNewCollection, setOpenValidatorModalNewCollection] =
    useState<boolean>(false);

  const handleToggleModal = () => {
    setIsRecurrence(false);
    setSelectContact(false);
    setIsInstallments(false);
    setEarlyPayment(false);
    setOverduePayment(false);
    setOpenModalSecurityValidation(false);
    setReceiptRender(false);
    setNewCollectionData({});
    setReceiptData({});
    setChooseNewCollection("none");
    setSelectedValue("");
    setStepIndex(0);
    setOpenNewCollectionOrRecurrenceModal(false);
    setOpenNewCollectionOrRecurrenceModalInDash(false);
  };

  const { setSelectedIds, setStatusColumnSelected } = useCollections();

  const [isLoadingReceipt, setIsLoadingReceipt] = useState<boolean>(false);
  const [receiptRender, setReceiptRender] = useState<boolean>(false);
  const [receiptData, setReceiptData] = useState<any>([]);

  const [limitBillets, setLimitBillets] = useState<number>(20);

  const getBillets = async (fromDate: string, toDate: string, params?: any) => {
    setBilletsData([]);
    setLoadingList(true);
    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/billet/",
        token: true,
        params: {
          from_date: fromDate,
          to_date: toDate,
          limit: 9999999,
        },
      });

      setBilletsData(data);
      setLoadingList(false);
      setSelectedIds([]);
      setStatusColumnSelected(false);
    } catch {
      setLoadingList(false);
    }
  };

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  const generateBillet = async () => {
    setOpenValidatorModalNewCollection(false);
    setIsLoadingReceipt(true);

    try {
      const { data: response } = await ApiService.HttpPost({
        route: "charge/billet/",
        token: true,
        body: newCollectionData,
      });

      if (!response.error) {
        const { data } = response;
        setReceiptData(data);
        setIsLoadingReceipt(false);
        setReceiptRender(true);
        getBillets(fromDate, todayFormatted);
        showToast("Aviso", response.message, "success");
      } else {
        showToast("Alerta", response.message, "error");
      }
    } catch (error: any) {
      if (error.response.data.message === "Verification code error") {
        showToast("Token Invalido", "Erro ao gerar boleto", "error");
        setIsLoadingReceipt(false);
        console.warn(error);
      } else {
        console.warn(error);
        showToast("Alerta", "Erro ao gerar boleto", "error");
        setIsLoadingReceipt(false);
      }
    }
  };

  return {
    stepIndex,
    setStepIndex,
    splitName,
    setSplitName,
    step,
    setStep,
    chooseNewCollection,
    setChooseNewCollection,
    handleNextStep,
    handlePreviousStep,
    selectContact,
    setSelectContact,
    newCollectionData,
    setNewCollectionData,
    isInstallments,
    setIsInstallments,
    overduePayment,
    setOverduePayment,
    earlyPayment,
    setEarlyPayment,
    BILLET_TAX,
    openModalSecurityValidation,
    setOpenModalSecurityValidation,
    handleToggleModal,
    isRecurrence,
    setIsRecurrence,
    selectedValue,
    setSelectedValue,
    contactInfo,
    setContactInfo,
    generateBillet,
    openNewCollectionOrRecurrenceModal,
    openValidatorModalNewCollection,
    setOpenValidatorModalNewCollection,
    isLoadingReceipt,
    setIsLoadingReceipt,
    receiptRender,
    setReceiptRender,
    receiptData,
    setReceiptData,
    limitBillets,
    setLimitBillets,
    getBillets,
    hasSplit,
    setHasSplit,
    splitsDataToSelect,
    setSplitsDataToSelect,
    taxCreateBillet,
    setTaxCreateBillet,
    taxLiquidateBillet,
    setTaxLiquidateBillet,
    taxCreateDynamicQrCode,
    setTaxCreateDynamicQrCode,
    taxLiquidateDynamicQrCode,
    setTaxLiquidateDynamicQrCode,
    moreInformations,
    setMoreInformations,
    setBilletsData,
    setLoadingList,
  };
});

export { NewCollectionProvider };

export default useNewCollection;
