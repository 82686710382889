import { Fragment, useEffect } from "react";
import Title from "~/components/Texts/Title";
import { ActivateCardCheckingButtonsDiv, ActivateCardCheckingImg, ActivateCardCheckingStepDiv, ActivateCardTitle, ActivateCardContainer, CardsDashboardHeader, ActivateCardReceivedWrapperDiv, ActivateCardInputWrapper } from "../styled";
import useAccount from "~/hooks/useAccount";
import useCards from "~/hooks/Cards/useCards";
import { Button, Input } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import CardImg from '~/assets/images/credit--card.svg'
import { BlockCardSteps } from "~/hooks/Cards/interfaces";

interface IBlockCardCheckingStep {
  setBlockCardStep: React.Dispatch<React.SetStateAction<BlockCardSteps>>;
  blockCardStep: BlockCardSteps;
}
const BlockCardCheckingStep = (props: IBlockCardCheckingStep) => {
  const navigate = useNavigate()

  return (
    <ActivateCardCheckingStepDiv>
      <ActivateCardCheckingImg src={CardImg}/>
      <ActivateCardTitle>
        Você deseja bloquear seu cartão?
      </ActivateCardTitle>
      <ActivateCardCheckingButtonsDiv>
        <Button 
          label="Sim, desejo bloquear" 
          mode='main' 
          onClick={() => {props.setBlockCardStep('CONFIRMATION')}}
        />
        <Button 
          label="Não, voltar para tela anterior" 
          mode='border'
          style={{borderColor: '#29183A', color: '#29183A'}}
          onClick={() => {navigate('/app/cards')}}
        />
      </ActivateCardCheckingButtonsDiv>
    </ActivateCardCheckingStepDiv>
  );
};

export default BlockCardCheckingStep;
