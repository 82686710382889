import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  Spacer,
  ButtonDropdown,
} from "@zilliondigital/mirage-ui";
import { useEffect, useState, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import {
  ContainerData,
  GeneralContainer,
} from "~/pages/App/app-content.styles";

import useAuthority from "~/hooks/useAuthority";
import GrantPermission from "./GrantPermission";
import AddApproverData from "./AddApproverData";
import Paginator from "~/components/Paginator";
import Copyright from "~/components/Copyright";
import { quantityPages } from "~/types";
import RepprovedData from "./RepprovedData";

const AuthoritysStructure = () => {
  const titlePage = ["Alçadas", "Escolha uma conta aprovadora"];
  const renderData = [
    <GrantPermission />,
    <AddApproverData />,
    <RepprovedData />,
  ];

  const menusBreadCrumb = [
    { label: "Permissões", onClick: () => navigate("/app/permissions") },
    { label: "Alçadas", href: "app/payments" },
  ];

  const {
    getApprovers,
    approvers,
    getAccounts,
    accounts,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
  } = useAuthority();

  const navigate = useNavigate();
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [list, setList] = useState<any[]>([]);

  const quantityPages: quantityPages[] = [
    {
      label: "20 por página",
      value: "1",
      onClick: () => {
        setRowsPerPage(20);
      },
    },
    {
      label: "50 por página",
      value: "2",
      onClick: () => {
        setRowsPerPage(50);
      },
    },
    {
      label: "100 por página",
      value: "3",
      onClick: () => {
        setRowsPerPage(100);
      },
    },
  ];

  const onChangeIndex = useCallback(async () => {
    if (stepIndex === 0) {
      await getApprovers();
      setList(approvers);
    }

    if (stepIndex === 1) {
      await getAccounts();
      setList(accounts);
    }
  }, [stepIndex, approvers, accounts]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    setPage(0);
    onChangeIndex();
  }, [stepIndex]);

  return (
    <GeneralContainer>
      <div className="d-flex flex-column container w-100">
        <Spacer top={30} />
        <Breadcrumb
          menus={menusBreadCrumb}
          onClickHome={() => navigate("/app/dashboard")}
        />

        <Spacer top={30} />
        <ContainerData className="">
          <div className="d-flex justify-content-between p-4">
            <Title>{titlePage[stepIndex]}</Title>

            <div>
              <div className="d-flex flex-column flex-lg-row">
                {stepIndex === 0 && (
                  <Button
                    mode="main"
                    label="Criar alçada"
                    onClick={() => {
                      setStepIndex(1);
                    }}
                  />
                )}

                {stepIndex === 1 && (
                  <Button
                    mode="main"
                    label="Voltar"
                    onClick={() => {
                      setStepIndex(0);
                    }}
                  />
                )}

                <Spacer right={10} />
                <div className="d-block d-lg-none">
                  <Spacer bottom={10} />
                </div>
              </div>
            </div>
          </div>

          <div className="pe-4 ps-4">
            <div>
              {list.length > 0 ? (
                <ButtonDropdown
                  mode="border"
                  label={`${rowsPerPage} por página`}
                  options={quantityPages}
                />
              ) : null}
            </div>
            <Spacer bottom={20} />
            <div>{renderData[stepIndex]}</div>
          </div>

          <Spacer bottom={20} />

          <Spacer top={30} />
        </ContainerData>

        <div className={list.length > 0 ? "" : "d-none"}>
          <Paginator
            list={list.length > 0 ? list : []}
            rowsPerPage={rowsPerPage}
            page={page}
            pageSetter={setPage}
          />

          <Spacer top={list.length > 0 ? 2 : 40} />
        </div>

        <Spacer top={40} />

        <Copyright />

        <Spacer top={30} />
      </div>
    </GeneralContainer>
  );
};

export default AuthoritysStructure;
