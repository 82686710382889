import {
  Button,
  CustomSelect,
  Input,
  InputCurrency,
  LateralModal,
  NotControlledToolTip,
  ProgressBar,
  Radio,
  RadioGroup,
  Spacer,
  Table,
  Toggle,
} from "@zilliondigital/mirage-ui";
import SucessIcon from "~/assets/Checkmark--filled.svg";
import DeleteIcon from "~/assets/icons/delete.png";
import EditIcon from "~/assets/icons/round_edit_black_24dp.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import SearchIcon from "~/assets/Search.svg";
import styled from "styled-components";
import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";
import useTariffs from "~/hooks/useTariffs";
import ApiService from "~/services/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import useToast from "~/hooks/useToast";
import Paginator, { applyPagination } from "~/components/Paginator";
import Description from "~/components/Texts/Description";

import AddIcon from "~/assets/Checkmark--filled.svg";
import DoNotAddIcon from "~/assets/IconX.png";

import {
  convertStringToNumber,
  formatCPFCNPJ,
  formatCash,
  formatNumberToCurrencyWithoutSimbol,
  onlyNumbers,
} from "~/utils";

import { getFirstLetters } from "~/services/utils";
import Title from "~/components/Texts/Title";
import label from "~/labels";
import theme from "~/themes/theme";
import {
  ContainerData,
  ImgEye,
  ImgSuccess,
  scaleAnimation,
} from "../../../app-content.styles";
import { useNavigate } from "react-router-dom";
import TableLoader from "~/components/SkeletonTables";
import documentNumberValidator from "~/utils/validateDocNumber";
import { ImgDelete } from "~/pages/App/Permissions/NewConcessionModal";

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .rounded-circle {
    width: 40px;
  }

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }
`;

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }
`;

type NewPackpageName = {
  name: string;
  search: string;
  document_number: string;
};
const initialValues: NewPackpageName = {
  name: "",
  search: "",
  document_number: "",
};

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  search: Yup.string(),
});

const DataNewPackpage = () => {
  const {
    setTariffsData,
    tariffsData,
    dataCreatePackpageTariffs,
    setDataCreatePackpageTariffs,
    setPackpagesTariffs,
    packpagesTariffs,
  } = useTariffs();
  const [packageId, setPackageId] = useState(null as null | number);
  const [error, setError] = useState<string>("");

  const [editingRowId, setEditingRowId] = useState<number | null>(null);

  const [valueSelected, setValueSelected] = useState<string>("pf");

  const handleSubmit: any = (values: NewPackpageName, formikHelpers: any) => {};

  const { values, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    onSubmit: (values, actions) => {
      console.log(values);
      actions.setSubmitting(false);
    },
  });

  const valuesTariffs = useMemo(() => {
    return dataCreatePackpageTariffs?.map((el) => el.customer_tax_out);
  }, [dataCreatePackpageTariffs]);

  const indexedTariffs = useMemo(() => {
    return tariffsData?.map((tariff: any, index: number) => ({
      index,
      ...tariff,
    }));
  }, [tariffsData]);

  const handleEditChange = (id: number, value: any, event: any = null) => {
    setDataCreatePackpageTariffs((currentData) =>
      currentData.map((item: any) =>
        item.id === id
          ? { ...item, customer_tax_out: convertStringToNumber(value) }
          : item
      )
    );

    if (event && event.key === "Enter") {
      setEditingRowId(null);
    }
  };

  const columnsTariffs = [
    {
      label: "Nome",
      key: "name",

      renderData: (data: any) => {
        return (
          <ContainerRenderData>
            <div className="d-flex align-items-center">
              <Spacer left={10} />
              <div className="d-flex flex-column">
                <Title>{data.name}</Title>
                <Description color="#929292">
                  {formatCPFCNPJ(data.document_number)}
                </Description>
              </div>
            </div>
          </ContainerRenderData>
        );
      },
    },
    {
      label: "Valor base (Sua taxa)",
      key: "baas_tax",

      renderData: (data: any) => {
        return <>{formatCash(data.baas_tax)} </>;
      },
    },
    {
      label: "Valor do cliente",
      key: "customer_tax_out",
      renderData: (data: any) => {
        const isEditing = editingRowId === data.id;
        const currentValue = formatNumberToCurrencyWithoutSimbol(
          valuesTariffs[data.index]
        );

        return isEditing ? (
          <div style={{ maxWidth: "160px" }} className="d-flex">
            <InputCurrency
              inputSize="normal"
              name={`tariff-value-${data.id}`}
              value={currentValue}
              maxLength={6}
              onChange={(e) => handleEditChange(data.id, e.target.value)}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  handleEditChange(data.id, e.target.value, e);
                } else if (
                  !e.key.match(/^[0-9]$/) &&
                  e.key !== "Backspace" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight" &&
                  e.key !== "." &&
                  e.key !== "Delete"
                ) {
                  e.preventDefault();
                }
              }}
            />
            {/* <Spacer left={20} />
            <IconOk
              style={{ position: "relative" }}
              onClick={(e: any) => {
                handleEditChange(data.id, e.target.value, e);
              }}
            >
              ✓
            </IconOk> */}
            <Spacer left={20} />
            <ImgSuccess
              id={`success-${data.id}`}
              style={{ position: "relative", top: "20px" }}
              src={SucessIcon}
              onClick={() => {
                const inputElement = document.querySelector(
                  `input[name="tariff-value-${data.id}"]`
                ) as HTMLInputElement;
                if (inputElement) {
                  const inputValue = inputElement.value;
                  handleEditChange(data.id, inputValue, null);
                  setEditingRowId(null);
                }
              }}
            />
            <NotControlledToolTip placement="top" target={`success-${data.id}`}>
              Confirmar
            </NotControlledToolTip>
          </div>
        ) : (
          <div className="d-flex justify-content-around ps-2">
            <p>
              R${" "}
              {formatNumberToCurrencyWithoutSimbol(
                valuesTariffs[data.index] ?? 0
              )}
            </p>

            <ImgEye
              style={{ position: "relative" }}
              src={EditIcon}
              onClick={() => setEditingRowId(data.id)}
            />
          </div>
        );
      },
    },
    {
      label: "Lucro",
      key: "profit",

      renderData: (data: any) => {
        return (
          <>{formatCash(Number(valuesTariffs[data.index]) - data.baas_tax)}</>
        );
      },
    },
  ];

  const handleSelectPackage = (e: any) => {
    const value = Number(e.target.value);

    setPackageId(value);
  };

  const listPackpages = useMemo(() => {
    return packpagesTariffs?.results
      ?.filter((tariffs: any) => tariffs.type === valueSelected)
      .map((packpage: any, index: number) => ({
        label: `${packpage.name}`,
        value: packpage.id,
      }));
  }, [packpagesTariffs, valueSelected]);

  const isValidDocument = useMemo(
    () =>
      values.document_number.length >= 14 &&
      documentNumberValidator(values.document_number),
    [values.document_number]
  );

  useEffect(() => {
    if (values.document_number.length < 14 && !isValidDocument) {
      setError("initialState");
    } else if (values.document_number.length >= 14 && isValidDocument) {
      setError("notError");
    } else if (values.document_number.length >= 14 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document_number]);

  const getTariffs = async (type: string) => {
    setIsLoading(true);

    setTariffsData([]);
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/",
        token: true,
      });

      const dataFiltered = data.results
        ?.filter(
          (packpage: any) => packpage.default && packpage.type === type
        )[0]
        ?.taxes?.map((item: any) => item);

      setTariffsData(dataFiltered);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getClients();
    getTariffsPackpage();
    setRowsPerPage(8);
  }, []);

  useEffect(() => {
    if (tariffsData?.length > 0) {
      const tariffsIdsAndValues = tariffsData.map((tariff: any) => ({
        id: tariff.id,
        customer_tax_out: tariff.customer_tax_out,
      }));

      setDataCreatePackpageTariffs(tariffsIdsAndValues);
    }
  }, [tariffsData]);

  useEffect(() => {
    if (valueSelected === "pf") {
      getTariffs("pf");
    } else {
      getTariffs("pj");
    }
    setIsMigrateClients(false);
    setClientsInTable([]);
  }, [valueSelected]);

  const {
    clientsData,
    page,
    rowsPerPage,
    setPage,
    setPageClients,
    setRowsPerPage,
    setClientsData,
  } = useTariffs();
  const { showToast } = useToast();
  const [holdersSelectedsIndex, setHoldersSelectedsIndex] = useState<number[]>(
    []
  );
  const [clientData, setClientData] = useState<any[]>([]);
  const [isMigrateClients, setIsMigrateClients] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [selectedRows, setSelectedRow] = useState<any[]>([]);
  const [clientsFiltered, setClientsFiltered] = useState<any>([]);
  const [clientsInTable, setClientsInTable] = useState<any>([]);

  const [namePackpage, setNamePackpage] = useState<string>();

  const navigate = useNavigate();

  const columnsHolders: any = [
    {
      label: "",
      key: "name",

      renderData: (data: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "start" }}>
            <ContainerImage>
              <div className="container-profile-picture">
                <div className="rounded-circle profile-picture">
                  <h1>{getFirstLetters(data.name)}</h1>
                </div>
              </div>
            </ContainerImage>

            <div className="d-flex flex-column">
              <Title>{data.name}</Title>

              <Description color="#929292">
                {formatCPFCNPJ(data.document_number)}
              </Description>
            </div>
          </div>
        );
      },
    },
    {
      name: "",
      key: "",
      renderData: (data: any) => (
        <div>
          <ImgDelete
            id={`delete-${data.id}`}
            src={DeleteIcon}
            onClick={() => removeClientById(data.id)}
          />
          <NotControlledToolTip target={`delete-${data.id}`}>
            Remover Correntista
          </NotControlledToolTip>
        </div>
      ),
    },
  ];

  // const columnsHoldersOld = [
  //   {
  //     label: "Nome",
  //     key: "name",

  //     renderData: (data: any) => {
  //       return (
  //         <ContainerRenderData onClick={() => handleClickClient(data)}>
  //           <div className="d-flex align-items-center">
  //             {isContactSelected(data.id) ? (
  //               <ContainerImage>
  //                 <div className="contact-selected-image">
  //                   <img
  //                     className="selected-contact-image"
  //                     src={SelectedContactIcon}
  //                   />
  //                 </div>
  //               </ContainerImage>
  //             ) : (
  //               <ContainerImage>
  //                 <div className="container-profile-picture">
  //                   <div className="rounded-circle profile-picture">
  //                     <h1>{getFirstLetters(data.name)}</h1>
  //                   </div>
  //                 </div>
  //               </ContainerImage>
  //             )}
  //             <Spacer left={10} />
  //             <div className="d-flex flex-column">
  //               <Title>{data.name}</Title>

  //               <Description color="#929292">
  //                 {formatCPFCNPJ(data.document_number)}
  //               </Description>
  //             </div>
  //           </div>
  //         </ContainerRenderData>
  //       );
  //     },
  //   },
  // ];

  const dataClients = useMemo(() => {
    return clientsData?.map(
      (contact: { id: number; name: string; document_number: string }) => ({
        id: contact.id,
        name: contact.name,
        document_number: contact.document_number,
      })
    );
  }, [clientsData]);

  const filteredContacts = useMemo(() => {
    const removeNonNumericChars = (input: string) => input.replace(/\D/g, "");
    const searchQuery = values.search.toLowerCase();
    const isNumericSearch = !!removeNonNumericChars(searchQuery);

    return dataClients?.filter(
      (client: { id: number; name: string; document_number: string }) => {
        if (isNumericSearch) {
          return client.document_number
            ?.toLowerCase()
            .includes(onlyNumbers(searchQuery));
        } else {
          return client.name.toLowerCase()?.includes(searchQuery);
        }
      }
    );
  }, [values.search, dataClients]);

  const getTariffsPackpage = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/",
        token: true,
      });

      setPackpagesTariffs(data);
    } catch (error) {
      console.error("Erro ao buscar cestas de tarifas:", error);
    }
  }, [setPackpagesTariffs]);

  const getClients = useCallback(
    async (type?: string) => {
      const typeDocument = type === "pf" ? "cpf" : "cnpj";

      try {
        const { data } = await ApiService.HttpGet({
          route: "backoffice/approved-partners/",
          token: true,
        });

        const filteredData = data.filter(
          (partner: any) => partner.document_type === typeDocument
        );

        setClientsData(filteredData);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
      }
    },
    [ApiService.HttpGet, setClientsData]
  );

  const fetchCreateNewPackpageTariffs = async () => {
    setIsLoading(true);
    try {
      if (isMigrateClients && packageId !== null) {
        const { data } = await ApiService.HttpPost({
          route: "backoffice/tariffs-package/",
          body: {
            name: namePackpage,
            tariffs: dataCreatePackpageTariffs,
            migrate: packageId,
            type: valueSelected,
          },
          token: true,
        });

        if (holdersSelectedsIndex.length > 0) {
          const AddHoldersData = await ApiService.HttpPost({
            route: `backoffice/tariffs-package/${data.data.id}/add-holders/`,
            body: {
              holders: holdersSelectedsIndex,
            },
            token: true,
          });

          showToast("Cesta criada com sucesso!", "", "success");
          navigate("/app/myspace/tariffs");

          setIsLoading(false);
        } else {
          showToast("Cesta criada com sucesso!", "", "success");
          navigate("/app/myspace/tariffs");

          setIsLoading(false);
        }
      } else {
        const { data } = await ApiService.HttpPost({
          route: "backoffice/tariffs-package/",
          body: {
            name: values.name,
            tariffs: dataCreatePackpageTariffs,
            type: valueSelected,
          },
          token: true,
        });

        if (holdersSelectedsIndex.length > 0) {
          const AddHoldersData = await ApiService.HttpPost({
            route: `backoffice/tariffs-package/${data.data.id}/add-holders/`,
            body: {
              holders: holdersSelectedsIndex,
            },
            token: true,
          });
          showToast("Cesta criada com sucesso!", "", "success");
          navigate("/app/myspace/tariffs");

          setIsLoading(false);
        } else {
          showToast("Cesta criada com sucesso!", "", "success");
          navigate("/app/myspace/tariffs");

          setIsLoading(false);
        }
      }
    } catch (e: any) {
      showToast(e.message, "", "error");

      setIsLoading(false);
    }
  };

  const SelectContact = (id: number) => {
    setHoldersSelectedsIndex((prevSelected) => {
      const index = prevSelected.indexOf(id);
      if (index > -1) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const isContactSelected = useCallback(
    (id: number) => {
      return holdersSelectedsIndex.includes(id);
    },
    [holdersSelectedsIndex]
  );

  const handleClickClient = useCallback(
    (data: any) => {
      SelectContact(data.id);
    },
    [SelectContact]
  );

  const addClient = (newClient: any) => {
    setClientsInTable((currentClients: any) => {
      const exists = currentClients.some(
        (client: any) => client.id === newClient.id
      );

      if (exists) {
        showToast(
          "Este cliente já faz parte da lista",
          "Não é possível adiciona-lo novamente",
          "warning",
          () => null,
          true,
          10000
        );
        return currentClients;
      } else {
        return [...currentClients, newClient];
      }
    });

    setHoldersSelectedsIndex((oldIds) => {
      const exists = oldIds.includes(newClient.id);
      if (!exists) {
        return [...oldIds, newClient.id];
      } else {
        return oldIds;
      }
    });

    setClientData([]);
  };
  const removeClientById = (clientId: number) => {
    setClientsInTable((currentClients: any) =>
      currentClients.filter((client: any) => client.id !== clientId)
    );
    setHoldersSelectedsIndex((oldIds) =>
      oldIds.filter((id) => id !== clientId)
    );
  };

  const fetchSearchClients = useCallback(async () => {
    setIsLoadingSearch(true);
    setClientData([]);

    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/approved-partners/",
        params: {
          document_number: onlyNumbers(values.document_number),
        },
        token: true,
      });
      resetForm({
        values: {
          ...initialValues,
          name: values.name, // Preserve the 'name' field
        },
      });

      if (data.length === 0) {
        showToast(
          "Correntista não encontrado",
          "Verifique o documento digitado e tente novamente",
          "error"
        );
        setIsLoadingSearch;
      } else {
        showToast("Correntista encontrado com sucesso", "", "success");
        setClientData(data);
      }
      setIsLoadingSearch(false);
    } catch (error) {
      setIsLoadingSearch(false);
      console.log("Erro ao buscar clientes:", error);
    }
  }, [values.document_number]);

  useEffect(() => {
    if (valueSelected !== "") {
      getClients(valueSelected);
      setClientData([]);
    }
  }, [valueSelected]);

  useEffect(() => {
    setClientsFiltered(filteredContacts);
  }, [values.search]);

  useEffect(() => {
    if (values.name !== undefined || values.name !== "") {
      setNamePackpage(values.name);
    }
  }, [values.name]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ContainerData className="p-4">
              <div className="pe-2 flex-grow-1 d-flex justify-content-between">
                <Title>Defina o nome e os valores das tarifas</Title>
                <div className="d-flex">
                  <Button
                    mode="border"
                    label="Cancelar"
                    onClick={() => {
                      navigate("/app/myspace/tariffs");
                    }}
                  />
                  <Spacer left={10} />
                  <Button
                    mode="main"
                    label="Criar pacote de tarifas"
                    disabled={
                      values?.name?.length <= 0 ||
                      valueSelected === "" ||
                      (holdersSelectedsIndex.length === 0 && packageId === null)
                    }
                    onClick={() => {
                      fetchCreateNewPackpageTariffs();
                    }}
                  />
                </div>
              </div>
              <Spacer bottom={50} />

              <div className="row">
                {" "}
                <div className="col-12 " style={{ marginTop: "3px" }}>
                  <Input
                    required
                    label="Defina o nome para cesta de tarifas"
                    placeholder="Digite o nome da cesta"
                    name="name"
                    onChange={handleChange}
                    value={namePackpage}
                    type="text"
                  />
                </div>
              </div>
              <div className="row" style={{ width: "350px" }}>
                <small>Tipo de cesta</small>
                <Spacer bottom={10} />
                <div className="col-6 d-flex align-items-start">
                  {" "}
                  <div onClick={() => setValueSelected("pf")}>
                    <Radio
                      disabled={false}
                      position="right"
                      checked={valueSelected === "pf"}
                    />
                  </div>
                  <p
                    onClick={() => {
                      setValueSelected("pj");
                    }}
                    className="ms-3 "
                    style={{
                      position: "relative",
                      bottom: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Pessoa Física
                  </p>
                </div>
                <div className="col-6 d-flex align-items-start">
                  <div
                    onClick={() => {
                      setValueSelected("pj");
                    }}
                  >
                    <Radio
                      disabled={false}
                      position="right"
                      checked={valueSelected === "pj"}
                    />
                  </div>
                  <p
                    onClick={() => setValueSelected("pj")}
                    className="ms-3 "
                    style={{
                      position: "relative",
                      bottom: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Pessoa Jurídica
                  </p>
                </div>
              </div>

              <Spacer bottom={10} />

              {valueSelected !== "" && (
                <>
                  <Title>Tarifas cobradas</Title>
                  <Spacer bottom={20} />

                  <div>
                    {isLoading ? (
                      <TableLoader />
                    ) : (
                      <Table
                        haveHeader={true}
                        columns={columnsTariffs}
                        data={applyPagination(indexedTariffs, {
                          page,
                          rowsPerPage,
                        })}
                      />
                    )}
                  </div>
                  <Spacer bottom={-30} />
                  <Paginator
                    list={tariffsData?.length > 0 ? tariffsData : []}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    pageSetter={setPage}
                  />
                </>
              )}
            </ContainerData>
            {valueSelected !== "" && (
              <ContainerData className="p-4 mt-5">
                <>
                  <Spacer bottom={20} />
                  <Title>Correntistas</Title>
                  <Description color="gray">
                    Selecione o(s) correntista(s) que utilizarão esta cesta de
                    tarifas
                  </Description>
                  <div className="mt-5 d-flex">
                    <div>
                      <small>Migrar correntistas de outra cesta</small>
                      <Spacer top={5} />
                      <div
                        onClick={() => setIsMigrateClients(!isMigrateClients)}
                      >
                        <Toggle mode="small" checked={isMigrateClients} />
                      </div>
                    </div>
                    <Spacer left={50} />

                    <div style={{ width: "300px", height: "80px" }}>
                      {isMigrateClients && (
                        <CustomSelect
                          label={"Migrar correntistas de "}
                          options={listPackpages}
                          onChange={handleSelectPackage}
                          placeholder="Selecione"
                        />
                      )}
                    </div>
                  </div>

                  {/* <Spacer bottom={-20} /> */}
                  <div className="row">
                    <div className="col-8">
                      <Input
                        mask={
                          valueSelected === "pf"
                            ? "999.999.999-99"
                            : "99.999.999/9999-99"
                        }
                        type="text"
                        required
                        placeholder="Pesquisar correntista"
                        name="document_number"
                        value={values.document_number}
                        success={error === "notError"}
                        error={error === "Documento inválido" && error}
                        onChange={handleChange}
                        label={
                          valueSelected === "pf"
                            ? "Digite o CPF do correntista"
                            : "Digite o CNPJ do correntista"
                        }
                      />
                    </div>

                    <div
                      className=" col-4 mt-4"
                      style={{ position: "relative", top: "14px" }}
                    >
                      {" "}
                      <Button
                        mode="border"
                        label="Pesquisar"
                        loading={isLoadingSearch}
                        iconPosition="right"
                        full
                        onClick={() => fetchSearchClients()}
                        icon={SearchIcon}
                        disabled={
                          valueSelected === "pf"
                            ? values.document_number.length < 14 ||
                              error !== "notError"
                            : values.document_number.length < 18 ||
                              error !== "notError"
                        }
                      />
                    </div>
                  </div>

                  <Spacer bottom={5} />

                  {clientData?.length > 0 && (
                    <div
                      className="p-2 d-flex justify-content-between"
                      style={{
                        background: theme.colors.base.light200,
                        borderRadius: "5px",
                      }}
                    >
                      <div>
                        {clientData[0].name} |{" "}
                        {formatCPFCNPJ(clientData[0].document_number)}
                      </div>

                      <div className="d-flex">
                        <ImgSuccess
                          src={AddIcon}
                          id={"add"}
                          onClick={() => addClient(clientData[0])}
                        />
                        <NotControlledToolTip placement="top" target={`add`}>
                          Adicionar correntista
                        </NotControlledToolTip>
                        <Spacer left={10} />

                        <ImgDelete
                          id={"notAdd"}
                          src={DoNotAddIcon}
                          onClick={() => setClientData([])}
                        />
                        <NotControlledToolTip placement="top" target={`notAdd`}>
                          Não adicionar correntista
                        </NotControlledToolTip>
                        <Spacer left={10} />
                      </div>
                    </div>
                  )}

                  {/* <div className="col-6">
                    <Input
                      type="search"
                      search
                      value={values.search}
                      name="search"
                      onChange={handleChange}
                    />
                  </div> */}
                  <div>
                    {clientsInTable.length > 0 ? (
                      <div
                        className="mt-3"
                        style={{ height: "300px", overflow: "auto" }}
                      >
                        <p>Correntistas Selecionados para esta cesta</p>
                        <Spacer bottom={-20} />
                        <Table
                          columns={columnsHolders}
                          data={clientsInTable}
                          dataSpacer={10}
                        />
                      </div>
                    ) : (
                      <div className=" text-center mt-5">
                        Você ainda não adicionou nenhum correntista{" "}
                      </div>
                    )}

                    {/* {values.search.length > 0 &&
                    filteredContacts.length === 0 ? (
                      <p>Nenhum resultado encontrado</p>
                    ) : (
                      <div style={{ height: "300px", overflow: "auto" }}>
                        <Table
                          selectedRows={selectedRows}
                          haveHeader={false}
                          columns={columnsHolders}
                          data={
                            clientsFiltered.length > 0
                              ? clientsFiltered
                              : dataClients
                          }
                          dataSpacer={10}
                        />
                      </div>
                    )} */}
                  </div>

                  <div
                    className="d-flex justify-content-end "
                    style={
                      clientsInTable.length > 0
                        ? { marginTop: "20px" }
                        : { marginTop: "200px" }
                    }
                  >
                    <Button
                      mode="border"
                      label="Cancelar"
                      onClick={() => {
                        navigate("/app/myspace/tariffs");
                      }}
                    />

                    <Spacer left={10} />
                    <Button
                      mode="main"
                      label="Criar pacote de tarifas"
                      disabled={
                        values?.name?.length <= 0 ||
                        valueSelected === "" ||
                        (holdersSelectedsIndex.length === 0 &&
                          packageId === null)
                      }
                      onClick={() => {
                        fetchCreateNewPackpageTariffs();
                      }}
                    />
                  </div>
                </>
              </ContainerData>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DataNewPackpage;
