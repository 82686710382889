import { LateralModal } from "@zilliondigital/mirage-ui";
import usePix from "~/hooks/PIX/usePix";

import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";

import TransferWithPixModalContent from "./TransferWithPixModalContent";
import { useEffect } from "react";

export const TransferWithPixModal = () => {
  const { openTransferWithPixModal, setOpenTransferWithPixModal } = usePix();
  const {
    manualPix,
    stepIndex,
    isLoadingTransferReceipt,
    transferReceiptRender,

    isPixWithSelectedPartner,
    setSelectedIndex,
  } = useTransferWithPix();

  const titlesModalSendPix = [
    !isPixWithSelectedPartner
      ? "Selecionar o tipo de chave"
      : "Selecione a conta bancária",
    "Enviar",
  ];

  const handleCloseModal = () => {
    setOpenTransferWithPixModal(!openTransferWithPixModal);
    setSelectedIndex(-1);
  };

  useEffect(() => {
    if (!openTransferWithPixModal) {
      setSelectedIndex(-1);
    }
  }, [openTransferWithPixModal]);

  return (
    <LateralModal
      style={{ zIndex: "3" }}
      toggleModal={handleCloseModal}
      open={openTransferWithPixModal}
      header
      title={
        manualPix
          ? "Pix manual"
          : isLoadingTransferReceipt
          ? "Processando transação..."
          : transferReceiptRender && !isLoadingTransferReceipt
          ? "Comprovante"
          : titlesModalSendPix[stepIndex]
      }
    >
      <TransferWithPixModalContent />
    </LateralModal>
  );
};
