import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  CustomSelect,
  Input,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { Fragment, useEffect, useMemo, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import useToast from "~/hooks/useToast";
import useContacts from "~/hooks/Contacts/useContacts";
import * as Yup from "yup";
import ApiService from "~/services/api";
import { useFormik } from "formik";
import {
  INVALID_FORMAT,
  REQUIRED_LABEL,
  cnpjCpfRegex,
} from "~/services/validators";
import { onlyNumbers } from "~/services/utils";
import useNewContact from "~/hooks/Contacts/useNewContact";
import { ufOptions, ufOptionsId } from "~/data/options";
import useCEP from "~/hooks/useCEP";
import useAccount from "~/hooks/useAccount";
import Copyright from "~/components/Copyright";
import { APIResponse, getcontacts } from "~/types";
import Description from "~/components/Texts/Description";
import theme from "~/themes/theme";
import documentNumberValidator from "~/utils/validateDocNumber";

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const GeneralContainerData = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;

  width: 100%;
  flex-grow: 1;

  background-color: #f8f8f8;
  border-radius: 24px 24px 0 0;

  .container-return-button {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    position: relative;
    flex-basis: auto;
    padding-top: 60px;
    flex-grow: 1;
  }

  .container-auxiliar {
    position: fixed;
  }

  .invisible-auxiliar-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
  }
`;

const ContainerData = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
`;

const Title = styled.p`
  font-size: 18px;
  color: #3b3b3b;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

type FormValues = {
  name: string;
  type: string;
  document_number: string;
  birth_date: string;
  mobile_phone: string;
  email: string;
  zip_code: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_LABEL),
  type: Yup.string().required(REQUIRED_LABEL),
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(cnpjCpfRegex, INVALID_FORMAT),
  birth_date: Yup.string().required(REQUIRED_LABEL),
  mobile_phone: Yup.string().required(REQUIRED_LABEL),
  email: Yup.string().required(REQUIRED_LABEL),
  cep: Yup.string().required("Campo obrigatório"),
  street: Yup.string().required("Campo obrigatório"),
  number: Yup.string().required("Campo obrigatório"),
  complement: Yup.string().required("Campo obrigatório"),
  district: Yup.string().required("Campo obrigatório"),
  city: Yup.string().required("Campo obrigatório"),
  state: Yup.string().required("Campo obrigatório"),
});

const EditContact = () => {
  const optionsPhoneCode = [{ label: "+55", value: "1" }];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTypeValue, setSelectedTypeValue] =
    useState<any>("residential");

  const {
    typeLenght,
    setContactsData,
    contactsData,
    contactIndex,
    setIsDetails,
    setIsEdit,
    isEdit,
  } = useContacts();

  const [newContactAddressPayload, setNewContactAddressPayload] = useState<any>(
    {}
  );

  const { handleCep, address, setAddress } = useCEP();

  const { showToast } = useToast();

  const { setNewContactPayload, newContactPayload } = useNewContact();

  const params = useParams<{ id: string }>();

  const [partnerData, setPartnerData] = useState<any>([]);

  const navigate = useNavigate();

  const menusBreadCrumb = [
    {
      label: "Contatos",
      onClick: () => {
        setIsEdit(false);
        navigate("/app/contacts");
      },
    },
    { label: "Editar contato" },
  ];

  const [initial, setInitial] = useState<FormValues>({
    name: partnerData[0]?.name,
    type: "",
    document_number: partnerData[0]?.document_number,
    birth_date: partnerData[0]?.birth_date,
    mobile_phone: partnerData[0]?.mobile_phone,
    email: partnerData[0]?.email,
    zip_code: partnerData[0]?.all_address[0]?.zipcode,
    street: partnerData[0]?.all_address[0]?.street,
    number: partnerData[0]?.all_address[0]?.number,
    complement:
      partnerData[0]?.all_address[0]?.complement !== null &&
      partnerData[0]?.all_address[0]?.complement,
    district: partnerData[0]?.all_address[0]?.district,
    city: partnerData[0]?.all_address[0]?.city,
    state: "",
  });

  const [error, setError] = useState<string>("");

  const getContacts = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "partner/",
        token: true,
        params: {
          type: "contact",
          default: false,
        },
      });

      setContactsData(data.results.reverse());
    } catch (e) {
      console.warn(e);
    }
  };

  const handleSubmit = (values: FormValues) => {
    const editContact = async () => {
      setIsLoading(true);
      try {
        const { data } = await ApiService.HttpPut({
          route: `partner/${params?.id}/`,
          token: true,
          body: newContactPayload,
        });

        console.log(data);

        const dataAddress = await ApiService.HttpPut({
          route: `address/${partnerData[0].all_address[0].id}/`,
          body: newContactAddressPayload,
          token: true,
        });

        console.log(dataAddress);

        setIsLoading(false);
        getContacts();

        showToast("Dados alterados com sucesso", "", "success");
        setIsEdit(false);
        navigate("/app/contacts");
      } catch (e) {
        setIsLoading(false);
        showToast(
          "Não foi possível atualizar os dados",
          "Certifique-se de que todos os campos estão completos e tente novamente",
          "error"
        );
      }
    };

    editContact();
  };

  const {
    values,
    handleBlur,
    submitForm,
    handleChange,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initial,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const getPartner = async () => {
    const { data } = await ApiService.HttpGet<APIResponse<getcontacts>>({
      route: "partner/",
      token: true,
      params: {
        type: "contact",
        default: false,
        id: params.id,
      },
    });

    setPartnerData(data.results);

    const complement = data.results[0]?.all_address[0]?.complement;

    setInitial({
      name: data.results[0]?.name,
      type: "",
      document_number: data.results[0]?.document_number,
      birth_date: data.results[0]?.birth_date,
      mobile_phone: data.results[0]?.mobile_phone,
      email: data.results[0]?.email,
      zip_code: data.results[0]?.all_address[0]?.zipcode,

      street: data.results[0]?.all_address[0]?.street,
      number: data.results[0]?.all_address[0]?.number,
      complement:
        complement !== null &&
        complement !== undefined &&
        complement !== "undefined" &&
        complement !== false
          ? data.results[0]?.all_address[0]?.complement
          : "",
      district: data.results[0]?.all_address[0]?.district,
      city: data.results[0]?.all_address[0]?.city,
      state: "",
    });
  };

  useEffect(() => {
    setAddress({
      zip_code: "",
      street: "",
      district: "",
      city: "",
      state: "",
      error: "",
    });

    getPartner();
    return () => {
      setIsEdit(false);
    };
  }, [params.id]);

  const disabledButton = useMemo(() => {
    return (
      (address.zip_code === "" &&
        values.zip_code === undefined &&
        address.street === "" &&
        values.street === undefined &&
        address.district === "" &&
        values.district === undefined &&
        address.city === "" &&
        values.city === undefined) ||
      values.number === "" ||
      values.number === undefined
    );
  }, [values, address]);

  const isValidDocument = useMemo(() => {
    return (
      values.document_number?.length > 13 &&
      documentNumberValidator(values?.document_number)
    );
  }, [values.document_number]);

  useEffect(() => {
    setNewContactAddressPayload({
      partner: partnerData[0]?.id,
      zipcode: address.zip_code !== "" ? address.zip_code : values.zip_code,
      street: address.street !== "" ? address.street : values.street,
      number: values.number,
      complement: String(values.complement),
      district: address.district !== "" ? address.district : values.district,
      city: address.city !== "" ? address.city : values.city,
      state: address.state,
      address_type: String(selectedTypeValue),
      country: "Brasil",
      id: partnerData[0]?.all_address[0]?.id,
    });

    setNewContactPayload({
      default: false,
      name: values.name,
      document_number: onlyNumbers(values.document_number),
      document_type: values?.document_number?.length > 14 ? "cnpj" : "cpf",
      birth_date: values.birth_date,
      mobile_phone: onlyNumbers(values.mobile_phone),
      email: values.email,
      target: "contact",
      type: "contact",
      id: partnerData[0]?.id,
      partners: [],
    });
  }, [values, partnerData, address, selectedTypeValue]);

  useEffect(() => {
    if (values.document_number?.length <= 13 && !isValidDocument) {
      setError("initialState");
    } else if (values.document_number?.length > 13 && isValidDocument) {
      setError("notError");
    } else if (values.document_number?.length > 13 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document_number]);

  return (
    <ParentContainer>
      {partnerData?.length > 0 && (
        <Fragment>
          <GeneralContainerData>
            <div className="d-flex flex-column container w-100 h-100 ">
              <Spacer top={30} />
              <Breadcrumb menus={menusBreadCrumb} />
              <Spacer top={30} />
              <ContainerData className="container">
                <div className="d-flex justify-content-between p-4 ">
                  <Title className="fw-bold">Dados Gerais</Title>
                </div>
                <Spacer bottom={-30} />
                <form className="ps-4">
                  <div className="row">
                    <div className="col-6">
                      <div className="row ">
                        <div className="col-12">
                          <Input
                            name="name"
                            label="Nome/Razão Social"
                            placeholder="Nome"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-6">
                          <Input
                            name="document_number"
                            label="CPF/CNPJ"
                            placeholder="Digite aqui"
                            error={
                              values.document_number?.length > 13 &&
                              !isValidDocument &&
                              error
                            }
                            mask={
                              values.document_number?.length > 14
                                ? "99.999.999/9999-99"
                                : "999.999.999-9999999"
                            }
                            value={values.document_number}
                            onChange={(props: any) => {
                              handleChange(props);
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-6">
                          <Input
                            type="date"
                            name="birth_date"
                            label="Data de nascimento"
                            value={values.birth_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div className="col-2">
                          <CustomSelect
                            placeholder="Selecione"
                            label="Telefone"
                            options={optionsPhoneCode}
                            className="pt-1"
                            value={optionsPhoneCode[0]}
                          />
                        </div>
                        <div className="col-4">
                          <Input
                            name="mobile_phone"
                            mask="(99) 99999-9999"
                            placeholder="(11) 98144-5263"
                            value={values.mobile_phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-6">
                          <Input
                            name="email"
                            label="Email"
                            placeholder="seunome@empresa.com"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <Spacer bottom={-30} />

                <Title className="fw-bold ps-4 pt-5 ">Endereço</Title>
                {partnerData[0]?.all_address.length === 0 && (
                  <Description className="ps-4" color={theme.colors.error.main}>
                    * Este contato não possui endereço cadastrado, insira o{" "}
                    endereço para poder salvar suas alterações.
                  </Description>
                )}

                <div className="row d-flex align-items-end p-4">
                  <div className="col-2">
                    <Input
                      mask="99999-999"
                      type="text"
                      name="zip_code"
                      value={
                        address.zip_code !== ""
                          ? address.zip_code
                          : values.zip_code
                      }
                      label="CEP"
                      placeholder="04313-110"
                      error={address.error}
                      onChange={(e) => {
                        handleChange(e);
                        handleCep(e);
                      }}
                      search
                    />
                  </div>
                  <div className="col-10"></div>
                  <div className="col-3">
                    <Input
                      type="text"
                      name="street"
                      value={
                        address.street !== "" ? address.street : values.street
                      }
                      label="Logradouro"
                      placeholder="Rua Borboletas Psicodélicas"
                      autoCapitalize="words"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-1">
                    <Input
                      type="text"
                      name="number"
                      value={values.number}
                      label="Número"
                      placeholder="269"
                      error={touched.number && errors.number}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-2">
                    <Input
                      type="text"
                      name="complement"
                      value={values.complement}
                      label="Complemento"
                      placeholder="Casa 1"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6"></div>
                  <div className="col-4">
                    <Input
                      type="text"
                      name="district"
                      value={
                        address.district !== ""
                          ? address.district
                          : values.district
                      }
                      label="Bairro"
                      placeholder="Vila Guarani"
                      onChange={handleChange}
                      autoComplete="off"
                      autoCapitalize="off"
                    />
                  </div>

                  <div className="col-2">
                    <Input
                      type="text"
                      name="city"
                      value={address.city !== "" ? address.city : values.city}
                      label="Cidade"
                      placeholder="São Paulo"
                      onChange={handleChange}
                      autoComplete="city"
                    />
                  </div>
                  <div className="col-1">
                    <CustomSelect
                      name="state"
                      label="UF"
                      placeholder="Selecione"
                      options={ufOptions}
                      value={
                        address.state !== ""
                          ? ufOptions.find((uf) => uf.value === address.state)
                          : ufOptionsId.find((uf) => uf.value === values.state)
                      }
                      onChange={(value: any) => {
                        setAddress({ ...address, state: value.value });
                        setFieldValue("state", values.state);
                      }}
                    />
                  </div>
                </div>
              </ContainerData>
              <div className="w-100 d-flex justify-content-center justify-content-lg-end p-3 align-items-center">
                <Button
                  mode="phantom"
                  label="Cancelar"
                  onClick={() => {
                    isEdit
                      ? navigate(`/app/contacts/details/${params?.id}`)
                      : navigate(`/app/contacts/`);
                  }}
                />
                <Spacer right={20} />
                <Button
                  loading={isLoading}
                  mode="main"
                  label="Salvar alterações"
                  onClick={() => handleSubmit(values)}
                  disabled={disabledButton}
                />
              </div>
              <Spacer top={40} />

              <Copyright />

              <Spacer top={30} />
            </div>
          </GeneralContainerData>
        </Fragment>
      )}
    </ParentContainer>
  );
};

export default EditContact;
