import "bootstrap/dist/css/bootstrap.min.css";
import {
  ButtonMoreDetails,
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";

import styled, { keyframes } from "styled-components";
import { Fragment, useEffect, useMemo, useState } from "react";
import PenIcon from "~/assets/icons/contacts/pencil.png";
import Timer from "~/assets/icons/figure_3d_time.png";
import DeleteIcon from "~/assets/icons/delete.png";
import SupplierIcon from "~/assets/icons/contacts/supplier.png";
import ColaboratteOrCostumerIcon from "~/assets/icons/contacts/collaborate-or-costumer.png";
import OpenAccordionIcon from "~/assets/icons/Actions/Chevron.png";
import CloseAccordionIcon from "~/assets/icons/Actions/Chevron2.png";
import { formatCash } from "~/utils";
import theme from "~/themes/theme";
import { Title } from "../../app-content.styles";
import useTariffs from "~/hooks/useTariffs";
import { Columns } from "~/types";
import { useNavigate } from "react-router-dom";

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
  }
`;

export const ImgEye = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

const ImgPen = styled.img`
  height: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

const Rounded = styled.div`
  width: 96px;
  height: 96px;
  background-color: #ffe5d8;
  position: relative;

  img {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`;

const ImgDelete = styled.img`
  height: 18px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.error.mainFilter};
  }
`;

const PackpagesTariffsList = () => {
  const {
    tariffsData,
    packpagesTariffs,
    setOpenDeletePackpageModal,
    setDeletePackpageModalData,
    lengthDefaultPF,
    lengthDefaultPJ,
  } = useTariffs();

  const [selectedDetailsIndex, setSelectedDetailsIndex] = useState<
    number | null
  >(null);

  const navigate = useNavigate();

  const dataPackpages = useMemo(() => {
    return packpagesTariffs?.results;
  }, [packpagesTariffs]);

  const columns: Columns[] = [
    {
      label: "Nome da cesta",
      key: "name",

      renderColumn: () => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <p>Nome das cestas</p>
            </div>
            <div>
              <p>Correntistas atribuídos</p>
            </div>
            <p></p>
          </div>
        );
      },
      renderData: (data: any) => {
        return (
          <>
            <div
              className="d-flex flex-column  w-100  "
              style={{ position: "relative" }}
            >
              <div className="d-flex justify-content-between  ">
                <div
                  className="col-4 "
                  onClick={() =>
                    setSelectedDetailsIndex(
                      selectedDetailsIndex === data.id ? null : data.id
                    )
                  }
                >
                  <ImgEye
                    style={{ height: "22px" }}
                    id={`more-details-${data.id}`}
                    src={
                      selectedDetailsIndex === data.id
                        ? CloseAccordionIcon
                        : OpenAccordionIcon
                    }
                    onClick={(e: any) => {
                      setSelectedDetailsIndex(
                        selectedDetailsIndex === data.id ? null : data.id
                      );
                    }}
                  />{" "}
                  <NotControlledToolTip
                    placement="top"
                    target={`more-details-${data.id}`}
                  >
                    {selectedDetailsIndex === data.id
                      ? "Ocultar detalhes"
                      : "Mais Detalhes"}
                  </NotControlledToolTip>
                  <span className="h6">{data.name}</span>
                </div>

                <div
                  className="col-4 text-center "
                  onClick={() =>
                    setSelectedDetailsIndex(
                      selectedDetailsIndex === data.id ? null : data.id
                    )
                  }
                >
                  {data.default && data.type === "pf"
                    ? lengthDefaultPF
                    : data.default && data.type === "pj"
                    ? lengthDefaultPJ
                    : data.holders.length}
                </div>
                <div className="pe-4 me-5 d-flex col-2">
                  {" "}
                  <ImgPen
                    id={`edit-${data.id}`}
                    src={PenIcon}
                    onClick={() =>
                      navigate(`/app/myspace/tariffs/edit/${data.id}/tariffs`)
                    }
                  />
                  <NotControlledToolTip
                    placement="top"
                    target={`edit-${data.id}`}
                  >
                    {"Editar"}
                  </NotControlledToolTip>
                  <Spacer left={10} />
                  {!data.default && (
                    <>
                      <ImgDelete
                        id={`delete-${data.id}`}
                        src={DeleteIcon}
                        onClick={() => {
                          setDeletePackpageModalData({
                            name: data.name,
                            id: data.id,
                          });
                          setOpenDeletePackpageModal(true);
                        }}
                      />
                      <NotControlledToolTip
                        placement="top"
                        target={`delete-${data.id}`}
                      >
                        {"Remover"}
                      </NotControlledToolTip>
                    </>
                  )}
                </div>
              </div>
              {selectedDetailsIndex === data.id && (
                <>
                  <hr />
                  <div className="ps-4 pe-5" key={data.id}>
                    {data.taxes.map((taxes: any) => {
                      return (
                        <div>
                          <p>
                            {taxes.name}:{" "}
                            <b>
                              {taxes.customer_tax_out !== 0
                                ? formatCash(taxes.customer_tax_out)
                                : "R$ 0,00"}
                            </b>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="pt-5">
      {dataPackpages?.length === 0 ? (
        <div className="w-100  pt-3  d-flex flex-column align-items-center justify content-start">
          <Rounded className="rounded-circle">
            {" "}
            <img src={Timer} />
          </Rounded>

          <Spacer bottom={60} />
          <Title>{"   Nenhum Pacote de tarifa encontrado"}</Title>

          <Spacer bottom={100} />
        </div>
      ) : (
        <Table data={dataPackpages} columns={columns} dataSpacer={10} />
      )}
    </div>
  );
};

export default PackpagesTariffsList;
