import {
  Button,
  Input,
  LateralModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Description from "~/components/Texts/Description";
import useTariffs from "~/hooks/useTariffs";
import useToast from "~/hooks/useToast";
import ApiService from "~/services/api";
import theme from "~/themes/theme";
import { PackpageTypes } from "~/types";
import { formatCPFCNPJ, formatCelphone, onlyNumbers } from "~/utils";
import documentNumberValidator from "~/utils/validateDocNumber";

const Container = styled.div`
  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;
  }
  padding: 20px;
`;

type values = {
  document_number: string;
};

const initialValues: values = {
  document_number: "",
};

const ModalAddClients = () => {
  const {
    openAddClient,
    setOpenAddClient,
    setEditPackpageData,
    editPackpageData,
  } = useTariffs();
  const [error, setError] = useState<string>("");

  const [clientData, setClientData] = useState<any[]>([]);
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [isloadingTable, setIsLoadingTable] = useState<boolean>(false);

  const { showToast } = useToast();

  const params = useParams<{ id: string }>();

  const { values, handleChange, resetForm } = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const isPFPackpage = true;

  const isValidDocument = useMemo(
    () =>
      values.document_number.length >= 14 &&
      documentNumberValidator(values.document_number),
    [values.document_number]
  );

  const fetchSearchClients = useCallback(async () => {
    setIsLoadingTable(true);
    setClientData([]);
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/approved-partners/",
        params: {
          document_number: onlyNumbers(values.document_number),
        },
        token: true,
      });

      if (data.length === 0) {
        showToast(
          "Correntista não encontrado",
          "Verifique o documento digitado e tente novamente",
          "error"
        );
      } else {
        showToast("Correntista encontrado com sucesso", "", "success");
        setClientData(data);
      }

      setIsLoadingTable(false);
    } catch (error) {
      setIsLoadingTable(false);
      console.log("Erro ao buscar clientes:", error);
    }
  }, [error]);

  const fethPackpageToEditData = useCallback(async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/",
        token: true,
      });

      const dataFiltered = data.results.filter(
        (packpage: PackpageTypes) => packpage.id === Number(params.id)
      );

      setEditPackpageData(dataFiltered);
    } catch {}
  }, [params.id, setEditPackpageData]);

  const fetchAddHolders = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost({
        route: `backoffice/tariffs-package/${params.id}/add-holders/`,
        body: {
          holders: [clientData[0]?.id],
        },
        token: true,
      });

      showToast("Correntista adicionado com sucesso", "", "success");
      setOpenAddClient(false);

      setIsLoading(false);
      fethPackpageToEditData();
    } catch (e: any) {
      showToast(
        "Ocorreu um erro ao tentar adicionar o correntista",
        "",
        "error"
      );

      setIsLoading(false);
    }
  }, [clientData]);

  useEffect(() => {
    if (error === "notError") {
      fetchSearchClients();
    }
  }, [error]);

  useEffect(() => {
    if (values.document_number.length < 14 && !isValidDocument) {
      setError("initialState");
    } else if (values.document_number.length >= 14 && isValidDocument) {
      setError("notError");
    } else if (values.document_number.length >= 14 && !isValidDocument) {
      setError("Documento inválido");
    }
  }, [values.document_number]);

  useEffect(() => {
    if (!openAddClient) {
      resetForm();
      setClientData([]);
    }
  }, [openAddClient]);

  return (
    <LateralModal
      title="Adicionar correntista"
      header
      open={openAddClient}
      toggleModal={() => setOpenAddClient(!openAddClient)}
    >
      <Container
        className="p-4 d-flex flex-column justify-content-between vh-100"
        style={{ overflow: "hidden" }}
      >
        <div>
          <Input
            onChange={handleChange}
            name="document_number"
            value={values.document_number}
            success={error === "notError"}
            error={error === "Documento inválido" && error}
            label={
              isPFPackpage
                ? "Digite o CPF do correntista"
                : "Digite o CNPJ do correntista"
            }
            required
            mask={isPFPackpage ? "999.999.999-99" : "99.999.999/9999-99"}
            placeholder="Pesquisar correntista"
            search
            helper={`Nesta cesta só é possível adicionar correntistas ${
              isPFPackpage ? "Pessoa Física" : "Pessoa Jurídica"
            }`}
          />

          <div>
            {clientData.length > 0 && (
              <div>
                <table className=" w-100">
                  <tbody>
                    <tr>
                      <td>
                        <Description color="#666666">Nome</Description>
                      </td>

                      <td className="second-column">
                        <Description>{clientData[0].name}</Description>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Description color="#666666">CPF</Description>
                      </td>

                      <td className="second-column">
                        <Description>
                          {formatCPFCNPJ(clientData[0]?.document_number)}
                        </Description>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Description color="#666666">Email</Description>
                      </td>

                      <td className="second-column">
                        <Description>{clientData[0].email ?? "-"}</Description>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Description color="#666666">Celular</Description>
                      </td>

                      <td className="second-column">
                        <Description>
                          {clientData[0]?.mobile_phone
                            ? formatCelphone(clientData[0].mobile_phone)
                            : "-"}
                        </Description>
                      </td>
                    </tr>

                    {clientData[0].tax_packages.length > 0 && (
                      <>
                        <tr>
                          <td>
                            <Description color="#666666">
                              Cesta de Tarifas
                            </Description>
                          </td>

                          <td className="second-column">
                            <Description>
                              {clientData[0]?.tax_packages[0]?.name ?? "-"}
                            </Description>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}{" "}
          </div>

          {clientData.length > 0 && clientData[0].tax_packages.length > 0 && (
            <p style={{ color: theme.colors.error.main }} className="mt-3">
              Este cliente já faz parte de outra cesta de tarifas. Remova-o da
              cesta atual antes de adicioná-lo a esta.
            </p>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <Button
            mode="phantom"
            onClick={() => setOpenAddClient(false)}
            label="Cancelar"
          />

          <Button
            mode="main"
            onClick={() => fetchAddHolders()}
            loading={isloading}
            label="Adicionar correntista"
            disabled={
              clientData.length > 0 && clientData[0].tax_packages.length > 0
                ? true
                : clientData.length <= 0
                ? true
                : false
            }
          />
        </div>
      </Container>
    </LateralModal>
  );
};

export default ModalAddClients;
