import constate from "constate";
import { useState } from "react";
import ApiService from "~/services/api";

const [BanksProvider, useBanks] = constate(() => {
  const [banksData, setBanksData] = useState<any>([]);

  const getBanks = async () => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "bank/",
        params: {
          limit: 9999,
          offset: 0,
        },
        token: true,
      });

      if (data) {
        setBanksData(data.results);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const flattenBanksData =
    banksData?.reduce((flattenedArray: any[], bankArray: any[]) => {
      return flattenedArray.concat(bankArray);
    }, []) || [];

  const bankOptions = flattenBanksData.map((bank: any) => ({
    label: `${bank.code} - ${bank.name}`,
    value: bank.id,
  }));

  return { getBanks, bankOptions, banksData, flattenBanksData };
});

export { BanksProvider };

export default useBanks;
