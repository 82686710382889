import {
  Button,
  Input,
  LateralModal,
  ProgressBar,
  RadioGroup,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { Fragment, useEffect } from "react";

import useCollections from "~/hooks/Collections/useCollections";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";

import { motion } from "framer-motion";

import useAccount from "~/hooks/useAccount";
import useAddBalance from "~/hooks/useAddBalance";
import useSplit from "~/hooks/useSplit";
import { Title } from "~/pages/Authentication/Auth.styles";
import Description from "~/components/Texts/Description";
import label from "~/labels";
import NewSplitType from "./NewSplitType";
import NewSplitSelectContact from "./NewSplitSelectContact";
import NewSplistSelectBankAccountOrKeyPix from "./NewSplitSelectBankAccountOrKeyPix";
import { useFormikContext } from "formik";
import SelectTypeToTransfer from "./SelectTypeToTransfer";
import NewSplitPercentOrAmount from "./NewSplitPercentOrAmount";

const NewSplitModal = () => {
  const {
    openNewCollectionOrRecurrenceModal,
    openNewCollectionOrRecurrenceModalInDash,
  } = useCollections();
  const { isEmpty, setIsEmpty } = useAddBalance();

  const {
    openNewSplitModal,
    setOpenNewSplitModal,
    stepIndex,
    setStepIndex,
    depositIsByKeyPix,
    setDepositIsByKeyPix,
    setReceivers,
    isEditing,
    setSelectedValue,
  } = useSplit();

  type ModalTitles = {
    [key: number]: string;
  };

  const RenderNewSplit: React.ReactNode[] = [
    <NewSplitType />,
    <NewSplitSelectContact />,
    <NewSplistSelectBankAccountOrKeyPix />,
    depositIsByKeyPix ? <NewSplitPercentOrAmount /> : <SelectTypeToTransfer />,
    <NewSplitPercentOrAmount />,
  ];

  const ModalTitles: ModalTitles = {
    0: "Criar novo arranjo",
    1: "Definir novo recebedor",
    2: "Selecionar conta bancária",
    3: depositIsByKeyPix
      ? "Definir percentual ou valor fixo"
      : "Definir meio de pagamento",
    4: "Definir percentual ou valor fixo",
  };

  const handleToggleModal = () => {
    setOpenNewSplitModal(!openNewSplitModal);
    setDepositIsByKeyPix(false);
    setReceivers([]);
    setIsEmpty(true);
    localStorage.removeItem("splitOver");
    localStorage.removeItem("values");
    localStorage.removeItem("splitLostDiscount");
  };

  useEffect(() => {
    if (
      !openNewCollectionOrRecurrenceModal ||
      !openNewCollectionOrRecurrenceModalInDash
    ) {
      setIsEmpty && setIsEmpty(true);
    }
  }, [
    openNewCollectionOrRecurrenceModal,
    openNewCollectionOrRecurrenceModalInDash,
  ]);

  useEffect(() => {
    if (!openNewSplitModal) {
      setStepIndex(0);
      setSelectedValue("");
    }
  }, [openNewSplitModal]);

  return (
    <LateralModal
      open={openNewSplitModal}
      title={ModalTitles[stepIndex]}
      toggleModal={handleToggleModal}
      style={{ zIndex: "3" }}
      header
    >
      <Fragment>
        <ProgressBar percent={100} />

        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.3 }}
          style={{ height: "100vh" }}
          className="p-4 "
        >
          {RenderNewSplit[stepIndex]}
        </motion.div>
      </Fragment>
    </LateralModal>
  );
};

export default NewSplitModal;
