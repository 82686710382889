import "bootstrap/dist/css/bootstrap.min.css";
import {
  Spacer,
  Table,
  Button,
  NotControlledToolTip,
} from "@zilliondigital/mirage-ui";

import useAuthority, { OPT_TYPES, OPT_TYPES_ICONS } from "~/hooks/useAuthority";
import EyeIcon from "~/assets/icons/eye-Size=MD.svg";
import EmptyListIcon from "~/assets/images/LoadingImages/Permissions.svg";
import { format, parseISO } from "date-fns";
import { formatCPFCNPJ, formatCash } from "~/utils";
import { applyPagination } from "~/components/Paginator";
import { operationStatus } from "~/data/options";
import Skeleton from "~/components/SkeletonTables";
import { Columns } from "~/types";

const ApprovedData = () => {
  const {
    operations,
    setOpenOperationDetailModal,
    setCurrentModalData,
    loadingOperations: loadingList,
    page,
    rowsPerPage,
  } = useAuthority();

  const columns: Columns[] = [
    {
      label: "Tipo",
      key: "bank_operation_type",
      renderData: (data: any) => (
        <div className="d-flex  align-items-center">
          <img src={OPT_TYPES_ICONS[data.bank_operation_type]} />
          <p className="ps-2 m-0">{OPT_TYPES[data.bank_operation_type]}</p>
        </div>
      ),
    },
    {
      label: "Status",
      key: "status",
      renderData: (data: any) => (
        <div className="d-flex" style={{ maxWidth: "400px" }}>
          <p>{data.status_description}</p>
        </div>
      ),
    },

    {
      label: "Beneficiário",
      key: "% assinada",

      renderData: (data: any) => (
        <div className="d-flex flex-column" style={{ maxWidth: "300px" }}>
          <p className="">
            {data.bank_operation_type === "pix_transfer"
              ? data?.operation_data?.receiver_account?.owner.name
              : data.bank_operation_type === "ted" ||
                data.bank_operation_type === "tev"
              ? data?.operation_data?.full_recipient?.name
              : data.bank_operation_type === "billet_payment" &&
                "Nome do beneficiário não encontrado"}
          </p>
          <Spacer bottom={-10} />
          <p className="">
            <b>
              {data.bank_operation_type === "pix_transfer"
                ? formatCPFCNPJ(
                    data?.operation_data?.receiver_account?.owner
                      .document_number
                  )
                : data.bank_operation_type === "ted" ||
                  data.bank_operation_type === "tev"
                ? formatCPFCNPJ(
                    data?.operation_data?.full_recipient?.document_number
                  )
                : data.bank_operation_type === "billet_payment" && ""}
            </b>
          </p>
        </div>
      ),
    },
    {
      label: "Data de emissão",
      key: "created",

      renderData: (data: any) => (
        <div>
          <p>
            {data.created
              ? format(parseISO(data.created.replace("Z", "")), "dd/MM/yyyy")
              : "---"}
          </p>
        </div>
      ),
    },
    {
      label: "Valor",
      key: "operation_data",

      renderData: (data: any) => (
        <div>
          <p>{data ? formatCash(data.operation_data?.amount) : "0"}</p>
        </div>
      ),
    },
    {
      label: "",
      key: "",

      renderData: (data: any) => {
        return (
          <div className="btn-icon-20">
            <Button
              id={`btn-${data.id}`}
              mode="phantom"
              label=""
              iconPosition="right"
              onClick={() => {
                setCurrentModalData(data);
                setOpenOperationDetailModal(true);
              }}
              icon={EyeIcon}
            />
            <NotControlledToolTip placement="top" target={`btn-${data.id}`}>
              Detalhes
            </NotControlledToolTip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Spacer bottom={20} />
      {loadingList ? (
        <Skeleton />
      ) : operations.length === 0 ? (
        <div className="empty-table-box no-filter">
          <img src={EmptyListIcon} />
          <span>Nenhuma transação foi 100% aprovada hoje</span>
        </div>
      ) : (
        <>
          <Table
            data={applyPagination(operations, { page, rowsPerPage })}
            columns={columns}
            dataSpacer={8}
          />
        </>
      )}
    </div>
  );
};

export default ApprovedData;
