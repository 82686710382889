import {
  BalanceAlert,
  Button,
  Input,
  InputCurrency,
  Spacer,
  Toggle,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";
import EyeIcon from "~/assets/icons/round_visibility_black_24dp.png";
import EyeOffIcon from "~/assets/icons/round_visibility_off_black_36dp.png";
import styled from "styled-components";
import BillingNotice from "~/components/BillingNotice";

import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import ApiService from "~/services/api";
import useToken from "~/hooks/useToken";

import useStatements from "~/hooks/useStatements";
import { Fragment, useEffect, useState } from "react";
import {
  convertedAmount,
  formatCPFCNPJ,
  formatNumberToCurrencyWithoutSimbol,
  onlyNumbers,
} from "~/utils";
import { useFormik } from "formik";
import useToast from "~/hooks/useToast";
import { ContainerBottom } from "~/pages/App/app-content.styles";

const Container = styled.div`
  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;
  }
  padding: 20px;

  .image-eye {
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  .container-balance {
    position: relative;
    top: -20px;
  }
`;

type FormValues = {
  amount: string;
  description: string;
};

const initialValues: FormValues = {
  amount: "",
  description: "",
};

const ManualPixSend = () => {
  const {
    hideAvailableBalance,
    setHideAvailableBalance,
    balanceFormatted,
    insufficientBalance,
    balance,
    setInsufficientBalance,
    getBalance,
  } = useAccount();

  const { showToast } = useToast();

  const [amount, setAmount] = useState(0);

  const {
    handlePrevStep,
    dataNewPixTransfer,
    setTransferReceiptRender,
    setDataTransferPixReceipt,
    setIsLoadingTransferReceipt,
    setTaxTransferPix,
    taxTransferPix,
  } = useTransferWithPix();
  const [error, setError] = useState(false);

  const { sendPartnerToken, getToken } = useToken();
  const { values, handleChange } = useFormik({
    initialValues: initialValues,

    onSubmit: (values: any) => {},
  });

  const fetchTax = async () => {
    try {
      const response = await ApiService.HttpPost({
        route: "customer-global-tax/",
        token: true,
        body: {
          slug: "pix_transfer",
          tax_is_enabled: true,
        },
      });
      setTaxTransferPix(response.data.customer_tax_out);
    } catch (err) {
      console.error("Erro", err);
    }
  };

  const sendManualPix = async () => {
    sendPartnerToken();

    const token = await getToken();

    setIsLoadingTransferReceipt(true);

    try {
      const { data } = await ApiService.HttpPost({
        route: "pix/transfer/",
        token: true,
        body: {
          amount: amount,
          code: token,
          receiver_account: Number(
            dataNewPixTransfer?.data?.data.bank_accounts[0]?.id ??
              Number(dataNewPixTransfer?.data?.data.bank_accounts[0]?.pk)
          ),
          sender_account: 1,
          type: "bank_account",
          description: values.description ?? "Sem descrição",
        },
      });

      setTransferReceiptRender(true);
      setDataTransferPixReceipt(data.data);
      setIsLoadingTransferReceipt(false);

      showToast(
        "Pix realizado com sucesso",
        "Sua transação foi enviada com sucesso.",
        "success"
      );

      setTimeout(() => {
        getBalance();
      }, 2000);
    } catch (e) {
      console.warn(e);
      setIsLoadingTransferReceipt(false);
    }
  };

  const handleClick = (e: any) => {
    if (isNaN(amount) || amount <= 0) {
      setError(true);
    } else if (amount <= balance) {
      sendManualPix();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (isNaN(amount) || amount <= 0) {
        setError(true);
      } else if (amount <= balance) {
        sendManualPix();
      }
    }
  };

  useEffect(() => {
    fetchTax();
  }, []);

  useEffect(() => {
    setAmount(convertedAmount(onlyNumbers(values.amount)));
  }, [values.amount]);

  useEffect(() => {
    if (amount + taxTransferPix > balance) {
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }
  }, [amount]);

  return (
    <Fragment>
      <Container className="p-4   ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-100">
            <InputCurrency
              isPercentage={false}
              id="amount"
              type="text"
              name="amount"
              onChange={handleChange}
              value={values.amount}
              error={error && "O valor precisa ser maior que 0"}
              inputSize="large"
              onFocus={() => setError(false)}
              onKeyDown={(e) => handleKeyDown(e)}
              label={`Defina o valor que deseja transferir para ${dataNewPixTransfer?.data?.data?.name}`}
            />
          </div>

          {/* <div className="ms-2 pb-3">
            <Description color="#666666">Agendar</Description>
            <Toggle mode="large" />
          </div> */}
        </div>
        <div className="d-flex justify-content-start align-items-center container-balance pt-1">
          <Description color="#666666">
            {`Saldo disponível atual é de
      `}
          </Description>
          <Spacer left={3} />
          <Description className="font-weight-bolder">
            {!hideAvailableBalance ? balanceFormatted : "******"}
          </Description>
          <Spacer left={5} />
          <img
            className="image-eye"
            src={!hideAvailableBalance ? EyeIcon : EyeOffIcon}
            onClick={() => setHideAvailableBalance(!hideAvailableBalance)}
          />
        </div>
        <Spacer bottom={-20} />
        <div>
          <Description color="#666666">{`Taxa `}</Description>
          <Description className="font-weight-bolder">
            R$ {formatNumberToCurrencyWithoutSimbol(taxTransferPix)}{" "}
          </Description>
        </div>

        {insufficientBalance && (
          <>
            {" "}
            <Spacer bottom={20} />
            <BalanceAlert
              title="Saldo insuficiente"
              message="Essa transferência não poder ser feita por saldo insuficiente"
            />
          </>
        )}

        <Spacer top={20} />

        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Quando</Description>
              </td>

              <td className="second-column">
                <Description>Agora</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Tipo de transferência</Description>
              </td>

              <td className="second-column">
                <Description>PIX</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Instituição</Description>
              </td>

              <td className="second-column">
                <Description>
                  {
                    dataNewPixTransfer.data.data.bank_accounts[0].bank_data
                      ?.description
                  }
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Agência</Description>
              </td>

              <td className="second-column">
                <Description>
                  {dataNewPixTransfer?.data?.data?.bank_accounts[0]?.agency}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Conta</Description>
              </td>

              <td className="second-column">
                <Description>{`${dataNewPixTransfer?.data?.data?.bank_accounts[0]?.account_number}-${dataNewPixTransfer?.data?.data?.bank_accounts[0]?.account_number_digit}`}</Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">CPF/CNPJ</Description>
              </td>

              <td className="second-column">
                <Description>{`${formatCPFCNPJ(
                  dataNewPixTransfer.data.data.document_number
                )}`}</Description>
              </td>
            </tr>
          </tbody>
        </table>
        <Spacer bottom={20} />
        <Input
          type="textarea"
          label="Descrição"
          name="description"
          value={values.description}
          onChange={handleChange}
        />
        <Spacer bottom={70} />

        <Spacer top={40} />
        <ContainerBottom>
          <div className="d-flex justify-content-between">
            <Button mode="phantom" label="Cancelar" onClick={handlePrevStep} />
            <Button
              mode="main"
              label="Transferir agora"
              onClick={handleClick}
              disabled={insufficientBalance}
            />
          </div>
        </ContainerBottom>
      </Container>
    </Fragment>
  );
};

export default ManualPixSend;
