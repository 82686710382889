import {
  Fragment,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import * as Yup from "yup";
import {
  Button,
  CustomSelect,
  Dropzone,
  InteractiveModal,
  NotControlledToolTip,
  Spacer,
} from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { REQUIRED_LABEL } from "~/services/validators";
import useSignupCompany from "~/hooks/Signup/useSignupCompany";
import AppStorage from "~/services/storage";
import {
  PaginatedResponse,
  PartnerDocumentResponse,
  RegisteredPartner,
} from "~/types";
import ApiService from "~/services/api";
import { CustomSelectValue } from "~/services/utils";
import { useNavigate } from "react-router-dom";
import useToast from "~/hooks/useToast";
import OpenAccordionIcon from "~/assets/icons/Actions/Chevron.png";
import CloseAccordionIcon from "~/assets/icons/Actions/x-circle.png";
import { ImgDelete } from "~/pages/App/Permissions/NewConcessionModal";
import theme from "~/themes/theme";
import { ImgEye } from "~/pages/App/app-content.styles";
import EyeImg from "~/assets/icons/eye-Size=MD.svg";
import DeleteIcon from "~/assets/icons/delete.png";
import { TitleModal } from "~/pages/Authentication/Auth.styles";
import Badge from "~/components/Badge";
import DraggableModal from "~/components/DraggeableModal";

const SlideTitle = styled.span`
  text-align: left;
  font: normal normal normal 18px/29px Proxima Nova;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
`;

const AttachmentTitle = styled.span`
  text-align: left;
  font: normal normal normal 18px/29px Proxima Nova;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
`;

const AttachmentDescription = styled.span`
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

const Container = styled.div<AttachamentsProps>`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 90%;
  padding: ${({ open }) => (open ? "0 30px 20px 30px" : "0 30px")};
`;

const ToggleButton = styled.button<AttachamentsProps>`
  background-color: #f2f6f9;
  width: 34px;
  height: 34px;
  color: #000;
  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.3s;

  &:hover {
    color: #000;
    background-color: #f2f6f9;
  }
`;

const ContainerAttachaments = styled.div<AttachamentsProps>`
  height: ${({ open }) => (open ? "auto" : "0")};
  pointer-events: ${({ open }) => (open ? "all" : "none")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  transition: 0.3s;
  overflow: ${({ open }) => (open ? "visible" : "hidden")};
`;
const ContainerDocs = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin-top: 10px;
  border-radius: 8px;
  background-color: ${theme.colors.base.light200};
`;

const ImgX = styled.img<AttachamentsProps>``;

type AttachamentsProps = HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
};

type Attachments = {
  type: string;
  label: string;
  description?: string;
};

type FileAttachment = Attachments & {
  status: string;
  file: any;
  uploaded_files?: any[];
};

type PartnerAttachments = RegisteredPartner & {
  opened: boolean;
  selected_doc: string;
  attachments: FileAttachment[];
};

type NamedPartnerDocuments = { [key: number]: PartnerDocumentResponse[] };

const Attachments = (props: AttachamentsProps) => {
  const { showToast } = useToast();
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);

  const [openSendingDocumentModal, setOpenSendingDocumentModal] =
    useState<boolean>(false);

  const [allDocumentsCompany, setAllDocumentsCompany] = useState<any>([]);
  const [allDocumentsRepresentatives, setAllDocumentsRepresentatives] =
    useState<any>([]);

  const {
    setStep,
    setValid,
    setSending,
    setCanAdvance,
    updatePartnerStep,
    getPartner,
    partnerCompany,
  } = useSignupCompany();

  const [representatives, setRepresentatives] = useState<RegisteredPartner[]>(
    []
  );

  const [showingFile, setShowingFile] = useState<string | undefined>(undefined);
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] =
    useState<boolean>(false);
  const [dataDocumentToDelete, setDataDocumentToDelete] = useState<any>({});
  const [loadingDeleteDocument, setLoadingDeleteDocument] =
    useState<boolean>(false);
  const [updateDocsRepresentatives, setUpdateDocsRepresentatives] =
    useState<boolean>(false);

  const [associated, setAssociated] = useState<number | null>(null);

  const [partnerDocuments, setPartnerDocuments] = useState<
    PartnerDocumentResponse[]
  >([]);

  const [partnersDocuments, setPartnersDocuments] =
    useState<NamedPartnerDocuments>({});

  const [representativesAttachments, setRepresentativesAttachments] = useState<
    PartnerAttachments[]
  >([]);

  const [partnerAttachments, setPartnerAttachments] =
    useState<PartnerAttachments>();

  const [send, setSend] = useState<boolean>(false);

  const newPartnerSaved: any = localStorage.getItem("new_company");
  const newPartnerSavedConverted = JSON.parse(newPartnerSaved);

  console.log(representativesAttachments[2]);

  const docs_status = {
    waiting_send: "Aguardando Envio",
    internal_validation: "Em Validação Interna",
    validated_internally: "Validado internamente",
    analyze: "Em Análise no Provedor",
    recused: "Recusado",
    error: "Erro no Processamento",
    done: "Concluído",
  };

  const DocumentTypeSelectOptions: { value: string; label: string }[] = [
    { value: "rg", label: "RG" },
    { value: "cnh", label: "CNH" },
  ];

  const filterDocs: { [key: string]: string[] } = {
    cnh: ["front_document", "back_document"],
    rg: ["cnh_front", "cnh_back"],
  };

  const company_required_docs = useMemo((): Attachments[] => {
    const registeredPartner = AppStorage.getRegisteredPartner();

    if (registeredPartner) {
      const base_required_docs: Attachments[] = [
        {
          type: "proof_residence",
          label: "Comprovante de Endereço",
          description: "Conta de água, luz, telefone ou gás",
        },
        {
          type: "cnpj",
          label: "Cartão CNPJ",
          description: "Insira o cartão CNPJ da empresa",
        },
        {
          type: "social_contract",
          label: "Contrato Social",
          description: "Insira o contrato social da empresa",
        },
      ];

      if (
        ["ltda", "sa", "eireli"].includes(registeredPartner.additional_type)
      ) {
        base_required_docs.push({
          type: "proof_annual_billing_pj",
          label: "Comprovante de faturamento anual PJ",
          description: "Insira o comprovante de faturamento anual PJ",
        });
      }

      if (["eireli"].includes(registeredPartner.additional_type)) {
        base_required_docs.push({
          type: "constitutive_act",
          label: "Ato Constitutivo",
          description: "Insira o documento de Ato Constitutivo",
        });
      }

      if (["fidc"].includes(registeredPartner.additional_type)) {
        base_required_docs.push(
          {
            type: "funds_regulating",
            label: "Regulamentação de fundos",
            description: "Insira o documento regulatório de fundos",
          },
          {
            type: "fund_bylaws",
            label: "Estatuto do fundo",
            description: "Insira o documento do estatuto do fundo",
          }
        );
      }

      return base_required_docs;
    }

    return [];
  }, []);

  const labelDocsDict: { [key: string]: string } = {
    cpf: "CPF",
    front_document: "RG (Frente)",
    back_document: "RG (Verso)",
    cnh_front: "CNH (Frente)",
    cnh_back: "CNH (Verso)",
    proof_residence: "Comprovante de Endereço",
    cnpj: "Cartão CNPJ",
    social_contract: "Contrato Social",
    proof_annual_billing_pj: "Comprovante de Faturamento Anual PJ",
    funds_regulating: "Regulamentação de Fundos",
    fund_bylaws: "Estatuto do Fundo",
    constitutive_act: "Ato Constitutivo",
  };

  const representative_required_docs = useMemo(() => {
    const base_required_docs: Attachments[] = [
      {
        type: "cnh_front",
        label: "CNH Frente",
        description: "Insira a frente da CNH do representante",
      },

      {
        type: "cnh_back",
        label: "CNH Verso",
        description: "Insira o verso da CNH do representante",
      },
      {
        type: "front_document",
        label: "RG Frente",
        description: "Insira a frente do RG do representante",
      },
      {
        type: "back_document",
        label: "RG Verso",
        description: "Insira o verso do RG do representante",
      },
      {
        type: "cpf",
        label: "CPF",
        description:
          "Insira o CPF do representante, caso tenha selecionado CNH, poderá reenviar",
      },
      {
        type: "proof_residence",
        label: "Comp. de Residência",
        description: "Conta de água, luz, telefone ou gás",
      },
    ];

    return base_required_docs;
  }, []);

  // const sendPartnerAttachments = async () => {
  //   try {
  //     // const requests = attachmentsToSend.attachments.map(
  //     //   sendPartnerAttachment(attachmentsToSend.id, )
  //     // );

  //     const responses = await Promise.all("requests");

  //     return responses;
  //   } catch (error) {
  //     console.error(error);
  //     return null;
  //   }
  // };

  // const sendAttachments = async (
  //   partner_attachments: PartnerAttachments,
  //   representative_attachments: PartnerAttachments[]
  // ) => {
  //   setSend(false);
  //   setSending(true);

  //   try {
  //     const allCompanySended = partner_attachments.attachments.every(
  //       (attachments) => {
  //         if (attachments.file) {
  //           return true;
  //         }

  //         if (attachments.uploaded_files) {
  //           return attachments.uploaded_files.length > 0;
  //         }

  //         return false;
  //       }
  //     );

  //     if (!allCompanySended) {
  //       throw "Envie todos os documentos da empresa para continuar";
  //     }

  //     const allRepresentativeSended = representative_attachments.some((rep) => {
  //       /* Verify if all docs has sended */
  //       const validAttachments = rep.attachments?.filter(
  //         (doc) => !filterDocs[rep.selected_doc].includes(doc.type)
  //       );

  //       return validAttachments.every((attachments) => {
  //         if (attachments.file) {
  //           return true;
  //         }

  //         if (attachments.uploaded_files) {
  //           return attachments.uploaded_files.length > 0;
  //         }

  //         return false;
  //       });
  //     });

  //     if (!allRepresentativeSended) {
  //       throw "Envie todos os documentos dos representantes para continuar";
  //     }

  //     const registeredToken = AppStorage.getRegisteredToken();

  //     const customHeaders = {
  //       Authorization: `Token ${registeredToken}`,
  //     };

  //     const allAttachments: PartnerAttachments[] = [
  //       ...representative_attachments,
  //     ];

  //     allAttachments.unshift(partner_attachments);

  //     const reqs = await allAttachments.map(sendPartnerAttachments);

  //     await Promise.all(reqs);

  //     const documentsCheck = await ApiService.HttpGet({
  //       route: "partner-documents-check/",
  //       customHeaders,
  //     });

  //     if (!documentsCheck.data.success) {
  //       showToast("Erro!", documentsCheck.data.message, "error");
  //       return;
  //     }

  //     await ApiService.HttpPost({
  //       route: "partner-sync/",
  //       customHeaders,
  //     });

  //     await ApiService.HttpPost({
  //       route: "submit-review/",
  //       customHeaders,
  //     });

  //     await updatePartnerStep("review", registeredToken);

  //     setStep("done");
  //     navigate("/signup/company/done");

  //     showToast(
  //       "Sucesso!",
  //       "Documentos finalizados! sua conta entrará em aprovação, lhe informamos via e-mail",
  //       "success"
  //     );

  //     setSending(false);
  //   } catch (error) {
  //     showToast("Erro", String(error), "error");
  //   }

  //   setSending(false);
  // };

  const handleSignupCompanySubmit = async () => {
    setSend(true);
  };

  const setPartnerAttachment = useCallback(
    (partner_documents: PartnerDocumentResponse[]) => {
      const registeredPartner = AppStorage.getRegisteredPartner();

      if (registeredPartner) {
        const mapped_partner: PartnerAttachments = {
          ...registeredPartner,
          opened: false,
          selected_doc: "",
          attachments: company_required_docs.map((doc) => ({
            ...doc,
            status: docs_status.waiting_send,
            file: null,
            uploaded_files: partner_documents
              ? partner_documents
                  ?.filter((partnerDoc) => partnerDoc.type === doc.type)
                  .map((partnerDocs) => ({
                    name: doc.label,
                    url: partnerDocs.file,
                  }))
              : [],
          })),
        };

        setPartnerAttachments(mapped_partner);
      }
    },
    [company_required_docs, setPartnerAttachments]
  );

  const setRepresentativeAttachment = useCallback(
    (partner_documents: NamedPartnerDocuments) => {
      const partners = representatives;

      const mapped_partners: PartnerAttachments[] = [];

      partners.forEach((partner) => {
        const partnerDocs = partner_documents[partner.id];

        const mapped_payload = {
          ...partner,
          opened: false,
          selected_doc: "rg",
          attachments: representative_required_docs.map((doc) => ({
            ...doc,
            status: docs_status.waiting_send,
            file: null,
            uploaded_files: partnerDocs
              ? partnerDocs
                  ?.filter((partnerDoc) => partnerDoc.type === doc.type)
                  .map((partnerDocs) => ({
                    name: doc.label,
                    url: partnerDocs.file,
                  }))
              : [],
          })),
        };

        mapped_partners.push(mapped_payload);
      });

      setRepresentativesAttachments(mapped_partners);
    },
    [
      representative_required_docs,
      representatives,
      setRepresentativesAttachments,
    ]
  );

  const handleCloseModal = useCallback(() => {
    setOpenDeleteDocumentModal(false);
    setDataDocumentToDelete({});
  }, []);

  const getDocumentsCompany = useCallback(async () => {
    try {
      const registeredToken = AppStorage.getRegisteredToken();

      const {
        data: { results },
      } = await ApiService.HttpGet<PaginatedResponse<PartnerDocumentResponse>>({
        route: "partner-documents/",

        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });
      setAllDocumentsCompany(results);
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [setAllDocumentsCompany]);

  const getDocumentsRepresentatives = useCallback(
    async (associated: number | string | null) => {
      try {
        const registeredToken = AppStorage.getRegisteredToken();

        const {
          data: { results },
        } = await ApiService.HttpGet<
          PaginatedResponse<PartnerDocumentResponse>
        >({
          route: "partner-documents/",
          params: { associated },
          customHeaders: {
            Authorization: `Token ${registeredToken}`,
          },
        });

        return results;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    []
  );

  const sendPartnerAttachment = useCallback(
    async (
      partner_id: number,
      attachmentToSend: any,
      documentType: string,
      associated?: number | string
    ) => {
      setOpenSendingDocumentModal(true);
      const registeredToken = AppStorage.getRegisteredToken();
      try {
        if (attachmentToSend) {
          const customHeaders = {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${registeredToken}`,
          };

          const formData = new FormData();
          formData.append("file", attachmentToSend);
          formData.append("partner", String(partner_id));
          formData.append("type", documentType);
          formData.append("description", "Cadastro");

          await ApiService.HttpPost({
            route: "partner-documents/",
            customHeaders,
            body: formData,
          });
        }

        if (associated) {
          if (representatives.length > 0) {
            const idsRepresentatives = representatives.map(
              (representative: any) => representative.id
            );

            if (idsRepresentatives.length > 0) {
              Promise.all(
                idsRepresentatives?.map((id) => getDocumentsRepresentatives(id))
              )
                .then((results) => {
                  const validResults: any = results.filter(
                    (result) => result !== null
                  );

                  setAllDocumentsRepresentatives(validResults);
                  setUpdateDocsRepresentatives(false);
                })
                .catch((error) => console.error(error));
              setUpdateDocsRepresentatives(false);
            }
          }
        } else {
          getDocumentsCompany();
        }

        setOpenSendingDocumentModal(false);
        showToast(`Documento enviado com sucesso!`, "", "success");
      } catch (error) {
        console.error(error);
        setOpenSendingDocumentModal(false);
        showToast(`Ocorreu um erro!`, "", "success");
        return null;
      }
    },
    [
      getDocumentsCompany,
      getDocumentsRepresentatives,
      representatives.length,
      setAllDocumentsRepresentatives,
      setUpdateDocsRepresentatives,
      showToast,
      setOpenSendingDocumentModal,
    ]
  );

  const deleteDocument = useCallback(
    async (data: any, associated?: string | number | null) => {
      setLoadingDeleteDocument(true);
      const registeredToken = AppStorage.getRegisteredToken();

      const customHeaders = {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${registeredToken}`,
      };

      try {
        if (associated && associated !== null) {
          await ApiService.HttpDelete({
            route: `partner-documents/${data.id}/`,
            params: { associated },
            customHeaders,
          });

          setLoadingDeleteDocument(false);
          handleCloseModal();
          await getDocumentsRepresentatives(associated);

          if (representatives.length > 0) {
            const idsRepresentatives = representatives.map(
              (representative: any) => representative.id
            );

            if (idsRepresentatives.length > 0) {
              Promise.all(
                idsRepresentatives?.map((id) => getDocumentsRepresentatives(id))
              )
                .then((results) => {
                  const validResults: any = results.filter(
                    (result) => result !== null
                  );

                  setAllDocumentsRepresentatives(validResults);
                  setUpdateDocsRepresentatives(false);
                })
                .catch((error) => console.error(error));
              setUpdateDocsRepresentatives(false);
            }
          }

          showToast(
            `${labelDocsDict[data.type]} excluído com sucesso!`,
            "",
            "success"
          );
          setAssociated(null);
          setTimeout(() => {
            setLoadingDeleteDocument(false);
          }, 2500);
        } else {
          await ApiService.HttpDelete({
            route: `partner-documents/${data.id}/`,

            customHeaders,
          });

          setLoadingDeleteDocument(false);
          handleCloseModal();
          getDocumentsCompany();
          showToast(
            `${labelDocsDict[data.type]} excluído com sucesso!`,
            "",
            "success"
          );

          setTimeout(() => {
            setLoadingDeleteDocument(false);
          }, 2500);
        }
      } catch (err) {
        handleCloseModal();
        setLoadingDeleteDocument(false);
        showToast(
          "Ocorreu um erro",
          "Não foi possível excluir o documento, tente mais tarde.",
          "error"
        );
      }
    },
    [
      getDocumentsCompany,
      handleCloseModal,
      showToast,
      setLoadingDeleteDocument,
      representatives,
    ]
  );

  const fetchRepresentatives = useCallback(async () => {
    try {
      const registeredToken = AppStorage.getRegisteredToken();

      const { data } = await ApiService.HttpGet({
        route: "partner-representatives/",

        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });

      setRepresentatives(data);
    } catch (e) {
      showToast("Erro", "Erro ao recuperar representantes", "error");
    }
  }, [setRepresentatives, showToast]);

  const allRequiredCompanyDocs = useMemo(() => {
    const requiredTypes = Array.from(
      new Set(
        partnerAttachments?.attachments.map((attachment) => attachment.type)
      )
    );
    const companyTypes = Array.from(
      new Set(allDocumentsCompany.map((document: any) => document.type))
    );

    return requiredTypes?.every((type: any) => companyTypes.includes(type));
  }, [allDocumentsCompany, partnerAttachments]);

  const documentToDeleteCompany = useCallback(
    (type: string) => {
      const docToDelete = allDocumentsCompany?.filter(
        (doc: any) => doc.type === type
      );

      setDataDocumentToDelete(docToDelete);
      setOpenDeleteDocumentModal(true);
    },
    [allDocumentsCompany, setOpenDeleteDocumentModal, setDataDocumentToDelete]
  );

  const documentToDeleteRepresentative = useCallback(
    (doc: any, associated: number) => {
      setDataDocumentToDelete([doc]);
      setAssociated(associated);
      setOpenDeleteDocumentModal(true);
    },
    [
      allDocumentsRepresentatives,
      setOpenDeleteDocumentModal,
      setDataDocumentToDelete,
    ]
  );

  const viewDocumentCompany = useCallback(
    (type: string) => {
      const docToView = allDocumentsCompany?.filter(
        (doc: any) => doc.type === type
      );

      if (docToView && docToView.length > 0) {
        setShowingFile(docToView[0].file);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    [allDocumentsCompany, setShowingFile]
  );

  const viewDocumentRepresentative = useCallback(
    (type: string, index: number) => {
      const docToView = allDocumentsRepresentatives[index]?.filter(
        (doc: any) => doc.type === type
      );

      if (docToView && docToView.length > 0) {
        setShowingFile(docToView[0].file);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    [allDocumentsRepresentatives, setShowingFile]
  );

  const documentCompanyExists = useCallback(
    (type: string) =>
      allDocumentsCompany?.some((doc: any) => doc.type === type),
    [allDocumentsCompany]
  );

  const docsRepresentativesRequired: string[] = useMemo(() => {
    return [
      "proof_residence",
      "cpf",
      "cnh_front",
      "front_document",
      "cnh_back",
      "back_document",
    ];
  }, []);

  const equivalentTypes: { [key: string]: string } = useMemo(() => {
    return {
      cnh_front: "front_document",
      cnh_back: "back_document",
      front_document: "cnh_front",
      back_document: "cnh_back",
    };
  }, []);

  const allRequiredRepresentativeDocs = useCallback(
    (index: number) => {
      const docs = allDocumentsRepresentatives[index] || [];

      const requiredTypes = Array.from(new Set(docsRepresentativesRequired));
      const representativeTypes = new Set(
        docs.map((document: any) => document.type)
      );

      // const hasCnhFront = Array.from(representativeTypes).includes("cnh_front");
      // const hasCnhBack = Array.from(representativeTypes).includes("cnh_back");
      // console.log(representativeTypes.has("cnh_front"));

      // if (hasCnhFront || hasCnhBack) {
      //   console.log("o index", index, "tem cnh");
      // }
      const isRequiredTypePresent = (type: string) => {
        return (
          representativeTypes.has(type) ||
          (equivalentTypes[type] &&
            representativeTypes.has(equivalentTypes[type]))
        );
      };

      const allTypesPresent = requiredTypes.every(isRequiredTypePresent);

      return allTypesPresent;
    },
    [allDocumentsRepresentatives, docsRepresentativesRequired, equivalentTypes]
  );

  const allRequiredAllRepresentativesDocs = useMemo(() => {
    const statusDocsAllRepresentatives = representatives?.map((_, index) =>
      allRequiredRepresentativeDocs(index)
    );

    const hasDocsIncompletes = statusDocsAllRepresentatives?.includes(false);

    return !hasDocsIncompletes;
  }, [allDocumentsRepresentatives]);

  useEffect(() => {
    getPartner();
    getDocumentsCompany();

    window.addEventListener("signupCompanySubmit", handleSignupCompanySubmit);
    fetchRepresentatives();
    setValid(true);
    setCanAdvance(false);
    setSending(false);

    setStep("attachments");

    return () => {
      window.removeEventListener(
        "signupCompanySubmit",
        handleSignupCompanySubmit
      );
    };
  }, []);

  useEffect(() => {
    if (representatives.length > 0) {
      setRepresentativeAttachment(partnersDocuments);
    }
  }, [representatives, partnerDocuments]);

  useEffect(() => {
    if (representatives.length > 0) {
      const idsRepresentatives = representatives.map(
        (representative: any) => representative.id
      );

      if (idsRepresentatives.length > 0) {
        Promise.all(
          idsRepresentatives?.map((id) => getDocumentsRepresentatives(id))
        )
          .then((results) => {
            const validResults: any = results.filter(
              (result) => result !== null
            );

            setAllDocumentsRepresentatives(validResults);
            setUpdateDocsRepresentatives(false);
          })
          .catch((error) => console.error(error));
        setUpdateDocsRepresentatives(false);
      }
    }
  }, [representatives]);

  useEffect(() => {
    setPartnerAttachment(partnerDocuments);
  }, [partnerDocuments]);

  useEffect(() => {
    if (allRequiredCompanyDocs && allRequiredAllRepresentativesDocs) {
      setCanAdvance(true);
    } else {
      setCanAdvance(false);
    }
  }, [allDocumentsCompany, allDocumentsRepresentatives]);

  // useEffect(() => {
  //   if (send && partnerAttachments) {
  //     sendAttachments(partnerAttachments, representativesAttachments);
  //   }
  // }, [send, partnerAttachments, representativesAttachments]);

  return (
    <Fragment>
      <div className="row">
        {" "}
        <div className="col-12 col-xxl-6">
          {" "}
          <Spacer bottom={50} />
          <Title>Agora é hora de comprovar as informações inseridas</Title>
          <Spacer bottom={32} />
          <div>
            <Container
              open={opened}
              className="d-flex flex-column justify-content-between align-items-start "
            >
              <div className="d-flex  w-100 justify-content-between align-items-center pt-3 pb-3">
                <div className="d-flex align-items-center">
                  <Badge
                    label={allRequiredCompanyDocs ? "Concluído" : "Pendente"}
                    color={allRequiredCompanyDocs ? "#CCEFDF" : "#FEE7CC"}
                    textColor={allRequiredCompanyDocs ? "#00B360" : "#FC8802"}
                  />{" "}
                  <Spacer left={10} />
                  <SlideTitle>Documentos da empresa</SlideTitle>
                </div>

                <ToggleButton type="button" onClick={() => setOpened(!opened)}>
                  <ImgX src={opened ? CloseAccordionIcon : OpenAccordionIcon} />
                </ToggleButton>
              </div>

              <ContainerAttachaments open={opened} className="row">
                <>
                  {partnerAttachments?.attachments.map((doc, docIndex) => (
                    <Fragment key={doc.type}>
                      <>
                        <div className="row mt-5 d-flex justify-content-between">
                          <div className="ms-xl-2 ms-0  col-12 d-flex flex-column">
                            <AttachmentTitle>{doc.label}</AttachmentTitle>
                            <div>
                              <AttachmentDescription>
                                {doc.description}
                              </AttachmentDescription>
                            </div>
                          </div>

                          <div className=" col-12 d-flex justify-content-end mt-2 mt-xxl-0">
                            <div className="col-12 d-flex justify-content-end flex-column">
                              <Dropzone
                                notShowFile={true}
                                helper="O arquivo deve estar no formato PDF, JPG ou PNG"
                                onFilesChanged={(files) => {
                                  sendPartnerAttachment(
                                    newPartnerSavedConverted?.id ??
                                      partnerCompany.id,
                                    files[0],
                                    doc.type
                                  );
                                }}
                                uploadedFiles={doc.uploaded_files}
                                multiple={false}
                                accept={{
                                  images: ["image/png", "image/jpeg"],
                                  pdf: ["application/pdf"],
                                }}
                              />
                              <div>
                                {documentCompanyExists(doc.type) && (
                                  <>
                                    {" "}
                                    <ContainerDocs>
                                      <span> {labelDocsDict[doc.type]}</span>
                                      <div className="d-flex">
                                        <ImgEye
                                          id={`view`}
                                          src={EyeImg}
                                          onClick={() =>
                                            viewDocumentCompany(doc.type)
                                          }
                                        />
                                        <NotControlledToolTip target={`view`}>
                                          Visualizar
                                        </NotControlledToolTip>
                                        <Spacer left={10} />

                                        <ImgDelete
                                          id={`delete`}
                                          src={DeleteIcon}
                                          onClick={() => {
                                            documentToDeleteCompany(doc.type);
                                          }}
                                        />
                                        <NotControlledToolTip target={`delete`}>
                                          Excluir
                                        </NotControlledToolTip>
                                      </div>
                                    </ContainerDocs>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <Spacer bottom={32} />
                      </>
                    </Fragment>
                  ))}
                </>
              </ContainerAttachaments>
            </Container>

            {representativesAttachments.map((representative, index) => (
              <Container
                key={String(index)}
                open={representative.opened}
                className="d-flex flex-column justify-content-between align-items-start "
              >
                <div className="d-flex  w-100 justify-content-between align-items-center pt-3 pb-3">
                  <div className="d-flex align-items-center">
                    <Badge
                      label={
                        allRequiredRepresentativeDocs(index)
                          ? "Concluído"
                          : "Pendente"
                      }
                      color={
                        allRequiredRepresentativeDocs(index)
                          ? "#CCEFDF"
                          : "#FEE7CC"
                      }
                      textColor={
                        allRequiredRepresentativeDocs(index)
                          ? "#00B360"
                          : "#FC8802"
                      }
                    />
                    <Spacer left={10} />{" "}
                    <SlideTitle>
                      Documentos de {representative?.name}
                    </SlideTitle>
                  </div>

                  <ToggleButton
                    type="button"
                    onClick={() => {
                      const newRepresentativesAttachments = [
                        ...representativesAttachments,
                      ];
                      newRepresentativesAttachments[index].opened =
                        !representative.opened;
                      setRepresentativesAttachments(
                        newRepresentativesAttachments
                      );
                    }}
                  >
                    <ImgX
                      open={representative.opened}
                      src={
                        representative.opened
                          ? CloseAccordionIcon
                          : OpenAccordionIcon
                      }
                    />
                  </ToggleButton>
                </div>

                <ContainerAttachaments
                  open={representative.opened}
                  className="row"
                >
                  <div className=" col-12">
                    <CustomSelect
                      label="Tipo de documento"
                      options={DocumentTypeSelectOptions}
                      value={CustomSelectValue(
                        allDocumentsRepresentatives[index]?.some(
                          (attachment: any) =>
                            attachment.type === "cnh_front" ||
                            attachment.type === "cnh_back"
                        )
                          ? "cnh"
                          : "rg",
                        DocumentTypeSelectOptions
                      )}
                      onChange={({ target: { value } }) => {
                        const newRepresentativesAttachments = [
                          ...representativesAttachments,
                        ];
                        console.log(allDocumentsRepresentatives[index]);
                        console.log(representative);
                        newRepresentativesAttachments[index].selected_doc =
                          value;
                        setRepresentativesAttachments(
                          newRepresentativesAttachments
                        );
                      }}
                    />
                  </div>

                  {representative.attachments
                    ?.filter(
                      (doc) =>
                        !filterDocs[representative.selected_doc].includes(
                          doc.type
                        )
                    )
                    .map((doc, docIndex) => (
                      <Fragment key={String(docIndex)}>
                        <div className="row mt-5 d-flex justify-content-between">
                          <div className="ms-xl-2 ms-0   col-12 d-flex flex-column ">
                            <AttachmentTitle>{doc.label}</AttachmentTitle>
                            <div>
                              <AttachmentDescription>
                                {doc.description}
                              </AttachmentDescription>
                            </div>
                          </div>

                          <div className=" col-12 d-flex justify-content-end mt-2 mt-xxl-0 ">
                            <div className="col-12  d-flex justify-content-end ">
                              <Dropzone
                                notShowFile={true}
                                helper="O arquivo deve estar no formato PDF, JPG ou PNG"
                                onFilesChanged={(files) => {
                                  sendPartnerAttachment(
                                    representative.id,
                                    files[0],
                                    doc.type,
                                    representative.id
                                  );
                                }}
                                uploadedFiles={doc.uploaded_files}
                                multiple={false}
                                accept={{
                                  images: ["image/png", "image/jpeg"],
                                  pdf: ["application/pdf"],
                                }}
                              />
                            </div>
                          </div>
                          {allDocumentsRepresentatives?.[index]
                            ?.filter((docs: any) => docs.type === doc.type)
                            ?.map((docs: any, docIndex: number) => (
                              <div key={docIndex}>
                                {" "}
                                <ContainerDocs>
                                  <span> {labelDocsDict[docs.type]}</span>
                                  <div className="d-flex">
                                    <ImgEye
                                      id={`view`}
                                      src={EyeImg}
                                      onClick={() =>
                                        viewDocumentRepresentative(
                                          docs.type,
                                          index
                                        )
                                      }
                                    />
                                    <NotControlledToolTip target={`view`}>
                                      Visualizar
                                    </NotControlledToolTip>
                                    <Spacer left={10} />

                                    <ImgDelete
                                      id={`delete`}
                                      src={DeleteIcon}
                                      onClick={() => {
                                        documentToDeleteRepresentative(
                                          docs,
                                          representative.id
                                        );
                                      }}
                                    />
                                    <NotControlledToolTip target={`delete`}>
                                      Excluir
                                    </NotControlledToolTip>
                                  </div>
                                </ContainerDocs>
                              </div>
                            ))}
                        </div>

                        <Spacer bottom={32} />
                      </Fragment>
                    ))}
                </ContainerAttachaments>
              </Container>
            ))}
          </div>
        </div>
        <div className="col-xxl-6 col-12">
          {" "}
          <Spacer bottom={62} />
          <Title
            className="text-center"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            Como enviar os documentos corretamente
          </Title>
          <Spacer bottom={32} />{" "}
          <p>
            Alguns cuidados são necessários na hora de enviar os seus
            documentos. Por exemplo:
          </p>
          <p>• Deve ser original, não sendo permitidas cópias ou rasuras;</p>
          <p>• Deve estar dentro do prazo de validade;</p>
          <p>
            • Não serão aceitos documentos digitalizados, autenticados, copiados
            ou serigrafados;
          </p>
          <p>
            • Ao fotografar os documentos, você deve prestar atenção à
            iluminação ambiente e à qualidade da imagem (certifique-se de que a
            documentação não esteja cortada ou com pouca nitidez);
          </p>
          <p>• Deve ficar fora da tampa plástica;</p>
          <p>• Deve estar em bom estado: sem danos, rasgos ou amassados;</p>
          <p>
            • No caso de documentos digitais é necessário enviar o arquivo
            extraído do portal;
          </p>
          <p>
            • Documentos digitais, como a versão digital da Carteira Nacional de
            Habilitação, deverão possuir o QR Code para validação;
          </p>
        </div>
      </div>

      <InteractiveModal
        size="md"
        toggleModal={() => null}
        open={openSendingDocumentModal}
      >
        <div className="p-4 h-100 d-flex h-100 justify-content-center align-items-center w-100   flex-column">
          <Spacer bottom={120} />
          <div
            className="spinner-border mb-2"
            role="status"
            style={{ color: theme.colors.primary.main }}
          ></div>
          <Spacer top={20} />
          <div className="text-center">Enviando documento...</div>
        </div>
      </InteractiveModal>
      <InteractiveModal
        size="md"
        toggleModal={handleCloseModal}
        open={openDeleteDocumentModal}
      >
        <div className="p-4">
          <TitleModal className="text-center">
            Deseja excluir o documento{" "}
            {labelDocsDict[dataDocumentToDelete[0]?.type]} ?
          </TitleModal>
          <Spacer bottom={150} />
          <Button
            mode="main"
            label="Não, cancelar"
            full
            onClick={handleCloseModal}
          />
          <Spacer bottom={10} />
          <Button
            mode="border"
            label="Sim, excluir"
            full
            onClick={() => deleteDocument(dataDocumentToDelete[0], associated)}
            loading={loadingDeleteDocument}
          />
        </div>
      </InteractiveModal>

      <DraggableModal
        title="Visualização de documento"
        isOpen={showingFile != undefined}
        onClose={() => setShowingFile(undefined)}
      >
        <iframe
          style={{ width: "100%", height: "150%" }}
          src={showingFile}
        ></iframe>
      </DraggableModal>
    </Fragment>
  );
};

export default Attachments;
