import constate from "constate";
import { useState } from "react";

const [NotificationsProvider, useNotifications] = constate(() => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [hasNewNotification, setHasNewNotification] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);

  return {
    notifications,
    setNotifications,
    hasNewNotification,
    setHasNewNotification,
    showNotifications,
    setShowNotifications,
  };
});

export { NotificationsProvider };

export default useNotifications;
