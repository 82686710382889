import { Button, Input, RadioGroup, Spacer } from "@zilliondigital/mirage-ui";

import useCollections from "~/hooks/Collections/useCollections";
import useAddBalance from "~/hooks/useAddBalance";
import useSplit from "~/hooks/useSplit";
import Description from "~/components/Texts/Description";
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RadioOptions } from "~/types";
import theme from "~/themes/theme";

type SplitValue = {
  name: string;
};

const initialValues: SplitValue = {
  name: "",
};

const FormSchema = Yup.object().shape({
  name: Yup.string(),
});

const SelectTypeToTransfer = () => {
  const { isEmpty, setIsEmpty } = useAddBalance();

  const {
    openNewSplitModal,
    stepIndex,
    setStepIndex,
    selectedValues,
    setSelectedValue,
    setNewPartnerSplitData,
    newPartnerSplitData,
    setReceivers,
    receivers,

    newSplitPayload,
  } = useSplit();

  const handleFormSubmit = (values: SplitValue) => {
    return;
  };

  const { handleChange, setFieldValue, values, resetForm } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleFormSubmit,
  });

  const radioOptions: RadioOptions[] = [
    {
      title: "PIX",
      description:
        "Transferência a qualquer hora e dia da semana, de forma instantânea.",
      value: "1",
      disabled: newSplitPayload?.split_operation_mode === "fitbank",
    },
    {
      title: "TEV",
      description: "Transferência entre contas bancárias da mesma instituição.",
      value: "2",
      disabled: newPartnerSplitData?.bank_code !== "450",
    },
  ];

  const handleAdvance = () => {
    setReceivers((prevValues: any) => {
      const updatedReceivers = [
        ...prevValues,
        { ...newPartnerSplitData, deposit_type: selectedValues },
      ];
      return updatedReceivers;
    });

    setStepIndex(stepIndex + 1);
  };

  const handleChangeTile = (value: any) => {
    setSelectedValue(value);

    switch (value) {
      case "0":
        setSelectedValue("ted");
        break;
      case "1":
        setSelectedValue("pix_manual_transfer");
        break;
      case "2":
        setSelectedValue("tev");
        break;
      default:
    }

    setIsEmpty(false);
  };

  useEffect(() => {
    setNewPartnerSplitData((prevState: any) => ({
      ...prevState,
      deposit_type: selectedValues,
    }));
  }, [selectedValues]);

  useEffect(() => {
    if (!openNewSplitModal) {
      resetForm();
    }
  }, [openNewSplitModal]);

  useEffect(() => {
    setSelectedValue("");
  }, []);

  return (
    <>
      <Description className="mb-4">
        <b>Selecionar tipo de transferência</b>
      </Description>
      <Spacer bottom={10} />
      <RadioGroup
        emptyValue={isEmpty}
        display="row"
        options={radioOptions}
        wrap
        onChange={(value) => handleChangeTile(value)}
      />
      <Spacer bottom={20} />

      {newSplitPayload?.split_operation_mode === "liquidation" &&
        selectedValues === "ted" && (
          <p className="text-start" style={{ color: theme.colors.error.main }}>
            Caso a liquidação ocorra fora do período de TED (seg. à sex. das
            07:00h às 16:30h), o valor destinado ao beneficiário será submetido
            à aprovação (Alçadas) no dia seguinte.
          </p>
        )}

      <Spacer bottom={20} />
      <div className="d-flex w-100 justify-content-between">
        <Button
          mode="border"
          label={"Voltar"}
          onClick={() => {
            setSelectedValue("");
            setStepIndex(stepIndex - 1);
          }}
        />
        <Button
          mode="main"
          label="Avançar"
          onClick={handleAdvance}
          disabled={selectedValues === ""}
        />
      </div>
    </>
  );
};

export default SelectTypeToTransfer;
