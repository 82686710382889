import React from "react";
import Timer from "~/assets/icons/figure_3d_time.png";
import styled from "styled-components";
import useTransfers from "~/hooks/Transfers/useTransfers";
import { Spacer } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import useCollections from "~/hooks/Collections/useCollections";

const Rounded = styled.div`
  width: 96px;
  height: 96px;
  background-color: #ffe5d8;
  position: relative;

  img {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`;

const EmptyCollections = () => {
  const { isFilteredResults } = useCollections();

  return (
    <div className="w-100  pt-3  d-flex flex-column align-items-center justify content-start">
      {isFilteredResults && <Spacer bottom={50} />}
      <Rounded className="rounded-circle">
        {" "}
        <img src={Timer} />
      </Rounded>

      <Spacer bottom={isFilteredResults ? 20 : 60} />
      <Title>
        {isFilteredResults
          ? "Nenhuma cobrança encontrada"
          : "Nenhuma cobrança emitida até o momento"}
      </Title>

      <Spacer bottom={10} />
      <Description className="text-center">
        Para emitir cobrança, basta clicar em{" "}
        <span style={{ fontWeight: "bold" }}>
          {" "}
          Criar
          <br />
          nova cobrança
        </span>{" "}
        localizado no canto superior <br /> direito.
      </Description>
      <Spacer bottom={30} />
    </div>
  );
};

export default EmptyCollections;
