import {
  BottomModal,
  Button,
  CustomSelect,
  Input,
  InteractiveModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { Fragment, useEffect, useMemo, useState } from "react";
import Description from "~/components/Texts/Description";

import useToast from "~/hooks/useToast";
import * as Yup from "yup";
import { onlyNumbers } from "~/services/utils";
import { useFormik } from "formik";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import {
  CpfRegex,
  INVALID_FORMAT,
  PhoneRegex,
  REQUIRED_LABEL,
} from "~/services/validators";
import useAccount from "~/hooks/useAccount";
import ApiService from "~/services/api";
import useMyKeys from "~/hooks/PIX/useMyKeys";

import useToken from "~/hooks/useToken";
import theme from "~/themes/theme";
import TitleModal from "~/components/Texts/TitleModal";

type FormValues = {
  document_number: string;
  email: string;
  telefone: string;
  evp: string;
};

const FormSchema = Yup.object().shape({
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(CpfRegex, INVALID_FORMAT),
  email: Yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
  telefone: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(PhoneRegex, INVALID_FORMAT),
  evp: Yup.string(),
});

const AddNewKeyModal = () => {
  const { mainAccount } = useAccount();

  const {
    getPixKeys,
    openNewKeyModal,
    setOpenNewKeyModal,
    typeOfKey,
    setTypeOfKey,
    pixKeysData,
  } = useMyKeys();
  const { handleNextStep } = useTransferWithPix();

  const { showToast } = useToast();
  const [noKeySelected, setNoKeySelected] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openCreateCNPJCPFKey, setCreateCNPJCPFKey] = useState(false);

  const { sendPartnerToken, getToken } = useToken();

  const IndexesTypeOfKeyValue: any = {
    document_number: 0,
    telefone: 1,
    email: 2,
    evp: 3,
  };

  const initialValues: any = {
    document_number: mainAccount?.bank_account_data?.owner.document_number,
    email: mainAccount?.bank_account_data?.owner.email,
    telefone: mainAccount?.bank_account_data?.owner.mobile_phone,
    evp: "",
  };

  const {
    values,

    handleChange,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleNextStep();
    },
  });

  type Types = {
    [key: string]: string;
  };

  const pixKeyType = [
    { label: "CPF/CNPJ", value: "1" },
    { label: "Celular", value: "2" },
    { label: "Email", value: "3" },
    { label: "Chave aleatória", value: "4" },
  ];

  const Labels: Types = {
    document_number: " CPF/CNPJ cadastrado",
    telefone: "Celular",
    email: "Email",
  };

  const LabelsNewKey: Types = {
    document_number: " CPF/CNPJ",
    telefone: "Celular",
    email: "Email",
    evp: "Chave aleatória",
  };

  const Masks: Types = {
    document_number:
      values?.document_number?.length > 11
        ? "99.999.999/9999-99"
        : "999.999.999-99",
    telefone: "(99) 99999-9999",
  };

  const LabelsAsyncModal: Types = {
    telefone: "Celular",
    email: "Email ",
  };

  const Names: Types = {
    document_number: "document_number",
    telefone: "telefone",
    email: "email",
    evp: "evp",
  };

  const handleChangeSelect = (optionSelected: any) => {
    const option = optionSelected.target.value;

    if (option === "") {
      setTypeOfKey("");
      resetForm();
      return;
    }

    if (option === "1") {
      setTypeOfKey("document_number");
    }
    if (option === "2") {
      setTypeOfKey("telefone");
    }
    if (option === "3") {
      setTypeOfKey("email");
    }
    if (option === "4") {
      setTypeOfKey("evp");
    }
  };

  const generateRandomKey = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpPost({
        token: true,
        route: "pix/key/",
        body: {
          bank_account: "0",
          key: "",
          type: "evp",
        },
      });

      setIsLoading(false);

      setOpenNewKeyModal(false);

      showToast("Chave Criada", "Sua chave foi criada com sucesso", "success");

      setTimeout(() => {
        getPixKeys();
      }, 3000);
    } catch (e) {
      setIsLoading(false);

      setTimeout(() => {
        getPixKeys();
      }, 3000);

      console.warn(e);
      setOpenNewKeyModal(false);

      showToast("Não foi possível registrar a chave pix", "", "error");
    }
  };

  const generateKeyCNPJCPF = async () => {
    setIsLoading(true);

    try {
      const { data } = await ApiService.HttpPost({
        token: true,
        route: "pix/key/",
        body: {
          bank_account: "0",
          key: initialValues[typeOfKey],
          type: initialValues[typeOfKey].length > 13 ? "cnpj" : "cpf",
        },
      });

      setIsLoading(false);

      setOpenNewKeyModal(false);
      setCreateCNPJCPFKey(false);

      setTimeout(() => {
        getPixKeys();
      }, 4000);

      showToast("Chave criada", "Sua chave foi criada com sucesso", "success");
    } catch (e) {
      setIsLoading(false);
      setTimeout(() => {
        getPixKeys();
      }, 3000);

      setOpenNewKeyModal(false);
      showToast(
        "Ocorreu um erro",
        "Ocorreu um erro na criação da sua chave, tente novamente",
        "error"
      );
    }
  };

  const generateKeyEmailCelphone = async () => {
    setIsLoading(true);

    try {
      const { data } = await ApiService.HttpPost({
        token: true,
        route: "pix/key/",
        body: {
          bank_account: "0",

          key: values[typeOfKey],
          type: typeOfKey,
        },
      });

      const token = await getToken({
        title: `Validação de ${LabelsAsyncModal[typeOfKey]} `,
        description: `Informe o token que enviamos para o seu ${LabelsAsyncModal[typeOfKey]} que deseja criar a chave PIX`,
      });

      const confirmTokenData = await ApiService.HttpPost({
        route: `pix/key/${data?.data?.id}/confirm_hold/`,
        body: {
          code: token,
          resend_code: false,
        },
        token: true,
      });

      if (!confirmTokenData.data.data.success) {
        showToast(
          "Ocorreu um erro",
          confirmTokenData.data.data.message,
          "error"
        );
      } else {
        showToast(
          "Chave Criada",
          "Sua chave foi criada com sucesso",
          "success"
        );
      }

      setOpenNewKeyModal(false);

      setTimeout(() => {
        getPixKeys();
      }, 3000);
    } catch (e) {
      setIsLoading(false);
      console.warn(e);
      setOpenNewKeyModal(false);
      showToast("Não foi possível registrar a chave pix", "", "error");
    }

    setTimeout(() => {
      getPixKeys();
    }, 3000);
  };

  const ValidateKey = async () => {
    setIsLoading(true);
    try {
      const { data } = await ApiService.HttpGet({
        token: true,
        route: "pix/search-key-account/",
        params: {
          key: values[typeOfKey],
          type:
            typeOfKey === "document_number" && values[typeOfKey].length > 15
              ? "cnpj"
              : typeOfKey === "document_number" && values[typeOfKey].length < 15
              ? "cpf"
              : typeOfKey,
        },
      });

      setIsLoading(false);

      showToast(
        "Chave já cadastrada",
        "Sua chave já está cadastrada em outra instituição, tente uma chave diferente",
        "warning"
      );
    } catch (e) {
      console.warn(e);
      if (typeOfKey === "document_number") {
        setCreateCNPJCPFKey(true);
      } else {
        generateKeyEmailCelphone();
      }

      showToast("", "", "error", () => undefined, false);

      setIsLoading(false);
    }
  };

  const handleToggleModal = () => {
    setOpenNewKeyModal(!openNewKeyModal);
  };

  const pixKeysDataFiltered = useMemo(() => {
    const initialFilterAndMap = pixKeysData
      ?.filter((key: any) => key.key !== null)
      .map((key, index) => ({
        key: key.key,
        type: key.type,
        index: index,
        status: key.status,
      }));
    const result = initialFilterAndMap.reduce((acc: any, current: any) => {
      if (!acc.some((item: any) => item.key === current.key)) {
        acc.push(current);
      }
      return acc;
    }, []);
    return result;
  }, [pixKeysData]);

  const typesPixKeysDataFiltered = pixKeysDataFiltered.map(
    (key: any) => key.type
  );

  const countEVPs = typesPixKeysDataFiltered.filter(
    (type: any) => type === "evp"
  ).length;

  return (
    <Fragment>
      <BottomModal
        style={{ zIndex: "3" }}
        header
        title="Adicionar chave pix"
        open={openNewKeyModal}
        toggleModal={handleToggleModal}
      >
        <Fragment>
          <div className="w-100 vh-100 d-flex flex-column justify-content-start pt-4 ps-4 pe-4">
            <Description>
              Selecione o tipo de chave que você deseja utilizar para receber
              transferências pelo Pix.
            </Description>
            <Spacer bottom={30} />

            <div className="row d-flex align-items-end ">
              <div className="col-6">
                {" "}
                <CustomSelect
                  label="Escolha o tipo de chave"
                  options={pixKeyType}
                  isSearchable={false}
                  onChange={handleChangeSelect}
                  error={noKeySelected && "Selecione um tipo de chave antes"}
                  required
                  placeholder="Selecione"
                  value={pixKeyType[IndexesTypeOfKeyValue[typeOfKey]]}
                />
              </div>

              <div className="col-6">
                {typeOfKey !== "" && typeOfKey !== "evp" && (
                  <Input
                    type="text"
                    disabled={typeOfKey === "document_number"}
                    name={Names[typeOfKey]}
                    value={typeOfKey === "evp" ? "" : values[typeOfKey]}
                    label={
                      typeOfKey === "" || typeOfKey === "evp"
                        ? ""
                        : Labels[typeOfKey]
                    }
                    placeholder={
                      typeOfKey === ""
                        ? "Selecione o tipo de chave primeiro"
                        : typeOfKey === "evp"
                        ? " "
                        : "Digite a chave pix"
                    }
                    onChange={handleChange}
                    autoCapitalize="off"
                    autoComplete="off"
                    mask={Masks[typeOfKey]}
                    required
                  />
                )}
              </div>
              {typeOfKey === "evp" && countEVPs === 5 ? (
                <p style={{ color: theme.colors.error.main }}>
                  Você atingiu o número máximo de criação de chaves aleatórias
                </p>
              ) : null}
            </div>
            <div className="row ">
              <div className="col-6 ">
                <Button
                  mode="phantom"
                  label="Cancelar"
                  onClick={handleToggleModal}
                />
              </div>
              <div className="col-6 ">
                <Button
                  mode="border"
                  label={
                    typeOfKey === "evp"
                      ? "Gerar chave aleatória"
                      : "Validar chave escolhida"
                  }
                  loading={isLoading}
                  full
                  onClick={() => {
                    typeOfKey === "evp" ? generateRandomKey() : ValidateKey();
                  }}
                  disabled={
                    typeOfKey === "" || (typeOfKey === "evp" && countEVPs === 5)
                  }
                />
              </div>
            </div>
          </div>
        </Fragment>
      </BottomModal>
      <InteractiveModal
        open={openCreateCNPJCPFKey}
        title="Aprovação"
        size="md"
        toggleModal={() => {
          isLoading ? null : setCreateCNPJCPFKey(!openCreateCNPJCPFKey);
        }}
      >
        <div className="p-4 h-100 d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
          <TitleModal>
            Deseja confirmar a criação
            <br /> de chave pix{" "}
            {initialValues[typeOfKey]?.length > 13 ? "CNPJ" : "CPF"} ?
          </TitleModal>
          <div className="d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
            <div style={{ width: "290px" }} className="mb-5">
              <Spacer bottom={5} />
              <Button
                mode="main"
                label={"Confirmar"}
                full
                onClick={() => generateKeyCNPJCPF()}
                loading={isLoading}
              />
              <Spacer bottom={5} />
              <Button
                mode="border"
                label="Cancelar"
                full
                onClick={() => setCreateCNPJCPFKey(false)}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </InteractiveModal>
    </Fragment>
  );
};

export default AddNewKeyModal;
