import {
  Button,
  Checkbox,
  CustomSelect,
  Input,
  InputCurrency,
  LateralModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import * as Yup from "yup";

import useTransfers from "~/hooks/Transfers/useTransfers";
import { Subtitle } from "../app-content.styles";
import { useEffect } from "react";
import { convertStringToNumber, payloadDatesToParam } from "~/utils";
import useBanks from "~/hooks/useBanks";

type FilterTransfersValues = {
  contact_name: string;
  identifier: string;
  transactionValueFrom: string;
  transactionValueTo: string;
  transactionDateFrom: string;
  transactionDateTo: string;
  done: boolean;
  scheduled: boolean;
  canceled: boolean;
  error: boolean;
  ted: boolean;
  tev: boolean;
};

const initialValues: FilterTransfersValues = {
  contact_name: "",
  identifier: "",
  transactionValueFrom: "",
  transactionValueTo: "",
  transactionDateFrom: "",
  transactionDateTo: "",
  done: false,
  scheduled: false,
  canceled: false,
  error: false,
  ted: false,
  tev: false,
};

const FormSchema = Yup.object().shape({
  contact_name: Yup.string(),
  identification: Yup.string(),
  transactionValueFrom: Yup.string(),
  transactionValueTo: Yup.string(),
  transactionDateFrom: Yup.string(),
  transactionDateTo: Yup.string(),
  paid: Yup.boolean(),
  scheduled: Yup.boolean(),
  canceled: Yup.boolean(),
  error: Yup.boolean(),
  ted: Yup.boolean(),
  tev: Yup.boolean(),
});

const TransfersFilter = () => {
  const {
    openFilterModal,
    setOpenFilterModal,
    setFilters,
    filters,
    filtersData,
    setFiltersData,

    setFetchParams,
    getTransfers,
    filterParams,
  } = useTransfers();

  const handleFormSubmit = async (
    values: FilterTransfersValues,
    helpers: any
  ) => {
    handleApplyFilters();

    helpers.setSubmitting(true);

    const params = payloadDatesToParam(
      {
        ...values,
      },
      true
    );

    setFetchParams(params);
    await getTransfers(params);
    setOpenFilterModal(false);

    helpers.setSubmitting(false);
  };

  const {
    handleChange,
    values,
    setFieldValue,
    isSubmitting,
    resetForm,
    submitForm,
  } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    const updatedParams = { ...filterParams };

    if (values.contact_name) {
      updatedParams.contact = values.contact_name;
    } else {
      delete updatedParams.contact;
    }

    if (values.transactionValueFrom && values.transactionValueFrom !== "0,00") {
      updatedParams.price_min = convertStringToNumber(
        values.transactionValueFrom
      );
    }

    if (values.transactionValueTo && values.transactionValueTo !== "0,00") {
      updatedParams.price_max = convertStringToNumber(
        values.transactionValueTo
      );
    } else {
      delete updatedParams.price_max;
    }

    if (values.transactionDateFrom) {
      updatedParams.from_date = values.transactionDateFrom;
    }

    if (values.transactionDateTo) {
      updatedParams.to_date = values.transactionDateTo;
    }

    setFetchParams(updatedParams);
  }, [
    values.contact_name,
    values.transactionValueFrom,
    values.transactionValueTo,
    values.transactionDateFrom,
    values.transactionDateTo,
  ]);

  const { getBanks, bankOptions, banksData } = useBanks();
  useEffect(() => {
    banksData?.length <= 0 && getBanks();
  }, []);

  const handleApplyFilters = () => {
    const updatedFilters = Object.keys(values).filter((key) => {
      const value = values[key as keyof FilterTransfersValues];

      if (typeof value === "string") {
        return value.trim() !== "" && value.trim() !== "0,00";
      }
      if (typeof value === "boolean") {
        return value;
      }
      return false;
    });

    setFilters(updatedFilters);
    setFiltersData(values);
    setOpenFilterModal(!openFilterModal);
  };

  const handleToggleModal = () => {
    setOpenFilterModal(!openFilterModal);
    resetForm();
  };

  return (
    <LateralModal
      open={openFilterModal}
      title="Filtros"
      toggleModal={handleToggleModal}
      header
    >
      <div className=" w-100 vh-100 d-flex flex-column justify-content-start p-4">
        <form className="col-12">
          <div className="row ">
            <div className="col-12">
              <Input
                name="contact_name"
                value={values.contact_name}
                onChange={handleChange}
                type="text"
                label="Contato"
                placeholder="Jhon Dhoe"
              />
            </div>

            <div className="">
              {/* <Input
                name="identifier"
                onChange={handleChange}
                type="text"
                label="Identificação"
                placeholder="opção ainda não disponível"
                disabled
              /> */}
            </div>
            <Subtitle className="ps-2">Valor da transação</Subtitle>
            <div className="col-12 col-md-6">
              <InputCurrency
                value={values.transactionValueFrom}
                label="De"
                inputSize="normal"
                name="transactionValueFrom"
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputCurrency
                value={values.transactionValueTo}
                label="Até"
                inputSize="normal"
                name="transactionValueTo"
                onChange={handleChange}
              />
            </div>
            <Subtitle className="ps-2">Data da transação</Subtitle>
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateFrom"
                value={values.transactionDateFrom}
                onChange={handleChange}
                type="date"
                label="De"
              />
            </div>
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateTo"
                value={values.transactionDateTo}
                onChange={handleChange}
                type="date"
                label="Até"
              />
            </div>

            <div className="col-6 d-flex flex-column align-items-start ">
              <Subtitle>Status</Subtitle>
              <Checkbox
                label="Transferido"
                name="done"
                checked={values.done}
                onClick={() => setFieldValue("done", !values.done)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Agendado"
                name="scheduled"
                checked={values.scheduled}
                onClick={() => setFieldValue("scheduled", !values.scheduled)}
                position="right"
              />

              <Spacer bottom={10} />
              <Checkbox
                label="Cancelado"
                name="canceled"
                checked={values.canceled}
                onClick={() => setFieldValue("canceled", !values.canceled)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Erro"
                name="error"
                checked={values.error}
                onClick={() => setFieldValue("error", !values.error)}
                position="right"
              />
            </div>

            <div className="col-6 d-flex flex-column align-items-start ">
              <Subtitle>Tipo de movimentação</Subtitle>
              <Checkbox
                label="TED"
                name="ted"
                checked={values.ted}
                onClick={() => setFieldValue("ted", !values.ted)}
                position="right"
              />
              <Spacer bottom={10} />
              {/* <Checkbox
                label="PIX"
                name="PIX"
                checked={values.PIX}
                onClick={() => setFieldValue("PIX", !values.PIX)}
                position="right"
              />
              <Spacer bottom={10} /> */}
              <Checkbox
                label="Transferência entre contas"
                name="tev"
                checked={values.tev}
                onClick={() => setFieldValue("tev", !values.tev)}
                position="right"
              />
            </div>
            <Spacer bottom={30} />
            <div className="col-12 col-lg-6 d-flex flex-column align-items-start ">
              <CustomSelect
                placeholder="Instituição"
                options={bankOptions}
                menuPlacement="top"
              />
            </div>
          </div>
        </form>

        <Spacer bottom={25} />
        <div className="w-100 d-flex justify-content-between ">
          <Button
            mode="phantom"
            onClick={() => resetForm()}
            label="Limpar tudo"
          />
          {/* <Button
            mode="main"
            loading={isSubmitting}
            label="Aplicar filtros"
            onClick={() => submitForm()}
          /> */}
        </div>
      </div>
    </LateralModal>
  );
};

export default TransfersFilter;
