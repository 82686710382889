import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Button,
  ButtonDropdown,
  NotControlledToolTip,
  Table,
  Spacer,
  TabMenu,
} from "@zilliondigital/mirage-ui";
import { useCallback, useEffect, useState } from "react";
import OpenAccordionIcon from "~/assets/icons/Actions/Chevron.png";
import CloseAccordionIcon from "~/assets/icons/Actions/Chevron2.png";
import { ImgEye } from "../../app-content.styles";
import { useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import { ContainerData } from "~/pages/App/app-content.styles";

import Paginator from "~/components/Paginator";

import ApiService from "~/services/api";

import useTariffs from "~/hooks/useTariffs";
import useAuth from "~/hooks/useAuth";
import { formatNumberToCurrencyWithoutSimbol } from "~/utils";

const Tax = () => {
  const [taxes, setTaxes] = useState<any>([]);

  const { user } = useAuth();

  const [results, setResults] = useState<any[]>([]);

  const fetchTax = async () => {
    setResults([]);

    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/holder-taxes/",
        token: true,
      });

      setResults(data.data);
    } catch (err: any) {}
  };


  const columnsTariffs = [
    {
      label: "Nome",
      key: "name",

      renderData: (data: any) => {
        return <Title>{data.name}</Title>;
      },
    },

    {
      label: "Taxa",
      key: "customer_tax_out",
      renderData: (data: any) => {
        return (
          <div>
            R$ {formatNumberToCurrencyWithoutSimbol(data.customer_tax_out)}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchTax();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ContainerData className="p-4">
              <div className="pe-2 flex-grow-1 d-flex justify-content-between mb-5">
                <Title>Confira sua cesta de tarifas</Title>
              </div>
              {results.length > 0 ? (
                <div className="">
                  <Table data={results} columns={columnsTariffs as any} />
                </div>
              ) : null}
            </ContainerData>

            {/* <div className={clientsData.length > 0 ? "" : "d-none"}>
              {value !== "" && (
                <Paginator
                  list={clientsData.length > 0 ? clientsData : []}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  pageSetter={setPage}
                />
              )}
              <Spacer top={clientsData.length > 0 ? 2 : 40} />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tax;
