import "bootstrap/dist/css/bootstrap.min.css";
import {
  Breadcrumb,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { Fragment, useEffect } from "react";
import "~/styles/reset.css";
import { Outlet, useNavigate } from "react-router-dom";
import {
  ContainerData,
  GeneralContainer,
} from "~/pages/App/app-content.styles";
import Copyright from "~/components/Copyright";
import useCards from "~/hooks/Cards/useCards";
import useAccount from "~/hooks/useAccount";

const CardsStructure = () => {
  
  const navigate = useNavigate();
  const { 
    subPage,
  } = useCards()

  const menusBreadCrumb = [
    {label: "Cartões", onClick: () => {navigate("/app/cards")}}
  ];
  if(subPage && subPage.name && subPage.route){
    menusBreadCrumb.push({label: subPage.name, onClick: () => {navigate(subPage.route || '')}} )
  }

  return (
    <Fragment>
      <GeneralContainer>
        <div className="d-flex flex-column container w-100">
          <Spacer top={30} />
          <Breadcrumb
            menus={menusBreadCrumb}
            onClickHome={() => navigate("/app/dashboard")}
          />
          <Spacer top={30} />
          <Outlet />
          <Spacer top={30} />
          <Copyright />

          <Spacer top={30} />
        </div>
      </GeneralContainer>
    </Fragment>
  );
};

export default CardsStructure;
