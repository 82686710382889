import "bootstrap/dist/css/bootstrap.min.css";
import { Spacer } from "@zilliondigital/mirage-ui";
import CardImg from "~/assets/images/credit--card.svg"
import "~/styles/reset.css";
import Title from "~/components/Texts/Title";
import Description from "~/components/Texts/Description";
import styled from "styled-components";

const EmptyImg = styled.img`
  width: 96px;
  height: 96px;
`;

const MyCardsEmpty = () => {
  return (
    <div className="
      w-100 pt-3 d-flex flex-column 
      align-items-center justify-content-center
      gap-5
    ">
      <EmptyImg src={CardImg} />
      
      <Description className="text-center">
        Você não possui nenhum cartão vinculado a sua conta no momento
      </Description>
    </div>
  );
};

export default MyCardsEmpty;
