import { Fragment, useEffect, useState } from "react";
import Title from "~/components/Texts/Title";
import { CardsDashboardHeader, CreateCardContainer } from "./styled";
import useAccount from "~/hooks/useAccount";
import useCards from "~/hooks/Cards/useCards";
import { useNavigate } from "react-router-dom";
import { CreateCardStep } from "~/hooks/Cards/interfaces";
import { ContainerData } from "../../app-content.styles";
import CreateCardInfoStepSection from "./section/CreateCardInfoStep";
import CreateCardDeliveryInfoStepSection from "./section/CreateCardDeliveryInfoStep";
import CreateCardDeliveryInfoOtherStep from "./section/CreateCardDeliveryInfoOtherStep";
import CreateCardConfirmationStepSection from "./section/CreateCardConfirmationStep";
import CreateCardSuccessStep from "./section/CreateCardSuccessStep";

const MyCardsCreateContainer = (props: {}) => {
  const {
    setSubPage,
    setCreateCardStep,
    createCardStep,
    setCreatingCardStatus,
    creatingCardStatus,
    setCreateCardValues,
    resetCreateCardValues,
    createCardInfos,
    postCreateBankCard,
  } = useCards();

  useEffect(() => {
    setSubPage({ name: "Solicitar novo", route: "/app/cards/create" });
    setCreateCardStep("INFORMATION");
    resetCreateCardValues();
    setCreatingCardStatus(null);
  }, []);

  const _returnStepComponent = (step: CreateCardStep) => {
    switch (step) {
      case "INFORMATION":
        return (
          <CreateCardInfoStepSection
            setCreateCardStep={setCreateCardStep}
            createCardStep={createCardStep}
            setCreateCardValues={setCreateCardValues}
            createCardInfos={createCardInfos}
          />
        );

      // Removed due to not having a default address to delivery the card yet.
      // case 'DELIVERY_INFO':
      //   return (
      //     <CreateCardDeliveryInfoStepSection
      //       setCreateCardStep={setCreateCardStep}
      //       createCardStep={createCardStep}
      //       setCreateCardValues={setCreateCardValues}
      //       createCardInfos={createCardInfos}
      //     />
      //   )

      case "DELIVERY_INFO_OTHER":
        return (
          <CreateCardDeliveryInfoOtherStep
            setCreateCardStep={setCreateCardStep}
            createCardStep={createCardStep}
            setCreateCardValues={setCreateCardValues}
            createCardInfos={createCardInfos}
          />
        );

      case "CONFIRMATION":
        return (
          <CreateCardConfirmationStepSection
            setCreateCardStep={setCreateCardStep}
            createCardStep={createCardStep}
            setCreateCardValues={setCreateCardValues}
            createCardInfos={createCardInfos}
            postCreateBankCard={postCreateBankCard}
            creatingCardStatus={creatingCardStatus}
          />
        );

      case "SUCCESS":
        return (
          <CreateCardSuccessStep
            createCardInfos={createCardInfos}
            creatingCardStatus={creatingCardStatus}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <Fragment>
      <ContainerData className="container">
        <CardsDashboardHeader>
          <Title>Solicitar novo cartão</Title>
        </CardsDashboardHeader>
        <div className="col-md-8 w-100 d-flex flex-column px-4">
          <CreateCardContainer>
            {_returnStepComponent(createCardStep)}
          </CreateCardContainer>
        </div>
      </ContainerData>
    </Fragment>
  );
};

export default MyCardsCreateContainer;
