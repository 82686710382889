import {
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";
import styled, { keyframes } from "styled-components";
import Description from "~/components/Texts/Description";
import useTariffs from "~/hooks/useTariffs";
import { Title } from "~/pages/App/app-content.styles";
import theme from "~/themes/theme";
import { formatCPFCNPJ, formatCelphone } from "~/utils";
import Timer from "~/assets/icons/figure_3d_time.png";
import DeleteIcon from "~/assets/icons/delete.png";
import Paginator, { applyPagination } from "~/components/Paginator";
import { useCallback, useEffect, useState } from "react";
import ApiService from "~/services/api";

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
  }
`;

const ImgDelete = styled.img`
  height: 18px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.error.mainFilter};
  }
`;

const Rounded = styled.div`
  width: 96px;
  height: 96px;
  background-color: #ffe5d8;
  position: relative;

  img {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`;

const RenderCustomers = () => {
  const {
    editPackpageData,
    setDeleteHolderData,
    setOpenDeleteHolderModal,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPageClients,
  } = useTariffs();

  const [holdersDefaultPackpage, setHoldersDefaultPackpage] = useState<any>([]);

  const columnsCustomer: any = [
    {
      label: "Nome",
      key: "name",
      renderData: (data: any) => (
        <div className="d-flex flex-column">
          {" "}
          <p>{data.name}</p> <Spacer bottom={-15} />
          <Description color="gray">
            {formatCPFCNPJ(data.document_number)}
          </Description>{" "}
        </div>
      ),
    },

    {
      label: "Contato",
      key: "email",
      renderData: (data: any) => {
        return (
          <div className="d-flex flex-column">
            <p>{data.email ?? "-"}</p> <Spacer bottom={-15} />
            <Description color="gray">
              {formatCelphone(data.mobile_phone ?? "123")}
            </Description>{" "}
          </div>
        );
      },
    },
    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="pe-4 me-5 d-flex col-2">
            {!editPackpageData[0]?.default && (
              <>
                <ImgDelete
                  id={`delete-${data.id}`}
                  src={DeleteIcon}
                  onClick={async () => {
                    await setDeleteHolderData(data);
                    setOpenDeleteHolderModal(true);
                  }}
                />
                <NotControlledToolTip
                  placement="top"
                  target={`delete-${data.id}`}
                >
                  {"Remover"}
                </NotControlledToolTip>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const fetchGetHoldersDefaultPackpage = useCallback(async () => {
    // setIsLoading(true);

    setHoldersDefaultPackpage([]);
    try {
      const { data } = await ApiService.HttpGet({
        route: "backoffice/tariffs-package/get-default-holders/",
        token: true,

        params: {
          document_type: editPackpageData[0].type === "pf" ? "cpf" : "cnpj",
        },
      });

      setHoldersDefaultPackpage(data.data);
    } catch {}
  }, []);

  useEffect(() => {
    if (editPackpageData[0].default) {
      fetchGetHoldersDefaultPackpage();
    }
  }, [editPackpageData[0]]);

  return (
    <div>
      {editPackpageData[0]?.holders.length > 0 ||
      holdersDefaultPackpage.length > 0 ? (
        <Table
          columns={columnsCustomer}
          data={applyPagination(
            holdersDefaultPackpage.length > 0
              ? holdersDefaultPackpage
              : editPackpageData[0]?.holders.sort(),
            {
              page,
              rowsPerPage,
            }
          )}
          haveHeader
        />
      ) : (
        <>
          <div className="w-100  pt-3  d-flex flex-column align-items-center justify content-start">
            <Rounded className="rounded-circle">
              <img src={Timer} />
            </Rounded>

            <Spacer bottom={60} />
            <Title>Nenhum correntista atribuído a esta cesta</Title>

            <Spacer bottom={200} />
          </div>
        </>
      )}

      <Paginator
        list={
          holdersDefaultPackpage.length > 0
            ? holdersDefaultPackpage
            : holdersDefaultPackpage.length === 0 &&
              editPackpageData[0]?.holders.length > 0
            ? editPackpageData[0]?.holders
            : []
        }
        rowsPerPage={rowsPerPage}
        page={page}
        pageSetter={setPage}
      />
    </div>
  );
};

export default RenderCustomers;
