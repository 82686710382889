import { LateralModal, ProgressBar } from "@zilliondigital/mirage-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Barcode from "./Barcode";
import PaymentData from "./PaymentData";
import usePayments from "~/hooks/usePayments";
import styled from "styled-components";

import LoaderNewPayment from "./Loader";
import { render } from "react-dom";
import ReceiptPayment from "./Receipt";
import LoaderPayments from "./LoaderPayments";
import useAccount from "~/hooks/useAccount";

type CopyAndPasteFormValues = {
  code: string;
};

const Container = styled.div`
  height: 100vh;
  display: flex;
`;

const initialValues: CopyAndPasteFormValues = {
  code: "",
};

const FormSchema = Yup.object().shape({
  code: Yup.string().required("Campo obrigatório"),
});

const NewPaymentModal = () => {
  const {
    stepIndex,
    steps,
    openNewPaymentModal,
    isLoadingNewPayment,
    handleCloseNewPaymentModal,
    openNewPaymentModalInDash,
    isLoadingReceipt,
    renderReceipt,
  } = usePayments();

  const { verifyPermission } = useAccount();

  const canCreatePayment = verifyPermission("ba_can_create_payment");

  const handleSubmit = (
    values: CopyAndPasteFormValues,
    formikHelpers: any
  ) => {};

  const renderStep = [
    <Barcode />,
    renderReceipt && !isLoadingReceipt ? (
      <ReceiptPayment />
    ) : isLoadingReceipt ? (
      <LoaderPayments />
    ) : (
      <PaymentData />
    ),
  ];

  const { errors, values, setFieldValue, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <LateralModal
        style={{ zIndex: "3" }}
        className=""
        header
        title={
          renderReceipt
            ? "Pagamento concluído"
            : isLoadingReceipt
            ? "Efetuando pagamento..."
            : "Efetuar novo pagamento"
        }
        open={openNewPaymentModal || openNewPaymentModalInDash}
        toggleModal={() => {
          handleCloseNewPaymentModal();
        }}
      >
        <div className="container-content">
          <div>
            <ProgressBar percent={((stepIndex + 1) * 100) / steps.length} />
          </div>
          {isLoadingNewPayment && <LoaderNewPayment />}
          {!isLoadingNewPayment && renderStep[stepIndex]}
        </div>
      </LateralModal>
    </Container>
  );
};

export default NewPaymentModal;
