import { useEffect, useState } from "react";
import { Pagination } from "@zilliondigital/mirage-ui";

type PaginatorProps = {
  list: any[];
  rowsPerPage: number;
  page: number;
  pageSetter: (page: any) => void;
};

const Paginator = (props: PaginatorProps) => {
  const { list = [], rowsPerPage, page = 0, pageSetter } = props;
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    if (rowsPerPage) {
      const total = Math.ceil(list.length / rowsPerPage);
      setTotalPages(total);
      pageSetter(0);
    }
  }, [list, rowsPerPage]);

  return (
    <div className="w-100 d-flex justify-content-center justify-content-lg-between p-3 align-items-center">
      <p style={{ color: "#666666" }}>{`${page + 1} de ${totalPages} `}</p>

      {rowsPerPage ? (
        <Pagination
          limit={rowsPerPage}
          total={list.length}
          activePageIndex={page}
          changePage={(page) => {
            pageSetter(page);
          }}
        />
      ) : null}
    </div>
  );
};

type ApplyPagination = {
  page: number;
  rowsPerPage: number;
};

export const applyPagination = (
  list: any[] = [],
  { page, rowsPerPage }: ApplyPagination
) => {
  return list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};

export default Paginator;
