import {
  Button,
  Checkbox,
  Input,
  LateralModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import usePermission from "~/hooks/usePermission";
import useAccount from "~/hooks/useAccount";
import useToast from "~/hooks/useToast";
import {
  Subtitle,
  ContainerDetails,
  ContainerBottom,
} from "../app-content.styles";

type FilterStatementsValues = {
  can_change_billets: boolean;
  can_create_billets: boolean;
  can_request_cards: boolean;
  can_create_cnab: boolean;
  can_create_contacts: boolean;
  can_request_card_machines: boolean;
  can_create_payments: boolean;
  can_create_recurrence: boolean;
  can_create_modify_settings: boolean;
  can_create_transactions: boolean;
  can_view_the_balance: boolean;
  can_view_invoices: boolean;
  can_view_cards: boolean;
  can_view_cnab: boolean;
  can_view_contacts: boolean;
  can_view_card_machines: boolean;
  can_view_the_statement: boolean;
  can_view_payments: boolean;
  can_view_payment_links: boolean;
  can_view_recurring_payments: boolean;
  can_view_settings: boolean;
  can_view_transactions: boolean;
  can_sign: boolean;
  name: string;
  [key: string]: any;
};

const initialValues: FilterStatementsValues = {
  can_change_billets: false,
  can_create_billets: false,
  can_request_cards: false,
  can_create_cnab: false,
  can_create_contacts: false,
  can_request_card_machines: false,
  can_create_payments: false,
  can_create_recurrence: false,
  can_create_modify_settings: false,
  can_create_transactions: false,
  can_view_the_balance: false,
  can_view_invoices: false,
  can_view_cards: false,
  can_view_cnab: false,
  can_view_contacts: false,
  can_view_card_machines: false,
  can_view_the_statement: false,
  can_view_payments: false,
  can_view_payment_links: false,
  can_view_recurring_payments: false,
  can_view_settings: false,
  can_view_transactions: false,
  can_sign: false,
  name: "",
};

const FormSchema = Yup.object().shape({
  can_change_billets: Yup.boolean(),
  can_create_billets: Yup.boolean(),
  can_request_cards: Yup.boolean(),
  can_create_cnab: Yup.boolean(),
  can_create_contacts: Yup.boolean(),
  can_request_card_machines: Yup.boolean(),
  can_create_payments: Yup.boolean(),
  can_create_recurrence: Yup.boolean(),
  can_create_modify_settings: Yup.boolean(),
  can_create_transactions: Yup.boolean(),
  can_view_the_balance: Yup.boolean(),
  can_view_invoices: Yup.boolean(),
  can_view_cards: Yup.boolean(),
  can_view_cnab: Yup.boolean(),
  can_view_contacts: Yup.boolean(),
  can_view_card_machines: Yup.boolean(),
  can_view_the_statement: Yup.boolean(),
  can_view_payments: Yup.boolean(),
  can_view_payment_links: Yup.boolean(),
  can_view_recurring_payments: Yup.boolean(),
  can_view_settings: Yup.boolean(),
  can_view_transactions: Yup.boolean(),
  can_sign: Yup.boolean(),
  cancel: Yup.boolean(),
  create_new_permission: Yup.boolean(),
  name: Yup.string().required("Nome é obrigatório"),
});

const PermissionModal = () => {
  const {
    permissions,
    getPermissions,
    currentPermission,
    isEditPermission,
    setOpenNewPermissionModal,
    openNewPermissionModal,
    createPermission,
    editPermission,
    submitPermission,
    setCurrentPermission,
    setSubmitPermission,
  } = usePermission();

  const [initVals, setInitVals] =
    useState<FilterStatementsValues>(initialValues);

  const { mainAccount } = useAccount();
  const { showToast } = useToast();

  const handleFormSubmit = async (values: any) => {
    const all_perms = Object.entries(values).filter(
      (_y: any) => _y[0] !== "name"
    );

    if (!isEditPermission) {
      // CREATE
      const permissionsList: any[] = all_perms
        .filter((_y: any) => _y[1] === true)
        .map((_x) => _x[0]);

      createPermission({
        partner: mainAccount?.partner,
        name: values.name,
        permissions: permissionsList,
      });
      setOpenNewPermissionModal(false);
      return;
    } else {
      const { permissions: perms } = currentPermission;

      const lastPerms = perms.map((_x: any) => _x.codename);
      const chosen: any[] = all_perms
        .filter((_y: any) => _y[1] === true)
        .map((_x) => _x[0]);

      // UPDATE
      let payload: any = { name: values.name, only_perms: true };

      const removedPerms = lastPerms.filter(
        (_p: string) => !chosen.includes(_p)
      );
      const addedPerms = chosen.filter((_p: string) => !lastPerms.includes(_p));

      let response: any = {};

      if (removedPerms) {
        payload = {
          ...payload,
          action: "remove",
          permissions: removedPerms,
        };
        response = await editPermission(currentPermission.id, payload);
      }

      if (addedPerms) {
        payload = {
          ...payload,
          action: "add",
          permissions: addedPerms,
        };
        response = await editPermission(currentPermission.id, payload);
      }

      showToast("Edição", "Permissão atualizada com sucesso", "success");
      getPermissions();
      setSubmitPermission(false);
      setCurrentPermission(response);
    }
  };

  const {
    handleChange,
    setFieldValue,
    values,
    submitForm,
    errors,
    resetForm,
    touched,
  } = useFormik({
    validationSchema: FormSchema,
    initialValues: initVals,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (openNewPermissionModal) {
      resetForm();

      if (!isEditPermission) {
        // NEW
        setInitVals(initialValues);
      } else {
        // EDIT
        const { permissions: perms } = currentPermission;
        const filteredPerms = perms.map((_x: any) => ({ [_x.codename]: true }));

        const auxObj: any = {};

        filteredPerms.forEach((element: any) => {
          auxObj[Object.keys(element)[0]] = Object.values(element)[0];
        });

        const newInit = {
          ...initialValues,
          ...auxObj,
          name: currentPermission.name,
        };

        setInitVals(newInit);
      }
    }
  }, [
    openNewPermissionModal,
    isEditPermission,
    currentPermission,
    initialValues,
  ]);

  const [labelButtonSelectedAll, setLabelButtonSelectedAll] =
    useState<string>("Selecionar todas");
  const allChecked = permissions.every((perm: any) => values[perm.codename]);

  const toggleAllPermissions = (e: any) => {
    // Verifica se todos os campos já estão marcados

    e.preventDefault();

    permissions.forEach((perm) => {
      setFieldValue(perm.codename, !allChecked);
    });
  };

  useEffect(() => {
    setLabelButtonSelectedAll(
      allChecked ? "Desmarcar todas" : "Selecionar todas"
    );
  }, [allChecked]);

  return (
    <LateralModal
      open={openNewPermissionModal}
      title={isEditPermission ? "Editar permissão" : "Nova permissão"}
      toggleModal={() => setOpenNewPermissionModal(!openNewPermissionModal)}
      header
    >
      <div className=" w-100 vh-100 d-flex flex-column justify-content-start p-4">
        <ContainerDetails>
          <form className="col-12 ">
            <div className="row ">
              <div className="col-12">
                <Input
                  label="Nome"
                  required
                  onChange={handleChange}
                  placeholder="Digite um nome"
                  name="name"
                  value={values.name}
                  error={touched.name && values.name === "" && errors.name}
                />
              </div>

              <div className="col-7 d-flex flex-column align-items-start mt-1">
                <Subtitle>Permissões</Subtitle>
                <Spacer bottom={5}></Spacer>
                {permissions.map((_perm) => {
                  return (
                    <>
                      <Checkbox
                        name={_perm.codename}
                        checked={values[_perm.codename]}
                        onClick={() =>
                          setFieldValue(_perm.codename, !values[_perm.codename])
                        }
                        label={_perm.name}
                        position="right"
                      />

                      <Spacer bottom={10} />
                    </>
                  );
                })}

                <Spacer bottom={10} />
              </div>
              <div
                className="col-5 "
                style={{ position: "relative", left: "7%" }}
              >
                <Button
                  mode="border"
                  label={labelButtonSelectedAll}
                  className="ms-2"
                  onClick={(e) => toggleAllPermissions(e)}
                />
              </div>
            </div>
          </form>
        </ContainerDetails>

        <Spacer bottom={55} />
        <ContainerBottom>
          <div className="w-100 d-flex justify-content-between ">
            <Button
              mode="phantom"
              label="Cancelar"
              onClick={() => {
                setOpenNewPermissionModal(false);
              }}
            />
            <Button
              mode="main"
              label={`${isEditPermission ? "Editar" : "Criar"} permissão`}
              onClick={() => submitForm()}
              loading={submitPermission}
              disabled={submitPermission}
            />
          </div>
        </ContainerBottom>
        <div style={{ paddingBottom: "25px" }}></div>
      </div>
    </LateralModal>
  );
};

export default PermissionModal;
