// export const hideDocumentNumber = (number?: string) => {
//   const digits = number?.replace(/\D/g, "");

//   if (digits && digits.length > 11) {
//     return `${digits.slice(0, 3)}********${digits.slice(-2)}`;
//   } else {
//     if (digits) {
//       return `${digits.slice(0, 3)}******${digits.slice(-2)}`;
//     }
//   }
// };

const hideFormatDocumentNumber = (numero: string): string => {
  numero = numero?.replace(/\D/g, ""); // Remove caracteres não numéricos

  if (numero.length === 11) {
    // CPF
    return `***.${numero.slice(3, 6)}.${numero.slice(6, 9)}-**`;
  } else if (numero.length === 14) {
    // CNPJ
    return `${numero.slice(0, 2)}.***.${numero.slice(5, 8)}/${numero.slice(
      8,
      12
    )}-**`;
  } else {
    return "Formato inválido. Deve conter 11 ou 14 dígitos.";
  }
};

export default hideFormatDocumentNumber;
