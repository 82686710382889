import { Button } from "@zilliondigital/mirage-ui";
import { Fragment, useEffect } from "react";
import Title from "~/components/Texts/Title";
import {
  CardsDashboardButtonDiv,
  CardsDashboardButtonWrapper,
  CardsDashboardHeader,
  RecentTransactionsTitle,
} from "./styled";
import { useNavigate } from "react-router-dom";
import useCards from "~/hooks/Cards/useCards";
import cardWhiteIcon from "~/assets/icons/purchase--sm--white.svg";
import { CardsRecentsTransactionsSection } from "./section/CardsRecentTransactions";
import TableLoader from "~/components/SkeletonTables";
import RecentTransactionsEmpty from "./features/RecentTransactionsEmpty";
import { ContainerData } from "../../app-content.styles";
import MyCardsContainer from "./MyCardsContainer";
import useAccount from "~/hooks/useAccount";
import { onlyNumbers } from "~/utils";

const CardsDashboardContainer = (props: {}) => {
  const navigate = useNavigate();

  const {
    setSubPage,
    recentTransactions,
    cardsRecentTransactionsError,
    isLoadingRecentTransactions,
    getRecentCardTransactionsAsync,
    getCardsAsync,
    setActivatingCardStatus,
    setCardDetailsStep,
    putCardContactlessStatus,
    setPutCardContactlessStatus,
    setIsLoadingCards,
    isLoadingCards,
    accountUuid,
    getCardAccount,
    accountFetchingStatus,
  } = useCards();

  const { mainAccount } = useAccount();

  useEffect(() => {
    if (mainAccount && !accountUuid) {
      const { id, owner } = mainAccount.bank_account_data;
      const { address } = owner;
      const cleaner_phone_number = onlyNumbers(owner.mobile_phone);

      getCardAccount({
        account_id: String(id),
        partner_id: String(owner.id),
        name: owner.name,
        document_number: owner.document_number,
        document_type: owner.document_type,
        birth_date: owner.birth_date,
        email: owner.email,
        phone: {
          phone_number: `${cleaner_phone_number.slice(2)}`,
          area_code: `${cleaner_phone_number.slice(0, 2)}`,
          country_code: "+55",
          phone_type: "COMMERCIAL",
          main: true,
        },
        address: {
          address_type: "RESIDENTIAL",
          zip_code: `${address[0].zipcode.slice(
            0,
            5
          )}-${address[0].zipcode.slice(5, 9)}`,
          address: address[0].street,
          number: address[0].number,
          complementary_address: address[0].complement || "",
          district: address[0].district,
          city: address[0].city,
          state: address[0].state,
          country: address[0].country,
        },
      });
    }
  }, [mainAccount, accountUuid]);

  useEffect(() => {
    setSubPage({});
    setActivatingCardStatus(null);
    setCardDetailsStep("CONFIRMATION");
  }, []);

  useEffect(() => {
    if (accountUuid) {
      getRecentCardTransactionsAsync();
      getCardsAsync();
    }
  }, [accountUuid]);

  useEffect(() => {
    if (putCardContactlessStatus == "SUCCESS") {
      getCardsAsync();
      setPutCardContactlessStatus(null);
    } else if (putCardContactlessStatus == "LOADING") {
      setIsLoadingCards(true);
    } else if (putCardContactlessStatus == "SERVER_ERROR") {
      setIsLoadingCards(false);
    }
  }, [putCardContactlessStatus]);

  return (
    <Fragment>
      <div className="d-flex gap-4 flex-column">
        <ContainerData className="container pt-3">
          <MyCardsContainer />
        </ContainerData>

        <ContainerData className="container pt-3">
          <CardsDashboardHeader>
            <Title>Transações recentes</Title>
          </CardsDashboardHeader>
          <div className="col-md-8 w-100 d-flex flex-column px-4">
            <div className="w-100 mt-2">
              {isLoadingRecentTransactions ||
              isLoadingCards ||
              accountFetchingStatus != "FETCHED" ? (
                <TableLoader />
              ) : recentTransactions.length == 0 ? (
                <>
                  <div style={{ paddingTop: "100px", paddingBottom: "150px" }}>
                    <RecentTransactionsEmpty />
                  </div>
                </>
              ) : null}
              {recentTransactions.length > 0 ||
              isLoadingCards ||
              accountFetchingStatus != "FETCHED" ? (
                <CardsRecentsTransactionsSection
                  recentTransactions={recentTransactions}
                  isLoadingRecentTransactions={isLoadingRecentTransactions}
                  recentTransactionsError={cardsRecentTransactionsError}
                />
              ) : null}
            </div>
          </div>
        </ContainerData>
      </div>
    </Fragment>
  );
};

export default CardsDashboardContainer;
