import { Toast } from "@zilliondigital/mirage-ui";

import styled from "styled-components";
import useToast from "~/hooks/useToast";

const Container = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 6;
`;

const ToastsContainer = () => {
  const { toasts, removeToast } = useToast();

  return (
    <Container>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          {...toast}
          onClick={() => {
            removeToast(toast.id);
            if (toast.onClick) toast.onClick();
          }}
          absolute={false}
        />
      ))}
    </Container>
  );
};

export default ToastsContainer;
