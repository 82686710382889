import React, { useEffect, useState } from "react";
import { CreateCardDeliveryStepDiv, CreatingCardDeliveryContainer, CreateCardAddressOtherFooterBtnWrapper } from "../styled";
import { ICreateCardInfos, initalCreateCardInfos } from "~/hooks/Cards/initalValues";
import { ActivateCardSteps, CreateCardStep } from "~/hooks/Cards/interfaces";
import { Button, Input } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import useCEP from "~/hooks/useCEP";
import { CardDeliveryInfoInputs } from "./CardDeliveryInfoInputs";

interface ICreateCardDeliveryInfoOtherStep {
  setCreateCardStep: React.Dispatch<React.SetStateAction<CreateCardStep>>;
  createCardStep: CreateCardStep;
  createCardInfos: ICreateCardInfos;
  setCreateCardValues: (info: string, value: any) => void;
}
const CreateCardDeliveryInfoOtherStep = (props: ICreateCardDeliveryInfoOtherStep) => {
  const navigate = useNavigate()
  const {address, handleCepString, setAddress} = useCEP()
  const [zipCodeError, setZipCodeError] = useState(false);
  const [numberError, setNumberError] = useState(false);

  useEffect(() => {
    if(props.createCardInfos.card_type == 'VIRTUAL'){
      props.setCreateCardStep('INFORMATION')
    }

    setAddress({
      zip_code: "",
      street: "",
      district: "",
      city: "",
      state: "",
    });
  }, [])

  useEffect(()  => {
    if(!address.error && address.street){
      setZipCodeError(false)
      props.setCreateCardValues('other_address',
      {
        zip_code: `${address.zip_code.slice(0, 5)}-${address.zip_code.slice(5, 8)}`,
        address: address.street,
        number: '',
        district: address.district,
        city: address.city,
        state: address.state,
        country: 'Brazil',
        complementary_address: '',
        address_type: 'OTHER'
      }
      )
    }else{
      if(address.error){
        setZipCodeError(true)
      }
    }
  }, [address])

  return (
    <CreateCardDeliveryStepDiv>
      <CreatingCardDeliveryContainer>
        <h4>Endereço para envio</h4>
        
        <CardDeliveryInfoInputs 
          {...props}
          handleCepString={handleCepString}
          numberError={numberError}
          zipCodeError={zipCodeError}
        />

        <CreateCardAddressOtherFooterBtnWrapper>
          <Button 
            mode="border" style={{width: '30%'}} label="Voltar"
            onClick={() => {props.setCreateCardStep('INFORMATION')}}
          />
          <Button 
            mode="main" style={{width: '30%'}} label="Avançar"
            onClick={() => {
              if(
                props.createCardInfos.other_address?.number &&
                props.createCardInfos.other_address?.number.length > 0 &&
                props.createCardInfos.other_address.zip_code.length > 0 &&
                props.createCardInfos.other_address.address.length > 0
              ){
                props.setCreateCardStep('CONFIRMATION')
              }else{
                if(
                  props.createCardInfos.other_address?.zip_code &&
                  props.createCardInfos.other_address.zip_code.length == 8
                ){
                  setZipCodeError(false)
                }else{
                  setZipCodeError(true)
                }

                if(
                  props.createCardInfos.other_address?.number &&
                  props.createCardInfos.other_address.number.length > 0
                ){
                  setNumberError(false)
                }else{
                  setNumberError(true)
                }
              }
            }}
          />
        </CreateCardAddressOtherFooterBtnWrapper>
      </CreatingCardDeliveryContainer>
    </CreateCardDeliveryStepDiv>
  );
};

export default CreateCardDeliveryInfoOtherStep;
