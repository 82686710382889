import constate from "constate";
import { useState } from "react";
import ApiService from "~/services/api";
import { getToday, payloadDatesToParam } from "~/utils";
import { subDays } from "date-fns";

const initialFilterParams = {
  from_date: getToday(),
  to_date: getToday(),
  price_min: 0,
};

const [TransfersProvider, useTransfers] = constate(() => {
  const [openNewTransferModal, setOpenNewTransferModal] =
    useState<boolean>(false);
  const [openNewTransferModalInDash, setOpenNewTransferModalInDash] =
    useState<boolean>(false);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [filteredTransactions, setFilteredTransactions] = useState<any[]>([]);
  const [openTransfersDetailsModal, setOpenTransfersDetailsModal] =
    useState<boolean>(false);
  const [indexTransfersDetails, setIndexTransfersDetails] = useState<number>(0);

  const [transfersData, setTransfersData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<string[]>([]);

  const [filtersData, setFiltersData] = useState<any>({});

  const [currentModalData, setCurrentModalData] = useState<any>();

  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);

  const [filterParams, setFilterParams] = useState<any>(initialFilterParams);

  const [key, setKey] = useState<string>("");

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState(0);

  const [isManual, setIsManual] = useState<boolean>(false);

  // {
  //   price_min: value_from,
  //   price_max: value_to,
  //   from_date: from_date,
  //   to_date: to_date,
  //   category:
  //   limit: limit ?? 20,
  // },

  const setFetchParams = (updatedParams = {}) => {
    setFilterParams((prevFilters: any) => ({
      ...prevFilters,
      ...updatedParams,
    }));
  };

  const getTransfers = async (fastParams?: any) => {
    setIsLoading(true);

    setTransfersData([]);

    const params = payloadDatesToParam({
      ...filterParams,
      ...fastParams,
    });

    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/transaction-bank/",
        token: true,
        params: {
          ...params,
        },
      });

      setTransfersData(data.results);
      setKey("");

      setIsLoading(false);
    } catch (e: any) {
      console.warn(e);
      setIsLoading(false);
    }
  };

  return {
    filteredTransactions,
    setFilteredTransactions,
    openFilterModal,
    setOpenFilterModal,
    openNewTransferModal,
    setOpenNewTransferModal,
    openTransfersDetailsModal,
    setOpenTransfersDetailsModal,
    indexTransfersDetails,
    setIndexTransfersDetails,
    transfersData,
    setTransfersData,
    isLoading,
    setIsLoading,
    getTransfers,
    filters,
    setFilters,
    filtersData,
    setFiltersData,

    filterParams,
    setFetchParams,

    key,
    setKey,
    openNewTransferModalInDash,
    setOpenNewTransferModalInDash,

    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,

    currentModalData,
    setCurrentModalData,
    setOpenDetailModal,
    openDetailModal,
    isManual,
    setIsManual,
  };
});

export { TransfersProvider };

export default useTransfers;
