import { Padding } from "@zilliondigital/mirage-ui";
import React from "react";

import { Rnd } from "react-rnd";
import styled from "styled-components";
import theme from "~/themes/theme";

interface DraggableModalProps {
  title: string;
  isOpen: boolean;
  children?: JSX.Element;
  onClose: () => void;
}

const ModalWrapper = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${theme.colors.base.dark100};
  width: 100%;
  height: 100%;
`;

const ModalBody = styled.div`
  width: 100%;
  height: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.primary.main};
  padding: 16px;
`;

const ModalTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: ${theme.colors.base.light100};
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 30px;
  color: #ffffff;
`;

const DraggableModal: React.FC<DraggableModalProps> = ({
  title = "Modal",
  isOpen,
  children,
  onClose,
}) => {
  return (
    <>
      {isOpen && (
        <Rnd
          /* Min width and height */
          /* Init on center of screen */

          default={{
            x: window.innerWidth / 2 - 300,
            y: window.innerHeight / 2 - 200,
            width: 600,
            height: 400,
          }}
        >
          <ModalWrapper isOpen={isOpen}>
            <ModalContent>
              <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
                <CloseButton onClick={onClose}>✖</CloseButton>
              </ModalHeader>
              <ModalBody>{children}</ModalBody>
            </ModalContent>
          </ModalWrapper>
        </Rnd>
      )}
    </>
  );
};

export default DraggableModal;
