import {
  AppPartner,
  AppUser,
  AppUserAccount,
  RegisteredPartner,
  UserRegisterResponse,
} from "~/types/index";

// usa um tipo genérico T para a função get
const get = <T>(name: string, parse?: boolean): T | undefined => {
  try {
    const storageValue = localStorage.getItem(name);

    if (storageValue !== null) {
      if (parse) {
        const parsed = JSON.parse(storageValue) as T; // usa o tipo T para o valor parseado
        return parsed;
      }
      return storageValue as unknown as T; // usa o tipo T para o valor não parseado
    }
    return undefined;
  } catch (error: any) {
    console.warn("Error while retrieving data", error);
  }
  return undefined;
};

// usa um tipo genérico T para a função set
const set = <T>(name: string, data: T, parse?: boolean): void => {
  try {
    const value = parse ? JSON.stringify(data) : String(data);

    localStorage.setItem(name, value);
  } catch (error: any) {
    console.warn("Error while storing data:", error);
  }
};

// usa um tipo genérico T para a função merge
const merge = <T>(name: string, data: T): T => {
  try {
    const oldData = get<T>(name); // usa o tipo T para obter o dado antigo

    if (oldData) {
      const merged = { ...oldData, ...data };
      set(name, merged);

      return merged;
    }

    return data;
  } catch (error: any) {
    console.warn("Error while merging data:", error);
  }
  return data;
};

const remove = (name: string) => {
  try {
    localStorage.removeItem(name);
  } catch (error: any) {
    console.warn("Error while removing data:", error);
  }
};

const clear = () => {
  try {
    localStorage.clear();
  } catch (error: any) {
    console.warn("Error while clearing data:", error);
  }
};

const getUser = () => {
  try {
    const user = get<AppPartner>("@app:user", true);

    return user;
  } catch (error: any) {
    console.warn("Error while retrieving user:", error);
  }
};

const setUser = (data: AppPartner) => {
  try {
    set("@app:user", data, true);
  } catch (error: any) {
    console.warn("Error when setting user:", error);
  }
};

const getRegisteredUser = () => {
  try {
    const user = get<UserRegisterResponse>("@app:registereduser", true);

    return user;
  } catch (error: any) {
    console.warn("Error while retrieving user:", error);
  }
};

const setRegisteredUser = (data: UserRegisterResponse) => {
  try {
    set("@app:registereduser", data, true);
  } catch (error: any) {
    console.warn("Error when setting user:", error);
  }
};

const getRegisteredToken = () => {
  try {
    const token = get<string>("@app:registeredtoken", true);

    return token;
  } catch (error: any) {
    console.warn("Error while retrieving token:", error);
  }
};

const setRegisteredToken = (data: string) => {
  try {
    set("@app:registeredtoken", data, true);
  } catch (error: any) {
    console.warn("Error when setting token:", error);
  }
};

const getRegisteredPartner = () => {
  try {
    const partner = get<RegisteredPartner>("@app:registeredpartner", true);

    return partner;
  } catch (error: any) {
    console.warn("Error while retrieving partner:", error);
  }
};

const setRegisteredPartner = (data: RegisteredPartner) => {
  try {
    set("@app:registeredpartner", data, true);
  } catch (error: any) {
    console.warn("Error when setting partner:", error);
  }
};

const getToken = () => {
  try {
    const storedToken = get<string>("@app:token", true);
    return storedToken;
  } catch (error: any) {
    console.warn("Error when retrieving token:", error);
  }
  return undefined;
};

const setToken = (data: string): void => {
  try {
    set("@app:token", data, true);
  } catch (error: any) {
    console.warn("Error when setting token:", error);
  }
};

const getAnyToken = () => {
  try {
    let token = getToken();

    if (!token) {
      token = getRegisteredToken();
    }

    return token;
  } catch (error: any) {
    console.warn("Error when retrieving token:", error);
  }

  return undefined;
};

const getUserAccount = () => {
  try {
    const storedUserAccount = get<AppUserAccount>("@app:useraccount", true);
    return storedUserAccount;
  } catch (error: any) {
    console.warn("Error when retrieving token:", error);
  }
  return undefined;
};

const setUserAccount = (data: AppUserAccount): void => {
  try {
    set("@app:useraccount", data, true);
  } catch (error: any) {
    console.warn("Error when setting token:", error);
  }
};

const setSession = (session: string) => set("@user:session", session);

const getSession = (): string | undefined => get("@user:session");

const setTheme = (theme: string) => set("@app:activeTheme", theme);

const getTheme = (): string | undefined => get("@app:activeTheme");

const AppStorage = {
  get,
  set,
  merge,
  remove,
  clear,

  getRegisteredUser,
  setRegisteredUser,

  getRegisteredToken,
  setRegisteredToken,

  getRegisteredPartner,
  setRegisteredPartner,

  setUser,
  getUser,

  getToken,
  setToken,

  getAnyToken,

  getUserAccount,
  setUserAccount,

  setSession,
  getSession,

  setTheme,
  getTheme,
};

export default AppStorage;
