import constate from "constate";
import { useState } from "react";

const [MySpaceProvider, useMySpace] = constate(() => {
  const [activeIndexMySpace, setActiveIndexMySpace] = useState<number>(-1);
  const [
    activeIndexCollectionsManagement,
    setActiveIndexCollectionsManagement,
  ] = useState<number>(-1);

  return {
    activeIndexMySpace,
    setActiveIndexMySpace,
    activeIndexCollectionsManagement,
    setActiveIndexCollectionsManagement,
  };
});

export { MySpaceProvider };

export default useMySpace;
