import constate from "constate";
import { useCallback, useState } from "react";

import ApiService from "~/services/api";
import useToast from "../useToast";
import useAddBalance from "../useAddBalance";
import { useLocation } from "react-router-dom";
import useToken from "../useToken";

export type PixMyKeysProps = {
  type: string;
  value: string;
  icon: string;
};

const [MyKeysProvider, useMyKeys] = constate(() => {
  const [keyData, setKeyData] = useState<any[]>([]);

  const { getToken } = useToken();

  const [openDeleteKeyModal, setOpenDeleteKeyModal] = useState(false);
  const [
    openValidatorModalCreateNewKeyPix,
    setOpenValidatorModalCreateNewKeyPix,
  ] = useState(false);

  const [pixKeysData, setPixKeysData] = useState<any[]>([]);

  const [openNewKeyModal, setOpenNewKeyModal] = useState<boolean>(false);

  const [idKey, setIdKey] = useState<number>(-1);
  const [keyTypeLabel, setKeyTypeLabel] = useState<string>("");

  const { showToast } = useToast();

  const [typeOfKey, setTypeOfKey] = useState<string>("");

  const [loadingPixKeys, setLoadingPixKeys] = useState<boolean>(false);

  const getPixKeys = async () => {
    setLoadingPixKeys(true);
    try {
      const dataSync = await ApiService.HttpPost({
        route: "pix/sync-key/",
        token: true,
      });

      const { data } = await ApiService.HttpGet({
        route: "pix/key/",
        token: true,
      });

      setPixKeysData(data.results);
      setLoadingPixKeys(false);
    } catch {
      setLoadingPixKeys(false);
    }
  };

  const [loading, setLoading] = useState<boolean>(false);

  const deteleKeyPix = useCallback(async () => {
    setLoading(true);
    try {
      await ApiService.HttpDelete({
        route: `pix/key/${idKey}/`,

        token: true,
      });

      setOpenDeleteKeyModal(false);
      setLoading(false);
      showToast(
        "Removida com sucesso",
        "Sua chave Pix foi removida com sucesso",
        "success"
      );

      setTimeout(async () => {
        getPixKeys();
      }, 5000);
    } catch (e) {
      setOpenDeleteKeyModal(false);
      setLoading(false);
      showToast(
        "Ocorreu um erro",
        "Não foi possível encontrar a chave pix informada ou ela se encontra em um status que não permite cancelamento.",
        "error"
      );

      setTimeout(async () => {
        getPixKeys();
      }, 5000);
    }
  }, [idKey]);

  return {
    openDeleteKeyModal,
    setOpenDeleteKeyModal,
    loadingPixKeys,
    setLoadingPixKeys,
    pixKeysData,
    setPixKeysData,

    idKey,
    setIdKey,

    keyTypeLabel,
    setKeyTypeLabel,

    deteleKeyPix,
    getPixKeys,

    openNewKeyModal,
    setOpenNewKeyModal,

    typeOfKey,
    setTypeOfKey,

    openValidatorModalCreateNewKeyPix,
    setOpenValidatorModalCreateNewKeyPix,

    keyData,
    setKeyData,
  };
});

export { MyKeysProvider };

export default useMyKeys;
