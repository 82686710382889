import { getYearAsString } from "~/utils";
import Description from "./Texts/Description";
import gitInfo from "~/gitinfo";

const Copyright = () => {
  return (
    <div className="d-flex justify-content-center">
      {" "}
      <Description color="#CCCCCC">
        © Zillion {getYearAsString()}
      </Description>
    </div>
  );
};

export default Copyright;
