import { useNavigate } from "react-router-dom";
import {
  CreatingCardConfirmationTableField,
  CreatingCardConfirmationTableValue,
} from "../styled";

export const buildCardDetailsColStatements = () => {
  return [
    {
      label: "",
      key: "field-column-create-card",

      renderData: (data: { field: string; value: string }) => (
        <div
          className="d-flex justify-content-start align-items-center my-2"
          id={`field-${data.field}`}
        >
          <div className="d-flex flex-column justify-content-center align-items-start">
            <CreatingCardConfirmationTableField>
              {data.field}
            </CreatingCardConfirmationTableField>
          </div>
        </div>
      ),
    },
    {
      label: "",
      key: "value-create-card",

      renderData: (data: { field: string; value: string }) => (
        <div
          className="d-flex justify-content-end align-items-center my-2 text-align-end"
          id={`value-${data.field}`}
        >
          <CreatingCardConfirmationTableValue>
            {data.value}
          </CreatingCardConfirmationTableValue>
        </div>
      ),
    },
  ];
};
