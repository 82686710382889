import { Button, Input, Spacer } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import { useNavigate } from "react-router-dom";
import MoreIcon from "~/assets/icons/buttons/more.png";
import useCollections from "~/hooks/Collections/useCollections";
import TableContacts from "~/components/TableContacts";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import styled from "styled-components";
import { useFormik } from "formik";
import { useEffect } from "react";
import useContacts from "~/hooks/Contacts/useContacts";
import { motion } from "framer-motion";
import useTransfers from "~/hooks/Transfers/useTransfers";
import useSplit from "~/hooks/useSplit";

const ContainerBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: white;

  padding: 40px 25px;
`;

const NotFoundContactContainer = styled.div`
  margin: 30px;
  p {
    font-size: large;
  }
`;

const ContainerTableContacts = styled.div`
  overflow-y: auto;
  height: 465px;
`;

type search = {
  search: string;
};

const initialValues: search = {
  search: "",
};

const notFoundContactLabel: string = "Nenhum contato encontrado";

const NewSplitSelectContact = () => {
  const {
    setSelectContact,
    handleTedNextStep,
    newTransferTEDData,

    setChangeSideAnimation,
    changeSideAnimation,
  } = useNewTransfer();

  const { contactsData, setContactsFiltered, setSection, contactsFiltered } =
    useContacts();

  const handleAdvance = () => {
    setStepIndex(stepIndex + 1);
  };

  const handlePrevStep = () => {
    setStepIndex(stepIndex - 1);
  };

  const {
    stepIndex,
    setStepIndex,
    newSplitPayload,
    isEditing,
    setReceivers,
    setDepositIsByKeyPix,
    setOpenNewSplitModal,
    openNewSplitModal,
  } = useSplit();

  const { setCameToCollectionsOrTransfers } = useCollections();

  const { setOpenNewTransferModal } = useTransfers();

  const navigate = useNavigate();

  const { values, handleChange } = useFormik({
    initialValues,
    onSubmit: (values) => {},
  });

  const handleAddContact = () => {
    setOpenNewTransferModal(false);
    setSelectContact(false);

    setCameToCollectionsOrTransfers(true);
    setSection("new");

    navigate("/app/contacts");
  };

  const handleToggleModal = () => {
    setOpenNewSplitModal(!openNewSplitModal);
    setDepositIsByKeyPix(false);
    setReceivers([]);
    localStorage.removeItem("splitOver");
    localStorage.removeItem("values");
    localStorage.removeItem("splitLostDiscount");
  };

  useEffect(() => {
    const filteredContacts = contactsData.filter((contact) => {
      return contact.name.toLowerCase().includes(values.search.toLowerCase());
    });

    setContactsFiltered(filteredContacts);
  }, [values.search]);

  console.log(newSplitPayload?.receivers);

  return (
    <motion.div
      initial={{
        x: changeSideAnimation ? "-100%" : !changeSideAnimation && "100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: changeSideAnimation ? "100%" : !changeSideAnimation && "-100%",
      }}
      transition={{ duration: 0.3 }}
      className="overflow-hidden"
    >
      <div className="p-4 pb-0">
        <Description color="#666666">
          Selecione um contato para adicionar ao arranjo
        </Description>

        {contactsData.length > 0 && (
          <div>
            <Input
              type="text"
              placeholder="Pesquise um contato"
              name="search"
              onChange={handleChange}
              value={values.search}
            />
          </div>
        )}
      </div>
      <ContainerTableContacts>
        {values.search.length > 0 && contactsFiltered.length <= 0 ? (
          <NotFoundContactContainer>
            <p>{notFoundContactLabel}</p>
          </NotFoundContactContainer>
        ) : (
          <TableContacts />
        )}
      </ContainerTableContacts>
      <Spacer bottom={40} />
      <div className="p-4">
        <ContainerBottom className="d-flex justify-content-between container-bottom ">
          <div>
            <Button
              mode="phantom"
              label={isEditing ? "Cancelar" : "Voltar"}
              onClick={() => {
                if (isEditing) {
                  handleToggleModal();
                } else if (newSplitPayload?.receivers?.length > 0) {
                  setStepIndex(4);
                } else {
                  setChangeSideAnimation(true);
                  setSelectContact(false);
                  handlePrevStep();
                }
              }}
            />
          </div>

          <div className="d-flex ">
            {/* <Button
              mode="border"
              label="Editar dados"
              onClick={handleEditContact}
            /> */}
            <Button
              mode="border"
              label="Adicionar contato"
              onClick={handleAddContact}
              icon={MoreIcon}
            />
            <Spacer left={10} />
            <Button
              label="Avançar"
              mode="main"
              onClick={() => {
                setChangeSideAnimation(false);
                handleAdvance();
              }}
              disabled={newTransferTEDData === undefined}
            />
          </div>
        </ContainerBottom>
      </div>
      S
      <Spacer bottom={50} />
    </motion.div>
  );
};

export default NewSplitSelectContact;
