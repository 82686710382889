import constate from "constate";
import { useState } from "react";
import ApiService from "~/services/api";
import useToast from "../useToast";
import useToken from "../useToken";

import useAccount from "../useAccount";

export const manualPixSteps = ["data", "send"];
export const sendPixSteps = ["data", "send"];

const [TransferWithPixProvider, useTransferWithPix] = constate(() => {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const { showToast } = useToast();
  const [selectedRows, setSelectedRow] = useState<any[]>([]);
  const [manualPix, setManualPix] = useState(false);
  const [step, setStep] = useState<string>(
    manualPix ? manualPixSteps[stepIndex] : sendPixSteps[stepIndex]
  );

  const [taxTransferPix, setTaxTransferPix] = useState<number>(0);

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const [contactsPixData, setContactsPixData] = useState<any[]>([]);
  const [contactSelectedPixData, setContactSelectedPixData] = useState<
    any[] | undefined
  >(undefined);

  const [isPixWithSelectedPartner, setIsPixWithSelectedPartner] =
    useState<boolean>(false);

  const [idBankAccountPixPartner, setIdBankAccountPixPartner] =
    useState<number>(-1);

  const handleNextStep = () => {
    setStepIndex(stepIndex + 1);
    setStep(manualPixSteps[stepIndex + 1]);
  };

  const handlePrevStep = () => {
    setStepIndex(stepIndex - 1);
    setStep(manualPixSteps[stepIndex - 1]);
  };

  const [dataNewPixTransfer, setDataNewPixTransfer] = useState<any>({});

  const [
    openValidatorModalTransferWithPix,
    setOpenValidatorModalTransferWithPix,
  ] = useState<boolean>(false);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [validation, setValidation] = useState<boolean>(false);

  const [amount, setAmount] = useState<number>(0);

  const [isLoadingTransferReceipt, setIsLoadingTransferReceipt] =
    useState<boolean>(false);

  const [transferReceiptRender, setTransferReceiptRender] =
    useState<boolean>(false);

  const [dataTransferPixReceipt, setDataTransferPixReceipt] =
    useState<any>(null);

  const { setIsLoadingButton, getToken } = useToken();
  const { getBalance } = useAccount();

  const sendPix = async () => {
    const token = await getToken({
      title: "",
      description: "",
      numberOfInputs: 6,
      sendAgain: "sendToken",
    });

    setIsLoadingButton(true);
    setIsLoadingTransferReceipt(true);

    try {
      const { data: response } = await ApiService.HttpPost({
        route: "pix/transfer/",
        body: {
          amount: amount,
          code: token,
          key: dataNewPixTransfer?.key,
          sender_account: 1,
          type: "key",
        },
        token: true,
      });

      setIsLoadingButton(false);
      setValidation(false);
      if (!response.error) {
        const { data } = response;
        setTransferReceiptRender(true);
        setDataTransferPixReceipt(data);
        setIsLoadingTransferReceipt(false);

        setTimeout(() => {
          showToast(
            "Pix realizado com sucesso",
            "Sua transação foi enviada com sucesso.",
            "success"
          );
        }, 500);

        setTimeout(() => {
          getBalance();
        }, 2000);
      } else {
        showToast("Pix não realizado", response.message, "error");
      }
    } catch (err) {
      console.warn(err);
      setIsLoadingTransferReceipt(false);

      setIsLoadingButton(false);
      setValidation(false);
    }
  };

  return {
    stepIndex,
    setStepIndex,
    step,
    setStep,
    handleNextStep,
    handlePrevStep,
    manualPix,
    setManualPix,

    dataNewPixTransfer,
    setDataNewPixTransfer,
    isLoadingData,
    setIsLoadingData,
    validation,
    setValidation,
    sendPix,
    amount,
    setAmount,

    openValidatorModalTransferWithPix,
    setOpenValidatorModalTransferWithPix,

    isLoadingTransferReceipt,
    setIsLoadingTransferReceipt,
    transferReceiptRender,
    setTransferReceiptRender,

    dataTransferPixReceipt,
    setDataTransferPixReceipt,

    contactsPixData,
    setContactsPixData,
    contactSelectedPixData,
    setContactSelectedPixData,
    isPixWithSelectedPartner,
    setIsPixWithSelectedPartner,
    idBankAccountPixPartner,
    setIdBankAccountPixPartner,
    selectedIndex,
    setSelectedIndex,
    selectedRows,
    setSelectedRow,
    taxTransferPix,
    setTaxTransferPix,
  };
});

export { TransferWithPixProvider };

export default useTransferWithPix;
