import { ActivateCardSteps, ActivatingCardStatus, Card } from "~/hooks/Cards/interfaces";
import { _returnReceivedCardStepComponent, _returnSuccessActivateCardComponent } from "./activateCardReceivedStepFunctions";

interface IActivateCardReceivedStep {
  selectedCard: Card,
  setActivateCardStep: React.Dispatch<React.SetStateAction<ActivateCardSteps>>;
  activateCardStep: ActivateCardSteps;
  setActivatingCardStatus: React.Dispatch<React.SetStateAction<ActivatingCardStatus>>;
  activatingCardStatus: ActivatingCardStatus;
  postActivateBankCard: (cvv: string) => void;
  cvv: string;
  setCVV: React.Dispatch<React.SetStateAction<string>>;
}
const ActivateCardReceivedStep = (props: IActivateCardReceivedStep) => {
  if (props.activatingCardStatus == 'SUCCESS'){
    return _returnSuccessActivateCardComponent()
  }else{
    return _returnReceivedCardStepComponent({...props})
  }
};

export default ActivateCardReceivedStep;
