import { Card, Transaction, TransactionDTO } from "./interfaces";


export const recentTransactionsFromDTO = (data: TransactionDTO[]):Transaction[] => {

  const transactions: Transaction[] = data.map((transactionDTO) => {
    return {
      ...transactionDTO,
      created_at: new Date(transactionDTO.created_at),
      finished_at: new Date(transactionDTO.finished_at)
    }
  })
  return transactions
}

export const orderCardsFromDTO = (data: Card[]): Card[] => {
  const activatedCards = data.filter((card) => card.status == 'ACTIVATED')
  const pendingCards = data.filter((card) => card.status == 'PENDING')
  const processingCards = data.filter((card) => card.status == 'PROCESSING')
  const blockedCards = data.filter((card) => card.status == 'BLOCKED')
  const expiredCards = data.filter((card) => card.status == 'EXPIRED')

  return [...activatedCards, ...pendingCards, ...blockedCards, ...processingCards, ...expiredCards]
}