import useAccount from "~/hooks/useAccount";
import { PhoneRegex } from "./validators";

export const hasLength = (str: string, length: number) => {
  return str.length >= length;
};

export const hasLowerCase = (str: string) => {
  return str.toUpperCase() !== str;
};

export const hasUpperCase = (str: string) => {
  return str.toLowerCase() !== str;
};

export const hasSpecialCharacter = (str: string) => {
  return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
};

export const hasNumber = (str: string) => {
  return /\d/.test(str);
};

export const passwordStrength = (password: string) => {
  const strengthSteps = [];

  if (password !== "") strengthSteps.push(0);
  if (password.length >= 8) strengthSteps.push(1);
  if (hasNumber(password)) strengthSteps.push(2);
  if (hasLowerCase(password) && hasUpperCase(password)) strengthSteps.push(3);
  if (hasSpecialCharacter(password)) strengthSteps.push(4);

  return strengthSteps;
};

export const passwordStrengthPercentage = (password: string) => {
  return (100 * passwordStrength(password).length) / 5;
};

export const passwordStrengthText = (password: string) => {
  const percentage = passwordStrengthPercentage(password);

  if (percentage > 60 && percentage < 90) {
    return "Sua senha precisa melhorar";
  } else if (percentage >= 90) {
    return "Sua senha parece bem segura";
  }

  return percentage > 0 ? "Sua senha está fraca" : "";
};

export const passwordLevel = (password: string) => {
  const percentage = passwordStrengthPercentage(password);

  if (percentage < 40) {
    return "easy";
  } else if (percentage >= 40 && percentage < 70) {
    return "medium";
  } else if (percentage >= 70) {
    return "hard";
  }
};

export const onlyNumbers = (str: string) => {
  return str?.replace(/\D/g, "");
};

export const getFirstLetters = (fullName: string) => {
  if (fullName !== null && fullName !== undefined && fullName !== "") {
    const names = fullName.split(" ");

    const initials = names
      .slice(0, 2)
      .filter((name) => name.length > 0)
      .map((name) => name.charAt(0))
      .join("");

    return initials;
  }
};
const isWeekdayAndWorkingHours = (): boolean => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();

  const currentHour = currentDate.getHours() + currentDate.getMinutes() / 60;

  const isWeekday = currentDay >= 1 && currentDay <= 5;

  const isWorkingHours = currentHour >= 7 && currentHour < 16.5;

  return isWeekday && isWorkingHours;
};

export const isWeekDay = isWeekdayAndWorkingHours();
export const isWorkingHours = isWeekdayAndWorkingHours();

const isWeekdayAndWorkingHoursPayments = (): boolean => {
  const currentDay = new Date().getDay();
  const currentHour = new Date().getHours();

  const isWeekdayPayments = currentDay >= 1 && currentDay <= 5;
  const isWorkingHoursPayments = currentHour >= 7 && currentHour < 21;

  return isWeekdayPayments && isWorkingHoursPayments;
};

export const isWeekDayPayments = isWeekdayAndWorkingHoursPayments();
export const isWorkingHoursPayments = isWeekdayAndWorkingHoursPayments();

export const verifyObjectIsEmpty = (obj: any) => {
  return Object.keys(obj).length === 0;
};

let create_array = (total: number, numero: number) =>
  Array.from(Array(total), () => number_random(numero));
let number_random = (number: number) => Math.round(Math.random() * number);
let mod = (dividendo: number, divisor: number) =>
  Math.round(dividendo - Math.floor(dividendo / divisor) * divisor);

export const generateCPF = (masked: boolean = false) => {
  let total_array = 9;
  let n = 9;
  let [n1, n2, n3, n4, n5, n6, n7, n8, n9] = create_array(total_array, n);

  let d1 =
    n9 * 2 +
    n8 * 3 +
    n7 * 4 +
    n6 * 5 +
    n5 * 6 +
    n4 * 7 +
    n3 * 8 +
    n2 * 9 +
    n1 * 10;
  d1 = 11 - mod(d1, 11);
  if (d1 >= 10) d1 = 0;

  let d2 =
    d1 * 2 +
    n9 * 3 +
    n8 * 4 +
    n7 * 5 +
    n6 * 6 +
    n5 * 7 +
    n4 * 8 +
    n3 * 9 +
    n2 * 10 +
    n1 * 11;
  d2 = 11 - mod(d2, 11);
  if (d2 >= 10) d2 = 0;

  if (masked) {
    return `${n1}${n2}${n3}.${n4}${n5}${n6}.${n7}${n8}${n9}-${d1}${d2}`;
  } else {
    return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}${n9}${d1}${d2}`;
  }
};

export const generateCNPJ = (masked: boolean = false) => {
  let total_array = 8;
  let n = 9;
  let [n1, n2, n3, n4, n5, n6, n7, n8] = create_array(total_array, n);
  let n9 = 0;
  let n10 = 0;
  let n11 = 0;
  let n12 = 1;

  let d1 =
    n12 * 2 +
    n11 * 3 +
    n10 * 4 +
    n9 * 5 +
    n8 * 6 +
    n7 * 7 +
    n6 * 8 +
    n5 * 9 +
    n4 * 2 +
    n3 * 3 +
    n2 * 4 +
    n1 * 5;
  d1 = 11 - mod(d1, 11);
  if (d1 >= 10) d1 = 0;

  let d2 =
    d1 * 2 +
    n12 * 3 +
    n11 * 4 +
    n10 * 5 +
    n9 * 6 +
    n8 * 7 +
    n7 * 8 +
    n6 * 9 +
    n5 * 2 +
    n4 * 3 +
    n3 * 4 +
    n2 * 5 +
    n1 * 6;
  d2 = 11 - mod(d2, 11);
  if (d2 >= 10) d2 = 0;

  if (masked) {
    return `${n1}${n2}.${n3}${n4}${n5}.${n6}${n7}${n8}/${n9}${n10}${n11}${n12}-${d1}${d2}`;
  } else {
    return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}${n9}${n10}${n11}${n12}${d1}${d2}`;
  }
};

export const generateCellphone = (
  masked: boolean = false,
  ddd: number = 11,
  prefix: number = 9
) => {
  let total_array = 8;
  let n = 9;
  let [n1, n2, n3, n4, n5, n6, n7, n8] = create_array(total_array, n);

  if (masked) {
    return `(${ddd}) ${prefix}${n1}${n2}${n3}${n4}-${n5}${n6}${n7}${n8}`;
  } else {
    return `${ddd}${prefix}${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}`;
  }
};

export const generateRG = (masked: boolean = false) => {
  let total_array = 8;
  let n = 9;
  let [n1, n2, n3, n4, n5, n6, n7, n8] = create_array(total_array, n);

  if (masked) {
    return `${n1}${n2}.${n3}${n4}${n5}.${n6}${n7}${n8}`;
  } else {
    return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}`;
  }
};

export const generateZipcode = (masked: boolean = false) => {
  let total_array = 8;
  let n = 9;
  let [n1, n2, n3, n4, n5, n6, n7, n8] = create_array(total_array, n);

  if (masked) {
    return `${n1}${n2}${n3}${n4}${n5}-${n6}${n7}${n8}`;
  } else {
    return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}`;
  }
};

export const setCustomSelectDefaultValue = (
  value: any,
  options: any[]
): any => {
  const option = options.find((option) => option.value === value);

  return option;
};

export const CustomSelectValue = (value: any, options: any[]): any => {
  const option = options.find((option) => option.value === value);

  return option ? option : null;
};

export const generateId = (size: number = 6) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < size) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getDateInfo = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Mês é baseado em zero (0-11), então adicionamos 1.
  const firstDayOfMonth = 1;
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();

  return {
    firstDayOfMonth,
    lastDayOfMonth,
    currentMonth,
    currentYear,
  };
};

export const obscureEmail = (email: string): string => {
  const [username, domain] = email.split("@");

  if (username.length < 3) {
    return email;
  }

  const firstTwoChars = username.slice(0, 2);

  const lastTwoChars = username.slice(-2);

  const obscuredEmail = `${firstTwoChars}**********${lastTwoChars}@${domain}`;

  return obscuredEmail;
};

export const optionsPhoneCode = [{ label: "+55", value: "1" }];

export const contactType = [
  {
    label: "Cliente",
    value: "customer",
  },
  {
    label: "Fornecedor",
    value: "supplier",
  },
  {
    label: "Prestador de serviço",
    value: "service_provider",
  },
  {
    label: "Funcionário",
    value: "employee",
  },
  {
    label: "Diretoria",
    value: "management",
  },
];

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePhoneNumber = (phone: string): boolean => {
  return PhoneRegex.test(phone);
};
