import React, { useEffect } from "react";
import { ActivateCardCheckingStepDiv, ActivateCardTitle, ActivateCardContainer, CardsDashboardHeader, ActivateCardReceivedWrapperDiv, ActivateCardInputWrapper, CreatingCardInfoContainer, CreatingCardInfoBtnWrapper, CreatingCardInfoInput, CreatingCardInfoCard, CardTypeLabelText, CreateCardInformationStepDiv, CreateCardInfosInputWrapper, CreateCardInfoFooterBtnWrapper } from "../styled";
import RadioIcon from '~/assets/icons/buttons/button_radio.svg'
import RadioIconChecked from '~/assets/icons/buttons/button_radio_checked.svg'
import { ICreateCardInfos } from "~/hooks/Cards/initalValues";
import { ActivateCardSteps, CreateCardStep } from "~/hooks/Cards/interfaces";
import { Button, Input } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";

interface ICreateCardInfoStepSection {
  setCreateCardStep: React.Dispatch<React.SetStateAction<CreateCardStep>>;
  createCardStep: CreateCardStep;
  createCardInfos: ICreateCardInfos;
  setCreateCardValues: (info: string, value: string | null) => void;
}
const CreateCardInfoStepSection = (props: ICreateCardInfoStepSection) => {
  const navigate = useNavigate()

  useEffect(() => {
    props.setCreateCardValues('other_address', null)
  }, [])
  return (
    <CreateCardInformationStepDiv>
      <CardTypeLabelText>
        Selecione o tipo de cartão
      </CardTypeLabelText>
      <CreatingCardInfoContainer>
        <CreatingCardInfoBtnWrapper>
          <label>
            <CreatingCardInfoInput 
              type="radio" 
              name="card_type" 
              onClick={(e) => {
                e.currentTarget.checked && 
                props.setCreateCardValues('card_type', 'PLASTIC')
              }}
            />
            <CreatingCardInfoCard 
              className={`
                card-input
                ${props.createCardInfos.card_type == 'PLASTIC' ? 'CHECKED' : ''}
              `}>
              <div className="d-flex align-items-center justify-content-center" style={{width: '32px', minHeight: '32px'}}>
                <img src={props.createCardInfos.card_type == 'PLASTIC' ? RadioIconChecked : RadioIcon}/>
              </div>
              <h4>Cartão físico</h4>
              <p>Cartão utilizado em estabelecimentos físicos</p>
            </CreatingCardInfoCard>
          </label>

          <label>
            <CreatingCardInfoInput type="radio" 
              name="card_type" 
              onClick={(e) => {
                e.currentTarget.checked && 
                props.setCreateCardValues('card_type', 'VIRTUAL')
              }}/>
            <CreatingCardInfoCard 
              className={`
              card-input
              ${props.createCardInfos.card_type == 'VIRTUAL' ? 'CHECKED' : ''}
            `}>
              <div className="d-flex align-items-center justify-content-center" style={{width: '32px', minHeight: '32px'}}>
                <img src={props.createCardInfos.card_type == 'VIRTUAL' ? RadioIconChecked : RadioIcon}/>
              </div>
              <h4>Cartão virtual</h4>
              <p>Cartão utilizado para transações online</p>
            </CreatingCardInfoCard>
          </label>
        </CreatingCardInfoBtnWrapper>

        <CreateCardInfosInputWrapper>
          <label>
            Apelido do cartão 
          </label>
          <Input 
            type="text" 
            maxLength={20} 
            value={props.createCardInfos.name}
            style={{width: '250px'}}
            onChange={(e) => {
              if(typeof e.currentTarget.value != "undefined"){
                props.setCreateCardValues('name', e.currentTarget.value)}
              }
            }
          />
        </CreateCardInfosInputWrapper>

        <CreateCardInfoFooterBtnWrapper>
          <Button 
            mode="border" style={{width: '30%'}} label="Cancelar"
            onClick={() => {navigate('/app/cards')}}
          />
          <Button 
            mode="main" style={{width: '30%'}} label="Avançar"
            onClick={() => {
              if(props.createCardInfos.card_type == 'PLASTIC'){
                props.setCreateCardStep('DELIVERY_INFO_OTHER')
              }else{
                props.setCreateCardStep('CONFIRMATION')
              }
            }}
          />
        </CreateCardInfoFooterBtnWrapper>
      </CreatingCardInfoContainer>
    </CreateCardInformationStepDiv>
  );
};

export default CreateCardInfoStepSection;
