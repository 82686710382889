import constate from "constate";
import { useState } from "react";

type ToastMode = "success" | "error" | "warning";

type ToastPosition = "top-right" | "top-left" | "bottom-right" | "bottom-left";

type ToastProps = {
  id: string;
  mode: ToastMode;
  title: string;
  text: string;
  position: ToastPosition;
  active: boolean;
  onClick?: () => void;
};

const [ToastProvider, useToast] = constate(() => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const removeToast = (id: ToastProps["id"]) => {
    setToasts((prevToasts) =>
      prevToasts.map((toast) =>
        toast.id === id ? { ...toast, active: false } : toast
      )
    );

    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, 500);
  };

  const showToast = (
    title: ToastProps["title"],
    message: ToastProps["text"],
    mode: ToastProps["mode"],
    onClick?: ToastProps["onClick"],
    boolean: ToastProps["active"] = true,
    time: number = 5000,
    position: ToastProps["position"] = "top-right"
  ) => {
    const random = Math.random() * 1000;
    const id = Date.now().toString() + random.toString();

    const newToast = {
      id,
      title,
      text: message,
      mode,
      onClick,
      active: boolean,
      position,
    };

    setToasts((prevToasts) => [...prevToasts, newToast]);

    setTimeout(() => {
      removeToast(id);
    }, time);
  };

  return {
    toasts,
    setToasts,

    removeToast,
    showToast,
  };
});

export { ToastProvider };

export default useToast;
