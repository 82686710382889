import { LateralModal, ProgressBar } from "@zilliondigital/mirage-ui";
import { Fragment, useEffect } from "react";
import useCollections from "~/hooks/Collections/useCollections";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import { motion } from "framer-motion";
import useAddBalance from "~/hooks/useAddBalance";
import useSplit from "~/hooks/useSplit";
import EditReceiverData from "./EditReceiversData";
import NewSplistSelectBankAccountOrKeyPix from "../../NewSplit/NewSplitSelectBankAccountOrKeyPix";

const EditReceiversModal = () => {
  const {
    openNewSplitModal,
    setOpenNewSplitModal,
    stepIndex,
    setStepIndex,
    depositIsByKeyPix,
    setDepositIsByKeyPix,
    setReceivers,
    isEditing,
    openEditReceiverModal,
    setOpenEditReceiverModal,
    setSelectedValue,
  } = useSplit();

  const handleToggleModal = () => {
    setOpenEditReceiverModal(!openEditReceiverModal);
    setDepositIsByKeyPix(false);
    setReceivers([]);
  };

  const RenderEditReceivers: React.ReactNode[] = [
    <EditReceiverData />,
    <NewSplistSelectBankAccountOrKeyPix />,
  ];

  useEffect(() => {
    if (!openNewSplitModal) {
      setStepIndex(0);
      setSelectedValue("");
    }
  }, [openNewSplitModal]);

  const ModalTitles: any = {
    0: "Definir meio de pagamento",
  };

  return (
    <LateralModal
      open={openEditReceiverModal}
      title={ModalTitles[stepIndex]}
      toggleModal={handleToggleModal}
      style={{ zIndex: "3" }}
      header
    >
      <Fragment>
        <ProgressBar percent={100} />

        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.3 }}
          style={{ height: "100vh" }}
          className="p-4 "
        >
          {RenderEditReceivers[stepIndex]}
        </motion.div>
      </Fragment>
    </LateralModal>
  );
};

export default EditReceiversModal;
