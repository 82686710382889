import Description from "~/components/Texts/Description";
import useSplit from "~/hooks/useSplit";
import { Title } from "~/pages/Authentication/Auth.styles";
import theme from "~/themes/theme";
import {
  convertStringToNumber,
  formatCPFCNPJ,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Input,
  InputCurrency,
  NotControlledToolTip,
  Spacer,
} from "@zilliondigital/mirage-ui";
import styled, { keyframes } from "styled-components";
import PenIcon from "~/assets/icons/contacts/pencil.png";
import { useEffect, useState } from "react";
import ApiService from "~/services/api";
import useContacts from "~/hooks/Contacts/useContacts";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import useTransfers from "~/hooks/Transfers/useTransfers";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import { APIResponse, getSplit } from "~/types";
import useToast from "~/hooks/useToast";

type RenderPercentType = {
  [key: string]: number;
};

type SplitAmount = {
  amount: string;
};

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);
  
    /* Cor que deseja quando a imagem estiver completamente expandida */
  }
`;

const ImgEye = styled.img`
  height: 27px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  cursor: pointer;

  position: relative;
  left: 30px;
  top: 25px;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2116%)
      hue-rotate(191deg) brightness(98%) contrast(101%);
  }
`;

const FormSchema = Yup.object().shape({
  amount: Yup.string(),
});

const EditReceiverData = () => {
  const {
    setDepositIsByKeyPix,
    setReceivers,

    openEditReceiverModal,
    setOpenEditReceiverModal,
    beneficiarieData,
    setStepIndex,
    setSplitData,
    splitData,
  } = useSplit();

  const handleToggleModal = () => {
    setOpenEditReceiverModal(!openEditReceiverModal);
    setDepositIsByKeyPix(false);
    setReceivers([]);
    resetForm();
  };

  const handleFormSubmit = (values: any) => {
    return;
  };

  const initialValues: SplitAmount = {
    amount: String(beneficiarieData.value),
  };

  const { handleChange, setFieldValue, values, resetForm } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleFormSubmit,
  });

  const { setContactsData, contactsData } = useContacts();

  const { setNewTransferTEDData, newTransferTEDData } = useNewTransfer();

  type Types = {
    [key: string]: string;
  };

  const renderDepositType: Types = {
    pix_transfer: "Transferência PIX",
    pix_manual_transfer: "Transferência PIX",
    tev: "TEV - Transferência entre contas",
    ted: "Tranferência TED",
  };

  const getContact = async (id: number) => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "partner/",
        token: true,
        params: {
          type: "contact",
          default: false,
        },
      });
      setContactsData(data.results);

      const selectedContact = contactsData.filter(
        (contactsData: any) => contactsData.id === id
      );

      setNewTransferTEDData(selectedContact[0]);

      setStepIndex(1);
    } catch {}
  };

  const [isloading, setIsloading] = useState<boolean>(false);

  const toggleModal = () => {
    setOpenEditReceiverModal(!openEditReceiverModal);
  };

  const { showToast } = useToast();

  const EditBeneficiarie = async () => {
    setIsloading(true);

    try {
      const data = await ApiService.HttpPut({
        route: `charge/split/${splitData.id}/update-beneficiary/${beneficiarieData.id}/`,
        body: {
          value:
            beneficiarieData?.value_type === "percentage"
              ? Number(values.amount)
              : convertStringToNumber(values.amount),
          value_type: beneficiarieData?.value_type,
        },
        token: true,
      });

      setIsloading(false);
      toggleModal();

      showToast("Dados atualizados com sucesso", "", "success");
      resetForm();

      const dataUpdated = await ApiService.HttpGet<APIResponse<getSplit>>({
        route: "charge/split/",
        token: true,
      });

      setSplitData(
        dataUpdated.data.results.filter(
          (item: any) => item.id === splitData.id
        )[0]
      );
    } catch {
      setIsloading(false);
      toggleModal();
      showToast("Nao foi possível atualizar os dados", "", "error");
    }
  };

  useEffect(() => {
    // setContactInfo({
    //   id: selectedContact[0]?.id,
    //   name: selectedContact[0]?.name,
    //   document_number: selectedContact[0]?.document_number,
    // });

    setFieldValue("amount", beneficiarieData?.value);
  }, []);

  return (
    <div className="p-4 d-flex flex-column">
      <Title style={{ fontSize: "23px" }}>
        {beneficiarieData.contact_data?.name}
      </Title>
      <Description color={theme.colors.base.dark200}>
        {formatCPFCNPJ(beneficiarieData.contact_data?.document_number)}{" "}
      </Description>
      <Spacer bottom={40} />
      <p className="h5">Redefina o percentual ou taxa fixa</p>
      <Spacer bottom={-5} />
      {beneficiarieData?.value_type === "percentage" ? (
        <Input
          type="text"
          label={`Redefina o valor a ser redistribuído para ${beneficiarieData.contact_data?.name}`}
          name={`amount`}
          value={values.amount}
          mask={"999"}
          isPercentage
          onChange={(e) => {
            handleChange(e);
            values.amount = e.target.value;
            setFieldValue(`amount`, e.target.value);
          }}
        />
      ) : (
        <InputCurrency
          label={`Redefina o valor a ser redistribuído para ${beneficiarieData.contact_data?.name}`}
          inputSize="normal"
          name={`amount`}
          value={values.amount}
          onChange={(e) => {
            handleChange(e);
            values.amount = e.target.value;
            setFieldValue(`amount`, e.target.value);
          }}
        />
      )}

      {beneficiarieData?.deposit_bank_account_data ? (
        <div className="d-flex">
          <Spacer top={-15} />
          <div>
            <p>
              {beneficiarieData?.deposit_bank_account_data.bank?.code} - {""}
              {beneficiarieData?.deposit_bank_account_data.bank?.name}
            </p>
            <Spacer bottom={-15} />
            <p>
              <b> Agência:</b>{" "}
              {beneficiarieData?.deposit_bank_account_data?.agency} -
              <b> Conta:</b>{" "}
              {beneficiarieData?.deposit_bank_account_data?.account_number}-
              {
                beneficiarieData?.deposit_bank_account_data
                  ?.account_number_digit
              }{" "}
            </p>
          </div>

          {/* <ImgEye
            style={{ height: "20px" }}
            id={`edit`}
            src={PenIcon}
            onClick={() => {
              getContact(beneficiarieData?.contact);
            }}
          />
          <NotControlledToolTip placement="top" target={`edit`}>
            {"Editar"}
          </NotControlledToolTip> */}
        </div>
      ) : (
        <div className="d-flex">
          <Spacer top={-15} />
          <p>
            <b>Chave:</b> {beneficiarieData?.deposit_pix_key_data?.key}
          </p>

          {/* <ImgEye
            className="key"
            style={{ height: "20px" }}
            id={`edit`}
            src={PenIcon}
            onClick={() => {
              getContact(beneficiarieData?.contact);
            }}
          />
          <NotControlledToolTip placement="top" target={`edit`}>
            {"Editar"}
          </NotControlledToolTip> */}
        </div>
      )}
      <Spacer bottom={-15} />
      <p>{renderDepositType[beneficiarieData?.deposit_type]}</p>

      <Spacer bottom={100} />
      <div className="d-flex justify-content-between">
        <Button mode="phantom" label="Cancelar" onClick={handleToggleModal} />
        <Button
          mode="main"
          label="Salvar alterações"
          onClick={EditBeneficiarie}
          // disabled={
          //   values.amount.includes("0,00") || values.amount.includes("0.00")
          // }
        />
      </div>
    </div>
  );
};

export default EditReceiverData;
