import "bootstrap/dist/css/bootstrap.min.css";
import { LateralModal, Button, Spacer } from "@zilliondigital/mirage-ui";

import Description from "~/components/Texts/Description";
import useAccount from "~/hooks/useAccount";
import theme from "~/themes/theme";
import label from "~/labels";

const HelperModal = () => {
  const { openHelpModal, setOpenHelpModal } = useAccount();

  return (
    <LateralModal
      open={openHelpModal}
      header
      title={"Ajuda e suporte"}
      toggleModal={() => {
        setOpenHelpModal(!openHelpModal);
      }}
    >
      <div className="p-4 d-flex flex-column">
        <Description className="fw-bold">Canais de atendimento</Description>
        <Spacer bottom={10} />
        <div className="row">
          <div className="col-6 d-flex flex-column">
            <Spacer bottom={5} />
            <Description>
              {label.helpPhone !== "" ? label.helpPhone : "-"}
            </Description>
            <Description color={theme.colors.base.dark200}>
              TELEFONE PARA CONTATO
            </Description>
            <Spacer bottom={10} />
          </div>

          {label.helpRamal !== "" && (
            <div className="col-6  d-flex flex-column">
              <Description>{label.helpRamal}</Description>
              <Description color={theme.colors.base.dark200}>
                RAMAIS
              </Description>
              <Spacer bottom={10} />
            </div>
          )}

          {label.sac !== "" && (
            <div className="col-6  d-flex flex-column">
              <Description>{label.sac}</Description>
              <Description color={theme.colors.base.dark200}>SAC</Description>
              <Spacer bottom={10} />
            </div>
          )}

          {label.helpEmail !== "" && (
            <div className="col-12  d-flex flex-column">
              <Description>{label.helpEmail}</Description>
              <Description color={theme.colors.base.dark200}>EMAIL</Description>
              <Spacer bottom={20} />
            </div>
          )}

          <div className="col-12  d-flex flex-column">
            <Description className="fw-bold">
              Horário de atendimento
            </Description>
            <Spacer bottom={5} />

            <Description color={theme.colors.base.dark200}>
              {label.helpSupportTime !== "" ? label.helpSupportTime : "-"}
            </Description>
          </div>
        </div>
      </div>
    </LateralModal>
  );
};

export default HelperModal;
