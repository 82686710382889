import constate from "constate";
import { useState } from "react";

const [AnimationProvider, useAnimation] = constate(() => {
  const [changeSideAnimation, setChangeSideAnimation] =
    useState<boolean>(false);

  return {
    changeSideAnimation,
    setChangeSideAnimation,
  };
});

export { AnimationProvider };

export default useAnimation;
