import {
  Button,
  CustomSelect,
  Input,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import Description from "~/components/Texts/Description";
import { ufOptions } from "~/data/options";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import * as Yup from "yup";
import useCEP from "~/hooks/useCEP";
import { useEffect } from "react";
import {
  CpfRegex,
  INVALID_FORMAT,
  PhoneRegex,
  REQUIRED_LABEL,
} from "~/services/validators";
import useCollections from "~/hooks/Collections/useCollections";
import { motion } from "framer-motion";
import useAnimation from "~/hooks/useAnimation";

type FormValues = {
  name: string;
  document_number: string;
  email: string;
  phone: string;
  cep: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
};

const initialValues: FormValues = {
  name: "",
  document_number: "",
  email: "",
  phone: "",
  cep: "",
  street: "",
  number: "",
  complement: "",
  district: "",
  city: "",
  state: "",
};

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(CpfRegex, INVALID_FORMAT),
  email: Yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
  phone: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(PhoneRegex, INVALID_FORMAT),
  cep: Yup.string().required("Campo obrigatório"),
  street: Yup.string().required("Campo obrigatório"),
  number: Yup.string().required("Campo obrigatório"),
  complement: Yup.string().required("Campo obrigatório"),
  district: Yup.string().required("Campo obrigatório"),
  city: Yup.string().required("Campo obrigatório"),
  state: Yup.string().required("Campo obrigatório"),
});

const ContactData = () => {
  const { handleNextStep, setSelectContact } = useNewCollection();

  const { handleCep, address, setAddress } = useCEP();
  const { openNewCollectionOrRecurrenceModal } = useCollections();
  const { setChooseNewCollection, setSelectedValue } = useNewCollection();

  const { changeSideAnimation } = useAnimation();

  const { values, errors, handleBlur, touched, handleChange, resetForm } =
    useFormik({
      initialValues: initialValues,
      validationSchema: FormSchema,
      onSubmit: (values) => {},
    });

  useEffect(() => {
    if (!openNewCollectionOrRecurrenceModal) {
      resetForm();
    }
  }, [openNewCollectionOrRecurrenceModal]);

  return (
    <motion.div
      initial={{
        x: changeSideAnimation ? "-100%" : !changeSideAnimation && "100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: changeSideAnimation ? "100%" : !changeSideAnimation && "-100%",
      }}
      transition={{ duration: 0.3 }}
      className="vh-100"
    >
      <div className="p-4">
        <Description>
          Para continuar é necessário informar os dados do seu cliente
        </Description>
        <Spacer bottom={20} />

        <div className="row d-flex align-items-end">
          <div className="col-6">
            <Input
              label="Nome / Razão Social"
              placeholder="ACME INC"
              // success={touched.name && !errors.name} need to fix
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              required={values.name.length < 3}
            />
          </div>
          <div className="col-6">
            <Input
              label="CPF/CNPJ"
              placeholder="123.456.789-01"
              required={
                values.document_number.length < 14 ||
                (values.document_number.length > 14 &&
                  values.document_number.length < 18)
              }
              mask={
                values.document_number.length > 14
                  ? "99.999.999/9999-99"
                  : "999.999.999-9999999"
              }
              type="text"
              name="document_number"
              value={values.document_number}
              success={
                values.document_number.length > 13 &&
                values.document_number.length >= 18
                  ? true
                  : values.document_number.length > 13 &&
                    values.document_number.length < 15
                  ? true
                  : false
              }
              error={touched.document_number && errors.document_number}
              onChange={handleChange}
              autoCapitalize="off"
              autoComplete="off"
            />
          </div>
          <div className="col-6">
            <Input
              type="email"
              required={values.email.includes("@") ? false : true}
              success={values.email.includes("@")}
              name="email"
              value={values.email}
              label="Seu melhor e-mail"
              placeholder="jhon.doe@gmail.com"
              error={touched.email && errors.email}
              onChange={handleChange}
              autoCapitalize="off"
              autoComplete="email"
            />
          </div>
          <div className="col-6">
            <Input
              mask={"(99) 99999-9999"}
              type="tel"
              required={values.phone.length < 15}
              success={values.phone.length > 14}
              name="phone"
              label="Número de celular"
              value={values.phone}
              placeholder="(00) 99887-7665"
              error={touched.phone && errors.phone}
              // success={!errors.phone} need to fix
              onChange={handleChange}
              autoCapitalize="off"
              autoComplete="off"
            />
          </div>
          <div className="col-6">
            <Input
              mask="99999-999"
              type="text"
              name="zip_code"
              label="CEP"
              placeholder="04313-110"
              error={address.error}
              onChange={handleCep}
              search
            />
          </div>
          <div className="col-6"></div>
          <div className="col-9">
            <Input
              type="text"
              name="street"
              value={address.street}
              label="Logradouro"
              placeholder="Rua Borboletas Psicodélicas"
              autoCapitalize="words"
              onChange={handleChange}
              success={address.street ? true : false}
              error={address.street === "" && errors.street}
            />
          </div>
          <div className="col-3">
            <Input
              type="text"
              name="number"
              value={values.number}
              label="Número"
              placeholder="269"
              error={touched.number && errors.number}
              onChange={handleChange}
              success={values.number ? true : false}
            />
          </div>

          <div className="col-6">
            <Input
              type="text"
              name="complement"
              value={values.complement}
              label="Complemento"
              placeholder="Casa 1"
              success={values.complement.length > 1 ? true : false}
              onChange={handleChange}
            />
          </div>
          <div className="col-6">
            <Input
              type="text"
              name="district"
              value={address.district}
              label="Bairro"
              placeholder="Vila Guarani"
              onChange={handleChange}
              success={address.district ? true : false}
              autoComplete="off"
              autoCapitalize="off"
              error={address.district === "" && errors.district}
            />
          </div>

          <div className="col-6">
            <Input
              type="text"
              name="city"
              value={address.city}
              success={address.city ? true : false}
              label="Cidade"
              placeholder="São Paulo"
              onChange={handleChange}
              autoComplete="city"
              error={address.city === "" && errors.city}
            />
          </div>
          <div className="col-6">
            <CustomSelect
              name="state"
              label="UF"
              placeholder="Selecione"
              options={ufOptions}
              value={ufOptions.find((uf) => uf.value === address.state)}
              menuPlacement="top"
            />
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between">
          <Button
            mode="phantom"
            label="Cancelar"
            onClick={() => {
              setSelectedValue("");
              setChooseNewCollection("none");
            }}
          />
          <div className="d-flex">
            {" "}
            <Button
              mode="border"
              label="Selecionar contato"
              onClick={() => setSelectContact(true)}
            />
            <Spacer left={10} />
            <Button mode="main" label="Avançar" onClick={handleNextStep} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactData;
