import "bootstrap/dist/css/bootstrap.min.css";
import { Spacer, Table } from "@zilliondigital/mirage-ui";
import UserIcon from "~/assets/icons/user.svg";
import { useNavigate } from "react-router-dom";
import Title from "~/components/Texts/Title";
import Skeleton from "~/components/SkeletonTables";
import {
  ParentContainer,
  ContainerData,
  GeneralContainer,
} from "~/pages/App/app-content.styles";
import styled from "styled-components";
import theme from "~/themes/theme";
import Copyright from "~/components/Copyright";
import Paginator from "~/components/Paginator";
import SubMenu from "~/components/SubMenu";

import useSplit from "~/hooks/useSplit";
import usePermission from "~/hooks/usePermission";
import { useEffect } from "react";
import useMySpace from "~/hooks/useMySpace";

const Card = styled.div`
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
  box-shadow: 0px 3px 1px 0px rgb(0 0 0 / 3%);
  padding: 10px;

  .empty-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem 1.5rem 1.5rem;
    flex-direction: column;

    span {
      text-align: center;
      display: block;
      margin: 15px 0;
    }

    img {
      filter: ${theme.colors.primary.mainFilter};
    }

    button {
      width: 100%;
      background: white;

      img {
        width: 15px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;

    .list {
      padding-left: 0px;
      width: 100%;

      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .item-box {
          padding: 10px 1.5rem;

          &:hover {
            background: rgba(0, 0, 0, 0.03);
          }
        }

        .desc {
          margin-bottom: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .footer-box {
    display: flex;
    padding: 15px 1.5rem;
    justify-content: flex-end;

    button {
      background: white;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
`;

const ConcessionsStructure = () => {
  const { getConcessions, grantedForMeList, loadingList } = usePermission();

  const columnsMe: any = [
    {
      label: "Titular",
      key: "owner",
      renderData: (data: any) => `${data.bank_account_data.owner.name}`,
    },

    {
      label: "Conta",
      key: "",
      renderData: (data: any) =>
        `${data.bank_account_data.account_number}-${data.bank_account_data.account_number_digit}`,
    },

    {
      label: "Grupo",
      name: "",
      renderData: (data: any) => data.group_data?.name || "---",
    },
    {
      label: "Papel",
      name: "",
      renderData: (data: any) => data?.role_display || "---",
    },
    {
      label: "Peso",
      name: "",
      renderData: (data: any) =>
        data?.sign_weight ? `${data?.sign_weight}%` : "Apenas visualizador",
    },
    {
      label: "Permissão",
      name: "",
      renderData: (data: any) => data.permissions_role?.name || "---",
    },
  ];

  const { activeIndexMySpace, setActiveIndexMySpace } = useMySpace();

  useEffect(() => {
    getConcessions();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ContainerData className="p-4">
              <Title className="">Meus acessos e permissões</Title>
              <Spacer bottom={40} />

              {loadingList ? (
                <Skeleton />
              ) : grantedForMeList.length == 0 ? (
                <div className="empty-table-box">
                  <img src={UserIcon} />
                  <span>
                    Você não possui acesso a <br />
                    outras contas
                  </span>
                </div>
              ) : (
                <>
                  <Table
                    data={grantedForMeList}
                    columns={columnsMe}
                    dataSpacer={16}
                  />
                </>
              )}
            </ContainerData>

            {/* <div className={clientsData.length > 0 ? "" : "d-none"}>
              {value !== "" && (
                <Paginator
                  list={clientsData.length > 0 ? clientsData : []}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  pageSetter={setPage}
                />
              )}
              <Spacer top={clientsData.length > 0 ? 2 : 40} />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConcessionsStructure;
