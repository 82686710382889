import {
  Button,
  Checkbox,
  CustomSelect,
  Input,
  InputCurrency,
  LateralModal,
  Radio,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import styled from "styled-components";
import theme from "~/themes/theme";
import * as Yup from "yup";

import useCollections from "~/hooks/Collections/useCollections";
import { useEffect, useMemo, useState } from "react";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";

import ApiService from "~/services/api";

import { convertStringToNumber } from "~/utils";
import useAuth from "~/hooks/useAuth";

const Subtitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  color: ${() => theme.colors.gray.main};
  padding: 0 0 10px 0;
  margin: 0;
`;

type FilterStatementsValues = {
  contact_name: string;
  identification: string;
  collectionAmountFrom: string;
  collectionAmountTo: string;
  dateOfIssuenceFrom: string;
  dateOfIssuenceTo: string;
  processing: boolean;
  overdue: boolean;
  canceled: boolean;
  canceled_all: boolean;
  canceling: boolean;
  liquidated: boolean;
  baixado: boolean;
  opened: boolean;
  selected_date: { label: string; value: string } | null;
};

const initialValues: FilterStatementsValues = {
  contact_name: "",
  identification: "",
  collectionAmountFrom: "",
  collectionAmountTo: "",
  dateOfIssuenceFrom: "",
  dateOfIssuenceTo: "",
  processing: false,
  overdue: false,
  canceled: false,
  canceling: false,
  canceled_all: false,
  liquidated: false,
  baixado: false,
  opened: false,
  selected_date: { label: "Selecione", value: "select" },
};

const FormSchema = Yup.object().shape({
  identification: Yup.string(),
  collectionAmountFrom: Yup.string(),
  collectionAmountTo: Yup.string(),
  dateOfIssuenceFrom: Yup.string(),
  dateOfIssuenceTo: Yup.string(),
  processing: Yup.boolean(),
  overdue: Yup.boolean(),
  canceled_all: Yup.boolean(),
  canceled: Yup.boolean(),
  liquidated: Yup.boolean(),
  baixado: Yup.boolean(),
  opened: Yup.boolean(),
  canceling: Yup.boolean(),
});

const CollectionsFilter = () => {
  const {
    openFilterModal,
    setOpenFilterModal,
    setParamsFilterCollections,
    paramsFilterCollections,
    labelBadgesFilterCollections,
    setLabelBadgessFilterCollections,
    isFilteredResults,
    setIsFilteredResults,
  } = useCollections();

  const { setBilletsData, setLoadingList } = useNewCollection();
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [optionValue, setOptionValue] = useState("");
  const [optionDate, setOptionDate] = useState<null | string>(null);

  const handleFormSubmit = (values: FilterStatementsValues) => {
    return;
  };

  const { user } = useAuth();

  const { handleChange, setFieldValue, values, resetForm } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleFormSubmit,
  });

  const getBillets = async () => {
    setBilletsData([]);
    setIsLoadingButton(true);
    setLoadingList(true);
    setOpenFilterModal(false);
    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/billet/",
        token: true,
        params: paramsFilterCollections,
      });

      setBilletsData(data);
      setLoadingList(false);

      setIsLoadingButton(false);
      setIsFilteredResults(true);
      setLabelBadgessFilterCollections(paramsFilterCollections);
    } catch {
      setLoadingList(false);
      setIsLoadingButton(false);
    }
  };

  const hasSomeValue = useMemo(() => {
    return (
      values.contact_name !== "" ||
      values.baixado ||
      values.canceled ||
      values.canceling ||
      values.liquidated ||
      values.opened ||
      values.overdue ||
      values.canceled_all ||
      values.processing ||
      (values.dateOfIssuenceFrom !== "" && values.dateOfIssuenceTo !== "") ||
      (values.collectionAmountFrom !== "" && values.collectionAmountTo !== "")
    );
  }, [values]);

  useEffect(() => {
    let status = [];

    if (values.opened) status.push("opened");
    if (values.baixado || values.liquidated) status.push("done");
    if (values.canceled) status.push("canceled");
    if (values.processing) status.push("processing");
    if (values.overdue) status.push("overdue");
    if (values.canceling) status.push("canceling");

    const statusStr = status.length > 0 ? status.join("-") : undefined;

    let updates: any = {};

    if (values.canceled_all) {
      updates.show_all = true;
    } else {
      updates.show_all = false;
    }

    if (statusStr !== undefined) {
      updates.status = statusStr;
    } else {
      updates.status = "";
    }

    if (values.contact_name) {
      updates.contact = values.contact_name;
    } else {
      updates.contact = "";
    }

    if (optionValue !== "") {
      updates.filteramountby = optionValue;
    } else {
      updates.filteramountby = "";
    }

    if (optionValue !== "" && values.collectionAmountFrom === "") {
      updates.price_min = 0;
    } else if (optionValue !== "" && values.collectionAmountFrom !== "") {
      updates.price_min = convertStringToNumber(values.collectionAmountFrom);
    } else {
      updates.price_min = "";
    }

    if (optionValue !== "" && values.collectionAmountTo === "") {
      updates.price_max = 0;
    } else if (optionValue !== "" && values.collectionAmountTo !== "") {
      updates.price_max = convertStringToNumber(values.collectionAmountTo);
    } else {
      updates.price_max = "";
    }

    if (optionDate !== "") {
      updates.filterby = optionDate;
    } else {
      updates.filterby = "";
    }

    if (optionDate !== "" && values.dateOfIssuenceFrom !== "") {
      updates.from_date = values.dateOfIssuenceFrom;
    } else {
      updates.from_date = "";
    }

    if (optionDate !== "" && values.dateOfIssuenceTo !== "") {
      updates.to_date = values.dateOfIssuenceTo;
    } else {
      updates.to_date = "";
    }

    updates.limit = 999999999;

    setParamsFilterCollections((prevState: any) => {
      let newState = { ...prevState, ...updates };

      Object.keys(newState).forEach((key) => {
        if (newState[key] === "") {
          delete newState[key];
        }
      });

      return newState;
    });
  }, [values, optionDate, optionValue]);

  useEffect(() => {
    if (openFilterModal && !isFilteredResults) {
      resetForm();
      setOptionDate("");
      setOptionValue("");
    }
  }, [openFilterModal]);

  return (
    <LateralModal
      open={openFilterModal}
      title="Filtros"
      toggleModal={() => setOpenFilterModal(!openFilterModal)}
      header
    >
      <div className=" w-100 vh-100 d-flex flex-column justify-content-start p-4">
        <form className="col-12">
          <div className="row ">
            <div className="col-12">
              <Input
                name="contact_name"
                value={values.contact_name}
                onChange={handleChange}
                type="text"
                label="Contato"
                placeholder="Jhon Doe"
              />
            </div>

            {/* <div className={"col-12 col-md-6 "}>
              <Description>Filtrar valor por</Description>
              <CustomSelect
                placeholder={"Selecione"}
                isSearchable={false}
                onChange={({ target: { value } }) => {
                  setOptionValue(value);
                }}
                options={[
                  { label: "Valor total", value: "amount" },
                  { label: "Valor pago", value: "amount_paid" },
                ]}
              />
            </div>
            <div className="col-12 col-md-6"></div> */}
            <div className="row">
              <b>Filtrar valor por</b>
              <Spacer bottom={10} />
              <div className="d-flex">
                <div className=" d-flex align-items-start">
                  {" "}
                  <div onClick={() => setOptionValue("amount")}>
                    <Radio
                      disabled={false}
                      position="right"
                      checked={optionValue === "amount"}
                    />
                  </div>
                  <p
                    onClick={() => setOptionValue("amount")}
                    className="ms-3 "
                    style={{
                      position: "relative",
                      bottom: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Valor total
                  </p>
                </div>
                <Spacer left={20} />
                <div className=" d-flex align-items-start">
                  <div onClick={() => setOptionValue("amount_paid")}>
                    <Radio
                      disabled={false}
                      position="right"
                      checked={optionValue === "amount_paid"}
                    />
                  </div>
                  <p
                    onClick={() => setOptionValue("amount_paid")}
                    className="ms-3 "
                    style={{
                      position: "relative",
                      bottom: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Valor pago
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <Spacer bottom={-10} />
              {optionValue === "" ? (
                <Input
                  value={"Selecione o tipo de valor"}
                  disabled
                  label="Valor mínimo"
                />
              ) : (
                <InputCurrency
                  value={values.collectionAmountFrom}
                  name="collectionAmountFrom"
                  onChange={handleChange}
                  label="Valor mínimo"
                  fontSize="normal"
                  inputSize="normal"
                  disabled={optionValue === ""}
                />
              )}
            </div>
            <div className="col-12 col-md-6">
              <Spacer bottom={-10} />

              {optionValue === "" ? (
                <Input
                  value={"Selecione o tipo de valor"}
                  disabled
                  label="Valor máximo"
                />
              ) : (
                <InputCurrency
                  value={values.collectionAmountTo}
                  name="collectionAmountTo"
                  onChange={handleChange}
                  label="Valor máximo"
                  fontSize="normal"
                  inputSize="normal"
                  disabled={optionValue === ""}
                />
              )}
            </div>

            {/* <div className="col-12 col-md-6">
              <CustomSelect
                isSearchable={false}
                name="select_date"
                placeholder={"Selecione"}
                onChange={(selectedOption) => {
                  setFieldValue(
                    "selected_date",
                    selectedOption ? selectedOption.value : ""
                  );
                }}
                value={optionsDate.find(
                  (option: any) => option.value === values.selected_date
                )}
                options={optionsDate}
                isClearable={true}
                label="Filtrar data por"
              />
            </div>
            <div className="col-12 col-md-6"></div> */}

            <div className="row">
              <b>Filtrar data por</b>
              <Spacer bottom={10} />
              <div className="d-flex">
                <div className=" d-flex align-items-start">
                  {" "}
                  <div onClick={() => setOptionDate("created_date")}>
                    <Radio
                      disabled={false}
                      position="right"
                      checked={optionDate === "created_date"}
                    />
                  </div>
                  <p
                    onClick={() => setOptionDate("created_date")}
                    className="ms-3 "
                    style={{
                      position: "relative",
                      bottom: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Emissão
                  </p>
                </div>
                <Spacer left={20} />

                <div className=" d-flex align-items-start">
                  <div onClick={() => setOptionDate("due_date")}>
                    <Radio
                      disabled={false}
                      position="right"
                      checked={optionDate === "due_date"}
                    />
                  </div>
                  <p
                    onClick={() => setOptionDate("due_date")}
                    className="ms-3 "
                    style={{
                      position: "relative",
                      bottom: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Vencimento
                  </p>
                </div>
                <Spacer left={20} />
                <div className=" d-flex align-items-start">
                  <div onClick={() => setOptionDate("payment_date")}>
                    <Radio
                      disabled={false}
                      position="right"
                      checked={optionDate === "payment_date"}
                    />
                  </div>
                  <p
                    onClick={() => setOptionDate("payment_date")}
                    className="ms-3 "
                    style={{
                      position: "relative",
                      bottom: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Crédito
                  </p>
                </div>
                <Spacer left={20} />
                <div className=" d-flex align-items-start">
                  <div onClick={() => setOptionDate("bank_payment_date")}>
                    <Radio
                      disabled={false}
                      position="right"
                      checked={optionDate === "bank_payment_date"}
                    />
                  </div>
                  <p
                    onClick={() => setOptionDate("bank_payment_date")}
                    className="ms-3 "
                    style={{
                      position: "relative",
                      bottom: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Pagamento
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <Spacer bottom={-10} />
              {optionDate === "" ? (
                <Input
                  value={"Selecione o tipo de data"}
                  disabled
                  label="Data inicial"
                />
              ) : (
                <Input
                  name="dateOfIssuenceFrom"
                  value={values.dateOfIssuenceFrom}
                  onChange={handleChange}
                  type="date"
                  label="Data inicial"
                />
              )}
            </div>
            <div className="col-12 col-md-6">
              <Spacer bottom={-10} />
              {optionDate === "" ? (
                <Input
                  value={"Selecione o tipo de data"}
                  disabled
                  label="Data final"
                />
              ) : (
                <Input
                  name="dateOfIssuenceTo"
                  value={values.dateOfIssuenceTo}
                  onChange={handleChange}
                  type="date"
                  label="Data final"
                />
              )}
            </div>

            <Subtitle className="ps-2">Status do boleto</Subtitle>
            <div className="col-6 d-flex flex-column align-items-start ">
              <div>
                <Checkbox
                  label="Liquidado / Baixado"
                  name="liquidated"
                  checked={values.liquidated}
                  onClick={() =>
                    setFieldValue("liquidated", !values.liquidated)
                  }
                  position="right"
                />
              </div>
              <Spacer bottom={10} />
              <Checkbox
                label="Em aberto"
                name="opened"
                checked={values.opened}
                onClick={() => setFieldValue("opened", !values.opened)}
                position="right"
              />
              <Spacer bottom={10} />{" "}
              <Checkbox
                label="Vencido"
                name="overdue"
                checked={values.overdue}
                onClick={() => setFieldValue("overdue", !values.overdue)}
                position="right"
              />
            </div>

            <div className="col-6 d-flex flex-column align-items-start ">
              <Checkbox
                label="Decurso de prazo"
                name="canceled"
                checked={values.canceled}
                onClick={() => setFieldValue("canceled", !values.canceled)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Em cancelamento"
                name="canceling"
                checked={values.canceling}
                onClick={() => setFieldValue("canceling", !values.canceling)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Em processamento"
                name="processing"
                checked={values.processing}
                onClick={() => setFieldValue("processing", !values.processing)}
                position="right"
              />
            </div>

            <Spacer bottom={20} />
            <Subtitle className="ps-2">Outros</Subtitle>

            {user?.is_backoffice && (
              <div className="col-12 d-flex flex-column align-items-start mt-2">
                <Checkbox
                  label="Exibir boletos cancelados/ocultados"
                  name="canceled_all"
                  checked={values.canceled_all}
                  onClick={() =>
                    setFieldValue("canceled_all", !values.canceled_all)
                  }
                  position="right"
                />
              </div>
            )}
          </div>
        </form>

        <Spacer bottom={25} />
        <div className="w-100 d-flex justify-content-between ">
          <Button
            mode="phantom"
            label="Limpar tudo"
            onClick={() => {
              resetForm();
              setOptionDate("");
              setOptionValue("");
              setIsFilteredResults(false);
            }}
          />
          <Button
            mode="main"
            label="Aplicar filtros"
            onClick={getBillets}
            loading={isLoadingButton}
            disabled={!hasSomeValue}
          />
        </div>
      </div>
    </LateralModal>
  );
};

export default CollectionsFilter;
