import {
  Button,
  Checkbox,
  Input,
  InputCurrency,
  LateralModal,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import styled from "styled-components";
import theme from "~/themes/theme";
import * as Yup from "yup";

import useContacts from "~/hooks/Contacts/useContacts";

const Subtitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  color: ${() => theme.colors.gray.main};
  padding: 0 0 10px 0;
  margin: 0;
`;

type FilterStatementsValues = {
  contact_name: string;
  identification: string;
  collectionAmountFrom: string;
  collectionAmountTo: string;
  dateOfIssuenceFrom: string;
  dateOfIssuenceTo: string;
  pending: boolean;
  overdue: boolean;
  canceled: boolean;
  paid: boolean;
  billet: boolean;
  subscription: boolean;
  payment_link: boolean;
};

const initialValues: FilterStatementsValues = {
  contact_name: "",
  identification: "",
  collectionAmountFrom: "",
  collectionAmountTo: "",
  dateOfIssuenceFrom: "",
  dateOfIssuenceTo: "",
  pending: false,
  overdue: false,
  canceled: false,
  paid: false,
  billet: false,
  subscription: false,
  payment_link: false,
};

const FormSchema = Yup.object().shape({
  identification: Yup.string(),
  collectionAmountFrom: Yup.string(),
  collectionAmountTo: Yup.string(),
  dateOfIssuenceFrom: Yup.string(),
  dateOfIssuenceTo: Yup.string(),
  pending: Yup.boolean(),
  overdue: Yup.boolean(),
  canceled: Yup.boolean(),
  paid: Yup.boolean(),
  billet: Yup.boolean(),
  subscription: Yup.boolean(),
  payment_link: Yup.boolean(),
});

const ContactsFilter = () => {
  const { openFilterModal, setOpenFilterModal } = useContacts();

  const handleFormSubmit = (values: FilterStatementsValues) => {
    return;
  };

  const { handleChange, setFieldValue, values, resetForm } = useFormik({
    validationSchema: FormSchema,
    initialValues,
    onSubmit: handleFormSubmit,
  });

  return (
    <LateralModal
      open={openFilterModal}
      title="Filtros"
      toggleModal={() => setOpenFilterModal(!openFilterModal)}
      header
    >
      <div className=" w-100 vh-100 d-flex flex-column justify-content-start p-4">
        <form className="col-12">
          <div className="row ">
            <div className="col-6">
              <Input
                name="contact_name"
                value={values.contact_name}
                onChange={handleChange}
                type="text"
                label="Contato"
                placeholder="#124547"
              />
            </div>
            <div className="col-6">
              <Input
                name="identification"
                value={values.identification}
                onChange={handleChange}
                type="text"
                label="Identificação"
                placeholder="#124547"
              />
            </div>
            <Subtitle className="ps-2">Valor da cobrança</Subtitle>
            <div className="col-12 col-md-6">
              <InputCurrency value={values.collectionAmountFrom} label="De" />
            </div>
            <div className="col-12 col-md-6">
              <InputCurrency value={values.collectionAmountTo} label="Até" />
            </div>
            <Subtitle className="ps-2">Data da emissão</Subtitle>
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateFrom"
                value={values.dateOfIssuenceFrom}
                onChange={handleChange}
                type="date"
                label="De"
              />
            </div>
            <div className="col-12 col-md-6">
              <Input
                name="transactionDateTo"
                value={values.dateOfIssuenceTo}
                onChange={handleChange}
                type="date"
                label="Até"
              />
            </div>

            <div className="col-6 d-flex flex-column align-items-start ">
              <Subtitle>Status da transação</Subtitle>
              <Checkbox
                name="pending"
                checked={values.pending}
                onClick={() => setFieldValue("pending", !values.pending)}
                label="Em aberto"
                position="right"
              />

              <Spacer bottom={10} />
              <div>
                <Checkbox
                  label="Em atraso"
                  name="overdue"
                  checked={values.overdue}
                  onClick={() => setFieldValue("overdue", !values.overdue)}
                  position="right"
                />
              </div>
              <Spacer bottom={10} />
              <Checkbox
                label="Cancelado"
                name="canceled"
                checked={values.canceled}
                onClick={() => setFieldValue("canceled", !values.canceled)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Pago"
                name="paid"
                checked={values.paid}
                onClick={() => setFieldValue("paid", !values.paid)}
                position="right"
              />
            </div>

            <div className="col-6 d-flex flex-column align-items-start ">
              <Subtitle>Tipo de transação</Subtitle>
              <Checkbox
                label="Boleto"
                name="billet"
                checked={values.billet}
                onClick={() => setFieldValue("billet", !values.billet)}
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Recorrência"
                name="subscription"
                checked={values.subscription}
                onClick={() =>
                  setFieldValue("subscription", !values.subscription)
                }
                position="right"
              />
              <Spacer bottom={10} />
              <Checkbox
                label="Link de pagamento"
                name="payment_link"
                checked={values.payment_link}
                onClick={() =>
                  setFieldValue("payment_link", !values.payment_link)
                }
                position="right"
              />
            </div>
          </div>
        </form>

        <Spacer bottom={25} />
        <div className="w-100 d-flex justify-content-between ">
          <Button
            mode="phantom"
            label="Limpar tudo"
            onClick={() => {
              resetForm();
            }}
          />
          <Button mode="main" label="Aplicar filtros" />
        </div>
      </div>
    </LateralModal>
  );
};

export default ContactsFilter;
