import { NotControlledToolTip } from "@zilliondigital/mirage-ui";
import {
  Card,
  CardStatusDict,
  CardTypeDict,
  RecentTransactionStatusDict,
  Transaction,
  AuthorizationWebhook,
  AuthorizationCategoryDict,
} from "~/hooks/Cards/interfaces";
import {
  MyCardsTableCardName,
  MyCardsTableCardSubName,
  RecentTransactionsTableCarValue,
  RecentTransactionsTableCardSubName,
  StatusMyCardsTablePill,
  StatusRecentTransactionsTablePill,
} from "./styled";
import "./style.css";
import { fromCentsToBRMonetary } from "~/utils/formatters/toMonetary";
import { formatCash, formatDateTime } from "~/utils";

export const buildRecentTransactionsStatements = (cards: Card[] = []) => {
  const validStatus = ["AUTHORIZATION", "CONFIRMATION", "INSTALLMENT"];

  return [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => (
        <div className="d-flex justify-content-start align-items-center">
          {data.label}
        </div>
      ),

      renderData: (data: AuthorizationWebhook) => (
        <div
          className="d-flex justify-content-start align-items-center my-2"
          style={{ marginLeft: "-0.5rem" }}
          id={`status-${data.cid}`}
        >
          <StatusRecentTransactionsTablePill
            className={`${data.authorization_category}`}
          >
            {AuthorizationCategoryDict[data.authorization_category]}
          </StatusRecentTransactionsTablePill>
        </div>
      ),
    },
    {
      label: "Cartão",
      key: "card-column",

      renderColumn: (data: any) => (
        <div className="d-flex  justify-content-start align-items-center">
          {data.label}
        </div>
      ),

      renderData: (data: AuthorizationWebhook) => (
        <div
          className="d-flex justify-content-start align-items-center"
          style={{ marginLeft: "-0.5rem" }}
          id={`card-${data.cid}`}
        >
          <div className="d-flex flex-column justify-content-center align-items-start">
            <MyCardsTableCardName
              className={`${
                validStatus.includes(data.authorization_category)
                  ? ""
                  : "disabled-transaction"
              }`}
            >
              {/* TODO */}
              Principal
            </MyCardsTableCardName>
            <MyCardsTableCardSubName
              className={`${
                validStatus.includes(data.authorization_category)
                  ? ""
                  : "disabled-transaction"
              }`}
            >
              {`${data.card_id}`}
            </MyCardsTableCardSubName>
          </div>
        </div>
      ),
    },
    {
      label: "Estabelecimento",
      key: "location-column",

      renderColumn: (data: any) => (
        <div className="d-flex  justify-content-start align-items-center">
          {data.label}
        </div>
      ),

      renderData: (data: AuthorizationWebhook) => (
        <div
          className="d-flex justify-content-start align-items-center"
          style={{ marginLeft: "-0.5rem" }}
          id={`location-${data.cid}`}
        >
          <MyCardsTableCardName
            className={`
              mb-0 
              ${
                validStatus.includes(data.authorization_category)
                  ? ""
                  : "disabled-transaction"
              }
            `}
          >
            {data.soft_descriptor}
          </MyCardsTableCardName>
        </div>
      ),
    },
    {
      label: "Valor",
      key: "value-column",

      renderColumn: (data: any) => (
        <div className="d-flex  justify-content-start align-items-center">
          {data.label}
        </div>
      ),

      renderData: (data: AuthorizationWebhook) => (
        <div
          className="d-flex justify-content-start align-items-center "
          style={{ marginLeft: "-0.5rem" }}
          id={`value-${data.cid}`}
        >
          <div className="d-flex flex-column justify-content-center align-items-start">
            <RecentTransactionsTableCarValue
              className={`
                ${data.contract_amount <= 0 ? "positive" : "negative"}
                ${
                  validStatus.includes(data.authorization_category)
                    ? ""
                    : "disabled-transaction"
                }
              `}
            >
              {`${formatCash(data.contract_amount)}`}
            </RecentTransactionsTableCarValue>
            <RecentTransactionsTableCardSubName>
              {`${formatDateTime(data.original_authorization_date_hour)}`}
            </RecentTransactionsTableCardSubName>
          </div>
        </div>
      ),
    },
  ];
};
