import styled from "styled-components";
import theme from "~/themes/theme";

const Title = styled.span`
  font-size: 28px;
  color: ${() => theme.colors.primary.main};
  font-weight: 300;
`;

type TitleModalProps = {
  children: React.ReactNode;
};

const TitleModal = (props: TitleModalProps) => {
  const { children } = props;

  return <Title>{children}</Title>;
};

export default TitleModal;
