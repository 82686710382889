import { useMemo } from "react";
import { Button, Spacer, Table } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import MoreIcon from "~/assets/icons/buttons/more.png";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getFirstLetters } from "~/services/utils";
import { formatCPFCNPJ } from "~/utils";
import Title from "~/components/Texts/Title";
import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";
import BankIcon from "~/assets/icons/contacts/bank_icon.png";
import useContacts from "~/hooks/Contacts/useContacts";
import theme from "~/themes/theme";
import label from "~/labels";
import useSplit from "~/hooks/useSplit";
import ApiService from "~/services/api";
import { useNavigate } from "react-router";
import useToast from "~/hooks/useToast";
import { ApiResponse, Columns, getKeyPixContact } from "~/types";

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }

  .bank-accounts-picture {
    width: 10px;
    height: 10px;
    background-color: #d8d8d7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }

  .img-bank-icon {
    width: 25px;
  }
`;

type Type = {
  [key: string]: string;
};

const accountType: Type = {
  corrente: "Conta Corrente",
  poupanca: "Conta Poupança",
  escrow: "Conta Escrow",
  pagamento: "Conta Pagamento",
  salario: "Conta Salário",
  taxas: "Conta de Taxas",
  movimentacao: "Conta de Movimentação",
  escrow_provedor: "Conta Escrow Provedor",
};

const renderType: Type = {
  telefone: "Celular",
  email: "Email",
  cpf: "CPF",
  cnpj: "CNPJ",
  evp: "Chave aleatória",
};

const NewSplistSelectBankAccountOrKeyPix = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedIndexKeyPix, setSelectedIndexKeyPix] = useState<number>(-1);

  const {
    setSelectContact,
    newTransferTEDData,
    changeSideAnimation,
    setChangeSideAnimation,
    setBankAccountIndex,
    bankAccountIndex,
  } = useNewTransfer();

  const { showToast } = useToast();
  const navigate = useNavigate();

  const {
    setOpenNewSplitModal,
    stepIndex,
    setStepIndex,
    setDepositIsByKeyPix,
    setNewPartnerSplitData,
    setReceivers,
    newPartnerSplitData,
    depositIsByKeyPix,
    newSplitPayload,
  } = useSplit();

  const [keysData, setKeysData] = useState<any>([]);

  const pk = newPartnerSplitData.contact;

  const [selectedRows, setSelectedRow] = useState<any[]>([]);

  const bankAccountData: any[] = useMemo<any>(() => {
    if (newTransferTEDData && newTransferTEDData?.bank_accounts) {
      const accounts = newTransferTEDData.bank_accounts.map(
        (account: any, index: number) => ({
          index: index,
          bank_id: account.bank_data.id,
          bank_name: account.bank_data.name,
          account_number: account.account_number,
          account_number_digit: account.account_number_digit,
          agency: account.agency,
          type: account.type,
          id: account.id,
          bank_code: account.bank_code,
        })
      );

      if (newSplitPayload?.split_operation_mode === "fitbank") {
        return accounts.filter((account: any) => account.bank_code === "450");
      } else {
        return accounts;
      }
    } else {
      return [];
    }
  }, [newTransferTEDData, newSplitPayload?.split_operation_mode]);

  const handleAdvance = () => {
    if (
      depositIsByKeyPix ||
      newSplitPayload?.split_operation_mode === "fitbank"
    ) {
      setReceivers((prevValues: any) => {
        const updatedReceivers = [...prevValues, { ...newPartnerSplitData }];
        return updatedReceivers;
      });
    }

    if (newSplitPayload?.split_operation_mode === "fitbank") {
      setStepIndex(stepIndex + 2);
    } else {
      setStepIndex(stepIndex + 1);
    }
  };

  const handlePrevStep = () => {
    setStepIndex(stepIndex - 1);
  };

  const SelectBankAccount = (id: number) => {
    setSelectedIndex(id);
    setSelectedRow([bankAccountIndex]);
  };

  const handleShowTheSelectBankAccount = (data: any) => {
    SelectBankAccount(selectedIndex === data.index ? -1 : data.index);
    setBankAccountIndex(
      bankAccountIndex !== -1 && bankAccountIndex === data.index
        ? -1
        : data.index
    );
  };

  const handleShowTheSelectKey = (data: any) => {
    SelectBankAccount(selectedIndexKeyPix === data.index ? -1 : data.index);
    setBankAccountIndex(
      bankAccountIndex !== -1 && bankAccountIndex === data.index
        ? -1
        : data.index
    );
  };

  type dataKeys = {
    index: number;
    key: string;
    type: string;
    id: number;
  };

  const dataKeys: dataKeys[] = keysData.map((account: any, index: number) => ({
    index: index,
    key: account.key,
    type: account.type,
    id: account.id,
  }));

  const columnContactSelected: Columns[] = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any) => (
        <div className="d-flex align-items-center">
          <ContainerImage>
            <div className="container-profile-picture">
              <div className="rounded-circle profile-picture">
                <h1>{getFirstLetters(data.name)}</h1>
              </div>
            </div>
          </ContainerImage>
          <Spacer left={10} />
          <div className="d-flex flex-column">
            <Title>{data.name}</Title>
            <Description color="#929292">
              {formatCPFCNPJ(data.document_number)}
            </Description>
          </div>
        </div>
      ),
    },
  ];

  const columnsBankAccounts: Columns[] = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any) => (
        <ContainerRenderData
          onClick={() => {
            setSelectedIndex(data.index);

            handleShowTheSelectBankAccount(data);
            if (newSplitPayload?.split_operation_mode === "fitbank") {
              setNewPartnerSplitData((prevState: any) => {
                const { deposit_type, deposit_type_display, ...rest } =
                  newPartnerSplitData;

                return {
                  ...rest,
                  deposit_type_display: "TEV",
                  deposit_type: "tev",
                  deposit_bank_account: data.id,
                  bank_account_with_digit: `${data.account_number}-${data.account_number_digit}`,
                  agency: data.agency,
                  bank_name: data.bank_name,
                  bank_code: data.bank_code,
                };
              });
            } else {
              setNewPartnerSplitData((prevState: any) => {
                const {
                  deposit_pix_key,
                  deposit_type,
                  key,
                  type,
                  deposit_type_display,

                  ...rest
                } = newPartnerSplitData;

                return {
                  ...rest,
                  deposit_bank_account: data.id,
                  bank_account_with_digit: `${data.account_number}-${data.account_number_digit}`,
                  agency: data.agency,
                  bank_name: data.bank_name,
                  bank_code: data.bank_code,
                };
              });
            }
            setDepositIsByKeyPix(false);
            setSelectedIndexKeyPix(-1);
          }}
        >
          <div className="d-flex align-items-center">
            {selectedIndex === data.index ? (
              <ContainerImage>
                <div className="contact-selected-image">
                  <img
                    className="selected-contact-image"
                    src={SelectedContactIcon}
                  />
                </div>
              </ContainerImage>
            ) : (
              <ContainerImage>
                <div className="container-profile-picture">
                  <div className="rounded-circle  bank-accounts-picture">
                    <img className="img-bank-icon" src={BankIcon} />
                  </div>
                </div>
              </ContainerImage>
            )}
            <Spacer left={10} />
            <div className="d-flex flex-column">
              <Title>{`${data.bank_id} - ${data.bank_name}`}</Title>
              <Description>
                {`Agência: ${data.agency} | Conta: ${data.account_number}-${data.account_number_digit}`}
              </Description>
              <Description>{`Tipo: ${accountType[data.type]}`}</Description>
            </div>
          </div>
        </ContainerRenderData>
      ),
    },
  ];

  const columnsKeys: Columns[] = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any) => (
        <ContainerRenderData
          onClick={() => {
            setSelectedIndexKeyPix(data.index);
            handleShowTheSelectKey(data);
            setDepositIsByKeyPix(true);

            setNewPartnerSplitData((prevState: any) => {
              const {
                deposit_bank_account,
                bank_account_with_digit,
                agency,
                bank_code,
                bank_name,
                ...rest
              } = newPartnerSplitData;

              return {
                ...rest,
                deposit_pix_key: data.id,
                deposit_type: "pix_transfer",
                key: data.key,
                type: data.type,
                deposit_type_display: "Transferência Pix",
              };
            });

            setSelectedIndex(-1);
          }}
        >
          <div className="d-flex align-items-center">
            {selectedIndexKeyPix === data.index ? (
              <ContainerImage>
                <div className="contact-selected-image">
                  <img
                    className="selected-contact-image"
                    src={SelectedContactIcon}
                  />
                </div>
              </ContainerImage>
            ) : (
              <ContainerImage>
                <div className="container-profile-picture">
                  <div className="rounded-circle  bank-accounts-picture">
                    <img className="img-bank-icon" src={BankIcon} />
                  </div>
                </div>
              </ContainerImage>
            )}
            <Spacer left={10} />
            <div className="d-flex flex-column">
              <Title>{data.key}</Title>
              <Description>{renderType[data.type]}</Description>
            </div>
          </div>
        </ContainerRenderData>
      ),
    },
  ];

  const getContactPixKeys = async () => {
    try {
      const { data } = await ApiService.HttpGet<ApiResponse<getKeyPixContact>>({
        route: `pix/partners-key/${pk}/keys/  `,
        token: true,
      });

      setKeysData(data.data);
    } catch {
      showToast("", "", "error", () => null, false);
    }
  };

  const handleNavigateToContactsArea = () => {
    navigate("/app/contacts");
    setOpenNewSplitModal(false);
  };

  useEffect(() => {
    setBankAccountIndex(-1);

    getContactPixKeys();
  }, []);

  return (
    <motion.div
      initial={{
        x: changeSideAnimation ? "-100%" : !changeSideAnimation && "100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: changeSideAnimation ? "100%" : !changeSideAnimation && "-100%",
      }}
      transition={{ duration: 0.3 }}
      className="overflow-hidden"
    >
      <div>
        <Table
          columns={columnContactSelected}
          data={[
            {
              name: newTransferTEDData?.name,
              document_number: newTransferTEDData?.document_number,
            },
          ]}
          haveHeader={false}
        />
        <div className="p-2">
          <Title>Selecione uma conta bancária</Title>
        </div>
        <div
          style={{ zIndex: "1", overflowY: "auto", maxHeight: "200px" }}
          className="  w-100"
        >
          {bankAccountData.length > 0 ? (
            <Table columns={columnsBankAccounts} data={bankAccountData} />
          ) : (
            <div className="text-center mb-4">
              <Spacer bottom={20} />
              <Description>
                {newSplitPayload?.split_operation_mode === "fitbank"
                  ? "Este contato não possui nenhuma conta bancária Fitbank associada. É necessário cadastrar pelo menos uma conta Fitbank para continuar essa operação."
                  : "Este contato não possui nenhuma conta bancária associada."}{" "}
                <br />
                <span
                  onClick={() => handleNavigateToContactsArea()}
                  style={{
                    color: theme.colors.secondary.main,
                    cursor: "pointer",
                  }}
                >
                  Ir para contatos.
                </span>
              </Description>
            </div>
          )}
        </div>

        {newSplitPayload?.split_operation_mode !== "fitbank" && (
          <div>
            <div className="p-2">
              <Title>Ou uma chave pix</Title>
            </div>
            <div
              style={{ zIndex: "1", overflowY: "auto", maxHeight: "200px" }}
              className="  w-100"
            >
              {dataKeys.length > 0 ? (
                <Table columns={columnsKeys} data={dataKeys} />
              ) : (
                <div className="text-center mb-4">
                  <Spacer bottom={20} />
                  <Description>
                    Este contato não possui nenhuma chave pix associada. <br />
                    <span
                      onClick={() => handleNavigateToContactsArea()}
                      style={{
                        color: theme.colors.secondary.main,
                        cursor: "pointer",
                      }}
                    >
                      Ir para contatos.
                    </span>
                  </Description>
                </div>
              )}
            </div>
          </div>
        )}

        <Spacer bottom={40} />
        <div className="p-4">
          <div className="d-flex w-100 justify-content-between  ">
            <div>
              <Button
                mode="phantom"
                label="Voltar"
                onClick={() => {
                  setChangeSideAnimation(true);
                  handlePrevStep();
                }}
              />
            </div>

            <div className="d-flex">
              {/* <Button
                mode="border"
                label="Adicionar conta bancária"
                // onClick={handleAddContact}
                icon={MoreIcon}
              /> */}
              <Spacer left={10} />
              <Button
                label="Avançar"
                mode="main"
                onClick={() => {
                  setSelectContact(false);
                  handleAdvance();
                }}
                disabled={selectedIndex === -1 && selectedIndexKeyPix === -1}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default NewSplistSelectBankAccountOrKeyPix;
