import { Spacer } from "@zilliondigital/mirage-ui";
import styled from "styled-components";
import theme from "~/themes/theme";

const BadgeLabel = styled.span``;

const BadgeContainer = styled.div<BadgeProps>`
  background-color: ${({ color }) => color ?? theme.colors.success.light400};
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 6px 1px 4px;

  border-radius: 25px;

  ${BadgeLabel} {
    color: ${({ textColor }) => textColor ?? theme.colors.success.dark200};
    font-size: 10px;
    font-weight: bold;
  }
`;

const BadgeIcon = styled.img``;

type BadgeProps = {
  color?: string;
  textColor?: string;
  icon?: string;
  label?: string;
  iconSize?: string;
};

const Badge = (props: BadgeProps) => {
  const { color, textColor, label, icon, iconSize } = props;

  return (
    <>
      <BadgeContainer color={color} textColor={textColor}>
        {BadgeIcon && (
          <BadgeIcon
            src={icon}
            style={iconSize ? { height: `${iconSize}px` } : {}}
          />
        )}
        <Spacer left={3} />
        <BadgeLabel>{label}</BadgeLabel>
      </BadgeContainer>
    </>
  );
};

export default Badge;
