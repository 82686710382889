import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Spacer,
  Table,
  NotControlledToolTip,
  InteractiveModal,
  Button,
} from "@zilliondigital/mirage-ui";

import useAuthority from "~/hooks/useAuthority";
import DeleteIcon from "~/assets/icons/delete-icon-small.png";
import { formatCPFCNPJ } from "~/utils";
import { applyPagination } from "~/components/Paginator";
import TitleModal from "~/components/Texts/TitleModal";
import Skeleton from "~/components/SkeletonTables";
import DoubleUserIcon from "~/assets/icons/user-couple.svg";
import { Columns } from "~/types";

const GrantPermission = () => {
  const {
    approvers,
    loadingRemoveApprover,
    removeApprover,
    currentGrantModalData,
    setCurrentGrantModalData,
    loadingList,

    rowsPerPage,
    page,
  } = useAuthority();

  const [canRemove, setCanRemove] = useState<boolean>(false);

  const handleRemovePermission = async () => {
    setCanRemove(false);
    await removeApprover(currentGrantModalData.id);
  };

  const columns: Columns[] = [
    {
      label: "Proprietário",
      key: "id",
      renderData: (data: any) => {
        const accountData = {
          name: data.allowed_account_data
            ? data.allowed_account_data.partner_data.name
            : data.allowed_partner_data.name,
          document_number: data.allowed_account_data
            ? data.allowed_account_data.partner_data.document_number
            : data.allowed_partner_data.document_number,
        };

        return (
          <div className="d-flex  ">
            <p className="">
              {accountData.name} - {formatCPFCNPJ(accountData.document_number)}
            </p>
          </div>
        );
      },
    },
    {
      label: "Conta",
      key: "id",
      renderData: (data: any) => {
        const bk = data.allowed_account_data
          ? data.allowed_account_data.bank_account_data
          : data.owner_bank_account_data;
        return (
          <div className="d-flex  ">
            <p className="">
              Ag: {bk.agency} - Conta: {bk.account_number}-
              {bk.account_number_digit}
            </p>
          </div>
        );
      },
    },
    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="btn-icon-30">
            <Button
              id={`btn-${data.id}`}
              mode="phantom"
              label=""
              iconPosition="right"
              onClick={() => {
                setCurrentGrantModalData(data);
                setCanRemove(true);
              }}
              icon={DeleteIcon}
            />
            <NotControlledToolTip placement="top" target={`btn-${data.id}`}>
              Remover
            </NotControlledToolTip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {loadingList ? (
        <Skeleton />
      ) : approvers.length === 0 ? (
        <div className="empty-table-box">
          <img src={DoubleUserIcon} />
          <span>
            Nenhuma alçada cadastrada <br />
            até o momento
          </span>
        </div>
      ) : (
        <>
          <Table
            data={applyPagination(approvers, { page, rowsPerPage })}
            columns={columns}
            dataSpacer={8}
          />
        </>
      )}

      {/* REMOVE MODAL */}
      <InteractiveModal
        open={canRemove}
        title="exclusao"
        size="md"
        toggleModal={() => setCanRemove(!canRemove)}
      >
        <div className="p-4 h-100 d-flex h-100 text-center justify-content-center align-items-center w-100 flex-wrap flex-grow-1">
          <TitleModal>Exclusão do grupo de aprovação</TitleModal>
          <div className="d-flex h-100 justify-content-center align-items-center w-100 flex-wrap  flex-grow-1">
            <p>
              Deseja realmente remover essa conta da permissão de aprovação de
              operações?
            </p>
            <div style={{ width: "290px" }} className="mb-5">
              <Spacer bottom={5} />
              <Button
                mode="main"
                label="Sim, remover!"
                full
                onClick={() => {
                  handleRemovePermission();
                }}
                disabled={loadingRemoveApprover}
                loading={loadingRemoveApprover}
              />
              <Spacer bottom={5} />
              <Button
                mode="border"
                label="Cancelar"
                full
                onClick={() => setCanRemove(false)}
              />
            </div>
          </div>
        </div>
      </InteractiveModal>
    </div>
  );
};

export default GrantPermission;
