import constate from "constate";
import { useEffect, useState } from "react";
import { UserRegisterResponse } from "~/types";

export type SignupUserSteps = "data" | "password" | "profile" | string;

type SignupUserValues = {
  full_name: string;
  username: string;
  email: string;
  token: string;
  phone: string;
  terms: boolean;

  password: string;
  password_confirm: string;
};

const initialSignUpUserValues: SignupUserValues = {
  full_name: "",
  username: "",
  email: "",
  token: "",
  phone: "",
  terms: false,

  password: "",
  password_confirm: "",
};

export const steps: SignupUserSteps[] = ["data", "password", "profile"];

const [SignupUserProvider, useSignupUser] = constate(() => {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [step, setStep] = useState<SignupUserSteps>(steps[stepIndex]);
  const [openModal, setOpenModal] = useState(false);
  const [valid, setValid] = useState<boolean>(false);
  const [comeToLogin, setComeToLogin] = useState<boolean>(false);

  const [isSignUp, setIsSignUp] = useState<boolean>(false);

  const [openValidatorModalSignUpUser, setOpenValidatorModalSignUpUser] =
    useState<boolean>(false);

  const [signUpUserPayload, setSignUpUserPayload] = useState<SignupUserValues>(
    initialSignUpUserValues
  );

  const [registeredUser, setRegisteredUser] = useState<UserRegisterResponse>();
  const [registeredToken, setRegisteredToken] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmitForm = () => {
    const event = new CustomEvent("signupUserSubmit", {
      detail: { step },
    });

    window.dispatchEvent(event);
  };

  const [doLogin, setDoLogin] = useState<boolean>(false);

  const [dataLoginUser, setDataLoginUser] = useState({
    username_login: "",
    password: "",
  });

  const [userHasAccountType, setUserHasAccountType] = useState<string>("");

  return {
    step,
    setStep,
    onSubmitForm,
    openModal,
    setOpenModal,
    valid,
    setValid,
    stepIndex,
    setStepIndex,
    loading,
    setLoading,
    signUpUserPayload,
    setSignUpUserPayload,
    openValidatorModalSignUpUser,
    setOpenValidatorModalSignUpUser,
    registeredUser,
    setRegisteredUser,
    registeredToken,
    setRegisteredToken,
    doLogin,
    setDoLogin,
    dataLoginUser,
    setDataLoginUser,
    userHasAccountType,
    setUserHasAccountType,
    isSignUp,
    setIsSignUp,
    comeToLogin,
    setComeToLogin,
  };
});

export { SignupUserProvider };

export default useSignupUser;
