import { Button, Spacer } from "@zilliondigital/mirage-ui";
import { useRouteError } from "react-router-dom";
import styled from "styled-components";

import FigureDecorationLeft from "~/assets/images/figure_docoration_left.png";
import FigureDecorationRight from "~/assets/images/figure_docoration_right.png";
import FigureError from "~/assets/images/figure_errorpage_500.png";

import * as Sentry from "@sentry/react";
import { useEffect } from "react";

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  overflow: none;
  margin: 0 auto;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    width: 100%;
    max-width: 367px;
    height: 100%;
    background-image: url(${FigureDecorationLeft});
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  ::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 367px;
    height: 100%;
    background-image: url(${FigureDecorationRight});
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const CodeError = styled.div`
  margin-bottom: 48px;
  width: 480px;
  height: 360px;
  background-image: url(${FigureError});
  background-repeat: no-repeat;
  background-size: cover;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 500px;
  text-align: center;
  font-weight: var(--secondary-font);

  h2 {
    font-size: 42px;
    color: var(--black);
    margin-bottom: 24px;
    font-weight: 600;
  }

  p {
    font-size: var(--font-lg);
    color: var(--alternative-black);
    margin-bottom: 0px;
  }
`;

const ErrorBoundary = () => {
  const error: any = useRouteError();

  const sendSentryError = () => {
    Sentry.withScope((scope) => {
      scope.setExtra("error", JSON.stringify(error));
      Sentry.captureException(error);
    });
  };

  useEffect(() => {
    console.error("Error caught: ", error);
    // sendSentryError();
  }, [error]);

  return (
    <Content>
      <Spacer bottom={48} />
      <CodeError />
      <TextContainer>
        <h2>Ops, tem algo errado</h2>
        <p>{String(error)}</p>
        <p>{JSON.stringify(error)}</p>
        <Spacer bottom={24} />
        <Button
          mode="main"
          label="Recarregar página"
          onClick={() => window.location.reload()}
        />
      </TextContainer>
    </Content>
  );
};

export default ErrorBoundary;
