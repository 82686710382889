import { Spacer, Table } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import styled from "styled-components";
import SupplierIcon from "~/assets/icons/contacts/supplier.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";
import ColaboratteOrCostumerIcon from "~/assets/icons/contacts/collaborate-or-costumer.png";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";

import ApiService from "~/services/api";
import useContacts from "~/hooks/Contacts/useContacts";
import { formatCPFCNPJ } from "~/utils";
import { getFirstLetters } from "~/services/utils";
import LoaderContactsList from "~/pages/App/Transfers/NewTransfer/LoaderContactsList";
import useTransferWithPix from "~/hooks/PIX/useTransferWithPix";
import theme from "~/themes/theme";
import label from "~/labels";

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .rounded-circle {
    width: 40px;
  }

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }
`;

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }
`;

const TableContactsPix = () => {
  const { setContactInfo, contactInfo } = useNewCollection();

  const {
    setContactsPixData,
    contactsPixData,
    setContactSelectedPixData,
    contactSelectedPixData,
    selectedIndex,
    setSelectedIndex,
    selectedRows,
    setSelectedRow,
  } = useTransferWithPix();

  const {
    isLoadingTableContacts,

    setIsLoadingTableContacts,
  } = useContacts();

  const getPixPartners = async () => {
    setIsLoadingTableContacts(true);
    try {
      const { data } = await ApiService.HttpGet({
        route: "pix/partners/",
        token: true,
      });

      setContactsPixData(data);
      setIsLoadingTableContacts(false);
    } catch {
      setIsLoadingTableContacts(false);
    }
  };

  useEffect(() => {
    getPixPartners();

    return () => {
      setSelectedIndex(-1);
    };
  }, []);

  useEffect(() => {
    const selectedContact = contactsPixData.filter(
      (contactsData: any) => contactsData.id === selectedIndex
    );

    setContactSelectedPixData(
      selectedContact[0] === undefined ? undefined : [selectedContact[0]]
    );

    setContactInfo({
      id: selectedContact[0]?.id,
      name: selectedContact[0]?.name,
      document_number: selectedContact[0]?.document_number,
    });
  }, [selectedIndex]);

  const dataContacts = contactsPixData
    .filter((c) => {
      if (c.document_number.includes("*")) {
        return false;
      }

      return true;
    })
    .map((contact, index) => {
      return {
        id: contact.id,
        name: contact.name,
        document_number: contact.document_number,
        address: contact.all_address,
        bank_accounts: contact.bank_accounts,
        index: index,
      };
    });

  // const filteredContacts = contactsFiltered.map((contact) => {
  //   return {
  //     id: contact.id,
  //     name: contact.name,
  //     profile_picture: contact.profile_picture,
  //     email: contact.email,
  //     phone: contact.mobile_phone,
  //     role: contact.role,
  //     document_number: contact.document_number,
  //     address: contact.all_address,
  //     bank_accounts: contact.bank_accounts,
  //   };
  // });

  const SelectContact = (id: number) => {
    setSelectedIndex(id);

    const idContactSelected = contactsPixData.findIndex(
      (contact) => contact.id === id
    );

    setSelectedRow([idContactSelected]);
  };

  const handleClickContact = (data: any) => {
    SelectContact(selectedIndex === data.id ? -1 : data.id);
  };

  const columnContacts: any = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <p>{data?.name}</p>,

      renderData: (data: any, index: number) => {
        return (
          <ContainerRenderData
            onClick={() => {
              handleClickContact(data);
            }}
          >
            <div className="d-flex align-items-center">
              {selectedIndex === data.id ? (
                <ContainerImage>
                  <div className="contact-selected-image">
                    <img
                      className="selected-contact-image"
                      src={SelectedContactIcon}
                      alt="Selected Contact Icon"
                    />
                  </div>
                </ContainerImage>
              ) : selectedIndex === data.id ? (
                <ContainerImage>
                  <div className="contact-selected-image">
                    <img
                      className="selected-contact-image"
                      src={SelectedContactIcon}
                      alt="Selected Contact Icon"
                    />
                  </div>
                </ContainerImage>
              ) : (
                <ContainerImage>
                  <div className="container-profile-picture">
                    <div className="rounded-circle profile-picture">
                      <h1>{getFirstLetters(data.name)}</h1>
                    </div>
                  </div>
                </ContainerImage>
              )}
              <Spacer left={10} />
              <div className="d-flex flex-column">
                <Title>{data.name}</Title>
                <Description color="#929292">
                  {formatCPFCNPJ(data.document_number)}
                </Description>
              </div>
            </div>
          </ContainerRenderData>
        );
      },
    },
  ];

  return (
    <>
      {isLoadingTableContacts ? (
        <LoaderContactsList />
      ) : dataContacts?.length > 0 ? (
        <Table
          data={dataContacts}
          columns={columnContacts}
          dataSpacer={10}
          selectedRows={selectedRows}
          haveHeader={false}
        />
      ) : (
        <div className="mt-3 w-100">Você não possui contatos frequentes</div>
      )}
    </>
  );
};

export default TableContactsPix;
