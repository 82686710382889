import LoaderRechargeImg from "~/assets/images/LoadingImages/Loader-Recharge.png";
import styled, { keyframes } from "styled-components";
import { Spacer } from "@zilliondigital/mirage-ui";
import LoadingImg from "~/assets/icons/loading.png";

const ImgLoading = styled.img`
  width: auto;
  margin-top: 20px;
  margin-left: -40px;
  height: 400px;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const IconLoading = styled.img`
  animation: ${rotate360} 1s linear infinite;
`;

const LoaderPayments = () => {
  return (
    <div className="p-4 w-100 d-flex flex-column flex-2">
      <Spacer bottom={60} />
      <div className="w-100 d-flex justify-content-center ">
        <ImgLoading className="ps-4" src={LoaderRechargeImg} />
      </div>

      <Spacer bottom={120} />
      <div className="w-100 d-flex justify-content-center">
        <IconLoading src={LoadingImg} />
      </div>
    </div>
  );
};

export default LoaderPayments;
