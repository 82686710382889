import {
  BalanceAlert,
  Button,
  InputCurrency,
  Spacer,
} from "@zilliondigital/mirage-ui";
import { useFormik } from "formik";
import Description from "~/components/Texts/Description";
import * as Yup from "yup";
import { convertedAmount, formatNumberToCurrencyWithoutSimbol } from "~/utils";
import { onlyNumbers } from "~/services/utils";
import useAccount from "~/hooks/useAccount";
import useAddBalance from "~/hooks/useAddBalance";
import { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import useToken from "~/hooks/useToken";
import ApiService from "~/services/api";

type FormValues = {
  amount: string;
};

const FormSchema = Yup.object().shape({
  amount: Yup.string(),
});

const AddBalanceData = () => {
  const [error, setError] = useState<boolean>(false);

  const { formatNumberToCurrency, balance, balanceFormatted } = useAccount();

  const {
    handleNextStep,
    setAmount,
    setChooseTypeAddBalance,
    isPIX,
    changeSideAnimation,
    setChangeSideAnimation,
    amount,
    setSelectedValue,
    setIndexPixKeyToCharge,
    setOpenAddBalanceModal,
    openAddBalanceModal,
    getPixKeys,
    setTaxGenerateBillet,
    setTaxGenerateQRCodeStatic,
    setTaxLiquidateBillet,
    setTaxLiquidateQRCodeStatic,
    taxGenerateBillet,
    taxGenerateQRCodeStatic,
    taxLiquidateBillet,
    taxLiquidateQRCodeStatic,
  } = useAddBalance();

  const { setToken } = useToken();

  const location = useLocation();

  const allowedPaths = ["/app/pix"];
  const isPIXLocation = allowedPaths.includes(location.pathname);

  const initialValues: FormValues = {
    amount: amount > 0 ? formatNumberToCurrencyWithoutSimbol(amount) : "",
  };

  const { values, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    onSubmit: (values) => {},
  });

  const AmountFormatted = formatNumberToCurrency(
    convertedAmount(onlyNumbers(values.amount)) -
      (isPIX ? taxLiquidateQRCodeStatic : taxLiquidateBillet)
  );

  const handlefocus = () => {
    setError(false);
  };

  const handlePrevStep = () => {
    setChooseTypeAddBalance(null);
    setSelectedValue("");
    setChangeSideAnimation(true);
    setAmount(0);
  };

  const handleToggleModal = () => {
    setOpenAddBalanceModal(!openAddBalanceModal);
    setChooseTypeAddBalance(null);
    setSelectedValue("");
    setToken("");
    setAmount(0);
    setIndexPixKeyToCharge(-1);
  };

  const insuficienteBalance = useMemo(() => {
    return isPIX
      ? balance < taxGenerateQRCodeStatic
      : balance < taxGenerateBillet;
  }, [balance, taxGenerateBillet, taxGenerateQRCodeStatic]);

  const fetchTax = async () => {
    const slugs = [
      { slug: "create_qrcode", setResult: setTaxGenerateQRCodeStatic },
      { slug: "create_billet", setResult: setTaxGenerateBillet },
      { slug: "liquidate_billet", setResult: setTaxLiquidateBillet },
      { slug: "liquidate_qrcode", setResult: setTaxLiquidateQRCodeStatic },
    ];

    try {
      for (const { slug, setResult } of slugs) {
        const response = await ApiService.HttpPost({
          route: "customer-global-tax/",
          token: true,
          body: {
            slug: slug,
            tax_is_enabled: true,
          },
        });

        setResult(response.data.customer_tax_out);
      }
    } catch (err) {
      console.error("Erro", err);
    }
  };

  useEffect(() => {
    if (isPIX) {
      getPixKeys();
    }

    fetchTax();
    setChangeSideAnimation(true);
  }, []);

  useEffect(() => {
    setAmount(convertedAmount(onlyNumbers(values.amount)));
  }, [values.amount]);

  return (
    <motion.div
      initial={{
        x: !changeSideAnimation ? "-100%" : changeSideAnimation && "100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: !changeSideAnimation ? "100%" : changeSideAnimation && "-100%",
      }}
      transition={{ duration: 0.3 }}
      className="vh-100"
    >
      <div className="p-4">
        <div>
          <InputCurrency
            name="amount"
            value={values.amount}
            onChange={handleChange}
            label={
              isPIXLocation
                ? "Insira o valor da cobrança"
                : "Insira o valor que quer adicionar"
            }
            inputSize="large"
            required
            error={
              error && isPIX && isPIXLocation
                ? "O valor mínimo para cobrança via Qr code é de R$ 5,00 "
                : error && isPIX
                ? "O valor mínimo para recarga de saldo via Pix é de R$ 5,00 "
                : error &&
                  !isPIX &&
                  "O valor mínimo para recarga de saldo via boleto é de R$ 5,00 "
            }
            onFocus={handlefocus}
          />
        </div>
        {/* <div className="d-flex justify-content-start align-items-center container-balance">
          <Description color="#666666">{`A receber `}</Description>
          <Spacer left={3} />
          <Description className="font-weight-bolder">
            {values.amount === "" ? "R$ 0,00" : AmountFormatted}
          </Description>
        </div> */}

        <div>
          <Description color="#666666">{`Taxa de emissão `}</Description>
          <Description className="font-weight-bolder">
            R${" "}
            {formatNumberToCurrencyWithoutSimbol(
              isPIX ? taxGenerateQRCodeStatic : taxGenerateBillet
            )}{" "}
          </Description>
        </div>
        <div>
          <Description color="#666666">{`Taxa de liquidação `}</Description>
          <Description className="font-weight-bolder">
            R${" "}
            {formatNumberToCurrencyWithoutSimbol(
              isPIX ? taxLiquidateQRCodeStatic : taxLiquidateBillet
            )}{" "}
          </Description>
        </div>
        <Spacer top={20} />
        {insuficienteBalance && (
          <BalanceAlert
            title="Saldo insuficiente"
            message={"Você não tem saldo suficiente para continuar"}
          />
        )}
        <Spacer bottom={60} />
        <div className="d-flex justify-content-between w-100 pe-2">
          <Button
            label="Cancelar"
            mode="phantom"
            onClick={() => {
              setIndexPixKeyToCharge(-1);
              isPIXLocation ? handleToggleModal() : handlePrevStep();
            }}
          />
          <Button
            label="Avançar"
            mode="main"
            disabled={insuficienteBalance}
            onClick={() => {
              if (
                (isPIX &&
                  isPIXLocation &&
                  convertedAmount(onlyNumbers(values.amount)) < 5) ||
                values.amount === ""
              ) {
                setError(true);
              } else if (
                (isPIX && convertedAmount(onlyNumbers(values.amount)) < 5) ||
                values.amount === ""
              ) {
                setError(true);
              } else if (
                (!isPIX && convertedAmount(onlyNumbers(values.amount)) < 5) ||
                values.amount === ""
              ) {
                setError(true);
              } else {
                handleNextStep();
              }
            }}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default AddBalanceData;
