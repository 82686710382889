import { Button, Spacer } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import { Fragment } from "react";
import { ContainerDetails } from "~/pages/App/app-content.styles";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import {
  formatCPFCNPJ,
  formatDate,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import useAnimation from "~/hooks/useAnimation";
import useToken from "~/hooks/useToken";

import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";
import { ApiResponse, createBillet } from "~/types";
import useAccount from "~/hooks/useAccount";
import { formatISO, subDays } from "date-fns";
import format from "date-fns/format";

export type CollectionData = {
  email_notification: boolean;
  sms_notification: boolean;
};

type LateFee = {
  [key: string]: string;
};

export const COLOR_GRAY = "#666666";

export const getNotificationsMethod = ({
  email_notification,
  sms_notification,
}: CollectionData): string => {
  if (!email_notification && !sms_notification) {
    return "Nenhum meio de envio selecionado";
  }

  const methods = [
    email_notification && "Email",
    email_notification && sms_notification && " / ",
    sms_notification && "SMS",
  ].filter(Boolean);

  return methods.join("");
};

const Confimation = () => {
  const { beforeDays } = useAccount();
  const {
    handlePreviousStep,
    newCollectionData,
    isInstallments,
    overduePayment,
    earlyPayment,
    isRecurrence,
    contactInfo,
    isLoadingReceipt,
    splitName,
    setIsLoadingReceipt,
    setReceiptData,
    getBillets,
    setReceiptRender,
  } = useNewCollection();

  const { showToast } = useToast();

  const { sendPartnerToken, getToken } = useToken();

  const { setChangeSideAnimation } = useAnimation();

  const getLateFee: LateFee = {
    1: `R$ ${formatNumberToCurrencyWithoutSimbol(
      newCollectionData?.late_fee_amount ?? 0
    )}`,
    3: `${
      isNaN(newCollectionData?.late_fee_amount)
        ? 0
        : newCollectionData?.late_fee_amount
    }% a.m`,
    5: "Isento",
  };

  console.log([newCollectionData.late_fee_type]);
  console.log([newCollectionData.fine_type]);

  const getFine: LateFee = {
    1: `R$ ${formatNumberToCurrencyWithoutSimbol(
      newCollectionData?.fine_amount ?? 0
    )}`,
    2: `${
      isNaN(newCollectionData?.fine_amount) ? 0 : newCollectionData?.fine_amount
    }% a.m`,
    3: "Isento",
  };

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  const generateBillet = async () => {
    sendPartnerToken();

    const token = await getToken();

    setIsLoadingReceipt(true);

    try {
      const { data } = await ApiService.HttpPost<ApiResponse<createBillet>>({
        route: "charge/billet/",
        token: true,
        body: { ...newCollectionData, code: token },
      });

      setReceiptData(data.data);
      setIsLoadingReceipt(false);
      setReceiptRender(true);

      getBillets(fromDate, todayFormatted);
      showToast(
        "Boleto gerado",
        "Seu boleto foi gerado com sucesso",
        "success"
      );
    } catch (error: any) {
      setIsLoadingReceipt(false);
    }
  };

  return (
    <ContainerDetails>
      <table className=" w-100">
        <tbody>
          <tr>
            <td>
              <Description color={COLOR_GRAY}>Valor da cobrança</Description>
            </td>

            <td className="second-column">
              <Description>
                R${" "}
                {formatNumberToCurrencyWithoutSimbol(newCollectionData?.amount)}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color={COLOR_GRAY}>Forma de pagamento</Description>
            </td>

            <td className="second-column">
              <Description>
                {isRecurrence ? "Recorrência" : "Boleto "}
                {isInstallments && `parcelado em x`}
              </Description>
            </td>
          </tr>
          {isInstallments && (
            <tr>
              <td>
                <Description color={COLOR_GRAY}>
                  Valor da primeira parcela
                </Description>
              </td>

              <td className="second-column">
                <Description>{}</Description>
              </td>
            </tr>
          )}

          <tr>
            <td>
              <Description color={COLOR_GRAY}>Data de vencimento</Description>
            </td>

            <td className="second-column">
              <Description>
                {formatDate(newCollectionData?.due_date)}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color={COLOR_GRAY}>Pagador</Description>
            </td>

            <td className="second-column d-flex flex-column">
              <Description>{contactInfo?.name}</Description>
              <Description color={COLOR_GRAY}>
                {formatCPFCNPJ(contactInfo?.document_number)}
              </Description>
            </td>
          </tr>
        </tbody>
      </table>

      <Spacer bottom={20} />
      {splitName !== "" && (
        <div>
          <table className=" w-100">
            <thead>
              <th>Split</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Description color="#666666">Arranjo</Description>
                </td>

                <td className="second-column">
                  <Description>{splitName}</Description>
                </td>
              </tr>
            </tbody>
          </table>
          <Spacer bottom={20} />
        </div>
      )}

      <table className=" w-100">
        <thead>
          <th>Canais de cobrança</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <Description color="#666666">Será enviado por</Description>
            </td>

            <td className="second-column">
              <Description>
                {getNotificationsMethod(newCollectionData)}
              </Description>
            </td>
          </tr>
        </tbody>
      </table>
      <Spacer bottom={20} />

      {overduePayment && (
        <Fragment>
          <table className=" w-100">
            <thead>
              <th>Pagamento após vencimento</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Description color={COLOR_GRAY}>Multa por atraso</Description>
                </td>

                <td className="second-column">
                  <Description>
                    {getFine[newCollectionData.fine_type]}
                  </Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color={COLOR_GRAY}>Taxa de juros</Description>
                </td>

                <td className="second-column">
                  <Description>
                    {getLateFee[newCollectionData.late_fee_type]}
                  </Description>
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}

      <Spacer bottom={20} />

      {earlyPayment && (
        <Fragment>
          <table className=" w-100">
            <thead>
              <th>Pagamento antecipado</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Description color={COLOR_GRAY}>
                    Data limite para desconto
                  </Description>
                </td>

                <td className="second-column">
                  <Description>
                    {newCollectionData?.discount_date
                      ? formatDate(newCollectionData?.discount_date)
                      : "---"}
                  </Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color={COLOR_GRAY}>
                    Desconto aplicado
                  </Description>
                </td>

                <td className="second-column">
                  <Description>
                    R${" "}
                    {formatNumberToCurrencyWithoutSimbol(
                      isNaN(newCollectionData?.discount_amount)
                        ? 0
                        : newCollectionData?.discount_amount
                    )}
                  </Description>
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}

      <Spacer bottom={50} />

      <div className="d-flex justify-content-between">
        <Button
          mode="phantom"
          label={`Editar ${isRecurrence ? "recorrência" : "cobrança"}`}
          onClick={() => {
            setChangeSideAnimation(true);
            handlePreviousStep();
          }}
        />
        <Button
          mode="main"
          label={`Gerar ${isRecurrence ? "recorrência" : "cobrança"} agora`}
          loading={isLoadingReceipt}
          disabled={isLoadingReceipt}
          onClick={generateBillet}
        />
      </div>
    </ContainerDetails>
  );
};

export default Confimation;
