import {
  Button,
  LateralModal,
  ProgressBar,
  RadioGroup,
  Spacer,
} from "@zilliondigital/mirage-ui";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import useTransfers from "~/hooks/Transfers/useTransfers";
import DataTEV from "./TEV/DataTEV";
import DataTED from "./TED/DataTED";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import usePix from "~/hooks/PIX/usePix";
import SendTEDorTEV from "./TED/SendTEDorTEV";
import ContactsList from "./ContactsList";
import SelectBankAccount from "./TED/SelectBankAccount";
import { motion } from "framer-motion";
import TransferReceiptTED from "./TED/TransferReceiptTED";
import LoaderReceiptTED from "./TED/LoaderReceiptTED";
import TransferReceiptTEV from "./TEV/TransferReceiptTEV";
import useAddBalance from "~/hooks/useAddBalance";
import Description from "~/components/Texts/Description";
import { isWeekDay, isWorkingHours } from "~/services/utils";
import SelectBankAccountTEV from "./TEV/SelectBankAccountTEV";

const NewTransfer = () => {
  const {
    openNewTransferModal,
    setOpenNewTransferModal,
    openNewTransferModalInDash,
    setOpenNewTransferModalInDash,
  } = useTransfers();
  const {
    chooseNewTransfer,
    setChooseNewTransfer,
    setStepIndex,
    stepIndex,
    setSelectContact,
    selectContact,
    selectedValue,
    setChangeSideAnimation,
    setSelectedValue,
    transferReceiptTEDRender,
    isLoadingTransferReceiptTEDorTEV,
    setTransferReceiptTEDRender,
    setIsTEV,
    isTEV,
  } = useNewTransfer();

  const { setIsEmpty, isEmpty } = useAddBalance();

  const navigate = useNavigate();
  const { setOpenTransferWithPixModal } = usePix();

  const location = useLocation();

  const isDash =
    location.pathname === "/app/dashboard" || location.pathname === "/app";

  const { setIsManual } = useTransfers();

  const rendersNewTransfer = {
    ted: [
      selectContact ? <DataTED /> : <ContactsList />,
      !selectContact && <SelectBankAccount />,
      <SendTEDorTEV />,
    ],
    tev: [<DataTEV />, <SelectBankAccountTEV />, <SendTEDorTEV />],
  };

  const transfersTitles = {
    ted: [
      selectContact ? "Transferência TED" : "Transferência TED",
      selectContact
        ? "Selecionar conta bancária"
        : "Efetuar nova transferência",
      "Efetuar nova transferência",
    ],
    tev: [
      selectContact ? "Selecionar contato" : " Transferência entre contas",
      selectContact
        ? "Selecionar conta bancária"
        : "Efetuar nova transferência",
      "Efetuar nova transferência",
    ],
  };

  const [canNotDoTED, setCanNotDoTED] = useState<boolean>(false);

  function handleChange(value: any) {
    setSelectedValue(value);

    if (value === "0") {
      if ((isWeekDay && !isWorkingHours) || !isWeekDay) {
        setCanNotDoTED(true);
        setSelectedValue("ted");
        setIsEmpty(false);
      } else {
        setSelectedValue("ted");
        setIsEmpty(false);
      }
    }

    if (value === "1") {
      setSelectedValue("pix");
      setIsEmpty(false);
      setCanNotDoTED(false);
    }

    if (value === "2") {
      setSelectedValue("tev");
      setIsEmpty(false);
      setCanNotDoTED(false);
    }
  }

  function handleAdvanceClick() {
    if (selectedValue === "") {
    } else {
      if (selectedValue === "ted") {
        setChooseNewTransfer("ted");
      } else if (selectedValue === "tev") {
        setIsTEV(true);
        setChooseNewTransfer("tev");
      }
    }
  }

  const handleButtonClick = () => {
    if (selectedValue === "pix") {
      navigate("/app/pix");
      setOpenNewTransferModal(false);
      setOpenTransferWithPixModal(true);
    } else {
      setChangeSideAnimation(false);
      handleAdvanceClick();
    }
  };

  const closeModal = () => {
    isDash
      ? setOpenNewTransferModalInDash(!openNewTransferModalInDash)
      : setOpenNewTransferModal(!openNewTransferModal);
    setChooseNewTransfer(null);
    setStepIndex(0);
    setSelectContact(false);
    setTransferReceiptTEDRender(false);
    setSelectedValue("");
    setIsTEV(false);
    setTransferReceiptTEDRender(false);
    setCanNotDoTED(false);
    setIsManual(false);
  };

  useEffect(() => {
    if (!openNewTransferModal || !openNewTransferModalInDash) {
      setIsEmpty && setIsEmpty(true);
    }
  }, [openNewTransferModal, openNewTransferModalInDash]);

  const radioOptions = [
    {
      title: "TED",
      description:
        "Transferência entre conta bancárias de diferentes instituições financeiras.",
      value: "0",
    },
    {
      title: "PIX",
      description:
        "Transferência a qualquer hora e dia da semana, de forma instantânea.",
      value: "1",
    },
    {
      title: "Transferência entre contas",
      description: "Transferência entre conta bancárias da mesma instituição.",

      value: "2",
    },
  ];

  const percents = [20, isTEV ? 50 : 35, 50];

  return (
    <LateralModal
      open={isDash ? openNewTransferModalInDash : openNewTransferModal}
      title={
        chooseNewTransfer === null
          ? "Tipo de transferência"
          : isLoadingTransferReceiptTEDorTEV
          ? "Processando transação"
          : transferReceiptTEDRender
          ? "Comprovante de transferência"
          : transfersTitles[chooseNewTransfer][stepIndex]
      }
      toggleModal={closeModal}
      header
    >
      <>
        {!transferReceiptTEDRender && !isLoadingTransferReceiptTEDorTEV && (
          <ProgressBar percent={percents[stepIndex]} />
        )}

        {transferReceiptTEDRender && chooseNewTransfer !== null && isTEV ? (
          <TransferReceiptTEV />
        ) : (
          transferReceiptTEDRender &&
          chooseNewTransfer !== null &&
          !isTEV && <TransferReceiptTED />
        )}
        {isLoadingTransferReceiptTEDorTEV && chooseNewTransfer !== null && (
          <LoaderReceiptTED />
        )}

        {chooseNewTransfer === null ? (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3 }}
          >
            <div className=" w-100  d-flex flex-column justify-content-start p-4">
              <RadioGroup
                display="row"
                options={radioOptions}
                wrap
                value=""
                onChange={(value) => handleChange(value)}
                emptyValue={isEmpty}
              />
              <Spacer bottom={20} />

              {canNotDoTED && (
                <Description color="red">
                  Transferências TED só estão disponíveis de Segunda a Sexta das
                  07h às 16:30h
                </Description>
              )}
              <div className="d-flex w-100 mt-5 justify-content-between">
                <Button
                  mode="border"
                  label="Cancelar transferência"
                  onClick={closeModal}
                />
                <Button
                  mode="main"
                  label="Avançar"
                  onClick={() => {
                    handleButtonClick();
                  }}
                  disabled={selectedValue === "" || canNotDoTED}
                />
              </div>
            </div>
          </motion.div>
        ) : (
          !transferReceiptTEDRender &&
          !isLoadingTransferReceiptTEDorTEV && (
            <> {rendersNewTransfer[chooseNewTransfer][stepIndex]} </>
          )
        )}
      </>
    </LateralModal>
  );
};

export default NewTransfer;
