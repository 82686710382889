import { Spacer, Button } from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import { ContainerDetails } from "../../app-content.styles";
import useAccount from "~/hooks/useAccount";
import {
  formatCPFCNPJ,
  formatDate,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import useNewCollection from "~/hooks/Collections/useNewCollectionOrRecurrence";
import { getNotificationsMethod } from "./Confimation";
import { baseURL } from "~/services/api";

export const COLOR_GRAY = "#666666";

const ReceiptCollection = () => {
  const link =
    "https://receipt.fitbank.com.br/receiptfile/2023-07-18/gq0jg40r.pdf";

  const { formatNumberToCurrency } = useAccount();

  const { receiptData, newCollectionData } = useNewCollection();

  const downloadPDF = (url: string, filename: string) => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FileName.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
      });
  };
  const handleDownload = () => {
    const pdfUrl = "";
    const filename = "comprovante.pdf";
    downloadPDF(pdfUrl, filename);
  };

  return (
    <ContainerDetails>
      <table className=" w-100">
        <tbody>
          <tr>
            <td>
              <Description color="#666666">Valor da cobrança</Description>
            </td>

            <td className="second-column">
              <Description>
                {formatNumberToCurrency(receiptData?.amount)}
              </Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Forma de pagamento</Description>
            </td>

            <td className="second-column">
              <Description>Boleto</Description>
            </td>
          </tr>
          <tr>
            <td>
              <Description color="#666666">Data de vencimento</Description>
            </td>

            <td className="second-column">
              <Description>{formatDate(receiptData?.due_date)}</Description>
            </td>
          </tr>
          {true && (
            <tr>
              <td>
                <Description color="#666666">Pagador</Description>
              </td>

              <td className="second-column d-flex flex-column">
                <Description>{receiptData?.payer_data.name}</Description>
                <Description color={COLOR_GRAY}>
                  {formatCPFCNPJ(receiptData?.payer_data.document_number)}
                </Description>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Spacer bottom={40} />

      <table className=" w-100">
        <thead>
          <th>Canais de cobrança</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <Description color="#666666">Será enviado por</Description>
            </td>

            <td className="second-column">
              <Description>
                {getNotificationsMethod(newCollectionData)}
              </Description>
            </td>
          </tr>
        </tbody>
      </table>

      {receiptData?.late_fee_type !== "5" && receiptData?.fine_type !== "3" && (
        <>
          <Spacer bottom={40} />

          <table className=" w-100">
            <thead>
              <th>Pagamento após o vencimento</th>
            </thead>
            <tbody>
              {receiptData?.fine_type !== "3" && (
                <tr>
                  <td>
                    <Description color="#666666">Multa por atraso</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {receiptData?.fine_type === "1"
                        ? `R$ ${formatNumberToCurrencyWithoutSimbol(
                            receiptData?.fine_amount
                          )}`
                        : `${receiptData?.fine_amount} %`}
                    </Description>
                  </td>
                </tr>
              )}
              {receiptData?.late_fee_type !== "5" && (
                <tr>
                  <td>
                    <Description color="#666666">Taxa de juros</Description>
                  </td>

                  <td className="second-column">
                    <Description>
                      {receiptData?.late_fee_type === "1"
                        ? `R$ ${formatNumberToCurrencyWithoutSimbol(
                            receiptData?.late_fee_amount
                          )}`
                        : ` ${receiptData?.late_fee_amount} % `}
                    </Description>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}

      {receiptData?.discount_type !== "0" && (
        <>
          <Spacer bottom={20} />

          <table className=" w-100">
            <thead>
              <th>Pagamento antecipado</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Description color="#666666">
                    Data limite para desconto
                  </Description>
                </td>

                <td className="second-column">
                  <Description>
                    {formatDate(receiptData?.discount_date)}
                  </Description>
                </td>
              </tr>
              <tr>
                <td>
                  <Description color="#666666">Desconto aplicado</Description>
                </td>

                <td className="second-column">
                  <Description>
                    R${" "}
                    {formatNumberToCurrencyWithoutSimbol(
                      receiptData?.discount_amount
                    )}
                  </Description>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      <Spacer bottom={50} />

      <div className="d-flex justify-content-end">
        <Button
          mode="border"
          //icon={DownloadIcon}
          label="Visualizar boleto"
          // onClick={() => DownloadReceipt(link)}
          onClick={() => {
            window.open(`${baseURL}/billet/${receiptData?.id}/html`, "_blank");
          }}
        />
      </div>
    </ContainerDetails>
  );
};

export default ReceiptCollection;
