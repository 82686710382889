import { Spacer } from "@zilliondigital/mirage-ui";
import Loader from "~/components/Loader";
import styled from "styled-components";

const LoaderHeaderContainer = styled.div`
  position: absolute;
  top: 95px;
  left: 90px;
  z-index: 2;
  display: flex;

  .container-1 {
    position: relative;
    left: 40px;
    top: -82px;
    display: flex;
  }

  .container-2 {
    position: relative;
    left: 335px;
    top: 80px;
  }
`;

const LoaderHeader = () => {
  return (
    <LoaderHeaderContainer>
      <div className="container-1">
        <div className="ms-4">
          <Loader
            x=""
            y=""
            rx="4"
            ry="4"
            widthContainer="52"
            isHeight="52"
            isWidth="52 "
            box="0 0 52 52"
            heightContainer="52"
          />
          <Spacer bottom={-15} />
        </div>
        <div className="ms-4">
          <Spacer bottom={-5} />
          <Loader
            x=""
            y=""
            rx="4"
            ry="4"
            widthContainer="240"
            isHeight="10"
            isWidth="240"
            box="0 0 240 10"
            heightContainer="10"
          />
          <Spacer bottom={0} />
          <Loader
            x=""
            y=""
            rx="4"
            ry="4"
            widthContainer="240"
            isHeight="32"
            isWidth="240"
            box="0 0 240 32"
            heightContainer="32"
          />
        </div>
        <Spacer left={40} />
        <div className="ms-4">
          <Spacer bottom={-5} />
          <Loader
            x=""
            y=""
            rx="4"
            ry="4"
            widthContainer="240"
            isHeight="10"
            isWidth="240"
            box="0 0 240 10"
            heightContainer="10"
          />
          <Spacer bottom={0} />
          <Loader
            x=""
            y=""
            rx="4"
            ry="4"
            widthContainer="240"
            isHeight="32"
            isWidth="240"
            box="0 0 240 32"
            heightContainer="32"
          />
        </div>
      </div>
    </LoaderHeaderContainer>
  );
};

export default LoaderHeader;
