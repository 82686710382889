const dataStatements = [
  {
    account_number: null,
    account_number_digit: null,
    agency: null,
    amount: 0,
    bank: null,
    code: null,
    description: "Saldo Inicial",
    document_number: null,
    entry_date: "2023-05-01T00:00:00",
    identifier: 0,
    name: null,
    nsu: null,
    operation_id: null,
    operation_type: "InitialBalance",
    operation_type_id: 0,
    tags: [],
    type: "C",
    voucher_url: null,
  },

  {
    account_number: null,
    account_number_digit: null,
    agency: null,
    amount: 5,
    bank: null,
    code: null,
    description:
      "Crédito de recebimento via Pix JHONNATTHAN SANTANA - 33845910000124",
    document_number: null,
    entry_date: "2023-05-12T16:43:58.69",
    identifier: 3344333016,
    name: null,
    nsu: null,
    operation_id: 292947460,
    operation_type: "CreditPixIn",
    operation_type_id: 41,
    tags: [
      "39884255830",
      "Jhonnatthan Santana Simões dos Santos",
      "Nu Pagamentos S.A",
    ],
    type: "C",
    voucher_url:
      "https://receipt.fitbank.com.br/receiptapi/pdf?filename=2023-05-12/gc00v3op.pdf",
  },

  {
    account_number: null,
    account_number_digit: null,
    agency: null,
    amount: -5,
    bank: null,
    code: null,
    description: "Transferência via Pix Nu Pagamentos S.A - 1 736544-6",
    document_number: null,
    entry_date: "2023-05-12T16:51:32.99",
    identifier: 3344376325,
    name: null,
    nsu: null,
    operation_id: 292952553,
    operation_type: "PreDebitPixOut",
    operation_type_id: 40,
    tags: [],
    type: "C",
    voucher_url:
      "https://receipt.fitbank.com.br/receiptapi/pdf?filename=2023-05-12/yijynukh.pdf",
  },
  {
    account_number: null,
    account_number_digit: null,
    agency: null,
    amount: 5,
    bank: null,
    code: null,
    description:
      "Crédito de recebimento via Pix JHONNATTHAN SANTANA - 33845910000124",
    document_number: null,
    entry_date: "2023-05-18T01:09:25.873",
    identifier: 3385399224,
    name: null,
    nsu: null,
    operation_id: 297654906,
    operation_type: "CreditPixIn",
    operation_type_id: 41,
    tags: [
      "39884255830",
      "Jhonnatthan Santana Simões dos Santos",
      "Nu Pagamentos S.A",
    ],
    type: "C",
    voucher_url:
      "https://receipt.fitbank.com.br/receiptapi/pdf?filename=2023-05-18/vijnyadp.pdf",
  },
  {
    account_number: null,
    account_number_digit: null,
    agency: null,
    amount: 5,
    bank: null,
    code: null,
    description: "Saldo Final",
    document_number: null,
    entry_date: "2023-05-31T00:00:00",
    identifier: 0,
    name: null,
    nsu: null,
    operation_i: null,
    operation_type: "FinalBalance",
    operation_type_id: 0,
    tags: [],
    type: "C",
    voucher_url: null,
  },
];

export default dataStatements;
