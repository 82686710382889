import {
  BalanceAlert,
  Button,
  InputCurrency,
  LateralModal,
  Margin,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";
import { Fragment, useEffect, useMemo, useState } from "react";
import Description from "~/components/Texts/Description";
import Title from "~/components/Texts/Title";
import useAccount from "~/hooks/useAccount";
import usePix from "~/hooks/PIX/usePix";
import useMyKeys from "~/hooks/PIX/useMyKeys";
import useToast from "~/hooks/useToast";
import styled from "styled-components";
import * as Yup from "yup";

import useAddBalance from "~/hooks/useAddBalance";
import SelectedContactIcon from "~/assets/icons/contacts/selected-contact.png";
import CPFKeyIcon from "~/assets/icons/round_account_circle_black_24dp.png";
import MailKeyIcon from "~/assets/icons/mail_FILL0_wght400_GRAD0_opsz48.png";
import PhoneKeyIcon from "~/assets/icons/phone_iphone_FILL0_wght400_GRAD0_opsz48.png";
import KeyIcon from "~/assets/icons/key.png";
import ApiService from "~/services/api";
import { useFormik } from "formik";
import {
  convertedAmount,
  formatCPFCNPJ,
  formatNumberToCurrencyWithoutSimbol,
} from "~/utils";
import { onlyNumbers } from "~/services/utils";
import theme from "~/themes/theme";
import label from "~/labels";

const ContainerImage = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 10px;

  .profile-picture {
    width: 48px;
    height: 48px;
    background-color: ${() =>
      label.usePrimaryColor?.includes("image_contacts")
        ? theme.colors.primary.light300
        : theme.colors.secondary.light300};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    h1 {
      margin-top: 7px;
      font-size: 20px;
      font-weight: 600;
      color: ${() =>
        label.usePrimaryColor?.includes("image_contacts")
          ? theme.colors.primary.main
          : theme.colors.secondary.main};
      text-transform: uppercase;
    }
  }

  .bank-accounts-picture {
    width: 10px;
    height: 10px;
    background-color: #d8d8d7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;

const ContainerRenderData = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  cursor: pointer;

  .contact-selected-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #cedce6;
    display: grid;
    place-items: center;
    margin-right: 10px;
  }

  .selected-contact-image {
    width: 20px;
  }

  .img-bank-icon {
    width: 25px;
  }
`;

type FormValues = {
  amount: string;
};

const FormSchema = Yup.object().shape({
  amount: Yup.string(),
});

const GenerateQRCodeModal = () => {
  const {
    setOpenGenerateQRCodeModal,
    openGenerateQRCodeModal,
    dataQRCodeStatic,
    setDataQRCodeStatic,
  } = usePix();

  const { pixKeysData } = useMyKeys();

  const {
    indexPixKeyToCharge,
    setIndexPixKeyToCharge,
    setTaxGenerateQRCodeStatic,
    taxGenerateQRCodeStatic,
  } = useAddBalance();

  const [isLoading, setIsloading] = useState<boolean>(false);

  const [amount, setAmount] = useState<number>(0);

  const { balance, getBalance } = useAccount();

  const {} = useMyKeys();

  const { showToast } = useToast();

  type typeKeysProps = {
    [key: string]: string;
  };

  const typesKeysLabel: typeKeysProps = {
    telefone: "Celular",
    email: "Email",
    cpf: "CPF",
    cnpj: "CNPJ",
    evp: "Chave aleatória",
  };

  const IconsKeys: typeKeysProps = {
    evp: KeyIcon,
    telefone: PhoneKeyIcon,
    email: MailKeyIcon,
    cpf: CPFKeyIcon,
    cnpj: CPFKeyIcon,
  };

  const columnsPixKeys = [
    {
      label: "Status",
      key: "status",

      renderColumn: (data: any) => <></>,

      renderData: (data: any, index: number) => (
        <ContainerRenderData
          onClick={() => {
            setIndexPixKeyToCharge(
              indexPixKeyToCharge === data.index ? -1 : data.index
            );
            // handleShowTheSelectBankAccount(data);
          }}
        >
          <div className="d-flex align-items-center">
            {indexPixKeyToCharge === data.index ? (
              <ContainerImage>
                <div className="contact-selected-image">
                  <img
                    className="selected-contact-image"
                    src={SelectedContactIcon}
                  />
                </div>
              </ContainerImage>
            ) : (
              <ContainerImage>
                <div className="container-profile-picture">
                  <div className="rounded-circle  bank-accounts-picture">
                    <img className="img-bank-icon" src={IconsKeys[data.type]} />
                  </div>
                </div>
              </ContainerImage>
            )}
            <Spacer left={10} />
            <div className="d-flex flex-column">
              <Title>{typesKeysLabel[data.type]}</Title>
              <Description>
                {" "}
                {data.type === "cpf" || data.type === "cnpj"
                  ? formatCPFCNPJ(data.key)
                  : data.key}
              </Description>
            </div>
          </div>
        </ContainerRenderData>
      ),
    },
  ];

  const dataKeyPixTable = pixKeysData
    ?.filter((key) => key.key !== null && key.status === "registered")
    .map((key: any, index: number) => ({
      index: index,
      type: key.type,
      key: key.key,
      id: key.id,
    }));

  const initialValues: FormValues = {
    amount: amount > 0 ? formatNumberToCurrencyWithoutSimbol(amount) : "",
  };

  const { values, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    onSubmit: (values) => {},
  });

  const insuficientBalance = useMemo(() => {
    return balance < taxGenerateQRCodeStatic;
  }, [balance, taxGenerateQRCodeStatic]);

  const fetchTax = async () => {
    const slugs = [
      { slug: "create_qrcode", setResult: setTaxGenerateQRCodeStatic },
    ];

    try {
      for (const { slug, setResult } of slugs) {
        const response = await ApiService.HttpPost({
          route: "customer-global-tax/",
          token: true,
          body: {
            slug: slug,
            tax_is_enabled: true,
          },
        });

        setResult(response.data.customer_tax_out);
      }
    } catch (err) {
      console.error("Erroou", err);
    }
  };

  const createQrCode = async () => {
    setIsloading(true);
    try {
      const { data } = await ApiService.HttpPost({
        route: "pix/qrcode/",
        token: true,
        body: {
          amount: null,
          key: String(dataKeyPixTable[indexPixKeyToCharge]?.id),
        },
      });

      setDataQRCodeStatic(data.data);
      setOpenGenerateQRCodeModal(false);
      showToast(
        "QR Code gerado",
        "Seu QR Code foi gerado com sucesso",
        "success"
      );
      setIsloading(false);
    } catch (e) {
      console.warn(e);
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (!openGenerateQRCodeModal) {
      setIndexPixKeyToCharge && setIndexPixKeyToCharge(-1);
    }
  }, [openGenerateQRCodeModal]);

  useEffect(() => {
    setAmount(convertedAmount(onlyNumbers(values.amount)));
  }, [values.amount]);

  useEffect(() => {
    fetchTax();
    getBalance();
  }, []);

  return (
    <Fragment>
      <LateralModal
        style={{ zIndex: "3" }}
        header
        title="Nova chave QR Code estático"
        open={openGenerateQRCodeModal}
        toggleModal={() => setOpenGenerateQRCodeModal(!openGenerateQRCodeModal)}
      >
        <div>
          <Spacer bottom={-30} />
          <div className="p-4">
            <Margin bottom={40} />
            <Description color="#666666">
              Selecione uma chave Pix para gerar seu QR Code estático
            </Description>
            <Margin bottom={40} />
          </div>
          <Spacer bottom={-40} />
          <div className="ps-4 mb-5 pe-4">
            <Description color="#666666">{`Taxa de emissão `}</Description>
            <Description className="font-weight-bolder">
              R$ {formatNumberToCurrencyWithoutSimbol(taxGenerateQRCodeStatic)}
            </Description>
            <Spacer bottom={10} />
            {insuficientBalance && (
              <BalanceAlert
                title="Saldo insuficiente"
                message={"Você não tem saldo suficiente para continuar"}
              />
            )}
          </div>

          <p className="ps-4 mt-5">Chaves disponíveis</p>
          {pixKeysData?.length <= 0 && (
            <div className="p-4 d-flex justify-content-center">
              <Description>
                Você não possui nenhuma chave pix cadastrada ou disponível para
                gera QR Code.
              </Description>
            </div>
          )}
          {pixKeysData?.length > 0 && (
            <>
              <div style={{ zIndex: "1" }}>
                <Table columns={columnsPixKeys} data={dataKeyPixTable} />
              </div>
            </>
          )}
          <Spacer bottom={20} />
          <div className="p-4">
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  mode="phantom"
                  label="Cancelar"
                  onClick={() => {
                    setOpenGenerateQRCodeModal(false);
                    setIndexPixKeyToCharge(-1);
                  }}
                />
              </div>

              <div className="d-flex">
                <Spacer left={10} />
                <Button
                  label="Gerar QR Code"
                  mode="main"
                  disabled={indexPixKeyToCharge === -1 || insuficientBalance}
                  onClick={createQrCode}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </LateralModal>
    </Fragment>
  );
};

export default GenerateQRCodeModal;
