import {
  BalanceAlert,
  Button,
  Input,
  InputCurrency,
  Spacer,
} from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import styled from "styled-components";
import useAccount from "~/hooks/useAccount";
import EyeIcon from "~/assets/icons/round_visibility_black_24dp.png";
import EyeOffIcon from "~/assets/icons/round_visibility_off_black_36dp.png";
import { Title } from "../../app-content.styles";
import usePayments from "~/hooks/usePayments";
import { formatCPFCNPJ, formatNumberToCurrencyWithoutSimbol } from "~/utils";
import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";

import useToken from "~/hooks/useToken";
import { Fragment, useEffect, useMemo } from "react";
import { format, subDays } from "date-fns";

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
  }

  th {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
  }

  .second-column {
    text-align: right;
  }
  padding: 20px;

  .image-eye {
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  .container-balance {
    position: relative;
  }

  .container-input-date {
    position: absolute;
    top: -70px;
    right: 0;
  }

  .container-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }
`;

const PaymentData = () => {
  const {
    balanceFormatted,
    hideAvailableBalance,
    setHideAvailableBalance,
    formatNumberToCurrency,
    getBalance,
    balance,
    beforeDays,
  } = useAccount();
  const { showToast } = useToast();
  const {
    prevStep,
    taxPayments,
    setTaxPayments,
    newPaymentData,
    descriptionPayment,
    setDataReceiptPayment,

    setIsLoadingReceipt,
    setRenderReceipt,

    getPayments,
  } = usePayments();

  const { sendPartnerToken, getToken } = useToken();

  const formatString = (str: string) => {
    let formattedStr = "";

    formattedStr += str.substr(0, 5) + ".";
    formattedStr += str.substr(5, 5) + " ";
    formattedStr += str.substr(10, 5) + ".";
    formattedStr += str.substr(15, 6) + " ";
    formattedStr += str.substr(21, 5) + ".";
    formattedStr += str.substr(26, 6) + " ";
    formattedStr += str.substr(32, 1) + " ";
    formattedStr += str.substr(33);

    return formattedStr;
  };

  function formatDate(dateString: string) {
    if (!dateString) {
      return dateString;
    }

    const dateParts = dateString.split("T")[0].split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }
  const formatAmount = (number: number) => Number(number).toFixed(2);

  const scheduleDate = () => {
    const actualDate = new Date();
    actualDate.setDate(actualDate.getDate() + 1);
    const thesScheduleDate = actualDate.toISOString();

    return thesScheduleDate;
  };

  const insuficienteBalance = useMemo(() => {
    return balance < newPaymentData?.data.updated_amount + taxPayments;
  }, [balance, newPaymentData?.data.updated_amount, taxPayments]);

  const today = new Date();
  const todayFormatted = format(today, "yyyy-MM-dd");
  const fromDate = format(subDays(today, beforeDays), "yyyy-MM-dd");

  const fetchTax = async () => {
    try {
      const response = await ApiService.HttpPost({
        route: "customer-global-tax/",
        token: true,
        body: {
          slug: "payment_billet",
          tax_is_enabled: true,
        },
      });

      setTaxPayments(response.data.customer_tax_out);
    } catch (err) {
      console.error("Erro", err);
    }
  };

  const PayBillet = async () => {
    sendPartnerToken();

    const token = await getToken();

    setIsLoadingReceipt(true);

    try {
      const today = new Date();
      const todayString = today.toISOString();

      const payload: any = {
        amount: newPaymentData?.data.updated_amount
          ? formatAmount(newPaymentData?.data.updated_amount)
          : formatAmount(newPaymentData?.data.amount),
        barcode: newPaymentData?.data.barcode,
        code: token,
        description: descriptionPayment ?? "-",
        due_date: newPaymentData?.data.due_date
          ? formatDate(newPaymentData?.data.due_date)
          : formatDate(todayString),
        original_amount: formatAmount(newPaymentData?.data.original_amount),
      };

      if (newPaymentData?.data.has_cip) {
        payload["beneficiary_document_number"] =
          newPaymentData.data.beneficiary_document_number;
        payload["beneficiary_name"] = newPaymentData.data.beneficiary_name;
        payload["discount"] = newPaymentData?.data.discount_value;
        payload["fine"] = newPaymentData?.data.fine_value;
        payload["interest"] = newPaymentData?.data.interest_value;
        payload["payment_amount"] = formatAmount(
          newPaymentData.data.updated_amount
        );
        payload["payer_document_number"] =
          newPaymentData.data.payer_document_number;
        payload["payer_name"] = newPaymentData.data.payer_name;
        payload["payment_date"] = formatDate(todayString);

        payload["payment_was_made"] = newPaymentData?.data.payment_was_made;
      } else {
        payload["beneficiary_document_number"] =
          newPaymentData.data.concessionaire_code;
        payload["beneficiary_name"] = newPaymentData.data.concessionaire_name;
        payload["payment_amount"] = formatAmount(newPaymentData.data.amount);
        payload["payment_date"] = formatDate(todayString);
      }

      const { data } = await ApiService.HttpPost({
        route: "charge/pay-billet/",
        body: payload,
        token: true,
      });

      setDataReceiptPayment(data);

      setIsLoadingReceipt(false);
      setRenderReceipt(true);

      getPayments({ from_date: fromDate, to_date: todayFormatted });

      showToast(
        "Pagamento Efetuado com sucesso",
        "Seu pagamento foi efetuado com sucesso.",
        "success"
      );

      setTimeout(() => {
        getBalance();
      }, 2000);
    } catch (error: any) {
      setIsLoadingReceipt(false);

      // if (error.response.data.status === "error") {
      //   showToast(
      //     "Erro",
      //     "Boleto já pago ou fora do prazo de pagamento.",
      //     "error"
      //   );
      // }
    }
  };

  useEffect(() => {
    fetchTax();
  }, []);

  const renderCIPInformations = () => {
    return (
      <Fragment>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Data do pagamento</Description>
              </td>

              <td className="second-column">
                <div className="position-relative">
                  <div className="col-6 container-input-date pt-5">
                    {/* <Input type="date" label="" value="25/02/1997" /> */}
                    <Description color="#666666">Hoje</Description>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Código de barras</Description>
              </td>

              <td className="second-column w-50">
                <Description>
                  {formatString(newPaymentData?.data.barcode)}
                </Description>
              </td>
            </tr>
            {newPaymentData?.data.beneficiary_name && (
              <tr>
                <td>
                  <Description color="#666666">Favorecido</Description>
                </td>

                <td className="second-column d-flex flex-column">
                  <Description>
                    {newPaymentData?.data.beneficiary_name}
                  </Description>
                  <Spacer bottom={10} />
                  <Description className="fw-bold">
                    {formatCPFCNPJ(
                      newPaymentData?.data.beneficiary_document_number
                    )}
                  </Description>
                </td>
              </tr>
            )}

            <tr>
              <td>
                <Description color="#666666">Data de vencimento</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatDate(newPaymentData?.data.due_date)}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Valor original</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatNumberToCurrency(newPaymentData?.data.amount)}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Pagador</Description>
              </td>

              <td className="second-column d-flex flex-column">
                <Description>{newPaymentData?.data.payer_name}</Description>
                <Spacer bottom={10} />
                <Description className="fw-bold">
                  {formatCPFCNPJ(newPaymentData?.data.payer_document_number)}
                </Description>
              </td>
            </tr>
          </tbody>
        </table>
        <Spacer top={20} />
        <Title> Dados adicionais do pagamento</Title>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Multa</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatNumberToCurrency(newPaymentData?.data.fine_value ?? 0)}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Juros</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatNumberToCurrency(
                    newPaymentData?.data.interest_value ?? 0
                  )}
                </Description>
              </td>
            </tr>
            <tr>
              <td>
                <Description color="#666666">Desconto</Description>
              </td>

              <td className="second-column">
                <Description>
                  {" "}
                  {formatNumberToCurrency(
                    newPaymentData?.data.discount_value ?? 0
                  )}
                </Description>
              </td>
            </tr>

            <Spacer top={20} />

            <tbody />
          </tbody>
        </table>
        <Spacer top={20} />
        <div className="w-100">
          <Input
            type="textarea"
            rows={3}
            label="Descrição"
            placeholder="Deixe um texto aqui."
          />
        </div>
        <div className="w-100" style={{ minHeight: "80px" }}></div>
      </Fragment>
    );
  };

  const renderBillletInformations = () => {
    return (
      <Fragment>
        <table className=" w-100">
          <tbody>
            <tr>
              <td>
                <Description color="#666666">Data do pagamento</Description>
              </td>

              <td className="second-column">
                <div className="position-relative">
                  <div className="col-6 container-input-date pt-5">
                    {/* <Input type="date" label="" value="25/02/1997" /> */}
                    <Description color="#666666">Hoje</Description>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Código de barras</Description>
              </td>

              <td className="second-column w-50">
                <Description>
                  {formatString(newPaymentData?.data.barcode)}
                </Description>
              </td>
            </tr>
            {newPaymentData?.data.concessionaire_name && (
              <tr>
                <td>
                  <Description color="#666666">Favorecido</Description>
                </td>

                <td className="second-column d-flex flex-column">
                  <Description>
                    {newPaymentData?.data.concessionaire_name}
                  </Description>
                  <Spacer bottom={10} />
                  <Description className="fw-bold">
                    {formatCPFCNPJ(newPaymentData?.data.concessionaire_code)}
                  </Description>
                </td>
              </tr>
            )}

            <tr>
              <td>
                <Description color="#666666">Data de vencimento</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatDate(newPaymentData?.data.due_date)}
                </Description>
              </td>
            </tr>

            <tr>
              <td>
                <Description color="#666666">Valor original</Description>
              </td>

              <td className="second-column">
                <Description>
                  {formatNumberToCurrency(newPaymentData?.data.amount)}
                </Description>
              </td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  };

  return (
    <Container className="p-4   ">
      <div className="d-flex justify-content-between align-items-center">
        <div className="w-100">
          <InputCurrency
            inputSize="large"
            label="Valor a ser pago"
            value={
              newPaymentData?.data?.updated_amount
                ? formatNumberToCurrencyWithoutSimbol(
                    String(newPaymentData?.data?.updated_amount)
                  )
                : formatNumberToCurrencyWithoutSimbol(
                    newPaymentData?.data?.amount
                  )
            }
            disabled={true}
          />
        </div>

        {/* <div className="ms-2 pb-3">
          <Description color="#666666">Agendar</Description>
          <div onClick={() => setIsSchedulingPayment(!isSchedulingPayment)}>
            <Toggle mode="large" checked={isSchedulingPayment} />
          </div>
        </div> */}
      </div>
      <Spacer bottom={-10} />

      <div className="d-flex justify-content-start align-items-center container-balance">
        <Description color="#666666">
          {`Saldo disponível atual é de
  `}
        </Description>
        <Spacer left={3} />
        <Description className="font-weight-bolder">
          {!hideAvailableBalance ? balanceFormatted : "R$ ******"}
        </Description>
        <Spacer left={5} />
        <img
          className="image-eye"
          src={!hideAvailableBalance ? EyeIcon : EyeOffIcon}
          onClick={() => setHideAvailableBalance(!hideAvailableBalance)}
        />
      </div>
      <div>
        <Description color="#666666">{`Taxa `}</Description>
        <Description className="font-weight-bolder">
          R$ {formatNumberToCurrencyWithoutSimbol(taxPayments)}{" "}
        </Description>
      </div>
      <Spacer top={20} />
      {insuficienteBalance && (
        <BalanceAlert
          title="Saldo insuficiente"
          message={"Você não tem saldo suficiente pra efetuar esse pagamento"}
        />
      )}

      <Spacer top={20} />
      <Fragment>
        {newPaymentData?.data.has_cip
          ? renderCIPInformations()
          : renderBillletInformations()}
      </Fragment>
      <div className=" container-buttons">
        <Button mode="phantom" label="Cancelar" onClick={prevStep} />
        <Button
          mode="main"
          label="Efetuar pagamento agora"
          onClick={() => PayBillet()}
          disabled={insuficienteBalance}
        />
      </div>
    </Container>
  );
};

export default PaymentData;
