import React from "react";
import ContentLoader from "react-content-loader";

type LoaderProps = {
  x: string;
  y: string;
  rx: string;
  ry: string;
  isWidth: string;
  isHeight: string;
  widthContainer: string;
  heightContainer: string;
  box: string;
};

const Loader = (props: LoaderProps) => {
  const {
    x,
    y,
    rx,
    ry,
    isWidth,
    isHeight,
    box,
    widthContainer,
    heightContainer,
  } = props;

  return (
    <ContentLoader
      speed={1}
      width={widthContainer}
      height={heightContainer}
      viewBox={box}
      backgroundColor="#d9d9d9"
      foregroundColor="#ededed"
      {...props}
    >
      <rect x={x} y={y} rx={rx} ry={ry} width={isWidth} height={isHeight} />
    </ContentLoader>
  );
};

Loader.metadata = {
  name: "Abraham Calsin",
  github: "abrahamcalsin",
  description: "Loading a list of tasks.",
  filename: "TaskList",
};

export default Loader;
