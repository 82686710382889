import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Checkbox,
  NotControlledToolTip,
  Spacer,
  Table,
} from "@zilliondigital/mirage-ui";
import SuccessIcon from "~/assets/icons/status/done_black_24dp (1).svg";
import PIXIcon from "~/assets/icons/Type/ic_context_payment_pix.png";
import PaidIcon from "~/assets/icons/status/ic_functional_status_success.png";
import PenIcon from "~/assets/icons/contacts/pen.png";
import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState } from "react";
import EyeIcon from "~/assets/icons/eye-Size=MD.svg";
import useCollections from "~/hooks/Collections/useCollections";
import BilletIcon from "~/assets/icons/Type/billet.png";
import { operationStatus } from "~/data/options";
import { applyPagination } from "~/components/Paginator";
import theme from "~/themes/theme";
import { formatCash, formatNumberToCurrencyWithoutSimbol } from "~/utils";
import ErrorIcon from "~/assets/icons/status/error.png";
import LeftIcon from "~/assets/icons/chevron_left.png";
import RigthIcon from "~/assets/icons/chevron_right.png";
import ExportIcon from "~/assets/relatory.png";
import TableLoader from "./TableLoader";
import { ImgEye } from "../app-content.styles";
import { Columns } from "~/types";
import Badge from "~/components/Badge";
import ApiService from "~/services/api";

const StatusData = styled.div`
  .button {
    opacity: 0;
  }

  &:hover > .button {
    .button {
      opacity: 1;
    }
  }
`;

const EditContainer = styled.div`
  position: relative;
  right: 20px;
`;

type descriptionImage = {
  [key: string]: string;
};

const DescriptionImage: descriptionImage = {
  pix: PIXIcon,
  billet: BilletIcon,
  card: PaidIcon,
};

const WithCollections = () => {
  const {
    billetsData,
    setCurrentModalData,
    setOpenDetailModal,
    page,
    setIsEditBillet,
    rowsPerPage,
    loadingList,
    setDataBillet,
    statusColumnSelected,
    setStatusColumnSelected,
    selectedBillets,
    setSelectedBillets,
    selectedIds,
    setSelectedIds,
    setOpenExportsModal,
    setAlterationsData,
  } = useCollections();
  const billets = billetsData?.results;

  const data = billets?.map((transaction: any, index: number) => ({
    ...transaction,
    delete: index,
  }));

  type GetStatusType = {
    [key: string]: JSX.Element;
  };

  const tableContainerRef = useRef<any>(null);
  const [scrollAmount, setScrollAmount] = useState(0);

  const scrollTable = (amount: number) => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft += amount;
      setScrollAmount(tableContainerRef.current.scrollLeft);
    }
  };

  const getStatus: GetStatusType = {
    processing: (
      <Badge label="Processando" color={"#FEE7CC"} textColor={"#FC8802"} />
    ),

    opened: (
      <Badge
        label="Em aberto"
        color={theme.colors.base.light200}
        textColor={theme.colors.base.main}
      />
    ),

    overdue: (
      <Badge
        label="Vencido"
        color={theme.colors.error.light200}
        textColor={theme.colors.error.main}
      />
    ),
    done: (
      <Badge
        label="Pago"
        color={"#CCEFDF"}
        textColor={"#00B360"}
        icon={SuccessIcon}
      />
    ),
    done_automatic: (
      <Badge
        label="Pago"
        color={"#CCEFDF"}
        textColor={"#00B360"}
        icon={SuccessIcon}
      />
    ),
    done_manual: (
      <Badge
        label="Baixado"
        color={"#CCEFDF"}
        textColor={"#00B360"}
        icon={SuccessIcon}
      />
    ),
    paid: (
      <Badge
        label="Pago"
        color={"#CCEFDF"}
        textColor={"#00B360"}
        icon={SuccessIcon}
      />
    ),
    canceled_automatic: (
      <Badge
        label="Decurso de prazo"
        color={theme.colors.error.light200}
        textColor={theme.colors.error.main}
      />
    ),
    canceled_manual: (
      <Badge
        label="Cancelado"
        color={theme.colors.error.light200}
        textColor={theme.colors.error.main}
      />
    ),
    canceled_canceled: (
      <Badge
        label="Cancelado"
        color={theme.colors.error.light200}
        textColor={theme.colors.error.main}
      />
    ),

    canceling: (
      <Badge
        label="Em cancelamento"
        color={theme.colors.error.light200}
        textColor={theme.colors.error.main}
      />
    ),
    error: (
      <div className="d-flex justify-content-center">
        <img src={ErrorIcon} />
      </div>
    ),
  };

  const getAlterations = async (billet: number) => {
    setAlterationsData([]);
    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/billet-instruction/",
        params: { billet },
        token: true,
      });

      setAlterationsData(data);
    } catch (e: any) {
      console.warn(e);
    }
  };

  const columns: Columns[] = [
    {
      label: "Status",
      key: "status",
      renderColumn: (data: any) => (
        <div
          className="d-flex justify-content-start align-items-center"
          onClick={(event) => {
            event.stopPropagation();
            handleStatusColumnToggle();
          }}
        >
          <Spacer left={10} />
          <div className="align-items-center d-flex">
            <Checkbox checked={statusColumnSelected} />
          </div>
          <Spacer left={20} />

          {data.label}
        </div>
      ),

      renderData: (data: any) => (
        <div
          className="d-flex justify-content-start align-items-center"
          onClick={() => {
            if (data.status !== "error") {
              handleAddRemoveId(data);
              handleToggleData(data);
              setDataBillet(data);
            }
          }}
        >
          <div
            className="align-items-center d-flex ms-1"
            style={
              data.status !== "error"
                ? { cursor: "pointer" }
                : { cursor: "not-allowed" }
            }
          >
            <Checkbox
              className="ps-1"
              checked={
                data.status !== "error" && selectedIds?.includes(data.id)
              }
              disabled={data.status === "error"}
            />
          </div>
          <div
            className="d-flex flex-grow-1 justify-content-center pe-3 ps-3"
            style={{ minWidth: "140px" }}
            id={`status-${data.id}`}
          >
            {data.status === "canceled"
              ? getStatus[`${data.status}_${data.cancel_type}`]
              : data.status === "done"
              ? getStatus[`${data.status}_${data.payment_type}`]
              : getStatus[data.status]}
            <NotControlledToolTip placement="top" target={`status-${data.id}`}>
              {data.status === "canceled"
                ? operationStatus[`${data.status}_${data.cancel_type}`]
                : data.status === "done"
                ? operationStatus[`${data.status}_${data.payment_type}`]
                : operationStatus[data.status]}
            </NotControlledToolTip>
          </div>
        </div>
      ),
    },

    {
      label: "Forma de pagamento",
      key: "payment_type",
      renderData: (data: any) => (
        <div>
          <img
            style={{ height: "15px" }}
            src={DescriptionImage["billet"]}
            className="me-2"
          />
          A Vista
        </div>
      ),
    },
    {
      label: "Contato",
      key: "contact",

      renderData: (data: any) => (
        <div style={{ minWidth: "150px" }}>{data.payer_name}</div>
      ),
    },
    {
      label: "Valor original",
      key: "amount",

      renderData: (data: any) => (
        <div className="d-flex justify-content-end ">
          {formatCash(data.amount)}
        </div>
      ),
    },
    {
      label: "Valor atualizado",
      key: "amount",

      renderData: (data: any) => (
        <div className="d-flex justify-content-end ">
          {formatCash(data.updated_amount)}
        </div>
      ),
    },
    {
      label: "Vencimento",
      key: "due_date",

      renderData: (data: any) => (
        <div className={data?.due_date ? "" : "ps-4"}>
          {formatDate(data?.due_date) ?? "—"}
        </div>
      ),
    },
    {
      label: "Valor Pago",
      key: "payment",
      renderData: (data: any) => (
        <div
          style={{ minWidth: "100px" }}
          className="d-flex justify-content-end"
        >
          R${" "}
          {formatNumberToCurrencyWithoutSimbol(Number(data?.amount_paid)) ??
            "—"}
        </div>
      ),
    },
    {
      label: "Emissão",
      key: "date",

      renderData: (data: any) => <>{formatDate(data.created_date)}</>,
    },

    {
      label: "Expiração",
      key: "deadline_date",

      renderData: (data: any) => (
        <div className={data?.deadline_date ? "" : "ps-4"}>
          {formatDate(data?.deadline_date) ?? "—"}
        </div>
      ),
    },
    {
      label: "Pagamento",
      key: "payment",
      renderData: (data: any) => (
        <div className={data?.bank_payment_date ? "" : "ps-4"}>
          {formatDate(data?.bank_payment_date) ?? "—"}
        </div>
      ),
    },
    {
        label: "Crédito",
        key: "credit",
        renderData: (data: any) => (
          <div className={data?.payment_date ? "" : "ps-4"}>
            {formatDate(data?.payment_date) ?? "—"}
          </div>
        ),
      },

    {
      label: "Identificação",
      key: "identification",
      renderData: (data: any) => <>{data.identifier}</>,
    },
    {
      label: "Seu N°",
      key: "your number",
      renderData: (data: any) => (
        <div style={{ minWidth: "100px" }}>{data.your_number ?? "—"}</div>
      ),
    },
    // {
    //   label: "Split",
    //   key: "Split",
    //   renderData: (data: any) => (
    //     <div style={{ minWidth: "50px" }}>
    //       {data?.split_book_data?.split_data.name ?? "— "}
    //     </div>
    //   ),
    // },
    {
      label: "",
      key: "",
      renderData: (data: any) => {
        return (
          <div className="d-flex">
            <div className="me-5 d-flex hover-list-button">
              <div className="d-flex justify-content-start align-items-center">
                <>
                  {" "}
                  <Spacer left={10} />
                  <div>
                    <ImgEye
                      id={`eye-${data.id}`}
                      onClick={() => {
                        getAlterations(data.id);

                        openDetail(data);
                      }}
                      src={EyeIcon}
                    />
                  </div>
                  <NotControlledToolTip
                    placement="top"
                    target={`eye-${data.id}`}
                  >
                    {"Visualizar detalhes"}
                  </NotControlledToolTip>
                </>
              </div>

              {/* <ButtonMoreDetails options={buttonMoreOptions} /> */}
            </div>

            {data.status !== "error" && data.status !== "done" && (
              <EditContainer className="me-5 d-flex hover-list-button edit-container">
                <div className="d-flex justify-content-start align-items-center">
                  <>
                    {" "}
                    <Spacer left={-3} />
                    <div>
                      <ImgEye
                        style={{ height: "16px" }}
                        id={`edit-${data.id}`}
                        onClick={() => {
                          openDetail(data);
                          setDataBillet(data);
                          setIsEditBillet(true);
                        }}
                        src={PenIcon}
                      />
                      <NotControlledToolTip
                        placement="top"
                        target={`edit-${data.id}`}
                      >
                        {"Editar"}
                      </NotControlledToolTip>
                    </div>
                    <Spacer left={10} />
                  </>
                </div>
              </EditContainer>
            )}

            {data.status !== "error" && (
              <EditContainer className="me-5 d-flex hover-list-button edit-container">
                <div className="d-flex justify-content-start align-items-center">
                  <>
                    {" "}
                    <Spacer left={data.status === "done" ? -3 : -35} />
                    <div>
                      <ImgEye
                        style={{ height: "19px" }}
                        id={`export-${data.id}`}
                        onClick={() => {
                          setSelectedIds([data.id]);
                          setOpenExportsModal(true);
                        }}
                        src={ExportIcon}
                      />
                    </div>
                  </>
                  <NotControlledToolTip
                    placement="top"
                    target={`export-${data.id}`}
                  >
                    {"Gerar relatório"}
                  </NotControlledToolTip>
                </div>
              </EditContainer>
            )}
          </div>
        );
      },
    },
  ];

  const openDetail = (data: any) => {
    setCurrentModalData(data);
    setOpenDetailModal(true);
  };

  const formatDate = (date: string) => {
    if (!date) {
      return "—";
    }

    let newDate = date;
    if (date.includes("T")) {
      newDate = date.split("T")[0];
    }

    if (newDate) {
      const [year, month, day] = newDate.split("-");
      return `${day}/${month}/${year}`;
    }
  };

  const handleAddRemoveId = (data: any) => {
    const idToAdd = data.id;

    if (idToAdd !== undefined && idToAdd !== null) {
      if (selectedIds.includes(idToAdd)) {
        setSelectedIds(
          selectedIds.filter((voucher: string) => voucher !== idToAdd)
        );
      } else {
        setSelectedIds([...selectedIds, idToAdd]);
      }
    }
  };

  const handleToggleData = (data: any) => {
    const dataIndex = selectedBillets.findIndex(
      (item: any) => item.id === data.id
    );

    if (dataIndex !== -1) {
      setSelectedBillets(
        selectedBillets.filter((_: any, index: number) => index !== dataIndex)
      );
    } else {
      setSelectedBillets([...selectedBillets, data]);
    }
  };

  useEffect(() => {
    const filteredBillets = data?.filter((billet: any) =>
      selectedIds.includes(billet.id)
    );

    setSelectedBillets(filteredBillets);
  }, [selectedIds]);

  const handleStatusColumnToggle = () => {
    const transactions = applyPagination(data, { page, rowsPerPage });

    const selectedVouchersForStatusColumn = statusColumnSelected
      ? []
      : transactions
          .filter(
            (transaction) =>
              transaction.id !== null &&
              transaction.id !== undefined &&
              transaction.status !== "error"
          )
          .map((transaction) => transaction.id);

    setSelectedIds(selectedVouchersForStatusColumn);
    setStatusColumnSelected(!statusColumnSelected);
  };
  const handleCancelAllSelections = () => {
    setSelectedIds([]);
    setStatusColumnSelected(false);
  };

  useEffect(() => {
    setSelectedIds([]);
  }, []);

  return (
    <>
      {loadingList ? (
        <TableLoader />
      ) : (
        billets?.length > 0 && (
          <div className="d-flex  flex-column">
            {selectedBillets?.length > 0 && (
              <div
                style={{
                  background: theme.colors.primary.main,
                  borderRadius: "4px",
                  color: "white",
                }}
                className="d-flex w-100  flex-column align-content-end ps-3 pe-3 pt-3
              "
              >
                <div className="d-flex justify-content-between">
                  <p>
                    {selectedBillets?.length > 1
                      ? `${selectedBillets?.length} itens selecionados`
                      : `${selectedBillets?.length} item selecionado`}{" "}
                  </p>
                  <p
                    onClick={() => handleCancelAllSelections()}
                    style={{ cursor: "pointer" }}
                  >
                    Cancelar
                  </p>
                </div>
              </div>
            )}
            <Spacer bottom={20} />
            <div className="d-flex justify-content-between">
              <div>
                <ImgEye
                  id="arrow-left"
                  src={LeftIcon}
                  onClick={() => scrollTable(-200)}
                  style={{ cursor: "pointer" }}
                />
                <NotControlledToolTip placement="top" target={`arrow-left`}>
                  {"Mover para esquerda"}
                </NotControlledToolTip>
              </div>{" "}
              <div>
                <ImgEye
                  id="arrow-right"
                  src={RigthIcon}
                  onClick={() => scrollTable(200)}
                  style={{ cursor: "pointer" }}
                />
                <NotControlledToolTip placement="top" target={`arrow-right`}>
                  {"Mover para direita"}
                </NotControlledToolTip>
              </div>
            </div>
            <div
              style={{
                overflow: "auto",
                position: "relative",
                scrollBehavior: "smooth",
                boxShadow: "inset 5px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
              ref={tableContainerRef}
            >
              {" "}
              <Table
                data={data ? applyPagination(data, { page, rowsPerPage }) : []}
                columns={columns}
                dataSpacer={8}
              />
            </div>
          </div>
        )
      )}
    </>
  );
};

export default WithCollections;
