import { Spacer, Tooltip } from "@zilliondigital/mirage-ui";
import { useState } from "react";
import styled from "styled-components";
import XIcon from "~/assets/icons/buttons/x.png";
import MoreIcon from "~/assets/icons/buttons/more-icon.png";
import theme from "~/themes/theme";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  cursor: default;
  justify-content: end;
`;

const Button = styled.button`
  border: none;
  background-color: #f2f7fb;
  margin-right: 12px;
  height: 40px;
  padding: 0 12px;
  border-radius: 8px;

  .button-remove,
  .button-add {
    position: relative;

    .container-tooltip {
      position: absolute;
      left: 0;
      transform: translate(-27px, -32px);
    }
  }

  cursor: default;
  &:hover {
    cursor: default;
  }

  .label {
    font-size: 12px;
    color: #929292;
    cursor: default;
  }
`;
type Filters = {
  label: string;
  onClick?: () => void;
};

const ImgX = styled.img`
  filter: brightness(0) saturate(100%) invert(21%) sepia(5%) saturate(23%)
    hue-rotate(322deg) brightness(100%) contrast(94%);
  cursor: pointer;
  position: relative;

  &:hover {
    filter: brightness(0) saturate(100%) invert(49%) sepia(71%) saturate(2440%)
      hue-rotate(330deg) brightness(89%) contrast(102%);
  }
`;

const ImgMore = styled.img`
  filter: brightness(0) saturate(100%) invert(21%) sepia(5%) saturate(23%)
    hue-rotate(322deg) brightness(100%) contrast(94%);
`;

type ButtonFiltersProps = {
  filters: Filters[];
  onClickMore?: () => void;
};

const ButtonFilters = (props: ButtonFiltersProps) => {
  const { filters = [], onClickMore } = props;

  const [iconHoverStates, setIconHoverStates] = useState<{
    [key: number]: boolean;
  }>({});

  const [iconMoreHover, setIconMoreHover] = useState<boolean>(false);

  return (
    <Container {...props}>
      {filters?.map((button: any, index: number) => {
        return (
          <>
            <Button className="d-flex align-items-center mb-2">
              <span className="label">{button.label}</span>
              <Spacer left={15} />
              <div className="button-remove">
                <div className="container-tooltip">
                  <Tooltip
                    tooltipColor="#E65244"
                    tooltipLabel="Remover"
                    visible={iconHoverStates[index]}
                  />
                </div>
                <ImgX
                  src={XIcon}
                  onClick={button.onClick}
                  onMouseEnter={() =>
                    setIconHoverStates((prevState) => ({
                      ...prevState,
                      [index]: true,
                    }))
                  }
                  onMouseLeave={() =>
                    setIconHoverStates((prevState) => ({
                      ...prevState,
                      [index]: false,
                    }))
                  }
                />
              </div>
            </Button>
          </>
        );
      })}
      <Button
        className="mb-2"
        onMouseEnter={() => setIconMoreHover(true)}
        onMouseLeave={() => setIconMoreHover(false)}
        onClick={onClickMore}
        style={{ cursor: "pointer" }}
      >
        <div className="button-add">
          <div className="container-tooltip">
            {" "}
            <Tooltip
              tooltipColor={theme.colors.info.light300}
              tooltipLabel="Adicionar"
              visible={iconMoreHover}
            />
          </div>

          <div>
            <ImgMore
              src={MoreIcon}
              style={
                iconMoreHover
                  ? {
                      filter:
                        "brightness(0) saturate(100%) invert(71%) sepia(83%) saturate(1195%)hue-rotate(167deg) brightness(95%) contrast(86%)",
                    }
                  : {}
              }
            />
          </div>
        </div>
      </Button>
    </Container>
  );
};
export default ButtonFilters;
