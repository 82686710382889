import constate from "constate";
import { useState } from "react";
import { subDays } from "date-fns";
import ApiService from "~/services/api";
import { getToday, payloadDatesToParam } from "~/utils";
import { ParsedBillet } from "~/types";
import { isWithinInterval, addDays, parseISO } from "date-fns";
import { render } from "react-dom";

const initialFilterParams = {
  from_date: getToday(),
  to_date: getToday(),
};

const [CollectionsProvider, useCollections] = constate(() => {
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [filteredCollections, setFilteredCollections] = useState<any[]>([]);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [isBaixa, setIsBaixa] = useState<boolean>(false);
  const [paramsFilterCollections, setParamsFilterCollections] = useState({});
  const [labelBadgesFilterCollections, setLabelBadgessFilterCollections] =
    useState<any>({});
  const [renderInitialContent, setRenderInitialContent] = useState<boolean>();
  const [openExportsModal, setOpenExportsModal] = useState<boolean>(false);
  const [alterationsData, setAlterationsData] = useState<any>([]);
  const [
    openNewCollectionOrRecurrenceModal,
    setOpenNewCollectionOrRecurrenceModal,
  ] = useState<boolean>(false);

  const [
    openNewCollectionOrRecurrenceModalInDash,
    setOpenNewCollectionOrRecurrenceModalInDash,
  ] = useState<boolean>(false);

  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);

  const [currentModalData, setCurrentModalData] = useState<any>();

  const [billetsData, setBilletsData] = useState<any>([]);

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(0);
  const [taxBilletLot, setTaxBilletLot] = useState<number>(0);

  const [filterParams, setFilterParams] = useState<any>(initialFilterParams);
  const [loadingList, setLoadingList] = useState<boolean>(false);

  const [cameToCollectionsOrTransfers, setCameToCollectionsOrTransfers] =
    useState<boolean>(false);

  const [cnabOperations, setCnabOperations] = useState<any[]>([]);
  const [cnabOperationsLoading, setCnabOperationsLoading] =
    useState<boolean>(false);
  const [daysAfter, setDaysAfter] = useState<number>(0);

  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [selectedBillets, setSelectedBillets] = useState<any>();
  const [statusColumnSelected, setStatusColumnSelected] = useState<boolean>();

  const [bank, setBank] = useState<string>("");
  const [layout, setLayout] = useState<string>("");
  const [file, setFile] = useState<Blob | string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [details, setDetails] = useState<ParsedBillet[]>([]);
  const [operation, setOperation] = useState<number>();
  const [sending, setSending] = useState<boolean>(false);
  const [openNewCnabModal, setOpenNewCnabModal] = useState<boolean>(false);
  const [futureReceivements, setFutureReceivements] = useState<number>(0);

  const [isFilteredResults, setIsFilteredResults] = useState<boolean>(false);
  const [isEditBillet, setIsEditBillet] = useState<boolean>(false);

  const [openEditInGroup, setOpenEditInGroup] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const [dataBillet, setDataBillet] = useState<any>({});

  const setFetchParams = (updatedParams = {}) => {
    setFilterParams((prevFilters: any) => ({
      ...prevFilters,
      ...updatedParams,
    }));
  };

  const getBillets = async (fastParams = {}) => {
    setBilletsData([]);
    setLoadingList(true);

    const params = payloadDatesToParam({
      ...filterParams,
      ...fastParams,
    });

    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/billet/",
        token: true,
        params: {
          ...params,
          limit: 9999999,
        },
      });

      setBilletsData(data);
      setLoadingList(false);
      setSelectedIds([]);
      setStatusColumnSelected(false);
    } catch {}
    setLoadingList(false);
  };

  const getBilletsProjections = async (from_date: string, to_date: string) => {
    try {
      const { data } = await ApiService.HttpGet({
        route: "charge/billet/",
        params: {
          filterby: "deadline_date",
          from_date: from_date,
          to_date: to_date,
        },
        token: true,
      });

      setFutureReceivements(data?.sum?.total_waiting_for_payment_amount);
    } catch {}
  };

  const fetchCnabOperations = async () => {
    setCnabOperationsLoading(true);

    try {
      const {
        data: { data },
      } = await ApiService.HttpGet({
        route: "cnab/operation/",
        token: true,
      });

      setCnabOperations(data);
    } catch (error) {
      console.error(error);
    }
    setCnabOperationsLoading(false);
  };

  return {
    filteredCollections,
    setFilteredCollections,
    openFilterModal,
    setOpenFilterModal,
    openNewCollectionOrRecurrenceModal,
    setOpenNewCollectionOrRecurrenceModal,
    billetsData,
    setBilletsData,
    openNewCollectionOrRecurrenceModalInDash,
    setOpenNewCollectionOrRecurrenceModalInDash,
    openDetailModal,
    setOpenDetailModal,
    currentModalData,
    setCurrentModalData,
    dataBillet,
    setDataBillet,
    setRenderInitialContent,
    renderInitialContent,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    selectedValue,
    setSelectedValue,
    openConfirmModal,
    setOpenConfirmModal,
    filterParams,
    setFetchParams,
    loadingList,
    setLoadingList,
    getBillets,
    cameToCollectionsOrTransfers,
    setCameToCollectionsOrTransfers,

    cnabOperationsLoading,
    setCnabOperationsLoading,
    fetchCnabOperations,
    cnabOperations,
    setCnabOperations,

    isEditBillet,
    setIsEditBillet,
    showContent,
    setShowContent,

    bank,
    setBank,
    layout,
    setLayout,
    file,
    setFile,
    submitting,
    setSubmitting,
    details,
    setDetails,
    operation,
    setOperation,
    sending,
    setSending,
    openNewCnabModal,
    setOpenNewCnabModal,
    isBaixa,
    setIsBaixa,
    getBilletsProjections,
    setFutureReceivements,
    futureReceivements,
    taxBilletLot,
    setTaxBilletLot,

    paramsFilterCollections,
    setParamsFilterCollections,

    isFilteredResults,
    setIsFilteredResults,

    setSelectedBillets,
    selectedBillets,
    selectedIds,
    setSelectedIds,
    statusColumnSelected,
    setStatusColumnSelected,
    openEditInGroup,
    setOpenEditInGroup,
    openExportsModal,
    setOpenExportsModal,
    alterationsData,
    setAlterationsData,
    labelBadgesFilterCollections,
    setLabelBadgessFilterCollections,
  };
});

export { CollectionsProvider };

export default useCollections;
