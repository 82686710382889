import { Fragment, useEffect } from "react";
import Title from "~/components/Texts/Title";
import { ActivateCardCheckingButtonsDiv, ActivateCardCheckingImg, ActivateCardCheckingStepDiv, ActivateCardTitle, ActivateCardContainer, CardsDashboardHeader } from "./styled";
import useAccount from "~/hooks/useAccount";
import useCards from "~/hooks/Cards/useCards";
import { Button } from "@zilliondigital/mirage-ui";
import { useNavigate } from "react-router-dom";
import CardImg from '~/assets/images/credit--card.svg'
import { UnblockCardSteps } from "~/hooks/Cards/interfaces";
import { ContainerData } from "../../app-content.styles";
import UnblockCardCheckingStep from "./section/UnblockCardCheckingStep";
import UnblockCardConfirmationStep from "./section/UnblockCardConfirmationStep";
import UnblockCardUnblockedStep from "./section/UnblockCardUnblockedStep";


const MyCardsUnblockContainer = (props: {}) => {
  const navigate = useNavigate()
  const {
    selectedCard,
    setSubPage,
    unblockCardStep,
    setUnblockCardStep,
    setUnblockingCardStatus,
  } = useCards()
  
  useEffect(() => {
    setSubPage({name: 'Desbloquear', route: '/app/cards/unblock'})
    
    if(selectedCard?.status != 'BLOCKED'){
      navigate('/app/cards')
    }else{
      setUnblockCardStep('CHECKING')
      setUnblockingCardStatus(null)
    }
  }, [])

  const _returnStepComponent = (step: UnblockCardSteps) => {
    if(!selectedCard){
      return <></>
    }
    switch (step){
      case 'CHECKING':
        return (
          <UnblockCardCheckingStep 
            unblockCardStep={unblockCardStep}
            setUnblockCardStep={setUnblockCardStep}
          />
        )
      
      case 'CONFIRMATION':
        return (
          <UnblockCardConfirmationStep 
            unblockCardStep={unblockCardStep}
            setUnblockCardStep={setUnblockCardStep}
          />
        )
      
      case 'BLOCKED':
        return (
          <UnblockCardUnblockedStep />
        )
    }
  }

  return (
    <Fragment>
      <ContainerData className="container">
        <CardsDashboardHeader>
          <Title>Bloquear meu cartão</Title>
        </CardsDashboardHeader>
        <div className="col-md-8 w-100 d-flex flex-column px-4">
          <ActivateCardContainer>
            {_returnStepComponent(unblockCardStep)}
          </ActivateCardContainer>
        </div>
      </ContainerData>
    </Fragment>
  );
};

export default MyCardsUnblockContainer;
 