import { useCallback, useEffect, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import AddIcon from "~/assets/icons/adicionar.png";
import PenIcon from "~/assets/icons/contacts/pencil.png";
import {
  Checkbox,
  Input,
  CustomSelect,
  Spacer,
  Button,
  InputCurrency,
  Table,
  Toggle,
  NotControlledToolTip,
  InteractiveModal,
} from "@zilliondigital/mirage-ui";

import "bootstrap/dist/css/bootstrap.min.css";
import { REQUIRED_LABEL } from "~/services/validators";
import { genderOptions, maritalStatusOptions, ufOptions } from "~/data/options";
import useSignupCompany, {
  CompanyRepresentativeValues,
} from "~/hooks/Signup/useSignupCompany";
import { Columns, TableColumn } from "~/types";
import Description from "~/components/Texts/Description";
import useToast from "~/hooks/useToast";
import ApiService from "~/services/api";

import {
  CustomSelectValue,
  generateCPF,
  generateCellphone,
  generateRG,
  validateEmail,
  validatePhoneNumber,
} from "~/services/utils";
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";
import useCEP from "~/hooks/useCEP";
import theme from "~/themes/theme";
import {
  formatCPFCNPJ,
  onlyNumbers,
  verifyCompleteName,
  verifyIfPayloadIsComplete,
} from "~/utils";

import trashIcon from "~/assets/icons/delete.png";
import AppStorage from "~/services/storage";
import { ImgPen } from "~/pages/App/Contacts/WithContacts";
import documentNumberValidator from "~/utils/validateDocNumber";
import { TitleModal } from "~/pages/Authentication/Auth.styles";
import { add } from "date-fns";

const Title = styled.span`
  font-size: 32px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
   
    background-color: initial;
  }

  100% {
    transform: scale(1.2);

  }
`;

const ImgDelete = styled.img`
  height: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  transform-origin: center;
  opacity: 0.7;

  &:hover {
    animation: ${scaleAnimation} 0.1s ease-in-out forwards;
    filter: ${() => theme.colors.error.mainFilter};
  }
`;

const initialValues = {
  document_type: "cpf",
  document_number: "",
  mobile_phone: "",
  name: "",
  email: "",
  identity_number: "",
  mothers_name: "",
  gender: null,
  marital_status: null,
  birth_state: null,
  birth_date: "",
  nationality: "",
  zipcode: "",
  state: "",
  city: "",
  district: "",
  street: "",
  number: "",
  complement: "",
  signatory: false,
};

const FormSchema = Yup.object().shape({});

const Representatives = () => {
  const {
    setStep,
    companyRepresentativeValues,
    setCompanyRepresentativeValues,
    setCanAdvance,
    setSending,
  } = useSignupCompany();
  const { handleCep, address, setAddress } = useCEP();
  const [addRepresentative, setAddRepresentative] = useState<boolean>(false);
  const [canAddRepresentative, setCanAddRepresentative] =
    useState<boolean>(false);
  const [send, setSend] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataRepresentativeToDelete, setDataRepresentativeToDelete] =
    useState<any>({});
  const [error, setError] = useState<string>("");
  const [isNotOld, setIsNotOld] = useState<boolean>(false);
  const [representativesData, setRepresentativesData] = useState<any>([]);
  const [namedRepresentativeEditing, setNamedRepresentativeEditing] =
    useState<string>("");
  const [idRepresentativeToEdit, setIdRepresentativeToEdit] =
    useState<number>(-1);
  const [openDeleteRepresentativeModal, setOpenDeleteRepresentativeModal] =
    useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const handleFormSubmit = async (values: CompanyRepresentativeValues) => {};

  const {
    submitForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: companyRepresentativeValues,
    validationSchema: FormSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  const registeredToken = useMemo(() => AppStorage.getRegisteredToken(), []);

  const fillForm = () => {
    const fakeValues: CompanyRepresentativeValues = {
      document_type: "cpf",
      document_number: formatCPFCNPJ(generateCPF()),
      mobile_phone: generateCellphone(true),
      name: faker.name.fullName({
        gender: "male",
      }),
      email: faker.internet.email(),
      identity_number: generateRG(),
      mothers_name: faker.name.fullName({
        gender: "female",
      }),
      gender: "female",
      marital_status: "0",
      nationality: "Brasileira",
      birth_state: "SP",
      birth_date: faker.date.past().toISOString().split("T")[0],

      zipcode: faker.address.zipCode("#####-###"),
      state: "SP",
      city: faker.address.city(),
      district: faker.address.city(),
      street: faker.address.streetName(),
      number: String(faker.datatype.number()),
      complement: faker.address.streetName(),

      signatory: faker.datatype.boolean(),
    };

    setCompanyRepresentativeValues(fakeValues);
  };

  const columns: Columns[] = [
    {
      label: "Nome Completo",
      key: "name",
      renderData: (data: CompanyRepresentativeValues) => (
        <div className="d-flex flex-column">
          <span>{data.name}</span>
          <Description color={theme.colors.base.dark100}>
            {formatCPFCNPJ(data.document_number)}
          </Description>
        </div>
      ),
    },

    {
      label: "Signatário",
      key: "signatory",
      renderData: (data: CompanyRepresentativeValues) => (
        <span>{data.signatory ? "Sim" : "Não"}</span>
      ),
    },
    {
      label: "",
      key: "delete",
      renderData: (data: any) => (
        <div className="d-flex ">
          <div
            className="d-flex justify-content-start align-items-center"
            id={`edit-${data.id}`}
          >
            <>
              {" "}
              <Spacer left={10} />
              <div>
                <ImgPen
                  style={{ height: "25px" }}
                  src={PenIcon}
                  onClick={() => {
                    getInfoRepresentative(data);
                    setNamedRepresentativeEditing(data.name);
                    setAddRepresentative(false);
                    setIsEditing(true);
                  }}
                />
              </div>
            </>
            <NotControlledToolTip placement="top" target={`edit-${data.id}`}>
              {"Editar dados"}
            </NotControlledToolTip>
          </div>

          <Spacer left={10} />

          <div
            className="d-flex justify-content-start align-items-center"
            id={`delete-${data.id}`}
          >
            <>
              {" "}
              <Spacer left={10} />
              <div>
                <ImgDelete
                  style={{ width: "22px" }}
                  src={trashIcon}
                  onClick={() => {
                    setDataRepresentativeToDelete(data);
                    setOpenDeleteRepresentativeModal(true);
                    setCompanyRepresentativeValues(initialValues);
                    setAddRepresentative(true);
                    setIsEditing(false);
                  }}
                />
              </div>
            </>
            <NotControlledToolTip placement="top" target={`delete-${data.id}`}>
              {"Excluir representante"}
            </NotControlledToolTip>
          </div>
        </div>
      ),
    },
  ];

  const isValidDocumentNumber = useMemo(() => {
    return (
      values.document_number.length > 13 &&
      documentNumberValidator(values.document_number)
    );
  }, [values.document_number]);

  const hasSignatory = useMemo(
    () =>
      representativesData?.some(
        (representative: any) => representative.signatory === true
      ),
    [representativesData]
  );

  const handleCloseModal = useCallback(() => {
    setOpenDeleteRepresentativeModal(false);
  }, []);

  const getRepresentatives = useCallback(async () => {
    try {
      const getRepresentatives = await ApiService.HttpGet({
        route: "partner-representatives/",
        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });

      setRepresentativesData(getRepresentatives.data);
    } catch (e) {
      console.warn(e);
    }
  }, []);

  const getInfoRepresentative = useCallback(
    async (data: any) => {
      try {
        const { data: addressData } = await ApiService.HttpGet({
          route: "address/",
          params: {
            partner: data.id,
          },
          customHeaders: {
            Authorization: `Token ${registeredToken}`,
          },
        });

        const latestAddress =
          addressData.results[addressData.results.length - 1];

        const address = {
          zip_code: latestAddress.zipcode,
          street: latestAddress.street,
          district: latestAddress.district,
          city: latestAddress.city,
          state: latestAddress.state,
          number: latestAddress.number,
          complement: latestAddress.complement,
        };

        setIdRepresentativeToEdit(data.id);
        setAddress(address);
        getRepresentatives();

        const representativeValues = {
          document_type: "cpf",
          document_number: data.document_number,
          mobile_phone: data.mobile_phone,
          name: data.name,
          email: data.email,
          identity_number: data.identity_number,
          mothers_name: data.mothers_name,
          gender: data.gender,
          marital_status: data.marital_status,
          nationality: data.nationality,
          birth_state: data.birth_state,
          birth_date: data.birth_date,
          signatory: data.signatory,
          ...address,
        };

        setCompanyRepresentativeValues(representativeValues);
      } catch (error) {
        showToast("Erro", "Erro ao puxar o endereço.", "error");
      }
    },
    [
      setIdRepresentativeToEdit,
      setAddress,
      setCompanyRepresentativeValues,
      getRepresentatives,
    ]
  );

  const fetchSendRepresentative = useCallback(async () => {
    setSend(false);
    setIsLoading(true);
    const payloadAddRepresentative = {
      document_type: "cpf",
      document_number: onlyNumbers(values.document_number),
      mobile_phone: values.mobile_phone,
      name: values.name,
      email: values.email,
      identity_number: values.identity_number,
      mothers_name: values.mothers_name,
      gender: values.gender,
      marital_status: values.marital_status,
      nationality: values.nationality,
      birth_state: values.birth_state,
      birth_date: values.birth_date,
      signatory: values.signatory,
    };

    try {
      const { data } = await ApiService.HttpPost({
        route: "partner-representatives/",
        body: payloadAddRepresentative,
        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });
      setAddRepresentative(true);
      resetForm();

      setAddress({
        zip_code: "",
        street: "",
        district: "",
        city: "",
        state: "",
        error: "",
      });

      const payloadAddress = {
        zipcode: values.zipcode,
        state: values.state,
        city: values.city,
        district: values.district,
        street: values.street,
        number: values.number,
        complement: values.complement,
        country: 1,
        partner: data.data.id,
        action: "add",
      };

      await ApiService.HttpPost({
        route: `address/`,
        body: payloadAddress,
        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });

      await getRepresentatives();

      setCompanyRepresentativeValues(initialValues);
      showToast("Sucesso!", "Representante adicionado com sucesso.", "success");

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToast(
        "Erro",
        "Erro ao adicionar representante, contate o suporte.",
        "error"
      );
    }
  }, [
    values,
    setSend,
    setIsLoading,
    resetForm,
    setAddress,
    getRepresentatives,
    setCompanyRepresentativeValues,
    initialValues,
  ]);

  const fetchEditRepresentativeData = useCallback(async () => {
    setSend(false);
    setIsLoading(true);

    const payloadAddRepresentative = {
      document_type: "cpf",
      document_number: onlyNumbers(values.document_number),
      mobile_phone: values.mobile_phone,
      name: values.name,
      email: values.email,
      identity_number: values.identity_number,
      mothers_name: values.mothers_name,
      gender: values.gender,
      marital_status: values.marital_status,
      nationality: values.nationality,
      birth_state: values.birth_state,
      birth_date: values.birth_date,
      signatory: values.signatory,
    };

    setAddRepresentative(true);

    try {
      const { data } = await ApiService.HttpPut({
        route: `partner-representatives/${idRepresentativeToEdit}/`,
        body: payloadAddRepresentative,
        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });

      resetForm();

      const dataAddressGet = await ApiService.HttpGet({
        route: `address/`,
        params: {
          partner: data.data.id,
        },
        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });

      const payloadAddress = {
        zipcode: values.zipcode,
        state: values.state,
        city: values.city,
        district: values.district,
        street: values.street,
        number: values.number,
        complement: values.complement,
        country: 1,
        partner: data.data.id,
        action: "add",
      };

      await ApiService.HttpPut({
        route: `address/${dataAddressGet?.data.results[0].id}/`,
        body: payloadAddress,
        customHeaders: {
          Authorization: `Token ${registeredToken}`,
        },
      });

      await getRepresentatives();

      setIsEditing(false);
      setIsLoading(false);

      showToast("Sucesso!", "Dados atualizados com sucesso.", "success");

      setCompanyRepresentativeValues(initialValues);
    } catch (error) {
      setIsLoading(false);
      showToast(
        "Erro",
        "Erro ao adicionar representante, contate o suporte.",
        "error"
      );
    }
  }, [
    idRepresentativeToEdit,
    values,
    setSend,
    setIsLoading,
    setAddRepresentative,
    resetForm,
    initialValues,
    setCompanyRepresentativeValues,
    getRepresentatives,
  ]);

  const deleteRepresentative = useCallback(
    async (id: number) => {
      setLoadingDelete(true);

      try {
        const { data } = await ApiService.HttpDelete({
          route: `partner-representatives/${id}/`,
          customHeaders: {
            Authorization: `Token ${registeredToken}`,
          },
        });

        await getRepresentatives();
        showToast("Representante excluído com sucesso!", "", "success");
        setOpenDeleteRepresentativeModal(false);
        setLoadingDelete(false);
      } catch (error) {
        console.warn(error);
        showToast("Não foi possível excluir o representante!", "", "error");
        setLoadingDelete(false);
      }
    },
    [getRepresentatives, setLoadingDelete, setOpenDeleteRepresentativeModal]
  );

  useEffect(() => {
    window.addEventListener("fillForm", fillForm);

    setIsEditing(false);

    setStep("representatives");
    setSending(false);

    setAddress({
      zip_code: "",
      street: "",
      district: "",
      city: "",
      state: "",
      error: "",
    });

    setCanAdvance(false);

    getRepresentatives();

    return () => {
      window.removeEventListener("fillForm", fillForm);
    };
  }, []);

  useEffect(() => {
    if (address) {
      setFieldValue("street", address.street);
      setFieldValue("district", address.district);
      setFieldValue("city", address.city);
      setFieldValue("state", address.state);
      setFieldValue("zipcode", address.zip_code);
    }
  }, [address]);

  useEffect(() => {
    if (verifyIfPayloadIsComplete(values, "complement")) {
      setCanAddRepresentative(true);
    } else {
      setCanAddRepresentative(false);
    }
  }, [values]);

  useEffect(() => {
    if (values.document_number.length <= 13 && !isValidDocumentNumber) {
      setError("initialState");
    } else if (values.document_number.length >= 13 && isValidDocumentNumber) {
      setError("notError");
    } else if (values.document_number.length > 13 && !isValidDocumentNumber) {
      setError("Documento inválido");
    }
  }, [values.document_number]);

  useEffect(() => {
    const currentDate = new Date();
    const selectedDate = new Date(values.birth_date);

    if (values.birth_date) {
      const age = currentDate.getFullYear() - selectedDate.getFullYear();
      if (age < 18) {
        setIsNotOld(true);
      } else {
        setIsNotOld(false);
      }
    }
  }, [values.birth_date]);

  useEffect(() => {
    if (hasSignatory) {
      setCanAdvance(true);
    } else if (!hasSignatory) {
      setCanAdvance(false);
    }
  }, [representativesData]);

  return (
    <div className="row" style={{ width: "100%" }}>
      <div style={{ maxWidth: "814px" }}>
        <Spacer bottom={50} />
        <Title>Representantes</Title>
      </div>
      <Spacer bottom={30} />
      <div className="row">
        <div className={!addRepresentative ? "col-8" : "col-12"}>
          <div className="row" style={{ width: "100%" }}>
            {representativesData.length > 0 && (
              <Table columns={columns} data={representativesData} />
            )}
          </div>
        </div>
      </div>

      <div className="pt-4" style={{ width: "50%" }}>
        <Button
          mode="border"
          label={
            representativesData?.length > 0
              ? "Adicionar outro representante"
              : "Adicionar representante"
          }
          onClick={() => {
            setAddRepresentative(false);
          }}
          disabled={isEditing}
        />
      </div>
      <Spacer bottom={20} />
      <div
        className="row pt-2"
        style={
          addRepresentative
            ? { display: "none", opacity: "0", pointerEvents: "none" }
            : { display: "block", opacity: "1", pointerEvents: "all" }
        }
      >
        <div className="col-12 col-xxl-8 ">
          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="row d-flex flex-row align-items-end mt-3">
                <div className="col-12 d-flex justify-content-between">
                  <div style={{ marginLeft: "-20px" }}>
                    {!addRepresentative && (
                      <Button
                        mode="phantom"
                        label="Cancelar"
                        onClick={() => {
                          resetForm();
                          setAddress({
                            zip_code: "",
                            street: "",
                            district: "",
                            city: "",
                            state: "",
                            error: "",
                          });
                          setCompanyRepresentativeValues(initialValues);
                          setAddRepresentative(true);
                          setIsEditing(false);
                        }}
                      />
                    )}
                  </div>

                  <Button
                    type="button"
                    onClick={() =>
                      isEditing
                        ? fetchEditRepresentativeData()
                        : fetchSendRepresentative()
                    }
                    label={
                      isEditing ? "Concluir edição" : "Salvar representante"
                    }
                    disabled={!canAddRepresentative || isNotOld}
                    loading={isSubmitting}
                    mode={"main"}
                  />
                </div>
              </div>
              <Spacer bottom={40} />

              {isEditing && (
                <div>
                  <p>
                    Você está editando os dados de{" "}
                    <b> {namedRepresentativeEditing}</b>
                  </p>
                  <Spacer bottom={20} />
                </div>
              )}

              <div className="row d-flex flex-row align-items-end">
                <h5>Dados gerais</h5>
                <Spacer bottom={10} />

                <div className="col-6">
                  <Input
                    type="text"
                    mask="999.999.999-99"
                    name="document_number"
                    label="CPF"
                    placeholder="123.456.789-09"
                    onChange={handleChange("document_number")}
                    value={values.document_number}
                    error={
                      values.document_number.length > 13 &&
                      !isValidDocumentNumber &&
                      error
                    }
                    required={error !== "notError"}
                    success={error === "notError"}
                  />
                </div>

                <div className="col-6 ">
                  <Input
                    type="text"
                    value={values.identity_number}
                    label="RG"
                    name="identity_number"
                    success={values.identity_number.length > 7}
                    placeholder="123456789"
                    error={touched.identity_number && errors.identity_number}
                    required={values.identity_number.length <= 7}
                    maxLength={10}
                    onChange={handleChange("identity_number")}
                  />
                </div>

                <div className="col-12 col-xl-6 ">
                  <Input
                    type="text"
                    name="name"
                    value={values.name}
                    required={!verifyCompleteName(values.name)}
                    label="Nome Completo"
                    placeholder="João das Neves"
                    error={touched.name && errors.name}
                    success={verifyCompleteName(values.name)}
                    onChange={handleChange("name")}
                  />
                </div>

                <div className="col-12 col-xl-6">
                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    required={!validateEmail(values.email)}
                    value={values.email}
                    placeholder="Jhon.doe@mail.com"
                    error={touched.email && errors.email}
                    success={validateEmail(values.email)}
                    onChange={handleChange("email")}
                    autoComplete="email"
                  />
                </div>

                <div className="col-6">
                  <Input
                    type="date"
                    name="birth_date"
                    id="birth_date"
                    value={values.birth_date}
                    required={!values.birth_date || isNotOld}
                    label="Data de nascimento"
                    error={
                      values.birth_date !== "" &&
                      isNotOld &&
                      "Você precisa ser maior de 18 anos"
                    }
                    success={values.birth_date !== "" && !isNotOld}
                    onChange={(value) => {
                      setFieldValue("birth_date", value.target.value);
                    }}
                  />
                </div>

                <div className="col-6">
                  <Input
                    mask="(99) 99999-9999"
                    type="phone"
                    name="mobile_phone"
                    label="Número de Celular"
                    placeholder="(11) 91111-1111"
                    onChange={handleChange("mobile_phone")}
                    value={values.mobile_phone}
                    error={touched.mobile_phone && errors.mobile_phone}
                    required={!validatePhoneNumber(values.mobile_phone)}
                    success={validatePhoneNumber(values.mobile_phone)}
                    autoComplete="phone"
                  />
                </div>

                <div className="col-6">
                  <Input
                    type="text"
                    name="mothers_name"
                    value={values.mothers_name}
                    required={!verifyCompleteName(values.mothers_name)}
                    label="Nome Completo da mãe"
                    placeholder="Joana da Silva"
                    error={touched.mothers_name && errors.mothers_name}
                    success={verifyCompleteName(values.mothers_name)}
                    onChange={handleChange("mothers_name")}
                  />
                </div>

                <div className="col-6">
                  <CustomSelect
                    required={values.gender === ""}
                    label="Gênero"
                    isSearchable={false}
                    name="gender"
                    value={CustomSelectValue(values.gender, genderOptions)}
                    placeholder="Selecione"
                    options={genderOptions}
                    onChange={(value) => {
                      setFieldValue("gender", value.target.value);
                    }}
                  />
                </div>

                <div className="col-6">
                  <CustomSelect
                    required={values.marital_status === ""}
                    isSearchable={false}
                    label="Estado civil"
                    name="marital_status"
                    value={CustomSelectValue(
                      values.marital_status,
                      maritalStatusOptions
                    )}
                    placeholder="Selecione"
                    options={maritalStatusOptions}
                    onChange={(value) => {
                      setFieldValue("marital_status", value.target.value);
                    }}
                  />
                </div>

                <div className="col-6">
                  <Input
                    type="text"
                    name="nationality"
                    value={values.nationality}
                    required={values.nationality.length <= 3}
                    label="Nacionalidade"
                    placeholder="Brasileira"
                    error={touched.nationality && errors.nationality}
                    success={values.nationality.length > 3}
                    onChange={handleChange("nationality")}
                  />
                </div>

                <div className="col-6">
                  <CustomSelect
                    required={values.birth_state === ""}
                    isSearchable={false}
                    label="Estado de nascimento"
                    name="birth_state"
                    value={CustomSelectValue(values.birth_state, ufOptions)}
                    placeholder="Selecione"
                    options={ufOptions}
                    onChange={(value) => {
                      setFieldValue("birth_state", value.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row d-flex flex-row align-items-end">
                <div className="col-12 mt-3">
                  <h5>Endereço</h5>
                  <Spacer bottom={10} />
                </div>

                <div className="col-xl-4 col-12 ">
                  <Input
                    mask="99999-999"
                    type="text"
                    name="zipcode"
                    label="CEP"
                    placeholder="12345-678"
                    required={address.zip_code === ""}
                    value={values.zipcode}
                    onKeyUp={(e: any) => {
                      handleCep(e);
                    }}
                    onChange={handleChange("zipcode")}
                    error={address.error !== "" && address.error}
                    search
                    success={address.zip_code !== ""}
                  />
                </div>

                <Spacer bottom={10} />

                <div className="col-xl-5 col-12">
                  <Input
                    required={address.street === ""}
                    label="Rua"
                    type="text"
                    value={values.street}
                    name="street"
                    placeholder="Rua Borboletas Psicodélicas"
                    onChange={handleChange("street")}
                    success={address.street !== ""}
                  />
                </div>

                <div className=" col-12 col-xl-3">
                  <Input
                    required={values.number === ""}
                    label="Número"
                    type="text"
                    value={values.number}
                    placeholder="261"
                    name="number"
                    error={touched.number && errors.number}
                    success={values.number !== ""}
                    onChange={handleChange("number")}
                  />
                </div>

                <div className=" col-12 col-xl-4">
                  <Input
                    label="Complemento"
                    type="text"
                    value={values.complement}
                    name="complement"
                    placeholder="Casa 1"
                    success={values.complement !== ""}
                    onChange={handleChange("complement")}
                  />
                </div>

                <div className="col-xl-5 col-12">
                  <Input
                    required={address.district === ""}
                    label="Bairro"
                    type="text"
                    name="district"
                    placeholder="Vila Guarani"
                    value={values.district}
                    error={
                      touched.district &&
                      values.district === "" &&
                      values.district
                    }
                    success={address.district !== ""}
                    onChange={handleChange("district")}
                  />
                </div>

                <div className="col-12 col-xl-4">
                  <Input
                    type="text"
                    value={values.city}
                    success={address.city !== ""}
                    required={address.city === ""}
                    name="city"
                    label="Cidade"
                    placeholder="São Paulo"
                    error={values.city === "" && values.city}
                    onChange={handleChange("city")}
                  />
                </div>

                <div className=" col-12 col-xl-3 ">
                  <CustomSelect
                    label="Estado"
                    name="state"
                    placeholder="Selecione"
                    required={values.state === ""}
                    value={CustomSelectValue(values.state, ufOptions)}
                    options={ufOptions}
                    onChange={(value) => {
                      setFieldValue("state", value.target.value);
                    }}
                    menuPlacement="top"
                  />
                </div>

                <div className="col-12 mt-3">
                  <h5>Representante signatário?</h5>

                  <p>
                    É obrigatório ter pelo menos um representante signatário
                  </p>
                  <Spacer bottom={10} />
                  <div
                    className="d-flex"
                    onClick={() => {
                      setFieldValue("signatory", !values.signatory);
                    }}
                  >
                    <Toggle mode="small" checked={values.signatory} />

                    <Spacer right={10} />

                    <Description color="#666666">
                      Marcar como representante signatário
                    </Description>
                  </div>

                  <Spacer bottom={30} />
                </div>
              </div>

              <div className="row d-flex flex-row align-items-end mt-3">
                <div className="col-12 d-flex justify-content-between">
                  <div style={{ marginLeft: "-20px" }}>
                    {!addRepresentative && (
                      <Button
                        mode="phantom"
                        label="Cancelar"
                        onClick={() => {
                          resetForm();
                          setAddress({
                            zip_code: "",
                            street: "",
                            district: "",
                            city: "",
                            state: "",
                            error: "",
                          });
                          setCompanyRepresentativeValues(initialValues);
                          setAddRepresentative(true);
                          setIsEditing(false);
                        }}
                      />
                    )}
                  </div>

                  <Button
                    type="button"
                    onClick={() =>
                      isEditing
                        ? fetchEditRepresentativeData()
                        : fetchSendRepresentative()
                    }
                    label={
                      isEditing ? "Concluir edição" : "Salvar representante"
                    }
                    disabled={!canAddRepresentative || isNotOld}
                    loading={isSubmitting}
                    mode={"main"}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Spacer bottom={89} />
      <InteractiveModal size="md" toggleModal={() => null} open={isLoading}>
        <div className="p-4 h-100 d-flex h-100 justify-content-center align-items-center w-100   flex-column">
          <Spacer bottom={120} />
          <div
            className="spinner-border mb-2"
            role="status"
            style={{ color: theme.colors.primary.main }}
          ></div>
          <Spacer top={20} />
          <div className="text-center">
            {isEditing
              ? "Atualizando dados..."
              : "Adicionando representante..."}
          </div>
        </div>
      </InteractiveModal>
      <InteractiveModal
        size="md"
        toggleModal={handleCloseModal}
        open={openDeleteRepresentativeModal}
      >
        <div className="p-4">
          <TitleModal className="text-center">
            Deseja excluir o representante {dataRepresentativeToDelete?.name} ?
          </TitleModal>
          <Spacer bottom={150} />
          <Button
            mode="main"
            label="Não, cancelar"
            full
            onClick={handleCloseModal}
          />
          <Spacer bottom={10} />
          <Button
            mode="border"
            label="Sim, excluir"
            full
            onClick={() => deleteRepresentative(dataRepresentativeToDelete?.id)}
            loading={loadingDelete}
          />
        </div>
      </InteractiveModal>
    </div>
  );
};

export default Representatives;
