import { useEffect, useState } from "react";
import * as Yup from "yup";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Input,
  InteractiveModal,
  Spacer,
  Radio,
} from "@zilliondigital/mirage-ui";

import "bootstrap/dist/css/bootstrap.min.css";
import TimerIcon from "~/assets/icons/figure_3d_time.jpg";
import ArrowLeftIcon from "~/assets/icons/ic_functional_back.png";
import { Link } from "react-router-dom";
import { FormikHelpers, FormikProps, useFormik } from "formik";
import {
  hasLength,
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCase,
  passwordLevel,
  passwordStrengthText,
} from "~/services/utils";
import { useNavigate } from "react-router-dom";
import {
  BackgroundContainer,
  ButtonReturnContainer,
  ContainerRadio,
  GeneralContainer,
  Sidebar,
  SidebarBody,
  SidebarBodyNewPassword,
  TextFooter,
  Title,
  IconModal,
  ContainerModal,
} from "./Auth.styles";
import { REQUIRED_LABEL } from "~/services/validators";
import ApiService from "~/services/api";
import useToast from "~/hooks/useToast";

type NewPasswordValues = {
  _password: string;
  _password_confirm: string;
  token: string;
};

const initialValues: NewPasswordValues = {
  _password: "",
  _password_confirm: "",
  token: "",
};

const validationSchema: any = Yup.object().shape({
  _password: Yup.string()
    .test("hasLength", "A senha deve conter pelo menos 8 caracteres", (value) =>
      hasLength(value ?? "", 8)
    )
    .test("hasNumber", "A senha deve conter pelo menos 1 número", (value) =>
      hasNumber(value ?? "")
    )
    .test(
      "hasUpperCase",
      "A senha deve conter pelo menos 1 letra maiúscula",
      (value) => hasUpperCase(value ?? "")
    )
    .test(
      "hasLowerCase",
      "A senha deve conter pelo menos 1 letra minúscula",
      (value) => hasLowerCase(value ?? "")
    )
    .test(
      "hasSpecialCharacter",
      "A senha deve conter pelo menos 1 caractere especial",
      (value) => hasSpecialCharacter(value ?? "")
    )
    .required("Este campo é obrigatório")
    .min(8, "Mínimo 8 caracteres"),
  token: Yup.string(),
});

type OnFinishProps = (newCount: number) => void;

const NewPassword = () => {
  const [count, setCount] = useState(5);
  const [openModal, setOpenModal] = useState(false);

  const { showToast } = useToast();

  const ModalTimer = (
    openModal: boolean,
    duration: number,
    onFinish: OnFinishProps
  ) => {
    const navigate = useNavigate();

    let timerId: NodeJS.Timeout | null = null;

    useEffect(() => {
      if (openModal && duration > 0) {
        timerId = setTimeout(() => onFinish(duration - 1), 1000);
      } else if (openModal && duration === 0) {
        timerId = setTimeout(() => navigate("/auth/login"), 1000);
      }

      return () => {
        if (timerId) {
          clearTimeout(timerId);
        }
      };
    }, [openModal, duration, onFinish]);
  };

  ModalTimer(openModal, count, (newCount: number) => {
    setCount(newCount);
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (openModal && count > 1) {
      setTimeout(() => setCount((prevCount) => prevCount - 1), 1000);
    } else if (count === 1) {
      setTimeout(() => navigate("/app/dashboard"), 1000);
    }
  }, [openModal, count, navigate]);

  const handleFormSubmit = (
    values: NewPasswordValues,
    helpers: FormikHelpers<NewPasswordValues>
  ) => {
    helpers.setSubmitting(true);

    resetPassword();

    helpers.setSubmitting(false);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  }) as FormikProps<NewPasswordValues>;

  const resetPassword = async () => {
    try {
      const { data } = await ApiService.HttpPost({
        route: "user-change-password/",
        body: {
          confirm_password: values._password_confirm,
          password: values._password,
          token: values.token,
        },
      });

      // setOpenModal(true);

      setTimeout(() => {
        showToast(
          "Senha alterada com sucesso!",
          "Faça login para continuar.",
          "success"
        );

        navigate("/auth/login");
      }, 500);
    } catch (e: any) {
      showToast("Ocorreu um erro", e?.message, "error");
    }
  };

  return (
    <GeneralContainer>
      <Sidebar>
        <ButtonReturnContainer>
          <Link to="/auth/login">
            <Button
              mode="phantom"
              icon={ArrowLeftIcon}
              iconPosition="left"
              link
              label="Voltar para a tela inicial"
            />
          </Link>
        </ButtonReturnContainer>
        <Spacer bottom={33} />
        <SidebarBody>
          <SidebarBodyNewPassword>
            <Title>Vamos criar uma nova senha</Title>
            <Spacer bottom={22} />
            <ContainerRadio>
              <Radio
                checked={hasLength(values._password, 8)}
                position="right"
                label="Mínimo de 8 caracteres"
              />
            </ContainerRadio>
            <ContainerRadio>
              <Radio
                checked={hasNumber(values._password)}
                position="right"
                label="Mínimo de 1 número"
              />
            </ContainerRadio>
            <ContainerRadio>
              <Radio
                checked={
                  hasUpperCase(values._password) &&
                  hasLowerCase(values._password)
                }
                position="right"
                label="Letras maiúsculas e Minúsculas"
              />
            </ContainerRadio>
            <ContainerRadio>
              <Radio
                checked={hasSpecialCharacter(values._password)}
                position="right"
                label="Caracteres especiais"
              />
            </ContainerRadio>
          </SidebarBodyNewPassword>

          <Spacer bottom={4} />
          <span className="text-size-md">
            Para reforçar a segurança da sua senha, não utilize informações
            pessoais como datas de nascimentos ou números de telefones e nem
            números sequenciais.
          </span>
          <Spacer bottom={17} />

          <form onSubmit={handleSubmit}>
            <Input
              label="Digite sua senha"
              required={
                !hasLength(values._password, 8) &&
                !hasNumber(values._password) &&
                !hasUpperCase(values._password) &&
                !hasLowerCase(values._password)
              }
              type="password"
              name="_password"
              value={values._password}
              newPassword={values._password.length > 0 && true}
              helper={passwordStrengthText(values._password)}
              newPasswordLevel={passwordLevel(values._password)}
              onChange={handleChange}
              error={touched._password && errors._password}
              autoCapitalize="none"
              autoComplete="none"
            />
            <Spacer bottom={-5} />
            <Input
              type="password"
              required={
                values._password_confirm !== values._password ||
                !values._password_confirm
              }
              label="Confirme sua senha"
              name="_password_confirm"
              value={values._password_confirm}
              onChange={handleChange}
              error={
                values._password_confirm.length > 0 &&
                values._password_confirm !== values._password &&
                "Suas senhas são diferentes"
              }
              autoCapitalize="none"
              autoComplete="none"
            />
            <Spacer bottom={-5} />

            <Input
              type="password"
              required={!values.token}
              label="Token de confirmação"
              name="token"
              placeholder="Digite o token enviado ao seu email"
              value={values.token}
              onChange={handleChange}
              error={errors.token}
              autoCapitalize="none"
              autoComplete="none"
            />
            <Spacer bottom={5} />

            <Button
              mode="main"
              label="Salvar nova senha e entrar"
              type="submit"
              full
              disabled={
                values._password_confirm !== values._password ||
                values.token.length < 1 ||
                !values._password_confirm ||
                values.token.length < 1
              }
            />
          </form>
        </SidebarBody>
        <footer>
          {/* <TextFooter>
            Powered by Prosperità Inovação e Tecnologia Ltda <br /> © 2018 -
            2023
          </TextFooter> */}
        </footer>
      </Sidebar>

      <BackgroundContainer></BackgroundContainer>

      <InteractiveModal
        size="md"
        open={openModal}
        toggleModal={() => setOpenModal(true)}
      >
        <ContainerModal>
          <IconModal src={TimerIcon} />
          <Spacer bottom={20} />
          <h2 className="text-center">Senha atualizada com sucesso</h2>
          <Spacer bottom={20} />
          <p>
            Tudo certo com seu acesso à plataforma. Você será redirecionado para
            a página de início em <strong>{count} segundos…</strong>
          </p>
        </ContainerModal>
      </InteractiveModal>
    </GeneralContainer>
  );
};

export default NewPassword;
