import {
  Button,
  CustomSelect,
  Input,
  Spacer,
} from "@zilliondigital/mirage-ui";
import Description from "~/components/Texts/Description";
import { accountType } from "~/data/options";
import useNewTransfer from "~/hooks/Transfers/useNewTransfer";
import ApiService from "~/services/api";
import * as Yup from "yup";
import {
  INVALID_FORMAT,
  REQUIRED_LABEL,
  cnpjCpfRegex,
} from "~/services/validators";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import useBanks from "~/hooks/useBanks";
import { motion } from "framer-motion";
import useAccount from "~/hooks/useAccount";
import useToast from "~/hooks/useToast";
import useTransfers from "~/hooks/Transfers/useTransfers";
import useContacts from "~/hooks/Contacts/useContacts";

type ValuesDataTEDProps = {
  name: string;
  document_number: string;
  agency: string;
  account_number_with_digit: string;
  email: string;
};

const initialValues: ValuesDataTEDProps = {
  name: "",
  document_number: "",
  account_number_with_digit: "",
  agency: "",
  email: "",
};

const FormSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_LABEL),
  document_number: Yup.string()
    .required(REQUIRED_LABEL)
    .matches(cnpjCpfRegex, INVALID_FORMAT),
  email: Yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
  account_number_with_digit: Yup.string().required(REQUIRED_LABEL),

  agency: Yup.string().required(REQUIRED_LABEL),
});

const DataTED = () => {
  const {
    setSelectContact,
    handleTedNextStep,
    setChooseNewTransfer,
    setSelectedValue,
    changeSideAnimation,
    newTransferTEDData,
    setNewTransferTEDData,
    setChangeSideAnimation,
    setBankAccountIndex,
    setIsTEV,
    setIdContact,
    idContact,
  } = useNewTransfer();

  const { setIsManual } = useTransfers();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getBanks, bankOptions, banksData } = useBanks();

  const { mainAccount } = useAccount();
  const { showToast } = useToast();

  const { setContactsData, contactsData } = useContacts();

  const [selectedOption, setSelectedOption] = useState<any>(null);

  const handleSelectChange = (selectedValue: any) => {
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    banksData?.length <= 0 && getBanks();
  }, []);

  const bankCode = selectedOption?.target.value;

  const { values, errors, handleBlur, touched, handleChange, resetForm } =
    useFormik({
      initialValues: initialValues,
      validationSchema: FormSchema,
      onSubmit: (values) => {},
    });

  const removeLastCharacter = (str: string) => {
    return str.slice(0, -1);
  };

  const getLastCharacter = (str: string) => {
    return str.slice(-1);
  };

  const newContactPayloadTED = {
    default: false,
    document_number: values.document_number,
    document_type: values.document_number.length > 14 ? "cnpj" : "cpf",
    email: values.email,
    name: values.name,
    target: "ted",
    type: "contact",
    user: mainAccount?.id,
  };

  const isValid =
    values.name.length >= 2 &&
    values.agency.length >= 2 &&
    values.account_number_with_digit.length >= 2 &&
    values.document_number.length >= 14 &&
    bankCode !== undefined;

  const setNewContact = async () => {
    setNewTransferTEDData({});

    setIsManual(true);
    setIsLoading(true);

    try {
      const data = await ApiService.HttpPost({
        route: "partner/",
        token: true,
        body: newContactPayloadTED,
      });

      const idNewContact = data.data.id;
      setIdContact(data.data.id);

      const newBankAccountPaylaod = {
        account_number: removeLastCharacter(values.account_number_with_digit),
        account_number_digit: getLastCharacter(
          values.account_number_with_digit
        ),
        agency: values.agency,
        bank: bankCode,
        partner: idNewContact,
        type: "corrente",
      };

      try {
        const { data } = await ApiService.HttpPost({
          route: "bank-account/",
          token: true,
          body: newBankAccountPaylaod,
        });

        const { data: partnerGetData } = await ApiService.HttpGet({
          route: "partner/",
          token: true,
          params: {
            type: "contact",
            default: false,
            target: "ted",
          },
        });

        const filteredContact = partnerGetData.results.find((contact: any) => {
          return contact.id === idNewContact;
        });

        setNewTransferTEDData(filteredContact);
        setContactsData([filteredContact]);

        // const contact = filteredContact.forEach((contact: any) => {
        //   return contact;
        // });

        // setBankAccountIndex(0);
        // setNewTransferTEDData(contact);

        handleTedNextStep();
      } catch (e) {
        console.warn(e);
      }
    } catch (e: any) {
      setIsLoading(false);
      if (
        e.response?.data?.document_number === "invalid partner document number"
      ) {
        setIsLoading(false);
        showToast(
          "CPF/CNPJ inválido",
          "verifique o numero digitado e tente novamente",
          "error"
        );
      }
    }
  };

  return (
    <motion.div
      initial={{
        x: changeSideAnimation ? "-100%" : !changeSideAnimation && "100%",
      }}
      animate={{ x: 0 }}
      exit={{
        x: changeSideAnimation ? "100%" : !changeSideAnimation && "-100%",
      }}
      transition={{ duration: 0.3 }}
      className="vh-100"
    >
      <div className=" w-100 d-flex flex-column justify-content-start  ">
        <Spacer bottom={20} />
        <Description className="ps-4 pe-4">
          Para continuar é necessário informar os dados de quem receberá a
          transferência
        </Description>

        <Spacer bottom={20} />

        <div className="row p-4 ">
          <div className="col-6 ">
            <Input
              label="Nome / Razão Social"
              placeholder="AMEC INC."
              name="name"
              value={values.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-6 ">
            <Input
              label="CPF / CNPJ"
              placeholder="00.000.000/0000-00"
              name="document_number"
              value={values.document_number}
              onChange={handleChange}
              mask={
                values.document_number.length > 14
                  ? "99.999.999/9999-99"
                  : "999.999.999-9999999"
              }
            />
          </div>
          <div className="col-12">
            {" "}
            <Input
              label="Email"
              placeholder="prosperita@gmail.com"
              name="email"
              value={values.email}
              onChange={handleChange}
            />
          </div>
          <div className="col-6">
            <CustomSelect
              label="Instituição"
              placeholder="Selecione"
              options={bankOptions}
              onChange={handleSelectChange}
            />
          </div>
          <div className="col-6">
            {/* {" "}
            <CustomSelect
              label="Tipo de conta"
              placeholder="Selecione"
              options={accountType}
            /> */}
          </div>
          <div className="col-6">
            {" "}
            <Input
              name="agency"
              label="Agência"
              value={values.agency}
              onChange={handleChange}
              placeholder="0001"
              helper="Sem dígito"
              mask="99999999999999999999999"
            />
          </div>
          <div className="col-6">
            <Input
              name="account_number_with_digit"
              value={values.account_number_with_digit}
              onChange={handleChange}
              label="Conta"
              placeholder="1245678-0"
              helper="Com dígito"
              mask="99999999999999999999999"
            />
          </div>
        </div>

        <Spacer bottom={20} />
        <div className="d-flex justify-content-between  w-100 ps-4 pe-4">
          <div>
            <Button
              mode="phantom"
              label="Cancelar"
              onClick={() => {
                setSelectedValue("");
                setIsTEV(false);

                setChooseNewTransfer(null);
              }}
            />
          </div>

          <div className="d-flex">
            <Button
              mode="border"
              label="Selecionar contato"
              onClick={() => {
                setChangeSideAnimation(false);
                setSelectContact(false);
              }}
            />
            <Spacer right={20} />
            <Button
              mode="main"
              loading={isLoading}
              label="Avançar"
              onClick={() => {
                setNewContact();
              }}
              disabled={!isValid}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default DataTED;
